import { Command, CommandEnum } from './CommandModule';
import { Events } from '../events';
import { eventsManager } from '../managers/EventsManager';
import { EntityFactory, FunctionalityChip } from '../SavaneJS';

export class EditFunctionalityChipCommand  extends Command
{
    private _modifiedFuncChip: FunctionalityChip;
    private _currentFuncChip: FunctionalityChip;

    constructor(currentFuncChip: FunctionalityChip) {
        super();
        // Clone current functionality chip so it copies the temporary fields into the cloned functionality chip entity
        this._modifiedFuncChip = EntityFactory.cloneEntity(currentFuncChip, false) as FunctionalityChip;
        // Override data from cloned object with data from object which will be taken from temporary (this is specific to this object because the clone operation doesn't do the copy itself [maybe this can be done better BTW])
        this._modifiedFuncChip.data = currentFuncChip.data;
        // End the functionality chip temporary so it drops the temporary data
        currentFuncChip.endTemporary();
        // Save the current functionality chip for future data exchange
        this._currentFuncChip = currentFuncChip;
    }

    // Command name returned from a global enum
    name(): string {
        return CommandEnum.EditFunctionalityChipCommand;
    }

    // Undo the current command
    undo() {
        // Call execute again, this will exchange old/new values
        this.execute();
    }

    // Execute current command (redo), this will exchange old/new values
    execute() {
        let tmpData = this._currentFuncChip.data;
        // Now copy new edited data into entity
        this._currentFuncChip.data = this._modifiedFuncChip.data;
        // Store saved data into local cloned entity for undo purpose
        this._modifiedFuncChip.data = tmpData;

        // Select object at the end
        eventsManager.instance.dispatch(Events.CHANGE_EDITOR_SELECTION,
            {
                selection: [this._currentFuncChip],
                keepSelected: false,
                showTulip: false
            });
    }
};
