import { ComponentConstants, math, SavaneMath } from '../SavaneJS';
import { Coating } from './Coating';

export class CoatingArea extends Coating {
    private _vertices: Array<math.vec3> = new Array<math.vec3>();
    public isDirectSide: boolean = true;

    constructor(coatingId: string, manufacturer: any, retailer: any, hangType: Coating.HangType, colors: any, randomization: any, floorGeneratorSettings: any, urlPreview: string) {
        super(coatingId, manufacturer, retailer, hangType, colors, randomization, floorGeneratorSettings, urlPreview);
    }

    isInArea(p: math.vec3): boolean {
        return SavaneMath.isInPoly(p, this._vertices);
    }

    get area(): number {
        return SavaneMath.getPolygonArea(this._vertices);
    }

    get componentType(): ComponentConstants.ComponentType {
        return ComponentConstants.ComponentType.CoatingArea;
    }

    get vertices(): Array<math.vec3> {
        return this._vertices;
    }

    set vertices(v: Array<math.vec3>) {
        this._vertices = v;
    }

    get aabb(): any {
        let result = {
            min: math.vec3.fromValues(Number.POSITIVE_INFINITY, Number.POSITIVE_INFINITY, Number.POSITIVE_INFINITY),
            max: math.vec3.fromValues(Number.NEGATIVE_INFINITY, Number.NEGATIVE_INFINITY, Number.NEGATIVE_INFINITY),
        }
        for (let i = 0; i < this._vertices.length; ++i) {
            result.min[0] = Math.min(result.min[0], this._vertices[i][0]);
            result.min[1] = Math.min(result.min[1], this._vertices[i][1]);
            result.min[2] = Math.min(result.min[2], this._vertices[i][2]);

            result.max[0] = Math.max(result.max[0], this._vertices[i][0]);
            result.max[1] = Math.max(result.max[1], this._vertices[i][1]);
            result.max[2] = Math.max(result.max[2], this._vertices[i][2]);
        }
        return result;
    }

    clone(): CoatingArea {
        let obj = new CoatingArea(this._coatingId, this._manufacturer, this._retailer, this._hangType, this._colors, this._randomization, this._floorGeneratorSettings, this.urlPreview);
        obj.colorIndex = this._colorIndex;
        obj.offset = this._offset;
        obj.isDirectSide = this.isDirectSide;
        obj.rotation = this.rotation;
        obj.colorIndex = this.colorIndex;
        obj.offset = math.vec2.clone(this.offset);
        obj.repeat = math.vec2.clone(this.repeat);

        let vertices = [];
        for (let i = 0; i < this._vertices.length; i++) {
            vertices.push(math.vec3.clone(this._vertices[i]));
        }
        obj.vertices = vertices;
        return obj;
    }
}
