import { Command, CommandEnum } from './CommandModule';
import { Events } from '../events';
import { eventsManager } from '../managers/EventsManager';
import { Entity, ArrangementGroup, math } from '../SavaneJS';

export class LeaveGroupCommand  extends Command
{
    // Group being left
    private _leftGroup: ArrangementGroup;
    // Entity leaving the group
    private _leavingEntity: Entity;

    constructor(entity: Entity) {
        super();
        // Entity that will be leaving the group
        this._leavingEntity = entity;
        // Arrangement group being left by the entity (parent)
        this._leftGroup = (entity.parent as ArrangementGroup);
    }

    // Command name returned from a global enum
    name(): string {
        return CommandEnum.LeaveGroupCommand;
    }

    // Datas that allows the command to be executed
    execDatas(): any {
        return {
            group: this._leftGroup,
            entity: this._leavingEntity
        };
    }

    // Undo the current command
    undo() {
        // Remove object from entity manager
        eventsManager.instance.dispatch(Events.REDRAW_GRAPHICAL_ENTITY,
            {
                entity: this._leavingEntity,
                extraActions: ['deleteMenu'],
            });
        eventsManager.instance.dispatch(Events.REMOVE_GRAPHICAL_ENTITY,
            {
                entity: this._leavingEntity,
            });

        let toLocal = this._leftGroup.transform.invertedGlobalMatrix;
        let world = this._leavingEntity.transform.globalMatrix;

        // Delete arrangement from its current parent
        this._leavingEntity.parent.deleteChild(this._leavingEntity.id);
        // Reassign it to the group
        this._leftGroup.addChild(this._leavingEntity);
        // Reassign position
        let matrix = math.mat4.create();
        math.mat4.multiply(matrix, toLocal, world);
        math.mat4.copy(this._leavingEntity.transform.localMatrix, matrix);

        // Recompute group content
        let entity = this._leftGroup;
        while (entity.isArrangementGroupEntity()) {
            entity.recenter();

            // Redraw all parents (their size might have changed due to recenter call above)
            eventsManager.instance.dispatch(Events.REDRAW_GRAPHICAL_ENTITY,
                {
                    entity: entity,
                });

            entity = (entity.parent as ArrangementGroup);
        }

        // Add the object back again
        eventsManager.instance.dispatch(Events.ADD_GRAPHICAL_ENTITY,
            {
                entity: this._leavingEntity,
            });
        eventsManager.instance.dispatch(Events.REDRAW_GRAPHICAL_ENTITY,
            {
                entity: this._leavingEntity,
                extraActions: ['addMenu'],
            });

        // Execute parent function
        super.undo();

        // Select the object used to create the group originally
        eventsManager.instance.dispatch(Events.CHANGE_EDITOR_SELECTION,
            {
                selection: [this._leavingEntity],
                keepSelected: false,
                showTulip: false
            });
    }

    // Execute current command (redo)
    execute() {
        // Remove the entity from the entity manager
        eventsManager.instance.dispatch(Events.REDRAW_GRAPHICAL_ENTITY,
            {
                entity: this._leavingEntity,
                extraActions: ['deleteMenu'],
            });
        eventsManager.instance.dispatch(Events.REMOVE_GRAPHICAL_ENTITY,
            {
                entity: this._leavingEntity,
            });

        // Delete the object from the group (i.e. from its parent)
        this._leavingEntity.parent.deleteChild(this._leavingEntity.id);

        let toWorld = math.mat4.create();
        if (this._leftGroup.parent) {
            toWorld =  this._leftGroup.parent.transform.invertedGlobalMatrix;
        }
        let local = this._leavingEntity.transform.localMatrix;

        // Reparent to left group parent
        this._leftGroup.parent.addChild(this._leavingEntity);

        // Reassign the object position and angle after leaving the group
        let matrix = math.mat4.create();
        math.mat4.multiply(matrix, this._leftGroup.transform.globalMatrix, local);
        math.mat4.multiply(matrix, toWorld, matrix);
        math.mat4.copy(this._leavingEntity.transform.localMatrix, matrix);

        // Recompute group content
        let entity = this._leftGroup;
        while (entity && entity.isArrangementGroupEntity()) {
            entity.recenter();

            // Redraw all parents (their size might have changed due to recenter call above)
            eventsManager.instance.dispatch(Events.REDRAW_GRAPHICAL_ENTITY,
                {
                    entity: entity,
                });

            entity = (entity.parent as ArrangementGroup);
        }

        // Add the object back again to the entity manager
        eventsManager.instance.dispatch(Events.ADD_GRAPHICAL_ENTITY,
            {
                entity: this._leavingEntity,
            });
        eventsManager.instance.dispatch(Events.REDRAW_GRAPHICAL_ENTITY,
            {
                entity: this._leavingEntity,
                extraActions: ['addMenu'],
            });

        // Select the object used to create the group originally
        eventsManager.instance.dispatch(Events.CHANGE_EDITOR_SELECTION,
            {
                selection: [this._leavingEntity],
                keepSelected: false,
                showTulip: false
            });

        //Execute parent function
        super.execute();
    }
};
