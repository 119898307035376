import { SceneConstants } from "../SceneConstants";
import { math } from "../Transform";
import { Segment } from "../../utils/Segment";
import { SavaneConstants } from "../../utils/SavaneConstants";
import { TemporaryStaircase } from "../temporary/TemporaryStaircase";
import { TechnicalElement } from "../TechElements/TechnicalElement";
import { Wall } from "../Wall";

// Staircases are considered to be technical elements specialised classes
export class Staircase extends TechnicalElement {

    protected _hasLeftRamp: boolean = false;
    protected _hasRightRamp: boolean = false;
    protected _rampMaterialType: number = 0;
    protected _rampHeight: number = 1000;
    protected _isGoingDown: boolean = false;
    protected _way: boolean = true;
    protected _closureType: SceneConstants.StaircaseCloseType = SceneConstants.StaircaseCloseType.opened_riser;
    protected _limonType: SceneConstants.StaircaseLimonType = SceneConstants.StaircaseLimonType.side_slope;
    protected _limonWidth: number = 40;
    protected _limonHeight: number = 180;
    protected _noseLength: number = 20;
    protected _hideInAxo: boolean = false;
    protected _cutSlope: boolean = true;
    protected _stairCaseStyleId: string;
    
    constructor(id: number, objectId: SceneConstants.StaircaseType, length: number, width: number, height: number, stairCaseStyleId: string) {
        super(id, objectId, length, width, height, "stairCase");

        if (stairCaseStyleId) {
            this._stairCaseStyleId = stairCaseStyleId;
        } else {
            this._stairCaseStyleId = null;
        }
    }

    get entityType() : SceneConstants.EntityType {
        return SceneConstants.EntityType.Staircase;
    }

    get isGoingDown() : boolean {
        return this._isGoingDown;
    }

    get way() : boolean {
        return this._way;
    }

    set way(value: boolean) {
        this._way = value;
    }

    get stairCaseStyleId() : string {
        return this._stairCaseStyleId;
    }

    set stairCaseStyleId(value: string) {
        this._stairCaseStyleId = value;
    }

    set isGoingDown(value: boolean) {
        this._isGoingDown = value;
    }

    get hasLeftRamp() : boolean {
        if (this.temporary === null) {
            return this._hasLeftRamp;
        } else {
            return (this.temporary as TemporaryStaircase).hasLeftRamp;
        }
    }

    set hasLeftRamp(value: boolean) {
        if (this.temporary === null) {
            this._hasLeftRamp = value;
        } else {
            (this.temporary as TemporaryStaircase).hasLeftRamp = value;
        }
    }

    get hasRightRamp() : boolean {
        if (this.temporary === null) {
            return this._hasRightRamp;
        } else {
            return (this.temporary as TemporaryStaircase).hasRightRamp;
        }
    }

    set hasRightRamp(value: boolean) {
        if (this.temporary === null) {
            this._hasRightRamp = value;
        } else {
            (this.temporary as TemporaryStaircase).hasRightRamp = value;
        }
    }

    get hideInAxo() : boolean {
        if (this.temporary === null) {
            return this._hideInAxo;
        } else {
            return (this.temporary as TemporaryStaircase).hideInAxo;
        }
    }

    set hideInAxo(value: boolean) {
        if (this.temporary === null) {
            this._hideInAxo = value;
        } else {
            (this.temporary as TemporaryStaircase).hideInAxo = value;
        }
    }

    get cutSlope() : boolean {
        if (this.temporary === null) {
            return this._cutSlope;
        } else {
            return (this.temporary as TemporaryStaircase).cutSlope;
        }
    }

    set cutSlope(value: boolean) {
        if (this.temporary === null) {
            this._cutSlope = value;
        } else {
            (this.temporary as TemporaryStaircase).cutSlope = value;
        }
    }

    get rampMaterialType() : number {
        if (this.temporary === null) {
            return this._rampMaterialType;
        } else {
            return (this.temporary as TemporaryStaircase).rampMaterialType;
        }
    }

    set rampMaterialType(value: number) {
        if (this.temporary === null) {
            this._rampMaterialType = value;
        } else {
            (this.temporary as TemporaryStaircase).rampMaterialType = value;
        }
    }

    get rampHeight() : number {
        if (this.temporary === null) {
            return this._rampHeight;
        } else {
            return (this.temporary as TemporaryStaircase).rampHeight;
        }
    }

    set rampHeight(value: number) {
        if (this.temporary === null) {
            this._rampHeight = value;
        } else {
            (this.temporary as TemporaryStaircase).rampHeight = value;
        }
    }

    get noseLength() : number {
        if (this.temporary === null) {
            return this._noseLength;
        } else {
            return (this.temporary as TemporaryStaircase).noseLength;
        }
    }

    set noseLength(value: number) {
        if (this.temporary === null) {
            this._noseLength = value;
        } else {
            (this.temporary as TemporaryStaircase).noseLength = value;
        }
    }

    get closureType() : SceneConstants.StaircaseCloseType {
        if (this.temporary === null) {
            return this._closureType;
        } else {
            return (this.temporary as TemporaryStaircase).closureType;
        }
    }

    set closureType(value: SceneConstants.StaircaseCloseType) {
        if (this.temporary === null) {
            this._closureType = value;
        } else {
            (this.temporary as TemporaryStaircase).closureType = value;
        }
    }

    get limonType() : SceneConstants.StaircaseLimonType {
        if (this.temporary === null) {
            return this._limonType;
        } else {
            return (this.temporary as TemporaryStaircase).limonType;
        }
    }

    set limonType(value: SceneConstants.StaircaseLimonType) {
        if (this.temporary === null) {
            this._limonType = value;
        } else {
            (this.temporary as TemporaryStaircase).limonType = value;
        }
    }

    get limonWidth() : number {
        if (this.temporary === null) {
            return this._limonWidth;
        } else {
            return (this.temporary as TemporaryStaircase).limonWidth;
        }
    }

    set limonWidth(value: number) {
        if (this.temporary === null) {
            this._limonWidth = value;
        } else {
            (this.temporary as TemporaryStaircase).limonWidth = value;
        }
    }

    get limonHeight() : number {
        if (this.temporary === null) {
            return this._limonHeight;
        } else {
            return (this.temporary as TemporaryStaircase).limonHeight;
        }
    }

    set limonHeight(value: number) {
        if (this.temporary === null) {
            this._limonHeight = value;
        } else {
            (this.temporary as TemporaryStaircase).limonHeight = value;
        }
    }
    /**
     * Setter for absolute position of ArrangementObject
     *
     * @param {*} newPosition
     */
    set localPosition(newPosition: math.vec3) {
        this.transform.localPosition = newPosition;
    }

    get localPosition() : math.vec3 {
        return this.transform.localPosition;
    }

    set floorHeight(value: number) {
        let newPosition = math.vec3.create();
        math.vec3.set(newPosition, this.localPosition[0], this.localPosition[1], value);
        this.localPosition = newPosition;
    }

    get floorHeight() : number {
        return this.localPosition[2];
    }

    /**
     * Returns the array of 4 point deliminting the technicalElement (2d)
     */
    get boundingBox() : Array<math.vec3> {
        let points = [];
        let point1 = math.vec3.create();
        let point2 = math.vec3.create();
        let point3 = math.vec3.create();
        let point4 = math.vec3.create();
        math.vec3.set(point1, this.position[0] + (Math.cos(this.angle) * this.length) / 2, this.position[1] + (Math.sin(this.angle) * this.length) / 2, 0);

        math.vec3.set(point2, point1[0] - (Math.cos(Math.PI / 2 - this.angle) * this.width) / 2, point1[1] + (Math.sin(Math.PI / 2 - this.angle) * this.width) / 2, 0);
        math.vec3.set(point1, point1[0] + (Math.cos(Math.PI / 2 - this.angle) * this.width) / 2, point1[1] - (Math.sin(Math.PI / 2 - this.angle) * this.width) / 2, 0);

        math.vec3.set(point3, this.position[0] - (Math.cos(this.angle) * this.length) / 2, this.position[1] - (Math.sin(this.angle) * this.length) / 2, 0);

        math.vec3.set(point4, point3[0] + (Math.cos(Math.PI / 2 - this.angle) * this.width) / 2, point3[1] - (Math.sin(Math.PI / 2 - this.angle) * this.width) / 2, 0);
        math.vec3.set(point3, point3[0] - (Math.cos(Math.PI / 2 - this.angle) * this.width) / 2, point3[1] + (Math.sin(Math.PI / 2 - this.angle) * this.width) / 2, 0);

        points.push(point1);
        points.push(point2);
        points.push(point3);
        points.push(point4);

        return points;
    }

    get realBoundingBox() : Array<math.vec3> {
        return this.boundingBox;
    }

    /**
     * Test whether the wall cross the object
     *
     * @param {*} wall
     */
    crossWall(wall: Wall) {
        var box = this.realBoundingBox;

        for (let i = 0; i < box.length; i++) {
            if (Segment.areSegmentCrossing(wall.segment, new Segment(box[i], box[(i + 1) % box.length]), SavaneConstants.PositionTolerance)) {
                return true;
            }
        }
        return false;
    }

    addToCappedProperty(property: string, value: any) : number {
        let capped = 0;

        if (property !== "angle") {
            if (this[property] + value <= 0) {
                this[property] = 0;
                capped = 1;
            }
        }

        if (capped === 0) {
            this[property] += value;
        }

        return capped;
    }

    startTemporary() : void {
        this.temporary = new TemporaryStaircase(this);
    }

    saveAndEndTemporary() : void {
        var positionTemp = this.position;
        var angleTemp = this.angle;
        var wTemp = this.width;
        var hTemp = this.height;
        var lTemp = this.length;
        var shapeTemp = this.shapeType;
        var hasLeftRampTemp = this.hasLeftRamp;
        var hasRightRampTemp = this.hasRightRamp;
        var rampMaterialType = this.rampMaterialType;
        var rampHeight = this.rampHeight;
        var noseLength = this.noseLength;
        var closureType = this.closureType;
        var limonType = this.limonType;
        var limonWidth = this.limonWidth;
        var limonHeight = this.limonHeight;
        var hideInAxo = this.hideInAxo;

        this.temporary = null;

        this.position = positionTemp;
        this.setRotationZ(angleTemp);
        this.height = hTemp;
        this.length = lTemp;
        this.width = wTemp;
        this.shapeType = shapeTemp;
        this.hasLeftRamp = hasLeftRampTemp;
        this.hasRightRamp = hasRightRampTemp;
        this.rampMaterialType = rampMaterialType;
        this.rampHeight = rampHeight;
        this.noseLength = noseLength;
        this.closureType = closureType;
        this.limonType = limonType;
        this.limonWidth = limonWidth;
        this.limonHeight = limonHeight;
        this.hideInAxo = hideInAxo;
    }
}
