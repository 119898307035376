import { Command, CommandEnum } from "./CommandModule";
import { Events } from "../events";
import { eventsManager } from "../managers/EventsManager";
import { EntityFactory, Wall, Joinery, TechnicalElement, CeilingBox, SceneConstants, wallManager, SavaneConstants, math } from "../SavaneJS";

export class EditWallPropertiesCommand extends Command {
    private _modifiedWall: Wall;
    private _currentWall: Wall;

    constructor(currentWall: Wall) {
        super();
        // Clone current wall so it copies the temporary fields into the cloned wall entity
        this._modifiedWall = EntityFactory.cloneEntity(currentWall, false) as Wall;
        // End the wall temporary so it drops the temporary data
        currentWall.endTemporary();
        // Save the current wall for future data exchange
        this._currentWall = currentWall;
    }

    // Command name returned from a global enum
    name(): string {
        return CommandEnum.EditWallPropertiesCommand;
    }

    execDatas(): any {
        return {
            id: this._currentWall.id,
            type: this._currentWall.entityType,
            entity: this._currentWall,
        };
    }

    // Undo the current command
    undo() {
        // Call the execute again this will restore saved values
        this.execute();
    }

    // Execute current command (redo)
    execute() {
        // Exchange wall height between cloned new wall and old wall (old value will end up being into the cloned wall for execute function)
        let tmpHeight = this._currentWall.height;
        this._currentWall.height = this._modifiedWall.height;
        this._modifiedWall.height = tmpHeight;

        //  Exhange wall begin points
        let tmpWallBegin = math.vec3.clone(this._currentWall.begin);
        this._currentWall.begin = math.vec3.clone(this._modifiedWall.begin);
        this._modifiedWall.begin = tmpWallBegin;

        //  Exhange wall end points
        let tmpWallEnd = math.vec3.clone(this._currentWall.end);
        this._currentWall.end = math.vec3.clone(this._modifiedWall.end);
        this._modifiedWall.end = tmpWallEnd;

        // Echange wall thickness
        let tmpThickness = this._currentWall.thickness;
        this._currentWall.thickness = this._modifiedWall.thickness;
        this._modifiedWall.thickness = tmpThickness;

        // Exchange wall shift directions
        let tmpShiftDirection = this._currentWall.shiftDirection;
        this._currentWall.shiftDirection = this._modifiedWall.shiftDirection;
        this._modifiedWall.shiftDirection = tmpShiftDirection;

        // Exchange wall slope parameters
        let tmpSlope = this._currentWall.slope;
        this._currentWall.slope = this._modifiedWall.slope;
        this._modifiedWall.slope = tmpSlope;
        let tmpSlopeHeight = this._currentWall.slopeHeight;
        this._currentWall.slopeHeight = this._modifiedWall.slopeHeight;
        this._modifiedWall.slopeHeight = tmpSlopeHeight;
        let tmpSlopeLength1 = this._currentWall.slopeLength1;
        this._currentWall.slopeLength1 = this._modifiedWall.slopeLength1;
        this._modifiedWall.slopeLength1 = tmpSlopeLength1;
        let tmpSlopeLength2 = this._currentWall.slopeLength2;
        this._currentWall.slopeLength2 = this._modifiedWall.slopeLength2;
        this._modifiedWall.slopeLength2 = tmpSlopeLength2;

        // Exchange wall forceNoPlinth
        let tmpForceNoPlinth = this._currentWall.forceNoPlinth;
        this._currentWall.forceNoPlinth = this._modifiedWall.forceNoPlinth;
        this._modifiedWall.forceNoPlinth = tmpForceNoPlinth;

        // Exchange wall forceNoCornice
        let tmpForceNoCornice = this._currentWall.forceNoCornice;
        this._currentWall.forceNoCornice = this._modifiedWall.forceNoCornice;
        this._modifiedWall.forceNoCornice = tmpForceNoCornice;

        // Update and redraw walls at begin and end of the current wall
        let beginWalls = wallManager.getWallsAtCorner(this._currentWall.begin, this._currentWall.floor, SavaneConstants.PositionTolerance);
        if (beginWalls != null) {
            for (let i = 0; i < beginWalls.length; ++i) {
                eventsManager.instance.dispatch(Events.REDRAW_GRAPHICAL_WALL, {
                    entity: beginWalls[i],
                });
            }
        }
        let endWalls = wallManager.getWallsAtCorner(this._currentWall.end, this._currentWall.floor, SavaneConstants.PositionTolerance);
        if (endWalls != null) {
            for (let i = 0; i < endWalls.length; ++i) {
                eventsManager.instance.dispatch(Events.REDRAW_GRAPHICAL_WALL, {
                    entity: endWalls[i],
                });
            }
        }
        // Draw current walls
        eventsManager.instance.dispatch(Events.REDRAW_GRAPHICAL_WALL, {
            entity: this._currentWall,
        });

        // Update the rooms around the edited wall
        for (let i = 0; i < this._currentWall.rooms.length; ++i) {
            eventsManager.instance.dispatch(Events.REDRAW_GRAPHICAL_ROOM, {
                entity: this._currentWall.rooms[i],
            });
        }

        // Recompute joineries position for the modified wall
        this._currentWall.recomputeJoineriesPosition();

        // Update joineryChild
        for (let i = 0; i < this._currentWall.children.length; i++) {
            // Get i-th child
            let join = this._currentWall.children[i];

            // If this is a joinery
            if (join.isJoineryEntity()) {
                // Parse all joinery children
                for (let j = 0; j < join.children.length; j++) {
                    // Get j-th joinery child
                    let joinChild = join.children[j];

                    // If the child is a technical element, ceilingBox or rolling shutter (volet roulant), uodate its position depding on joinery orientation
                    if (joinChild.isTechnicalElementEntity() && ((joinChild as TechnicalElement).objectId === SceneConstants.TechnicalElementType.ceilingBox) && ((joinChild as CeilingBox).ceilingBoxType === SceneConstants.CeilingBoxType.rollingShutter)) {
                        let position = math.vec3.create();

                        if ((join as Joinery).orientation) {
                            math.vec3.set(position, 0, -this._currentWall.thickness / 2, (join as Joinery).height + (join as Joinery).floorHeight);
                        } else {
                            math.vec3.set(position, 0, this._currentWall.thickness / 2, (join as Joinery).height + (join as Joinery).floorHeight);
                        }
                        joinChild.transform.localPosition = position;
                    }
                }
            }
        }

        // Activate selection on edited wall at the end
        eventsManager.instance.dispatch(Events.CHANGE_EDITOR_SELECTION, {
            selection: [this._currentWall],
            keepSelected: false,
            showTulip: false,
        });

        //Execute parent function
        super.execute();
    }
}
