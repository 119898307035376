import { Command, CommandEnum, AddEntitiesCommand, AddComponentCommand, DeleteEntitiesCommand, DeleteComponentCommand } from "./CommandModule";
import { Component, Coating, CoatingArea, Credence, ComponentConstants, Entity, Room, RenderCamera, SceneConstants } from "../SavaneJS";

// Constructor will take 2 parameters for the solution to apply, the room on which to apply the solution and the solution in itself
// The entities array parameter contains a structure with entities elements to add with their parent in the scene hierarchy
// The coatings array parameter contains a structure with the wall id to attach it to and the coating component
export class ApplySmartRestylerSolutionCommand extends Command {
    private _smartDesignedRoom: Room;
    private _smartDesignerParentEntityId: Array<number>;
    private _addedEntities: Array<any> = [];
    private _addedCoatings: Array<any> = [];
    // This will be used to save the commands created in the execute method so we can undo them in the undo method
    private _subCommands: Array<Command> = [];
    
    constructor(smartDesignedRoom: Room, smartDesignerParentEntityId: Array<number>, addedEntities: Array<any>, addedCoatings: Array<any>, cleanAll: boolean) {
        super();
        // Store all parameters
        this._smartDesignedRoom = smartDesignedRoom;
        this._smartDesignerParentEntityId = smartDesignerParentEntityId;
        for (let i = 0; i < addedEntities.length; i++) {
            this._addedEntities = this._addedEntities.concat(addedEntities[i].filter((e) => e.entity.entityType != SceneConstants.EntityType.Floor));
        }
        for (let i = 0; i < addedCoatings.length; i++) {
            this._addedCoatings = this._addedCoatings.concat(addedCoatings[i]);
        }

        if (cleanAll) {
            // clean the whole room for a restyle
            this.clearAllForRoom();
        } else {
            // First clear room from any arrangement object and group coming from the smartDesigner root entities
            this.clearObjectsFromRoom();
            // Second clear cameras from room
            this.clearCamerasForRoom();
        }
        // Fill the room with smartDesigner solution
        this.fillRoom();
    }

    // Command name returned from a global enum
    name(): string {
        return CommandEnum.ApplySmartRestylerSolutionCommand;
    }

    // Datas that allows the command to be executed
    execDatas(): any {
        return {
            room: this._smartDesignedRoom,
            entities: this._addedEntities,
            coatings: this._addedCoatings,
        };
    }

    needsRefreshHull(): boolean {
        for (let i = 0; i < this._subCommands.length; i++) {
            if (this._subCommands[i].needsRefreshHull()) {
                return true;
            }
        }

        return false;
    }

    // Clear the room
    clearAllForRoom() {
        // Arrangements to remove
        let entitiesToRemove = this._smartDesignedRoom.children;
        // try to get box in parent just in case
        if (this._smartDesignedRoom.parent) {
            let boxes = this._addedEntities.filter((e) => e.entity.isGeometryPrimitiveEntity()).map((e) => e.entity.id);
            entitiesToRemove = entitiesToRemove.concat(this._smartDesignedRoom.parent.children.filter((c) => boxes.includes(c.id)));
        }

        if (entitiesToRemove.length > 0) {
            // And store the command into the commands array for undo/redo
            this._subCommands.push(new DeleteEntitiesCommand(entitiesToRemove));
        }
    }

    // Clear the room and save (yes/no) its content
    clearObjectsFromRoom() {
        let i;
        // Arrangements to remove
        let entitiesToRemove = [];

        for (i = 0; i < this._addedEntities.length; i++) {
            if (this._addedEntities[i].entity.isWorktopEntity()) {
                entitiesToRemove.push(this._addedEntities[i].entity);
            }
        }
        // Get the entities to remove from room
        for (i = 0; i < this._smartDesignedRoom.children.length; i++) {
            let entity = this._smartDesignedRoom.children[i];
            if (entity.isArrangementObjectEntity() || entity.isArrangementGroupEntity() || entity.isWorktopEntity() || entity.isGeometryPrimitiveEntity()) {
                for (let j = 0; j < this._smartDesignerParentEntityId.length; j++) {
                    // Check that the entity was generated by the smartDesignerParent entity
                    if ((entity as any).smartDesignerParentId === this._smartDesignerParentEntityId[j]) {
                        entitiesToRemove.push(entity);
                        break;
                    }
                }
            }
        }

        if (entitiesToRemove.length > 0) {
            // And store the command into the commands array for undo/redo
            this._subCommands.push(new DeleteEntitiesCommand(entitiesToRemove));
        }
    }

    // Clear the cameras coming from smartDesigner for a room
    clearCamerasForRoom() {
        // Get cameras from floor
        let cameras = this._smartDesignedRoom.floor.renderCameras;
        let roomCameras = this._smartDesignedRoom.children.filter((child) => child.isRenderCameraEntity()) as Array<RenderCamera>;
        cameras = cameras.concat(roomCameras);
        // Arrangements to remove
        let entitiesToRemove = [];

        // Parse all cameras searching the ones that comes from a smart design for this room
        for (let i = 0; i < cameras.length; i++) {
            if ((cameras[i] as any).smartDesignerParentId === this._smartDesignedRoom.id) {
                entitiesToRemove.push(cameras[i]);
            }
        }

        if (entitiesToRemove.length > 0) {
            // And store the command into the commands array for undo/redo
            this._subCommands.push(new DeleteEntitiesCommand(entitiesToRemove));
        }
    }

    // Clean the coatings from an entity if it is of hangtype hangType
    cleanCoatings(entity: Entity, coating: Component) {
        let i;

        if (coating.componentType == ComponentConstants.ComponentType.Coating) {
            let components = entity.getComponents(ComponentConstants.ComponentType.Coating) as Array<Coating>;
            // Parse all coatings found
            for (i = 0; i < components.length; i++) {
                // Remove only the correct direction of the coating, don't remove the other side
                if (components[i].hangType === (coating as Coating).hangType && components[i].componentType == coating.componentType) {
                    // And store the command into the commands array for undo/redo
                    this._subCommands.push(new DeleteComponentCommand(components[i], false));
                }
            }
        }

        if (coating.componentType == ComponentConstants.ComponentType.Credence) {
            let components = entity.getComponents(ComponentConstants.ComponentType.Credence) as Array<Credence>;
            // Parse all coatings found
            for (i = 0; i < components.length; i++) {
                // Remove only the correct direction of the coating, don't remove the other side
                if (components[i].hangType === (coating as Credence).hangType && components[i].componentType == coating.componentType) {
                    // And store the command into the commands array for undo/redo
                    this._subCommands.push(new DeleteComponentCommand(components[i], false));
                }
            }
        }

        if (coating.componentType == ComponentConstants.ComponentType.CoatingArea) {
            let coatingArea = entity.getComponents(ComponentConstants.ComponentType.CoatingArea);
            for (i = 0; i < coatingArea.length; i++) {
                if (((coatingArea[i] as CoatingArea).isDirectSide && (coating as CoatingArea).hangType == Coating.HangType.wallDirect) || (!(coatingArea[i] as CoatingArea).isDirectSide && (coating as CoatingArea).hangType == Coating.HangType.wallUndirect)) {
                    this._subCommands.push(new DeleteComponentCommand(coatingArea[i], false));
                }
            }
        }
    }

    replaceCoatings(entity: Entity, newCoating: Component) {
        let coatings = entity.getComponents(newCoating.componentType) as Array<any>;
        for (let i = 0; i < coatings.length; i++) {
            if (coatings[i].id == newCoating.id) {
                this._subCommands.push(new DeleteComponentCommand(coatings[i], false));
                this._subCommands.push(new AddComponentCommand(newCoating, entity, false, false));
            }
        }
    }

    // Fill the room with the smart designer solution the object was created with
    fillRoom() {
        // And store the command into the commands array for undo
        this._subCommands.push(new AddEntitiesCommand(this._addedEntities, false, undefined, undefined));

        // Parse all coatings and assign them to their wall
        for (let i = 0; i < this._addedCoatings.length; i++) {
            // get the entity
            let entityToCoat = null;
            if (this._addedCoatings[i].forEntityId == this._smartDesignedRoom.floor.id) {
                entityToCoat = this._smartDesignedRoom.floor;
            } else {
                entityToCoat = this._smartDesignedRoom.floor.getDeepChild(this._addedCoatings[i].forEntityId);
            }

            if (entityToCoat) {
                switch (entityToCoat.entityType) {
                    case SceneConstants.EntityType.Floor:
                        this.replaceCoatings(entityToCoat, this._addedCoatings[i].component);
                        break;

                    case SceneConstants.EntityType.Wall:
                        // Clean existing coatings
                        this.cleanCoatings(entityToCoat, this._addedCoatings[i].component);
                        // And store the command into the commands array for undo/redo
                        this._subCommands.push(new AddComponentCommand(this._addedCoatings[i].component, entityToCoat, false, false));
                        break;

                    case SceneConstants.EntityType.Room:
                        this.cleanCoatings(entityToCoat, this._addedCoatings[i].component);
                        // And store the command into the commands array for undo/redo
                        this._subCommands.push(new AddComponentCommand(this._addedCoatings[i].component, this._smartDesignedRoom, false, false));
                        break;

                    case SceneConstants.EntityType.WorkTop:
                        this.cleanCoatings(entityToCoat, this._addedCoatings[i].component);
                        this._subCommands.push(new AddComponentCommand(this._addedCoatings[i].component, entityToCoat, false, false));
                        break;
                }
            }
        }
    }

    // Undo the current command
    undo() {
        // Undo all commands
        for (let i = this._subCommands.length - 1; i >= 0; i--) {
            this._subCommands[i].undo();
        }

        // Heavy change info that the plan has changed
        // Check if necessary
        //eventsManager.instance.dispatch(Events.PLAN_STATE_CHANGED, ActionStateEnum.IdleDecoration);
        //Savane.KitchenTool.KitchenManager.setFurnitureFinishes(this._smartDesignedRoom.floor);
        // Execute parent function
        super.undo();
    }

    // Execute current command (redo)
    execute() {
        // Redo all commands
        for (let i = 0; i < this._subCommands.length; i++) {
            this._subCommands[i].execute();
        }

        // Heavy change info that the plan has changed
        // Check if necessary
        // eventsManager.instance.dispatch(Savane.Events.PLAN_STATE_CHANGED, ActionStateEnum.IdleDecoration);
        //Savane.KitchenTool.KitchenManager.setFurnitureFinishes(this._smartDesignedRoom.floor);
        //Execute parent function
        super.execute();
    }
}
