import { ArrangementObject } from "../../scene/ArrangementObject";
import { Component } from "../../components/Component";
import { ComponentConstants } from "../../components/ComponentConstants";
import { AMFurnitureFinishesReference } from "./AMFurnitureFinishesReference";

export class FurnitureFinishes extends Component {

    public plinths: any;
    public fillers: any;
    public endPanels: any;

    constructor() {
        super();
        this.plinths = {
            front: null,
            left: null,
            back: null,
            right: null
        };
        //FIXME May be store entityType to be more generic (but more complex)
        this.fillers = {
            front: null,
            front_wall: false,
            left: null,
            left_wall: false,
            back: null,
            back_wall: false,
            right: null,
            right_wall: false
        };
        this.endPanels = {
            front: null,
            left: null,
            back: null,
            right: null
        };

        this._isUnique = true;
    }

    clone() {
        let finishes = new FurnitureFinishes();
        finishes.plinths.front = this.plinths.front;
        finishes.plinths.left = this.plinths.left;
        finishes.plinths.back = this.plinths.back;
        finishes.plinths.right = this.plinths.right;

        finishes.fillers.front = this.fillers.front;
        finishes.fillers.left = this.fillers.left;
        finishes.fillers.back = this.fillers.back;
        finishes.fillers.right = this.fillers.right;

        finishes.endPanels.front = this.endPanels.front;
        finishes.endPanels.left = this.endPanels.left;
        finishes.endPanels.back = this.endPanels.back;
        finishes.endPanels.right = this.endPanels.right;
        return finishes;
    }

    /**
     * reinit the component and delete children of the entity
     */
    reInit() {
        if (this.plinths.front !== null) {
            this.plinths.front.deleteFromParent();
            this.plinths.front = null;
        }
        if (this.plinths.left !== null) {
            this.plinths.left.deleteFromParent();
            this.plinths.left = null;
        }
        if (this.plinths.back !== null) {
            this.plinths.back.deleteFromParent();
            this.plinths.back = null;
        }
        if (this.plinths.right !== null) {
            this.plinths.right.deleteFromParent();
            this.plinths.right = null;
        }
        if (this.endPanels.front !== null) {
            this.endPanels.front.deleteFromParent();
            this.endPanels.front = null;
        }
        if (this.endPanels.left !== null) {
            this.endPanels.left.deleteFromParent();
            this.endPanels.left = null;
        }
        if (this.endPanels.back !== null) {
            this.endPanels.back.deleteFromParent();
            this.endPanels.back = null;
        }
        if (this.endPanels.right !== null) {
            this.endPanels.right.deleteFromParent();
            this.endPanels.right = null;
        }
        if (this.fillers.front !== null) {
            this.fillers.front.deleteFromParent();
            this.fillers.front = null;
            this.fillers.front_wall = false;
        }
        if (this.fillers.left !== null) {
            this.fillers.left.deleteFromParent();
            this.fillers.left = null;
            this.fillers.left_wall = false;
        }
        if (this.fillers.back !== null) {
            this.fillers.back.deleteFromParent();
            this.fillers.back = null;
            this.fillers.back_wall = false;
        }
        if (this.fillers.right !== null) {
            this.fillers.right.deleteFromParent();
            this.fillers.right = null;
            this.fillers.right_wall = false;
        }

        // Cleanup other things if necessary
        let AmRef = this.entity.getComponents(ComponentConstants.ComponentType.AMFurnitureFinishesReference);

        if (AmRef.length === 0) {
            return;
        }

        let amFurnitureFinishesReference = AmRef[0] as AMFurnitureFinishesReference;
        for (let i = this.entity.children.length - 1; i >= 0; i--) {
            if (this.entity.children[i].isArrangementObjectEntity()) {
                let arrangementObject = this.entity.children[i] as ArrangementObject;
                if (arrangementObject.objectType === amFurnitureFinishesReference.plinthData.objectType ||
                    arrangementObject.objectType === amFurnitureFinishesReference.endPanelData.objectType ||
                    arrangementObject.objectType === amFurnitureFinishesReference.fillerData.objectType) {
                        arrangementObject.deleteFromParent();
                }
            }
        }
    }

    get componentType() {
        return ComponentConstants.ComponentType.FurnitureFinishes;
    }
}
