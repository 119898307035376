import { Command, CommandEnum } from "./CommandModule";
import { Events } from "../events";
import { eventsManager } from "../managers/EventsManager";
import { Entity, Floor, Room, Wall, SceneConstants, math } from '../SavaneJS';

export class ScaleFloorCommand extends Command {
    // Store scaling factor
    private _factor: number;
    // And command floor
    private _floor: Floor;

    constructor(entity: Entity, isUpScale: boolean, scale: number, floor: Floor) {
        super();
        // Default scale factor
        this._factor = 1.0; // Default no scale

        // Entity will define the type of scale
        if (entity === null) {
            // No entity will use default scale value (typically floor)
            this._factor = 1.05;
            if (scale !== undefined) {
                this._factor = scale;
            }
        } else {
            switch (entity.entityType) {
                //Room entity area will be increase of 0.25m²/250cm2/250000mm2
                case SceneConstants.EntityType.Room:
                    let squaredFactor = 1.0 + 250000 / (entity as Room).area;
                    this._factor = Math.sqrt(squaredFactor);
                    break;

                // Wall entity length will be increase of 1cm
                case SceneConstants.EntityType.Wall:
                    this._factor = 1.0 + 10.0 / (entity as Wall).length;
                    break;

                // No scale for other entities
                default:
                    this._factor = 1.0;
            }
        }

        // If it's a down scale invert scale
        if (!isUpScale) {
            this._factor = 1 / this._factor;
        }
        this._floor = floor;
    }

    // Command name returned from a global enum
    name(): string {
        return CommandEnum.ScaleFloorCommand;
    }

    // Datas that allows the command to be executed
    execDatas(): any {
        return {
            factor: this._factor,
            floor: this._floor
        };
    }

    // Undo the current command
    undo() {
        // Apply inverse scale of the scale used for the execute method
        this.applyScale(1.0 / this._factor);

        super.undo();

        eventsManager.instance.dispatch(Events.PROJECT_RELOAD);
    }

    // Execute current command (redo), call the applyScale method with the scale factor initialised at start
    execute() {
        this.applyScale(this._factor);

        super.execute();

        eventsManager.instance.dispatch(Events.PROJECT_RELOAD);
    }

    // Method that scles the entire floor
    applyScale(scale: number) {
        // Loop variables
        let i, j;
        // Floor bounding box to recenter all entities
        let floorBBox = this._floor.boundingBox;
        // Center of the bounding box
        let center = math.vec3.create();

        // Retrieve center of bounding box
        math.vec3.set(center, floorBBox[0][0] + (floorBBox[3][0] - floorBBox[0][0]) * 0.5, floorBBox[0][1] + (floorBBox[3][1] - floorBBox[0][1]) * 0.5, floorBBox[0][2] + (floorBBox[3][2] - floorBBox[0][2]) * 0.5);

        // Scale walls from center
        for (i = 0; i < this._floor.walls.length; i++) {
            // Current wall to scale
            let wall = this._floor.walls[i];
            // Begin and end coordinates
            let begin = wall.begin;
            let end = wall.end;
            // Find dist from center to begin
            let distanceBegin = math.vec3.create();

            math.vec3.set(distanceBegin, begin[0] - center[0], begin[1] - center[1], begin[2] - center[2]);
            // Find dist from center to end
            let distanceEnd = math.vec3.create();
            math.vec3.set(distanceEnd, end[0] - center[0], end[1] - center[1], end[2] - center[2]);

            // Multiply distance from factor
            distanceBegin[0] = distanceBegin[0] * scale;
            distanceBegin[1] = distanceBegin[1] * scale;
            // Don't scale Y
            // distanceBegin[2]  = distanceBegin[2] * scale;
            distanceEnd[0] = distanceEnd[0] * scale;
            distanceEnd[1] = distanceEnd[1] * scale;
            // Don't scale Y
            // distanceEnd[2]    = distanceEnd[2] * scale;

            // Apply dist to create new begin and end
            let newBegin = math.vec3.create();
            math.vec3.add(newBegin, center, distanceBegin);
            let newEnd = math.vec3.create();
            math.vec3.add(newEnd, center, distanceEnd);

            // Set new value for point of wall
            wall.begin = newBegin;
            wall.end = newEnd;

            // Redraw Node
            eventsManager.instance.dispatch(Events.REDRAW_GRAPHICAL_ENTITY, {
                entity: wall,
            });

            // Adapt joineries
            for (j = 0; j < wall.joineries.length; j++) {
                //Scale position and size of each joinery
                wall.joineries[j].length = wall.joineries[j].length * scale;
                //Multiply distance from begin
                let positionJoinery = wall.joineries[j].transform.localPosition;
                positionJoinery[0] *= scale;
                positionJoinery[1] *= scale;
                // Don't scale Y
                // positionJoinery[2] *= scale;
                wall.joineries[j].transform.localPosition = positionJoinery;
                // Redraw Node
                eventsManager.instance.dispatch(Events.REDRAW_GRAPHICAL_ENTITY, {
                    entity: wall.joineries[j],
                });
            }
        }

        // Redraw rooms
        for (i = 0; i < this._floor.rooms.length; i++) {
            // GameObjectManager.drawRoom(this.floor.rooms[i], false, null, true);
            eventsManager.instance.dispatch(Events.REDRAW_GRAPHICAL_ROOM, {
                entity: this._floor.rooms[i],
            });
        }

        // Scale tech elements from center
        for (i = 0; i < this._floor.technicalElementsWithStaircases.length; i++) {
            let techElem = this._floor.technicalElementsWithStaircases[i];

            this.scaleEntity(techElem, center, scale);

            // Scale dimensions and check Min/Max values
            techElem.width = techElem.width * scale;
            if (techElem.width < SceneConstants.TechElementMinWidth[techElem.objectId]) {
                techElem.width = SceneConstants.TechElementMinWidth[techElem.objectId];
            }
            if (techElem.width > SceneConstants.TechElementMaxWidth[techElem.objectId]) {
                techElem.width = SceneConstants.TechElementMaxWidth[techElem.objectId];
            }
            techElem.length = techElem.length * scale;
            if (techElem.length < SceneConstants.TechElementMinLength[techElem.objectId]) {
                techElem.length = SceneConstants.TechElementMinLength[techElem.objectId];
            }
            if (techElem.length > SceneConstants.TechElementMaxLength[techElem.objectId]) {
                techElem.length = SceneConstants.TechElementMaxLength[techElem.objectId];
            }
        }

        // Scale arrangementObjects from center
        for (i = 0; i < this._floor.arrangementObjects.length; i++) {
            if (!this._floor.arrangementObjects[i].parent.isArrangementObjectEntity() && !this._floor.arrangementObjects[i].parent.isArrangementGroupEntity() && !this._floor.arrangementObjects[i].parent.isArrangementZoneEntity()) {
                this.scaleEntity(this._floor.arrangementObjects[i], center, scale);
            }
        }
        // Scale arrangementGroups from center
        for (i = 0; i < this._floor.arrangementGroups.length; i++) {
            if (!this._floor.arrangementGroups[i].parent.isArrangementObjectEntity() && !this._floor.arrangementGroups[i].parent.isArrangementGroupEntity() && !this._floor.arrangementGroups[i].parent.isArrangementZoneEntity()) {
                this.scaleEntity(this._floor.arrangementGroups[i], center, scale);
            }
        }
        // Scale renderCameras from center
        for (i = 0; i < this._floor.renderCameras.length; i++) {
            this.scaleEntity(this._floor.renderCameras[i], center, scale);
        }
        // Scale geometryPrimitives from center
        for (i = 0; i < this._floor.geometryPrimitives.length; i++) {
            this.scaleEntity(this._floor.geometryPrimitives[i], center, scale);
        }
    }

    // Perform the scale of an entity thanks to the floor center
    scaleEntity(entity: Entity, center: math.vec3, scale: number) {
        // Scale position from center (like walls)
        let positionEntity = entity.position;
        //Find dist from center to begin
        let distance = math.vec3.create();
        math.vec3.set(distance, positionEntity[0] - center[0], positionEntity[1] - center[1], positionEntity[2] - center[2]);

        // Multiply distance from factor
        distance[0] = distance[0] * scale;
        distance[1] = distance[1] * scale;
        // Don't scale Y
        // distance[2] = distance[2] * scale;

        // Apply dist to create new begin and end
        let newPosition = math.vec3.create();
        math.vec3.add(newPosition, center, distance);
        // Set new value for tech element position
        entity.position = newPosition;

        // Redraw Node
        eventsManager.instance.dispatch(Events.REDRAW_GRAPHICAL_ENTITY, {
            entity: entity,
        });
    }
}
