import { SceneConstants } from "./../SceneConstants";
import { math } from "./../Transform";
import { DoubleQuarterStaircase } from "./DoubleQuarterStaircase";

// A quarter turn is a double quarter turn specialised class
export class QuarterStaircase extends DoubleQuarterStaircase {
    // Class constructor
    constructor(id: number) {
        // Init mother class
        super(id);
        // Put object id instead of the double quarter turn staircase
        this._objectId = SceneConstants.StaircaseType.quarterTurn;
    }

    // Returns the bounding of of the object with all points (i.e. a polygon and not a box)
    get realBoundingBox() : Array<math.vec3> {
        // Point array to return
        let points = [];
        // The bounding box will be composed of 6 points
        let point1 = math.vec3.create();
        let point2 = math.vec3.create();
        let point3 = math.vec3.create();
        let point4 = math.vec3.create();
        let point5 = math.vec3.create();
        let point6 = math.vec3.create();
        // Step width that will give the width of the L
        let partLength = this.stepWidth;

        var sin = Math.sin(this.angle);
        var cos = Math.cos(this.angle);
        var w = this.length / 2;
        var h = this.width / 2;

        // Compute the 6 points of the bounding box
        math.vec3.set(point1, w * cos - -h * sin, w * sin + -h * cos, 0);
        math.vec3.set(point2, w * cos - h * sin, w * sin + h * cos, 0);
        math.vec3.set(point3, (w - partLength) * cos - h * sin, (w - partLength) * sin + h * cos, 0);
        math.vec3.set(point4, (w - partLength) * cos - (-h + partLength) * sin, (w - partLength) * sin + (-h + partLength) * cos, 0);
        math.vec3.set(point5, -w * cos - (-h + partLength) * sin, -w * sin + (-h + partLength) * cos, 0);
        math.vec3.set(point6, -w * cos - -h * sin, -w * sin + -h * cos, 0);

        // Push them into the points to return array
        points.push(point1);
        points.push(point2);
        points.push(point3);
        points.push(point4);
        points.push(point5);
        points.push(point6);

        // Translate the box with the stair position
        for (let i = 0; i < points.length; ++i) {
            points[i][0] += this.position[0];
            points[i][1] += this.position[1];
        }

        // Return array of points
        return points;
    }

    // Cap quarter turn staircase field values
    addToCappedProperty(property: string, value: any) : number {
        // 0 = yes/no active, 1 = - inactive, 2 = + inactive, 3 = yes/no inactive
        let capped = 0;

        // Values can't be negative (forbid -)
        if (this[property] + value <= 0) {
            this[property] = 0;
            capped = 1;
        }

        // stepWidth can't be bigger than stair width (forbid +)
        if (property === "stepWidth") {
            if (this[property] + value >= this.width) {
                this[property] = this.width;
                capped = 2;
            }

            if (this[property] + value >= this.length) {
                this[property] = this.length;
                capped = 2;
            }
        }

        // If we can add the value capped is still 0, then add it
        if (capped === 0) {
            this[property] += value;
        }

        // Return capped value to activate/deactivate + and - buttons
        return capped;
    }
}
