import { Coating, ComponentConstants } from '../SavaneJS';
import { Component } from './Component';

export class CustomCoating extends Component {
    private _name: string;
    private _coating: Coating;

    constructor(n: string, c: Coating) {
        super();
        this._name = n;
        this._coating = c;
    }

    get name(): string {
        return this._name;
    }

    get coating(): Coating {
        return this._coating;
    }

    set coating(c: Coating) {
        this._coating = c;
    }

    get componentType(): ComponentConstants.ComponentType {
        return ComponentConstants.ComponentType.CustomCoating;
    }
}
