
import { SavaneCookie } from '../../utils/SavaneCookie';
import { TemporaryJoinery } from '../temporary/TemporaryJoinery';
import { JoineryType, SceneConstants, math } from '../../SavaneJS';
import { Joinery } from './Joinery';

/**
 * Joinery  of type : Niche
 */
export class Niche extends Joinery {
    // Default thickness (depth) of the niche
    private _thickness: number = 50;

    constructor(joineryType: SceneConstants.JoineryType, position: math.vec3) {
        super(joineryType, position);

        this.addComponent(new JoineryType(SavaneCookie.getCookie("Rhinov-Savane-DefaultJoineryModelNiche", SceneConstants.DefaultJoineryType.niche), undefined, undefined));
    }

    // Getters and setters for nche thickness
    get thickness(): number {
        if (this.temporary === null) {
            return this._thickness;
        }
        else {
            return (this.temporary as TemporaryJoinery).thickness;
        }
    }

    set thickness(t: number) {
        if (this.temporary === null) {
            this._thickness = t;
        }
        else {
            (this.temporary as TemporaryJoinery).thickness = t;
        }
    }

    // Called when a property is changed to verify max and min boundaries or to perform computations before final assignment
    addToCappedProperty(property: string, value: any) : number {
        let capped = 0;

        if (property === "thickness") {
            if (this[property] + value <= 0) {
                this[property] = 0;
                capped = 1;
            }
            else {
                if (this[property] + value >= this.wall.thickness) {
                    this[property] = this.wall.thickness;
                    capped = 2;
                }
                else {
                    this[property] += value;
                }
            }
        }
        else {
            return super.addToCappedProperty(property, value);
        }
        return capped;
    }
}
