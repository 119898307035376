import { ComponentConstants } from "../../components/ComponentConstants";

export class Exporter {
    constructor() {}

    static exportComponent(component) {
        let jsonComponent = {
            componentType: component.componentType,
            plinths: null,
            fillers: null,
            endPanels: null,
            fillerData: null,
            endPanelData: null,
            plinthData: null
        };
        switch (component.componentType) {
            case ComponentConstants.ComponentType.FurnitureFinishes:
                jsonComponent.plinths = {
                    front: component.plinths.front !== null ? component.plinths.front.id : null,
                    left: component.plinths.left !== null ? component.plinths.left.id : null,
                    back: component.plinths.back !== null ? component.plinths.back.id : null,
                    right: component.plinths.right !== null ? component.plinths.right.id : null,
                };
                jsonComponent.fillers = {
                    front: component.fillers.front !== null ? component.fillers.front.id : null,
                    front_wall: component.fillers.front_wall,
                    left: component.fillers.left !== null ? component.fillers.left.id : null,
                    left_wall: component.fillers.left_wall,
                    back: component.fillers.back !== null ? component.fillers.back.id : null,
                    back_wall: component.fillers.back_wall,
                    right: component.fillers.right !== null ? component.fillers.right.id : null,
                    right_wall: component.fillers.right_wall,
                };
                jsonComponent.endPanels = {
                    front: component.endPanels.front !== null ? component.endPanels.front.id : null,
                    left: component.endPanels.left !== null ? component.endPanels.left.id : null,
                    back: component.endPanels.back !== null ? component.endPanels.back.id : null,
                    right: component.endPanels.right !== null ? component.endPanels.right.id : null,
                };
                return jsonComponent;
            case ComponentConstants.ComponentType.AMFurnitureFinishesReference:
                jsonComponent.fillerData = component.fillerData;
                jsonComponent.endPanelData = component.endPanelData;
                jsonComponent.plinthData = component.plinthData;
                return jsonComponent;
            default:
                return null;
        }
    }

    static exportComponentForHull(component) {
        switch (component.componentType) {
            case ComponentConstants.ComponentType.FurnitureFinishes:
                let jsonComponent = {
                    componentType: component.componentType,
                    plinths: null,
                    fillers: null,
                    endPanels: null
                };
                jsonComponent.plinths = {
                    front: component.plinths.front !== null,
                    left: component.plinths.left !== null,
                    back: component.plinths.back !== null,
                    right: component.plinths.right !== null,
                };

                var frontLength = 0;
                if (component.fillers.front !== null) {
                    frontLength = component.fillers.front.length;
                    if (Number.isInteger(frontLength)) {
                        frontLength += 0.00001;
                    }
                }
                var leftLength = 0;
                if (component.fillers.left !== null) {
                    leftLength = component.fillers.left.length;
                    if (Number.isInteger(leftLength)) {
                        leftLength += 0.00001;
                    }
                }
                var backLength = 0;
                if (component.fillers.back !== null) {
                    backLength = component.fillers.back.length;
                    if (Number.isInteger(backLength)) {
                        backLength += 0.00001;
                    }
                }
                var rightLength = 0;
                if (component.fillers.right !== null) {
                    rightLength = component.fillers.right.length;
                    if (Number.isInteger(rightLength)) {
                        rightLength += 0.00001;
                    }
                }

                jsonComponent.fillers = {
                    front: frontLength,
                    front_wall: component.fillers.front_wall,
                    left: leftLength,
                    left_wall: component.fillers.left_wall,
                    back: backLength,
                    back_wall: component.fillers.back_wall,
                    right: rightLength,
                    right_wall: component.fillers.right_wall,
                };
                jsonComponent.endPanels = {
                    front: component.endPanels.front !== null,
                    left: component.endPanels.left !== null,
                    back: component.endPanels.back !== null,
                    right: component.endPanels.right !== null,
                };
                return jsonComponent;
            default:
                return null;
        }
    }
}
