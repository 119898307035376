import { AddArea, Area, ArrangementGroup, ArrangementObject, ArrangementZone, BoundingBox, CeilingBox, CircularStaircase, Coating, CoatingArea, Comment, Component, CorniceType, Credence, CustomCoating, CutArea, Cylinder, DepthBoundingBox, DomeLight, DoubleQuarterStaircase, Floor, FloorCoatingArea, Functionality, FunctionalityChip, GeometryPrimitive, Hood, Joinery, JoineryType, math, RenderCamera, RendererTopView, Room, RoomQuotation, SketchBlock, Sphere, Staircase, Sun, TechnicalElement, TechnicalElementType, TemplateImage, Wall, WallQuotation, WallType, WaterSource, WorkTop, World } from "../SavaneJS";
import { VersionHandler } from "./VersionHandler";
import { SavaneConstants } from "./SavaneConstants";
import { SceneConstants } from "../scene/SceneConstants";
import { ComponentConstants } from "../components/ComponentConstants";
import { roomManager } from "../managers/RoomManager";
import { Entity } from "../scene/Entity"
import { UserPicture } from "../scene/UserPicture";
import { PlainData } from "../components/PlainData";

/**
 * Filter structure for serialization process
 */
export class JSONSerializer {

    private static _externalSerializeComponents = new Array();

    constructor() {}

    static get externalSerializeComponents() {
        return JSONSerializer._externalSerializeComponents;
    }

    static set externalSerializeComponents(funcs) {
        JSONSerializer._externalSerializeComponents = funcs;
    }
    /**
     * Serialize a layer
     **/
    static serializeLayer(layer) {
        let jsonObject = {
            name: String(),
            entities: Array<Entity>()
        };
        jsonObject.name = layer.name;
        jsonObject.entities = new Array<Entity>();
        for (let i = 0; i < layer.count; i++) {
            jsonObject.entities.push(layer.list[i].id);
        }
        return jsonObject;
    }

    /**
     * Serialize an entity filtering the entitytypes and componenttypes we do not want (add the formatVersion)
     * @param entity
     * @param version
     * @returns {*}
     */
    static serializeEntityWithFilters(entity: Entity, filterEntityTypes: Array<SceneConstants.EntityType>, filterComponentTypes: Array<ComponentConstants.ComponentType>, filterStats: boolean = false, version: number = SavaneConstants.formatVersion) : any {
        // Protection to make sure all non roomed walls are in their correct room destination (shoudln't be necessary but we have examples showing sometimes non roomed walls aren't attached to their correct room)
        if (entity.entityType === SceneConstants.EntityType.Scene) {
            for (var i = 0; i < entity.children.length; i++) {
                if (entity.children[i].entityType === SceneConstants.EntityType.Floor) {
                    roomManager.manageNonRoomedWalls(entity.children[i] as Floor);
                }
            }
        }
        // End of protection

        let jsonObject = JSONSerializer._serializeEntity(entity, filterEntityTypes, filterComponentTypes, [], filterStats);
        jsonObject.formatVersion = version;
        if (jsonObject.formatVersion !== SavaneConstants.formatVersion) {
            jsonObject = VersionHandler.convertToVersion(jsonObject, version);
        }

        return jsonObject;
    }

    /**
     * Serialize an entity filtering the entitytypes and componenttypes we do not want (add the formatVersion)
     * @param entity
     * @param version
     * @returns {*}
     */
    static serializeEntityWithFiltersAndObjectTypes(entity: Entity, filterEntityTypes: Array<SceneConstants.EntityType>, filterComponentTypes: Array<ComponentConstants.ComponentType>, objectTypesToKeep: Array<string>, version: number = SavaneConstants.formatVersion) : any {
        // Protection to make sure all non roomed walls are in their correct room destination (shoudln't be necessary but we have examples showing sometimes non roomed walls aren't attached to their correct room)
        if (entity.entityType === SceneConstants.EntityType.Scene) {
            for (var i = 0; i < entity.children.length; i++) {
                if (entity.children[i].entityType === SceneConstants.EntityType.Floor) {
                    roomManager.manageNonRoomedWalls(entity.children[i] as Floor);
                }
            }
        }
        // End of protection

        let jsonObject = JSONSerializer._serializeEntity(entity, filterEntityTypes, filterComponentTypes, objectTypesToKeep);
        jsonObject.formatVersion = version;
        if (jsonObject.formatVersion !== SavaneConstants.formatVersion) {
            jsonObject = VersionHandler.convertToVersion(jsonObject, version);
        }

        return jsonObject;
    }

    /**
     * Serialize an entity (add the formatVersion)
     * @param entity
     * @param version
     * @returns {*}
     */
    static serializeEntity(entity: Entity, version: number = SavaneConstants.formatVersion) {
        // Protection to make sure all non roomed walls are in their correct room destination (shoudln't be necessary but we have examples showing sometimes non roomed walls aren't attached to their correct room)
        if (entity.entityType === SceneConstants.EntityType.Scene) {
            for (var i = 0; i < entity.children.length; i++) {
                if (entity.children[i].entityType === SceneConstants.EntityType.Floor) {
                    roomManager.manageNonRoomedWalls(entity.children[i] as Floor);
                }
            }
        }
        // End of protection

        let jsonObject = JSONSerializer._serializeEntity(entity, [], [], []);
        jsonObject.formatVersion = version;
        if (jsonObject.formatVersion !== SavaneConstants.formatVersion) {
            jsonObject = VersionHandler.convertToVersion(jsonObject, version);
        }

        return jsonObject;
    }

    /**
     * Serialize an entity
     * @param entity
     * @returns {*}
     * @private
     */
    static _serializeEntity(entity: Entity, filterEntityTypes: Array<SceneConstants.EntityType>, filterComponentTypes: Array<ComponentConstants.ComponentType>, objectTypesToKeep: Array<string>, filterStats: boolean = false) : any {
        // If entity within the entities to filter, skip it
        if (filterEntityTypes.indexOf(entity.entityType) !== -1) {
            return null;
        }

        // Reset camera pitch if camera and camera is a panoramic
        if (entity.entityType === SceneConstants.EntityType.RenderCamera) {
            let Tentity = entity as RenderCamera;
            if (Tentity.cameraType === SceneConstants.CameraType.Panoramic) {
                Tentity.pitch = 0;
            }
        }

        let entityJSON: any = {
            id: entity.id,
            entityType: entity.entityType,
            name: entity.name,
            locked: entity.locked,
            holdout: entity.holdout,
            hideAtRendering: entity.hideAtRendering,
            transform: {
                localMatrix: entity.transform.localMatrix,
            },
            components: [],
            children: []
        };

        // Protection against no name
        if (entityJSON.name === undefined) {
            entityJSON.name = null;
        }

        //Serialize specific data
        switch (entity.entityType) {
            case SceneConstants.EntityType.World:
                {
                let Tentity = entity as World;
                    if (!filterStats) {
                        entityJSON.activeDrawTime = Tentity._activeDrawTime;
                        entityJSON.idleDrawTime = Tentity._idleDrawTime;
                        entityJSON.idleDrawDurations = Tentity._idleDrawDurations;

                        entityJSON.activeDrawCRTime = Tentity._activeDrawCRTime;
                        entityJSON.idleDrawCRTime = Tentity._idleDrawCRTime;
                        entityJSON.idleDrawCRDurations = Tentity._idleDrawCRDurations;

                        entityJSON.activeDrawCRVisioTime = Tentity._activeDrawCRVisioTime;
                        entityJSON.idleDrawCRVisioTime = Tentity._idleDrawCRVisioTime;
                        entityJSON.idleDrawCRVisioDurations = Tentity._idleDrawCRVisioDurations;

                        entityJSON.activeConstructionTime = Tentity._activeConstructionTime;
                        entityJSON.idleConstructionTime = Tentity._idleConstructionTime;
                        entityJSON.idleConstructionDurations = Tentity._idleConstructionDurations;

                        entityJSON.activeConstructionCRTime = Tentity._activeConstructionCRTime;
                        entityJSON.idleConstructionCRTime = Tentity._idleConstructionCRTime;
                        entityJSON.idleConstructionCRDurations = Tentity._idleConstructionCRDurations;

                        entityJSON.activeConstructionCRVisioTime = Tentity._activeConstructionCRVisioTime;
                        entityJSON.idleConstructionCRVisioTime = Tentity._idleConstructionCRVisioTime;
                        entityJSON.idleConstructionCRVisioDurations = Tentity._idleConstructionCRVisioDurations;

                        entityJSON.activeDecorationTime = Tentity._activeDecorationTime;
                        entityJSON.idleDecorationTime = Tentity._idleDecorationTime;
                        entityJSON.idleDecorationDurations = Tentity._idleDecorationDurations;

                        entityJSON.activeDecorationCRTime = Tentity._activeDecorationCRTime;
                        entityJSON.idleDecorationCRTime = Tentity._idleDecorationCRTime;
                        entityJSON.idleDecorationCRDurations = Tentity._idleDecorationCRDurations;

                        entityJSON.activeDecorationCRVisioTime = Tentity._activeDecorationCRVisioTime;
                        entityJSON.idleDecorationCRVisioTime = Tentity._idleDecorationCRVisioTime;
                        entityJSON.idleDecorationCRVisioDurations = Tentity._idleDecorationCRVisioDurations;

                        entityJSON.logActiveTime = Tentity._logActiveTime;
                        entityJSON.logIdleTime = Tentity._logIdleTime;

                        entityJSON.nbAMRequests = Tentity._nbAMRequests;
                        entityJSON.totalTimeAMRequests = Tentity._totalTimeAMRequests;
                        entityJSON.maxTimeAMRequests = Tentity._maxTimeAMRequests;
                        entityJSON.minTimeAMRequests = Tentity._minTimeAMRequests;
                        entityJSON.nbStaticHullRequests = Tentity._nbStaticHullRequests;
                        entityJSON.totalTimeStaticHullRequests = Tentity._totalTimeStaticHullRequests;
                        entityJSON.nbDynamicHullRequests = Tentity._nbDynamicHullRequests;
                        entityJSON.totalTimeDynamicHullRequests = Tentity._totalTimeDynamicHullRequests;
                        entityJSON.nbFloorHullRequests = Tentity._nbFloorHullRequests;
                        entityJSON.totalTimeFloorHullRequests = Tentity._totalTimeFloorHullRequests;
                    }
                }
                break;
            case SceneConstants.EntityType.Scene:
                break;
            case SceneConstants.EntityType.WorkTop:
                {
                    let Tentity = entity as WorkTop;
                    entityJSON.thickness = Tentity.thickness;
                    entityJSON.legs = Tentity.legs;
                    entityJSON.credences = Tentity.credences;
                }
                break;
            case SceneConstants.EntityType.Wall:
                {
                    let Tentity = entity as Wall;
                    entityJSON.thickness = Tentity.thickness;
                    entityJSON.height = Tentity.height;
                    entityJSON.isRoomedWall = Tentity.rooms.length !== 0;
                    entityJSON.end = Tentity.end;
                    entityJSON.shiftDirection = Tentity.shiftDirection;
                    entityJSON.shiftOffset = Tentity.shiftOffset;
                    if (Tentity.slope) {
                        entityJSON.slope = Tentity.slope;
                        entityJSON.slopeHeight = Tentity.slopeHeight;
                        entityJSON.slopeLength1 = Tentity.slopeLength1;
                        entityJSON.slopeLength2 = Tentity.slopeLength2;
                    }
                    if (Tentity.forceNoPlinth) {
                        entityJSON.forceNoPlinth = Tentity.forceNoPlinth;
                    }
                    if (Tentity.forceNoCornice) {
                        entityJSON.forceNoCornice = Tentity.forceNoCornice;
                    }
                }
                break;
            case SceneConstants.EntityType.Staircase:
                {
                    let Tentity = entity as Staircase;
                    entityJSON.hasLeftRamp = Tentity.hasLeftRamp;
                    entityJSON.hasRightRamp = Tentity.hasRightRamp;
                    entityJSON.rampMaterialType = Tentity.rampMaterialType;
                    entityJSON.rampHeight = Tentity.rampHeight;
                    entityJSON.isGoingDown = Tentity.isGoingDown;
                    entityJSON.closureType = Tentity.closureType;
                    entityJSON.limonType = Tentity.limonType;
                    entityJSON.limonWidth = Tentity.limonWidth;
                    entityJSON.limonHeight = Tentity.limonHeight;
                    entityJSON.noseLength = Tentity.noseLength;
                    entityJSON.hideInAxo = Tentity.hideInAxo;
                    entityJSON.cutSlope = Tentity.cutSlope;
                    entityJSON.way = Tentity.way;
                    entityJSON.stairCaseStyleId = Tentity.stairCaseStyleId;

                    
                    {
                        let DQSTentity = entity as DoubleQuarterStaircase;
                        entityJSON.stepWidth = DQSTentity.stepWidth;
                        entityJSON.hasBearing = DQSTentity.hasBearing;
                        entityJSON.modifiedWidth = DQSTentity.modifiedWidth;
                    }
                    {
                        let CSTentity = entity as CircularStaircase;
                        entityJSON.revolutionAngle = CSTentity.revolutionAngle;
                    }
                }
            case SceneConstants.EntityType.TechnicalElement:
                {
                    let Tentity = entity as TechnicalElement;
                    entityJSON.objectId = Tentity.objectId;
                    entityJSON.objectType = Tentity.objectType;
                    entityJSON.length = Tentity.length;
                    entityJSON.width = Tentity.width;
                    entityJSON.height = Tentity.height;
                    entityJSON.materialType = Tentity.materialType;
                    entityJSON.shapeType = Tentity.shapeType;
                    entityJSON.subModelId = Tentity.subModelId;
                    entityJSON.lightOn = Tentity.lightOn;
                    entityJSON.lightOff = Tentity.lightOff;
                    entityJSON.temperature = Tentity.temperature;
                    entityJSON.lightColor = Tentity.lightColor;
                    entityJSON.intensity = Tentity.intensity;
                    entityJSON.symmetry = Tentity.symmetry;

                    {
                        let CTentity = entity as CeilingBox;
                        entityJSON.ceilingBoxType = CTentity.ceilingBoxType;
                    }

                    {
                        let WTentity = entity as WaterSource;
                        entityJSON.isIn = WTentity.isIn;
                        entityJSON.isOut = WTentity.isOut;
                    }
                }
                break;
            case SceneConstants.EntityType.SketchBlock:
                {
                    let Tentity = entity as SketchBlock;
                    entityJSON.sketchId = Tentity.sketchId;
                    entityJSON.length = Tentity.length;
                    entityJSON.width = Tentity.width;
                    entityJSON.height = Tentity.height;
                }
                break;
            case SceneConstants.EntityType.Room:
                {
                    let Tentity = entity as Room;
                    entityJSON.height = Tentity.height;
                    entityJSON.floorHeight = Tentity.floorHeight;
                    entityJSON.walls = [];
                    for (let i = 0; i < Tentity.walls.length; i++) {
                        entityJSON.walls[i] = Tentity.walls[i].id;
                    }
                    entityJSON.nonRoomedWalls = [];
                    for (let i = 0; i < Tentity.nonRoomedWalls.length; i++) {
                        entityJSON.nonRoomedWalls[i] = Tentity.nonRoomedWalls[i].id;
                    }
                    entityJSON.area = Tentity.area;
                    entityJSON.plinths = Tentity.plinths;
                    entityJSON.plinthsHeight = Tentity.plinthsHeight;
                    entityJSON.plinthsMaterialType = Tentity.plinthsMaterialType;
                    entityJSON.cornices = Tentity.cornices;
                }
                break;
            case SceneConstants.EntityType.Sun:
                {
                    let Tentity = entity as Sun;
                    entityJSON.altitude = Tentity.altitude;
                    entityJSON.weather = Tentity.weather;
                    entityJSON.southDirection = Tentity.southDirection;
                    entityJSON.exterior = Tentity.exterior;
                    entityJSON.additionalExterior = Tentity.additionalExterior;
                    entityJSON.increaseDecorsSize = Tentity.increaseDecorsSize;
                    entityJSON.decorsRotation = Tentity.decorsRotation;
                    entityJSON.shadowBlurRadius = Tentity.shadowBlurRadius;
                    entityJSON.temperature = Tentity.temperature;
                    entityJSON.skyIntensity = Tentity.skyIntensity;

                    var dir = math.vec3.create();
                    var southDir = math.vec3.create();

                    math.vec3.set(dir, 0, 0, 1);
                    math.vec3.set(southDir, Tentity.southDirection[0], 0, Tentity.southDirection[1]);

                    entityJSON.southAngle = math.vec3.angle(dir, southDir);
                    if (Tentity.southDirection[0] < 0) {
                        entityJSON.southAngle = -entityJSON.southAngle;
                    }
                }
                break;
            case SceneConstants.EntityType.RenderCamera:
                {
                    let Tentity = entity as RenderCamera;
                    entityJSON.fov = Tentity.fov;
                    entityJSON.verticalShift = Tentity.verticalShift;
                    entityJSON.pitch = Tentity.pitch;
                    entityJSON.cameraType = Tentity.cameraType;
                    entityJSON.quality = Tentity.quality;
                    entityJSON.isFinalRender = Tentity.isFinalRender;

                    entityJSON.iso = Tentity.iso;
                    entityJSON.shutterSpeed = Tentity.shutterSpeed;
                    entityJSON.fNumber = Tentity.fNumber;
                    entityJSON.cameraNb = Tentity.cameraNb;
                    entityJSON.renderWidth = Tentity.renderWidth;
                    entityJSON.renderHeight = Tentity.renderHeight;
                    entityJSON.projection = Tentity.projection;
                    entityJSON.excludedObjectIds = Tentity.excludedObjectIds;
                    entityJSON.shoppingList = Tentity.shoppingList;
                    entityJSON.entityShoppingList = Tentity.entityShoppingList;
                    entityJSON.denoising = Tentity.denoising;
                    entityJSON.autoexposure = Tentity.autoexposure;
                    entityJSON.whitebalance = Tentity.whitebalance;
                    entityJSON.renderType = Tentity.renderType;
                    entityJSON.exposure = Tentity.exposure;
                    entityJSON.dof = Tentity.dof;
                    entityJSON.aperture = Tentity.aperture;
                    entityJSON.hd = Tentity.hd;
                    entityJSON.format = Tentity.format;
                    entityJSON.nbImages = Tentity.nbImages;
                    entityJSON.sunOffsetRotation = Tentity.sunOffsetRotation;
                    entityJSON.sunOffsetAltitude = Tentity.sunOffsetAltitude;
                }
                break;
            // Deprecated don't export anymore
            case SceneConstants.EntityType.Light:
                break;
            case SceneConstants.EntityType.Comment:
                {
                    let Tentity = entity as Comment;
                    entityJSON.text = Tentity.text;
                    entityJSON.images = Tentity.images;
                }
                break;
            case SceneConstants.EntityType.FunctionalityChip:
                {
                    let Tentity = entity as FunctionalityChip;
                    entityJSON.isOnPlan = Tentity.isOnPlan;
                }
                break;
            case SceneConstants.EntityType.ArrangementZone:
                {
                    let Tentity = entity as ArrangementZone;
                    entityJSON.zoneType = Tentity.zoneType;
                    entityJSON.length = Tentity.length;
                    entityJSON.width = Tentity.width;
                    entityJSON.height = Tentity.height;
                    entityJSON.offset = Tentity.offset;
                    entityJSON.data = Tentity.data;
                }
                break;
            case SceneConstants.EntityType.ArrangementGroup:
                {
                    let Tentity = entity as ArrangementGroup;
                    entityJSON.objectId = Tentity.objectId;
                    entityJSON.arrangementGroupType = Tentity.arrangementGroupType;
                    entityJSON.masterObjectId = Tentity.masterObjectId;
                    entityJSON.AMLayoutId = Tentity.AMLayoutId;
                    entityJSON.smartDesignerParentId = Tentity.smartDesignerParentId;
                }
                break;
            case SceneConstants.EntityType.ArrangementObject:
                {
                    let Tentity = entity as ArrangementObject;
                    // Check if we have some objects we have to keep ?
                    if (objectTypesToKeep.length !== 0) {
                        // Object type not in the objectTypesToKeep ? then we filter the object, return null
                        if (objectTypesToKeep.indexOf(Tentity.objectType) === -1) {
                            return null;
                        }
                    }
                    entityJSON.objectId = Tentity.objectId;
                    entityJSON.manufacturer = Tentity.manufacturer;
                    entityJSON.retailer = Tentity.retailer;
                    entityJSON.colorId = Tentity.colorId;
                    entityJSON.objectType = Tentity.objectType;
                    entityJSON.objectStyles = Tentity.objectStyles;
                    entityJSON.coatingId = Tentity.coatingId;
                    entityJSON.length = Tentity.length;
                    entityJSON.width = Tentity.width;
                    entityJSON.height = Tentity.height;
                    entityJSON.anchor = Tentity.anchor;
                    entityJSON.anchorActive = Tentity.isAnchorActive;
                    entityJSON.stackable = Tentity.stackable;
                    entityJSON.objectTypeConfig = Tentity.objectTypeConfig;
                    entityJSON.stretchability = Tentity.stretchability;
                    entityJSON.excludeFromShoppingList = Tentity.excludeFromShoppingList;
                    if (Tentity.customization !== undefined) {
                        entityJSON.customization = Tentity.customization;

                        if (entityJSON.customization.parts !== undefined) {
                            for (var i = 0; i < entityJSON.customization.parts.length; i++) {
                                entityJSON.customization.parts[i].$$hashKey = undefined;
                            }
                        }
                    }
                    entityJSON.handleSide = Tentity.handleSide;
                    entityJSON.handleAsset = Tentity.handleAsset;
                    entityJSON.originalLength = Tentity.originalLength;
                    entityJSON.originalWidth = Tentity.originalWidth;
                    entityJSON.originalHeight = Tentity.originalHeight;
                    entityJSON.lightOn = Tentity.lightOn;
                    entityJSON.lightOff = Tentity.lightOff;
                    entityJSON.temperature = Tentity.temperature;
                    entityJSON.lightColor = Tentity.lightColor;
                    entityJSON.symmetry = Tentity.symmetry;
                    entityJSON.coatingAllowed = Tentity.coatingAllowed;
                    entityJSON.hidden = Tentity.hidden;
                    entityJSON.smartDesignerParentId = Tentity.smartDesignerParentId;
                    entityJSON.initialObjectId = Tentity.initialObjectId;
                    entityJSON.configurable = Tentity.configurable;
                }
                break;
            // Deprecated don't export anymore
            case SceneConstants.EntityType.ArrangementDecorated:
                break;
            case SceneConstants.EntityType.Joinery:
                {
                    let Tentity = entity as Joinery;
                    entityJSON.position = Tentity.position;
                    entityJSON.joineryType = Tentity.joineryType;
                    entityJSON.length = Tentity.length;
                    entityJSON.height = Tentity.height;
                    entityJSON.floorHeight = Tentity.floorHeight;
                    entityJSON.materialType = Tentity.materialType;
                    entityJSON.orientation = Tentity.orientation;
                    entityJSON.subModelId = Tentity.subModelId;
                    entityJSON.scalable = Tentity.scalable;
                    entityJSON.freeColor = Tentity.freeColor;
                    entityJSON.wallInstallType = Tentity.wallInstallType;
                    entityJSON.frosted = Tentity.frosted;
                    entityJSON.cutSlope = Tentity.cutSlope;

                    if (entityJSON.joineryType !== SceneConstants.JoineryType.velux) {
                        entityJSON.transom = Tentity.transom;
                        entityJSON.transomHeight = Tentity.transomHeight;
                        entityJSON.bottomTransom = Tentity.bottomTransom;
                        entityJSON.bottomTransomHeight = Tentity.bottomTransomHeight;
                    }

                    if ((entity as any).nbCasement !== undefined) {
                        entityJSON.nbCasement = (entity as any).nbCasement;
                    }
                    if ((entity as any).nbDoors !== undefined) {
                        entityJSON.nbDoors = (entity as any).nbDoors;
                    }
                    if ((entity as any).openingMode !== undefined) {
                        entityJSON.openingMode = (entity as any).openingMode;
                    }
                    if ((entity as any).slideDirection !== undefined) {
                        entityJSON.slideDirection = (entity as any).slideDirection;
                    }
                    if ((entity as any).handleSide !== undefined) {
                        entityJSON.handleSide = (entity as any).handleSide;
                    }
                    if ((entity as any).openingSymetry !== undefined) {
                        entityJSON.openingSymetry = (entity as any).openingSymetry;
                    }
                    if ((entity as any).thickness !== undefined) {
                        entityJSON.thickness = (entity as any).thickness;
                    }
                    if ((entity as any).isOpened !== undefined) {
                        entityJSON.isOpened = (entity as any).isOpened;
                    }
                    if ((entity as any).leftOpeningAngle !== undefined) {
                        entityJSON.leftOpeningAngle = (entity as any).leftOpeningAngle;
                    }
                    if ((entity as any).rightOpeningAngle !== undefined) {
                        entityJSON.rightOpeningAngle = (entity as any).rightOpeningAngle;
                    }
                }
                break;
            case SceneConstants.EntityType.UserPicture:
                {
                    let Tentity = entity as UserPicture;
                    entityJSON.pictureRef = Tentity.pictureRef;
                }
                break;
            case SceneConstants.EntityType.GeometryPrimitive:
                {
                    let Tentity = entity as GeometryPrimitive;
                    entityJSON.primitiveType = Tentity.primitiveType;
                    entityJSON.height = Tentity.height;
                    entityJSON.hideInAxo = Tentity.hideInAxo;
                    entityJSON.cutByJoineries = Tentity.cutByJoineries;
                    entityJSON.length = Tentity.length;
                    entityJSON.width = Tentity.width;

                    if ((Tentity as any).diameter) {
                        entityJSON.diameter = (Tentity as any).diameter;
                    }
                    if ((Tentity as any).topLength) {
                        entityJSON.topLength = (Tentity as any).topLength;
                        entityJSON.topWidth = (Tentity as any).topWidth;
                        entityJSON.shapeType = (Tentity as any).shapeType;
                    }
                }
                break;
            default:
        }

        for (let i = 0; i < entity.children.length; i++) {
            let serializeChild = JSONSerializer._serializeEntity(entity.children[i], filterEntityTypes, filterComponentTypes, objectTypesToKeep);
            if (serializeChild !== null) {
                entityJSON.children.push(serializeChild);
            }
        }

        for (let i = 0; i < entity.components.length; i++) {
            let serializeComponent = JSONSerializer._serializeComponent(entity.components[i], filterComponentTypes);
            if (serializeComponent !== null) {
                entityJSON.components.push(serializeComponent);
            }
        }

        // Case of an arrangement group with no content, filter it
        if (entity.entityType === SceneConstants.EntityType.ArrangementGroup && entityJSON.children.length === 0) {
            return null;
        }

        return entityJSON;
    }

    /**
     * Export an component, if
     * @param component
     * @private
     */
    static _serializeComponent(component: Component, filterComponentTypes: Array<ComponentConstants.ComponentType> = null) {
        // If entity within the entities to filter, skip it
        if (filterComponentTypes && filterComponentTypes.indexOf(component.componentType) !== -1) {
            return null;
        }

        let jsonComponent: any = {
            componentType: component.componentType,
            isUnique: component.isUnique,
        };

        switch (component.componentType) {
            case ComponentConstants.ComponentType.BoundingBox:
                {
                    let Tcomponent = component as BoundingBox;
                    jsonComponent.position = Tcomponent.center3d;
                    jsonComponent.length = Tcomponent.length;
                    jsonComponent.width = Tcomponent.width;
                    jsonComponent.height = Tcomponent.height;
                }
                break;
            case ComponentConstants.ComponentType.DepthBoundingBox:
                {
                    let Tcomponent = component as DepthBoundingBox;
                    jsonComponent.position = Tcomponent.center3d;
                    jsonComponent.length = Tcomponent.xLength;
                    jsonComponent.width = Tcomponent.yLength;
                    jsonComponent.height = Tcomponent.zLength;
                }
                break;
            case ComponentConstants.ComponentType.Area:
                {
                    let Tcomponent = component as Area;
                    jsonComponent.vertices = Tcomponent.vertices;
                }
                break;
            case ComponentConstants.ComponentType.CutArea:
            case ComponentConstants.ComponentType.AddArea:
                {
                    let Tcomponent = component as CutArea | AddArea;
                    jsonComponent.vertices = Tcomponent.vertices;
                    jsonComponent.isDirectSide = Tcomponent.isDirectSide;
                }
                break;
            case ComponentConstants.ComponentType.CoatingArea:
            case ComponentConstants.ComponentType.FloorCoatingArea:
            case ComponentConstants.ComponentType.Credence:
            case ComponentConstants.ComponentType.Coating:
                {
                    let Tcomponent = component as CoatingArea | FloorCoatingArea | Credence | Coating;
                    jsonComponent.coatingId = Tcomponent.coatingId;
                    jsonComponent.manufacturer = Tcomponent.manufacturer;
                    jsonComponent.retailer = Tcomponent.retailer;
                    jsonComponent.hangType = Tcomponent.hangType;
                    jsonComponent.colors = Tcomponent.colors;
                    jsonComponent.randomization = Tcomponent.randomization;
                    jsonComponent.floorGeneratorSettings = Tcomponent.floorGeneratorSettings;
                    jsonComponent.colorIndex = Tcomponent.colorIndex;
                    jsonComponent.offset = Tcomponent.offset;
                    jsonComponent.repeat = Tcomponent.repeat;
                    jsonComponent.rotation = Tcomponent.rotation;
                    jsonComponent.usemtlName = Tcomponent.usemtlName;
                    jsonComponent.locked = Tcomponent.locked;
                    jsonComponent.configurable = Tcomponent.configurable;

                    {
                        let CTcomponent = Tcomponent as Credence;
                        jsonComponent.credenceIndex = CTcomponent.credenceIndex;
                    
                        if (CTcomponent.height !== null && CTcomponent.height !== undefined) {
                            jsonComponent.height = CTcomponent.height;
                        }
                    }

                    {
                        let FCTcomponent = Tcomponent as CoatingArea | FloorCoatingArea;
                        jsonComponent.vertices = FCTcomponent.vertices;
                    }
                    
                    {
                        let FCTcomponent = Tcomponent as FloorCoatingArea;
                        jsonComponent.altitude = FCTcomponent.altitude;
                    }

                    {
                        let CTcomponent = Tcomponent as CoatingArea;
                        jsonComponent.isDirectSide = CTcomponent.isDirectSide;
                    }
                }
                break;
            case ComponentConstants.ComponentType.CustomCoating:
                {
                    let Tcomponent = component as CustomCoating;
                    jsonComponent.name = component.name;
                    jsonComponent.coating = JSONSerializer._serializeComponent(Tcomponent.coating);
                }
                break;
            case ComponentConstants.ComponentType.DomeLight:
                {
                    let Tcomponent = component as DomeLight;
                    jsonComponent.warmth = Tcomponent.warmth;
                    jsonComponent.intensity = Tcomponent.intensity;
                }
                break;
            case ComponentConstants.ComponentType.Functionality:
                {
                    let Tcomponent = component as Functionality;
                    jsonComponent.functionalityId = Tcomponent.functionalityId;
                    jsonComponent.functionalityName = Tcomponent.rawFunctionalityName;
                    jsonComponent.data = Tcomponent._data;
                }
                break;
            case ComponentConstants.ComponentType.JoineryType:
                {
                    let Tcomponent = component as JoineryType;
                    jsonComponent.joineryTypeId = Tcomponent.joineryTypeId;
                    jsonComponent.manufacturer = Tcomponent.manufacturer;
                    jsonComponent.retailer = Tcomponent.retailer;
                }
                break;
            case ComponentConstants.ComponentType.TechnicalElementType:
                {
                    let Tcomponent = component as TechnicalElementType;
                    jsonComponent.technicalElementTypeId = Tcomponent.technicalElementTypeId;
                }
                break;
            case ComponentConstants.ComponentType.WallType:
                {
                    let Tcomponent = component as WallType;
                    jsonComponent.wallTypeId = Tcomponent.wallTypeId;
                }
                break;
            case ComponentConstants.ComponentType.CorniceType:
                {
                    let Tcomponent = component as CorniceType;
                    jsonComponent.corniceTypeId = Tcomponent.corniceTypeId;
                }
                break;
            case ComponentConstants.ComponentType.RendererTopView:
                {
                    let Tcomponent = component as RendererTopView;
                    jsonComponent.urlTopView = Tcomponent.urlTopview;
                    jsonComponent.urlSecondary = Tcomponent.urlSecondary;
                }
                break;
            case ComponentConstants.ComponentType.TemplateImage:
                {
                    let Tcomponent = component as TemplateImage;
                    jsonComponent.idPhoto = Tcomponent.idPhoto;
                    if (Tcomponent.idPhoto === -1) {
                        jsonComponent.url = ""; //Data may be too heavy for the format
                    } else {
                        jsonComponent.url = Tcomponent.url;
                    }

                    jsonComponent.scale = Tcomponent.scale;
                    jsonComponent.userScale = Tcomponent.userScale;

                    jsonComponent.rotation = Tcomponent.rotation;
                    jsonComponent.offset = Tcomponent.offset;
                }
                break;
            case ComponentConstants.ComponentType.PlainData:
                {
                    let Tcomponent = component as PlainData;
                    jsonComponent = Tcomponent.data;
                }
                break;
            case ComponentConstants.ComponentType.Mesh:
            case ComponentConstants.ComponentType.WallQuotation:
            case ComponentConstants.ComponentType.RoomQuotation:
                return null;
                break;
            case ComponentConstants.ComponentType.Population:
                return null;
                break;
            case ComponentConstants.ComponentType.SmartDesignerSolution:
                return null;
                break;
            default:
                //_externalSerializeComponents si null serialize as plaindata
                jsonComponent = null;
                for (let i = 0; i < JSONSerializer._externalSerializeComponents.length; i++) {
                    if (typeof JSONSerializer._externalSerializeComponents[i] === "function") {
                        jsonComponent = JSONSerializer._externalSerializeComponents[i](component);
                    }

                    if (jsonComponent !== null) {
                        break;
                    }
                }

                if (jsonComponent === null) {
                    jsonComponent = {
                        componentType: component.componentType,
                        isUnique: component.isUnique,
                    };
                    Object.keys(component).forEach(function (key) {
                        //export only ref to other entity
                        if (component[key] && component[key].entityType >= 0) {
                            jsonComponent[key] = component[key].id;
                        } else {
                            jsonComponent[key] = component[key];
                        }
                    });
                }
        }
        return jsonComponent;
    }

    /**
     * serialize the quotation from the world
     * @param world
     */
    static serializeQuotation(world: World) {
        if (world.entityType === SceneConstants.EntityType.World) {
            return JSONSerializer._serializeEntityQuotation(world);
        }
        return null;
    }

    /**
     * serialize an entity for quotation
     * @param obj
     */
    static _serializeEntityQuotation(obj: Entity) {
        let entityJSON : any = {
            id: obj.id,
            entityType: obj.entityType,
            name: obj.name,
            components: [],
            children: [],
        };

        //Serialize specific data
        switch (obj.entityType) {
            case SceneConstants.EntityType.Wall:
                {
                    let Tobj = obj as Wall;
                    let boundingBox = Tobj.boundingBoxCut;
                    entityJSON.areaDirect = math.vec3.distance(boundingBox[3], boundingBox[0]) * Tobj.height;
                    entityJSON.areaUndirect = math.vec3.distance(boundingBox[2], boundingBox[1]) * Tobj.height;
                }
                break;
            case SceneConstants.EntityType.TechnicalElement:
                {
                    let Tobj = obj as TechnicalElement;
                    entityJSON.objectId = Tobj.objectId;
                }
                break;
            case SceneConstants.EntityType.Room:
                {
                    let Tobj = obj as Room;
                    entityJSON.area = Tobj.area;
                }
                break;
            case SceneConstants.EntityType.FunctionalityChip:
                {
                    let Tobj = obj as FunctionalityChip;
                    entityJSON.isOnPlan = Tobj.isOnPlan;
                }
                break;
            case SceneConstants.EntityType.Joinery:
                {
                    let Tobj = obj as Joinery;
                    entityJSON.joineryType = Tobj.joineryType;
                }
                break;
            case SceneConstants.EntityType.World:
            case SceneConstants.EntityType.Scene:
            case SceneConstants.EntityType.Floor:
                break;
            default:
                return null;
        }

        for (let i = 0; i < obj.children.length; i++) {
            let serializeChild = JSONSerializer._serializeEntityQuotation(obj.children[i]);
            if (serializeChild !== null) {
                entityJSON.children.push(serializeChild);
            }
        }

        for (let i = 0; i < obj.components.length; i++) {
            let serializeComponent = JSONSerializer._serializeComponentQuotation(obj.components[i]);
            if (serializeComponent !== null) {
                entityJSON.components.push(serializeComponent);
            }
        }
        return entityJSON;
    }

    /**
     *
     */
    static _serializeComponentQuotation(component: Component) {
        let jsonComponent: any = {
            componentType: component.componentType,
        };

        switch (component.componentType) {
            case ComponentConstants.ComponentType.Coating:
            case ComponentConstants.ComponentType.Credence:
            case ComponentConstants.ComponentType.CoatingArea:
            case ComponentConstants.ComponentType.FloorCoatingArea:
                {
                    let Tcomponent = component as Coating | Credence | CoatingArea | FloorCoatingArea;
                    jsonComponent.coatingId = Tcomponent.coatingId;
                    jsonComponent.hangType = Tcomponent.hangType;
                    jsonComponent.colors = Tcomponent.colors;
                    jsonComponent.randomization = Tcomponent.randomization;
                    jsonComponent.floorGeneratorSettings = Tcomponent.floorGeneratorSettings;
                    jsonComponent.offset = Tcomponent.offset;
                    jsonComponent.repeat = Tcomponent.repeat;
                    jsonComponent.urlPreview = Tcomponent.urlPreview;
                    jsonComponent.colorIndex = Tcomponent.colorIndex;

                    {
                        let FCTcomponent = component as CoatingArea | FloorCoatingArea;
                        jsonComponent.area = FCTcomponent.area;
                    }

                    {
                        let TCcomponent = component as Credence;
                        jsonComponent.credenceIndex = TCcomponent.credenceIndex;
                        jsonComponent.height = TCcomponent.height;
                    }
                }
                break;
            case ComponentConstants.ComponentType.Functionality:
                {
                    let Tcomponent = component as Functionality;
                    jsonComponent.functionalityId = Tcomponent.functionalityId;
                    jsonComponent.functionalityName = Tcomponent.functionalityName;
                }
                break;
            case ComponentConstants.ComponentType.JoineryType:
                {
                    let Tcomponent = component as JoineryType;
                    jsonComponent.joineryTypeId = Tcomponent.joineryTypeId;
                }
                break;
            case ComponentConstants.ComponentType.TechnicalElementType:
                {
                    let Tcomponent = component as TechnicalElementType;
                    jsonComponent.technicalElementTypeId = Tcomponent.technicalElementTypeId;
                }
                break;
            case ComponentConstants.ComponentType.WallType:
                {
                    let Tcomponent = component as WallType;
                    jsonComponent.wallTypeId = Tcomponent.wallTypeId;
                }
                break;
            case ComponentConstants.ComponentType.CorniceType:
                {
                    let Tcomponent = component as CorniceType;
                    jsonComponent.corniceTypeId = Tcomponent.corniceTypeId;
                }
                break;
            case ComponentConstants.ComponentType.RoomQuotation:
                {
                    let Tcomponent = component as RoomQuotation;
                    jsonComponent.floorCovering = Tcomponent.floorCovering;
                    jsonComponent.ceilingRepair = Tcomponent.ceilingRepair;
                    jsonComponent.ceilingCovering = Tcomponent.ceilingCovering;
                    jsonComponent.kitchenSize = Tcomponent.kitchenSize;
                }
                break;
            case ComponentConstants.ComponentType.WallQuotation:
                {
                    let Tcomponent = component as WallQuotation;
                    jsonComponent.wallCoveringDirect = Tcomponent.wallCoveringDirect;
                    jsonComponent.wallCoveringUndirect = Tcomponent.wallCoveringUndirect;
                    jsonComponent.wallRepairDirect = Tcomponent.wallRepairDirect;
                    jsonComponent.wallRepairUndirect = Tcomponent.wallRepairUndirect;
                }
                break;
            default:
                return null;
        }
        return jsonComponent;
    }
}
