import { Command, CommandEnum } from './CommandModule';
import { Events } from '../events';
import { eventsManager } from '../managers/EventsManager';
import { Entity, Coating, Component, ComponentConstants } from '../SavaneJS';

export class AddComponentCommand  extends Command
{
    // Added and revious component
    private _addedComponent: Component;
    private _previousComponent: Component;
    // Entity on which the component is added
    private _addedComponentEntity: Entity;
    private _unique: boolean;
    private _updateWebGL: boolean;
    
    constructor(addedComponent: Component, entity: Entity, unique: boolean, updateWebGL: boolean) {
        super();
        // Component to add
        this._addedComponent = addedComponent;
        // Entity to attach the component to
        this._addedComponentEntity = entity;
        // Component unicity handling
        this._unique = unique;
        // Used for the Undo in case of unicity requested
        this._previousComponent = null;
        // Keep update webGL parameters
        this._updateWebGL = updateWebGL;
    }

    // Command name returned from a global enum
    name(): string {
        return CommandEnum.AddComponentCommand;
    }

    // Datas that allows the command to be executed
    execDatas(): any {
        return {
            component: this._addedComponent,
            entity: this._addedComponentEntity,
            unique: this._unique
        };
    }

    // Do anything specific for redraw for specific nodes
    redrawAndUpdateWebGL() {
        if (this._addedComponent === null) {
            if (this._previousComponent !== null) {
                if ((this._previousComponent.componentType === ComponentConstants.ComponentType.Coating || this._previousComponent.componentType === ComponentConstants.ComponentType.FloorCoatingArea) && ((this._previousComponent as Coating).floorGeneratorSettings)) {
                    eventsManager.instance.dispatch(Events.FLOOR_GENERATOR_CHANGED);
                }
            }
        } else {
            if (this._previousComponent !== null) {
                if ((this._previousComponent.componentType === ComponentConstants.ComponentType.Coating || this._previousComponent.componentType === ComponentConstants.ComponentType.FloorCoatingArea) &&
                    (this._addedComponent.componentType === ComponentConstants.ComponentType.Coating || this._addedComponent.componentType === ComponentConstants.ComponentType.FloorCoatingArea) &&
                    ((this._previousComponent as Coating).floorGeneratorSettings !== (this._addedComponent as Coating).floorGeneratorSettings)) {
                    eventsManager.instance.dispatch(Events.FLOOR_GENERATOR_CHANGED);
                }
            } else {
                if ((this._addedComponent.componentType === ComponentConstants.ComponentType.Coating || this._addedComponent.componentType === ComponentConstants.ComponentType.FloorCoatingArea) && ((this._addedComponent as Coating).floorGeneratorSettings)) {
                    eventsManager.instance.dispatch(Events.FLOOR_GENERATOR_CHANGED);
                }
            }
        }
    }

    // Undo the current command
    undo() {
        // Remove added component
        this._addedComponentEntity.removeComponent(this._addedComponent);

        // Check if we have to do something when the component is removed
        switch (this._addedComponent.componentType) {
            case ComponentConstants.ComponentType.CoatingArea:
            case ComponentConstants.ComponentType.CutArea:
            case ComponentConstants.ComponentType.AddArea:
                eventsManager.instance.dispatch(Events.DELETE_FRONTVIEW_AREA,
                    {
                        component: this._addedComponent,
                    });
                break;
        }

        // Notify everybody that a component was removed
        eventsManager.instance.dispatch(Events.COMPONENT_REMOVED, { component: this._addedComponent, from: this._addedComponentEntity });

        // If the component was requested to be unique a previous component was removed, put it back
        if (this._unique && this._previousComponent) {
            this._addedComponentEntity.addComponent(this._previousComponent);
        }

        // Perform specific redraw depending on node type
        eventsManager.instance.dispatch(Events.REDRAW_GRAPHICAL_ENTITY,
            {
                entity: this._addedComponentEntity
            });

        // Update webgl only if needed
        if (this._updateWebGL) {
            this.redrawAndUpdateWebGL();
            //Execute parent function
            super.undo();
        }
    }

    // Execute current command (redo)
    execute() {
        // If the requested addition must remove any previous compone tof same type
        if (this._unique) {
            // Clear stored component
            this._previousComponent = null;
            // Get any component with same type added previously
            let currentComponents = this._addedComponentEntity.getComponents(this._addedComponent.componentType);

            // If more than one component found, this is not a normal situation, delete all components others than the first one
            for (let i = currentComponents.length - 1 ; i > 1  ; i--) {
                this._addedComponentEntity.removeComponent(currentComponents[i]);
            }

            // One component found, store it for undo and remove it
            if (currentComponents.length === 1) {
                this._previousComponent = currentComponents[0];
                this._addedComponentEntity.removeComponent(currentComponents[0]);
            }
        }

        // Add component to the entity
        this._addedComponentEntity.addComponent(this._addedComponent);

        // Check if we have to do something when the component is added
        switch (this._addedComponent.componentType) {
            case ComponentConstants.ComponentType.CoatingArea:
            case ComponentConstants.ComponentType.CutArea:
            case ComponentConstants.ComponentType.AddArea:
                eventsManager.instance.dispatch(Events.REDRAW_FRONTVIEW_AREA,
                    {
                        component: this._addedComponent,
                    });
                break;
        }

        // Check if a component was removed from the entity
        if (this._previousComponent) {
            // Inform everyone that a component was removed
            eventsManager.instance.dispatch(Events.COMPONENT_REMOVED, { component: this._previousComponent, from: this._addedComponentEntity });
        }
        // Inform everyone that a component was added
        eventsManager.instance.dispatch(Events.COMPONENT_ADDED, this._addedComponent);

        // Perform specific redraw depending on node type
        eventsManager.instance.dispatch(Events.REDRAW_GRAPHICAL_ENTITY,
            {
                entity: this._addedComponentEntity
            });

        // Update webgl only if needed
        if (this._updateWebGL) {
            this.redrawAndUpdateWebGL();
            //Execute parent function
            super.execute();
        }
    }
};
