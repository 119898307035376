import {ComponentConstants} from '../SavaneJS';
import {Component} from './Component';

// This component will be created if savane don't know the type of the component to create.
export class PlainData extends Component {
    public data: any;

    constructor(data: any) {
        super();
        this.data = data;
    }

    get componentType(): ComponentConstants.ComponentType {
        return ComponentConstants.ComponentType.PlainData;
    }

    clone(): PlainData {
        return new PlainData(this.data);
    }
}
