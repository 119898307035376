export class ComponentConstants {
    constructor() {
    }
}

export namespace ComponentConstants
{
    export const CredenceDefaultHeight: number = 730;
    
    export enum ComponentType
    {
        Mesh = 0,
        MeshLib = 1,
        RendererTopView = 2,
        Functionality = 3,
        RoomQuotation = 4,
        WallQuotation = 5,
        TemplateImage = 6,
        Sun = 7,
        DomeLight = 8,
        Coating = 9,
        Area = 10,
        Credence = 11,
        FurnitureFinishes = 12,
        JoineryType = 13,
        CoatingArea = 14,
        AMFurnitureFinishesReference = 15,
        PlainData = 16,
        BoundingBox = 17,
        DepthBoundingBox = 18,
        TechnicalElementType = 19,
        SmartDesignerSolution = 20,
        WallType = 21,
        CorniceType = 22,
        CustomCoating = 23,
        FloorCoatingArea = 24,
        CutArea = 25,
        AddArea = 26,
        Population = 27
    };

    export enum Functionalities
    {
        Veranda = 1,
        Doorway = 2,
        Livingroom = 3,
        Laundryroom = 4,
        Diningroom = 5,
        GameRoom = 6,
        Kitchen = 7,
        Garage = 8,
        Clearance = 9,
        Dressing = 11,
        Bedroom = 12,
        Bathroom = 13,
        Watercloset = 14,
        Cellar = 15,
        Office = 16,
        LivingDiningroom = 17,
        Library = 19,
        Terrace = 23,
        Balcony = 24,
        Patio = 25,
        Pool = 26,
        KidBedroom = 33,
        BabyBedroom = 35,
        TechnicalRoom = 38,
        ParentalSuite = 39,
        Wardrobe = 40,
        Garden = 41,
        Driveway = 42,
        Void = 50,
        Mezzanine = 51,
        TeenagerBedroom = 52,
    };
}
