import { Entity, Floor } from '../../SavaneJS';

export class TemporaryEntity {
    // Is the temporary valid
    protected _isValid: boolean;
    // Do we have to recompute the temporary entity validity
    public recomputeValidity: boolean;
    // Entity holding the temporary
    protected entity: Entity;
    // Parent of the entity holding the temporary
    private parent: Entity;
    // Holdout parameter
    private holdout: boolean;
    // hideAtRendering parameter
    private hideAtRendering: boolean;

    constructor(entity: Entity) {
        if (this.constructor === TemporaryEntity) {
            throw new TypeError('Abstract class "TemporaryEntity" cannot be instantiated directly.');
        }

        this._isValid = true;
        this.recomputeValidity = true;
        this.entity = entity;
        this.parent = entity.parent;
        this.holdout = entity.holdout;
        this.hideAtRendering = entity.hideAtRendering;
    }

    isValid(floor: Floor){
        if (this.recomputeValidity){
            this.computeValidity(floor);
            this.recomputeValidity = false;
        }
        return this._isValid;
    }

    computeValidity(floor: Floor) {
        throw new TypeError('Not implemented on temporary ' + (this.entity.entityType) + ' - Abstract class');
    }
}
