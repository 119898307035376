
import { Wall } from "./Wall";
import { Joinery } from "./Joinery";
import { Slope } from "./Slope";
import { Partition } from "./Partition";

export class Model {

    private _walls: Array<Wall> = new Array<Wall>();
    private _wallJoineries: Array<Joinery> = new Array<Joinery>();
    private _partitions: Array<Partition> = new Array<Partition>();
    private _partitionJoineries: Array<Joinery> = new Array<Joinery>();
    private _slopes: Array<Slope> = new Array<Slope>();
    private _slopesPartition: Array<Slope> = new Array<Slope>();

    constructor() {
    }

    Clear() {
        this._walls = new Array<Wall>();
        this._wallJoineries = new Array<Joinery>();
        this._partitions = new Array<Partition>();
        this._partitionJoineries = new Array<Joinery>();
        this._slopes = new Array<Slope>();
        this._slopesPartition = new Array<Slope>();
    }

    get Walls() : Array<Wall> {
        return this._walls;
    }

    set Walls(value: Array<Wall>) {
        this._walls = value;
    }

    get WallJoineries() : Array<Joinery> {
        return this._wallJoineries;
    }

    set WallJoineries(value: Array<Joinery>) {
        this._wallJoineries = value;
    }

    get Partitions() : Array<Partition> {
        return this._partitions;
    }

    set Partitions(value: Array<Partition>) {
        this._partitions = value;
    }

    get PartitionJoineries() : Array<Joinery> {
        return this._partitionJoineries;
    }

    set PartitionJoineries(value: Array<Joinery>) {
        this._partitionJoineries = value;
    }

    get Slopes() : Array<Slope> {
        return this._slopes;
    }

    set Slopes(value: Array<Slope>) {
        this._slopes = value;
    }

    get SlopesPartition() : Array<Slope> {
        return this._slopesPartition;
    }

    set SlopesPartition(value: Array<Slope>) {
        this._slopesPartition = value;
    }

}