import { Command, CommandEnum } from "./CommandModule";
import { Events } from "../events";
import { eventsManager } from "../managers/EventsManager";
import { CeilingBox, Wall, Joinery, SceneConstants, TechnicalElement, math } from "../SavaneJS";

export class AddJoineryCommand extends Command {
    private _newJoinery: Joinery;

    constructor(newJoinery: Joinery) {
        super();
        this._newJoinery = newJoinery;
    }

    // Command name returned from a global enum
    name(): string {
        return CommandEnum.AddJoineryCommand;
    }

    // Undo the current command
    undo() {
        // Remove the entity from the graphical entity manager
        eventsManager.instance.dispatch(Events.REMOVE_GRAPHICAL_ENTITY, {
            entity: this._newJoinery,
        });
        if (this._newJoinery.wall) {
            this._newJoinery.wall.deleteJoinery(this._newJoinery.id);
        }

        eventsManager.instance.dispatch(Events.CHANGE_EDITOR_SELECTION, {
            selection: [],
            keepSelected: false,
            showTulip: false,
        });
    }

    // Execute current command (redo)
    execute() {
        // Try to remove the joinery from the graphical entity manager
        eventsManager.instance.dispatch(Events.REMOVE_GRAPHICAL_ENTITY, {
            entity: this._newJoinery,
        });

        // Parse all children and reassign rolling shutters position to joinery orientation to the new joinery
        for (let j = 0; j < this._newJoinery.children.length; j++) {
            let joinChild = this._newJoinery.children[j];

            if (joinChild.isTechnicalElementEntity() && (joinChild as TechnicalElement).objectId === SceneConstants.TechnicalElementType.ceilingBox && (joinChild as CeilingBox).ceilingBoxType === SceneConstants.CeilingBoxType.rollingShutter) {
                (joinChild as CeilingBox).length = this._newJoinery.length;
                let positionChild = math.vec3.create();

                if (this._newJoinery.orientation) {
                    math.vec3.set(positionChild, 0, -(this._newJoinery.parent as Wall).thickness / 2, this._newJoinery.height + this._newJoinery.floorHeight);
                } else {
                    math.vec3.set(positionChild, 0, (this._newJoinery.parent as Wall).thickness / 2, this._newJoinery.height + this._newJoinery.floorHeight);
                }
                joinChild.transform.localPosition = positionChild;
            }
        }

        // Add the joinery to the graphical entity manager
        eventsManager.instance.dispatch(Events.ADD_GRAPHICAL_ENTITY, {
            entity: this._newJoinery,
        });
        this._newJoinery.wall.addJoinery(this._newJoinery);

        // Select object at the end
        eventsManager.instance.dispatch(Events.CHANGE_EDITOR_SELECTION, {
            selection: [this._newJoinery],
            keepSelected: false,
            showTulip: false,
        });
    }
}
