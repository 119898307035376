import { Staircase, CircularStaircase, SceneConstants, arrangementManager } from '../../SavaneJS';
import { TemporaryTechnicalElement } from './TemporaryTechnicalElement';

/**
 * TemporaryStaircase is used for staircase edition
 *
 * @constructor
 */
/**
 * The goal here is to save a staircase in a temporary element when it is edited, make changes on this temporary model and not on the real entityType
 * and potentially save back the changes at the end
 */
export class TemporaryStaircase extends TemporaryTechnicalElement {
    public hasLeftRamp: boolean;
    public hasRightRamp: boolean;
    public rampMaterialType: number;
    public rampHeight: number;
    public noseLength: number;
    public closureType: SceneConstants.StaircaseCloseType;
    public limonType: SceneConstants.StaircaseLimonType;
    public limonWidth: number;
    public limonHeight: number;
    public hideInAxo: boolean;
    public cutSlope: boolean;
    public revolutionAngle: number;

    constructor(staircase: Staircase) {
        super(staircase);

        // Store left and right ramps
        this.hasLeftRamp = staircase.hasLeftRamp;
        this.hasRightRamp = staircase.hasRightRamp;
        this.rampMaterialType = staircase.rampMaterialType;
        this.rampHeight = staircase.rampHeight;
        // Store nose length and height (nez de marche)
        this.noseLength = staircase.noseLength;
        // Store closure type (how the stair is closed, opened, closed or boxed)
        this.closureType = staircase.closureType;
        // Store limon type (none, center, side)
        this.limonType = staircase.limonType;
        this.limonWidth = staircase.limonWidth;
        this.limonHeight = staircase.limonHeight;
        // Store hide in axo
        this.hideInAxo = staircase.hideInAxo;
        // Store cut slope
        this.cutSlope = staircase.cutSlope;

        // If stair is circular stair, save revolution angle parameter (maybe could be in a specialised class)
        if (staircase.objectId === SceneConstants.StaircaseType.circular) {
            // Store revolution angle (how much the stair will turn for circular staircase)
            this.revolutionAngle = (staircase as CircularStaircase).revolutionAngle;
        }
    }

    computeValidity() : void {
        this._isValid = arrangementManager.isStaircaseValid(this.entity as Staircase);
    }
}
