import { Command, CommandEnum, AddEntitiesCommand, AddComponentCommand, DeleteEntitiesCommand, DeleteComponentCommand } from "./CommandModule";
import { Coating, CoatingArea, Component, ComponentConstants, Entity, Room, RenderCamera, SceneConstants } from "../SavaneJS";

// Constructor will take 2 parameters for the solution to apply, the room on which to apply the solution and the solution in itself
// The entities array parameter contains a structure with entities elements to add with their parent in the scene hierarchy
// The coatings array parameter contains a structure with the wall id to attach it to and the coating component
export class ApplySmartDesignerSolutionCommand extends Command {
    private _smartDesignedRoom: Room;
    private _smartDesignerParentEntityId: Array<number>;
    private _addedEntities: Array<any> = [];
    private _addedCoatings: Array<any> = [];
    // This will be used to save the commands created in the execute method so we can undo them in the undo method
    private _subCommands: Array<Command> = [];

    constructor(smartDesignedRoom: Room, smartDesignerParentEntityId: Array<number>, addedEntities: Array<any>, addedCoatings: Array<Component>, cleanAll: boolean) {
        super();
        // Store all parameters
        this._smartDesignedRoom = smartDesignedRoom;
        this._smartDesignerParentEntityId = smartDesignerParentEntityId;
        for (let i = 0; i < addedEntities.length; i++) {
            this._addedEntities = this._addedEntities.concat(addedEntities[i]);
        }
        for (let i = 0; i < addedCoatings.length; i++) {
            this._addedCoatings = this._addedCoatings.concat(addedCoatings[i]);
        }

        if (cleanAll) {
            // clean the whole room for a restyle
            this.clearAllForRoom();
        } else {
            // First clear room from any arrangement object and group coming from the smartDesigner root entities
            this.clearObjectsFromRoom();
            // Second clear cameras from room
            this.clearCamerasForRoom();
        }
        // Fill the room with smartDesigner solution
        this.fillRoom();
    }

    // Command name returned from a global enum
    name(): string {
        return CommandEnum.ApplySmartDesignerSolutionCommand;
    }

    // Datas that allows the command to be executed
    execDatas(): any {
        return {
            room: this._smartDesignedRoom,
            entities: this._addedEntities,
            coatings: this._addedCoatings,
        };
    }

    needsRefreshHull(): boolean {
        for (let i = 0; i < this._subCommands.length; i++) {
            if (this._subCommands[i].needsRefreshHull()) {
                return true;
            }
        }

        return false;
    }

    // Clear the room
    clearAllForRoom() {
        // Arrangements to remove
        let entitiesToRemove = this._smartDesignedRoom.children.filter((entity) => !entity.isRenderCameraEntity() || (entity as RenderCamera).cameraType !== SceneConstants.CameraType.PhotoRender);
        if (entitiesToRemove.length > 0) {
            // And store the command into the commands array for undo/redo
            this._subCommands.push(new DeleteEntitiesCommand(entitiesToRemove));
        }
    }

    // Clear the room and save (yes/no) its content
    clearObjectsFromRoom() {
        // Arrangements to remove
        let entitiesToRemove = [];

        // Get the entities to remove from room
        for (let i = 0; i < this._smartDesignedRoom.children.length; i++) {
            let entity = this._smartDesignedRoom.children[i];
            if (entity.isArrangementObjectEntity() || entity.isArrangementGroupEntity() || entity.isWorktopEntity() || entity.isGeometryPrimitiveEntity()) {
                for (let j = 0; j < this._smartDesignerParentEntityId.length; j++) {
                    // Check that the entity was generated by the smartDesignerParent entity
                    if ((entity as any).smartDesignerParentId === this._smartDesignerParentEntityId[j]) {
                        entitiesToRemove.push(entity);
                        break;
                    }
                }
            }
        }

        if (entitiesToRemove.length > 0) {
            // And store the command into the commands array for undo/redo
            this._subCommands.push(new DeleteEntitiesCommand(entitiesToRemove));
        }
    }

    // Clear the cameras coming from smartDesigner for a room
    clearCamerasForRoom() {
        // Get cameras from floor
        let cameras = this._smartDesignedRoom.floor.renderCameras;
        let roomCameras = this._smartDesignedRoom.children.filter((child) => child.isRenderCameraEntity() && !((child as RenderCamera).cameraType === SceneConstants.CameraType.PhotoRender)) as Array<RenderCamera>;
        cameras = cameras.concat(roomCameras);
        // Arrangements to remove
        let entitiesToRemove = [];

        // Parse all cameras searching the ones that comes from a smart design for this room
        for (let i = 0; i < cameras.length; i++) {
            if ((cameras[i] as any).smartDesignerParentId === this._smartDesignedRoom.id) {
                entitiesToRemove.push(cameras[i]);
            }
        }

        if (entitiesToRemove.length > 0) {
            // And store the command into the commands array for undo/redo
            this._subCommands.push(new DeleteEntitiesCommand(entitiesToRemove));
        }
    }

    // Clean all coating area from a wall according to is directSide
    cleanAllCoatingAreas(room: Room) {
        let i;

        // Get the coating area component
        for (i = 0; i < room.walls.length; i++) {
            let coatingArea = room.walls[i].getComponents(ComponentConstants.ComponentType.CoatingArea);
            for (let j = 0; j < coatingArea.length; j++) {
                let hangType = room.walls[i].getHangTypeForRoom(room);
                if (((coatingArea[j] as CoatingArea).isDirectSide && hangType == Coating.HangType.wallDirect) || (!(coatingArea[j] as CoatingArea).isDirectSide && hangType == Coating.HangType.wallUndirect)) {
                    this._subCommands.push(new DeleteComponentCommand(coatingArea[j], false));
                }
            }
        }
        for (i = 0; i < room.nonRoomedWalls.length; i++) {
            let coatingArea = room.nonRoomedWalls[i].getComponents(ComponentConstants.ComponentType.CoatingArea);
            for (let j = 0; j < coatingArea.length; j++) {
                this._subCommands.push(new DeleteComponentCommand(coatingArea[j], false));
            }
        }
    }

    // Clean the coatings from an entity if it is of hangtype hangType
    cleanCoatings(entity: Entity, hangType: Coating.HangType) {
        let components = entity.getComponents(ComponentConstants.ComponentType.Coating) as Array<Coating>;
        // Parse all coatings found
        for (let j = 0; j < components.length; j++) {
            // Remove only the correct direction of the coating, don't remove the other side
            if (components[j].hangType === hangType) {
                // And store the command into the commands array for undo/redo
                this._subCommands.push(new DeleteComponentCommand(components[j], false));
            }
        }
    }

    // Fill the room with the smart designer solution the object was created with
    fillRoom() {
        // And store the command into the commands array for undo
        this._subCommands.push(new AddEntitiesCommand(this._addedEntities, false, undefined, undefined));

        // Remove all initial coating areas in room beforehand
        this.cleanAllCoatingAreas(this._smartDesignedRoom);

        // Parse all coatings and assign them to their wall
        for (let i = 0; i < this._addedCoatings.length; i++) {
            // Wall found ?
            let found = false;

            // Find the wall entity in the room where smartDesigner was started
            for (let j = 0; j < this._smartDesignedRoom.walls.length; j++) {
                // Wall found (thanks to its id)
                if (this._smartDesignedRoom.walls[j].id === this._addedCoatings[i].forEntityId) {
                    // Wall found !
                    found = true;

                    // clean all coating area on the wall

                    if (this._addedCoatings[i].component.componentType === ComponentConstants.ComponentType.Coating) {
                        // If the coating hangtype wasn't given, let's compute it so the coating is inside the room
                        if (this._addedCoatings[i].component.hangType === undefined) {
                            // Find hangType so that the coating is inside the room
                            this._addedCoatings[i].component._hangType = this._smartDesignedRoom.walls[j].getHangTypeForRoom(this._smartDesignedRoom);
                        }

                        // Clean existing coatings
                        this.cleanCoatings(this._smartDesignedRoom.walls[j], this._addedCoatings[i].component._hangType);
                    }

                    // And store the command into the commands array for undo/redo
                    this._subCommands.push(new AddComponentCommand(this._addedCoatings[i].component, this._smartDesignedRoom.walls[j], false, false));
                    // Leave now
                    break;
                }
            }

            // If not found then the coating might below to non roomed walls²
            if (!found) {
                for (let j = 0; j < this._smartDesignedRoom.nonRoomedWalls.length; j++) {
                    // Wall found (thanks to its id)
                    if (this._smartDesignedRoom.nonRoomedWalls[j].id === this._addedCoatings[i].forEntityId) {
                        // Wall found !
                        found = true;

                        // If the coating hangtype wasn't given, say it will be on both sides
                        if (this._addedCoatings[i].component.hangType === undefined) {
                            // Clean existing coatings
                            this.cleanCoatings(this._smartDesignedRoom.nonRoomedWalls[j], Coating.HangType.wallUndirect);

                            // To create an additionalCoating to paint both sides
                            let additionalCoating;

                            // Create one more coating to paint both sides
                            if (this._addedCoatings[i].colors !== undefined) {
                                additionalCoating = new Coating(this._addedCoatings[i]._id, this._addedCoatings[i].manufacturer, this._addedCoatings[i].retailer, Coating.HangType.wallUndirect, [this._addedCoatings[i].colors], this._addedCoatings[i].randomization, this._addedCoatings[i].floorGeneratorSettings, this._addedCoatings[i].url);
                            } else {
                                additionalCoating = new Coating(this._addedCoatings[i]._id, this._addedCoatings[i].manufacturer, this._addedCoatings[i].retailer, Coating.HangType.wallUndirect, this._addedCoatings[i].configs, this._addedCoatings[i].randomization, this._addedCoatings[i].floorGeneratorSettings, this._addedCoatings[i].url);
                            }
                            // And store the command into the commands array for undo/redo
                            this._subCommands.push(new AddComponentCommand(additionalCoating, this._smartDesignedRoom.nonRoomedWalls[j], false, false));

                            // Say the current coating will be wall direct
                            this._addedCoatings[i].component._hangType = Coating.HangType.wallDirect;
                        }

                        // Clean existing coatings
                        this.cleanCoatings(this._smartDesignedRoom.nonRoomedWalls[j], this._addedCoatings[i].component._hangType);

                        // And store the command into the commands array for undo
                        this._subCommands.push(new AddComponentCommand(this._addedCoatings[i].component, this._smartDesignedRoom.nonRoomedWalls[j], false, false));
                        // Leave now
                        break;
                    }
                }
            }

            // If not found then the coating must belong to the room itself (ceiling or floor)
            if (!found) {
                // Check the coating is really for the room
                if (this._smartDesignedRoom.id === this._addedCoatings[i].forEntityId) {
                    // Clean existing coatings
                    this.cleanCoatings(this._smartDesignedRoom, this._addedCoatings[i].component._hangType);

                    // And store the command into the commands array for undo/redo
                    this._subCommands.push(new AddComponentCommand(this._addedCoatings[i].component, this._smartDesignedRoom, false, false));
                }
            }
        }
    }

    // Undo the current command
    undo() {
        // Undo all commands
        for (let i = this._subCommands.length - 1; i >= 0; i--) {
            this._subCommands[i].undo();
        }

        super.undo();
    }

    // Execute current command (redo)
    execute() {
        // Redo all commands
        for (let i = 0; i < this._subCommands.length; i++) {
            this._subCommands[i].execute();
        }

        super.execute();
    }
}
