import { Command, CommandEnum } from './CommandModule';
import { Events } from '../events';
import { eventsManager } from '../managers/EventsManager';
import { Floor } from '../SavaneJS';

export class SetFloorCornicesCommand extends Command
{
    // Cornices activated or not
    private _activate: boolean;
    // Floor to apply to
    private _floor: Floor;

    constructor(activate: boolean, floor: Floor) {
        super();
        // Activate of not value
        this._activate = activate;
        // Store floor to set cornices on, default will be current floor if not specified in the parameters
        this._floor = floor;
    }

    // Command name returned from a global enum
    name(): string {
        return CommandEnum.SetFloorCornicesCommand;
    }

    // Datas that allows the command to be executed
    execDatas(): any {
        return {
            activate: this._activate,
            floor: this._floor
        };
    }

    // Undo the current command
    undo() {
        let floorRooms = this._floor.rooms;

        for (let i = 0; i < floorRooms.length; i++) {
            floorRooms[i].cornices = !this._activate;
        }

        super.undo();

        eventsManager.instance.dispatch(Events.PROJECT_RELOAD);
    }

    // Execute current command (redo), call the applyTranslation method with the translation values initialised at start
    execute() {
        let floorRooms = this._floor.rooms;

        for (let i = 0; i < floorRooms.length; i++) {
            floorRooms[i].cornices = this._activate;
        }

        super.execute();

        eventsManager.instance.dispatch(Events.PROJECT_RELOAD);
    }
};
