import { Command, CommandEnum, EditEntityCommand } from './CommandModule';
import { Events } from '../events';
import { eventsManager } from '../managers/EventsManager';
import { ArrangementGroup, ArrangementObject, Entity } from '../SavaneJS';

export class SnapEntitiesCommand  extends Command
{
    // Command list to execute and undo
    private _subCommands: Array<Command> = [];
    // Record the entity Array
    private _editedEntitiesArray: Array<Entity> = [];    
    // Lowest item enity
    private _snapEntity: Entity = null;

    constructor(editedEntitiesArray: Array<Entity>, mode = SnapEntitiesCommand.SnapEnum.CenterOnTop) {
        super();

        // filter entities to keep iArrangementEntities only and find the lowest item
        for (let i = 0; i < editedEntitiesArray.length; ++i) {
            if (editedEntitiesArray[i].isIArrangementEntity()) {
                editedEntitiesArray[i].startTemporary();
                this._editedEntitiesArray.push(editedEntitiesArray[i]);
                this._snapEntity = this._snapEntity === null ? editedEntitiesArray[i] : 
                    (this._snapEntity.floorHeight > editedEntitiesArray[i].floorHeight ? editedEntitiesArray[i] : this._snapEntity);
            }
        }

        // snap entities and create edit commands
        for (let i = 0; i < this._editedEntitiesArray.length; ++i) {
            let entity = this._editedEntitiesArray[i];
            if (entity === this._snapEntity) continue;

            switch (mode) {
                case SnapEntitiesCommand.SnapEnum.CenterOnTop:
                    entity.position = this._snapEntity.position;
                    entity.floorHeight = this._snapEntity.floorHeight + (this._snapEntity as (ArrangementObject | ArrangementGroup)).height;
                break;
            }

            this._subCommands.push(new EditEntityCommand(entity));
        }
    }

    // Command name returned from a global enum
    name(): string {
        return CommandEnum.SnapEntitiesCommand;
    }

    needsRefreshHull(): boolean {
        for (let i = 0; i < this._subCommands.length; i++) {
            if (this._subCommands[i].needsRefreshHull()) {
                return (true);
            }
        }

        return (false);
    }

    // Undo the current command
    undo() {
        // Parse all commands created by the constructor and undo them all
        for (let i = 0; i < this._subCommands.length; i++) {
            this._subCommands[i].undo();
        }

        // Execute parent function
        super.undo();

        // Select edited object at the end
        eventsManager.instance.dispatch(Events.CHANGE_EDITOR_SELECTION,
            {
                selection: this._editedEntitiesArray,
                keepSelected: false,
                showTulip: false
            });
    }

    // Execute current command (redo)
    execute() {
        // Parse all commands created by the constructor and execute them all
        for (let i = 0; i < this._subCommands.length; i++) {
            this._subCommands[i].execute();
        }

        //Execute parent function
        super.execute();

        // Select edited object at the end
        eventsManager.instance.dispatch(Events.CHANGE_EDITOR_SELECTION,
            {
                selection: this._editedEntitiesArray,
                keepSelected: false,
                showTulip: false
            });
    }
};

export namespace SnapEntitiesCommand
{
    export enum SnapEnum {
        CenterOnTop = 1        
    };
}
