import { Command, CommandEnum } from "./CommandModule";
import { Events } from "../events";
import { eventsManager } from "../managers/EventsManager";
import { Area, Coating, ComponentConstants, EntityFactory, Entity, Floor, Wall, Room, SceneConstants, SavaneConstants, wallManager, roomManager, math, SavaneMath } from "../SavaneJS";

export class EditPointCommand extends Command {

    private _beginPosition: math.vec3;
    private _endPosition: math.vec3;
    private _currentFloor: Floor;
    private _selectedEntities: Array<Entity>;
    private _wallsEdited: Array<Wall>;
    private _roomsEdited: Array<Room>;
    private _firstTime: boolean;

    constructor(begin: math.vec3, end: math.vec3, currentFloor: Floor, selectedEntities: Array<Entity>) {
        super();
        this._beginPosition = math.vec3.create();
        math.vec3.copy(this._beginPosition, begin);
        this._endPosition = math.vec3.create();
        math.vec3.copy(this._endPosition, end);
        this._currentFloor = currentFloor;
        // Selected entities array
        this._selectedEntities = selectedEntities;

        this._wallsEdited = wallManager.getWallsAtCornerAngleOrdered(this._beginPosition, currentFloor, SavaneConstants.PositionTolerance, -1);

        // Keep track of edited rooms
        this._roomsEdited = [];
        for (let i = 0; i < this._wallsEdited.length; i++) {
            for (let j = 0; j < this._wallsEdited[i].rooms.length; j++) {
                let found = false;

                for (let k = 0; k < this._roomsEdited.length; k++) {
                    if (this._wallsEdited[i].rooms[j].id === this._roomsEdited[k].id) {
                        found = true;
                        break;
                    }
                }

                if (!found) {
                    this._roomsEdited.push(EntityFactory.cloneEntity(this._wallsEdited[i].rooms[j], false) as Room);
                }
            }
        }
        this._firstTime = true;
    }

    name(): string {
        return CommandEnum.EditPointCommand;
    }

    undo() {
        for (let i = this._actions.length - 1; i >= 0; i--) {
            this.undoAction(this._actions[i]);
        }

        let wallsBegin = wallManager.getWallsAtPosition(this._beginPosition, this._currentFloor, SavaneConstants.PositionTolerance, -1, null, false);

        for (let i = 0; i < wallsBegin.length; i++) {
            this.updateAdjacentWall(wallsBegin[i]);
            let room = roomManager.deleteNonRoomedWall(wallsBegin[i].id, this._currentFloor);
            if (room !== null) {
                eventsManager.instance.dispatch(Events.REDRAW_GRAPHICAL_ROOM, {
                    entity: room,
                });
            }
        }

        let wallsEnd = wallManager.getWallsAtPosition(this._endPosition, this._currentFloor, SavaneConstants.PositionTolerance, -1, null, false);
        if (wallsEnd !== null) {
            for (let i = 0; i < wallsEnd.length; i++) {
                let wall = wallsEnd[i];
                roomManager.deleteNonRoomedWall(wall.id, this._currentFloor);
                eventsManager.instance.dispatch(Events.REDRAW_GRAPHICAL_WALL, {
                    entity: wall,
                });
                //GameObjectManager.drawWall(wall, true, false, false, -1, null, true);
                for (let j = 0; j < wall.rooms.length; j++) {
                    eventsManager.instance.dispatch(Events.REDRAW_GRAPHICAL_ROOM, {
                        entity: wall.rooms[j],
                    });
                }
            }
        }

        this.collectAndMatchCreatedRooms(wallsBegin);

        roomManager.manageNonRoomedWalls(this._currentFloor);

        // Select object at the end
        eventsManager.instance.dispatch(Events.CHANGE_EDITOR_SELECTION, {
            selection: this._wallsEdited,
            keepSelected: false,
            showTulip: false,
        });
    }

    execute() {
        let walls, wall;
        //First time define action
        if (this._firstTime) {
            this._firstTime = false;

            //wallCut at the end position
            walls = wallManager.getWallsAtPosition(this._endPosition, this._currentFloor, SavaneConstants.PositionTolerance, -1, null, false);
            if (walls !== null) {
                for (let i = walls.length - 1; i >= 0; ) {
                    if (this._wallsEdited.indexOf(walls[i]) !== -1) {
                        walls.splice(i, 1);
                    } else {
                        i--;
                    }
                }

                if (walls.length === 1 && !walls[0].isCorner(this._endPosition)) {
                    if (walls[0].shiftDirection !== 0) {
                        let snapPoint = SavaneMath.getCrossPoint(walls[0].begin, walls[0].end, this._beginPosition, this._endPosition, null);
                        if (snapPoint !== null) {
                            this._endPosition = snapPoint;
                        }
                    }

                    let cutAction = this.createCutAction(walls[0], this._endPosition);
                    this.doAction(cutAction);
                }
            }

            let itemToRecompute: Array<Entity> = new Array<Entity>();
            for (let i = 0; i < this._wallsEdited.length; i++) {
                wall = this._wallsEdited[i];
                for (let j = 0; j < wall.rooms.length; j++) {
                    for (let k = 0; k < wall.rooms[j].children.length; k++) {
                        if (itemToRecompute.findIndex((item) => item.id === wall.rooms[j].children[k]._id) === -1) {
                            itemToRecompute.push(wall.rooms[j].children[k]);
                        }
                    }
                }
            }

            for (let i = 0; i < this._wallsEdited.length; i++) {
                wall = this._wallsEdited[i];
                roomManager.deleteNonRoomedWall(wall.id, this._currentFloor);
                let editAction;
                if (wall.isBegin(this._beginPosition)) {
                    editAction = { type: Command.ActionType.edit, wall: wall, isBegin: true };
                } else {
                    editAction = { type: Command.ActionType.edit, wall: wall, isBegin: false };
                }

                this._actions.push(editAction);
                this.doAction(editAction);
            }

            //Recompute all items
            for (let i = 0; i < itemToRecompute.length; i++) {
                let item = itemToRecompute[i];
                //Recompute parent
                if (item.isArrangementObjectEntity() || item.isArrangementGroupEntity() || item.isRenderCameraEntity() || item.isGeometryPrimitiveEntity() || item.isWorktopEntity()) {
                    // Find containing room
                    let roomContaining: Room = null;

                    if (item.isWorktopEntity()) {
                        let area = item.getComponent(ComponentConstants.ComponentType.Area);
                        if (area) {
                            roomContaining = roomManager.getRoomAtPosition((area as Area).vertices[0], this._currentFloor);
                        }
                    } else {
                        roomContaining = roomManager.getRoomAtPosition(item.position, this._currentFloor);
                    }

                    if (roomContaining !== null) {
                        //set item child of room
                        if (item.parent !== null) {
                            item.parent.deleteChild(item.id);
                        }
                        roomContaining.addChild(item);
                    } else {
                        //set item child of floor
                        if (item.parent !== null) {
                            item.parent.deleteChild(item.id);
                        }
                        this._currentFloor.addChild(item);
                    }
                    // Graphical node
                    eventsManager.instance.dispatch(Events.ADD_GRAPHICAL_ENTITY, {
                        entity: item,
                    });
                }
            }

            for (let i = 0; i < this._wallsEdited.length; i++) {
                this.findAndAddActions(this._wallsEdited[i]);
            }

            walls = wallManager.getWallsAtCorner(this._endPosition, this._currentFloor, SavaneConstants.PositionTolerance, false, -1);
            if (walls.length === 2) {
                if (Wall.mergeWall(walls[0], walls[1]) !== null) {
                    let mergeAction = this.createMergeAction(walls[0], walls[1]);
                    this.doAction(mergeAction);
                }
            }
        } else {
            eventsManager.instance.dispatch(Events.CHANGE_EDITOR_SELECTION, {
                selection: [],
                keepSelected: false,
                showTulip: false,
            });
            //Action already defines execute them
            for (let i = 0; i < this._actions.length; i++) {
                this.doAction(this._actions[i]);
            }
        }

        walls = wallManager.getWallsAtPosition(this._endPosition, this._currentFloor, SavaneConstants.PositionTolerance, -1, null, false);
        if (walls !== null) {
            for (let i = 0; i < walls.length; i++) {
                this.updateAdjacentWall(walls[i]);
            }

            this.collectAndMatchCreatedRooms(walls);
        }

        roomManager.manageNonRoomedWalls(this._currentFloor);

        // Select object at the end
        eventsManager.instance.dispatch(Events.CHANGE_EDITOR_SELECTION, {
            selection: walls,
            keepSelected: false,
            showTulip: false,
        });
    }

    collectAndMatchCreatedRooms(walls: Array<Wall>) {
        // Get all created rooms
        let _roomsCreated: Array<Room> = new Array<Room>();
        for (let i = 0; i < walls.length; i++) {
            for (let j = 0; j < walls[i].rooms.length; j++) {
                let found = false;

                for (let k = 0; k < _roomsCreated.length; k++) {
                    if (walls[i].rooms[j].id === _roomsCreated[k].id) {
                        found = true;
                        break;
                    }
                }

                if (!found) {
                    _roomsCreated.push(walls[i].rooms[j]);
                }
            }
        }

        // Try to match them with original collected rooms during command constructor
        for (let i = 0; i < _roomsCreated.length; i++) {
            let found = false;
            let j;

            for (j = 0; j < this._roomsEdited.length; j++) {
                if (this.matchRooms(_roomsCreated[i], walls, this._roomsEdited[j])) {
                    found = true;
                    break;
                }
            }

            if (found) {
                _roomsCreated[i].cornices = this._roomsEdited[j].cornices;
                _roomsCreated[i].floorHeight = this._roomsEdited[j].floorHeight;
                _roomsCreated[i].plinths = this._roomsEdited[j].plinths;
                _roomsCreated[i].plinthsHeight = this._roomsEdited[j].plinthsHeight;
                _roomsCreated[i].plinthsMaterialType = this._roomsEdited[j].plinthsMaterialType;
                _roomsCreated[i].height = this._roomsEdited[j].height;

                // Copy components
                for (let k = 0; k < this._roomsEdited[j].components.length; k++) {
                    if (this._roomsEdited[j].components[k].componentType === ComponentConstants.ComponentType.Functionality) {
                        _roomsCreated[i].addComponent(this._roomsEdited[j].components[k].clone());
                    }

                    if (this._roomsEdited[j].components[k].componentType === ComponentConstants.ComponentType.Coating) {
                        for (let l = 0; l < _roomsCreated[i].components.length; l++) {
                            if (_roomsCreated[i].components[l].componentType === this._roomsEdited[j].components[k].componentType && (_roomsCreated[i].components[l] as Coating).hangType === (this._roomsEdited[j].components[k] as Coating).hangType) {
                                _roomsCreated[i].removeComponent(_roomsCreated[i].components[l]);
                            }
                        }
                        _roomsCreated[i].addComponent(this._roomsEdited[j].components[k].clone());
                    }
                }

                eventsManager.instance.dispatch(Events.REDRAW_GRAPHICAL_ROOM, {
                    entity: _roomsCreated[i],
                });
            }
        }
    }

    matchRooms(room1: Room, excludedWalls: Array<Wall>, room2: Room) {
        for (let i = 0; i < room1.walls.length; i++) {
            let checkNeeded = true;

            for (let j = 0; j < excludedWalls.length; j++) {
                if (room1.walls[i].id === excludedWalls[j].id) {
                    checkNeeded = false;
                    break;
                }
            }

            if (checkNeeded) {
                let found = false;

                for (let j = 0; j < room2.walls.length; j++) {
                    if (room1.walls[i].id === room2.walls[j].id) {
                        found = true;
                        break;
                    }
                }

                if (!found) {
                    return false;
                }
            }
        }

        return true;
    }

    undoAction(action) {
        switch (action.type) {
            case Command.ActionType.edit:
                this.editOneWall(action.wall, this._beginPosition, action.isBegin);
                wallManager.deleteWallForCommand(action.wall.id, true, this._currentFloor, this._selectedEntities);
                wallManager.addWallForCommand(action.wall, true, this._currentFloor, action.wall.height);
                break;
            case Command.ActionType.cutWall:
                wallManager.mergeWallsForCommand(action.wallMerge, action.wallsCut, this._currentFloor, this._selectedEntities);
                break;
            case Command.ActionType.mergeWall:
                wallManager.cutWallForCommand(action.wallMerge, action.wallsCut, this._currentFloor, this._selectedEntities);
                break;
            default:
                break;
        }
    }

    doAction(action) {
        switch (action.type) {
            case Command.ActionType.edit:
                this.editOneWall(action.wall, this._endPosition, action.isBegin);
                wallManager.deleteWallForCommand(action.wall.id, true, this._currentFloor, this._selectedEntities);
                wallManager.addWallForCommand(action.wall, true, this._currentFloor, action.wall.height);
                break;
            case Command.ActionType.cutWall:
                wallManager.cutWallForCommand(action.wallMerge, action.wallsCut, this._currentFloor, this._selectedEntities);
                // Copy again shift direction from original wall, it might have been cleared by the cutWall (1st wall mainly)
                action.wallsCut[0].shiftDirection = action.wallMerge.shiftDirection;
                action.wallsCut[1].shiftDirection = -action.wallMerge.shiftDirection;
                break;
            case Command.ActionType.mergeWall:
                wallManager.mergeWallsForCommand(action.wallMerge, action.wallsCut, this._currentFloor, this._selectedEntities);
                break;
            default:
                break;
        }
    }

    findAndAddActions(wall: Wall) {
        let point = wall.isBegin(this._endPosition) ? wall.end : wall.begin;
        let walls = wallManager.getWallsAtCorner(point, this._currentFloor, SavaneConstants.PositionTolerance, false, -1);
        if (walls.length === 2) {
            if (Wall.mergeWall(walls[0], walls[1]) !== null) {
                let mergeAction = this.createMergeAction(walls[0], walls[1]);
                this.doAction(mergeAction);
            }
        }
    }

    editOneWall(wall: Wall, finalPoint: math.vec3, isBegin: boolean) {
        let distOnWall: Array<number> = new Array<number>();
        let j;
        if (isBegin) {
            for (j = 0; j < wall.joineries.length; j++) {
                distOnWall.push(math.vec2.dist(math.vec2.fromValues(wall.shiftedEnd[0], wall.shiftedEnd[1]), math.vec2.fromValues(wall.joineries[j].position[0], wall.joineries[j].position[1])));
            }
            let originalLength = wall.length;
            wall.begin = finalPoint;
            let finalLength = wall.length;
            let direction = math.vec3.clone(wall.wallDirection);
            for (j = 0; j < wall.joineries.length; j++) {
                if (wall.joineries[j].joineryType !== SceneConstants.JoineryType.velux) {
                    let position = math.vec3.clone(wall.joineries[j].position);
                    position[0] = wall.shiftedEnd[0] - direction[0] * distOnWall[j];
                    position[1] = wall.shiftedEnd[1] - direction[1] * distOnWall[j];

                    wall.joineries[j].position = position;
                } else {
                    let deltaLength = finalLength - originalLength;
                    wall.joineries[j].transform.localMatrix[12] = wall.joineries[j].transform.localMatrix[12] + deltaLength;
                }
            }
        } else {
            for (j = 0; j < wall.joineries.length; j++) {
                distOnWall.push(math.vec2.dist(math.vec2.fromValues(wall.shiftedBegin[0], wall.shiftedBegin[1]), math.vec2.fromValues(wall.joineries[j].position[0], wall.joineries[j].position[1])));
            }
            wall.end = finalPoint;
            let direction = math.vec3.clone(wall.wallDirection);
            for (j = 0; j < wall.joineries.length; j++) {
                if (wall.joineries[j].joineryType !== SceneConstants.JoineryType.velux) {
                    let position = math.vec3.clone(wall.joineries[j].position);
                    position[0] = wall.shiftedBegin[0] + direction[0] * distOnWall[j];
                    position[1] = wall.shiftedBegin[1] + direction[1] * distOnWall[j];

                    wall.joineries[j].position = position;
                }
            }
        }

        eventsManager.instance.dispatch(Events.REDRAW_GRAPHICAL_WALL, {
            entity: wall,
        });
    }

    updateAdjacentWall(wall: Wall) {
        let walls: Array<Wall> = wallManager.getWallsAtPosition(wall.begin, this._currentFloor, SavaneConstants.PositionTolerance, -1, null, false);
        let roomRefreshed: Array<Room> = new Array<Room>();
        let wallToDrawBegin: Wall | null = null, wallToDrawEnd: Wall | null = null;

        if (walls !== null) {
            for (let i = 0; i < walls.length; i++) {
                wallToDrawBegin = walls[i];
                eventsManager.instance.dispatch(Events.REDRAW_GRAPHICAL_WALL, {
                    entity: wallToDrawBegin,
                });
                for (let j = 0; j < wallToDrawBegin.rooms.length; j++) {
                    if (roomRefreshed.indexOf(wallToDrawBegin.rooms[j]) === -1) {
                        roomRefreshed.push(wallToDrawBegin.rooms[j]);
                        eventsManager.instance.dispatch(Events.REDRAW_GRAPHICAL_ROOM, {
                            entity: wallToDrawBegin.rooms[j],
                        });
                    }
                }
            }
        }

        walls = wallManager.getWallsAtPosition(wall.end, this._currentFloor, SavaneConstants.PositionTolerance, -1, null, false);
        if (walls !== null) {
            for (let i = 0; i < walls.length; i++) {
                wallToDrawEnd = walls[i];
                eventsManager.instance.dispatch(Events.REDRAW_GRAPHICAL_WALL, {
                    entity: wallToDrawEnd,
                });
                for (let j = 0; j < wallToDrawEnd.rooms.length && wallToDrawBegin; j++) {
                    if (roomRefreshed.indexOf(wallToDrawBegin.rooms[j]) === -1) {
                        roomRefreshed.push(wallToDrawBegin.rooms[j]);
                        eventsManager.instance.dispatch(Events.REDRAW_GRAPHICAL_ROOM, {
                            entity: wallToDrawEnd.rooms[j],
                        });
                    }
                }
            }
        }
    }
}
