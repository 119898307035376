import { SceneConstants } from "../SceneConstants";
import { Staircase } from "./Staircase";

export class StraightStaircase extends Staircase {

    constructor(id: number) {
        super(id, SceneConstants.StaircaseType.straight, 1000, 3000, 2500, null);
    }
    
}
