import { VersionHandler } from "./VersionHandler";

import { EntityFactory } from "../scene/EntityFactory";
import { Layer } from "../scene/Layer";
import { Scene } from "../scene/Scene";
import { SketchBlock } from "../scene/SketchBlock";
import { UserPicture } from "../scene/UserPicture";
import { WorkTop } from "../scene/WorkTop";
import { World } from "../scene/World";
import { SceneConstants } from "../scene/SceneConstants";
import { Room } from "../scene/Room";

import { math } from "../scene/Transform";

import { AddArea } from "../components/AddArea";
import { Area } from "../components/Area";
import { Coating } from "../components/Coating";
import { CoatingArea } from "../components/CoatingArea";
import { CorniceType } from "../components/CorniceType";
import { Credence } from "../components/Credence";
import { CustomCoating } from "../components/CustomCoating";
import { CutArea } from "../components/CutArea";
import { DomeLight } from "../components/DomeLight";
import { FloorCoatingArea } from "../components/FloorCoatingArea";
import { Functionality } from "../components/Functionality";
import { JoineryType } from "../components/JoineryType";
import { PlainData } from "../components/PlainData";
import { RendererTopView } from "../components/RendererTopView";
import { RoomQuotation } from "../components/RoomQuotation";
import { TechnicalElementType } from "../components/TechnicalElementType";
import { TemplateImage } from "../components/TemplateImage";
import { WallType } from "../components/WallType";
import { ComponentConstants } from "../components/ComponentConstants";
import { Canopy, CupboardDoor, Cylinder, Door, FrenchDoor, Hood, joineryManager, Niche, PictureWindow, Sphere, Window } from "../SavaneJS";

/**
 * Class wich contains importation functions
 */
export class JSONImporter {

    private static duplicate_zero_list = null; // FIXME this is a temporary fix to handle multiple entity with id 0 due to a permissive factory error

    private static _externalImportComponents = new Array();
    public static createdRoom: Array<Room> = new Array<Room>();

    constructor() {}

    static get externalImportComponents() {
        return JSONImporter._externalImportComponents;
    }

    static set externalImportComponents(funcs) {
        JSONImporter._externalImportComponents = funcs;
    }

    /**
     *  Generate a layer from the entry json
     */
    static importLayer(JSONLayer, scene) {
        let layer = new Layer(JSONLayer.name);
        //Import entity list
        for (let i = 0; i < JSONLayer.entities.length; i++) {
            var entity = scene.getDeepChild(JSONLayer.entities[i]);
            if (entity !== null) {
                layer.addEntity(entity);
            } else {
                console.warn("Can't find entity" + JSONLayer.entities[i] + "referenced in layer : " + layer.name);
            }
        }
        return layer;
    }

    /**
     * import the world from JSON format
     *
     * @param {Object} JSONworld
     * @returns {*} world
     */
    static importWorld(JSONworld) {
        try {
            JSONworld = VersionHandler.toLastVersion(JSONworld);
            if (JSONworld.entityType !== 20) {
                // This is a scene with a format version 2... This shoudn't be
                if (JSONworld.entityType === 2) {
                    let world = EntityFactory.createWorld();
                    world.children[0] = this.importScene(JSONworld);
                    return world;
                }
                return null;
            } else {
                let world = new World(JSONworld.id);
                //*****************
                for (var i = 0; i < JSONworld.children.length; ++i) {
                    world.addChild(this.importScene(JSONworld.children[i]));
                }

                // Get all benchmarck infos
                if (JSONworld.activeDrawTime !== undefined) {
                    world._activeDrawTime = JSONworld.activeDrawTime;
                }
                if (JSONworld.idleDrawTime !== undefined) {
                    world._idleDrawTime = JSONworld.idleDrawTime;
                }
                if (JSONworld.idleDrawDurations !== undefined) {
                    world._idleDrawDurations = JSONworld.idleDrawDurations;
                }

                if (JSONworld.activeDrawCRTime !== undefined) {
                    world._activeDrawCRTime = JSONworld.activeDrawCRTime;
                }
                if (JSONworld.idleDrawCRTime !== undefined) {
                    world._idleDrawCRTime = JSONworld.idleDrawCRTime;
                }
                if (JSONworld.idleDrawCRDurations !== undefined) {
                    world._idleDrawCRDurations = JSONworld.idleDrawCRDurations;
                }

                if (JSONworld.activeDrawCRVisioTime !== undefined) {
                    world._activeDrawCRVisioTime = JSONworld.activeDrawCRVisioTime;
                }
                if (JSONworld.idleDrawCRVisioTime !== undefined) {
                    world._idleDrawCRVisioTime = JSONworld.idleDrawCRVisioTime;
                }
                if (JSONworld.idleDrawCRVisioDurations !== undefined) {
                    world._idleDrawCRVisioDurations = JSONworld.idleDrawCRVisioDurations;
                }

                if (JSONworld.activeConstructionTime !== undefined) {
                    world._activeConstructionTime = JSONworld.activeConstructionTime;
                }
                if (JSONworld.idleConstructionTime !== undefined) {
                    world._idleConstructionTime = JSONworld.idleConstructionTime;
                }
                if (JSONworld.idleConstructionDurations !== undefined) {
                    world._idleConstructionDurations = JSONworld.idleConstructionDurations;
                }

                if (JSONworld.activeConstructionCRTime !== undefined) {
                    world._activeConstructionCRTime = JSONworld.activeConstructionCRTime;
                }
                if (JSONworld.idleConstructionCRTime !== undefined) {
                    world._idleConstructionCRTime = JSONworld.idleConstructionCRTime;
                }
                if (JSONworld.idleConstructionCRDurations !== undefined) {
                    world._idleConstructionCRDurations = JSONworld.idleConstructionCRDurations;
                }

                if (JSONworld.activeConstructionCRVisioTime !== undefined) {
                    world._activeConstructionCRVisioTime = JSONworld.activeConstructionCRVisioTime;
                }
                if (JSONworld.idleConstructionCRVisioTime !== undefined) {
                    world._idleConstructionCRVisioTime = JSONworld.idleConstructionCRVisioTime;
                }
                if (JSONworld.idleConstructionCRVisioDurations !== undefined) {
                    world._idleConstructionCRVisioDurations = JSONworld.idleConstructionCRVisioDurations;
                }

                if (JSONworld.activeDecorationTime !== undefined) {
                    world._activeDecorationTime = JSONworld.activeDecorationTime;
                }
                if (JSONworld.idleDecorationTime !== undefined) {
                    world._idleDecorationTime = JSONworld.idleDecorationTime;
                }
                if (JSONworld.idleDecorationDurations !== undefined) {
                    world._idleDecorationDurations = JSONworld.idleDecorationDurations;
                }

                if (JSONworld.activeDecorationCRTime !== undefined) {
                    world._activeDecorationCRTime = JSONworld.activeDecorationCRTime;
                }
                if (JSONworld.idleDecorationCRTime !== undefined) {
                    world._idleDecorationCRTime = JSONworld.idleDecorationCRTime;
                }
                if (JSONworld.idleDecorationCRDurations !== undefined) {
                    world._idleDecorationCRDurations = JSONworld.idleDecorationCRDurations;
                }

                if (JSONworld.activeDecorationCRVisioTime !== undefined) {
                    world._activeDecorationCRVisioTime = JSONworld.activeDecorationCRVisioTime;
                }
                if (JSONworld.idleDecorationCRVisioTime !== undefined) {
                    world._idleDecorationCRVisioTime = JSONworld.idleDecorationCRVisioTime;
                }
                if (JSONworld.idleDecorationCRVisioDurations !== undefined) {
                    world._idleDecorationCRVisioDurations = JSONworld.idleDecorationCRVisioDurations;
                }

                if (JSONworld.logActiveTime !== undefined) {
                    world._logActiveTime = JSONworld.logActiveTime;
                }
                if (JSONworld.logIdleTime !== undefined) {
                    world._logIdleTime = JSONworld.logIdleTime;
                }

                if (JSONworld.nbAMRequests !== undefined) {
                    world._nbAMRequests = JSONworld.nbAMRequests;
                }
                if (JSONworld.totalTimeAMRequests !== undefined) {
                    world._totalTimeAMRequests = JSONworld.totalTimeAMRequests;
                }
                if (JSONworld.maxTimeAMRequests !== undefined) {
                    world._maxTimeAMRequests = JSONworld.maxTimeAMRequests;
                }
                if (JSONworld.minTimeAMRequests !== undefined) {
                    world._minTimeAMRequests = JSONworld.minTimeAMRequests;
                }
                if (JSONworld.nbStaticHullRequests !== undefined) {
                    world._nbStaticHullRequests = JSONworld.nbStaticHullRequests;
                }
                if (JSONworld.totalTimeStaticHullRequests !== undefined) {
                    world._totalTimeStaticHullRequests = JSONworld.totalTimeStaticHullRequests;
                }
                if (JSONworld.nbDynamicHullRequests !== undefined) {
                    world._nbDynamicHullRequests = JSONworld.nbDynamicHullRequests;
                }
                if (JSONworld.totalTimeDynamicHullRequests !== undefined) {
                    world._totalTimeDynamicHullRequests = JSONworld.totalTimeDynamicHullRequests;
                }
                if (JSONworld.nbFloorHullRequests !== undefined) {
                    world._nbFloorHullRequests = JSONworld.nbFloorHullRequests;
                }
                if (JSONworld.totalTimeFloorHullRequests !== undefined) {
                    world._totalTimeFloorHullRequests = JSONworld.totalTimeFloorHullRequests;
                }
                world.currentScene = world.children.length > 0 ? world.children[0] as Scene : null;
                return world;
            }
        } catch (e) {
            console.error(e);
            throw e;
        }
    }

    /**
     * import the scene from JSON format
     *
     * @param {Object} JSONscene
     * @returns {*} scene
     */
    static importScene(JSONscene) {
        try {
            JSONImporter.createdRoom = new Array<Room>();
            if (JSONscene.entityType !== 2) {
                return null;
            } else {
                let scene = new Scene(JSONscene.id);
                if (JSONscene.name) {
                    scene.name = JSONscene.name;
                }
                //FIXME Temporary fix for multiple zero id entities
                JSONImporter.duplicate_zero_list = [];
                if (JSONscene.id === 0) {
                    JSONImporter.duplicate_zero_list.push(scene);
                }
                //delete floor created at the scene creation
                scene.children = [];
                JSONImporter.importChildAndComponent(JSONscene, scene);
                scene.currentFloor = scene.floors.length > 0 ? scene.floors[0] : null;
                //FIXME UPDATE ID to remove duplicate 0
                if (JSONImporter.duplicate_zero_list.length > 1) {
                    for (var i = 0; i < JSONImporter.duplicate_zero_list.length - 1; i++) {
                        console.log("Had to change id of an entity of type " + JSONImporter.duplicate_zero_list[i].entityType);
                        JSONImporter.duplicate_zero_list[i].id = EntityFactory.incLastEntityId();
                    }
                }
                return scene;
            }
        } catch (e) {
            console.error(e);
            throw e;
        }
    }

    /**
     * import the entity from JSON format
     *
     */
    static importEntity(JSONEntity, parent) {
        let importedObj = null;
        switch (JSONEntity.entityType) {
            case SceneConstants.EntityType.Wall:
                //don't call addWall not to create again rooms
                importedObj = JSONImporter.importWall(JSONEntity);
                break;
            case SceneConstants.EntityType.Joinery:
                //call addJoinery place the joinery on the wall
                importedObj = JSONImporter.importJoinery(JSONEntity);
                break;
            case SceneConstants.EntityType.Scene:
                break;
            case SceneConstants.EntityType.ArrangementObject:
                importedObj = JSONImporter.importArrangement(JSONEntity);
                break;
            case SceneConstants.EntityType.TechnicalElement:
                importedObj = JSONImporter.importTechnicalElement(JSONEntity);
                break;
            case SceneConstants.EntityType.Staircase:
                importedObj = JSONImporter.importStaircase(JSONEntity);
                break;
            case SceneConstants.EntityType.Floor:
                importedObj = JSONImporter.importFloor(JSONEntity);
                break;
            case SceneConstants.EntityType.ArrangementGroup:
                importedObj = JSONImporter.importArrangementGroup(JSONEntity);
                break;
            case SceneConstants.EntityType.Room:
                //Avoid importing room before all walls are loaded
                break;
            case SceneConstants.EntityType.FunctionalityChip:
                //imported with rooms
                importedObj = JSONImporter.importFunctionalityChip(JSONEntity);
                break;
            case SceneConstants.EntityType.RenderCamera:
                //imported with rooms
                importedObj = JSONImporter.importRenderCamera(JSONEntity);
                break;
            // Deprecated don't import anymore
            case SceneConstants.EntityType.Light:
                break;
            case SceneConstants.EntityType.Comment:
                importedObj = JSONImporter.importComment(JSONEntity);
                break;
            case SceneConstants.EntityType.ArrangementZone:
                //imported with rooms
                importedObj = JSONImporter.importArrangementZone(JSONEntity);
                break;
            // Deprecated don't import anymore
            case SceneConstants.EntityType.ArrangementDecorated:
                //imported arrangement group with a master element
                break;
            case SceneConstants.EntityType.SketchBlock:
                importedObj = JSONImporter.importSketchBlock(JSONEntity);
                break;
            case SceneConstants.EntityType.WorkTop:
                importedObj = JSONImporter.importWorkTop(JSONEntity);
                break;
            case SceneConstants.EntityType.UserPicture:
                importedObj = JSONImporter.importUserPicture(JSONEntity);
                break;
            case SceneConstants.EntityType.Sun:
                importedObj = JSONImporter.importSun(JSONEntity);
                break;
            case SceneConstants.EntityType.GeometryPrimitive:
                importedObj = JSONImporter.importGeometryPrimitive(JSONEntity);
                break;
            case SceneConstants.EntityType.GeometryGroup:
                importedObj = JSONImporter.importGeometryGroup(JSONEntity);
                break;
            case SceneConstants.EntityType.Scene:
                importedObj = JSONImporter.importScene(JSONEntity);
        }
        if (importedObj !== null) {
            if (parent !== null) {
                if (JSONEntity.entityType === SceneConstants.EntityType.Joinery) {
                    parent.addJoinery(importedObj);
                } else {
                    parent.addChild(importedObj);
                }
            }

            math.mat4.copy(importedObj.transform.localMatrix, JSONEntity.transform.localMatrix);

            // RETROCOMPATIBILITY WHEN FLOORHEIGHT WAS STORED AS A SEPARATE VARIABLE
            if (JSONEntity.entityType === SceneConstants.EntityType.TechnicalElement) {
                if (JSONEntity.floorHeight !== undefined) {
                    importedObj.floorHeight = JSONEntity.floorHeight;
                }
            }

            // ADDED FOR OLD COMPATIBILITY WHEN JOINERY POSITIONS WHERE ABSOLUTE
            if (JSONEntity.entityType === SceneConstants.EntityType.Joinery && JSONEntity.joineryType !== SceneConstants.JoineryType.velux) {
                importedObj.transform.localMatrix[14] = 0;
            }
            // END

            importedObj.locked = JSONEntity.locked ? true : false;
            importedObj.name = JSONEntity.name;
            importedObj.holdout = JSONEntity.holdout ? true : false;
            importedObj.hideAtRendering = JSONEntity.hideAtRendering ? true : false;
        }

        //FIXME Temporary fix for multiple zero id entities
        if (importedObj !== null && importedObj.id === 0) {
            JSONImporter.duplicate_zero_list.push(importedObj);
        }
        //************************
        return importedObj;
    }

    /**
     * import the wall from JSON format
     *
     */
    static importWall(JSONWall) {
        let begin = math.vec3.create();
        math.vec3.set(begin, JSONWall.transform.localMatrix[12], JSONWall.transform.localMatrix[13], JSONWall.transform.localMatrix[14]);
        let end = math.vec3.create();
        math.vec3.set(end, JSONWall.end[0], JSONWall.end[1], JSONWall.end[2]);
        let wall = EntityFactory.createWall(begin, end, JSONWall.id);
        wall.thickness = JSONWall.thickness;
        if (JSONWall.height !== undefined) {
            wall.height = JSONWall.height;
        }
        wall.isRoomedWall = JSONWall.isRoomedWall;
        wall.shiftDirection = JSONWall.shiftDirection !== undefined ? JSONWall.shiftDirection : 0;
        wall.shiftOffset = JSONWall.shiftOffset !== undefined ? JSONWall.shiftOffset : 0;
        if (JSONWall.slope !== undefined) {
            wall.slope = JSONWall.slope;
            wall.slopeHeight = JSONWall.slopeHeight;
            wall.slopeLength1 = JSONWall.slopeLength1;
            wall.slopeLength2 = JSONWall.slopeLength2;
        }
        if (JSONWall.forceNoPlinth !== undefined) {
            wall.forceNoPlinth = JSONWall.forceNoPlinth;
        }
        if (JSONWall.forceNoCornice !== undefined) {
            wall.forceNoCornice = JSONWall.forceNoCornice;
        }
        JSONImporter.importChildAndComponent(JSONWall, wall);
        //TODO: wall component ? (except room) mesh

        return wall;
    }

    /**
     * import the joinery from JSON format
     *
     */
    static importJoinery(JSONJoinery) {
        //TODO: joinery component ? meshLib
        let position = math.vec3.create();
        math.vec3.set(position, JSONJoinery.position[0], JSONJoinery.position[1], JSONJoinery.position[2]);
        let joinery = EntityFactory.createJoinery(position, JSONJoinery.joineryType, JSONJoinery.length, JSONJoinery.id);
        joinery.orientation = JSONJoinery.orientation;
        joinery.height = JSONJoinery.height;
        joinery.floorHeight = JSONJoinery.floorHeight;
        joinery.materialType = JSONJoinery.materialType !== undefined ? JSONJoinery.materialType : 0;
        joinery.subModelId = JSONJoinery.subModelId !== undefined ? JSONJoinery.subModelId : 0;
        joinery.scalable = JSONJoinery.scalable !== undefined ? JSONJoinery.scalable : true;
        joinery.freeColor = JSONJoinery.freeColor !== undefined ? JSONJoinery.freeColor : true;
        joinery.transom = JSONJoinery.transom !== undefined ? JSONJoinery.transom : false;
        joinery.transomHeight = JSONJoinery.transomHeight !== undefined ? JSONJoinery.transomHeight : 2500;
        joinery.bottomTransom = JSONJoinery.bottomTransom !== undefined ? JSONJoinery.bottomTransom : false;
        joinery.bottomTransomHeight = JSONJoinery.bottomTransomHeight !== undefined ? JSONJoinery.bottomTransomHeight : 0;
        joinery.frosted = JSONJoinery.frosted !== undefined ? JSONJoinery.frosted : false;
        joinery.cutSlope = JSONJoinery.cutSlope !== undefined ? JSONJoinery.cutSlope : true;
        joinery.wallInstallType = JSONJoinery.wallInstallType !== undefined ? JSONJoinery.wallInstallType : 1;
        switch (JSONJoinery.joineryType) {
            case SceneConstants.JoineryType.door:
            case SceneConstants.JoineryType.frontDoor:
                //Don't care about private to avoid check validity
                if (joinery instanceof Door) {
                    joinery.nbDoors = JSONJoinery.nbDoors;
                    joinery.openingMode = JSONJoinery.openingMode;
                    joinery.slideDirection = JSONJoinery.slideDirection;
                    joinery.handleSide = JSONJoinery.handleSide;
                    joinery.openingSymetry = JSONJoinery.openingSymetry;
                    joinery.isOpened = JSONJoinery.isOpened;
                    if (JSONJoinery.openingAngle !== undefined) {
                        joinery.leftOpeningAngle = JSONJoinery.openingAngle;
                        joinery.rightOpeningAngle = JSONJoinery.openingAngle;
                    }
                    if (JSONJoinery.leftOpeningAngle !== undefined) {
                        joinery.leftOpeningAngle = JSONJoinery.leftOpeningAngle;
                    }
                    if (JSONJoinery.rightOpeningAngle !== undefined) {
                        joinery.rightOpeningAngle = JSONJoinery.rightOpeningAngle;
                    }
                }
                break;
            case SceneConstants.JoineryType.frenchWindow:
                //Don't care about private to avoid check validity
                if (joinery instanceof FrenchDoor) {
                    joinery.nbDoors = JSONJoinery.nbDoors;
                    joinery.handleSide = JSONJoinery.handleSide;
                    joinery.openingSymetry = JSONJoinery.openingSymetry;
                    joinery.isOpened = JSONJoinery.isOpened;
                    if (JSONJoinery.openingAngle !== undefined) {
                        joinery.leftOpeningAngle = JSONJoinery.openingAngle;
                        joinery.rightOpeningAngle = JSONJoinery.openingAngle;
                    }
                    if (JSONJoinery.leftOpeningAngle !== undefined) {
                        joinery.leftOpeningAngle = JSONJoinery.leftOpeningAngle;
                    }
                    if (JSONJoinery.rightOpeningAngle !== undefined) {
                        joinery.rightOpeningAngle = JSONJoinery.rightOpeningAngle;
                    }
                }
                break;
            case SceneConstants.JoineryType.pictureWindow:
                //Don't care about private to avoid check validity
                if (joinery instanceof PictureWindow) {
                    joinery.nbDoors = JSONJoinery.nbDoors;
                    joinery.slideDirection = JSONJoinery.slideDirection;
                    joinery.isOpened = JSONJoinery.isOpened;
                    if (JSONJoinery.openingAngle !== undefined) {
                        joinery.leftOpeningAngle = JSONJoinery.openingAngle;
                        joinery.rightOpeningAngle = JSONJoinery.openingAngle;
                    }
                    if (JSONJoinery.leftOpeningAngle !== undefined) {
                        joinery.leftOpeningAngle = JSONJoinery.leftOpeningAngle;
                    }
                    if (JSONJoinery.rightOpeningAngle !== undefined) {
                        joinery.rightOpeningAngle = JSONJoinery.rightOpeningAngle;
                    }
                }
                break;
            case SceneConstants.JoineryType.window:
                //Don't care about private to avoid check validity
                if (joinery instanceof Window) {
                    joinery.nbCasement = JSONJoinery.nbCasement;
                    joinery.openingMode = JSONJoinery.openingMode;
                    joinery.slideDirection = JSONJoinery.slideDirection;
                    joinery.handleSide = JSONJoinery.handleSide;
                    joinery.openingSymetry = JSONJoinery.openingSymetry;
                    joinery.isOpened = JSONJoinery.isOpened;
                    if (JSONJoinery.openingAngle !== undefined) {
                        joinery.leftOpeningAngle = JSONJoinery.openingAngle;
                        joinery.rightOpeningAngle = JSONJoinery.openingAngle;
                    }
                    if (JSONJoinery.leftOpeningAngle !== undefined) {
                        joinery.leftOpeningAngle = JSONJoinery.leftOpeningAngle;
                    }
                    if (JSONJoinery.rightOpeningAngle !== undefined) {
                        joinery.rightOpeningAngle = JSONJoinery.rightOpeningAngle;
                    }
                }
                break;
            case SceneConstants.JoineryType.fixedWindow:
                break;
            case SceneConstants.JoineryType.canopy:
                if (joinery instanceof Canopy) {
                    joinery.nbCasement = JSONJoinery.nbCasement;
                    joinery.isOpened = JSONJoinery.isOpened;
                }
                break;
            case SceneConstants.JoineryType.niche:
                if (joinery instanceof Niche) { 
                    joinery.thickness = JSONJoinery.thickness;
                }
                break;
            case SceneConstants.JoineryType.cupboardDoor:
                //Don't care about private to avoid check validity
                if (joinery instanceof CupboardDoor) {
                    joinery.nbDoors = JSONJoinery.nbDoors;
                    joinery.isOpened = JSONJoinery.isOpened;
                    if (JSONJoinery.handleSide !== undefined) {
                        joinery.handleSide = JSONJoinery.handleSide;
                    }
                    if (JSONJoinery.openingAngle !== undefined) {
                        joinery.leftOpeningAngle = JSONJoinery.openingAngle;
                        joinery.rightOpeningAngle = JSONJoinery.openingAngle;
                    }
                    if (JSONJoinery.leftOpeningAngle !== undefined) {
                        joinery.leftOpeningAngle = JSONJoinery.leftOpeningAngle;
                    }
                    if (JSONJoinery.rightOpeningAngle !== undefined) {
                        joinery.rightOpeningAngle = JSONJoinery.rightOpeningAngle;
                    }
                }
                break;
            case SceneConstants.JoineryType.velux:
                break;
        }

        JSONImporter.importChildAndComponent(JSONJoinery, joinery);
        return joinery;
    }

    /**
     * import the geometry primitive from JSON format
     *
     */
    static importGeometryPrimitive(JSONGeometryPrimitive) {
        let geometryPrimitive = EntityFactory.createGeometryPrimitive(JSONGeometryPrimitive.primitiveType, JSONGeometryPrimitive.height, JSONGeometryPrimitive.id);

        if (JSONGeometryPrimitive.hideInAxo !== undefined) {
            geometryPrimitive.hideInAxo = JSONGeometryPrimitive.hideInAxo;
        }
        if (JSONGeometryPrimitive.cutByJoineries !== undefined) {
            geometryPrimitive.cutByJoineries = JSONGeometryPrimitive.cutByJoineries;
        }
        if (JSONGeometryPrimitive.floorHeight !== undefined) {
            geometryPrimitive.floorHeight = JSONGeometryPrimitive.floorHeight;
        }
        switch (JSONGeometryPrimitive.primitiveType) {
            case SceneConstants.GeometryPrimitiveType.box:
            case SceneConstants.GeometryPrimitiveType.triangle:
            case SceneConstants.GeometryPrimitiveType.oval:
                geometryPrimitive.width = JSONGeometryPrimitive.width;
                geometryPrimitive.length = JSONGeometryPrimitive.length;
                break;
            case SceneConstants.GeometryPrimitiveType.cylinder:
            case SceneConstants.GeometryPrimitiveType.sphere:
                if (geometryPrimitive instanceof Sphere || geometryPrimitive instanceof Cylinder) {
                    geometryPrimitive.diameter = JSONGeometryPrimitive.diameter;
                }
                break;
            case SceneConstants.GeometryPrimitiveType.hood:
                geometryPrimitive.width = JSONGeometryPrimitive.width;
                geometryPrimitive.length = JSONGeometryPrimitive.length;
                if (geometryPrimitive instanceof Hood) {
                    geometryPrimitive.topWidth = JSONGeometryPrimitive.topWidth;
                    geometryPrimitive.topLength = JSONGeometryPrimitive.topLength;
                    geometryPrimitive.shapeType = JSONGeometryPrimitive.shapeType;
                }
                break;
            case SceneConstants.GeometryPrimitiveType.halfarc:
                geometryPrimitive.width = JSONGeometryPrimitive.width;
                geometryPrimitive.length = JSONGeometryPrimitive.length;
                break;
        }

        JSONImporter.importChildAndComponent(JSONGeometryPrimitive, geometryPrimitive);
        return geometryPrimitive;
    }

    static importGeometryGroup(JSONGeometryGroup) {
        let geometryGroup = EntityFactory.createGeometryGroup(JSONGeometryGroup.id);
        JSONImporter.importChildAndComponent(JSONGeometryGroup, geometryGroup);
        return geometryGroup;
    }

    /**
     * import the arrangement from JSON format
     *
     */
    static importArrangement(JSONArrangement) {
        let arrangement = EntityFactory.createArrangementObjectFromJSON(JSONArrangement);
        JSONImporter.importChildAndComponent(JSONArrangement, arrangement);
        return arrangement;
    }

    /**
     * import the technicalElement from JSON format
     *
     */
    static importTechnicalElement(JSONentity) {
        let technicalElement = EntityFactory.createTechnicalElementFromJSON(JSONentity);
        technicalElement.materialType = JSONentity.materialType !== undefined ? JSONentity.materialType : 0;
        if (JSONentity.lightOn !== undefined) {
            technicalElement.lightOn = JSONentity.lightOn;
        }
        if (JSONentity.lightOff !== undefined) {
            technicalElement.lightOff = JSONentity.lightOff;
        } else {
            technicalElement.lightOff = false;
        }
        if (JSONentity.temperature !== undefined) {
            technicalElement.temperature = JSONentity.temperature;
        } else {
            technicalElement.temperature = SceneConstants.LightTemperature.normal;
        }
        if (JSONentity.lightColor !== undefined) {
            technicalElement.lightColor = JSONentity.lightColor;
        } else {
            technicalElement.lightColor = "#ffffff";
        }
        if (JSONentity.intensity !== undefined) {
            technicalElement.intensity = JSONentity.intensity;
        }
        if (JSONentity.symmetry !== undefined) {
            technicalElement.symmetry = JSONentity.symmetry;
        }
        JSONImporter.importChildAndComponent(JSONentity, technicalElement);
        return technicalElement;
    }

    /**
     * import the technicalElement from JSON format
     *
     */
    static importStaircase(JSONentity) {
        let technicalElement = EntityFactory.createStairCaseFromJSON(JSONentity);
        JSONImporter.importChildAndComponent(JSONentity, technicalElement);
        return technicalElement;
    }

    /**
     * import the arrangementGroup from JSON format
     *
     */
    static importArrangementGroup(JSONArrangementGroup) {
        let arrangementGroup = EntityFactory.createEmptyArrangementGroup(JSONArrangementGroup.id);
        if (JSONArrangementGroup.objectId !== undefined) {
            arrangementGroup.objectId = JSONArrangementGroup.objectId;
        }
        if (JSONArrangementGroup.arrangementGroupType !== undefined) {
            arrangementGroup.arrangementGroupType = JSONArrangementGroup.arrangementGroupType;
        }
        if (JSONArrangementGroup.masterObjectId !== undefined) {
            arrangementGroup.masterObjectId = JSONArrangementGroup.masterObjectId;
        }
        if (JSONArrangementGroup.AMLayoutId !== undefined) {
            arrangementGroup.AMLayoutId = JSONArrangementGroup.AMLayoutId;
        }
        if (JSONArrangementGroup.smartDesignerParentId !== undefined) {
            arrangementGroup.smartDesignerParentId = JSONArrangementGroup.smartDesignerParentId;
        }
        JSONImporter.importChildAndComponent(JSONArrangementGroup, arrangementGroup);
        return arrangementGroup;
    }

    /**
     * import the floor from JSON format
     *
     */
    static importFloor(JSONFloor) {
        let floor = EntityFactory.createEmptyFloor(JSONFloor.id);
        floor.height = JSONFloor.transform.localMatrix[14];
        JSONImporter.importChildAndComponent(JSONFloor, floor);
        JSONImporter.importFloorRooms(JSONFloor, floor);
        if (!floor.hasSun()) {
            floor.addChild(EntityFactory.createSun());
        }
        return floor;
    }

    /**
     * import the chip from JSON format
     */
    static importFunctionalityChip(JSONChip) {
        let chip = EntityFactory.createEmptyFunctionalityChip(JSONChip.id);
        chip.isOnPlan = JSONChip.isOnPlan;
        JSONImporter.importChildAndComponent(JSONChip, chip);
        return chip;
    }

    /**
     * import the render camera from JSON format
     */
    static importRenderCamera(JSONCamera) {
        let camera = EntityFactory.createEmptyRenderCamera(JSONCamera.id);
        
        camera.fov = JSONCamera.fov;
        if (JSONCamera.verticalShift !== undefined) {
            camera.verticalShift = JSONCamera.verticalShift;
        }
        if (JSONCamera.format !== undefined) {
            camera.format = JSONCamera.format;
        }
        if (JSONCamera.hd !== undefined) {
            camera.hd = JSONCamera.hd;
        }

        camera.iso = JSONCamera.iso;
        camera.shutterSpeed = JSONCamera.shutterSpeed;
        camera.fNumber = JSONCamera.fNumber;
        camera.cameraNb = JSONCamera.cameraNb;
        camera.projection = JSONCamera.projection;
        camera.renderWidth = JSONCamera.renderWidth;
        camera.renderHeight = JSONCamera.renderHeight;
        camera.quality = JSONCamera.quality;
        camera.isFinalRender = JSONCamera.isFinalRender;
        camera.denoising = JSONCamera.denoising;
        
        if (JSONCamera.autoexposure !== undefined) {
            camera.autoexposure = JSONCamera.autoexposure;
        }
        if (JSONCamera.whitebalance !== undefined) {
            camera.whitebalance = JSONCamera.whitebalance;
        }
        if (JSONCamera.renderType !== undefined) {
            camera.renderType = JSONCamera.renderType;
        }
        if (JSONCamera.excludedObjectIds !== undefined) {
            camera.excludedObjectIds = JSONCamera.excludedObjectIds;
        }
        if (JSONCamera.shoppingList !== undefined) {
            camera.shoppingList = JSONCamera.shoppingList;
        }
        if (JSONCamera.entityShoppingList !== undefined) {
            camera.entityShoppingList = JSONCamera.entityShoppingList;
        }
        if (JSONCamera.exposure !== undefined) {
            camera.exposure = JSONCamera.exposure;
        }
        if (JSONCamera.dof !== undefined) {
            camera.dof = JSONCamera.dof;
        }
        if (JSONCamera.aperture !== undefined) {
            camera.aperture = JSONCamera.aperture;
        }
        if (JSONCamera.nbImages !== undefined) {
            camera.nbImages = JSONCamera.nbImages;
        }
        if (JSONCamera.sunOffsetRotation !== undefined) {
            camera.sunOffsetRotation = JSONCamera.sunOffsetRotation;
        }
        if (JSONCamera.sunOffsetAltitude !== undefined) {
            camera.sunOffsetAltitude = JSONCamera.sunOffsetAltitude;
        }
        camera.rawCameraType = JSONCamera.cameraType;
        // Force camera to be HD if not already
        if (!camera.hd) {
            camera.hd = true;
        }
        JSONImporter.importChildAndComponent(JSONCamera, camera);
        return camera;
    }

    /**
     * import the render camera from JSON format
     */
    static importSun(JSONSun) {
        let sun = EntityFactory.createEmptySun(JSONSun.id);
        if (JSONSun.altitude) {
            sun.altitude = JSONSun.altitude;
        }
        sun.weather = JSONSun.weather;
        sun.southDirection = JSONSun.southDirection;
        if (JSONSun.exterior !== undefined) {
            sun.exterior = JSONSun.exterior;
        }
        if (JSONSun.additionalExterior !== undefined) {
            sun.additionalExterior = JSONSun.additionalExterior;
        }
        if (JSONSun.increaseDecorsSize !== undefined) {
            sun.increaseDecorsSize = JSONSun.increaseDecorsSize;
        }
        if (JSONSun.decorsRotation !== undefined) {
            sun.decorsRotation = JSONSun.decorsRotation;
        }
        if (JSONSun.shadowBlurRadius !== undefined) {
            sun.shadowBlurRadius = JSONSun.shadowBlurRadius;
        }
        if (JSONSun.temperature !== undefined) {
            sun.temperature = JSONSun.temperature;
        }
        if (JSONSun.skyIntensity !== undefined) {
            sun.skyIntensity = JSONSun.skyIntensity;
        }
        JSONImporter.importChildAndComponent(JSONSun, sun);
        return sun;
    }

    static importComment(JSONComment) {
        let comment = EntityFactory.createEmptyComment(JSONComment.id);
        comment.text = JSONComment.text;
        if (JSONComment.images) {
            comment.images = JSONComment.images;
        }

        JSONImporter.importChildAndComponent(JSONComment, comment);
        return comment;
    }

    /**
     * import the arrangement zone from JSON format
     */
    static importArrangementZone(JSONEntity) {
        let zone = EntityFactory.createArrangementZoneFromJSON(JSONEntity);
        JSONImporter.importChildAndComponent(JSONEntity, zone);
        return zone;
    }

    static importSketchBlock(JSONEntity) {
        let sketchBlock = new SketchBlock(JSONEntity.id, JSONEntity.sketchId, JSONEntity.length, JSONEntity.width, JSONEntity.height);
        JSONImporter.importChildAndComponent(JSONEntity, sketchBlock);
        if (EntityFactory.getLastEntityId() < sketchBlock.id) {
            EntityFactory.setLastEntityId(sketchBlock.id);
        }
        return sketchBlock;
    }

    static importWorkTop(JSONEntity) {
        let workTop = new WorkTop(JSONEntity.id, JSONEntity.thickness);

        // Import child and component first so that area is there before importing credences and legs
        JSONImporter.importChildAndComponent(JSONEntity, workTop);

        let begin = null;
        let end = null;
        for (let i = 0; i < JSONEntity.legs.length; i++) {
            begin = math.vec3.create();
            end = math.vec3.create();
            math.vec3.set(begin, JSONEntity.legs[i].begin[0], JSONEntity.legs[i].begin[1], JSONEntity.legs[i].begin[2]);
            math.vec3.set(end, JSONEntity.legs[i].end[0], JSONEntity.legs[i].end[1], JSONEntity.legs[i].end[2]);

            workTop.loadLeg(begin, end, JSONEntity.legs[i].isUnder);
        }
        if (JSONEntity.credences !== undefined && JSONEntity.credences !== null) {
            for (let i = 0; i < JSONEntity.credences.length; i++) {
                begin = math.vec3.create();
                end = math.vec3.create();
                math.vec3.set(begin, JSONEntity.credences[i].begin[0], JSONEntity.credences[i].begin[1], JSONEntity.credences[i].begin[2]);
                math.vec3.set(end, JSONEntity.credences[i].end[0], JSONEntity.credences[i].end[1], JSONEntity.credences[i].end[2]);

                let height;
                if (JSONEntity.credences[i].height) {
                    height = JSONEntity.credences[i].height;
                } else {
                    let comp = workTop.getComponent(ComponentConstants.ComponentType.Credence);

                    if (comp) {
                        height = (comp as Credence).height;
                    }
                }
                workTop.addCredence(begin, end, height);
            }
        }

        if (EntityFactory.getLastEntityId() < workTop.id) {
            EntityFactory.setLastEntityId(workTop.id);
        }

        return workTop;
    }

    /**
     *
     **/
    static importUserPicture(JSONEntity) {
        let userPicture = new UserPicture(JSONEntity.id, JSONEntity.pictureRef);
        JSONImporter.importChildAndComponent(JSONEntity, userPicture);
        if (EntityFactory.getLastEntityId() < userPicture.id) {
            EntityFactory.setLastEntityId(userPicture.id);
        }
        return userPicture;
    }

    /**
     * import the component from JSON format
     *
     */
    static importComponent(JSONComponent, parent) {
        switch (JSONComponent.componentType) {
            case ComponentConstants.ComponentType.TemplateImage:
                var component = new TemplateImage(JSONComponent.idPhoto, JSONComponent.url, JSONComponent.scale, JSONComponent.rotation, JSONComponent.offset);
                if (JSONComponent.userScale) {
                    component.userScale = JSONComponent.userScale;
                }
                parent.addComponent(component);
                break;
            case ComponentConstants.ComponentType.RendererTopView:
                parent.addComponent(new RendererTopView(JSONComponent.urlTopView, JSONComponent.urlSecondary));
                break;
            case ComponentConstants.ComponentType.Functionality:
                parent.addComponent(new Functionality(JSONComponent.functionalityId, JSONComponent.functionalityName, JSONComponent.data));
                break;
            case ComponentConstants.ComponentType.JoineryType:
                parent.addComponent(new JoineryType(JSONComponent.joineryTypeId, JSONComponent.manufacturer, JSONComponent.retailer));
                break;
            case ComponentConstants.ComponentType.TechnicalElementType:
                parent.addComponent(new TechnicalElementType(JSONComponent.technicalElementTypeId));
                break;
            case ComponentConstants.ComponentType.WallType:
                parent.addComponent(new WallType(JSONComponent.wallTypeId));
                break;
            case ComponentConstants.ComponentType.CorniceType:
                parent.addComponent(new CorniceType(JSONComponent.corniceTypeId));
                break;
            case ComponentConstants.ComponentType.Sun:
                let sun = EntityFactory.createEmptySun(undefined);
                sun.altitude = 35;
                sun.weather = 0;
                sun.southDirection = JSONComponent.southDirection;
                sun.exterior = "None";
                parent.addChild(sun);
                break;
            case ComponentConstants.ComponentType.DomeLight:
                var dome = new DomeLight();
                dome.intensity = JSONComponent.intensity;
                dome.warmth = JSONComponent.warmth;
                parent.addComponent(dome);
                break;
            case ComponentConstants.ComponentType.Coating:
                let coating = new Coating(JSONComponent.coatingId, JSONComponent.manufacturer, JSONComponent.retailer, JSONComponent.hangType, JSONComponent.colors, JSONComponent.randomization, JSONComponent.floorGeneratorSettings, JSONComponent.urlPreview);
                coating.colorIndex = JSONComponent.colorIndex !== undefined ? JSONComponent.colorIndex : JSONComponent.colors[0].index;
                coating.configurable = JSONComponent.configurable;

                if (JSONComponent.offset !== undefined) {
                    math.vec2.set(coating.offset, JSONComponent.offset[0], JSONComponent.offset[1]);
                }
                if (JSONComponent.repeat !== undefined) {
                    math.vec2.set(coating.repeat, JSONComponent.repeat[0], JSONComponent.repeat[1]);
                }
                coating.rotation = JSONComponent.rotation !== undefined ? JSONComponent.rotation : 0;
                if (JSONComponent.usemtlName !== undefined) {
                    coating.usemtlName = JSONComponent.usemtlName;
                }
                if (JSONComponent.locked !== undefined) {
                    coating.locked = JSONComponent.locked;
                }
                parent.addComponent(coating);
                break;
            case ComponentConstants.ComponentType.CustomCoating:
                let JSONComponentCoating = JSONComponent.coating;
                let cc = new Coating(JSONComponentCoating.coatingId, JSONComponentCoating.manufacturer, JSONComponentCoating.retailer, JSONComponentCoating.hangType, JSONComponentCoating.colors, JSONComponentCoating.randomization, JSONComponentCoating.floorGeneratorSettings, JSONComponentCoating.urlPreview);
                cc.colorIndex = JSONComponentCoating.colorIndex !== undefined ? JSONComponentCoating.colorIndex : JSONComponentCoating.colors[0].index;

                if (JSONComponentCoating.offset !== undefined) {
                    math.vec2.set(cc.offset, JSONComponentCoating.offset[0], JSONComponentCoating.offset[1]);
                }
                if (JSONComponentCoating.repeat !== undefined) {
                    math.vec2.set(cc.repeat, JSONComponentCoating.repeat[0], JSONComponentCoating.repeat[1]);
                }
                cc.rotation = JSONComponentCoating.rotation !== undefined ? JSONComponentCoating.rotation : 0;
                let customCoating = new CustomCoating(JSONComponent.name, cc);
                parent.addComponent(customCoating);
                break;
            case ComponentConstants.ComponentType.CoatingArea:
                let coatingArea = new CoatingArea(JSONComponent.coatingId, JSONComponent.manufacturer, JSONComponent.retailer, JSONComponent.hangType, JSONComponent.colors, JSONComponent.randomization, JSONComponent.floorGeneratorSettings, JSONComponent.urlPreview);
                coatingArea.colorIndex = JSONComponent.colorIndex !== undefined ? JSONComponent.colorIndex : JSONComponent.colors[0].index;
                coatingArea.configurable = JSONComponent.configurable;
                if (JSONComponent.rotation !== undefined) {
                    coatingArea.rotation = JSONComponent.rotation;
                }
                if (JSONComponent.offset !== undefined) {
                    math.vec2.set(coatingArea.offset, JSONComponent.offset[0], JSONComponent.offset[1]);
                }
                if (JSONComponent.repeat !== undefined) {
                    math.vec2.set(coatingArea.repeat, JSONComponent.repeat[0], JSONComponent.repeat[1]);
                }
                let coatingVertices = [];
                let coatingVertex = null;
                for (let i = 0; i < JSONComponent.vertices.length; i++) {
                    if (!JSONComponent.vertices[i]) {
                        continue;
                    }
                    coatingVertex = math.vec3.create();
                    math.vec3.set(coatingVertex, JSONComponent.vertices[i][0], JSONComponent.vertices[i][1], JSONComponent.vertices[i][2]);
                    coatingVertices.push(coatingVertex);
                }
                coatingArea.vertices = coatingVertices;
                coatingArea.isDirectSide = JSONComponent.isDirectSide;
                parent.addComponent(coatingArea);
                break;
            case ComponentConstants.ComponentType.FloorCoatingArea:
                let floorCoatingArea = new FloorCoatingArea(JSONComponent.coatingId, JSONComponent.manufacturer, JSONComponent.retailer, JSONComponent.hangType, JSONComponent.colors, JSONComponent.randomization, JSONComponent.floorGeneratorSettings, JSONComponent.urlPreview);
                floorCoatingArea.colorIndex = JSONComponent.colorIndex !== undefined ? JSONComponent.colorIndex : JSONComponent.colors[0].index;
                if (JSONComponent.rotation !== undefined) {
                    floorCoatingArea.rotation = JSONComponent.rotation;
                }
                if (JSONComponent.offset !== undefined) {
                    math.vec2.set(floorCoatingArea.offset, JSONComponent.offset[0], JSONComponent.offset[1]);
                }
                if (JSONComponent.repeat !== undefined) {
                    math.vec2.set(floorCoatingArea.repeat, JSONComponent.repeat[0], JSONComponent.repeat[1]);
                }
                let floorCoatingVertices = [];
                let floorCoatingVertex = null;
                for (let i = 0; i < JSONComponent.vertices.length; i++) {
                    if (!JSONComponent.vertices[i]) {
                        continue;
                    }
                    floorCoatingVertex = math.vec3.create();
                    math.vec3.set(floorCoatingVertex, JSONComponent.vertices[i][0], JSONComponent.vertices[i][1], JSONComponent.vertices[i][2]);
                    floorCoatingVertices.push(floorCoatingVertex);
                }
                floorCoatingArea.vertices = floorCoatingVertices;
                if (JSONComponent.altitude !== undefined) {
                    floorCoatingArea.altitude = JSONComponent.altitude;
                }
                parent.addComponent(floorCoatingArea);
                break;
            case ComponentConstants.ComponentType.Area:
                let area = new Area();
                let vertices = [];
                let vertex = null;
                for (let i = 0; i < JSONComponent.vertices.length; i++) {
                    if (!JSONComponent.vertices[i]) {
                        continue;
                    }
                    vertex = math.vec3.create();
                    math.vec3.set(vertex, JSONComponent.vertices[i][0], JSONComponent.vertices[i][1], JSONComponent.vertices[i][2]);
                    vertices.push(vertex);
                }
                area.vertices = vertices;
                parent.addComponent(area);
                break;
            case ComponentConstants.ComponentType.CutArea:
                let cutArea = new CutArea();
                let cutAreaVertices = [];
                let cutAreavertex = null;
                for (let i = 0; i < JSONComponent.vertices.length; i++) {
                    if (!JSONComponent.vertices[i]) {
                        continue;
                    }
                    cutAreavertex = math.vec3.create();
                    math.vec3.set(cutAreavertex, JSONComponent.vertices[i][0], JSONComponent.vertices[i][1], JSONComponent.vertices[i][2]);
                    cutAreaVertices.push(cutAreavertex);
                }
                cutArea.vertices = cutAreaVertices;
                cutArea.isDirectSide = JSONComponent.isDirectSide;
                parent.addComponent(cutArea);
                break;
            case ComponentConstants.ComponentType.AddArea:
                let addArea = new AddArea();
                let addAreaVertices = [];
                let addAreavertex = null;
                for (let i = 0; i < JSONComponent.vertices.length; i++) {
                    if (!JSONComponent.vertices[i]) {
                        continue;
                    }
                    addAreavertex = math.vec3.create();
                    math.vec3.set(addAreavertex, JSONComponent.vertices[i][0], JSONComponent.vertices[i][1], JSONComponent.vertices[i][2]);
                    addAreaVertices.push(addAreavertex);
                }
                addArea.vertices = addAreaVertices;
                addArea.isDirectSide = JSONComponent.isDirectSide;
                parent.addComponent(addArea);
                break;
            case ComponentConstants.ComponentType.Credence:
                var credence = new Credence(JSONComponent.coatingId, JSONComponent.manufacturer, JSONComponent.retailer, JSONComponent.hangType, JSONComponent.colors, JSONComponent.randomization, JSONComponent.height, JSONComponent.credenceIndex);
                credence.colorIndex = JSONComponent.colorIndex !== undefined ? JSONComponent.colorIndex : JSONComponent.colors[0].index;
                if (JSONComponent.offset !== undefined) {
                    math.vec2.set(credence.offset, JSONComponent.offset[0], JSONComponent.offset[1]);
                }
                if (JSONComponent.repeat !== undefined) {
                    math.vec2.set(credence.repeat, JSONComponent.repeat[0], JSONComponent.repeat[1]);
                }
                parent.addComponent(credence);
                break;
            case ComponentConstants.ComponentType.BoundingBox:
                break;
            case ComponentConstants.ComponentType.SmartDesignerSolution:
                break;
            default:
                if (JSONComponent.componentType > 0) {
                    let component = null;
                    //try importing from external factory
                    for (let i = 0; i < JSONImporter.externalImportComponents.length; i++) {
                        if (typeof JSONImporter.externalImportComponents[i] === "function") {
                            component = JSONImporter.externalImportComponents[i](JSONComponent, parent);
                        }

                        if (component !== null) {
                            break;
                        }
                    }

                    if (component === null) {
                        component = new PlainData(JSONComponent);
                    }
                    parent.addComponent(component);
                }
                break;
        }
    }

    /**
     * import the child and component from JSON format
     *
     */
    static importChildAndComponent(JSONEntity, parent) {
        for (let i = 0; i < JSONEntity.children.length; i++) {
            JSONImporter.importEntity(JSONEntity.children[i], parent);
        }
        for (let i = 0; i < JSONEntity.components.length; i++) {
            JSONImporter.importComponent(JSONEntity.components[i], parent);
        }
    }

    /**
     * Import room's floor
     *
     * @param {*} JSONFloor
     * @param {*} floor
     */
    static importFloorRooms(JSONFloor, floor) {
        for (let i = 0; i < JSONFloor.children.length; i++) {
            if (JSONFloor.children[i].entityType === SceneConstants.EntityType.Room) {
                // Room variable, try to read the room to import (if fail, will be null after the all)
                var room = JSONImporter.importRoom(JSONFloor.children[i], floor);
                // Add the room to the floor only if valid
                if (room !== null) {
                    floor.addRoom(room);
                }
            }
        }
    }

    /**
     * import the entity from JSON format
     *
     */
    static importRoom(JSONRoom, parent) {
        // Protection against importing invalide rooms (a room with less than 3 walls isn't a correct room)
        if (JSONRoom.walls.length < 3) {
            return null;
        }

        // Create an empty room and let's fill it
        let room = EntityFactory.createEmptyRoom(JSONRoom.id);
        room.parent = parent;

        if (JSONRoom.height !== undefined) {
            room.height = JSONRoom.height;
        }
        if (JSONRoom.floorHeight !== undefined) {
            room.floorHeight = JSONRoom.floorHeight;
        }

        if (JSONRoom.plinths !== undefined) {
            room.plinths = JSONRoom.plinths;
            room.plinthsHeight = JSONRoom.plinthsHeight;
            room.plinthsMaterialType = JSONRoom.plinthsMaterialType;
        }

        if (JSONRoom.cornices !== undefined) {
            room.cornices = JSONRoom.cornices;
        }

        for (let i = 0; i < JSONRoom.walls.length; i++) {
            var wall = parent.getWall(JSONRoom.walls[i]);
            if (wall) {
                room.addWall(wall);
            }
        }
        for (let i = 0; i < JSONRoom.nonRoomedWalls.length; i++) {
            var wall = parent.getWall(JSONRoom.nonRoomedWalls[i]);
            if (wall) {
                room.addNonRoomedWall(wall);
            }
        }
        JSONImporter.importChildAndComponent(JSONRoom, room);
        if (room.components.length <= 0) {
            var component = new Functionality(null, null, null);
            room.addComponent(component);
        }
        if (room.getComponent(ComponentConstants.ComponentType.RoomQuotation) === null) {
            room.addComponent(new RoomQuotation());
        }
        return room;
    }

    /**
     * Import the quotation in the world
     */
    static importQuotation(JSONQuotation, world) {
        JSONImporter.importChildAndComponentQuotation(JSONQuotation, world);
    }

    /**
     * import the child and component from JSON format
     *
     */
    static importChildAndComponentQuotation(JSONQuotation, sceneObject) {
        for (let i = 0; i < JSONQuotation.children.length; i++) {
            JSONImporter.importChildAndComponentQuotation(JSONQuotation.children[i], sceneObject.getChild(JSONQuotation.children[i].id));
        }

        for (let i = 0; i < JSONQuotation.components.length; i++) {
            let quotationComponent = JSONQuotation.components[i];
            let sceneComponent = sceneObject.getComponent(quotationComponent.componentType);
            switch (sceneComponent.componentType) {
                case ComponentConstants.ComponentType.RoomQuotation:
                    sceneComponent.floorCovering = quotationComponent.floorCovering;
                    sceneComponent.ceilingRepair = quotationComponent.ceilingRepair;
                    sceneComponent.ceilingCovering = quotationComponent.ceilingCovering;
                    sceneComponent.kitchenSize = quotationComponent.kitchenSize;
                    sceneComponent.kitchenSize = quotationComponent.kitchenSize;
                    break;
                case ComponentConstants.ComponentType.WallQuotation:
                    sceneComponent.wallCoveringDirect = quotationComponent.wallCoveringDirect;
                    sceneComponent.wallCoveringUndirect = quotationComponent.wallCoveringUndirect;
                    sceneComponent.wallRepairDirect = quotationComponent.wallRepairDirect;
                    sceneComponent.wallRepairUndirect = quotationComponent.wallRepairUndirect;
                    break;
                default:
                    break;
            }
        }
    }
}
