import { SceneConstants, SavaneConstants, math, SavaneMath } from "../SavaneJS";
import { Entity } from "./Entity";

export class ArrangementZone extends Entity {
    private _zoneType: SceneConstants.ArrangementZoneType;
    private _width: number;
    private _length: number;
    private _height: number;
    private _offset: math.vec3;
    public data: any = null;

    constructor(id: number, zoneType: SceneConstants.ArrangementZoneType, width: number, length: number, height: number, offset: math.vec3, up: any, forward: any, right: any) {
        super();
        this.id = id;
        this._zoneType = zoneType;
        // Copy dimensions
        this._length = length;
        this._width = width;
        this._height = height;
        // Format is {x, y, z}
        this._offset = offset;

        // Prepare local matrix
        if (up !== undefined && forward !== undefined && right !== undefined) {
            this.transform.localMatrix[0] = -right.x;
            this.transform.localMatrix[1] = -right.y;
            this.transform.localMatrix[2] = -right.z;

            this.transform.localMatrix[4] = forward.x;
            this.transform.localMatrix[5] = forward.y;
            this.transform.localMatrix[6] = forward.z;

            this.transform.localMatrix[8] = up.x;
            this.transform.localMatrix[9] = up.y;
            this.transform.localMatrix[10] = up.z;
        }
    }
    

    get entityType(): SceneConstants.EntityType {
        return SceneConstants.EntityType.ArrangementZone;
    }

    get zoneType(): SceneConstants.ArrangementZoneType {
        return this._zoneType;
    }

    get width(): number {
        if (this._width === undefined) {
            return 0;
        }
        return this._width;
    }

    get position(): math.vec3 {
        return this.transform.globalPosition;
    }

    get angle(): number {
        return this.transform.globalZRotation;
    }

    get height(): number {
        if (this._height === undefined) {
            return 0;
        }
        return this._height;
    }

    get length(): number {
        if (this._length === undefined) {
            return 0;
        }
        return this._length;
    }

    get offset(): any {
        if (this._offset === undefined) {
            return { x: 0, y: 0, z: 0 };
        }
        return this._offset;
    }

    /* To know if this zone is supposed to receive a handle */
    isHandleZone(): boolean {
        return this.zoneType === SceneConstants.ArrangementZoneType.handle || this.zoneType === SceneConstants.ArrangementZoneType.handleright ||
                this.zoneType === SceneConstants.ArrangementZoneType.handleleft || this.zoneType === SceneConstants.ArrangementZoneType.handlecenter;
    }

    get boundingBox(): Array<math.vec3> {
        return this.getHooverBox(0);
    }

    /**
     * get bounding box bigger of the precision
     * @param precision
     */
    getHooverBox(precision: number): Array<math.vec3> {
        let points = [];
        points.push(math.vec3.fromValues(this.length / 2 + precision, -this.width / 2 - precision, 0));
        points.push(math.vec3.fromValues(this.length / 2 + precision, this.width / 2 + precision, 0));
        points.push(math.vec3.fromValues(-this.length / 2 - precision, this.width / 2 + precision, 0));
        points.push(math.vec3.fromValues(-this.length / 2 - precision, -this.width / 2 - precision, 0));

        var q = math.quat.create();
        math.quat.setAxisAngle(q, math.vec3.fromValues(0, 0, 1), this.angle);
        var m = math.mat4.create();
        math.mat4.fromRotationTranslation(m, q, this.position);
        for (var i = 0; i < points.length; ++i) {
            math.vec3.transformMat4(points[i], points[i], m);
            points[i][2] = 0;
        }

        return points;
    }

    /**
     * Check if bounding box contains
     *
     * @param point
     * @param precision
     */
    contains(point: math.vec3, precision: number = SavaneConstants.PositionTolerance): Entity {
        if (SavaneMath.isInPoly(point, this.getHooverBox(precision))) {
            return this;
        }
        return null;
    }

    set localPosition(p: math.vec3) {
        this.transform.localPosition = p;
    }

    get localPosition(): math.vec3 {
        return this.transform.localPosition;
    }
}
