import { Command, CommandEnum } from './CommandModule';
import { Events } from '../events';
import { eventsManager } from '../managers/EventsManager';
import { EntityFactory, Sun, math } from '../SavaneJS';

export class EditSunCommand extends Command
{
    // To store current and modified suns
    private _currentSun: Sun;
    private _modifiedSun: Sun;

    // Constructor takes current sun (with temporary) as parameter
    constructor(currentSun: Sun) {
        super();
        // Clone current sun so it copies the temporary fields into the cloned sun entity
        this._modifiedSun = EntityFactory.cloneEntity(currentSun, false) as Sun;
        // End the sun temporary so it drops the temporary data
        currentSun.endTemporary();
        // Save the current sun for future data exchange
        this._currentSun = currentSun;
    }

    // Command name returned from a global enum
    name(): string {
        return CommandEnum.EditSunCommand;
    }

    // Undo the current command
    undo() {
        // Execute the current command, the savedStair structure keeps the changes back and forth
        this.execute();
    }

    // Execute current command (redo)
    execute() {
        // Get its southDirection and exchange it with the savedSun one
        let tmpSouthDirection = math.vec2.create();
        math.vec2.copy(tmpSouthDirection, this._currentSun.southDirection);
        this._currentSun.southDirection = this._modifiedSun.southDirection;
        this._modifiedSun.southDirection = tmpSouthDirection;

        // Get its altitude and exchange it with the savedStair one
        let tmpAltitude = this._currentSun.altitude;
        this._currentSun.altitude = this._modifiedSun.altitude;
        this._modifiedSun.altitude = tmpAltitude;

        // Get its weather and exchange it with the savedStair one
        let tmpWeather = this._currentSun.weather;
        this._currentSun.weather = this._modifiedSun.weather;
        this._modifiedSun.weather = tmpWeather;

        let tmpExterior = this._currentSun.exterior;
        this._currentSun.exterior = this._modifiedSun.exterior;
        this._modifiedSun.exterior = tmpExterior;

        let tmpAdditionalExterior = this._currentSun.additionalExterior;
        this._currentSun.additionalExterior = this._modifiedSun.additionalExterior;
        this._modifiedSun.additionalExterior = tmpAdditionalExterior;

        let tmpIncreaseDecorsSize = this._currentSun.increaseDecorsSize;
        this._currentSun.increaseDecorsSize = this._modifiedSun.increaseDecorsSize;
        this._modifiedSun.increaseDecorsSize = tmpIncreaseDecorsSize;

        let tmpDecorsRotation = this._currentSun.decorsRotation;
        this._currentSun.decorsRotation = this._modifiedSun.decorsRotation;
        this._modifiedSun.decorsRotation = tmpDecorsRotation;

        let tmpShadowBlurRadius = this._currentSun.shadowBlurRadius;
        this._currentSun.shadowBlurRadius = this._modifiedSun.shadowBlurRadius;
        this._modifiedSun.shadowBlurRadius = tmpShadowBlurRadius;

        let tmpTemperature = this._currentSun.temperature;
        this._currentSun.temperature = this._modifiedSun.temperature;
        this._modifiedSun.temperature = tmpTemperature;

        let tmpSkyIntensity = this._currentSun.skyIntensity;
        this._currentSun.skyIntensity = this._modifiedSun.skyIntensity;
        this._modifiedSun.skyIntensity = tmpSkyIntensity;

        // Potential modification of the south direction so send message to update webGL
        eventsManager.instance.dispatch(Events.ENTITY_PARAM_EDITED,
            {
                id: this._currentSun.id,
                type: this._currentSun.entityType,
                entity: this._currentSun,
                key: "southDirection"
            });

        // Select object at the end
        eventsManager.instance.dispatch(Events.CHANGE_EDITOR_SELECTION,
            {
                selection: [this._currentSun],
                keepSelected: false,
                showTulip: false
            });
    }
};
