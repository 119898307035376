import { Room, Transform } from '../../SavaneJS';
import { TemporaryEntity } from './TemporaryEntity';

/**
 * TemporaryRoom is used for room edition
 */
export class TemporaryRoom extends TemporaryEntity {
    public height: number;
    public floorHeight: number;
    public hasPlinths: boolean;
    public plinthsHeight: number;
    public plinthsMaterialType: number;
    public hasCornices: boolean;
    private _transform: Transform;

    constructor(room: Room) {
        super(room);

        // Room height
        this.height = room.height;
        // and room floor height (can be negative)
        this.floorHeight = room.floorHeight;

        // Plinths parameters
        this.hasPlinths = room.plinths;
        this.plinthsHeight = room.plinthsHeight;
        // Material type is an enum (number)
        this.plinthsMaterialType = room.plinthsMaterialType;

        // Cornices or not
        this.hasCornices = room.cornices;

        this._transform = new Transform(null);
        this._transform.clone(room.transform);
    }

    computeValidity() {
        this._isValid = true;
    }

    get transform(): Transform {
        return this._transform;
    }
}
