import { ComponentConstants, math } from '../SavaneJS';
import { Component } from './Component';

export class TemplateImage extends Component {
    private _idPhoto: number;
    private _url: string;
    private _scale: number;
    private _userScale: number;
    private _rotation: number;
    private _offset: math.vec2;

    constructor(idPhoto: any, url: string, scale: number, rotation: any, offset: math.vec2) {
        super();
        this._idPhoto = idPhoto;
        this._url = url;
        this._scale = scale;
        this._userScale = 1;
        this._rotation = rotation;
        this._offset = math.vec2.create();
        if (offset) {
            this._offset = offset;
        }
    }

    set idPhoto(id: number) {
        this._idPhoto = id;
    }

    get idPhoto(): number {
        return this._idPhoto;
    }

    set url(u: string) {
        this._url = u;
    }

    get url(): string {
        return this._url;
    }

    set userScale(s: number) {
        this._userScale = s;
    }

    get userScale(): number {
        return this._userScale;
    }

    set scale(s: number) {
        this._scale = s;
    }

    get scale(): number {
        return this._scale;
    }

    set rotation(r: number) {
        this._rotation = r;
    }

    get rotation(): number {
        return this._rotation;
    }

    get offset(): math.vec2 {
        return this._offset;
    }

    set offset(o: math.vec2) {
        math.vec2.set(this._offset, o[0], o[1]);
    }

    get componentType(): ComponentConstants.ComponentType {
        return ComponentConstants.ComponentType.TemplateImage;
    }

    clone(): TemplateImage {
        return new TemplateImage(this._idPhoto, this._url, this._scale, this._rotation, this._offset);
    }
}
