import { SceneConstants } from "../SceneConstants";
import { TechnicalElement } from "./TechnicalElement";
import { ComponentConstants } from "../../components/ComponentConstants";
import { TechnicalElementType } from "../../components/ComponentModule";
import { SavaneCookie } from "../../utils/SavaneCookie";

export class CeilingBox extends TechnicalElement {
    private _ceilingBoxType: number;

    constructor(id: number, objectId: SceneConstants.TechnicalElementType, length: number, width: number, height: number, objectType: string, boxType: number) {
        super(id, objectId, length, width, height, objectType);

        this._ceilingBoxType = boxType;
    }

    get ceilingBoxType(): number {
        return this._ceilingBoxType;
    }

    /**
     * Set the correct AM tech Elt Type
     */
    setTechnicalElementType(): void {
        var componentList = this.getComponents(ComponentConstants.ComponentType.TechnicalElementType) as Array<TechnicalElementType>;
        if (componentList.length > 0) {
            for (let i = 0; i < componentList.length; ++i) {
                if (componentList[i].componentType === ComponentConstants.ComponentType.TechnicalElementType) {
                    componentList[i].technicalElementTypeId = SavaneCookie.getCookie("Rhinov-Savane-DefaultTechnicalElementModelCeilingBox", SceneConstants.DefaultTechnicalElementType.ceilingBox);
                }
            }
        } else {
            this.addComponent(new TechnicalElementType(SavaneCookie.getCookie("Rhinov-Savane-DefaultTechnicalElementModelCeilingBox", SceneConstants.DefaultTechnicalElementType.ceilingBox)));
        }
    }
}
