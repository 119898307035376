/**
 * Created by rhinov25e on 12/07/2017.
 */

import { KitchenToolConstants } from "../KitchenToolConstants";
import { AMComponentFactory } from "./AMComponentFactory";
import { FurnitureFinishes } from "../components/FurnitureFinishes";
import { ComponentConstants } from "../../components/ComponentConstants";
import { SceneConstants } from "../../scene/SceneConstants";
import { math } from "../../scene/Transform";
import { Segment } from "../../utils/Segment";
import { SavaneMath } from "../../utils/SavaneMath";
import { joineryManager } from "../../managers/JoineryManager";
import { SavaneConstants } from "../../utils/SavaneConstants";
import { eventsManager } from "../../managers/EventsManager";
import { Events } from "../../events";
import { EntityFactory } from "../../scene/EntityFactory";
import { Area } from "../../components/Area";

export class KitchenManager {
    constructor() {}

    /**
     * get all arrangermant whose objecttype is contained in the array objectTypes
     * @param obj
     * @param objectTypes
     */
    static getKitchenToolArrangements(obj, objectTypes) {
        let children = [];
        let count = obj.children.length;
        for (var i = 0; i < count; i++) {
            if (SceneConstants.EntityType.ArrangementObject === obj.children[i].entityType && (objectTypes === null || objectTypes.indexOf(obj.children[i].objectType) !== -1)) {
                children.push(obj.children[i]);
            }

            children = children.concat(KitchenManager.getKitchenToolArrangements(obj.children[i], objectTypes));
        }

        return children;
    }

    /**
     * add a plinth on the object (entity plinth as child and fill furnitureFinishesComponent)
     * @param object
     * @param side
     * @param {Number} additionalParam  FIXME handle angle furniture for kf
     */
    static addPlinth(object, side, additionalParam?) {
        //create object
        let AmRef = object.getComponents(ComponentConstants.ComponentType.AMFurnitureFinishesReference);
        let plinthData = null;
        if (AmRef.length === 0) {
            return;
        } else {
            plinthData = AmRef[0].plinthData;
        }
        let furniture = object.getComponents(ComponentConstants.ComponentType.FurnitureFinishes);
        if (plinthData === null || furniture.length !== 1 || object.objectType === SceneConstants.ArrangementType.kitchenFurnitureTop || (side !== "back" && side !== "right" && side !== "left" && side !== "front")) {
            return;
        }
        furniture = furniture[0];

        //Sketchblock info are to be taken to ignore objetc details
        let arrangementSize = object.getChildren([SceneConstants.EntityType.SketchBlock]).length === 1 ? object.getChildren([SceneConstants.EntityType.SketchBlock])[0] : object,
            localPosition = object.getChildren([SceneConstants.EntityType.SketchBlock]).length === 1 ? math.vec3.clone(object.getChildren([SceneConstants.EntityType.SketchBlock])[0].transform.localPosition) : math.vec3.create(),
            plinthLength;

        //add filler length
        let positionDelta = 0;
        if (side === "front" || side === "back") {
            plinthLength = arrangementSize.length;
            if (object.originalLength !== undefined) {
                plinthLength *= object.length / object.originalLength;
            }
            if (furniture.fillers.left !== null) {
                plinthLength += furniture.fillers.left.length;
                positionDelta -= furniture.fillers.left.length / 2;
            }
            if (furniture.fillers.right !== null) {
                plinthLength += furniture.fillers.right.length;
                positionDelta += furniture.fillers.right.length / 2;
            }
        } else {
            plinthLength = arrangementSize.width;
            if (object.originalWidth !== undefined) {
                plinthLength *= object.width / object.originalWidth;
            }
        }
        let plinth = EntityFactory.createArrangementObject(plinthData.objectId, plinthData.objectType, undefined, undefined, plinthData.styles,
            plinthLength, plinthData.width, plinthData.height, plinthData.configId, plinthData.coatingId, undefined, undefined, undefined);

        //add children +add to furniture finishes
        object.addChild(plinth);
        furniture.plinths[side] = plinth;

        //place it

        let angle = 0;

        localPosition[2] += -arrangementSize.height / 2 + plinth.height / 2;
        switch (side) {
            case "front":
                localPosition[1] += arrangementSize.width / 2 - plinth.width / 2;
                localPosition[0] += positionDelta;
                break;
            case "back":
                localPosition[1] += -arrangementSize.width / 2 + plinth.width / 2;
                localPosition[0] += positionDelta;
                angle = Math.PI;
                break;
            case "left":
                localPosition[0] += -arrangementSize.length / 2 + plinth.width / 2 - (additionalParam > 0 ? additionalParam : 0);
                angle = Math.PI / 2;
                break;
            case "right":
                localPosition[0] += arrangementSize.length / 2 - plinth.width / 2 - (additionalParam > 0 ? additionalParam : 0);
                angle = -Math.PI / 2;
                break;
        }

        plinth.transform.globalZRotation = angle;
        plinth.localPosition = localPosition;
    }

    /**
     *
     * @param {Object} object
     *
     **/
    static addHandles(object, handleAsset) {
        if (object.objectTypeConfig === undefined) {
            return; // Can't find configuration for item
        }
        var DECAL_VERTICAL_FLOOR = 120;
        var DECAL_VERTICAL_HANDLE = 30;
        var DECAL_HORIZONTAL_HANDLE = 60;
        var DRAWER_SIZE = 175;
        var BIG_DRAWER_SIZE = 2 * DRAWER_SIZE;
        var zPosition = object.width / 2 + handleAsset.dimensions.length / 2;
        //Drawers
        if (object.objectType === SceneConstants.ArrangementType.kitchenFurnitureBottom || object.objectType === SceneConstants.ArrangementType.kitchenFurnitureTop || object.objectType === SceneConstants.ArrangementType.kitchenFurnitureColumn) {
            var i;
            //Big drawers
            for (i = 0; i < object.objectTypeConfig.bigDrawersCount; i++) {
                let handleItem = EntityFactory.createArrangementObject(handleAsset._id, handleAsset.objectType._id, handleAsset.manufacturer, 
                    handleAsset.retailer, handleAsset.styles, handleAsset.dimensions.width, handleAsset.dimensions.length,
                    handleAsset.dimensions.height, handleAsset.configId, undefined, undefined, undefined, undefined);
                let position = math.vec3.create();

                position[0] = 0;
                position[2] = -(object.height / 2) + DECAL_VERTICAL_FLOOR + (i + 1) * BIG_DRAWER_SIZE - DECAL_VERTICAL_HANDLE;
                position[1] = zPosition;

                //Set position
                handleItem.transform.localPosition = position;
                //Add child
                object.addChild(handleItem);
            }
            var decal = -(object.height / 2) + object.objectTypeConfig.bigDrawersCount * BIG_DRAWER_SIZE + DECAL_VERTICAL_FLOOR;
            for (i = 0; i < object.objectTypeConfig.drawersCount; i++) {
                let handleItem = EntityFactory.createArrangementObject(handleAsset._id, handleAsset.objectType._id, handleAsset.manufacturer,
                    handleAsset.retailer, handleAsset.styles, handleAsset.dimensions.width, handleAsset.dimensions.length, handleAsset.dimensions.height,
                    handleAsset.configId, undefined, undefined, undefined, undefined);
                let position = math.vec3.create();

                position[0] = 0;
                position[2] = decal + (i + 1) * DRAWER_SIZE - DECAL_VERTICAL_HANDLE;
                position[1] = zPosition;

                //Set position
                handleItem.transform.localPosition = position;
                //Add child
                object.addChild(handleItem);
            }
        }

        if (object.objectType === SceneConstants.ArrangementType.kitchenFurnitureBottom) {
            let nbDoors = object.objectTypeConfig.doorsCount;
            let isDishwasher = object.objectTypeConfig.builtinDishwasher;
            let isOven = object.objectTypeConfig.builtinOven;
            let angleDirection = object.objectTypeConfig.angleDirection;
            if (nbDoors === 1) {
                let handleItem = EntityFactory.createArrangementObject(handleAsset._id, handleAsset.objectType._id, handleAsset.manufacturer,
                    handleAsset.retailer, handleAsset.styles, handleAsset.dimensions.width, handleAsset.dimensions.length, handleAsset.dimensions.height,
                    handleAsset.configId, undefined, undefined, undefined, undefined);
                let position = math.vec3.create();
                if (angleDirection === "none") {
                    position[0] = 0;
                } else if (angleDirection === "left") {
                    position[0] = -300;
                } else if (angleDirection === "right") {
                    position[0] = 300;
                }
                position[2] = object.height / 2 - DECAL_HORIZONTAL_HANDLE;
                position[1] = zPosition;
                //Set position
                handleItem.transform.localPosition = position;
                //Add as child
                object.addChild(handleItem);
            } else if (nbDoors === 2) {
                let handleItem1 = EntityFactory.createArrangementObject(handleAsset._id, handleAsset.objectType._id, handleAsset.manufacturer,
                    handleAsset.retailer, handleAsset.styles, handleAsset.dimensions.width, handleAsset.dimensions.length, handleAsset.dimensions.height,
                    handleAsset.configId, undefined, undefined, undefined, undefined);
                let handleItem2 = EntityFactory.createArrangementObject(handleAsset._id, handleAsset.objectType._id, handleAsset.manufacturer,
                    handleAsset.retailer, handleAsset.styles, handleAsset.dimensions.width, handleAsset.dimensions.length, handleAsset.dimensions.height,
                    handleAsset.configId, undefined, undefined, undefined, undefined);
                let position1 = math.vec3.create();
                let position2 = math.vec3.create();
                position1[0] = object.length / 4;
                position2[0] = -object.length / 4;
                position1[2] = object.height / 2 - handleItem1.width / 2 - DECAL_HORIZONTAL_HANDLE;
                position2[2] = object.height / 2 - handleItem1.width / 2 - DECAL_HORIZONTAL_HANDLE;
                position1[1] = zPosition;
                position2[1] = zPosition;
                //Set position
                handleItem1.transform.localPosition = position1;
                handleItem2.transform.localPosition = position2;
                //Add child
                object.addChild(handleItem1);
                object.addChild(handleItem2);
            } else if (nbDoors === 0) {
                if (isDishwasher) {
                    let handleItem = EntityFactory.createArrangementObject(handleAsset._id, handleAsset.objectType._id, handleAsset.manufacturer,
                        handleAsset.retailer, handleAsset.styles, handleAsset.dimensions.width, handleAsset.dimensions.length, handleAsset.dimensions.height,
                        handleAsset.configId, undefined, undefined, undefined, undefined);
                    let position = math.vec3.create();
                    position[0] = 0;
                    position[2] = object.height / 2 - DECAL_HORIZONTAL_HANDLE;
                    position[1] = zPosition;
                    //Set position
                    handleItem.transform.localPosition = position;
                    //Add as child
                    object.addChild(handleItem);
                }
            }
        }
        if (object.objectType === SceneConstants.ArrangementType.kitchenFurnitureTop) {
            let nbDoors = object.objectTypeConfig.doorsCount;
            let hoodType = object.objectTypeConfig.builtinHood;
            let angleDirection = object.objectTypeConfig.angleDirection;
            let downdoors = object.objectTypeConfig.downdoors;
            if (nbDoors === 1) {
                let handleItem = EntityFactory.createArrangementObject(handleAsset._id, handleAsset.objectType._id, handleAsset.manufacturer,
                    handleAsset.retailer, handleAsset.styles, handleAsset.dimensions.width, handleAsset.dimensions.length, handleAsset.dimensions.height,
                    handleAsset.configId, undefined, undefined, undefined, undefined);
                let position = math.vec3.create();
                position[0] = -300;
                if (angleDirection === "none") {
                    position[0] = 0;
                } else if (angleDirection === "left") {
                    position[0] = -300;
                } else if (angleDirection === "right") {
                    position[0] = 300;
                }
                if (hoodType === "none") {
                    position[2] = -object.height / 2 + handleItem.height + DECAL_VERTICAL_HANDLE;
                } else {
                    position[2] = -object.height / 2 + handleItem.height + DECAL_VERTICAL_HANDLE + 150;
                }

                position[1] = zPosition;
                //Set position
                handleItem.transform.localPosition = position;
                //Add as child
                object.addChild(handleItem);
            } else if (nbDoors === 2) {
                let handleItem1 = EntityFactory.createArrangementObject(handleAsset._id, handleAsset.objectType._id, handleAsset.manufacturer,
                    handleAsset.retailer, handleAsset.styles, handleAsset.dimensions.width, handleAsset.dimensions.length, handleAsset.dimensions.height,
                    handleAsset.configId, undefined, undefined, undefined, undefined);
                let handleItem2 = EntityFactory.createArrangementObject(handleAsset._id, handleAsset.objectType._id, handleAsset.manufacturer,
                    handleAsset.retailer, handleAsset.styles, handleAsset.dimensions.width, handleAsset.dimensions.length, handleAsset.dimensions.height,
                    handleAsset.configId, undefined, undefined, undefined, undefined);

                let position1 = math.vec3.create();
                let position2 = math.vec3.create();
                if (downdoors) {
                    position1[0] = 0;
                    if (hoodType === "none") {
                        position1[2] = DECAL_HORIZONTAL_HANDLE;
                    } else {
                        position1[2] = DECAL_HORIZONTAL_HANDLE + 150;
                    }
                    position2[0] = 0;
                    if (hoodType === "none") {
                        position2[2] = -object.height / 2 + DECAL_HORIZONTAL_HANDLE;
                    } else {
                        position2[2] = -object.height / 2 + DECAL_HORIZONTAL_HANDLE + 150;
                    }
                } else {
                    position1[0] = object.length / 4;
                    position2[0] = -object.length / 4;
                    if (hoodType === "none") {
                        position1[2] = -object.height / 2 + DECAL_HORIZONTAL_HANDLE;
                        position2[2] = -object.height / 2 + DECAL_HORIZONTAL_HANDLE;
                    } else {
                        position1[2] = -object.height / 2 + DECAL_HORIZONTAL_HANDLE + 150;
                        position2[2] = -object.height / 2 + DECAL_HORIZONTAL_HANDLE + 150;
                    }
                }
                if (angleDirection !== "none") {
                    position1[1] = 0;
                    position2[1] = 0;
                } else {
                    position1[1] = zPosition;
                    position2[1] = zPosition;
                }

                //Set position
                handleItem1.transform.localPosition = position1;
                handleItem2.transform.localPosition = position2;
                //Add child
                object.addChild(handleItem1);
                object.addChild(handleItem2);
            }
        }
        if (object.objectType === SceneConstants.ArrangementType.kitchenFurnitureColumn) {
            //Others
            var array = object.name.split("_");
            var ref = array[array.length - 1]; //Extract KF reference
            var isBig = array[array.length - 2] === "H240";
            ref = ref.slice(2, ref.length - 1);
            var SMALL_DOOR_SIZE = 482;
            var DOOR_SIZE = 670;
            var BOT_DOOR_SIZE = 720;
            var BIG_DOOR_SIZE = 1324;
            var TOP_BIG_DOOR_SIZE = 1264;
            //Adjut to match
            if (isBig) {
                BIG_DOOR_SIZE = 1560;
                TOP_BIG_DOOR_SIZE = 1540;
                BOT_DOOR_SIZE = 720;
                DOOR_SIZE = 940;
                SMALL_DOOR_SIZE = 722;
            }
            //Has bot big door
            if (ref === "CB01" || ref === "CB04" || ref === "CA01" || ref === "CA04") {
                let handleItem = EntityFactory.createArrangementObject(handleAsset._id, handleAsset.objectType._id, handleAsset.manufacturer,
                    handleAsset.retailer, handleAsset.styles, handleAsset.dimensions.width, handleAsset.dimensions.length, handleAsset.dimensions.height,
                    handleAsset.configId, undefined, undefined, undefined, undefined);
                let position = math.vec3.create();

                position[0] = 0;
                position[2] = -(object.height / 2) + DECAL_VERTICAL_FLOOR + BIG_DOOR_SIZE - DECAL_VERTICAL_HANDLE;
                position[1] = zPosition;

                //Set position
                handleItem.transform.localPosition = position;
                //Add child
                object.addChild(handleItem);
            }
            //Top classic door
            if (ref === "CA03" || ref === "CA1C03" || ref === "CB03" || ref === "CB1C03") {
                let handleItem2 = EntityFactory.createArrangementObject(handleAsset._id, handleAsset.objectType._id, handleAsset.manufacturer,
                    handleAsset.retailer, handleAsset.styles, handleAsset.dimensions.width, handleAsset.dimensions.length, handleAsset.dimensions.height,
                    handleAsset.configId, undefined, undefined, undefined, undefined);
                let position2 = math.vec3.create();

                position2[0] = 0;
                position2[2] = object.height / 2 - DOOR_SIZE + DECAL_VERTICAL_HANDLE;
                position2[1] = zPosition;

                //Set position
                handleItem2.transform.localPosition = position2;
                //Add child
                object.addChild(handleItem2);
            }
            //Has top door
            if (ref === "CA01" || ref === "CA04" || ref === "CB01" || ref === "CB04") {
                let handleItem2 = EntityFactory.createArrangementObject(handleAsset._id, handleAsset.objectType._id, handleAsset.manufacturer,
                    handleAsset.retailer, handleAsset.styles, handleAsset.dimensions.width, handleAsset.dimensions.length, handleAsset.dimensions.height,
                    handleAsset.configId, undefined, undefined, undefined, undefined);
                let position2 = math.vec3.create();

                position2[0] = 0;
                position2[2] = -(object.height / 2) + DECAL_VERTICAL_FLOOR + BIG_DOOR_SIZE + DECAL_VERTICAL_HANDLE;
                position2[1] = zPosition;

                //Set position
                handleItem2.transform.localPosition = position2;
                //Add child
                object.addChild(handleItem2);
            }
            //Has top big door
            if (ref === "CA02" || ref === "CA05" || ref === "CB02" || ref === "CB05") {
                let handleItem = EntityFactory.createArrangementObject(handleAsset._id, handleAsset.objectType._id, handleAsset.manufacturer,
                    handleAsset.retailer, handleAsset.styles, handleAsset.dimensions.width, handleAsset.dimensions.length, handleAsset.dimensions.height,
                    handleAsset.configId, undefined, undefined, undefined, undefined);
                let position = math.vec3.create();
                position[0] = 0;
                position[2] = object.height / 2 - TOP_BIG_DOOR_SIZE + DECAL_VERTICAL_HANDLE;
                position[1] = zPosition;
                //Set position
                handleItem.transform.localPosition = position;
                //Add child
                object.addChild(handleItem);
            }
            //Has bot door
            if (ref === "CA03" || ref === "CA02" || ref === "CA05" || ref === "CB03" || ref === "CB02" || ref === "CB05") {
                let handleItem = EntityFactory.createArrangementObject(handleAsset._id, handleAsset.objectType._id, handleAsset.manufacturer,
                    handleAsset.retailer, handleAsset.styles, handleAsset.dimensions.width, handleAsset.dimensions.length, handleAsset.dimensions.height,
                    handleAsset.configId, undefined, undefined, undefined, undefined);
                let position = math.vec3.create();
                position[0] = 0;
                position[2] = -(object.height / 2) + DECAL_VERTICAL_FLOOR + BOT_DOOR_SIZE - DECAL_VERTICAL_HANDLE;
                position[1] = zPosition;
                //Set position
                handleItem.transform.localPosition = position;
                //Add child
                object.addChild(handleItem);
            }
            //Has bot small door
            if (ref === "CA06" || ref === "CB06") {
                let handleItem = EntityFactory.createArrangementObject(handleAsset._id, handleAsset.objectType._id, handleAsset.manufacturer,
                    handleAsset.retailer, handleAsset.styles, handleAsset.dimensions.width, handleAsset.dimensions.length, handleAsset.dimensions.height,
                    handleAsset.configId, undefined, undefined, undefined, undefined);
                let position = math.vec3.create();
                position[0] = 0;
                position[2] = -(object.height / 2) + DECAL_VERTICAL_FLOOR + SMALL_DOOR_SIZE - DECAL_VERTICAL_HANDLE;
                position[1] = zPosition;
                //Set position
                handleItem.transform.localPosition = position;
                //Add child
                object.addChild(handleItem);
            }
            //Has top door
            if (ref === "CA06" || ref === "CB06") {
                let handleItem = EntityFactory.createArrangementObject(handleAsset._id, handleAsset.objectType._id, handleAsset.manufacturer,
                    handleAsset.retailer, handleAsset.styles, handleAsset.dimensions.width, handleAsset.dimensions.length, handleAsset.dimensions.height,
                    handleAsset.configId, undefined, undefined, undefined, undefined);
                let position = math.vec3.create();

                position[0] = 0;
                position[2] = object.height / 2 - SMALL_DOOR_SIZE + DECAL_VERTICAL_HANDLE;
                position[1] = zPosition;

                //Set position
                handleItem.transform.localPosition = position;
                //Add child
                object.addChild(handleItem);
            }
        }
    }

    /**
     *
     * @param object
     * @param side
     * @param {Number} additionalParam : FIXME handle angle furniture for kf
     */
    static addEndPanel(object, side, additionalParam?) {
        //create object
        let AmRef = object.getComponents(ComponentConstants.ComponentType.AMFurnitureFinishesReference);
        let endPanelData = null;
        if (AmRef.length === 0) {
            return;
        } else {
            endPanelData = AmRef[0].endPanelData;
        }
        let furniture = object.getComponents(ComponentConstants.ComponentType.FurnitureFinishes);
        if (endPanelData === null || furniture.length !== 1 || (side !== "back" && side !== "right" && side !== "left" && side !== "front")) {
            return;
        }
        furniture = furniture[0];

        //Sketchblock info are to be taken to ignore objetc details
        let arrangementSize = object.getChildren([SceneConstants.EntityType.SketchBlock]).length === 1 ? object.getChildren([SceneConstants.EntityType.SketchBlock])[0] : object,
            //FIXME End panel particular case for kitchen fab
            endPanel = EntityFactory.createArrangementObject(side === "back" || additionalParam > 0 ? null : endPanelData.objectId, endPanelData.objectType, undefined, undefined, endPanelData.styles, side === "back" ? arrangementSize.length : arrangementSize.width,
                endPanelData.width, arrangementSize.height, side === "back" || additionalParam > 0 ? null : endPanelData.configId, side === "back" || additionalParam > 0 ? null : endPanelData.coatingId, undefined, undefined, undefined),
            localPosition = object.getChildren([SceneConstants.EntityType.SketchBlock]).length === 1 ? math.vec3.clone(object.getChildren([SceneConstants.EntityType.SketchBlock])[0].transform.localPosition) : math.vec3.create();

        //add children +add to furniture finishes
        object.addChild(endPanel);
        furniture.endPanels[side] = endPanel;

        //FIXME : this is just to debug != id for != side of the panel
        if (furniture.endPanels.left !== null) {
            AmRef[0].updateEndPanel(furniture, "left");
        }
        if (furniture.endPanels.right !== null) {
            AmRef[0].updateEndPanel(furniture, "right");
        }

        //place it
        let angle = 0;
        switch (side) {
            case "front":
                localPosition[1] = +arrangementSize.width / 2;
                if (endPanel.objectType === SceneConstants.ArrangementType.kitchenEndPanel) {
                    localPosition[1] += endPanelData.width / 2;
                } else {
                    localPosition[1] -= endPanelData.width / 2;
                }

                angle = -Math.PI;
                break;
            case "back":
                localPosition[1] = -arrangementSize.width / 2;
                if (endPanel.objectType === SceneConstants.ArrangementType.kitchenEndPanel) {
                    localPosition[1] -= endPanelData.width / 2;
                } else {
                    localPosition[1] += endPanelData.width / 2;
                }

                angle = Math.PI;
                break;
            case "left":
                localPosition[0] -= arrangementSize.length / 2;
                if (endPanel.objectType === SceneConstants.ArrangementType.kitchenEndPanel) {
                    localPosition[0] -= endPanelData.width / 2;
                } else {
                    localPosition[0] += endPanelData.width / 2;
                }

                localPosition[0] -= additionalParam > 0 ? additionalParam : 0;
                angle = Math.PI / 2;
                break;
            case "right":
                localPosition[0] += arrangementSize.length / 2;
                if (endPanel.objectType === SceneConstants.ArrangementType.kitchenEndPanel) {
                    localPosition[0] += endPanelData.width / 2;
                } else {
                    localPosition[0] -= endPanelData.width / 2;
                }

                localPosition[0] += additionalParam > 0 ? additionalParam : 0;
                angle = -Math.PI / 2;
                break;
        }

        endPanel.transform.globalZRotation = angle;
        endPanel.localPosition = localPosition;
    }

    /**
     *
     * @param object
     * @param side
     * @param length of the filler
     */
    static addFiller(object, side, length) {
        //create object
        let AmRef = object.getComponents(ComponentConstants.ComponentType.AMFurnitureFinishesReference);
        let fillerData = null;
        if (AmRef.length === 0) {
            return;
        } else {
            fillerData = AmRef[0].fillerData;
        }
        let furniture = object.getComponents(ComponentConstants.ComponentType.FurnitureFinishes);
        if (fillerData === null || furniture.length !== 1 || (side !== "right" && side !== "left")) {
            return;
        }
        furniture = furniture[0];

        //Sketchblock info are to be taken to ignore objetc details
        let arrangementSize = object.getChildren([SceneConstants.EntityType.SketchBlock]).length === 1 ? object.getChildren([SceneConstants.EntityType.SketchBlock])[0] : object,
            localPosition = object.getChildren([SceneConstants.EntityType.SketchBlock]).length === 1 ? math.vec3.clone(object.getChildren([SceneConstants.EntityType.SketchBlock])[0].transform.localPosition) : math.vec3.create(),
            filler = EntityFactory.createArrangementObject(fillerData.objectId, fillerData.objectType, undefined, undefined, fillerData.styles, length,
                fillerData.width, arrangementSize.height, fillerData.configId, fillerData.coatingId, undefined, undefined, undefined);

        //add children + add to furniture finishes
        object.addChild(filler);
        furniture.fillers[side] = filler;

        //place it
        let angle = 0;
        switch (side) {
            case "left":
                localPosition[0] += -arrangementSize.length / 2 - filler.length / 2;
                localPosition[1] += arrangementSize.width / 2 - filler.width / 2;
                break;
            case "right":
                localPosition[0] += arrangementSize.length / 2 + filler.length / 2;
                localPosition[1] += arrangementSize.width / 2 - filler.width / 2;
                angle = Math.PI;
                break;
        }

        filler.transform.globalZRotation = angle;
        filler.localPosition = localPosition;
    }

    /**
     * get the boundingbox of the object with the fillers (with border of worktops)
     * @param object
     * @param worktopBorder
     */
    static getBoundingBoxKitchenTool(object, worktopBorder) {
        let obj = object.getChildren([SceneConstants.EntityType.SketchBlock]).length === 1 ? object.getChildren([SceneConstants.EntityType.SketchBlock])[0] : object,
            furniture = object.getComponents(ComponentConstants.ComponentType.FurnitureFinishes);

        if (furniture.length !== 1) {
            return obj.boundingBox;
        }
        furniture = furniture[0];

        let points = [];
        let point1 = math.vec3.create();
        let point2 = math.vec3.create();
        let point3 = math.vec3.create();
        let point4 = math.vec3.create();
        let point5 = math.vec3.create();
        let point6 = math.vec3.create();

        //FIXME angle furniture kitchen fab case : add plinth and endPanel
        let isAngleRight = false;
        let isAngleLeft = false;
        let isTwoSided = false;
        let isTwoSidedBeveled = false;
        if (object.objectType === SceneConstants.ArrangementType.kitchenFurnitureBottom && object._objectTypeConfig !== undefined && object._objectTypeConfig !== null && object._objectTypeConfig.angleDirection === "right") {
            isAngleLeft = true;
        }
        if (object.objectType === SceneConstants.ArrangementType.kitchenFurnitureBottom && object._objectTypeConfig !== undefined && object._objectTypeConfig !== null && object._objectTypeConfig.angleDirection === "left") {
            isAngleRight = true;
        }
        if (object.objectType === SceneConstants.ArrangementType.kitchenFurnitureBottom && object._objectTypeConfig !== undefined && object._objectTypeConfig !== null && object._objectTypeConfig.angleDirection === "twoSided") {
            isTwoSided = true;
        }
        if (object.objectType === SceneConstants.ArrangementType.kitchenFurnitureBottom && object._objectTypeConfig !== undefined && object._objectTypeConfig !== null && object._objectTypeConfig.angleDirection === "twoSidedBeveled") {
            isTwoSidedBeveled = true;
        }
        let rightLength = furniture.fillers.right !== null ? furniture.fillers.right.length : isAngleRight ? 52 : furniture.endPanels.right !== null ? (furniture.endPanels.right.objectType === SceneConstants.ArrangementType.kitchenEndPanel ? furniture.endPanels.right.width : 2) : 0;

        let leftLength = furniture.fillers.left !== null ? furniture.fillers.left.length : isAngleLeft ? 52 : furniture.endPanels.left !== null ? (furniture.endPanels.left.objectType === SceneConstants.ArrangementType.kitchenEndPanel ? furniture.endPanels.left.width : 2) : 0;
        let backLength = furniture.endPanels.back !== null ? (furniture.endPanels.back.objectType === SceneConstants.ArrangementType.kitchenEndPanel ? furniture.endPanels.back.width : 2) : 0;

        let length = obj.length;
        let width = obj.width;

        if (obj.entityType === SceneConstants.EntityType.SketchBlock) {
            if (obj.parent) {
                if (obj.parent._originalLength) {
                    length *= obj.parent.length / obj.parent._originalLength;
                }
                if (obj.parent._originalWidth) {
                    width *= obj.parent.width / obj.parent._originalWidth;
                }
            }
        }

        math.vec3.set(point1, obj.position[0] + Math.cos(obj.angle) * (length / 2 + rightLength), obj.position[1] + Math.sin(obj.angle) * (length / 2 + rightLength), 0);

        math.vec3.set(point2, point1[0] - Math.cos(Math.PI / 2 - obj.angle) * (width / 2 + worktopBorder), point1[1] + Math.sin(Math.PI / 2 - obj.angle) * (width / 2 + worktopBorder), 0);
        math.vec3.set(point1, point1[0] + Math.cos(Math.PI / 2 - obj.angle) * (width / 2 + backLength), point1[1] - Math.sin(Math.PI / 2 - obj.angle) * (width / 2 + backLength), 0);

        math.vec3.set(point3, obj.position[0] - Math.cos(obj.angle) * (length / 2 + leftLength), obj.position[1] - Math.sin(obj.angle) * (length / 2 + leftLength), 0);

        math.vec3.set(point4, point3[0] + Math.cos(Math.PI / 2 - obj.angle) * (width / 2 + backLength), point3[1] - Math.sin(Math.PI / 2 - obj.angle) * (width / 2 + backLength), 0);
        if (isTwoSided) {
            if (object.objectId === '673b15b3334b7f8a5cc45de7' || object.objectId === '673b15bc334b7f8a5cc45fad' || object.objectId === '673b15c4334b7f8a5cc46181' || object.objectId === '673b15cc334b7f8a5cc46345' ||
                object.objectId === '673b15b8334b7f8a5cc45ecb' || object.objectId === '673b15c0334b7f8a5cc460a3' || object.objectId === '673b15c8334b7f8a5cc46263' || object.objectId === '673b15d0334b7f8a5cc46427') {
                math.vec3.set(point3, point3[0] - Math.cos(Math.PI / 2 - obj.angle) * (width / 2 - 70 + worktopBorder), point3[1] + Math.sin(Math.PI / 2 - obj.angle) * (width / 2 - 70 + worktopBorder), 0);
                math.vec3.set(point5, point3[0] - Math.cos(obj.angle) * -40, point3[1] + Math.sin(obj.angle) * 40, 0);
                math.vec3.set(point6, point5[0] - Math.cos(Math.PI / 2 - obj.angle) * 70, point5[1] + Math.sin(Math.PI / 2 - obj.angle) * 70, 0);
            }
            else {
                math.vec3.set(point3, point3[0] - Math.cos(Math.PI / 2 - obj.angle) * (width / 2 - 60 + worktopBorder), point3[1] + Math.sin(Math.PI / 2 - obj.angle) * (width / 2 - 60 + worktopBorder), 0);
                math.vec3.set(point5, point3[0] - Math.cos(obj.angle) * -30, point3[1] + Math.sin(obj.angle) * 30, 0);
                math.vec3.set(point6, point5[0] - Math.cos(Math.PI / 2 - obj.angle) * 60, point5[1] + Math.sin(Math.PI / 2 - obj.angle) * 60, 0);
            }
        } else {
            if (isTwoSidedBeveled) {
                math.vec3.set(point3, point3[0] - Math.cos(Math.PI / 2 - obj.angle) * ((16 * width) / 100), point3[1] + Math.sin(Math.PI / 2 - obj.angle) * ((16 * width) / 100), 0);

                math.vec3.set(point2, point2[0] + Math.cos(Math.PI / 2 - obj.angle) * worktopBorder, point2[1] - Math.sin(Math.PI / 2 - obj.angle) * worktopBorder, 0);

                math.vec3.set(point5, point2[0] - Math.cos(obj.angle) * ((66 * length) / 100), point2[1] - Math.sin(obj.angle) * ((66 * length) / 100), 0);
            } else {
                math.vec3.set(point3, point3[0] - Math.cos(Math.PI / 2 - obj.angle) * (width / 2 + worktopBorder), point3[1] + Math.sin(Math.PI / 2 - obj.angle) * (width / 2 + worktopBorder), 0);
            }
        }

        points.push(point1);
        points.push(point2);
        if (isTwoSided) {
            points.push(point6);
            points.push(point5);
        }
        if (isTwoSidedBeveled) {
            points.push(point5);
        }
        points.push(point3);
        points.push(point4);

        return points;
    }

    /**
     * set furnitureFinishes for all assets of the scene
     */
    static setFurnitureFinishes(floor) {
        let floorArrangements = KitchenManager.getKitchenToolArrangements(floor, [SceneConstants.ArrangementType.kitchenFurnitureBottom, SceneConstants.ArrangementType.kitchenFurnitureTop, SceneConstants.ArrangementType.kitchenFurnitureColumn]),
            arrangement = null,
            arrangementBox = null,
            backSegment = null,
            middleSegment = null,
            testBoundingBox = null,
            crossPoint = null,
            testSegment = null,
            floorWalls = floor.walls,
            nearer;

        let i, j, k;
        for (i = 0; i < floorArrangements.length; i++) {
            arrangement = floorArrangements[i];

            nearer = {
                leftFront: KitchenToolConstants.DefaultFillerLength,
                left: KitchenToolConstants.DefaultFillerLength,
                lItem: null,
                rightFront: KitchenToolConstants.DefaultFillerLength,
                right: KitchenToolConstants.DefaultFillerLength,
                rItem: null,
                back: KitchenToolConstants.DefaultFillerLength,
                bItem: null,
                front: KitchenToolConstants.DefaultFillerLength,
            };

            //Get the component for the kitchen furniture or create id;
            let finishes = arrangement.getComponents(ComponentConstants.ComponentType.FurnitureFinishes);
            if (finishes.length === 0) {
                finishes = new FurnitureFinishes();
                arrangement.addComponent(finishes);
            } else {
                //delete old
                finishes = finishes[0];
                finishes.reInit(); //=> delete old
            }

            //test to add != finishes
            //filler <==>distance( point of the front face   , the object (top | Bot & column )| walls) < SceneConstants.MaxFillerSize         right or left only
            //plinth<==>always front, right/left/back :  distance( point of the face (center of the face)  , the object (top | Bot & column )| walls)>SceneConstants.MaxFillerSize
            //endPanel <==> never front,right/left/back : same as plinth
            arrangementBox = arrangement.getChildren([SceneConstants.EntityType.SketchBlock]).length === 1 ? arrangement.getChildren([SceneConstants.EntityType.SketchBlock])[0].boundingBox : arrangement.boundingBox;
            backSegment = new Segment(SavaneMath.getMiddle(arrangementBox[0], arrangementBox[3]), SavaneMath.getMiddle(arrangementBox[2], arrangementBox[1]));

            middleSegment = new Segment(SavaneMath.getMiddle(arrangementBox[0], arrangementBox[1]), SavaneMath.getMiddle(arrangementBox[2], arrangementBox[3]));
            //Left/Right : intersection of the object with [arrangementBox[1],arrangementBox[2]] , near 1 ==> right ,near 2 left
            for (j = 0; j < floorWalls.length; j++) {
                testBoundingBox = floorWalls[j].boundingBox;
                for (k = 0; k < testBoundingBox.length; k++) {
                    testSegment = new Segment(testBoundingBox[k], testBoundingBox[(k + 1) % 4]);

                    //for filler
                    crossPoint = SavaneMath.getCrossPoint(arrangementBox[1], arrangementBox[2], testSegment.begin, testSegment.end);
                    if (crossPoint !== null) {
                        //right
                        if (math.vec3.dist(crossPoint, arrangementBox[1]) < nearer.rightFront && testSegment.isPointOnSegment(crossPoint, KitchenToolConstants.FillerPrecision)) {
                            var joinery = joineryManager.getJoineryAtPosition(crossPoint, floorWalls[j].thickness / 2 + KitchenToolConstants.FillerPrecision, floor);
                            if (!joinery || joinery.floorHeight >= arrangement.floorHeight + arrangement.height) {
                                nearer.rightFront = math.vec3.dist(crossPoint, arrangementBox[1]);
                                nearer.rItem = floorWalls[j];
                            }
                        }
                        //left
                        if (math.vec3.dist(crossPoint, arrangementBox[2]) < nearer.leftFront && testSegment.isPointOnSegment(crossPoint, KitchenToolConstants.FillerPrecision)) {
                            var joinery = joineryManager.getJoineryAtPosition(crossPoint, floorWalls[j].thickness / 2 + KitchenToolConstants.FillerPrecision, floor);
                            if (!joinery || joinery.floorHeight >= arrangement.floorHeight + arrangement.height) {
                                nearer.leftFront = math.vec3.dist(crossPoint, arrangementBox[2]);
                                nearer.lItem = floorWalls[j];
                            }
                        }
                    }

                    //for endPanels/plinth
                    crossPoint = SavaneMath.getCrossPoint(middleSegment.begin, middleSegment.end, testSegment.begin, testSegment.end);
                    if (crossPoint !== null) {
                        //right
                        if (math.vec3.dist(crossPoint, middleSegment.begin) < nearer.right && testSegment.isPointOnSegment(crossPoint, KitchenToolConstants.FillerPrecision)) {
                            var joinery = joineryManager.getJoineryAtPosition(crossPoint, floorWalls[j].thickness + KitchenToolConstants.FillerPrecision, floor);
                            if (arrangement.objectTypeConfig) {
                                if (!joinery || joinery.floorHeight >= arrangement.objectTypeConfig.plinthHeight) {
                                    nearer.right = math.vec3.dist(crossPoint, middleSegment.begin);
                                }
                            }
                        }
                        //left
                        if (math.vec3.dist(crossPoint, middleSegment.end) < nearer.left && testSegment.isPointOnSegment(crossPoint, KitchenToolConstants.FillerPrecision)) {
                            var joinery = joineryManager.getJoineryAtPosition(crossPoint, floorWalls[j].thickness + KitchenToolConstants.FillerPrecision, floor);
                            if (arrangement.objectTypeConfig) {
                                if (!joinery || joinery.floorHeight >= arrangement.objectTypeConfig.plinthHeight) {
                                    nearer.left = math.vec3.dist(crossPoint, middleSegment.end);
                                }
                            }
                        }
                    }

                    crossPoint = SavaneMath.getCrossPoint(backSegment.begin, backSegment.end, testSegment.begin, testSegment.end);
                    if (crossPoint !== null) {
                        if (math.vec3.dist(crossPoint, backSegment.begin) < nearer.back && testSegment.isPointOnSegment(crossPoint, KitchenToolConstants.FillerPrecision)) {
                            nearer.back = math.vec3.dist(crossPoint, backSegment.begin);
                            nearer.bItem = floorWalls[j];
                        }
                    }
                }
            }

            for (j = 0; j < floorArrangements.length; j++) {
                if (i !== j && (floorArrangements[j].objectType === SceneConstants.ArrangementType.kitchenFurnitureColumn || arrangement.objectType === floorArrangements[j].objectType)) {
                    testBoundingBox = KitchenManager.getBoundingBoxKitchenTool(floorArrangements[j], 0);

                    for (k = 0; k < testBoundingBox.length; k++) {
                        testSegment = new Segment(testBoundingBox[k], testBoundingBox[(k + 1) % 4]);

                        //for fillter
                        crossPoint = SavaneMath.getCrossPoint(arrangementBox[1], arrangementBox[2], testSegment.begin, testSegment.end);
                        if (crossPoint !== null) {
                            //right
                            if (math.vec3.dist(crossPoint, arrangementBox[1]) < nearer.rightFront && testSegment.isPointOnSegment(crossPoint, KitchenToolConstants.FillerPrecision)) {
                                nearer.rightFront = math.vec3.dist(crossPoint, arrangementBox[1]);
                                nearer.rItem = floorArrangements[j];
                            }
                            //left
                            if (math.vec3.dist(crossPoint, arrangementBox[2]) < nearer.leftFront && testSegment.isPointOnSegment(crossPoint, KitchenToolConstants.FillerPrecision)) {
                                nearer.leftFront = math.vec3.dist(crossPoint, arrangementBox[2]);
                                nearer.lItem = floorArrangements[j];
                            }
                            // check if both sides have the same object
                            if ((nearer.lItem && nearer.rItem) && (nearer.lItem.id === nearer.rItem.id)) {
                                // only keep the closest
                                if (nearer.leftFront < nearer.rightFront) {
                                    nearer.rItem = null;
                                    nearer.rightFront = KitchenToolConstants.DefaultFillerLength;
                                }
                                else {
                                    nearer.lItem = null;
                                    nearer.leftFront = KitchenToolConstants.DefaultFillerLength;
                                }
                            }
                        }

                        //for endPanels/plinth
                        crossPoint = SavaneMath.getCrossPoint(middleSegment.begin, middleSegment.end, testSegment.begin, testSegment.end);
                        if (crossPoint !== null) {
                            //right
                            if (math.vec3.dist(crossPoint, middleSegment.begin) < nearer.right && testSegment.isPointOnSegment(crossPoint, KitchenToolConstants.FillerPrecision)) {
                                if (arrangement.width <= floorArrangements[j].width) {
                                    nearer.right = math.vec3.dist(crossPoint, middleSegment.begin);
                                }
                            }
                            //left
                            if (math.vec3.dist(crossPoint, middleSegment.end) < nearer.left && testSegment.isPointOnSegment(crossPoint, KitchenToolConstants.FillerPrecision)) {
                                if (arrangement.width <= floorArrangements[j].width) {
                                    nearer.left = math.vec3.dist(crossPoint, middleSegment.end);
                                }
                            }
                        }

                        crossPoint = SavaneMath.getCrossPoint(backSegment.begin, backSegment.end, testSegment.begin, testSegment.end);
                        if (crossPoint !== null) {
                            // back
                            if (math.vec3.dist(crossPoint, backSegment.begin) < nearer.back && testSegment.isPointOnSegment(crossPoint, KitchenToolConstants.FillerPrecision)) {
                                nearer.back = math.vec3.dist(crossPoint, backSegment.begin);
                            }

                            // front
                            if (math.vec3.dist(crossPoint, backSegment.end) < nearer.front && testSegment.isPointOnSegment(crossPoint, KitchenToolConstants.FillerPrecision)) {
                                nearer.front = math.vec3.dist(crossPoint, backSegment.end);
                            }
                        }
                    }
                }
            }

            let AmRef = arrangement.getComponents(ComponentConstants.ComponentType.AMFurnitureFinishesReference);
            if (AmRef.length === 0) {
                let amComp = AMComponentFactory.createComponent(arrangement);
                arrangement.addComponent(amComp);
            } else if (AmRef.length === 1 && (AmRef[0].fillerData.objectId === null || AmRef[0].fillerData.objectId === undefined || AmRef[0].endPanelData.objectId === null || AmRef[0].endPanelData.objectId === undefined || AmRef[0].plinthData.objectId === null || AmRef[0].plinthData.objectId === undefined)) {
                arrangement.removeComponent(AmRef[0]);
                let amComp = AMComponentFactory.createComponent(arrangement);
                arrangement.addComponent(amComp);
            }

            //FIXME angle furniture kitchen fab case : add plinth and endPanel
            if (arrangement.objectType === SceneConstants.ArrangementType.kitchenFurnitureBottom && arrangement._objectTypeConfig !== undefined && arrangement._objectTypeConfig !== null && arrangement._objectTypeConfig.angleDirection === "right") {
                if (nearer.leftFront === KitchenToolConstants.DefaultFillerLength || (nearer.lItem !== null && nearer.lItem.height < SavaneConstants.PositionTolerance)) {
                    KitchenManager.addPlinth(arrangement, "left", 50);
                    KitchenManager.addEndPanel(arrangement, "left", 50);
                }
            } else if (nearer.leftFront + SavaneConstants.PositionTolerance < KitchenToolConstants.DefaultFillerLength && nearer.leftFront - SavaneConstants.PositionTolerance > KitchenToolConstants.FillerPrecision) {
                // No filler for corner kitchen furnitures
                if (arrangement.objectTypeConfig !== undefined && arrangement.objectTypeConfig.angleDirection !== "twoSided" && arrangement.objectTypeConfig.angleDirection !== "twoSidedBeveled") {
                    if (nearer.lItem !== null) {
                        let arrBottom = arrangement.transform.globalPosition[2] - arrangement.height / 2;
                        let arrTop = arrBottom + arrangement.height;
                        let nearerBottom = nearer.lItem.transform.globalPosition[2] - nearer.lItem.height / 2;
                        let nearerTop = nearerBottom + nearer.lItem.height;

                        if (nearer.lItem.entityType === SceneConstants.EntityType.Wall) {
                            nearerBottom = nearer.lItem.transform.globalPosition[2];
                            nearerTop = nearerBottom + nearer.lItem.height;
                        }

                        if (nearerTop + SavaneConstants.PositionTolerance >= arrTop && nearerBottom - SavaneConstants.PositionTolerance <= arrBottom) {
                            KitchenManager.addFiller(arrangement, "left", nearer.leftFront);
                            finishes.fillers.left_wall = nearer.lItem.entityType === SceneConstants.EntityType.Wall;
                        }
                    }
                    if (nearer.lItem !== null && nearer.lItem.height < SavaneConstants.PositionTolerance) {
                        KitchenManager.addPlinth(arrangement, "left");
                        KitchenManager.addEndPanel(arrangement, "left");
                    }
                } else {
                    KitchenManager.addPlinth(arrangement, "left");
                }
            } else if (nearer.left === KitchenToolConstants.DefaultFillerLength || (arrangement.objectTypeConfig !== undefined && (arrangement.objectTypeConfig.angleDirection === "twoSided" || arrangement.objectTypeConfig.angleDirection === "twoSidedBeveled")) || (nearer.lItem !== null && nearer.lItem.height < SavaneConstants.PositionTolerance)) {
                KitchenManager.addPlinth(arrangement, "left");
                KitchenManager.addEndPanel(arrangement, "left");
            } else if (nearer.left + SavaneConstants.PositionTolerance < KitchenToolConstants.DefaultFillerLength && nearer.leftFront === KitchenToolConstants.DefaultFillerLength) {
                KitchenManager.addPlinth(arrangement, "left");
                KitchenManager.addEndPanel(arrangement, "left");
            }

            if (arrangement.objectType === SceneConstants.ArrangementType.kitchenFurnitureBottom && arrangement._objectTypeConfig !== undefined && arrangement._objectTypeConfig !== null && arrangement._objectTypeConfig.angleDirection === "left") {
                if (nearer.leftFront === KitchenToolConstants.DefaultFillerLength || (nearer.rItem !== null && nearer.rItem.height < SavaneConstants.PositionTolerance)) {
                    KitchenManager.addPlinth(arrangement, "right", 50);
                    KitchenManager.addEndPanel(arrangement, "right", 50);
                }
            } else if (nearer.rightFront + SavaneConstants.PositionTolerance < KitchenToolConstants.DefaultFillerLength && nearer.rightFront - SavaneConstants.PositionTolerance > KitchenToolConstants.FillerPrecision) {
                if (arrangement.objectTypeConfig === undefined || (arrangement.objectTypeConfig.angleDirection !== "twoSided" && arrangement.objectTypeConfig.angleDirection !== "twoSidedBeveled")) {
                    if (nearer.rItem !== null) {
                        let arrBottom = arrangement.transform.globalPosition[2] - arrangement.height / 2;
                        let arrTop = arrBottom + arrangement.height;
                        let nearerBottom = nearer.rItem.transform.globalPosition[2] - nearer.rItem.height / 2;
                        let nearerTop = nearerBottom + nearer.rItem.height;

                        if (nearer.rItem.entityType === SceneConstants.EntityType.Wall) {
                            nearerBottom = nearer.rItem.transform.globalPosition[2];
                            nearerTop = nearerBottom + nearer.rItem.height;
                        }

                        if (nearerTop + SavaneConstants.PositionTolerance >= arrTop && nearerBottom - SavaneConstants.PositionTolerance <= arrBottom) {
                            KitchenManager.addFiller(arrangement, "right", nearer.rightFront);
                            finishes.fillers.right_wall = nearer.rItem.entityType === SceneConstants.EntityType.Wall;
                        }
                    }
                    if (nearer.rItem !== null && nearer.rItem.height < SavaneConstants.PositionTolerance) {
                        KitchenManager.addPlinth(arrangement, "right");
                        KitchenManager.addEndPanel(arrangement, "right");
                    }
                }
            } else if (nearer.right === KitchenToolConstants.DefaultFillerLength || (nearer.rItem !== null && nearer.rItem.height < SavaneConstants.PositionTolerance)) {
                KitchenManager.addPlinth(arrangement, "right");
                KitchenManager.addEndPanel(arrangement, "right");
            } else if (nearer.right + SavaneConstants.PositionTolerance < KitchenToolConstants.DefaultFillerLength && nearer.rightFront === KitchenToolConstants.DefaultFillerLength) {
                KitchenManager.addPlinth(arrangement, "right");
                KitchenManager.addEndPanel(arrangement, "right");
            }

            if (nearer.back === KitchenToolConstants.DefaultFillerLength || (nearer.bItem !== null && nearer.bItem.height < SavaneConstants.PositionTolerance)) {
                KitchenManager.addPlinth(arrangement, "back");
                KitchenManager.addEndPanel(arrangement, "back");
            }

            //@aurelien Warning dirty specific gesture

            if (nearer.front === KitchenToolConstants.DefaultFillerLength && arrangement.objectType === SceneConstants.ArrangementType.kitchenFurnitureTop && arrangement.objectTypeConfig !== undefined && arrangement.objectTypeConfig.angleDirection !== "none") {
                KitchenManager.addEndPanel(arrangement, "front");
            }

            KitchenManager.addPlinth(arrangement, "front");
        }
        eventsManager.instance.dispatch(Events.FINISHES_DONE);
    }

    /**
     * Creates workTop for all assets (SceneConstants.ArrangementType.kitchenFurnitureBottom) of the scene
     * @param floor {Floor}
     * @param coating {Component} coating
     * @param credence {Component} credence
     * @param thickness {Number}
     * @param border {Number}
     */
    static generateKitchenWorktopAndFuse(floor, coating = null, credence = null, thickness = KitchenToolConstants.workTopDefaultThickness, border = KitchenToolConstants.workTopDefaultBorder) {
        let furnituresBottom = KitchenManager.getKitchenToolArrangements(floor, SceneConstants.ArrangementType.generateWorktopBottomArrangements),
            workTops = floor.getChildren([SceneConstants.EntityType.WorkTop]),
            furniture = null,
            i,
            j;

        let poly1, poly2, alreadyIn, areaComponent, fused;

        for (i = 0; i < furnituresBottom.length; i++) {
            //Furniture fused with worktop are already handle.
            alreadyIn = false;
            furniture = furnituresBottom[i];
            //take sketch box for computing worktop and filler/endpanels
            poly1 = KitchenManager.getBoundingBoxKitchenTool(furniture, border);

            for (j = 0; j < workTops.length; j++) {
                areaComponent = workTops[j].getComponent(ComponentConstants.ComponentType.Area);
                //fuse area component to worktop
                if (areaComponent !== null && Math.abs(furniture.position[2] + furniture.localToFloorMaxHeight / 2 - workTops[j].position[2]) < KitchenToolConstants.WorkTopEightPrecision) {
                    poly2 = areaComponent.vertices;
                    fused = SavaneMath.fusePolys(poly1, poly2);
                    if (fused !== null) {
                        alreadyIn = true;
                        areaComponent.vertices = fused;
                        //update height
                        let pos = math.vec3.create();
                        math.vec3.set(pos, 0, 0, Math.min(workTops[j].transform.localPosition[2], furniture.localToFloorMaxHeight + thickness * 0.5));
                        workTops[j].transform.localPosition = pos;
                        break;
                    }
                }
            }

            if (!alreadyIn) {
                let newWorkTop = EntityFactory.createWorkTop(thickness);
                (newWorkTop.getComponent(ComponentConstants.ComponentType.Area) as Area).vertices = poly1;
                let pos = math.vec3.create();
                math.vec3.set(pos, 0, 0, furniture.localToFloorMaxHeight + thickness * 0.5);
                newWorkTop.transform.localPosition = pos;
                if (credence !== null) {
                    newWorkTop.addComponent(credence);
                }
                if (coating !== null) {
                    newWorkTop.addComponent(coating);
                }
                floor.addChild(newWorkTop);
                workTops.push(newWorkTop);
            }
        }

        this.fuseWorktop(floor);
        this.reprojectToClosestWall(floor);
        this.generateCredences(floor);
    }

    /**
     * Creates credences for all worktops of the floor
     * @param floor {Floor}
     */
    static generateCredences(floor) {
        let wall,
            worktop,
            segment,
            i,
            j,
            k,
            area,
            worktops = floor.getChildren([SceneConstants.EntityType.WorkTop]),
            walls = floor.walls;

        for (j = 0; j < worktops.length; j++) {
            worktops[j].credences = [];
        }

        for (i = 0; i < walls.length; i++) {
            wall = walls[i];
            for (j = 0; j < worktops.length; j++) {
                worktop = worktops[j];
                area = worktop.getComponent(ComponentConstants.ComponentType.Area);
                for (k = 0; k < area.vertices.length; k++) {
                    segment = new Segment(area.vertices[k], area.vertices[(k + 1) % area.vertices.length]);
                    if (wall.isPointOnWall(area.vertices[k], SavaneConstants.MagnetDistance) && wall.isPointOnWall(area.vertices[(k + 1) % area.vertices.length], SavaneConstants.MagnetDistance) && Segment.areColinear(segment, wall.segment, SavaneConstants.PositionTolerance)) {
                        if (wall.height >= worktop.maxHeight + ComponentConstants.CredenceDefaultHeight) {
                            worktop.addCredence(segment.begin, segment.end);
                        }
                    }
                }
            }
        }
    }

    static reprojectToClosestWall(floor) {
        let workTops = floor.getChildren([SceneConstants.EntityType.WorkTop]);
        let walls = floor.walls;
        for (let i = 0; i < workTops.length; ++i) {
            let worktop = workTops[i];
            let area = worktop.getComponent(ComponentConstants.ComponentType.Area);
            for (let j = 0; j < walls.length; ++j) {
                let wall = walls[j];
                let barycenter = math.vec3.create();
                let length = area.vertices.length;
                for (let k = 0; k < length; ++k) {
                    let point = area.vertices[k];
                    math.vec3.add(barycenter, barycenter, point);
                }
                math.vec3.divide(barycenter, barycenter, math.vec3.fromValues(length, length, length));
                for (let k = 0; k < length; ++k) {
                    let point = area.vertices[k];
                    if (wall.isPointOnWall(point, 0)) {
                        let bboxCut = wall.boundingBoxCut;
                        let segment1 = new Segment(bboxCut[2], bboxCut[1]);
                        let segment2 = new Segment(bboxCut[3], bboxCut[0]);
                        let segment = segment1;
                        if (math.vec3.squaredDistance(barycenter, segment2.orthogonalProjection(barycenter)) < math.vec3.squaredDistance(barycenter, segment1.orthogonalProjection(barycenter))) {
                            segment = segment2;
                        }
                        let p = segment.orthogonalProjection(point);
                        area.vertices[k] = p;
                    }
                }
            }
        }
    }

    /**
     * Creates workTop for all assets (SceneConstants.ArrangementType.kitchenFurnitureBottom) of the scene
     * @param floor {Floor}
     * @param coating {Component} coating
     * @param credence {Component} credence
     * @param thickness {Number}
     * @param border {Number}
     */
    static fuseWorktop(floor) {
        let workTops = floor.getChildren([SceneConstants.EntityType.WorkTop]),
            i,
            j,
            k;

        let fused;

        let areaComponent1, areaComponent2, tmpPoint, workTopFused, legs, found, credences;
        i = 0;

        while (i < workTops.length) {
            workTopFused = false;
            areaComponent1 = workTops[i].getComponent(ComponentConstants.ComponentType.Area);
            for (j = 0; j < workTops.length; j++) {
                if (i !== j) {
                    areaComponent2 = workTops[j].getComponent(ComponentConstants.ComponentType.Area);

                    if ((areaComponent1 !== null && areaComponent2 !== null && Math.abs(workTops[j].position[2] - workTops[i].position[2]) < KitchenToolConstants.WorkTopEightPrecision) || workTops[j].position[2] < KitchenToolConstants.WorkTopEightPrecision || workTops[i].position[2] < KitchenToolConstants.WorkTopEightPrecision) {
                        fused = SavaneMath.fusePolys(areaComponent1.vertices, areaComponent2.vertices);
                        if (fused !== null) {
                            areaComponent1.vertices = fused;
                            if (workTops[i].transform.localPosition[2] < workTops[j].transform.localPosition[2]) {
                                workTops[i].transform.localPosition = workTops[j].transform.localPosition;
                            }
                            workTops[j].deleteFromParent();
                            workTops.splice(j, 1);
                            workTopFused = true;
                            break;
                        }
                    }
                }
            }

            if (i < workTops.length) {
                var vertices = areaComponent1.vertices;
                //delete legs unrevelant
                legs = workTops[i].legs;
                for (j = legs.length - 1; j >= 0; j--) {
                    found = false;
                    for (k = 0; k < vertices.length; k++) {
                        if ((math.vec3.dist(legs[j].begin, vertices[k]) < SavaneConstants.PositionTolerance && math.vec3.dist(legs[j].end, vertices[(k + 1) % vertices.length]) < SavaneConstants.PositionTolerance) || (math.vec3.dist(legs[j].end, vertices[k]) < SavaneConstants.PositionTolerance && math.vec3.dist(legs[j].begin, vertices[(k + 1) % vertices.length]) < SavaneConstants.PositionTolerance)) {
                            if (math.vec3.dist(legs[j].end, vertices[k]) < SavaneConstants.PositionTolerance && math.vec3.dist(legs[j].begin, vertices[(k + 1) % vertices.length]) < SavaneConstants.PositionTolerance) {
                                tmpPoint = legs[j].end;
                                legs[j].end = legs[j].begin;
                                legs[j].begin = tmpPoint;
                            }
                            found = true;
                        }
                    }
                    if (!found) {
                        workTops[i].deleteLeg(j);
                    }
                }

                //delete credences unrevelant
                credences = workTops[i].credences;
                for (j = credences.length - 1; j >= 0; j--) {
                    found = false;
                    for (k = 0; k < vertices.length; k++) {
                        if ((math.vec3.dist(credences[j].begin, vertices[k]) < SavaneConstants.PositionTolerance && math.vec3.dist(credences[j].end, vertices[(k + 1) % vertices.length]) < SavaneConstants.PositionTolerance) || (math.vec3.dist(credences[j].end, vertices[k]) < SavaneConstants.PositionTolerance && math.vec3.dist(credences[j].begin, vertices[(k + 1) % vertices.length]) < SavaneConstants.PositionTolerance)) {
                            if (math.vec3.dist(credences[j].end, vertices[k]) < SavaneConstants.PositionTolerance && math.vec3.dist(credences[j].begin, vertices[(k + 1) % vertices.length]) < SavaneConstants.PositionTolerance) {
                                tmpPoint = credences[j].end;
                                credences[j].end = credences[j].begin;
                                credences[j].begin = tmpPoint;
                            }
                            found = true;
                        }
                    }
                    if (!found) {
                        workTops[i].deleteCredence(j);
                    }
                }

                //delete unecessary point
                areaComponent1.smooth();
                areaComponent1.mergeVertices();
                areaComponent1.cleanColinearVertices();
            }

            if (!workTopFused) {
                i++;
            }
        }

        let arrlist = floor.getChildren([SceneConstants.EntityType.ArrangementObject]);
        for (let u = 0; u < arrlist.length; u++) {
            arrlist[u].updateForWorktop(workTops);
        }
    }

    static worktopRoutine(floor) {
        let workTops = floor.getChildren([SceneConstants.EntityType.WorkTop]);

        this.mergeWorktopVertices(floor);

        let arrlist = floor.getChildren([SceneConstants.EntityType.ArrangementObject]);
        for (let u = 0; u < arrlist.length; u++) {
            arrlist[u].updateForWorktop(workTops);
        }
    }

    static mergeWorktopVertices(floor) {
        let workTops = floor.getChildren([SceneConstants.EntityType.WorkTop]),
            i,
            areaComponent;
        i = 0;

        while (i < workTops.length) {
            areaComponent = workTops[i].getComponent(ComponentConstants.ComponentType.Area);

            areaComponent.mergeVertices();

            this.worktopCredencesLegsGesture(workTops, i);
            ++i;
        }
    }

    static smoothAndMergeWorktopVertices(floor) {
        let workTops = floor.getChildren([SceneConstants.EntityType.WorkTop]),
            i,
            areaComponent;
        i = 0;

        while (i < workTops.length) {
            areaComponent = workTops[i].getComponent(ComponentConstants.ComponentType.Area);

            areaComponent.mergeVertices();
            areaComponent.smooth();

            this.worktopCredencesLegsGesture(workTops, i);
            ++i;
        }
    }

    static worktopCredencesLegsGesture(workTops, i) {
        let legs, tmpPoint, found, j, k, credences;
        var vertices = workTops[i].getComponent(ComponentConstants.ComponentType.Area).vertices;
        legs = workTops[i].legs;
        for (j = legs.length - 1; j >= 0; j--) {
            found = false;
            for (k = 0; k < vertices.length; k++) {
                if ((math.vec3.dist(legs[j].begin, vertices[k]) < SavaneConstants.PositionTolerance && math.vec3.dist(legs[j].end, vertices[(k + 1) % vertices.length]) < SavaneConstants.PositionTolerance) || (math.vec3.dist(legs[j].end, vertices[k]) < SavaneConstants.PositionTolerance && math.vec3.dist(legs[j].begin, vertices[(k + 1) % vertices.length]) < SavaneConstants.PositionTolerance)) {
                    if (math.vec3.dist(legs[j].end, vertices[k]) < SavaneConstants.PositionTolerance && math.vec3.dist(legs[j].begin, vertices[(k + 1) % vertices.length]) < SavaneConstants.PositionTolerance) {
                        tmpPoint = legs[j].end;
                        legs[j].end = legs[j].begin;
                        legs[j].begin = tmpPoint;
                    }
                    found = true;
                }
            }
            if (!found) {
                workTops[i].deleteLeg(j);
            }
        }
        credences = workTops[i].credences;
        for (j = credences.length - 1; j >= 0; j--) {
            found = false;
            for (k = 0; k < vertices.length; k++) {
                if ((math.vec3.dist(credences[j].begin, vertices[k]) < SavaneConstants.PositionTolerance && math.vec3.dist(credences[j].end, vertices[(k + 1) % vertices.length]) < SavaneConstants.PositionTolerance) || (math.vec3.dist(credences[j].end, vertices[k]) < SavaneConstants.PositionTolerance && math.vec3.dist(credences[j].begin, vertices[(k + 1) % vertices.length]) < SavaneConstants.PositionTolerance)) {
                    if (math.vec3.dist(credences[j].end, vertices[k]) < SavaneConstants.PositionTolerance && math.vec3.dist(credences[j].begin, vertices[(k + 1) % vertices.length]) < SavaneConstants.PositionTolerance) {
                        tmpPoint = credences[j].end;
                        credences[j].end = credences[j].begin;
                        credences[j].begin = tmpPoint;
                    }
                    found = true;
                }
            }
            if (!found) {
                workTops[i].deleteCredence(j);
            }
        }
    }

    /**
     * Creates workTop for all assets (SceneConstants.ArrangementType.kitchenFurnitureBottom) of the scene
     * @param floor {Floor}
     * @param coating {Component} coating
     * @param credence {Component} credence
     * @param thickness {Number}
     * @param border {Number}
     */
    static setWorkTop(floor, coating = null, credence = null, thickness = KitchenToolConstants.workTopDefaultThickness, border = KitchenToolConstants.workTopDefaultBorder) {
        let furnituresBottom = KitchenManager.getKitchenToolArrangements(floor, SceneConstants.ArrangementType.generateWorktopBottomArrangements),
            workTops = floor.getChildren([SceneConstants.EntityType.WorkTop]),
            furniture = null,
            i,
            j,
            k;

        let poly1, poly2, alreadyIn, areaComponent, fused;
        for (i = 0; i < furnituresBottom.length; i++) {
            //Furniture fused with worktop are already handle.
            alreadyIn = false;
            furniture = furnituresBottom[i];
            //take sketch box for computing worktop and filler/endpanels
            poly1 = KitchenManager.getBoundingBoxKitchenTool(furniture, border);

            for (j = 0; j < workTops.length; j++) {
                areaComponent = workTops[j].getComponent(ComponentConstants.ComponentType.Area);
                //fuse area component to worktop
                if (areaComponent !== null && Math.abs(furniture.position[2] + furniture.height / 2 - workTops[j].position[2]) < KitchenToolConstants.WorkTopEightPrecision) {
                    poly2 = areaComponent.vertices;
                    fused = SavaneMath.fusePolys(poly1, poly2);
                    if (fused !== null) {
                        alreadyIn = true;
                        areaComponent.vertices = fused;
                        //update height
                        let pos = math.vec3.create();
                        math.vec3.set(pos, 0, 0, Math.min(workTops[j].transform.localPosition[2], furniture.maxHeight + thickness * 0.5));
                        workTops[j].transform.localPosition = pos;
                        break;
                    }
                }
            }

            if (!alreadyIn) {
                let newWorkTop = EntityFactory.createWorkTop(thickness);
                (newWorkTop.getComponent(ComponentConstants.ComponentType.Area) as Area).vertices = poly1;
                let pos = math.vec3.create();
                math.vec3.set(pos, 0, 0, furniture.maxHeight + thickness * 0.5);
                newWorkTop.transform.localPosition = pos;
                if (credence !== null) {
                    newWorkTop.addComponent(credence);
                }
                if (coating !== null) {
                    newWorkTop.addComponent(coating);
                }
                floor.addChild(newWorkTop);
                workTops.push(newWorkTop);
            }
        }

        let areaComponent1, areaComponent2, tmpPoint, workTopFused, legs, found, credences;
        i = 0;

        while (i < workTops.length) {
            workTopFused = false;
            areaComponent1 = workTops[i].getComponent(ComponentConstants.ComponentType.Area);
            for (j = 0; j < workTops.length; j++) {
                if (i !== j) {
                    areaComponent2 = workTops[j].getComponent(ComponentConstants.ComponentType.Area);

                    if ((areaComponent1 !== null && areaComponent2 !== null && Math.abs(workTops[j].position[2] - workTops[i].position[2]) < KitchenToolConstants.WorkTopEightPrecision) || workTops[j].position[2] < KitchenToolConstants.WorkTopEightPrecision || workTops[i].position[2] < KitchenToolConstants.WorkTopEightPrecision) {
                        fused = SavaneMath.fusePolys(areaComponent1.vertices, areaComponent2.vertices);
                        if (fused !== null) {
                            areaComponent1.vertices = fused;
                            if (workTops[i].transform.localPosition[2] < workTops[j].transform.localPosition[2]) {
                                workTops[i].transform.localPosition = workTops[j].transform.localPosition;
                            }
                            workTops[j].deleteFromParent();
                            workTops.splice(j, 1);
                            workTopFused = true;
                            break;
                        }
                    }
                }
            }

            if (i < workTops.length) {
                var vertices = areaComponent1.vertices;
                //delete legs unrevelant
                legs = workTops[i].legs;
                for (j = legs.length - 1; j >= 0; j--) {
                    found = false;
                    for (k = 0; k < vertices.length; k++) {
                        if ((math.vec3.dist(legs[j].begin, vertices[k]) < SavaneConstants.PositionTolerance && math.vec3.dist(legs[j].end, vertices[(k + 1) % vertices.length]) < SavaneConstants.PositionTolerance) || (math.vec3.dist(legs[j].end, vertices[k]) < SavaneConstants.PositionTolerance && math.vec3.dist(legs[j].begin, vertices[(k + 1) % vertices.length]) < SavaneConstants.PositionTolerance)) {
                            if (math.vec3.dist(legs[j].end, vertices[k]) < SavaneConstants.PositionTolerance && math.vec3.dist(legs[j].begin, vertices[(k + 1) % vertices.length]) < SavaneConstants.PositionTolerance) {
                                tmpPoint = legs[j].end;
                                legs[j].end = legs[j].begin;
                                legs[j].begin = tmpPoint;
                            }
                            found = true;
                        }
                    }
                    if (!found) {
                        workTops[i].deleteLeg(j);
                    }
                }

                //delete credences unrevelant
                credences = workTops[i].credences;
                for (j = credences.length - 1; j >= 0; j--) {
                    found = false;
                    for (k = 0; k < vertices.length; k++) {
                        if ((math.vec3.dist(credences[j].begin, vertices[k]) < SavaneConstants.PositionTolerance && math.vec3.dist(credences[j].end, vertices[(k + 1) % vertices.length]) < SavaneConstants.PositionTolerance) || (math.vec3.dist(credences[j].end, vertices[k]) < SavaneConstants.PositionTolerance && math.vec3.dist(credences[j].begin, vertices[(k + 1) % vertices.length]) < SavaneConstants.PositionTolerance)) {
                            if (math.vec3.dist(credences[j].end, vertices[k]) < SavaneConstants.PositionTolerance && math.vec3.dist(credences[j].begin, vertices[(k + 1) % vertices.length]) < SavaneConstants.PositionTolerance) {
                                tmpPoint = credences[j].end;
                                credences[j].end = credences[j].begin;
                                credences[j].begin = tmpPoint;
                            }
                            found = true;
                        }
                    }
                    if (!found) {
                        workTops[i].deleteCredence(j);
                    }
                }

                //delete unecessary point
                areaComponent1.smooth();
            }

            if (!workTopFused) {
                i++;
            }
        }

        let arrlist = floor.getChildren([SceneConstants.EntityType.ArrangementObject]);
        for (let u = 0; u < arrlist.length; u++) {
            arrlist[u].updateForWorktop(workTops);
        }
    }

    /**
     * return asset ref array use in quotation
     * @param scene
     * @returns {Array}
     */
    static getAssetReferences(scene) {
        let refs = [];
        //Plinth refs have special quantity ==> divise linear m by the length of one kit
        let plinthRefs = [];
        let assets = KitchenManager.getKitchenToolArrangements(scene, [SceneConstants.ArrangementType.kitchenFurnitureBottom, SceneConstants.ArrangementType.kitchenFurnitureTop, SceneConstants.ArrangementType.kitchenFurnitureColumn, SceneConstants.ArrangementType.kitchenPlinth, SceneConstants.ArrangementType.kitchenFiller, SceneConstants.ArrangementType.kitchenHandle]);

        let i, j, ref, amRefs, visibleSide;
        for (i = 0; i < assets.length; i++) {
            ref = null;
            visibleSide = null;
            amRefs = assets[i].getComponents(ComponentConstants.ComponentType.FurnitureFinishes);
            if (amRefs.length === 1) {
                visibleSide = amRefs[0].endPanels.left !== null ? amRefs[0].endPanels.left.objectId : amRefs[0].endPanels.right !== null ? amRefs[0].endPanels.right.objectId : amRefs[0].endPanels.back !== null ? amRefs[0].endPanels.back.objectId : null;
            }

            for (j = 0; j < refs.length; j++) {
                if (assets[i].objectId === refs[j].id && assets[i].colorId === refs[j].configId && visibleSide === refs[j].visibleSide) {
                    ref = refs[j];
                    break;
                }
            }

            if (ref === null) {
                ref = {
                    id: assets[i].objectId,
                    configId: assets[i].colorId,
                    visibleSide: visibleSide,
                    quantity: 0,
                };
                refs.push(ref);
                if (assets[i].objectType === SceneConstants.ArrangementType.kitchenPlinth) {
                    plinthRefs.push(ref);
                }
            }

            //linear m
            if (assets[i].objectType === SceneConstants.ArrangementType.kitchenPlinth) {
                ref.quantity += assets[i].length;
            } else {
                ref.quantity++;
            }
        }

        //find AMFurnitureFinishesReference to get length of plinth kit (else DefaultPlinthLengthKit)
        let components = null;
        let kitDistance = KitchenToolConstants.DefaultPlinthLengthKit;
        for (i = 0; i < plinthRefs.length; i++) {
            for (j = 0; j < assets.length; j++) {
                components = assets[j].getComponents(ComponentConstants.ComponentType.AMFurnitureFinishesReference);
                if (components.length === 1 && components[0].plinthData.objectId === plinthRefs[i].id && components[0].plinthData.configId === plinthRefs[i].configId && components[0].plinthData.length > 0) {
                    kitDistance = components[0].plinthData.length;
                    break;
                }
            }

            plinthRefs[i].quantity = Math.floor(plinthRefs[i].quantity / kitDistance) + 1;
        }

        return refs;
    }

    /**
     * return coating ref array use in quotation
     * (do not handle several credence/coating)
     * @param scene
     * @returns {Array}
     */
    static getCoatingReferences(scene) {
        let worktops = scene.getChildren([SceneConstants.EntityType.WorkTop]),
            coating = null,
            credence = null,
            coatingFound = false,
            credenceFound = false,
            refs = [],
            i;

        for (i = 0; i < worktops.length; i++) {
            coating = worktops[i].getComponents(ComponentConstants.ComponentType.Coating);
            credence = worktops[i].getComponents(ComponentConstants.ComponentType.Credence);

            if (!coatingFound && coating.length === 1 && coating[0].coatingId !== null && coating[0].coatingId !== undefined && coating[0].colorIndex > 0) {
                refs.push({
                    id: coating[0].coatingId,
                    configId: coating[0].colorIndex,
                });
                coatingFound = true;
            }

            if (!credenceFound && credence.length === 1 && credence[0].coatingId !== undefined && credence[0].coatingId !== null && credence[0].colorIndex > 0) {
                refs.push({
                    id: credence[0].coatingId,
                    configId: credence[0].colorIndex,
                });
                credenceFound = true;
            }

            if (coatingFound && credenceFound) {
                break;
            }
        }

        return refs;
    }
}
