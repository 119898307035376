import { TemporaryFunctionalityChip } from "./temporary/TemporaryModule";
import { Functionality, RendererTopView, ComponentConstants, Floor, SceneConstants, math } from "../SavaneJS";
import { Entity } from "./Entity";

/**
 * FunctionalityChip is a chip that can be placed in a room
 * and defines area of functionalityId
 */
export class FunctionalityChip extends Entity {
    private _onPlan: boolean;

    constructor() {
        super();
        this._onPlan = false;
    }

    /**
     * Getter for the Entity type
     */
    get entityType(): SceneConstants.EntityType {
        return SceneConstants.EntityType.FunctionalityChip;
    }

    get position(): math.vec3 {
        return this.transform.globalPosition;
    }

    set position(p: math.vec3) {
        this.transform.globalPosition = p;
    }

    /**
     * Check whereever the chip is in a valid position inside the room
     * @returns {boolean}
     */
    isValid(floor: Floor): boolean {
        return !this.isOnPlan || this.room.isInRoom(this.transform.globalPosition);
    }

    get isOnPlan():boolean {
        return this._onPlan;
    }

    set isOnPlan(v: boolean) {
        this._onPlan = v;
    }

    get functionality(): ComponentConstants.Functionalities {
        let component = this.getComponent(ComponentConstants.ComponentType.Functionality) as Functionality;
        return component === null ? null : component.functionalityId;
    }

    set data(d: any) {
        if (this.temporary === null) {
            let component = this.getComponent(ComponentConstants.ComponentType.Functionality) as Functionality;

            if (component !== null) {
                component._data = d;
            }
        } else {
            (this.temporary as TemporaryFunctionalityChip).data = d;
        }
    }

    get data(): any {
        if (this.temporary === null) {
            let component = this.getComponent(ComponentConstants.ComponentType.Functionality) as Functionality;

            if (component !== null) {
                return component._data;
            }
            return undefined;
        } else {
            return (this.temporary as TemporaryFunctionalityChip).data;
        }
    }

    setData(field: string, value: any) {
        if (this.temporary === null) {
            let component = this.getComponent(ComponentConstants.ComponentType.Functionality) as Functionality;

            if (component !== null) {
                component._data[field] = value;
            }
        } else {
            (this.temporary as TemporaryFunctionalityChip).data[field] = value;
        }
    }

    getData(field: string): any {
        if (this.temporary === null) {
            let component = this.getComponent(ComponentConstants.ComponentType.Functionality) as Functionality;

            if (component !== null) {
                return component._data[field];
            }
            return undefined;
        } else {
            return (this.temporary as TemporaryFunctionalityChip).data[field];
        }
    }

    changeFunctionality(newFunction: ComponentConstants.Functionalities, name: string, imgUrl: string, imgSecondaryUrl: string) {
        for (let i = 0; i < this.components.length; ) {
            if (this.components[i].componentType === ComponentConstants.ComponentType.Functionality || this.components[i].componentType === ComponentConstants.ComponentType.RendererTopView) {
                this.components.splice(i, 1);
            } else {
                i++;
            }
        }
        this.addComponent(new Functionality(newFunction, name, null));
        this.addComponent(new RendererTopView(imgUrl, imgSecondaryUrl));
    }

    addToCappedProperty(property: string, value: any) : number {
        let capped = 0; // 0 = no cap, 1 = minus cap, 2 max cap

        if (this[property] === undefined) {
            let val = this.getData(property);

            if (val === undefined) {
                return -1;
            } else {
                if (val + value <= 1) {
                    this.setData(property, 1);
                    capped = 1;
                } else {
                    this.setData(property, val + value);
                }
            }
        } else {
            this[property] += value;
        }

        return capped;
    }

    /**
     * Create the renderCamera that will be used during edition
     */
    startTemporary() {
        let temp = new TemporaryFunctionalityChip(this);
        this.temporary = temp;
    }

    /**li
     * delete the renderCamera
     */
    endTemporary() {
        this.temporary = null;
    }

    /**
     * save the temporary data in the renderCamera and delete the temporaryArrangement
     */
    saveAndEndTemporary() {
        let dataTemp = (this.temporary as TemporaryFunctionalityChip).data;

        this.temporary = null;

        let component = this.getComponent(ComponentConstants.ComponentType.Functionality) as Functionality;

        if (component !== null) {
            component._data = dataTemp;
        }
    }
}
