import { Command, CommandEnum, SymetryFloorCommand } from './CommandModule';
import { Scene } from '../SavaneJS';

export class SymetryProjectCommand extends Command
{
    // Command list to execute and undo
    private _subCommands: Array<Command> = [];
    // Symetry axis
    private _axis: string;
    
    constructor(axis: string, scene: Scene) {
        super();
        // Symetry axis
        this._axis = axis;
        // Build commands
        for (let i = 0; i < scene.floors.length; i++) {
            this._subCommands.push(new SymetryFloorCommand(axis, scene.floors[i]));
        }
    }

    // Command name returned from a global enum
    name(): string {
        return CommandEnum.SymetryProjectCommand;
    }

    // Datas that allows the command to be executed
    execDatas(): any {
        return {
            axis: this._axis
        };
    }

    // Undo the current command
    undo() {
        // Call symetry floor undo for each command created by the constructor
        for (let i = 0; i < this._subCommands.length; i++) {
            this._subCommands[i].undo();
        }

        super.undo();
    }

    // Execute current command (redo), call the symetry floor commands created in the constructor
    execute() {
        // Parse all commands and execute them
        for (let i = 0; i < this._subCommands.length; i++) {
            this._subCommands[i].execute();
        }

        super.execute();
    }
};
