import {ComponentConstants} from '../SavaneJS';
import {Component} from './Component';

export class DomeLight extends Component {
    private _warmth: number;
    private _intensity: number;

    constructor() {
        super();
        this._intensity = 0;
        this._warmth = 0;
    }

    get componentType(): ComponentConstants.ComponentType {
        return ComponentConstants.ComponentType.DomeLight;
    }
    
    get intensity(): number {
        return this._intensity;
    }

    set intensity(i: number) {
        this._intensity = i;
    }
    
    get warmth(): number {
        return this._warmth;
    }

    set warmth(w: number) {
        this._warmth = w;
    }
}
