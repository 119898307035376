import { Importer } from "./Importer";
import { Exporter } from "./Exporter";
import { JSONImporter } from "../../utils/JSONImporter";
import { JSONSerializer } from "../../utils/JSONSerializer";

export class VersionHandler {
    constructor() {}

    /**
     * dirty conversion between format && hull ...
     * @param format
     */
    static toHullFormat(format) {
        let functionImportSaved = JSONImporter.externalImportComponents,
            functionExportSaved = JSONSerializer.externalSerializeComponents;

        JSONImporter.externalImportComponents = [Importer.importComponent];
        JSONSerializer.externalSerializeComponents = [Exporter.exportComponent];
        let scene = JSONImporter.importScene(format);
        JSONSerializer.externalSerializeComponents = [Exporter.exportComponentForHull];
        let json = JSONSerializer.serializeEntity(scene);

        JSONImporter.externalImportComponents = functionImportSaved;
        JSONSerializer.externalSerializeComponents = functionExportSaved;

        return json;
    }
}
