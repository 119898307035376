/**
 * Class which contains static cookie functions
 */
export class SavaneCookie {
    constructor()
    {
    }

    static getCookie(cname, valueIfMissing)
    {
        try
        {
            var name = cname + "=";
            var decodedCookie = decodeURIComponent(document.cookie);
            var ca = decodedCookie.split(';');
            for(var i = 0; i <ca.length; i++) {
                var c = ca[i];
                while (c.charAt(0) == ' ') {
                    c = c.substring(1);
                }
                if (c.indexOf(name) == 0) {
                    var value = c.substring(name.length, c.length);
                    if (value === 'null') return valueIfMissing;
                    return value;
                }
            }
        }
        catch(err)
        {
        }

        SavaneCookie.setCookie(cname, valueIfMissing);

        return valueIfMissing;
    }

    /**
     * get middle of the segment
     */
    static setCookie(cname, cvalue)
    {
        if (!cvalue) {
            throw "Trying to set null or undefined value for cookie " + cname;
        }
        try
        {
            var d = new Date();
            // 40 years expiration
            d.setTime(d.getTime() + (365*40*24*60*60*1000));
            var expires = "expires="+ d.toUTCString();
            document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
        }
        catch(err)
        {
        }
    }
}
