import { SavaneCookie } from '../../utils/SavaneCookie';
import { TemporaryJoinery } from './../temporary/TemporaryJoinery';
import { JoineryType, ComponentConstants, SceneConstants, math } from '../../SavaneJS';
import { Joinery } from './Joinery';

/**
 * Joinery  of type : Window
 */
export class Window extends Joinery {
    // Defaul number of casements (battants) of a window
    private _nbCasement = 2;
    //Opening mode slide or default
    private _openingMode = SceneConstants.OpeningMode.mode_default;
    //Slide params
    public slideDirection = SceneConstants.SlideDirection.slide_left;
    //Not slide params
    private _handleSide = SceneConstants.HandleSide.handle_left;
    public openingSymetry = SceneConstants.Symetry.default;
    // Default door isn't opened
    private _isOpened = false;
    private _leftOpeningAngle = 90;
    private _rightOpeningAngle = 90;

    constructor(joineryType: SceneConstants.JoineryType, position: math.vec3) {
        super(joineryType, position);

        // Adjust joinery 3D model thanks to the parameters set above
        this.setJoineryType();
    }

    //Getters and setters
    get openingMode(): SceneConstants.OpeningMode {
        if (this.temporary === null) {
            return this._openingMode;
        }
        else {
            return (this.temporary as TemporaryJoinery).openingMode;
        }
    }

    /**
     * Change the opening mode to a value from SceneConstants.OpeningMode
     * @param om
     */
    set openingMode(om: SceneConstants.OpeningMode) {
        // Boolean to detect if a change is performed to update 3D model only if a change is performed
        let change = false;

        if (this.temporary === null) {
            if (this._openingMode !== om) {
                this._openingMode = om;
                change = true;
            }
        }
        else {
            if ((this.temporary as TemporaryJoinery).openingMode != om) {
                (this.temporary as TemporaryJoinery).openingMode = om;
                change = true;
            }
        }

        // Change 3D model only is a change is really performed
        if (change) {
            // Set default 3D model after this change
            this.setJoineryType();
        }
    }

    /**
     * Set the correct AM joinery 3D model depending on door parameters
     */
    setJoineryType() {
        // Get all JoineryType entity components
        let componentList = this.getComponents(ComponentConstants.ComponentType.JoineryType);

        // Is there any existing JoineryType component ?
        if (componentList.length > 0) {
            // Yes ! There is only one joinery type component for a joinery so it will be index 0 for sure

            // Normal (default) opening mode or sliding ?
            if (this.openingMode == SceneConstants.OpeningMode.mode_default) {
                // Single casement model (battant)
                if (this.nbCasement === 1) {
                    (componentList[0] as JoineryType).joineryTypeId = SavaneCookie.getCookie("Rhinov-Savane-DefaultJoineryModelWindow", SceneConstants.DefaultJoineryType.window);
                }
                else {
                    // Double casement model
                    (componentList[0] as JoineryType).joineryTypeId = SavaneCookie.getCookie("Rhinov-Savane-DefaultJoineryModelDoubleWindow", SceneConstants.DefaultJoineryType.doubleWindow);
                }
            }
            else {
                // Single casement model (battant)
                if (this.nbCasement === 1) {
                    (componentList[0] as JoineryType).joineryTypeId = SavaneCookie.getCookie("Rhinov-Savane-DefaultJoineryModelFixedWindow", SceneConstants.DefaultJoineryType.fixedWindow);
                }
                else {
                    (componentList[0] as JoineryType).joineryTypeId = SavaneCookie.getCookie("Rhinov-Savane-DefaultJoineryModelPictureWindow", SceneConstants.DefaultJoineryType.pictureWindow);
                }
            }
        }
        else {
            // No so we will create the component now

            // Normal (default) opening mode or sliding ?
            if (this.openingMode == SceneConstants.OpeningMode.mode_default) {
                // Single casement model
                if (this.nbCasement === 1) {
                    this.addComponent(new JoineryType(SavaneCookie.getCookie("Rhinov-Savane-DefaultJoineryModelWindow", SceneConstants.DefaultJoineryType.window), undefined, undefined));
                }
                else {
                    // Double casement model
                    this.addComponent(new JoineryType(SavaneCookie.getCookie("Rhinov-Savane-DefaultJoineryModelDoubleWindow", SceneConstants.DefaultJoineryType.doubleWindow), undefined, undefined));
                }
            }
            else {
                if (this.nbCasement === 1) {
                    this.addComponent(new JoineryType(SavaneCookie.getCookie("Rhinov-Savane-DefaultJoineryModelFixedWindow", SceneConstants.DefaultJoineryType.fixedWindow), undefined, undefined));
                }
                else {
                    // Sliding door model
                    this.addComponent(new JoineryType(SavaneCookie.getCookie("Rhinov-Savane-DefaultJoineryModelPictureWindow", SceneConstants.DefaultJoineryType.pictureWindow), undefined, undefined));
                }
            }
        }
    }

    // Getters and setters for isOpened
    get isOpened(): boolean {
        if (this.temporary === null) {
            return this._isOpened;
        }
        else {
            return (this.temporary as TemporaryJoinery).isOpened;
        }
    }

    set isOpened(o: boolean) {
        if (this.temporary === null) {
            this._isOpened = o;
        }
        else {
            (this.temporary as TemporaryJoinery).isOpened = o;
        }
    }

    get leftOpeningAngle(): number {
        if (this.temporary === null) {
            return this._leftOpeningAngle;
        }
        else {
            return (this.temporary as TemporaryJoinery).leftOpeningAngle;
        }
    }

    set leftOpeningAngle(a: number) {
        if (this.temporary === null) {
            this._leftOpeningAngle = a;
        }
        else {
            (this.temporary as TemporaryJoinery).leftOpeningAngle = a;
        }
    }

    get rightOpeningAngle(): number {
        if (this.temporary === null) {
            return this._rightOpeningAngle;
        }
        else {
            return (this.temporary as TemporaryJoinery).rightOpeningAngle;
        }
    }

    set rightOpeningAngle(a: number) {
        if (this.temporary === null) {
            this._rightOpeningAngle = a;
        }
        else {
            (this.temporary as TemporaryJoinery).rightOpeningAngle = a;
        }
    }

    // Getters and setters for casements (battants)
    get nbCasement(): number {
        if (this.temporary === null) {
            return this._nbCasement;
        }
        else {
            return (this.temporary as TemporaryJoinery).nbCasement;
        }
    }

    set nbCasement(c: number) {
        // Boolean to detect if a change is performed to update 3D model only if a change is performed
        let change = false;

        if (c > SceneConstants.MaxDoorJoineryDefault) {
            throw (new Error('Casement count can\'t be more than {SceneConstants.MaxDoorJoineryDefault} in that opening mode'));
        }

        if (this.temporary === null) {
            if (this._nbCasement !== c) {
                this._nbCasement = c;
                change = true;
            }
        }
        else {
            if ((this.temporary as TemporaryJoinery).nbCasement !== c) {
                (this.temporary as TemporaryJoinery).nbCasement = c;
                change = true;
            }
        }

        if (c > 1) {
            this.handleSide = SceneConstants.HandleSide.handle_center; //Handle side forced for 2 doors
        }
        else {
            if (this.handleSide === SceneConstants.HandleSide.handle_center) {
                this.handleSide = SceneConstants.HandleSide.handle_left; //Handle side forced for {SceneConstants.MaxDoorJoineryDefault} doors
            }
        }

        // Update 3D model only if a change is really performed
        if (change) {
            // Set default 3D model after this change
            this.setJoineryType();
        }
    }

    // Getters and setters for the opening side
    get handleSide(): SceneConstants.HandleSide {
        if (this.temporary === null) {
            return this._handleSide;
        }
        else {
            return ((this.temporary as TemporaryJoinery).handleSide);
        }
    }

    set handleSide(hs: SceneConstants.HandleSide) {
        if (this.openingMode !== SceneConstants.OpeningMode.mode_default) {
            return;
            // throw(new Error('HandleSide is not avaible for this opening mode'));
        }
        if (this.nbCasement === 1) {
            if (this.temporary === null) {
                this._handleSide = hs;
            }
            else {
                (this.temporary as TemporaryJoinery).handleSide = hs;
            }
        }
        else {
            if (hs !== SceneConstants.HandleSide.handle_center) {
                //More than two casement handle side can't be changed
                throw (new Error('HandleSide is forced to handle_center with more than {SceneConstants.MaxDoorJoineryDefault} casement on default opening mode'));
            }
            else {
                if (this.temporary === null) {
                    this._handleSide = hs;
                }
                else {
                    (this.temporary as TemporaryJoinery).handleSide = hs;
                }
            }
        }
    }

    // Called when a property is changed to verify max and min boundaries or to perform computations before final assignment
    addToCappedProperty(property: string, value: any) : number {
        let capped = 0; // 0 = no cap, 1 = minus cap, 2 max cap

        if (value === 0) {
            return (capped);
        }

        if (property === "nbCasement") {
            if (this[property] + value <= 1) {
                this[property] = 1;
                capped = 1;
            }
            if (this.openingMode === SceneConstants.OpeningMode.mode_default) {
                if (this[property] + value >= SceneConstants.MaxDoorJoineryDefault) {
                    this[property] = SceneConstants.MaxDoorJoineryDefault;
                    capped = 2;
                }
            }
            else {
                if (this[property] + value >= SceneConstants.MaxDoorJoinerySlide) {
                    this[property] = SceneConstants.MaxDoorJoinerySlide;
                    capped = 2;
                }
            }
            if (capped === 0) {
                this[property] += value;
            }
        }
        else {
            return super.addToCappedProperty(property, value);
        }
        return capped;
    }
}
