import { Joinery } from '../model/Joinery';
import { glMatrix, Ray } from "../../../math/Math";
import { SceneConstants } from '../../../scene/SceneConstants';
import { SlopeJoinery } from '../model/SlopeJoinery';
import { GroundJoinery } from '../model/GroundJoinery';
import { Photo2World } from '../Photo2World';

export class WallJoineriesFactory {

    private _pixels: Array<glMatrix.vec2> = new Array<glMatrix.vec2>();
    private _joineryDatas: Array<any> = new Array<any>();
    private _photo2world: Photo2World;

    constructor(photo2world: Photo2World) {
        this._photo2world = photo2world;
    }

    _handleWallJoinery(wall1, wall2, orientation, joineryIndex) {
        if (wall1.wall.Type !== 'WALL' || wall2.wall.Type !== 'WALL') {
            return;
        }

        wall1.intersection[2] = Math.max(0, wall1.intersection[2]); //cannot be under the ground
        wall2.intersection[2] = Math.min(wall2.wall.Height, wall2.intersection[2]); //cannot be over the ceiling

        let data = this._joineryDatas[joineryIndex];
        var A = glMatrix.vec3.clone(wall1.intersection);
        A[2] = 0;
        var B = glMatrix.vec3.clone(wall2.intersection);
        B[2] = 0;
        if (data.invert) {
            this._photo2world.Model.WallJoineries.push(new Joinery(B, A, Math.abs(wall2.intersection[2] - wall1.intersection[2]), wall1.intersection[2], wall1.wall, data, orientation));
        } else {
            this._photo2world.Model.WallJoineries.push(new Joinery(A, B, Math.abs(wall2.intersection[2] - wall1.intersection[2]), wall1.intersection[2], wall1.wall, data, orientation));
        }
    }

    _handleSlopeJoinery(wall1, wall2, orientation, joineryIndex) {
        if (wall1.wall.Type !== 'SLOPE' || wall2.wall.Type !== 'SLOPE') {
            return;
        }

        var slope = wall1.wall;
        var localSlopeA = slope.ToLocal(slope.A);
        var localA = slope.ToLocal(wall1.intersection);
        localA[2] = Math.max(localSlopeA[2], localA[2]); //cannot be under the slope

        var localSlopeD = slope.ToLocal(slope.D);
        var localD = slope.ToLocal(wall2.intersection);
        localD[2] = Math.min(localSlopeD[2], localD[2]); //cannot be over the slope
        var height = localD[2] - localA[2];

        var localC = glMatrix.vec3.clone(localA);
        localC[2] += height;
        var localB = glMatrix.vec3.clone(localD);
        localB[2] -= height;

        let data = this._joineryDatas[joineryIndex];
        this._photo2world.Model.WallJoineries.push(new SlopeJoinery(
            slope.ToGlobal(localA), slope.ToGlobal(localB), slope.ToGlobal(localC), slope.ToGlobal(localD), slope, data, orientation));
    }

    _handleCeilingJoinery(p1, p2, joineryIndex) {
        var iP1 = this._photo2world.ProjectJoineryOnCeiling(p1);
        var iP2 = this._photo2world.ProjectJoineryOnCeiling(p2);

        if (!iP1 || !iP2) {
            return;
        }

        let data = this._joineryDatas[joineryIndex];
        this._photo2world.Model.WallJoineries.push(new GroundJoinery(iP1, iP2, data));
    }

    _handleGroundJoinery(p1, p2, joineryIndex) {
        var iP1 = this._photo2world.ProjectJoineryOnGround(p1);
        var iP2 = this._photo2world.ProjectJoineryOnGround(p2);

        if (!iP1 || !iP2) {
            return;
        }

        let data = this._joineryDatas[joineryIndex];
        this._photo2world.Model.WallJoineries.push(new GroundJoinery(iP1, iP2, data));
    }

    set Pixels(value: Array<glMatrix.vec2>) {
        this._pixels = value;
    }

    set JoineryDatas(value: Array<any>) {
        this._joineryDatas = value;
    }

    Build() : void {
        this._photo2world.Model.WallJoineries = [];

        if (this._joineryDatas.length == 0) {
            return;
        }

        if (this._pixels.length < 2) {
            return;
        }

        var joineryIndex = 0;
        //find the closest wall
        for (var i = 0; i < this._pixels.length; i += 2) {
            let p1 = this._pixels[i];
            let p2 = this._pixels[i + 1];

            var orientation =  SceneConstants.HandleSide.handle_left;
            if (p2[0] > p1[0]) {
                orientation = SceneConstants.HandleSide.handle_right;
            }

            let data = this._joineryDatas[joineryIndex];
            var useWall1 = data.isPinnedA;
            var useWall2 = data.isPinnedB;
            if (p1[1] < p2[1]) { //p1 must always be the lowest point to simplify next computations
                let temp = p1;
                p1 = p2;
                p2 = temp;

                temp = useWall1;
                useWall1 = useWall2;
                useWall2 = temp;
            }

            let wall1 = this._photo2world.FindClosestWallOrSlope(p1);
            let wall2 = this._photo2world.FindClosestWallOrSlope(p2);
            //the 2 pixels do not belong to the image - intersect with infinite walls or slope
            if (this._photo2world.OutsideNDC(p1) && this._photo2world.OutsideNDC(p2)) {
                wall1 = this._photo2world.FindClosestWallOrSlope(p1, false, true);
                wall2 = this._photo2world.FindClosestWallOrSlope(p2, false, true);
            }

            if (!wall1 && !wall2) {
                this._handleCeilingJoinery(p1, p2, joineryIndex);
                this._handleGroundJoinery(p1, p2, joineryIndex);
                joineryIndex++;
                continue;
            }

            if ((this._photo2world.OutsideNDC(p1) || useWall2 || !wall1) && wall2) { //p1 is outside the image - use the p2 wall and compute intersection with plane directly
                wall1 = {
                    wall: wall2.wall,
                    intersection: useWall2 ? wall2.wall.Intersect(new Ray(this._photo2world.CameraPosition, this._photo2world.Unproject(p1)), false, false, true) :
                                             wall2.wall.Plane.Intersect(new Ray(this._photo2world.CameraPosition, this._photo2world.Unproject(p1)))
                }
                wall1.intersection = wall1.wall.ClampToBorder(wall1.intersection);
            }

            if ((this._photo2world.OutsideNDC(p2) || useWall1 || !wall2) && wall1) { //p2 is outside the image - use the p1 wall and compute intersection with plane directly
                wall2 = {
                    wall: wall1.wall,
                    intersection: useWall1 ? wall1.wall.Intersect(new Ray(this._photo2world.CameraPosition, this._photo2world.Unproject(p2)), false, false, true) :
                                             wall1.wall.Plane.Intersect(new Ray(this._photo2world.CameraPosition, this._photo2world.Unproject(p2)))
                }
                wall2.intersection = wall2.wall.ClampToBorder(wall2.intersection);
            }

            if (!wall1 || !wall2 || wall1.wall !== wall2.wall || !wall1.intersection || !wall2.intersection) { //invalid joinery - share 2 walls
                joineryIndex++;
                continue;
            }

            if (data.invert) {
                if (orientation === SceneConstants.HandleSide.handle_left) {
                    orientation = SceneConstants.HandleSide.handle_right;
                } else {
                    orientation = SceneConstants.HandleSide.handle_left;
                }
            }

            this._handleWallJoinery(wall1, wall2, orientation, joineryIndex);
            this._handleSlopeJoinery(wall1, wall2, orientation, joineryIndex);

            joineryIndex++;
        }
    }

}