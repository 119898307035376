import { Command, CommandEnum } from "./CommandModule";
import { Events } from "../events";
import { eventsManager } from "../managers/EventsManager";
import { CeilingBox, EntityFactory, Joinery, SceneConstants, TechnicalElement, math } from "../SavaneJS";

export class EditJoineryCommand extends Command {
    private _modifiedJoinery: Joinery;
    private _currentJoinery: Joinery;
    
    constructor(currentJoinery: Joinery) {
        super();
        // Clone current joinery so it copies the temporary fields into the cloned joinery entity
        this._modifiedJoinery = EntityFactory.cloneEntity(currentJoinery, false) as Joinery;
        //var position = this._modifiedJoinery.position;
        this._modifiedJoinery.wall = currentJoinery.wall;
        //this._modifiedJoinery.position = position;
        // End the joinery temporary so it drops the temporary data
        currentJoinery.endTemporary();
        // Save the current joinery for future data exchange
        this._currentJoinery = currentJoinery;
    }

    // Command name returned from a global enum
    name(): string {
        return CommandEnum.EditJoineryCommand;
    }

    execDatas(): any{
        return {
            id: this._currentJoinery.id,
            type: this._currentJoinery.entityType,
            entity: this._currentJoinery,
        };
    }

    // Undo the current command
    undo() {
        // Execute the current command, the savedJoinery structure keeps the changes back and forth
        this.execute();
    }

    // Execute current command (redo)
    execute() {
        // If the joinery is on a wall remove it from the wall
        this._currentJoinery.wall.deleteJoinery(this._currentJoinery.id);
        // Get its position
        let tmpPosition = math.vec3.create();
        math.vec3.copy(tmpPosition, this._currentJoinery.position);
        // Get its orientation
        let tmpOrientation = this._currentJoinery.orientation;
        // Get its attached wall
        let tmpWall = this._currentJoinery.wall;
        // Get its length
        let tmpLength = this._currentJoinery.length;
        // Get its height
        let tmpHeight = this._currentJoinery.height;
        // Get its floorHeight
        let tmpFloorHeight = this._currentJoinery.floorHeight;
        // Get its material type
        let tmpMaterialType = this._currentJoinery.materialType;
        // Get its transom (imposte)
        let tmpTransom = this._currentJoinery.transom;
        let tmpTransomHeight = this._currentJoinery.transomHeight;
        let tmpBottomTransom = this._currentJoinery.bottomTransom;
        let tmpBottomTransomHeight = this._currentJoinery.bottomTransomHeight;
        // Optional parameters to save
        let tmpNbCasement;
        let tmpNbDoors;
        let tmpOpeningMode;
        let tmpHandleSide;
        let tmpOpeningSymetry;
        let tmpSlideDirection;
        let tmpIsOpened;
        let tmpLeftOpeningAngle;
        let tmpRightOpeningAngle;
        let tmpThickness;
        let tmpWallInstallType;
        let tmpFrosted;
        let tmpCutSlope;

        // If the joinery has nbCasement then save it
        if ((this._currentJoinery as any).nbCasement !== undefined) {
            tmpNbCasement = (this._currentJoinery as any).nbCasement;
        }
        // If the joinery has nbDoors then save it
        if ((this._currentJoinery as any).nbDoors !== undefined) {
            tmpNbDoors = (this._currentJoinery as any).nbDoors;
        }
        // If the joinery has openingMode then save it
        if ((this._currentJoinery as any).openingMode !== undefined) {
            tmpOpeningMode = (this._currentJoinery as any).openingMode;
        }
        // If the joinery has handleSide then save it
        if ((this._currentJoinery as any).handleSide !== undefined) {
            tmpHandleSide = (this._currentJoinery as any).handleSide;
        }
        // If the joinery has openingSymetry then save it
        if ((this._currentJoinery as any).openingSymetry !== undefined) {
            tmpOpeningSymetry = (this._currentJoinery as any).openingSymetry;
        }
        // If the joinery has slideDirection then save it
        if ((this._currentJoinery as any).slideDirection !== undefined) {
            tmpSlideDirection = (this._currentJoinery as any).slideDirection;
        }
        // If the joinery has isOpened then save it
        if ((this._currentJoinery as any).isOpened !== undefined) {
            tmpIsOpened = (this._currentJoinery as any).isOpened;
        }
        if ((this._currentJoinery as any).leftOpeningAngle !== undefined) {
            tmpLeftOpeningAngle = (this._currentJoinery as any).leftOpeningAngle;
        }
        if ((this._currentJoinery as any).rightOpeningAngle !== undefined) {
            tmpRightOpeningAngle = (this._currentJoinery as any).rightOpeningAngle;
        }
        // If the joinery has thickness (niche)
        if ((this._currentJoinery as any).thickness !== undefined) {
            tmpThickness = (this._currentJoinery as any).thickness;
        }
        // If the joinery has an installType then save it
        if (this._currentJoinery.wallInstallType !== undefined) {
            tmpWallInstallType = this._currentJoinery.wallInstallType;
        }
        // If the joinery has an frosted then save it
        if (this._currentJoinery.frosted !== undefined) {
            tmpFrosted = this._currentJoinery.frosted;
        }

        if (this._currentJoinery.cutSlope !== undefined) {
            tmpCutSlope = this._currentJoinery.cutSlope;
        }

        // Exchange position with the _modifiedJoinery one
        this._currentJoinery.position = this._modifiedJoinery.position;
        this._modifiedJoinery.position = tmpPosition;
        //  Exchange orientation with the _modifiedJoinery one
        this._currentJoinery.orientation = this._modifiedJoinery.orientation;
        this._modifiedJoinery.orientation = tmpOrientation;
        // Exchange the wall with the _modifiedJoinery one
        this._currentJoinery.wall = this._modifiedJoinery.wall;
        this._modifiedJoinery.wall = tmpWall;
        // Exchange the length with the _modifiedJoinery one
        this._currentJoinery.length = this._modifiedJoinery.length;
        this._modifiedJoinery.length = tmpLength;
        // Exchange the height with the _modifiedJoinery one
        this._currentJoinery.height = this._modifiedJoinery.height;
        this._modifiedJoinery.height = tmpHeight;
        // Exchange the floorHeight with the _modifiedJoinery one
        this._currentJoinery.floorHeight = this._modifiedJoinery.floorHeight;
        this._modifiedJoinery.floorHeight = tmpFloorHeight;
        // Exchange the material types with the _modifiedJoinery ones
        this._currentJoinery.materialType = this._modifiedJoinery.materialType;
        this._modifiedJoinery.materialType = tmpMaterialType;
        // Exchange the transoms top and bottom (imposte) with the _modifiedJoinery one
        this._currentJoinery.transom = this._modifiedJoinery.transom;
        this._modifiedJoinery.transom = tmpTransom;
        this._currentJoinery.transomHeight = this._modifiedJoinery.transomHeight;
        this._modifiedJoinery.transomHeight = tmpTransomHeight;
        this._currentJoinery.bottomTransom = this._modifiedJoinery.bottomTransom;
        this._modifiedJoinery.bottomTransom = tmpBottomTransom;
        this._currentJoinery.bottomTransomHeight = this._modifiedJoinery.bottomTransomHeight;
        this._modifiedJoinery.bottomTransomHeight = tmpBottomTransomHeight;

        // If the joinery has nbCasement then exchange it with the _modifiedJoinery one
        if ((this._currentJoinery as any).nbCasement !== undefined) {
            (this._currentJoinery as any)._nbCasement = (this._modifiedJoinery as any).nbCasement;
            (this._modifiedJoinery as any)._nbCasement = tmpNbCasement;
        }
        // If the joinery has nbDoors then exchange it with the _modifiedJoinery one
        if ((this._currentJoinery as any).nbDoors !== undefined) {
            (this._currentJoinery as any)._nbDoors = (this._modifiedJoinery as any).nbDoors;
            (this._modifiedJoinery as any)._nbDoors = tmpNbDoors;
        }
        // If the joinery has openingMode then exchange it with the _modifiedJoinery one
        if ((this._currentJoinery as any).openingMode !== undefined) {
            (this._currentJoinery as any)._openingMode = (this._modifiedJoinery as any).openingMode;
            (this._modifiedJoinery as any)._openingMode = tmpOpeningMode;
        }
        // If the joinery has handleSide then exchange it with the _modifiedJoinery one
        if ((this._currentJoinery as any).handleSide !== undefined) {
            (this._currentJoinery as any).handleSide = (this._modifiedJoinery as any).handleSide;
            (this._modifiedJoinery as any)._handleSide = tmpHandleSide;
        }
        // If the joinery has openingSymetry then exchange it with the _modifiedJoinery one
        if ((this._currentJoinery as any).openingSymetry !== undefined) {
            (this._currentJoinery as any).openingSymetry = (this._modifiedJoinery as any).openingSymetry;
            (this._modifiedJoinery as any)._openingSymetry = tmpOpeningSymetry;
        }
        // If the joinery has slideDirection then exchange it with the _modifiedJoinery one
        if ((this._currentJoinery as any).slideDirection !== undefined) {
            (this._currentJoinery as any).slideDirection = (this._modifiedJoinery as any).slideDirection;
            (this._modifiedJoinery as any)._slideDirection = tmpSlideDirection;
        }
        // If the joinery can be opened, exchange the parameter with the _modifiedJoinery one
        if ((this._currentJoinery as any).isOpened !== undefined) {
            (this._currentJoinery as any).isOpened = (this._modifiedJoinery as any).isOpened;
            (this._modifiedJoinery as any)._isOpened = tmpIsOpened;
        }
        if ((this._currentJoinery as any).leftOpeningAngle !== undefined) {
            (this._currentJoinery as any).leftOpeningAngle = (this._modifiedJoinery as any).leftOpeningAngle;
            (this._modifiedJoinery as any)._leftOpeningAngle = tmpLeftOpeningAngle;
        }
        if ((this._currentJoinery as any).rightOpeningAngle !== undefined) {
            (this._currentJoinery as any).rightOpeningAngle = (this._modifiedJoinery as any).rightOpeningAngle;
            (this._modifiedJoinery as any)._rightOpeningAngle = tmpRightOpeningAngle;
        }
        // If the joinery has thickness (niche), exhange it with the _modifiedJoinery one
        if ((this._currentJoinery as any).thickness !== undefined) {
            (this._currentJoinery as any).thickness = (this._modifiedJoinery as any).thickness;
            (this._modifiedJoinery as any).thickness = tmpThickness;
        }
        // If the joinery has an install type, exchange it with the _modifiedJoinery one
        if (this._currentJoinery.wallInstallType !== undefined) {
            this._currentJoinery.wallInstallType = this._modifiedJoinery.wallInstallType;
            this._modifiedJoinery.wallInstallType = tmpWallInstallType;
        }
        // If the joinery has an install type, exchange it with the _modifiedJoinery one
        if (this._currentJoinery.frosted !== undefined) {
            this._currentJoinery.frosted = this._modifiedJoinery.frosted;
            this._modifiedJoinery.frosted = tmpFrosted;
        }
        // If the joinery cuts a slope, exchange it with the _modifiedJoinery one
        if (this._currentJoinery.cutSlope !== undefined) {
            this._currentJoinery.cutSlope = this._modifiedJoinery.cutSlope;
            this._modifiedJoinery.cutSlope = tmpCutSlope;
        }

        // Parse all children and reassign rolling shutters position to joinery orientation to the new joinery
        for (let j = 0; j < this._currentJoinery.children.length; j++) {
            let joinChild = this._currentJoinery.children[j];

            if (joinChild.isTechnicalElementEntity() && (joinChild as TechnicalElement).objectId === SceneConstants.TechnicalElementType.ceilingBox && (joinChild as CeilingBox).ceilingBoxType === SceneConstants.CeilingBoxType.rollingShutter) {
                (joinChild as CeilingBox).length = this._currentJoinery.length;
                let positionChild = math.vec3.create();

                if (this._currentJoinery.orientation) {
                    math.vec3.set(positionChild, 0, -this._currentJoinery.wall.thickness / 2, this._currentJoinery.height + this._currentJoinery.floorHeight);
                } else {
                    math.vec3.set(positionChild, 0, this._currentJoinery.wall.thickness / 2, this._currentJoinery.height + this._currentJoinery.floorHeight);
                }
                if (this._currentJoinery.transom) {
                    positionChild[2] = this._currentJoinery.transomHeight;
                }
                joinChild.transform.localPosition = positionChild;
            }
        }

        // Place the joinery back on the final wall
        this._currentJoinery.wall.addJoinery(this._currentJoinery);

        super.execute();

        // Select object at the end
        eventsManager.instance.dispatch(Events.CHANGE_EDITOR_SELECTION, {
            selection: [this._currentJoinery],
            keepSelected: false,
            showTulip: false,
        });
    }
}
