import { EntityFactory, Scene, Floor, Joinery, Velux, SceneConstants, SavaneConstants, math, SavaneMath } from "../SavaneJS";
/**
 * JoineryManager is a singleton managing all joineries of the current floor
 *
 * @constructor
 */
class JoineryManager {
    constructor() {
    }

    /**
     * Getter for the joinery of id
     *
     * @param {Number} joineryId
     */
    getJoinery(joineryId: number, scene: Scene) : Joinery | null {
        let floors = scene.floors;
        for (let i = 0; i < floors.length; ++i) {
            let floor = floors[i];
            let currentFloorJoineries = floor.joineries;

            for (let j = 0; j < currentFloorJoineries.length; j++) {
                if (currentFloorJoineries[j].id === joineryId) {
                    return currentFloorJoineries[j];
                }
            }
        }
        return null;
    }

    /**
     * Getter for the joinery at the position given in parameters
     *
     * @param {*} position
     * @param {Number} precision
     * @returns {*}
     */
    getJoineryAtPosition(position: math.vec3, precision: number, floor: Floor, ignoreId?: Array<number>) : Joinery | null {
        let currentFloorJoineries = floor.joineries;
        let tmpJoinery = null;
        for (let i = 0; i < currentFloorJoineries.length; i++) {
            if (currentFloorJoineries[i].wall !== null) {
                let begin = math.vec3.create();
                let end = math.vec3.create();
                SavaneMath.computeJoineryExtremities(currentFloorJoineries[i].wall, currentFloorJoineries[i], begin, end);

                let collision;

                if (currentFloorJoineries[i].joineryType === SceneConstants.JoineryType.velux) {
                    let horizontalHeight = (currentFloorJoineries[i] as Velux).getHorizontaleHeight();
                    let angle1 = SavaneMath.anglePoints(begin, position, end);
                    let angle2 = SavaneMath.anglePoints(end, position, begin);

                    collision = (SavaneMath.distanceToLine(position, begin, end) < horizontalHeight / 2 && ((angle1 <= Math.PI / 2 && angle2 >= (3 * Math.PI) / 2) || (angle1 >= (3 * Math.PI) / 2 && angle2 <= Math.PI / 2))) || (angle1 === 0 && angle2 === 0);
                } else {
                    begin[2] = 0;
                    end[2] = 0;

                    if (SavaneMath.distanceToLine(position, begin, end) < precision) {
                        let pt = SavaneMath.getProjection(position, begin, end);

                        if (math.vec3.dist(pt, begin) < currentFloorJoineries[i].length && math.vec3.dist(pt, end) < currentFloorJoineries[i].length) {
                            collision = true;
                        }
                    }
                }

                if (collision) {
                    if (tmpJoinery === null) {
                        if (!(Array.isArray(ignoreId) && ignoreId.includes(currentFloorJoineries[i].id))) {
                            tmpJoinery = currentFloorJoineries[i];
                        }
                    } else {
                        if (tmpJoinery.floorHeight + tmpJoinery.height < currentFloorJoineries[i].floorHeight + currentFloorJoineries[i].height && !(Array.isArray(ignoreId) && ignoreId.includes(currentFloorJoineries[i].id))) {
                            tmpJoinery = currentFloorJoineries[i];
                        }
                    }
                }
            }
        }
        return tmpJoinery;
    }

    /**
     * Getter for the joinery at the position given in parameters
     *
     * @param {*} position
     * @param {Number} precision
     * @returns {*}
     */
    getJoineryAtPositionWithHeight(position: math.vec3, height: number, precision: number, floor: Floor, ignoreId: number) : Joinery | null {
        let currentFloorJoineries = floor.joineries;
        let tmpJoinery = null;
        for (let i = 0; i < currentFloorJoineries.length; i++) {
            if (currentFloorJoineries[i].wall !== null) {
                let begin = math.vec3.create();
                let end = math.vec3.create();
                SavaneMath.computeJoineryExtremities(currentFloorJoineries[i].wall, currentFloorJoineries[i], begin, end);

                let collision;

                if (currentFloorJoineries[i].joineryType === SceneConstants.JoineryType.velux) {
                    let horizontalHeight = (currentFloorJoineries[i] as Velux).getHorizontaleHeight();
                    let angle1 = SavaneMath.anglePoints(begin, position, end);
                    let angle2 = SavaneMath.anglePoints(end, position, begin);

                    collision = (SavaneMath.distanceToLine(position, begin, end) < horizontalHeight / 2 && ((angle1 <= Math.PI / 2 && angle2 >= (3 * Math.PI) / 2) || (angle1 >= (3 * Math.PI) / 2 && angle2 <= Math.PI / 2))) || (angle1 === 0 && angle2 === 0);
                } else {
                    collision = SavaneMath.distanceToLine(position, begin, end) < precision && position[0] < Math.max(begin[0], end[0]) + precision && position[0] > Math.min(begin[0], end[0]) - precision && position[1] < Math.max(begin[1], end[1]) + precision && position[1] > Math.min(begin[1], end[1]) - precision;
                }

                if (collision) {
                    if (position[2] + height / 2 < currentFloorJoineries[i].floorHeight || position[2] - height / 2 > currentFloorJoineries[i].floorHeight + currentFloorJoineries[i].height) {
                        collision = false;
                    }
                }

                if (collision) {
                    if (tmpJoinery === null) {
                        if (!(Array.isArray(ignoreId) && ignoreId.includes(currentFloorJoineries[i].id))) {
                            tmpJoinery = currentFloorJoineries[i];
                        }
                    } else {
                        if (tmpJoinery.floorHeight + tmpJoinery.height < currentFloorJoineries[i].floorHeight + currentFloorJoineries[i].height && !(Array.isArray(ignoreId) && ignoreId.includes(currentFloorJoineries[i].id))) {
                            tmpJoinery = currentFloorJoineries[i];
                        }
                    }
                }
            }
        }
        return tmpJoinery;
    }

    /**
     * Getter for the joinery at the position given in parameters
     *
     * @param {*} position
     * @param {Number} precision
     * @returns {*}
     */
    getJoineryAtPositionWithType(position: math.vec3, precision: number, floor: Floor, ignoreId: number, searchedType: SceneConstants.JoineryType) : Joinery | null {
        let currentFloorJoineries = floor.joineries;
        for (let i = 0; i < currentFloorJoineries.length; i++) {
            if (currentFloorJoineries[i].wall !== null && currentFloorJoineries[i].joineryType === searchedType) {
                let begin = math.vec3.create();
                let end = math.vec3.create();
                SavaneMath.computeJoineryExtremities(currentFloorJoineries[i].wall, currentFloorJoineries[i], begin, end);

                let collision;

                if (currentFloorJoineries[i].joineryType === SceneConstants.JoineryType.velux) {
                    let horizontalHeight = (currentFloorJoineries[i] as Velux).getHorizontaleHeight();
                    let angle1 = SavaneMath.anglePoints(begin, position, end);
                    let angle2 = SavaneMath.anglePoints(end, position, begin);

                    collision = (SavaneMath.distanceToLine(position, begin, end) < horizontalHeight / 2 && ((angle1 <= Math.PI / 2 && angle2 >= (3 * Math.PI) / 2) || (angle1 >= (3 * Math.PI) / 2 && angle2 <= Math.PI / 2))) || (angle1 === 0 && angle2 === 0);
                } else {
                    collision = SavaneMath.distanceToLine(position, begin, end) < precision && position[0] < Math.max(begin[0], end[0]) + precision && position[0] > Math.min(begin[0], end[0]) - precision && position[1] < Math.max(begin[1], end[1]) + precision && position[1] > Math.min(begin[1], end[1]) - precision;
                }

                if (collision) {
                    if (position[2] < currentFloorJoineries[i].floorHeight || position[2] > currentFloorJoineries[i].floorHeight + currentFloorJoineries[i].height) {
                        collision = false;
                    }
                }

                if (collision) {
                    if (!(Array.isArray(ignoreId) && ignoreId.includes(currentFloorJoineries[i].id))) {
                        return currentFloorJoineries[i];
                    }
                }
            }
        }
        return null;
    }

    /**
     * Test whether the joinery is valid
     *
     * @param {Joinery} testedJoinery
     * @returns {*}
     */
    isJoineryValid(testedJoinery: Joinery) : boolean {
        if (testedJoinery.wall === null) {
            return false;
        }

        if (testedJoinery.joineryType === SceneConstants.JoineryType.velux) {
            let slope = testedJoinery.wall.getSlope(testedJoinery);
            let slopeLength;

            if (slope === 0) {
                return false;
            }

            if (slope === 1) {
                slopeLength = testedJoinery.wall.slopeLength1;
            }

            if (slope === 2) {
                slopeLength = testedJoinery.wall.slopeLength2;
            }

            let distToWall = SavaneMath.distanceToLine(testedJoinery.position, testedJoinery.wall.begin, testedJoinery.wall.end);
            distToWall -= testedJoinery.wall.thickness / 2;

            let horizontalHeight = (testedJoinery as Velux).getHorizontaleHeight();

            if (distToWall - horizontalHeight / 2 < 0) {
                return false;
            }

            if (distToWall + horizontalHeight / 2 > slopeLength) {
                return false;
            }

            if (testedJoinery.length < SceneConstants.MinimalJoineryLength) {
                return false;
            }

            return true;
        } else {
            //Find the limit using the smallest part of wall's bounding box
            let bbox = testedJoinery.wall.boundingBoxCut;
            let invertMat = math.mat4.create();
            let wallMatrix = testedJoinery.wall.globalMatrix;
            math.mat4.invert(invertMat, wallMatrix);
            for (let i = 0; i < bbox.length; i++) {
                math.vec3.transformMat4(bbox[i], bbox[i], invertMat);
            }
            let begPoint = null;
            if (bbox[0][0] > bbox[1][0]) {
                begPoint = bbox[0];
            } else {
                begPoint = bbox[1];
            }
            begPoint[1] = 0;

            let endPoint = null;
            if (bbox[2][0] < bbox[3][0]) {
                endPoint = bbox[2];
            } else {
                endPoint = bbox[3];
            }
            endPoint[1] = 0;

            let smallestDistance = Math.abs(endPoint[0] - begPoint[0]);
            math.vec3.transformMat4(begPoint, begPoint, wallMatrix);
            math.vec3.transformMat4(endPoint, endPoint, wallMatrix);
            let pos = testedJoinery.position;
            pos[2] = 0;
            let beginPointDistance = math.vec3.dist(pos, begPoint) + testedJoinery.length / 2;
            let endPointDistance = math.vec3.dist(pos, endPoint) + testedJoinery.length / 2;

            //joinery is not entierly on the wall
            if (beginPointDistance > smallestDistance + SavaneConstants.PositionTolerance || endPointDistance > smallestDistance + SavaneConstants.PositionTolerance) {
                return false;
            }
            if (testedJoinery.floorHeight + testedJoinery.height > testedJoinery.wall.height) {
                return false;
            }
            if (testedJoinery.length < SceneConstants.MinimalJoineryLength) {
                return false;
            }
            if (testedJoinery.height < SceneConstants.MinimalJoineryHeight) {
                return false;
            }

            if (testedJoinery.wall.isSpecialWall) {
                return false;
            }
            return true;
        }
    }

    /**
     * Test whether all joineries are valid
     *
     * @returns {*}
     */
    areJoineriesValid(floor: Floor) : boolean {
        let currentFloorJoineries = floor.joineries;
        for (let i = 0; i < currentFloorJoineries.length; i++) {
            if (!this.isJoineryValid(currentFloorJoineries[i])) {
                return false;
            }
        }
        return true;
    }

    /**
     * return the joinery maximal Length
     */
    findMaxJoineryLength(testedJoinery: Joinery) : number {
        if (testedJoinery.wall === null) {
            return 0;
        }
        //Find the limit using the smallest part of wall's bounding box
        let bbox = testedJoinery.wall.boundingBoxCut;
        let invertMat = math.mat4.create();
        let wallMatrix = testedJoinery.wall.globalMatrix;
        math.mat4.invert(invertMat, wallMatrix);
        for (let i = 0; i < bbox.length; i++) {
            math.vec3.transformMat4(bbox[i], bbox[i], invertMat);
        }
        let begPoint = null;
        if (bbox[0][0] > bbox[1][0]) {
            begPoint = bbox[0];
        } else {
            begPoint = bbox[1];
        }
        begPoint[1] = 0;

        let endPoint = null;
        if (bbox[2][0] < bbox[3][0]) {
            endPoint = bbox[2];
        } else {
            endPoint = bbox[3];
        }
        endPoint[1] = 0;

        let jPos = math.vec3.create();
        math.vec3.transformMat4(jPos, testedJoinery.transform.globalPosition, invertMat);
        let points = [];

        points.push(endPoint);
        points.push(begPoint);

        for (let i = 0; i < testedJoinery.wall.joineries.length; ++i) {
            if (testedJoinery.id === testedJoinery.wall.joineries[i].id) {
                continue;
            }
            if (testedJoinery.wall.joineries[i].joineryType === SceneConstants.JoineryType.velux) {
                continue;
            }
            let jleft = math.vec3.create();
            math.vec3.copy(jleft, testedJoinery.wall.joineries[i].transform.localPosition);
            jleft[0] -= testedJoinery.wall.joineries[i].length / 2;
            let jright = math.vec3.create();
            math.vec3.copy(jright, testedJoinery.wall.joineries[i].transform.localPosition);

            jright[0] += testedJoinery.wall.joineries[i].length / 2;

            points.push(jleft);

            points.push(jright);
        }

        let p1 = null;
        let p2 = null;

        let prevDist = null;

        for (let i = 0; i < points.length; ++i) {
            let newDist = Math.abs(jPos[0] - points[i][0]);
            if ((prevDist === null || newDist <= prevDist) && points[i][0] > jPos[0]) {
                p1 = points[i];
                prevDist = newDist;
            }
        }

        prevDist = null;

        for (let i = 0; i < points.length; ++i) {
            let newDist = Math.abs(jPos[0] - points[i][0]);
            if ((prevDist === null || newDist <= prevDist) && points[i][0] < jPos[0]) {
                p2 = points[i];
                prevDist = newDist;
            }
        }

        if (p1 === null || p2 === null) {
            return 0;
        }
        let smallestDistance = Math.abs(p1[0] - p2[0]);
        return smallestDistance;
    }

    getVeluxRect(joinery: Velux) : {top: number, bottom: number, left: number, right: number} {
        let veluxHeight = joinery.getHorizontaleHeight();

        let joineryBottom = joinery.transform.localMatrix[13] - veluxHeight / 2;
        let joineryTop = joinery.transform.localMatrix[13] + veluxHeight / 2;
        let joineryLeft = joinery.transform.localMatrix[12] - joinery.length / 2;
        let joineryRight = joinery.transform.localMatrix[12] + joinery.length / 2;

        return {
            top: joineryTop,
            bottom: joineryBottom,
            left: joineryLeft,
            right: joineryRight,
        };
    }

    getJoineryRect(joinery: Joinery) : {top: number, bottom: number, left: number, right: number} {
        let joineryBottom = joinery.floorHeight;
        let joineryTop = joinery.floorHeight + joinery.height;
        let joineryLeft = joinery.transform.localMatrix[12] - joinery.length / 2;
        let joineryRight = joinery.transform.localMatrix[12] + joinery.length / 2;

        return {
            top: joineryTop,
            bottom: joineryBottom,
            left: joineryLeft,
            right: joineryRight,
        };
    }

    snapVelux(veluxToSnap: Velux, otherJoineries: Array<Joinery>, snapPower: number) : void {
        // Keep only velux and same side of the wall than the velux to snap
        otherJoineries = otherJoineries.filter((joinery) => joinery.joineryType === SceneConstants.JoineryType.velux);

        // Clone joinery to attach the clone to the parent wall (the original joinery might not be attched to the wall in event of a joinery creation or move from one wall to another)
        // Keep its current global position to reinject it after parenting
        let joinery = EntityFactory.cloneEntity(veluxToSnap, false) as Velux;
        let position = math.vec3.clone(veluxToSnap.position);
        joinery.parent = veluxToSnap.wall;
        joinery.position = position;

        // Keep only veluxes on the same side of the wall
        if (joinery.transform.localMatrix[13] > 0) {
            otherJoineries = otherJoineries.filter((joinery) => joinery.transform.localMatrix[13] > 0);
        } else {
            otherJoineries = otherJoineries.filter((joinery) => joinery.transform.localMatrix[13] < 0);
        }

        let veluxToSnapRect = this.getVeluxRect(joinery);

        // Test all velux along the same side of wall
        for (let i = 0; i < otherJoineries.length; i++) {
            let otherJoineryRect = this.getVeluxRect(otherJoineries[i] as Velux);

            if (Math.abs(veluxToSnapRect.left - otherJoineryRect.right) < snapPower) {
                joinery.transform.localMatrix[12] = otherJoineries[i].transform.localMatrix[12] + (joinery.length + otherJoineries[i].length) / 2;
            }

            if (Math.abs(veluxToSnapRect.right - otherJoineryRect.left) < snapPower) {
                joinery.transform.localMatrix[12] = otherJoineries[i].transform.localMatrix[12] - (joinery.length + otherJoineries[i].length) / 2;
            }

            if (Math.abs(veluxToSnapRect.top - otherJoineryRect.bottom) < snapPower) {
                joinery.transform.localMatrix[13] = otherJoineries[i].transform.localMatrix[13] - (joinery.getHorizontaleHeight() + (otherJoineries[i] as Velux).getHorizontaleHeight()) / 2;
            }

            if (Math.abs(veluxToSnapRect.bottom - otherJoineryRect.top) < snapPower) {
                joinery.transform.localMatrix[13] = otherJoineries[i].transform.localMatrix[13] + (joinery.getHorizontaleHeight() + (otherJoineries[i] as Velux).getHorizontaleHeight()) / 2;
            }

            if (Math.abs(veluxToSnapRect.top - otherJoineryRect.top) < snapPower) {
                joinery.transform.localMatrix[13] = otherJoineries[i].transform.localMatrix[13] + (otherJoineries[i] as Velux).getHorizontaleHeight() / 2 - joinery.getHorizontaleHeight() / 2;
            }

            if (Math.abs(veluxToSnapRect.bottom - otherJoineryRect.bottom) < snapPower) {
                joinery.transform.localMatrix[13] = otherJoineries[i].transform.localMatrix[13] - (otherJoineries[i] as Velux).getHorizontaleHeight() / 2 + joinery.getHorizontaleHeight() / 2;
            }

            if (Math.abs(veluxToSnapRect.left - otherJoineryRect.left) < snapPower) {
                joinery.transform.localMatrix[12] = otherJoineries[i].transform.localMatrix[12] - otherJoineries[i].length / 2 + joinery.length / 2;
            }

            if (Math.abs(veluxToSnapRect.right - otherJoineryRect.right) < snapPower) {
                joinery.transform.localMatrix[12] = otherJoineries[i].transform.localMatrix[12] + otherJoineries[i].length / 2 - joinery.length / 2;
            }
        }

        // Copie final position and update floor height once all snap is done
        veluxToSnap.position = joinery.position;
        veluxToSnap.updateFloorHeight();
    }

    snap(joineryToSnap: Joinery) : void {
        let snapPower = 50;

        // No wall attached to the current joinery, leave
        if (joineryToSnap.wall === null) {
            return;
        }

        // Get other joinries and filter the one we want to snap with others
        let otherJoineries = joineryToSnap.wall.joineries;
        otherJoineries = otherJoineries.filter((joinery) => joinery.id !== joineryToSnap.id);

        if (joineryToSnap.joineryType === SceneConstants.JoineryType.velux) {
            this.snapVelux((joineryToSnap as Velux), otherJoineries, snapPower);
            return;
        }

        // Clone joinery to attach the clone to the parent wall (the original joinery might not be attched to the wall in event of a joinery creation or move from one wall to another)
        // Keep its current global position to reinject it after parenting
        let joinery = EntityFactory.cloneEntity(joineryToSnap, false) as Velux;
        let position = math.vec3.clone(joineryToSnap.position);
        joinery.parent = joineryToSnap.wall;
        joinery.position = position;

        // Basic snap against wall
        let minFloorHeight = 0;
        if (joinery.wall.rooms && joinery.wall.rooms.length > 0) {
            if (joinery.wall.rooms[0].floorHeight < minFloorHeight) {
                minFloorHeight = joinery.wall.rooms[0].floorHeight;
            }
            if (joinery.wall.rooms.length > 1) {
                if (joinery.wall.rooms[1].floorHeight < minFloorHeight) {
                    minFloorHeight = joinery.wall.rooms[1].floorHeight;
                }
            }
        }
        if (joinery.floorHeight < minFloorHeight + snapPower) {
            joinery.floorHeight = minFloorHeight;
        }
        if (joinery.floorHeight + joinery.height > joinery.wall.height - snapPower) {
            joinery.floorHeight = joinery.wall.height - joinery.height;
        }

        let joineryToSnapRect = this.getJoineryRect(joinery);

        // Test all joineries along the same wall
        for (let i = 0; i < otherJoineries.length; i++) {
            let otherJoineryRect = this.getJoineryRect(otherJoineries[i]);

            if (Math.abs(joineryToSnapRect.left - otherJoineryRect.right) < snapPower) {
                joinery.transform.localMatrix[12] = otherJoineries[i].transform.localMatrix[12] + (joinery.length + otherJoineries[i].length) / 2;
            }

            if (Math.abs(joineryToSnapRect.right - otherJoineryRect.left) < snapPower) {
                joinery.transform.localMatrix[12] = otherJoineries[i].transform.localMatrix[12] - (joinery.length + otherJoineries[i].length) / 2;
            }

            if (Math.abs(joineryToSnapRect.top - otherJoineryRect.bottom) < snapPower) {
                joinery.floorHeight = otherJoineries[i].floorHeight - joinery.height;
            }

            if (Math.abs(joineryToSnapRect.bottom - otherJoineryRect.top) < snapPower) {
                joinery.floorHeight = otherJoineries[i].floorHeight + otherJoineries[i].height;
            }

            if (Math.abs(joineryToSnapRect.top - otherJoineryRect.top) < snapPower) {
                joinery.floorHeight = otherJoineries[i].floorHeight + otherJoineries[i].height - joinery.height;
            }

            if (Math.abs(joineryToSnapRect.bottom - otherJoineryRect.bottom) < snapPower) {
                joinery.floorHeight = otherJoineries[i].floorHeight;
            }

            if (Math.abs(joineryToSnapRect.left - otherJoineryRect.left) < snapPower) {
                joinery.transform.localMatrix[12] = otherJoineries[i].transform.localMatrix[12] - otherJoineries[i].length / 2 + joinery.length / 2;
            }

            if (Math.abs(joineryToSnapRect.right - otherJoineryRect.right) < snapPower) {
                joinery.transform.localMatrix[12] = otherJoineries[i].transform.localMatrix[12] + otherJoineries[i].length / 2 - joinery.length / 2;
            }
        }

        // Copie final position and floorHeight once all snap is done
        joineryToSnap.floorHeight = joinery.floorHeight;
        joineryToSnap.position = joinery.position;
    }

    snapResize(joineryToSnap: Joinery, direction: string, elevation: number = 0) : void {
        let snapPower = 50;

        // No wall attached to the current joinery, leave
        if (joineryToSnap.wall === null) {
            return;
        }

        // Get other joinries and filter the one we want to snap with others
        let otherJoineries = joineryToSnap.wall.joineries;
        otherJoineries = otherJoineries.filter((joinery) => joinery.id !== joineryToSnap.id);

        if (joineryToSnap.joineryType === SceneConstants.JoineryType.velux) {
            return;
        }

        let joineryToSnapRect = this.getJoineryRect(joineryToSnap);
        let otherJoineryRect;

        if (direction === "height") {
            // Basic snap against wall
            if (joineryToSnap.floorHeight < snapPower + elevation) {
                joineryToSnap.height += joineryToSnap.floorHeight - elevation;
                joineryToSnap.floorHeight = elevation;
            }
            if (joineryToSnap.floorHeight + joineryToSnap.height > joineryToSnap.wall.height - snapPower) {
                joineryToSnap.height += joineryToSnap.wall.height - (joineryToSnap.floorHeight + joineryToSnap.height);
                joineryToSnap.floorHeight = joineryToSnap.wall.height - joineryToSnap.height;
            }

            // Test all joineries along the same wall
            for (let i = 0; i < otherJoineries.length; i++) {
                let delta: number;
                otherJoineryRect = this.getJoineryRect(otherJoineries[i]);

                /*if (joineryToSnapRect.top === otherJoineryRect.top && Math.abs(joineryToSnap.floorHeight - otherJoineries[i].floorHeight) < snapPower) {
                    joineryToSnap.height = otherJoineries[i].height;
                    joineryToSnap.floorHeight = otherJoineries[i].floorHeight;
                }

                if (joineryToSnapRect.bottom === otherJoineryRect.bottom && Math.abs(joineryToSnap.height - otherJoineries[i].height) < snapPower) {
                    joineryToSnap.height = otherJoineries[i].height;
                    joineryToSnap.floorHeight = otherJoineries[i].floorHeight;
                }*/

                if (Math.abs(joineryToSnap.floorHeight - otherJoineries[i].floorHeight) < snapPower) {
                    delta = joineryToSnap.floorHeight - otherJoineries[i].floorHeight;
                    joineryToSnap.floorHeight -= delta;
                    joineryToSnap.height += delta;
                }

                if (Math.abs(joineryToSnap.floorHeight - (otherJoineries[i].floorHeight + otherJoineries[i].height)) < snapPower) {
                    delta = joineryToSnap.floorHeight - (otherJoineries[i].floorHeight + otherJoineries[i].height);
                    joineryToSnap.floorHeight -= delta;
                    joineryToSnap.height += delta;
                }

                if (Math.abs((joineryToSnap.floorHeight + joineryToSnap.height) - (otherJoineries[i].floorHeight + otherJoineries[i].height)) < snapPower) {
                    delta = (joineryToSnap.floorHeight + joineryToSnap.height) - (otherJoineries[i].floorHeight + otherJoineries[i].height);
                    joineryToSnap.height -= delta;
                }

                if (Math.abs((joineryToSnap.floorHeight + joineryToSnap.height) - otherJoineries[i].floorHeight) < snapPower) {
                    delta = (joineryToSnap.floorHeight + joineryToSnap.height) - otherJoineries[i].floorHeight;
                    joineryToSnap.height -= delta;
                }
            }
        } else {
            // Test all joineries along the same wall
            for (let i = 0; i < otherJoineries.length; i++) {
                let delta: number;
                otherJoineryRect = this.getJoineryRect(otherJoineries[i]);

                if (Math.abs(joineryToSnapRect.left - otherJoineryRect.left) < snapPower) {
                    delta = joineryToSnapRect.left - otherJoineryRect.left;
                    joineryToSnap.length += delta;
                    joineryToSnap.transform.localMatrix[12] -= (delta / 2);
                }

                if (Math.abs(joineryToSnapRect.left - otherJoineryRect.right) < snapPower) {
                    delta = joineryToSnapRect.left - otherJoineryRect.right;
                    joineryToSnap.length += delta;
                    joineryToSnap.transform.localMatrix[12] -= (delta / 2);
                }

                if (Math.abs(joineryToSnapRect.right - otherJoineryRect.right) < snapPower) {
                    delta = joineryToSnapRect.right - otherJoineryRect.right;
                    joineryToSnap.length -= delta;
                    joineryToSnap.transform.localMatrix[12] -= (delta / 2);
                }

                if (Math.abs(joineryToSnapRect.right - otherJoineryRect.left) < snapPower) {
                    delta = joineryToSnapRect.right - otherJoineryRect.left;
                    joineryToSnap.length -= delta;
                    joineryToSnap.transform.localMatrix[12] -= (delta / 2);
                }
            }
        }
    }
}

export let joineryManager = new JoineryManager();
