import { ComponentConstants } from "../components/ComponentConstants";
import { Scene } from "./Scene";
import { Wall } from "./Wall";
import { Floor } from "./Floor";
import { SketchBlock } from "./SketchBlock";
import { WorkTop } from "./WorkTop";
import { ArrangementObject } from "./ArrangementObject";
import { ArrangementZone } from "./ArrangementZone";
import { TechnicalElement } from "./TechElements/TechnicalElement";
import { StraightStaircase } from "./Staircases/StraightStaircase";
import { CircularStaircase } from "./Staircases/CircularStaircase";
import { QuarterStaircase } from "./Staircases/QuarterStaircase";
import { DoubleQuarterStaircase } from "./Staircases/DoubleQuarterStaircase";
import { WaterSource } from "./TechElements/WaterSource";
import { CeilingBox } from "./TechElements/CeilingBox";
import { UserPicture } from "./UserPicture";
import { Sun } from "./Sun";

import { ArrangementGroup } from "./ArrangementGroup";
import { Door, FrenchDoor, Opening, PictureWindow, Window, GarageDoor, CupboardDoor, FixedWindow, Canopy, Niche, Velux, Joinery } from "./Joineries/JoineryModule";
import { Room } from "./Room";
import { JSONImporter } from "../utils/JSONImporter";
import { SceneConstants } from "./SceneConstants";
import { FunctionalityChip } from "./FunctionalityChip";
import { RenderCamera } from "./RenderCamera";
import { Comment } from "./Comment";
import { Box } from "./GeometryPrimitives/Box";
import { Cylinder } from "./GeometryPrimitives/Cylinder";
import { Triangle } from "./GeometryPrimitives/Triangle";
import { Oval } from "./GeometryPrimitives/Oval";
import { Sphere } from "./GeometryPrimitives/Sphere";
import { Hood } from "./GeometryPrimitives/Hood";
import { HalfArc } from "./GeometryPrimitives/HalfArc";
import { math } from "./Transform";
import { RendererTopView, Area, WallQuotation, Functionality, RoomQuotation, DomeLight, BoundingBox, DepthBoundingBox } from "../components/ComponentModule";
import { World } from "./World";
import { Entity } from "./Entity";
import { GeometryGroup, GeometryPrimitive, Staircase } from "./SceneModule";

/**
 * EntityFactory contains static function to create different sort of entity
 */
export class EntityFactory {
    private constructor() {}

    static lastEntityId = -1;

    /**
     * Return a entity clone
     *
     * @param {*} toClone
     */
    static cloneEntity(entity: Entity, renewId: boolean) : Entity {
        var clone = null;
        switch (entity.entityType) {
            case SceneConstants.EntityType.Wall:
                clone = EntityFactory.cloneWall(entity as Wall, renewId);
                break;
            case SceneConstants.EntityType.Room:
                clone = EntityFactory.cloneRoom(entity as Room, true, renewId);
                break;
            case SceneConstants.EntityType.Staircase:
                clone = EntityFactory.cloneStaircase(entity as Staircase, renewId);
                break;
            case SceneConstants.EntityType.TechnicalElement:
                clone = EntityFactory.cloneTechnicalElement(entity as TechnicalElement, renewId);
                break;
            case SceneConstants.EntityType.Joinery:
                clone = EntityFactory.cloneJoinery(entity as Joinery, renewId);
                break;
            case SceneConstants.EntityType.ArrangementObject:
                clone = EntityFactory.cloneArrangementObject(entity as ArrangementObject, renewId);
                break;
            case SceneConstants.EntityType.ArrangementGroup:
                clone = EntityFactory.cloneArrangementGroup(entity as ArrangementGroup, renewId);
                break;
            case SceneConstants.EntityType.RenderCamera:
                clone = EntityFactory.cloneRenderCamera(entity as RenderCamera, renewId);
                break;
            case SceneConstants.EntityType.Sun:
                clone = EntityFactory.cloneSun(entity as Sun, renewId);
                break;
            case SceneConstants.EntityType.ArrangementZone:
                //FIXME serialize / unserialize is not convienient to handle id consistancy
                clone = EntityFactory.cloneArrangementZone(entity as ArrangementZone, renewId);
                break;
            case SceneConstants.EntityType.SketchBlock:
                clone = EntityFactory.cloneSketchBlock(entity as SketchBlock, renewId);
                break;
            case SceneConstants.EntityType.UserPicture:
                clone = EntityFactory.cloneUserPicture(entity as UserPicture, renewId);
                break;
            case SceneConstants.EntityType.FunctionalityChip:
                clone = EntityFactory.cloneFunctionalityChip(entity as FunctionalityChip, renewId);
                break;
            case SceneConstants.EntityType.Comment:
                clone = EntityFactory.cloneComment(entity as Comment, renewId);
                break;
            case SceneConstants.EntityType.GeometryPrimitive:
                clone = EntityFactory.cloneGeometryPrimitive(entity as GeometryPrimitive, renewId);
                break;
            case SceneConstants.EntityType.GeometryGroup:
                clone = EntityFactory.cloneGeometryGroup(entity as GeometryGroup, renewId);
                break;
            case SceneConstants.EntityType.WorkTop:
                clone = EntityFactory.cloneWorkTop(entity as WorkTop, renewId);
                break;
            default:
                return null;
        }
        if (!renewId) {
            clone.id = entity.id;
        }
        clone.name = entity.name;
        clone.locked = entity.locked;
        clone.holdout = entity.holdout;
        clone.hideAtRendering = entity.hideAtRendering;

        return clone;
    }

    /**
     * Return an entity world
     *
     * @param {Number} id
     **/
    static createWorld(id: number = -1): World {
        let world = null;
        if (id === -1) {
            this.lastEntityId++;
            world = new World(this.lastEntityId);
        } else {
            world = new World(id);
            if (this.lastEntityId < id) {
                this.lastEntityId = id;
            }
        }

        if (world.children.length === 0) {
            world.addChild(this.createScene());
        }

        world.currentScene = world.children[0];

        return world;
    }

    /**
     * Return an entity scene
     *
     * @param {Number} id
     **/
    static createScene(id: number = -1): Scene {
        let scene = null;
        if (id === -1) {
            this.lastEntityId++;
            scene = new Scene(this.lastEntityId);
        } else {
            scene = new Scene(id);
            if (this.lastEntityId < id) {
                this.lastEntityId = id;
            }
        }
        //Add Default sun and dome

        let floor = EntityFactory.createFloor();
        floor.addComponent(new DepthBoundingBox());
        scene.addChild(floor);
        scene.currentFloor = floor;
        return scene;
    }

    /**
     * Return a entity wall
     *
     * @param {*} begin
     * @param {*} end
     * @param {*} id
     */
    static createWall(begin: math.vec3, end: math.vec3, id: number = -1): Wall {
        let wall = new Wall();

        if (id === -1) {
            this.lastEntityId++;
            wall.id = this.lastEntityId;
        } else {
            wall.id = id;
            if (this.lastEntityId < id) {
                this.lastEntityId = id;
            }
        }

        //wall.addComponent(new Mesh());
        wall.addComponent(new WallQuotation());
        //wall.addComponent(new BoundingBox());

        wall.begin = begin;
        wall.end = end;

        return wall;
    }

    /**
     * Return the clone of the wall (the parent and room are not cloned)
     *
     * @param {Wall} clonedWall
     */
    static cloneWall(clonedWall: Wall, renewId: boolean): Wall {
        let wall = new Wall();
        if (!renewId) {
            wall.id = clonedWall.id;
        } else {
            this.lastEntityId++;
            wall.id = this.lastEntityId;
        }

        wall.addComponent(new WallQuotation());

        wall.thickness = clonedWall.thickness;
        wall.height = clonedWall.height;

        wall.begin = clonedWall.begin;
        wall.end = clonedWall.end;

        wall.shiftOffset = clonedWall.shiftOffset;
        wall.shiftDirection = clonedWall.shiftDirection;

        wall.slope = clonedWall.slope;
        wall.slopeHeight = clonedWall.slopeHeight;
        wall.slopeLength1 = clonedWall.slopeLength1;
        wall.slopeLength2 = clonedWall.slopeLength2;

        wall.forceNoPlinth = clonedWall.forceNoPlinth;
        wall.forceNoCornice = clonedWall.forceNoCornice;

        var wallType = clonedWall.getComponents(ComponentConstants.ComponentType.WallType);
        if (wallType.length > 0) {
            wall.addComponent(wallType[0].clone());
        }

        for (let i = 0; i < clonedWall.joineries.length; i++) {
            var joinery = EntityFactory.cloneJoinery(clonedWall.joineries[i], renewId);
            joinery.wall = wall;

            math.mat4.copy(joinery.transform.localMatrix, clonedWall.joineries[i].transform.localMatrix);

            wall.addJoinery(joinery);
        }

        wall.parent = clonedWall.parent;
        return wall;
    }

    /**
     * Return a entity joinery
     *
     * @param {vec3} position
     * @param {Number} joineryType
     * @param {Number} length
     * @param {*} id
     */
    static createJoinery(position: math.vec3, joineryType: SceneConstants.JoineryType, length: number, id: number = -1) : Joinery {
        // New joinery to return
        let joinery;

        // Create the correct joinery depending of the desired type
        switch (joineryType) {
            case SceneConstants.JoineryType.door:
                joinery = new Door(joineryType, position);
                break;

            case SceneConstants.JoineryType.window:
                joinery = new Window(joineryType, position);
                break;

            case SceneConstants.JoineryType.frenchWindow:
                joinery = new FrenchDoor(joineryType, position);
                break;

            case SceneConstants.JoineryType.pictureWindow:
                joinery = new PictureWindow(joineryType, position);
                break;

            case SceneConstants.JoineryType.opening:
                joinery = new Opening(joineryType, position);
                break;

            case SceneConstants.JoineryType.fixedWindow:
                joinery = new FixedWindow(joineryType, position);
                break;

            case SceneConstants.JoineryType.canopy:
                joinery = new Canopy(joineryType, position);
                break;

            case SceneConstants.JoineryType.garageDoor:
                joinery = new GarageDoor(joineryType, position);
                break;

            case SceneConstants.JoineryType.cupboardDoor:
                joinery = new CupboardDoor(joineryType, position);
                break;

            case SceneConstants.JoineryType.frontDoor:
                joinery = new Door(joineryType, position);
                break;

            case SceneConstants.JoineryType.niche:
                joinery = new Niche(joineryType, position);
                break;

            case SceneConstants.JoineryType.velux:
                joinery = new Velux(joineryType, position);
                break;

            default:
                throw new Error("This joineryType is not defined type : " + joineryType);
        }

        // Create new Id or retrieve it from passed parameter
        if (id === -1) {
            this.lastEntityId++;
            joinery.id = this.lastEntityId;
        } else {
            joinery.id = id;
            if (this.lastEntityId < id) {
                this.lastEntityId = id;
            }
        }

        // Set joinery position and length
        joinery.position = position;
        joinery.length = length;

        // Reassign height and floorHeight from default parameters
        switch (joineryType) {
            case SceneConstants.JoineryType.window:
                joinery.height = SceneConstants.DefaultWindowHeight;
                joinery.floorHeight = SceneConstants.DefaultWindowFloorHeight;
                break;

            case SceneConstants.JoineryType.cupboardDoor:
                joinery.height = SceneConstants.DefaultCupboardDoorHeight;
                break;

            default:
                joinery.height = SceneConstants.DefaultJoineryHeight;
        }

        // Save joinery type
        joinery.joineryType = joineryType;

        // And return created object
        return joinery;
    }

    /**
     * Return the clone of the Joinery (the parent is not cloned)
     *
     * @param {Joinery} clonedJoinery
     */
    static cloneJoinery(clonedJoinery: Joinery, renewId: boolean) : Joinery {
        // Create a new joinery with destination position, joinery type and length
        let joinery = this.createJoinery(clonedJoinery.position, clonedJoinery.joineryType, clonedJoinery.length);

        // If we want a real copy of the joinery, copy its Id
        if (!renewId) {
            joinery.id = clonedJoinery.id;
        } else {
            this.lastEntityId++;
            joinery.id = this.lastEntityId;
        }

        // Copy joinery orientation
        joinery.orientation = clonedJoinery.orientation;
        // And other standard parameters
        joinery.length = clonedJoinery.length;
        joinery.height = clonedJoinery.height;
        joinery.floorHeight = clonedJoinery.floorHeight;
        joinery.materialType = clonedJoinery.materialType;
        joinery.transom = clonedJoinery.transom;
        joinery.transomHeight = clonedJoinery.transomHeight;
        joinery.bottomTransom = clonedJoinery.bottomTransom;
        joinery.bottomTransomHeight = clonedJoinery.bottomTransomHeight;

        // Here we copy all the specific parameters present in all types of joineries by testing if the field exist and if so, copy it
        if ((clonedJoinery as any).openingMode !== undefined) {
            (joinery as any).openingMode = (clonedJoinery as any).openingMode;
        }
        if ((clonedJoinery as any).nbCasement !== undefined) {
            (joinery as any).nbCasement = (clonedJoinery as any).nbCasement;
        }
        if ((clonedJoinery as any).nbDoors !== undefined) {
            (joinery as any).nbDoors = (clonedJoinery as any).nbDoors;
        }
        if ((clonedJoinery as any).handleSide !== undefined) {
            (joinery as any).handleSide = (clonedJoinery as any).handleSide;
        }
        if ((clonedJoinery as any).openingSymetry !== undefined) {
            (joinery as any).openingSymetry = (clonedJoinery as any).openingSymetry;
        }
        if ((clonedJoinery as any).slideDirection !== undefined) {
            (joinery as any).slideDirection = (clonedJoinery as any).slideDirection;
        }
        if ((clonedJoinery as any).isOpened !== undefined) {
            (joinery as any).isOpened = (clonedJoinery as any).isOpened;
        }
        if ((clonedJoinery as any).leftOpeningAngle !== undefined) {
            (joinery as any).leftOpeningAngle = (clonedJoinery as any).leftOpeningAngle;
        }
        if ((clonedJoinery as any).rightOpeningAngle !== undefined) {
            (joinery as any).rightOpeningAngle = (clonedJoinery as any).rightOpeningAngle;
        }
        if ((clonedJoinery as any).thickness !== undefined) {
            (joinery as any).thickness = (clonedJoinery as any).thickness;
        }

        if (clonedJoinery.wallInstallType !== undefined) {
            joinery.wallInstallType = clonedJoinery.wallInstallType;
        }
        if (clonedJoinery.frosted !== undefined) {
            joinery.frosted = clonedJoinery.frosted;
        }
        if (clonedJoinery.cutSlope !== undefined) {
            joinery.cutSlope = clonedJoinery.cutSlope;
        }

        //Clone components
        for (let i = 0; i < clonedJoinery.components.length; i++) {
            joinery.addComponent(clonedJoinery.components[i].clone());
        }

        //Clone children
        for (let i = 0; i < clonedJoinery.children.length; i++) {
            joinery.addChild(EntityFactory.cloneEntity(clonedJoinery.children[i], renewId));
            joinery.children[i].position = clonedJoinery.children[i].position;
        }

        return joinery;
    }

    /**
     * Return a entity geometryPrimitive
     *
     * @param {vec3} position
     * @param {Number} primitiveType
     * @param {Number} height
     * @param {*} id
     */
    static createGeometryPrimitive(primitiveType: SceneConstants.GeometryPrimitiveType, height: number, id: number = -1) : GeometryPrimitive {
        // New joinery to return
        let geometryPrimitive;

        // Create the correct joinery depending of the desired type
        switch (primitiveType) {
            case SceneConstants.GeometryPrimitiveType.box:
                geometryPrimitive = new Box(primitiveType, height);
                break;

            case SceneConstants.GeometryPrimitiveType.cylinder:
                geometryPrimitive = new Cylinder(primitiveType, height);
                break;

            case SceneConstants.GeometryPrimitiveType.triangle:
                geometryPrimitive = new Triangle(primitiveType, height);
                break;

            case SceneConstants.GeometryPrimitiveType.oval:
                geometryPrimitive = new Oval(primitiveType, height);
                break;

            case SceneConstants.GeometryPrimitiveType.sphere:
                geometryPrimitive = new Sphere(primitiveType, height);
                break;

            case SceneConstants.GeometryPrimitiveType.hood:
                geometryPrimitive = new Hood(primitiveType, height);
                break;

            case SceneConstants.GeometryPrimitiveType.halfarc:
                geometryPrimitive = new HalfArc(primitiveType, height);
                break;

            default:
                throw new Error("This primitiveType is not defined type : " + primitiveType);
        }

        // Create new Id or retrieve it from passed parameter
        if (!id || id === -1) {
            this.lastEntityId++;
            geometryPrimitive.id = this.lastEntityId;
        } else {
            geometryPrimitive.id = id;
            if (this.lastEntityId < id) {
                this.lastEntityId = id;
            }
        }

        geometryPrimitive.floorHeight = 0;

        // And return created object
        return geometryPrimitive;
    }

    static createGeometryGroup(id: number = -1) : GeometryGroup {
        let geometryGroup = new GeometryGroup(id);
        // Create new Id or retrieve it from passed parameter
        if (!id || id === -1) {
            this.lastEntityId++;
            geometryGroup.id = this.lastEntityId;
        } else {
            geometryGroup.id = id;
            if (this.lastEntityId < id) {
                this.lastEntityId = id;
            }
        }
        return geometryGroup;
    }

    /**
     * Return the clone of the GeometryPrimitive (the parent is not cloned)
     *
     * @param {GeometryPrimitive} clonedGeometryPrimitive
     */
    static cloneGeometryPrimitive(clonedGeometryPrimitive: GeometryPrimitive, renewId: boolean) : GeometryPrimitive {
        // Create a new geometry primitive with destination position, primitive type and height
        let geometryPrimitive = this.createGeometryPrimitive(clonedGeometryPrimitive.primitiveType, clonedGeometryPrimitive.height);

        // If we want a real copy of the geometry primitive, copy its Id
        if (!renewId) {
            geometryPrimitive.id = clonedGeometryPrimitive.id;
        } else {
            this.lastEntityId++;
            geometryPrimitive.id = this.lastEntityId;
        }

        math.mat4.copy(geometryPrimitive.transform.localMatrix, clonedGeometryPrimitive.transform.localMatrix);

        geometryPrimitive.hideInAxo = clonedGeometryPrimitive.hideInAxo;
        geometryPrimitive.cutByJoineries = clonedGeometryPrimitive.cutByJoineries;
        // Here we copy all the specific parameters present in all types of geometry primitives by testing if the field exist and if so, copy it
        if (clonedGeometryPrimitive.width !== undefined) {
            geometryPrimitive.width = clonedGeometryPrimitive.width;
        }
        if (clonedGeometryPrimitive.length !== undefined) {
            geometryPrimitive.length = clonedGeometryPrimitive.length;
        }

        if ((clonedGeometryPrimitive instanceof Sphere || clonedGeometryPrimitive instanceof Cylinder) &&
            (geometryPrimitive instanceof Sphere || geometryPrimitive instanceof Cylinder))
        if (clonedGeometryPrimitive.diameter !== undefined) {
            geometryPrimitive.diameter = clonedGeometryPrimitive.diameter;
        }

        if (clonedGeometryPrimitive instanceof Hood && geometryPrimitive instanceof Hood) {
            if (clonedGeometryPrimitive.topWidth !== undefined) {
                geometryPrimitive.topWidth = clonedGeometryPrimitive.topWidth;
            }
            if (clonedGeometryPrimitive.topLength !== undefined) {
                geometryPrimitive.topLength = clonedGeometryPrimitive.topLength;
            }
            if (clonedGeometryPrimitive.shapeType !== undefined) {
                geometryPrimitive.shapeType = clonedGeometryPrimitive.shapeType;
            }
        }

        //Clone components
        for (let i = 0; i < clonedGeometryPrimitive.components.length; i++) {
            geometryPrimitive.addComponent(clonedGeometryPrimitive.components[i].clone());
        }

        //Clone children
        for (let i = 0; i < clonedGeometryPrimitive.children.length; i++) {
            geometryPrimitive.addChild(EntityFactory.cloneEntity(clonedGeometryPrimitive.children[i], renewId));
            geometryPrimitive.children[i].position = clonedGeometryPrimitive.children[i].position;
        }

        geometryPrimitive.parent = clonedGeometryPrimitive.parent;
        return geometryPrimitive;
    }

    static cloneGeometryGroup(clonedGeometryGroup: GeometryGroup, renewId: boolean) : GeometryGroup {
        let geometryGroup = this.createGeometryGroup();

        // If we want a real copy of the geometry primitive, copy its Id
        if (!renewId) {
            geometryGroup.id = geometryGroup.id;
        } else {
            this.lastEntityId++;
            geometryGroup.id = this.lastEntityId;
        }

        math.mat4.copy(geometryGroup.transform.localMatrix, clonedGeometryGroup.transform.localMatrix);
        //Clone components
        for (let i = 0; i < clonedGeometryGroup.components.length; i++) {
            geometryGroup.addComponent(clonedGeometryGroup.components[i].clone());
        }

        //Clone children
        for (let i = 0; i < clonedGeometryGroup.children.length; i++) {
            geometryGroup.addChild(EntityFactory.cloneEntity(clonedGeometryGroup.children[i], renewId) as GeometryPrimitive | GeometryGroup);
            geometryGroup.children[i].position = clonedGeometryGroup.children[i].position;
        }

        geometryGroup.parent = clonedGeometryGroup.parent;
        return geometryGroup;
    } 

    /**
     * Return a entity arrangement
     *
     * @param {*} parsedJSON
     */
    static createArrangementObjectFromJSON(parsedJSON: any) : ArrangementObject {
        //this.lastEntityId++;
        if (parsedJSON.id > this.lastEntityId) {
            this.lastEntityId = parsedJSON.id;
        }

        let originalWidth = parsedJSON.width;
        let originalHeight = parsedJSON.height;
        let originalLength = parsedJSON.length;

        // If original dimensions are in the JSON, then consider them as dimensions we want to initialise the object with (so then end up going into originalWidth, Height and Length of the object)
        if (parsedJSON.originalWidth !== undefined) {
            originalWidth = parsedJSON.originalWidth;
        }
        if (parsedJSON.originalHeight !== undefined) {
            originalHeight = parsedJSON.originalHeight;
        }
        if (parsedJSON.originalLength !== undefined) {
            originalLength = parsedJSON.originalLength;
        }

        let arrangement = new ArrangementObject(parsedJSON.id, parsedJSON.objectId, parsedJSON.manufacturer, parsedJSON.retailer, parsedJSON.objectType, parsedJSON.objectStyles, originalLength, originalWidth, originalHeight, parsedJSON.colorId, parsedJSON.coatingId, parsedJSON.stretchability, parsedJSON.customization);

        if (parsedJSON.excludeFromShoppingList !== undefined) {
            arrangement.excludeFromShoppingList = parsedJSON.excludeFromShoppingList;
        }

        if (parsedJSON.handleSide !== undefined) {
            arrangement.handleSide = parsedJSON.handleSide;
        }
        if (parsedJSON.handleAsset !== undefined) {
            arrangement.handleAsset = parsedJSON.handleAsset;
        }
        // If the original dimensions are in the JSON, post update the object to reassign actual width, height and length
        if (parsedJSON.originalWidth !== undefined) {
            arrangement.width = parsedJSON.width;
        }
        if (parsedJSON.originalHeight !== undefined) {
            arrangement.height = parsedJSON.height;
        }
        if (parsedJSON.originalLength !== undefined) {
            arrangement.length = parsedJSON.length;
        }
        if (parsedJSON.lightOn !== undefined) {
            arrangement.lightOn = parsedJSON.lightOn;
        }
        if (parsedJSON.lightOff !== undefined) {
            arrangement.lightOff = parsedJSON.lightOff;
        }
        if (parsedJSON.temperature !== undefined) {
            arrangement.temperature = parsedJSON.temperature;
        }
        if (parsedJSON.lightColor !== undefined) {
            arrangement.lightColor = parsedJSON.lightColor;
        } else {
            arrangement.lightColor = "#ffffff";
        }
        if (parsedJSON.symmetry !== undefined) {
            arrangement.symmetry = parsedJSON.symmetry;
        }
        if (parsedJSON.coatingAllowed !== undefined) {
            arrangement.coatingAllowed = parsedJSON.coatingAllowed;
        }
        if (parsedJSON.hidden !== undefined) {
            arrangement.hidden = parsedJSON.hidden;
        }
        if (parsedJSON.smartDesignerParentId !== undefined) {
            arrangement.smartDesignerParentId = parsedJSON.smartDesignerParentId;
        }
        if (parsedJSON.initialObjectId !== undefined) {
            arrangement.initialObjectId = parsedJSON.initialObjectId;
        }
        if (parsedJSON.configurable !== undefined) {
            arrangement.configurable = parsedJSON.configurable;
        }

        arrangement.name = parsedJSON.name;
        arrangement.objectTypeConfig = parsedJSON.objectTypeConfig;
        if (parsedJSON.anchor !== undefined) {
            arrangement.anchor = parsedJSON.anchor;
        }
        arrangement.isAnchorActive = parsedJSON.anchorActive !== undefined ? parsedJSON.anchorActive : true;
        arrangement.stackable = parsedJSON.stackable;

        return arrangement;
    }

    /**
     * Return a entity arrangement
     *
     * @param {Number} objectId
     * @param {Number} objectType
     * @param {Number} length
     * @param {Number} width
     * @param {Number} height
     * @param {Number} colorId
     * @param {String} urlTop
     * @param {String} coatingId
     * @returns {ArrangementObject}
     */
    static createArrangementObject(objectId: string, objectType: string, objectManufacturer: any, objectRetailer: any, objectStyles: any, length: number, width: number, height: number, colorId: any, coatingId: string, stretchability: any, customization: any, urlTop: string) : ArrangementObject {
        this.lastEntityId++;
        let arrangement = new ArrangementObject(this.lastEntityId, objectId, objectManufacturer, objectRetailer, objectType, objectStyles, length, width, height, colorId, coatingId, stretchability, customization);
        //Create components
        if (urlTop !== undefined) {
            //Add 2d renderer
            arrangement.addComponent(new RendererTopView(urlTop));
        }
        return arrangement;
    }

    /**
     * Return the clone of the entity arrangementObject. Parent (only type and id) have to be clone if they are arrangementGroup
     * to have access to there id
     *
     * @param {ArrangementObject} clonedArrangement
     * @param {boolean} renewId If not defined clone id, else new id is generated
     */
    static cloneArrangementObject(clonedArrangement: ArrangementObject, renewId: boolean) : ArrangementObject {
        if (renewId) {
            this.lastEntityId++;
        }

        let originalWidth = clonedArrangement.width;
        let originalHeight = clonedArrangement.height;
        let originalLength = clonedArrangement.length;

        // If original dimensions are in the JSON, then consider them as dimensions we want to initialise the object with (so then end up going into originalWidth, Height and Length of the object)
        if (clonedArrangement.originalWidth !== undefined) {
            originalWidth = clonedArrangement.originalWidth;
        }
        if (clonedArrangement.originalHeight !== undefined) {
            originalHeight = clonedArrangement.originalHeight;
        }
        if (clonedArrangement.originalLength !== undefined) {
            originalLength = clonedArrangement.originalLength;
        }

        let customizationCopy;

        if (clonedArrangement.customization) {
            customizationCopy = JSON.parse(JSON.stringify(clonedArrangement.customization));
        }

        let arrangement = new ArrangementObject(renewId ? this.lastEntityId : clonedArrangement.id, clonedArrangement.objectId, clonedArrangement.manufacturer, clonedArrangement.retailer, clonedArrangement.objectType, clonedArrangement.objectStyles, originalLength, originalWidth, originalHeight, clonedArrangement.colorId, clonedArrangement.coatingId, clonedArrangement.stretchability, customizationCopy);

        if (clonedArrangement.excludeFromShoppingList !== undefined) {
            arrangement.excludeFromShoppingList = clonedArrangement.excludeFromShoppingList;
        }

        if (clonedArrangement.handleSide !== undefined) {
            arrangement.handleSide = clonedArrangement.handleSide;
        }
        if (clonedArrangement.handleAsset !== undefined) {
            arrangement.handleAsset = clonedArrangement.handleAsset;
        }
        // If the original dimensions are in the JSON, post update the object to reassign actual width, height and length
        if (clonedArrangement.originalWidth !== undefined) {
            arrangement.width = clonedArrangement.width;
        }
        if (clonedArrangement.originalHeight !== undefined) {
            arrangement.height = clonedArrangement.height;
        }
        if (clonedArrangement.originalLength !== undefined) {
            arrangement.length = clonedArrangement.length;
        }
        if (clonedArrangement.lightOn !== undefined) {
            arrangement.lightOn = clonedArrangement.lightOn;
        }
        if (clonedArrangement.lightOff !== undefined) {
            arrangement.lightOff = clonedArrangement.lightOff;
        }
        if (clonedArrangement.temperature !== undefined) {
            arrangement.temperature = clonedArrangement.temperature;
        }
        if (clonedArrangement.lightColor !== undefined) {
            arrangement.lightColor = clonedArrangement.lightColor;
        }
        if (clonedArrangement.symmetry !== undefined) {
            arrangement.symmetry = clonedArrangement.symmetry;
        }
        if (clonedArrangement.coatingAllowed !== undefined) {
            arrangement.coatingAllowed = clonedArrangement.coatingAllowed;
        }
        if (clonedArrangement.smartDesignerParentId !== undefined) {
            arrangement.smartDesignerParentId = clonedArrangement.smartDesignerParentId;
        }
        if (clonedArrangement.configurable !== undefined) {
            arrangement.configurable = clonedArrangement.configurable;
        }

        arrangement.name = clonedArrangement.name;

        arrangement.objectTypeConfig = clonedArrangement.objectTypeConfig;
        arrangement.stackable = clonedArrangement.stackable;

        math.mat4.copy(arrangement.transform.localMatrix, clonedArrangement.transform.localMatrix);

        arrangement.isAnchorActive = clonedArrangement.isAnchorActive !== undefined ? clonedArrangement.isAnchorActive : true;
        arrangement.anchor = clonedArrangement.anchor; //Done like that to avoid the automated treatement for upper anchor
        arrangement.parent = clonedArrangement.parent;

        //Clone components
        let i,
            component,
            furnitureFinishes = null;
        for (i = 0; i < clonedArrangement.components.length; i++) {
            component = clonedArrangement.components[i].clone();
            arrangement.addComponent(component);
            if (clonedArrangement.components[i].componentType === ComponentConstants.ComponentType.FurnitureFinishes) {
                furnitureFinishes = component;
            }
        }

        for (i = 0; i < clonedArrangement.children.length; i++) {
            var subentity = EntityFactory.cloneEntity(clonedArrangement.children[i], renewId);
            math.mat4.copy(subentity.transform.localMatrix, clonedArrangement.children[i].transform.localMatrix);
            arrangement.addChild(subentity);

            if (furnitureFinishes !== null) {
                if (furnitureFinishes.fillers.right !== null && furnitureFinishes.fillers.right === clonedArrangement.children[i]) {
                    furnitureFinishes.fillers.right = subentity;
                }
                if (furnitureFinishes.fillers.left !== null && furnitureFinishes.fillers.left === clonedArrangement.children[i]) {
                    furnitureFinishes.fillers.left = subentity;
                }
                if (furnitureFinishes.fillers.back !== null && furnitureFinishes.fillers.back === clonedArrangement.children[i]) {
                    furnitureFinishes.fillers.back = subentity;
                }
                if (furnitureFinishes.fillers.front !== null && furnitureFinishes.fillers.front === clonedArrangement.children[i]) {
                    furnitureFinishes.fillers.front = subentity;
                }
                if (furnitureFinishes.endPanels.right !== null && furnitureFinishes.endPanels.right === clonedArrangement.children[i]) {
                    furnitureFinishes.endPanels.right = subentity;
                }
                if (furnitureFinishes.endPanels.left !== null && furnitureFinishes.endPanels.left === clonedArrangement.children[i]) {
                    furnitureFinishes.endPanels.left = subentity;
                }
                if (furnitureFinishes.endPanels.back !== null && furnitureFinishes.endPanels.back === clonedArrangement.children[i]) {
                    furnitureFinishes.endPanels.back = subentity;
                }
                if (furnitureFinishes.endPanels.front !== null && furnitureFinishes.endPanels.front === clonedArrangement.children[i]) {
                    furnitureFinishes.endPanels.front = subentity;
                }
                if (furnitureFinishes.plinths.right !== null && furnitureFinishes.plinths.right === clonedArrangement.children[i]) {
                    furnitureFinishes.plinths.right = subentity;
                }
                if (furnitureFinishes.plinths.left !== null && furnitureFinishes.plinths.left === clonedArrangement.children[i]) {
                    furnitureFinishes.plinths.left = subentity;
                }
                if (furnitureFinishes.plinths.back !== null && furnitureFinishes.plinths.back === clonedArrangement.children[i]) {
                    furnitureFinishes.plinths.back = subentity;
                }
                if (furnitureFinishes.plinths.front !== null && furnitureFinishes.plinths.front === clonedArrangement.children[i]) {
                    furnitureFinishes.plinths.front = subentity;
                }
            }
        }

        return arrangement;
    }

    static cloneArrangementZone(arrangementZone: ArrangementZone, renewId: boolean) : ArrangementZone {
        if (renewId) {
            this.lastEntityId++;
        }

        let arrangement = new ArrangementZone(renewId ? this.lastEntityId : arrangementZone.id, arrangementZone.zoneType, arrangementZone.width, arrangementZone.length, arrangementZone.height, arrangementZone.offset, undefined, undefined, undefined);

        math.mat4.copy(arrangement.transform.localMatrix, arrangementZone.transform.localMatrix);

        //Clone components
        let i, component;
        for (i = 0; i < arrangementZone.components.length; i++) {
            component = arrangementZone.components[i].clone();
            arrangement.addComponent(component);
        }

        //update position
        for (i = 0; i < arrangementZone.children.length; i++) {
            var subentity = EntityFactory.cloneEntity(arrangementZone.children[i], renewId);
            math.mat4.copy(subentity.transform.localMatrix, arrangementZone.children[i].transform.localMatrix);
            arrangement.addChild(subentity);
        }

        arrangement.parent = arrangementZone.parent;

        return arrangement;
    }

    static createEmptySun(id: number) : Sun {
        if (!id) {
            this.lastEntityId++;
            id = this.lastEntityId;
        } else {
            if (this.lastEntityId < id) {
                this.lastEntityId = id;
            }
        }
        return new Sun(id);
    }

    /**
     * Return a entity sketchBlock
     *
     * @param {Number} objectId
     * @param {Number} length
     * @param {Number} width
     * @param {Number} height
     * @returns {SketchBlock}
     */
    static createSketchBlock(sketchId: string, length: number, width: number, height: number) : SketchBlock {
        this.lastEntityId++;
        let sketchBlock = new SketchBlock(this.lastEntityId, sketchId, length, width, height);
        return sketchBlock;
    }

    static cloneSketchBlock(clonedSketch: SketchBlock, renewId: boolean) : SketchBlock {
        if (renewId) {
            this.lastEntityId++;
        }

        let sketchBlock = new SketchBlock(renewId ? this.lastEntityId : clonedSketch.id, clonedSketch.sketchId, clonedSketch.length, clonedSketch.width, clonedSketch.height);
        math.mat4.copy(sketchBlock.transform.localMatrix, clonedSketch.transform.localMatrix);
        sketchBlock.parent = clonedSketch.parent;

        //Clone components
        for (let i = 0; i < clonedSketch.components.length; i++) {
            sketchBlock.addComponent(clonedSketch.components[i].clone());
        }

        for (let i = 0; i < clonedSketch.children.length; i++) {
            var subentity = EntityFactory.cloneEntity(clonedSketch.children[i], renewId);
            math.mat4.copy(subentity.transform.localMatrix, clonedSketch.children[i].transform.localMatrix);
            sketchBlock.addChild(subentity);
        }
        return sketchBlock;
    }
    /**
     * Create a user picture
     **/
    static createUserPicture(pictureRef: any) : UserPicture {
        this.lastEntityId++;
        let userPicture = new UserPicture(this.lastEntityId, pictureRef);
        return userPicture;
    }
    /**
     * Clone a user picture
     **/
    static cloneUserPicture(clonedEntity: UserPicture, renewId: boolean) : UserPicture {
        if (renewId) {
            this.lastEntityId++;
        }
        let userPicture = new UserPicture(renewId ? this.lastEntityId : clonedEntity.id, clonedEntity.pictureRef);
        return userPicture;
    }

    /**
     * Return a entity TechnicalElement
     *
     * @param {*} parsedJSON
     */
    static createTechnicalElementFromJSON(parsedJSON: any) : TechnicalElement {
        if (parsedJSON.id > this.lastEntityId) {
            this.lastEntityId = parsedJSON.id;
        }
        let technicalElement = null;
        switch (parsedJSON.objectId) {
            case SceneConstants.TechnicalElementType.waterSource:
                technicalElement = new WaterSource(parsedJSON.id, parsedJSON.objectId, parsedJSON.length, parsedJSON.width, parsedJSON.height, parsedJSON.objectType);
                technicalElement.isIn = parsedJSON.isIn;
                technicalElement.isOut = parsedJSON.isOut;
                break;
            case SceneConstants.TechnicalElementType.ceilingBox:
                technicalElement = new CeilingBox(parsedJSON.id, parsedJSON.objectId, parsedJSON.length, parsedJSON.width, parsedJSON.height, parsedJSON.objectType, parsedJSON.ceilingBoxType);
                break;
            default:
                technicalElement = new TechnicalElement(parsedJSON.id, parsedJSON.objectId, parsedJSON.length, parsedJSON.width, parsedJSON.height, parsedJSON.objectType);
                break;
        }
        technicalElement.subModelId = parsedJSON.subModelId !== undefined ? parsedJSON.subModelId : 0;
        technicalElement.shapeType = parsedJSON.shapeType;

        return technicalElement;
    }

    /**
     * Create technical element
     *
     * @param {Number} objectId
     * @param {Number} length
     * @param {Number} width
     * @param {Number} height
     * @param {Number} objectType
     * @param {*} urlTop
     * @returns {TechnicalElement}
     */
    static createTechnicalElement(objectId: SceneConstants.TechnicalElementType, length: number, width: number, height: number, objectType: string, urlTop: string) : TechnicalElement {
        this.lastEntityId++;
        let technicalElement;

        switch (objectId) {
            case SceneConstants.TechnicalElementType.waterSource:
                technicalElement = new WaterSource(this.lastEntityId, objectId, length, width, height, objectType);
                break;
            case SceneConstants.TechnicalElementType.ceilingBox:
                technicalElement = new CeilingBox(this.lastEntityId, objectId, length, width, height, objectType, 0);
                break;
            default:
                technicalElement = new TechnicalElement(this.lastEntityId, objectId, length, width, height, objectType);
                break;
        }

        //Set default floor height
        technicalElement.floorHeight = SceneConstants.TechElementDefaultFloorHeight[technicalElement.objectId];

        //Create components
        if (urlTop !== undefined) {
            //Add 2d renderer
            technicalElement.addComponent(new RendererTopView(urlTop));
        }

        let box = new BoundingBox();
        box.update(technicalElement.position, technicalElement.length, technicalElement.width, technicalElement.height);
        technicalElement.addComponent(box);

        return technicalElement;
    }

    /**
     * Return the clone of the entity TechnicalElement.
     *
     * @param {TechnicalElement} clonedTechnicalElement
     */
    static cloneTechnicalElement(clonedTechnicalElement: TechnicalElement, renewid: boolean) : TechnicalElement {
        //Create a new if or use the previous one
        if (renewid) {
            this.lastEntityId++;
        }
        var itemId = renewid ? this.lastEntityId : clonedTechnicalElement.id;
        let technicalElement;
        switch (clonedTechnicalElement.objectId) {
            case SceneConstants.TechnicalElementType.waterSource:
                technicalElement = new WaterSource(itemId, clonedTechnicalElement.objectId, clonedTechnicalElement.length, clonedTechnicalElement.width, clonedTechnicalElement.height, clonedTechnicalElement.objectType);
                technicalElement.isIn = (clonedTechnicalElement as WaterSource).isIn;
                technicalElement.isOut = (clonedTechnicalElement as WaterSource).isOut;
                break;

            case SceneConstants.TechnicalElementType.ceilingBox:
                technicalElement = new CeilingBox(itemId, clonedTechnicalElement.objectId, clonedTechnicalElement.length, clonedTechnicalElement.width, clonedTechnicalElement.height, clonedTechnicalElement.objectType, (clonedTechnicalElement as CeilingBox).ceilingBoxType);

                break;

            default:
                technicalElement = new TechnicalElement(itemId, clonedTechnicalElement.objectId, clonedTechnicalElement.length, clonedTechnicalElement.width, clonedTechnicalElement.height, clonedTechnicalElement.objectType);
        }

        technicalElement.position = clonedTechnicalElement.position;
        math.mat4.copy(technicalElement.transform.localMatrix, clonedTechnicalElement.transform.localMatrix);
        technicalElement.materialType = clonedTechnicalElement.materialType;
        if (clonedTechnicalElement.lightOn !== undefined) {
            technicalElement.lightOn = clonedTechnicalElement.lightOn;
        }
        if (clonedTechnicalElement.lightOff !== undefined) {
            technicalElement.lightOff = clonedTechnicalElement.lightOff;
        }
        if (clonedTechnicalElement.temperature !== undefined) {
            technicalElement.temperature = clonedTechnicalElement.temperature;
        }
        if (clonedTechnicalElement.lightColor !== undefined) {
            technicalElement.lightColor = clonedTechnicalElement.lightColor;
        }
        if (clonedTechnicalElement.intensity !== undefined) {
            technicalElement.intensity = clonedTechnicalElement.intensity;
        }
        if (clonedTechnicalElement.symmetry !== undefined) {
            technicalElement.symmetry = clonedTechnicalElement.symmetry;
        }

        technicalElement.shapeType = clonedTechnicalElement.shapeType;

        //Clone components
        for (let i = 0; i < clonedTechnicalElement.components.length; i++) {
            technicalElement.addComponent(clonedTechnicalElement.components[i].clone());
        }

        technicalElement.parent = clonedTechnicalElement.parent;
        return technicalElement;
    }

    /**
     *
     * @param {Number} objectId
     * @param {Number} id
     * @returns {*}
     */
    static createStaircase(objectId: SceneConstants.StaircaseType, id?: number) : Staircase {
        var idToUse;
        if (id === undefined) {
            id = -1;
        }
        if (id === -1) {
            this.lastEntityId++;
            idToUse = this.lastEntityId;
        } else {
            idToUse = id;
            if (this.lastEntityId < id) {
                this.lastEntityId = id;
            }
        }
        let stair = null;
        switch (objectId) {
            case SceneConstants.StaircaseType.straight:
                stair = new StraightStaircase(idToUse);
                break;
            case SceneConstants.StaircaseType.quarterTurn:
                stair = new QuarterStaircase(idToUse);
                break;
            case SceneConstants.StaircaseType.doubleQuarterTurn:
                stair = new DoubleQuarterStaircase(idToUse);
                break;
            case SceneConstants.StaircaseType.circular:
                stair = new CircularStaircase(idToUse);
                break;
        }

        return stair;
    }

    /**
     *  Create staircase from a JSON string
     *
     * @param {*} parsedJSON
     * @returns {*}
     */
    static createStairCaseFromJSON(parsedJSON: any) : Staircase {
        if (parsedJSON.id > this.lastEntityId) {
            this.lastEntityId = parsedJSON.id;
        }
        let stairCase = this.createStaircase(parsedJSON.objectId, parsedJSON.id);
        stairCase.shapeType = parsedJSON.shapeType;
        stairCase.width = parsedJSON.width;
        stairCase.height = parsedJSON.height;
        stairCase.length = parsedJSON.length;
        stairCase.hasLeftRamp = parsedJSON.hasLeftRamp;
        stairCase.hasRightRamp = parsedJSON.hasRightRamp;
        stairCase.isGoingDown = parsedJSON.isGoingDown;
        stairCase.stairCaseStyleId = parsedJSON.stairCaseStyleId;
        stairCase.noseLength = parsedJSON.noseLength;
        if (parsedJSON.hideInAxo !== undefined) {
            stairCase.hideInAxo = parsedJSON.hideInAxo;
        } else {
            stairCase.hideInAxo = false;
        }
        if (parsedJSON.cutSlope !== undefined) {
            stairCase.cutSlope = parsedJSON.cutSlope;
        } else {
            stairCase.cutSlope = true;
        }
        stairCase.closureType = parsedJSON.closureType;
        stairCase.limonType = parsedJSON.limonType;
        if (parsedJSON.limonWidth !== undefined) {
            stairCase.limonWidth = parsedJSON.limonWidth;
        }
        if (parsedJSON.limonHeight !== undefined) {
            stairCase.limonHeight = parsedJSON.limonHeight;
        }
        if (parsedJSON.way !== undefined) {
            stairCase.way = parsedJSON.way;
        } else {
            stairCase.way = true;
        }

        if (stairCase instanceof DoubleQuarterStaircase) {
            if (parsedJSON.stepWidth !== undefined) {
                stairCase.stepWidth = parsedJSON.stepWidth;
            }
            if (parsedJSON.hasBearing !== undefined) {
                stairCase.hasBearing = parsedJSON.hasBearing;
            }
            if (parsedJSON.modifiedWidth !== undefined) {
                stairCase.modifiedWidth = parsedJSON.modifiedWidth;
            } else {
                stairCase.modifiedWidth = stairCase.width;
            }
        }

        if (stairCase instanceof CircularStaircase) {
            if (parsedJSON.revolutionAngle !== undefined) {
                stairCase.revolutionAngle = parsedJSON.revolutionAngle;
            }
        }

        if (parsedJSON.materialType !== undefined) {
            stairCase.materialType = parsedJSON.materialType;
        }
        if (parsedJSON.rampMaterialType !== undefined) {
            stairCase.rampMaterialType = parsedJSON.rampMaterialType;
        }
        if (parsedJSON.rampHeight !== undefined) {
            stairCase.rampHeight = parsedJSON.rampHeight;
        }

        return stairCase;
    }

    /**
     * Clone staircase
     *
     * @param clonedStaircase
     * @param renewId {Boolean} If false or undefined will clone id
     * @returns {*}
     */
    static cloneStaircase(clonedStaircase: Staircase, renewId: boolean) {
        let clone = this.createStaircase(clonedStaircase.objectId as SceneConstants.StaircaseType, renewId ? undefined : clonedStaircase.id);
        clone.parent = clonedStaircase.parent;
        math.mat4.copy(clone.transform.localMatrix, clonedStaircase.transform.localMatrix);
        clone.shapeType = clonedStaircase.shapeType;
        clone.width = clonedStaircase.width;
        clone.height = clonedStaircase.height;
        clone.length = clonedStaircase.length;
        clone.hasLeftRamp = clonedStaircase.hasLeftRamp;
        clone.hasRightRamp = clonedStaircase.hasRightRamp;
        clone.isGoingDown = clonedStaircase.isGoingDown;
        clone.closureType = clonedStaircase.closureType;
        clone.limonType = clonedStaircase.limonType;
        clone.limonWidth = clonedStaircase.limonWidth;
        clone.limonHeight = clonedStaircase.limonHeight;
        clone.noseLength = clonedStaircase.noseLength;
        clone.hideInAxo = clonedStaircase.hideInAxo;
        clone.cutSlope = clonedStaircase.cutSlope;
        clone.way = clonedStaircase.way;
        clone.materialType = clonedStaircase.materialType;
        clone.rampMaterialType = clonedStaircase.rampMaterialType;
        clone.rampHeight = clonedStaircase.rampHeight;

        if (clonedStaircase instanceof DoubleQuarterStaircase && clone instanceof DoubleQuarterStaircase) {
            clone.stepWidth = clonedStaircase.stepWidth;
            clone.hasBearing = clonedStaircase.hasBearing;
            clone.modifiedWidth = clonedStaircase.modifiedWidth;
        }

        if (clonedStaircase instanceof CircularStaircase && clone instanceof CircularStaircase) {
            clone.revolutionAngle = clonedStaircase.revolutionAngle;
        }

        //Clone components
        for (let i = 0; i < clonedStaircase.components.length; i++) {
            clone.addComponent(clonedStaircase.components[i].clone());
        }

        return clone;
    }

    static cloneStaircaseWithoutID(clonedStaircase: Staircase) {
        let clone = this.createStaircase(clonedStaircase.objectId as SceneConstants.StaircaseType);
        
        math.mat4.copy(clone.transform.localMatrix, clonedStaircase.transform.localMatrix);
        clone.shapeType = clonedStaircase.shapeType;
        clone.width = clonedStaircase.width;
        clone.height = clonedStaircase.height;
        clone.length = clonedStaircase.length;
        clone.hasLeftRamp = clonedStaircase.hasLeftRamp;
        clone.hasRightRamp = clonedStaircase.hasRightRamp;
        clone.rampMaterialType = clonedStaircase.rampMaterialType;
        clone.rampHeight = clonedStaircase.rampHeight;
        clone.isGoingDown = clonedStaircase.isGoingDown;
        clone.closureType = clonedStaircase.closureType;
        clone.limonType = clonedStaircase.limonType;
        clone.limonWidth = clonedStaircase.limonWidth;
        clone.limonHeight = clonedStaircase.limonHeight;
        clone.noseLength = clonedStaircase.noseLength;
        clone.hideInAxo = clonedStaircase.hideInAxo;
        clone.cutSlope = clonedStaircase.cutSlope;
        clone.way = clonedStaircase.way;

        if (clonedStaircase instanceof DoubleQuarterStaircase && clone instanceof DoubleQuarterStaircase) {
            clone.stepWidth = clonedStaircase.stepWidth;
            clone.hasBearing = clonedStaircase.hasBearing;
            clone.modifiedWidth = clonedStaircase.modifiedWidth;
        }

        if (clonedStaircase instanceof CircularStaircase && clone instanceof CircularStaircase) {
            clone.revolutionAngle = clonedStaircase.revolutionAngle;
        }

        return clone;
    }

    /**
     * Return a entity arrangementGroup
     *
     * @param {*} entityId
     */
    static createEmptyArrangementGroup(entityId: number = -1) : ArrangementGroup {
        let arrangementGroup = null;
        if (entityId !== -1) {
            arrangementGroup = new ArrangementGroup(entityId);
            if (this.lastEntityId < entityId) {
                this.lastEntityId = entityId;
            }
        } else {
            this.lastEntityId++;
            arrangementGroup = new ArrangementGroup(this.lastEntityId);
        }

        return arrangementGroup;
    }

    /**
     * Return a entity geometryGroup
     *
     * @param {*} entityId
     */
    static createEmptyGeometryGroup(entityId: number = -1) : GeometryGroup {
        let result = null;
        if (entityId !== -1) {
            result = new GeometryGroup(entityId);
            if (this.lastEntityId < entityId) {
                this.lastEntityId = entityId;
            }
        } else {
            this.lastEntityId++;
            result = new GeometryGroup(this.lastEntityId);
        }

        return result;
    }

    /**
     * Return the clone of the entity arrangement group
     *
     * @param {ArrangementGroup} clonedArrangementGroup
     * * @param {boolean} renewId If not set the id will be cloned, else call a new id
     */
    static cloneArrangementGroup(clonedArrangementGroup: ArrangementGroup, renewId: boolean) : ArrangementGroup {
        if (renewId) {
            this.lastEntityId++;
        }
        let arrangementGroup = new ArrangementGroup(renewId ? this.lastEntityId : clonedArrangementGroup.id);

        arrangementGroup.objectId = clonedArrangementGroup.objectId;
        arrangementGroup.arrangementGroupType = clonedArrangementGroup.arrangementGroupType;
        arrangementGroup.masterObjectId = clonedArrangementGroup.masterObjectId;
        arrangementGroup.AMLayoutId = clonedArrangementGroup.AMLayoutId;

        arrangementGroup.parent = clonedArrangementGroup.parent;

        math.mat4.copy(arrangementGroup.transform.localMatrix, clonedArrangementGroup.transform.localMatrix);

        if (clonedArrangementGroup.smartDesignerParentId !== undefined) {
            arrangementGroup.smartDesignerParentId = clonedArrangementGroup.smartDesignerParentId;
        }

        var iArrangements = clonedArrangementGroup.iArrangements;
        for (let i = 0; i < iArrangements.length; i++) {
            if (iArrangements[i].entityType === SceneConstants.EntityType.ArrangementGroup) {
                arrangementGroup.addArrangement(EntityFactory.cloneArrangementGroup(iArrangements[i] as ArrangementGroup, renewId));
            } else {
                arrangementGroup.addArrangement(EntityFactory.cloneArrangementObject(iArrangements[i] as ArrangementObject, renewId));
            }
        }

        //Clone components
        for (let i = 0; i < clonedArrangementGroup.components.length; i++) {
            arrangementGroup.addComponent(clonedArrangementGroup.components[i].clone());
        }

        return arrangementGroup;
    }

    /**
     * Return a entity Room
     *
     * @param {*} id
     */
    static createEmptyRoom(id: number = -1): Room {
        let room = null;
        if (id === -1) {
            this.lastEntityId++;
            room = new Room(this.lastEntityId);
            //Add components
            room.addComponent(new Functionality(null, null, null));
            room.addComponent(new RoomQuotation());
            room.addComponent(new BoundingBox());
        } else {
            room = new Room(id);
            if (this.lastEntityId < id) {
                this.lastEntityId = id;
            }
        }

        return room;
    }

    /**
     * Return the clone of the entity Room
     *
     * @param {Room} clonedRoom
     * @param {Boolean} cloneWalls default:true
     */
    static cloneRoom(clonedRoom: Room, cloneWalls: boolean, renewId: boolean) : Room {
        let newRoom = new Room(renewId ? this.lastEntityId++ : clonedRoom.id);
        newRoom.height = clonedRoom.height;
        newRoom.floorHeight = clonedRoom.floorHeight;
        newRoom.hasPlinths = clonedRoom.hasPlinths;
        newRoom.plinthsHeight = clonedRoom.plinthsHeight;
        newRoom.plinthsMaterialType = clonedRoom.plinthsMaterialType;
        newRoom.hasCornices = clonedRoom.hasCornices;
        if (cloneWalls === undefined || cloneWalls) {
            for (let i = 0; i < clonedRoom.walls.length; i++) {
                newRoom.addWall(EntityFactory.cloneWall(clonedRoom.walls[i], renewId));
            }
            for (let i = 0; i < clonedRoom.nonRoomedWalls.length; i++) {
                newRoom.addNonRoomedWall(EntityFactory.cloneWall(clonedRoom.nonRoomedWalls[i], renewId));
            }
        }
        for (let i = 0; i < clonedRoom.components.length; i++) {
            var cloneComponent = clonedRoom.components[i].clone();
            newRoom.addComponent(cloneComponent);
        }
        for (let i = 0; i < clonedRoom.children.length; i++) {
            var chip = clonedRoom.children[i];
            if (chip.entityType === SceneConstants.EntityType.FunctionalityChip) {
                var clonedChip = EntityFactory.cloneFunctionalityChip(chip as FunctionalityChip, renewId);
                newRoom.addChild(clonedChip);
                clonedChip.position = chip.position;
                clonedChip.isOnPlan = (chip as FunctionalityChip).isOnPlan;
            }
        }

        newRoom.parent = clonedRoom.parent;
        return newRoom;
    }

    /**
     * Returns the clone of the entity worktop
     * @param {Worktop} worktopicarrangementZone
     * @param {Boolean} renewId
     */
    static cloneWorkTop(worktop: WorkTop, renewId: boolean) : WorkTop {
        if (renewId) {
            this.lastEntityId++;
        }

        let clone = new WorkTop(renewId ? this.lastEntityId : worktop.id, worktop.thickness);

        math.mat4.copy(clone.transform.localMatrix, worktop.transform.localMatrix);

        //Clone components
        let i, component;
        for (i = 0; i < worktop.components.length; i++) {
            component = worktop.components[i].clone();
            clone.addComponent(component);
        }

        clone.parent = worktop.parent;

        for (let i = 0; i < worktop.credences.length; i++) {
            clone.addCredence(worktop.credences[i].begin, worktop.credences[i].end, worktop.credences[i].height);
        }

        for (let i = 0; i < worktop.legs.length; i++) {
            clone.addLeg(worktop.legs[i].begin, worktop.legs[i].end, worktop.legs[i].isUnder);
        }

        return clone;
    }
    /**
     * Return an empty entity Floor
     * @param {*} id
     */
    static createEmptyFloor(id: number = -1) : Floor {
        let floor = null;
        if (id === -1) {
            this.lastEntityId++;
            floor = new Floor(this.lastEntityId);
        } else {
            floor = new Floor(id);
            if (this.lastEntityId < id) {
                this.lastEntityId = id;
            }
        }

        return floor;
    }

    static createFloor(id: number = -1) : Floor {
        let floor = null;
        if (id === -1) {
            this.lastEntityId++;
            floor = new Floor(this.lastEntityId);

            this.lastEntityId++;
            let sun = new Sun(this.lastEntityId);
            floor.addChild(sun);
        } else {
            floor = new Floor(id);
            if (this.lastEntityId < id) {
                this.lastEntityId = id;
            }
        }
        //Add default components
        let dome = new DomeLight();
        floor.addComponent(dome);
        let boundingBox = new BoundingBox();
        floor.addComponent(boundingBox);
        return floor;
    }

    static createSun(id: number = -1) : Sun {
        let sun = null;
        if (id === -1) {
            this.lastEntityId++;
            sun = new Sun(this.lastEntityId);
        } else {
            sun = new Sun(id);
            if (this.lastEntityId < id) {
                this.lastEntityId = id;
            }
        }
        return sun;
    }

    static createEmptyFunctionalityChip(id: number) : FunctionalityChip {
        let chip = new FunctionalityChip();
        if (id === -1) {
            this.lastEntityId++;
            chip.id = this.lastEntityId;
        } else {
            chip.id = id;
            if (this.lastEntityId < id) {
                this.lastEntityId = id;
            }
        }
        return chip;
    }

    /**
     * Return the clone of the chip
     *
     * @param {*} chip
     */
    static cloneFunctionalityChip(chip: FunctionalityChip, renewId: boolean) : FunctionalityChip {
        if (renewId) {
            this.lastEntityId++;
        }

        var newChip = EntityFactory.createEmptyFunctionalityChip(renewId ? this.lastEntityId : chip.id);
        newChip.parent = chip.parent;

        for (let i = 0; i < chip.components.length; i++) {
            var cloneComponent = chip.components[i].clone();
            newChip.addComponent(cloneComponent);
        }

        return newChip;
    }

    /**
     * Return a entity FunctionalityChip
     *
     * @param {*} functionality
     * @param {String} name
     * @param {String} urlChipImg
     * @param {*} urlSecondary
     * @param {*} id
     */
    static createFunctionalityChip(functionality: ComponentConstants.Functionalities, name: string, urlChipImg: string, urlSecondary: string, id: number) : FunctionalityChip {
        let chip = new FunctionalityChip();

        if (id === -1) {
            this.lastEntityId++;
            chip.id = this.lastEntityId;
        } else {
            chip.id = id;
            if (this.lastEntityId < id) {
                this.lastEntityId = id;
            }
        }
        chip.addComponent(new RendererTopView(urlChipImg, urlSecondary));
        chip.addComponent(new Functionality(functionality, name, null));

        return chip;
    }

    static cloneSun(sun: Sun, renewId: boolean) : Sun {
        let clonedSun = new Sun();

        if (!renewId) {
            clonedSun.id = sun.id;
        } else {
            this.lastEntityId++;
            clonedSun.id = this.lastEntityId;
        }
        clonedSun.altitude = sun.altitude;
        clonedSun.weather = sun.weather;
        clonedSun.southDirection = sun.southDirection;
        clonedSun.exterior = sun.exterior;
        clonedSun.additionalExterior = sun.additionalExterior;
        clonedSun.increaseDecorsSize = sun.increaseDecorsSize;
        clonedSun.decorsRotation = sun.decorsRotation;
        clonedSun.shadowBlurRadius = sun.shadowBlurRadius;
        clonedSun.temperature = sun.temperature;
        clonedSun.skyIntensity = sun.skyIntensity;
        clonedSun.parent = sun.parent;

        return clonedSun;
    }

    static createEmptyComment(id: number) : Comment {
        let comment = new Comment();

        if (!id || id === -1) {
            this.lastEntityId++;
            comment.id = this.lastEntityId;
        } else {
            comment.id = id;
            if (this.lastEntityId < id) {
                this.lastEntityId = id;
            }
        }
        return comment;
    }

    static cloneComment(comment: Comment, renewId: boolean) : Comment {
        let clonedComment = new Comment();

        if (!renewId) {
            clonedComment.id = comment.id;
        } else {
            this.lastEntityId++;
            clonedComment.id = this.lastEntityId;
        }
        clonedComment.text = comment.text;
        clonedComment.images = comment.images;
        clonedComment.position = comment.position;
        clonedComment.parent = comment.parent;

        return clonedComment;
    }

    static createEmptyRenderCamera(id: number) : RenderCamera {
        let camera = new RenderCamera();

        if (!id || id === -1) {
            this.lastEntityId++;
            camera.id = this.lastEntityId;
        } else {
            camera.id = id;
            if (this.lastEntityId < id) {
                this.lastEntityId = id;
            }
        }
        return camera;
    }

    static cloneRenderCamera(camera: RenderCamera, renewId: boolean) : RenderCamera {
        let clonedCamera = new RenderCamera();

        if (!renewId) {
            clonedCamera.id = camera.id;
        } else {
            this.lastEntityId++;
            clonedCamera.id = this.lastEntityId;
        }
        clonedCamera.parent = camera.parent;
        math.mat4.copy(clonedCamera.transform.localMatrix, camera.transform.localMatrix);
        clonedCamera.quality = camera.quality;
        clonedCamera.isFinalRender = camera.isFinalRender;
        clonedCamera.iso = camera.iso;
        clonedCamera.cameraNb = camera.cameraNb;
        clonedCamera.renderWidth = camera.renderWidth;
        clonedCamera.renderHeight = camera.renderHeight;
        clonedCamera.shutterSpeed = camera.shutterSpeed;
        clonedCamera.fNumber = camera.fNumber;
        clonedCamera.denoising = camera.denoising;
        clonedCamera.autoexposure = camera.autoexposure;
        clonedCamera.whitebalance = camera.whitebalance;
        clonedCamera.renderType = camera.renderType;
        clonedCamera.format = camera.format;
        clonedCamera.hd = camera.hd;
        clonedCamera.fov = camera.fov;
        clonedCamera.excludedObjectIds = camera.excludedObjectIds.slice();
        clonedCamera.exposure = camera.exposure;
        clonedCamera.dof = camera.dof;
        clonedCamera.aperture = camera.aperture;
        clonedCamera.nbImages = camera.nbImages;
        clonedCamera.verticalShift = camera.verticalShift;
        clonedCamera.sunOffsetRotation = camera.sunOffsetRotation;
        clonedCamera.sunOffsetAltitude = camera.sunOffsetAltitude;

        if (camera.projection) {
            clonedCamera.format = camera.format;
            clonedCamera.hd = camera.hd;
            clonedCamera.renderWidth = camera.renderWidth;
            clonedCamera.renderHeight = camera.renderHeight;
            clonedCamera.fov = camera.fov;
            clonedCamera.projection = math.mat4.clone(camera.projection);
        }

        // Set camera type at the end to prevent render cameras non editable fields
        clonedCamera.rawCameraType = camera.cameraType;
        return clonedCamera;
    }

    static createArrangementGroupFromJSON(json : any) : ArrangementGroup {

        var currentLastEntityID = this.getLastEntityId();
        var baseEntity = JSONImporter.importEntity(json, null);
        this.setLastEntityId(currentLastEntityID);
        let arrangementGroup = EntityFactory.cloneArrangementGroup(baseEntity, true);
        return arrangementGroup;
    }

    static createArrangementZoneFromJSON(parsedJSON: any) : ArrangementZone {
        if (parsedJSON.id > this.lastEntityId) {
            this.lastEntityId = parsedJSON.id;
        }
        let zone = new ArrangementZone(parsedJSON.id, parsedJSON.zoneType, parsedJSON.width, parsedJSON.length, parsedJSON.height, parsedJSON.offset, undefined, undefined, undefined);
        if (parsedJSON.data) {
            zone.data = parsedJSON.data;
        }

        let box = new BoundingBox();
        box.update(zone.position, zone.length, zone.width, zone.height);
        zone.addComponent(box);

        return zone;
    }

    static createArrangementZone(zoneType: SceneConstants.ArrangementZoneType, width: number, length: number, height: number, offset: math.vec3, up: any, forward: any, left: any) {
        this.lastEntityId++;
        var newId = this.lastEntityId;
        let zone = new ArrangementZone(newId, zoneType, width, length, height, offset, up, forward, left);

        let box = new BoundingBox();
        box.update(zone.position, zone.length, zone.width, zone.height);
        zone.addComponent(box);

        return zone;
    }

    static createWorkTop(thickness: number) : WorkTop {
        this.lastEntityId++;
        var newId = this.lastEntityId;
        let workTop = new WorkTop(newId, thickness);
        workTop.addComponent(new Area());
        workTop.addComponent(new BoundingBox());
        return workTop;
    }

    //Static acessor
    static getLastEntityId() : number {
        return this.lastEntityId;
    }

    static incLastEntityId() {
        return ++this.lastEntityId;
    }

    static setLastEntityId(val: number) {
        this.lastEntityId = val;
    }

    static renumberTree(entity: Entity) {
        this.lastEntityId++;

        entity.id = this.lastEntityId;

        for (var i = 0; i < entity.children.length; i++) {
            this.renumberTree(entity.children[i]);
        }
    }
}
