import { FurnitureFinishes } from './FurnitureFinishes'
import { Component } from '../../components/Component';
import { ComponentConstants } from '../../components/ComponentConstants';
import { KitchenToolConstants } from '../KitchenToolConstants';
import { SceneConstants } from '../../SavaneJS';

/**
 * Saved reference of the assetManager object representingfurniture (Plinth/filler/endPanel) of an arrangement
 */
export class AMFurnitureFinishesReference extends Component {

    public fillerData: any;
    public endPanelData: any;
    public plinthData: any;

    constructor(plinthJSON?, endPanelJSON?, fillerJSON?) {
        super();
        this.fillerData = {
            objectId: (fillerJSON !== undefined && fillerJSON !== null &&
                fillerJSON.objectId !== undefined) ? fillerJSON.objectId : null,

            objectType: (fillerJSON !== undefined && fillerJSON !== null &&
                fillerJSON.objectType !== undefined) ? fillerJSON.objectType : SceneConstants.ArrangementType.kitchenFiller,

            length: (fillerJSON !== undefined && fillerJSON !== null &&
                fillerJSON.length !== undefined) ? fillerJSON.length : null,

            width: (fillerJSON !== undefined && fillerJSON !== null &&
                fillerJSON.width !== undefined) ? fillerJSON.width : KitchenToolConstants.DefaultFillerThickness,

            height: (fillerJSON !== undefined && fillerJSON !== null &&
                fillerJSON.height !== undefined) ? fillerJSON.height : null,

            configId: (fillerJSON !== undefined && fillerJSON !== null &&
                fillerJSON.configId !== undefined) ? fillerJSON.configId : null,

            coatingId: (fillerJSON !== undefined && fillerJSON !== null &&
                fillerJSON.coatingId !== undefined) ? fillerJSON.coatingId : null
        };
        // Possible object type for endPanel : kitchenVisibleSide or kitchenEndPanel ==> Either The object is added to
        // the furniture (endPanel) or the object replace a side (VisibleSide)
        this.endPanelData = {
            objectId: (fillerJSON !== undefined && fillerJSON !== null &&
                fillerJSON.objectId !== undefined) ? fillerJSON.objectId : null,

            objectLeftId: (endPanelJSON !== undefined && endPanelJSON !== null &&
                endPanelJSON.objectLeftId !== undefined) ? endPanelJSON.objectLeftId : null,

            objectRightId: (endPanelJSON !== undefined && endPanelJSON !== null &&
                endPanelJSON.objectRightId !== undefined) ? endPanelJSON.objectRightId : null,

            objectRightAndRightId: (endPanelJSON !== undefined && endPanelJSON !== null &&
                endPanelJSON.objectRightAndRightId !== undefined) ? endPanelJSON.objectRightAndRightId : null,

            objectType: (endPanelJSON !== undefined && endPanelJSON !== null &&
                endPanelJSON.objectType !== undefined) ? endPanelJSON.objectType : SceneConstants.ArrangementType.kitchenVisibleSide,

            length: (endPanelJSON !== undefined && endPanelJSON !== null &&
                endPanelJSON.length !== undefined) ? endPanelJSON.length : null,

            width: (endPanelJSON !== undefined && endPanelJSON !== null &&
                endPanelJSON.width !== undefined) ? endPanelJSON.width : KitchenToolConstants.DefaultEndPanelThickness,

            height: (endPanelJSON !== undefined && endPanelJSON !== null &&
                endPanelJSON.height !== undefined) ? endPanelJSON.height : null,

            configId: (endPanelJSON !== undefined && endPanelJSON !== null &&
                endPanelJSON.configId !== undefined) ? endPanelJSON.configId : null,

            coatingId: (endPanelJSON !== undefined && endPanelJSON !== null &&
                endPanelJSON.coatingId !== undefined) ? endPanelJSON.coatingId : null
        }
            ;
        this.plinthData = {
            objectId: (plinthJSON !== undefined && plinthJSON !== null &&
                plinthJSON.objectId !== undefined) ? plinthJSON.objectId : null,

            objectType: (plinthJSON !== undefined && plinthJSON !== null &&
                plinthJSON.objectType !== undefined) ? plinthJSON.objectType : SceneConstants.ArrangementType.kitchenPlinth,

            length: (plinthJSON !== undefined && plinthJSON !== null &&
                plinthJSON.length !== undefined) ? plinthJSON.length : null,

            width: (plinthJSON !== undefined && plinthJSON !== null &&
                plinthJSON.width !== undefined) ? plinthJSON.width : KitchenToolConstants.DefaultPlinthThickness,

            height: (plinthJSON !== undefined && plinthJSON !== null &&
                plinthJSON.height !== undefined) ? plinthJSON.height : KitchenToolConstants.DefaultPlinthHeight,

            configId: (plinthJSON !== undefined && plinthJSON !== null &&
                plinthJSON.configId !== undefined) ? plinthJSON.configId : null,

            coatingId: (plinthJSON !== undefined && plinthJSON !== null &&
                plinthJSON.coatingId !== undefined) ? plinthJSON.coatingId : null
        };

        this._isUnique = true;
    }

    /**
     * update data from assets manager
     *
     * @param plinth
     * @param endPanels (list because depends of the side)
     * @param filler
     * @param arrangementCoatingId
     */
    updateFromAssets(plinth, endPanels, filler, arrangementCoatingId) {
        let i, j,
            configId,
            coatingId;
        //plinth
        if (plinth !== null && plinth !== undefined) {
            //find configId and coatingId (going thought config configs[i].coating.item._id === arrangementCoatingId)
            configId = plinth.configs[0].index;
            coatingId = arrangementCoatingId;

            this.plinthData = {
                objectId: plinth._id !== undefined ? plinth._id : null,
                objectType: plinth.objectType !== undefined ? plinth.objectType._id : SceneConstants.ArrangementType.kitchenPlinth,
                length: plinth.dimensions !== undefined ? plinth.dimensions.width : null,
                width: plinth.dimensions !== undefined ? plinth.dimensions.length : KitchenToolConstants.DefaultPlinthThickness,
                height: plinth.dimensions !== undefined ? plinth.dimensions.height : KitchenToolConstants.DefaultPlinthHeight,
                configId: configId,
                coatingId: coatingId
            };
        }
        //endPanels
        let endPanel = null;
        if (endPanels !== null && endPanels !== undefined && endPanels.length > 0) {
            //find configId and coatingId (going thought config configs[i].coating.item._id === arrangementCoatingId)
            configId = 1;
            coatingId = null;

            for (j = 0; j < endPanels.length; j++) {
                endPanel = endPanels[j];
                if (j === 0) {
                    for (i = 0; i < endPanel.configs.length; i++) {
                        if (endPanel.configs[i].coating !== null && endPanel.configs[i].coating !== undefined &&
                            endPanel.configs[i].coating.item !== null && endPanel.configs[i].coating.item !== undefined &&
                            endPanel.configs[i].coating.item._id === arrangementCoatingId) {
                            configId = endPanel.configs[i].index;
                            coatingId = arrangementCoatingId;
                        }
                    }

                    this.endPanelData = {
                        objectId: null,
                        objectLeftId: null,
                        objectRightId: null,
                        objectRightAndRightId: null,
                        objectType: endPanel.objectType !== undefined ? endPanel.objectType._id : SceneConstants.ArrangementType.kitchenVisibleSide,
                        length: endPanel.dimensions !== undefined ? endPanel.dimensions.length : null,
                        width: endPanel.dimensions !== undefined ? endPanel.dimensions.width : KitchenToolConstants.DefaultEndPanelThickness,
                        height: endPanel.dimensions !== undefined ? endPanel.dimensions.height : null,
                        configId: configId,
                        coatingId: coatingId
                    };
                }

                if (endPanel.objectTypeConfig !== null && endPanel.objectTypeConfig !== undefined &&
                    endPanel.objectTypeConfig.side !== null && endPanel.objectTypeConfig.side !== undefined) {
                    if (endPanel.objectTypeConfig.side === 'left') {
                        this.endPanelData.objectLeftId = endPanel._id !== undefined ? endPanel._id : null;
                    } else if (endPanel.objectTypeConfig.side === 'right') {
                        this.endPanelData.objectRightId = endPanel._id !== undefined ? endPanel._id : null;
                    } else if (endPanel.objectTypeConfig.side === 'leftAndRight') {
                        this.endPanelData.objectRightAndRightId = endPanel._id !== undefined ? endPanel._id : null;
                    } else {
                        this.endPanelData.objectId = endPanel._id !== undefined ? endPanel._id : null;
                    }
                } else {
                    this.endPanelData.objectId = endPanel._id !== undefined ? endPanel._id : null;
                }
            }
        }
        //filler
        if (filler !== null && filler !== undefined) {
            //find configId and coatingId (going thought config configs[i].coating.item._id === arrangementCoatingId)
            configId = 1;
            coatingId = null;
            for (i = 0; i < filler.configs.length; i++) {
                if (filler.configs[i].coating !== null && filler.configs[i].coating !== undefined &&
                    filler.configs[i].coating.item !== null && filler.configs[i].coating.item !== undefined &&
                    filler.configs[i].coating.item._id === arrangementCoatingId) {
                    configId = filler.configs[i].index;
                    coatingId = arrangementCoatingId;
                }
            }

            this.fillerData = {
                objectId: filler._id !== undefined ? filler._id : null,
                objectType: filler.objectType !== undefined ? filler.objectType._id : SceneConstants.ArrangementType.kitchenFiller,
                length: filler.dimensions !== undefined ? filler.dimensions.width : null,
                width: filler.dimensions !== undefined ? filler.dimensions.length : KitchenToolConstants.DefaultFillerThickness,
                height: filler.dimensions !== undefined ? filler.dimensions.height : null,
                configId: configId,
                coatingId: coatingId
            };
        }
    }

    /**
     * update the asset reference entity created from this component
     */
    updateEntityData() {
        if (this.entity !== null && this.entity !== undefined) {
            let furnitureComponent = this.entity.getComponents(ComponentConstants.ComponentType.FurnitureFinishes);
            if (furnitureComponent.length === 1) {
                let component = furnitureComponent[0] as FurnitureFinishes;

                //plinth
                if (component.plinths.front !== null) {
                    this.updatePlinth(component, 'front');
                }
                if (component.plinths.back !== null) {
                    this.updatePlinth(component, 'back');
                }
                if (component.plinths.left !== null) {
                    this.updatePlinth(component, 'left');
                }
                if (component.plinths.right !== null) {
                    this.updatePlinth(component, 'right');
                }

                //endPanel
                if (component.endPanels.front !== null) {
                    this.updateEndPanel(component, 'front');
                }
                if (component.endPanels.back !== null) {
                    this.updateEndPanel(component, 'back');
                }
                if (component.endPanels.left !== null) {
                    this.updateEndPanel(component, 'left');
                }
                if (component.endPanels.right !== null) {
                    this.updateEndPanel(component, 'right');
                }

                //filler
                if (component.fillers.front !== null) {
                    this.updateFiller(component, 'front');
                }
                if (component.fillers.back !== null) {
                    this.updateFiller(component, 'back');
                }
                if (component.fillers.left !== null) {
                    this.updateFiller(component, 'left');
                }
                if (component.fillers.right !== null) {
                    this.updateFiller(component, 'right');
                }

            }
        }
    }

    /**
     *  update plinth entity : do not update plinth length since it is computed from arrangement length
     *
     * @param furnitureComponent
     * @param side
     */
    updatePlinth(furnitureComponent, side) {
        furnitureComponent.plinths[side]._objectId = this.plinthData.objectId;
        furnitureComponent.plinths[side]._objectType = this.plinthData.objectType;
        furnitureComponent.plinths[side]._colorId = this.plinthData.configId;
        furnitureComponent.plinths[side]._coatingId = this.plinthData.coatingId;
        furnitureComponent.plinths[side]._width = this.plinthData.width;
        furnitureComponent.plinths[side]._height = this.plinthData.height;
    }

    /**
     *  update entPanel entity
     *
     * @param furnitureComponent
     * @param side
     */
    updateEndPanel(furnitureComponent, side) {
        let id = this.endPanelData.objectId;
        if (side === 'left' || side === 'right') {
            if (this.endPanelData.objectLeftId !== null &&
                furnitureComponent.endPanels.left !== null &&
                furnitureComponent.endPanels.right === null) {
                id = this.endPanelData.objectLeftId;
            }
            if (this.endPanelData.objectRightId !== null &&
                furnitureComponent.endPanels.right !== null &&
                furnitureComponent.endPanels.left === null) {
                id = this.endPanelData.objectRightId;
            }
            if (this.endPanelData.objectRightAndRightId !== null &&
                furnitureComponent.endPanels.left !== null &&
                furnitureComponent.endPanels.right !== null) {
                id = this.endPanelData.objectRightAndRightId;
            }
        }

        furnitureComponent.endPanels[side]._objectId = id;
        furnitureComponent.endPanels[side]._objectType = this.endPanelData.objectType;
        furnitureComponent.endPanels[side]._colorId = this.endPanelData.configId;
        furnitureComponent.endPanels[side]._coatingId = this.endPanelData.coatingId;
        furnitureComponent.endPanels[side]._width = this.endPanelData.width;
        if (this.endPanelData.length !== null && this.endPanelData.length !== undefined) {
            furnitureComponent.endPanels[side]._length = this.endPanelData.length;

        }
        if (this.endPanelData.height !== null && this.endPanelData.height !== undefined) {
            furnitureComponent.endPanels[side]._height = this.endPanelData.height;
        }
    }

    /**
     *  update filler entity : do not update filler length/height since it is computed from the arrangement size
     *
     * @param furnitureComponent
     * @param side
     */
    updateFiller(furnitureComponent, side) {
        furnitureComponent.fillers[side]._objectId = this.fillerData.objectId;
        furnitureComponent.fillers[side]._objectType = this.fillerData.objectType;
        furnitureComponent.fillers[side]._colorId = this.fillerData.configId;
        furnitureComponent.fillers[side]._coatingId = this.fillerData.coatingId;
        furnitureComponent.fillers[side]._width = this.fillerData.width;
    }

    clone() {
        return new AMFurnitureFinishesReference(this.plinthData, this.endPanelData, this.fillerData);
    }

    get componentType() {
        return ComponentConstants.ComponentType.AMFurnitureFinishesReference;
    }
}
