import {TemporaryEntity} from './TemporaryEntity';
import {math} from './../Transform';
import {wallManager} from './../../managers/WallManager';
import {Transform} from '../Transform';
import { Wall } from '../Wall';
import { Floor } from '../Floor';

export class TemporaryWall extends TemporaryEntity {
    public thickness: number;
    public height: number;
    private _begin: math.vec3;
    private _end: math.vec3;
    private _wallDirection: math.vec3;
    private _length: number;
    public shiftDirection:number;
    public slope: boolean;
    public slopeHeight: number;
    public slopeLength1: number;
    public slopeLength2: number;
    private canBeSuperposed: boolean;
    public forceNoPlinth: boolean;
    public forceNoCornice: boolean;

    private _transform: Transform;

    constructor(wall: Wall) {
        super(wall);
        this.thickness = wall.thickness;
        this.height = wall.height;

        this._begin = math.vec3.clone(wall.begin);
        this._end = math.vec3.clone(wall.end);

        this._wallDirection = math.vec3.clone(wall.wallDirection);
        this._length = wall.length;

        this.shiftDirection = wall.shiftDirection;

        this.slope = wall.slope;
        this.slopeHeight = wall.slopeHeight;
        this.slopeLength1 = wall.slopeLength1;
        this.slopeLength2 = wall.slopeLength2;

        this.canBeSuperposed = false;
        this.forceNoPlinth = wall.forceNoPlinth;
        this.forceNoCornice = wall.forceNoCornice;

        this._transform = new Transform(null);
        this._transform.clone(wall.transform);
    }

    get transform(): Transform{
        return this._transform;
    }

    get begin(): math.vec3 {
        return this._begin;
    }

    set begin(b: math.vec3) {
        let newWallVec = math.vec3.create();
        math.vec3.subtract(newWallVec, this._end, b);
        let wallVec = math.vec3.create();
        math.vec3.subtract(wallVec, this._end, this.begin);
        this.transform.localPosition = b;
        this.transform.globalZRotation = Math.atan2(newWallVec[1], newWallVec[0]);

        this._length = math.vec3.length(newWallVec);
        math.vec3.set(this._wallDirection, this.transform.localMatrix[0],
            this.transform.localMatrix[1], this.transform.localMatrix[2]);

        this._begin = math.vec3.clone(b);
    }

    get end(): math.vec3 {
        return this._end;
    }

    set end(e: math.vec3) {
        let newWallVec = math.vec3.create();
        math.vec3.subtract(newWallVec, e, this.begin);
        let wallVec = math.vec3.create();
        math.vec3.subtract(wallVec, this._end, this.begin);
        this.transform.globalZRotation = Math.atan2(newWallVec[1], newWallVec[0]);

        this._length = math.vec3.length(newWallVec);
        math.vec3.set(this._wallDirection, this.transform.localMatrix[0],
            this.transform.localMatrix[1], this.transform.localMatrix[2]);

        math.vec3.set(this._end, this.wallDirection[0] * this._length,
            this.wallDirection[1] * this._length, this.wallDirection[2] * this._length);
        math.vec3.add(this._end, this._end, this.begin);
    }

    get length(): number {
        return this._length;
    }

    get wallDirection(): math.vec3 {
        return this._wallDirection;
    }

    computeValidity(floor: Floor) {
        if (this.canBeSuperposed){
            this._isValid = wallManager.isWallValidWithSuperposition(this.entity as Wall);
        }else {
            this._isValid = wallManager.isWallValid(this.entity as Wall);
        }
    }
}
