import { Coating, ComponentConstants, EntityFactory, Floor, SceneConstants, Wall, Room, TechnicalElement, GeometryPrimitive, Staircase, ArrangementObject, ArrangementGroup, WorkTop, Sun, Joinery, RenderCamera, Credence, math } from '../SavaneJS';
import { Entity } from "./Entity";

/**
 * Scene store all data useful for the renderer (camera, entities,...)
 *
 * @constructor
 */
export class Scene extends Entity {

    public currentFloor: Floor;

    constructor(id: number) {
        super();
        this._id = id;
    }

    /**
     * Getter for the Entity type
     *
     */
    get entityType(): SceneConstants.EntityType {
        return SceneConstants.EntityType.Scene;
    }

    /**
     * Add a entity containing a entity wall to the currentFloor
     *
     * @param {Entity} wallEntity
     * @param {Boolean} tryCreateRoom
     * @returns {Room} The room affected by this operation (can be null)
     */
    addWall(wallEntity: Wall, tryCreateRoom: boolean, roomHeight: number) : Array<Room> {
        return this.currentFloor.addWall(wallEntity, tryCreateRoom, roomHeight, false);
    }

    /**
     * delete the entity (wall) of id = wallEntityId of the currentFloor
     *
     * @param {Number} wallEntityId
     * @param {Boolean} tryCreateRoom
     */
    deleteWall(wallEntityId: number, tryCreateRoom: boolean) : Array<Room> {
        return this.currentFloor.deleteWall(wallEntityId, tryCreateRoom);
    }

    /**
     * Add a entity containing a entity technicalElement to the currentFloor
     *
     * @param {Entity} technicalElement
     */
    addTechnicalElement(technicalElement: TechnicalElement) : void {
        this.currentFloor.addTechnicalElement(technicalElement);
    }

    /**
     * Add a geometry primitive entity to the currentFloor
     *
     * @param {Entity} geomPrimElement
     */
    addGeometryPrimitive(geomPrimElement: GeometryPrimitive) : void {
        this.currentFloor.addGeometryPrimitive(geomPrimElement);
    }

    /**
     * delete the entity (technicalElement) of id = technicalElementId of the currentFloor
     *
     * @param {Number} technicalElementId
     */
    deleteTechnicalElement(technicalElementId: number) : void {
        this.currentFloor.deleteTechnicalElement(technicalElementId);
    }

    addStaircase(stair: Staircase) : void{
        this.currentFloor.addStaircase(stair);
    }

    deleteStaircase(id: number) : void{
        this.currentFloor.deleteStaircase(id);
    }

    addEntity(entity: Entity) : void{
        this.currentFloor.addChild(entity);
    }

    deleteEntity(entity: Entity) : void{
        this.currentFloor.deleteChild(entity.id);
    }

    get floors() : Array<Floor> {
        let floors = [];
        for (let i = 0; i < this.children.length; i++) {
            if (this.children[i].isFloorEntity()) {
                floors.push(this.children[i]);
            }
        }
        return floors;
    }

    get rooms() : Array<Room> {
        let arr = [];
        for (let i = 0; i < this.children.length; i++) {
            if (this.children[i].isFloorEntity()) {
                var floorRooms = (this.children[i] as Floor).rooms;

                for (let j = 0; j < floorRooms.length; j++) {
                    arr.push(floorRooms[j]);
                }
            }
        }
        return arr;
    }

    get arrangementObjects() : Array<ArrangementObject> {
        let arr = [];
        for (let i = 0; i < this.children.length; i++) {
            if (this.children[i].isFloorEntity()) {
                var floorArr = (this.children[i] as Floor).arrangementObjects;

                for (let j = 0; j < floorArr.length; j++) {
                    arr.push(floorArr[j]);
                }
            }
        }
        return arr;
    }

    get arrangementGroups() : Array<ArrangementGroup> {
        let arr = [];
        for (let i = 0; i < this.children.length; i++) {
            if (this.children[i].isFloorEntity()) {
                var floorArr = (this.children[i] as Floor).arrangementGroups;

                for (let j = 0; j < floorArr.length; j++) {
                    arr.push(floorArr[j]);
                }
            }
        }
        return arr;
    }

    get worktops() : Array<WorkTop> {
        let worktopList = [];
        for (let i = 0; i < this.children.length; i++) {
            if (this.children[i].isFloorEntity()) {
                var floorWorktops = (this.children[i] as Floor).worktops;

                for (let j = 0; j < floorWorktops.length; j++) {
                    worktopList.push(floorWorktops[j]);
                }
            }
        }
        return worktopList;
    }

    get kitchenArrangementObjects() : Array<ArrangementObject> {
        let arr = [];
        for (let i = 0; i < this.children.length; i++) {
            if (this.children[i].isFloorEntity()) {
                var floorArr = (this.children[i] as Floor).arrangementObjects;

                for (let j = 0; j < floorArr.length; j++) {
                    let curArr = floorArr[j];
                    if (curArr.objectType === SceneConstants.ArrangementType.kitchenFurnitureBottom || curArr.objectType === SceneConstants.ArrangementType.kitchenFurnitureTop || curArr.objectType === SceneConstants.ArrangementType.kitchenFurnitureColumn) {
                        arr.push(curArr);
                    }
                }
            }
        }
        return arr;
    }

    /*
    get bathroomArrangementObjects() : Array<ArrangementObject> {
        let arr = [];
        for (let i = 0; i < this.children.length; i++) {
            if (this.children[i].isFloorEntity()) {
                var floorArr = (this.children[i] as Floor).arrangementObjects;

                for (let j = 0; j < floorArr.length; j++) {
                    let curArr = floorArr[j];
                    if (curArr.isBathroomArrangement()) {
                        arr.push(curArr);
                    }
                }
            }
        }
        return arr;
    }*/

    get walls() : Array<Wall> {
        let wallList = [];
        for (let i = 0; i < this.children.length; i++) {
            if (this.children[i].isFloorEntity()) {
                var floorWalls = (this.children[i] as Floor).walls;

                for (let j = 0; j < floorWalls.length; j++) {
                    wallList.push(floorWalls[j]);
                }
            }
        }
        return wallList;
    }

    get suns() : Array<Sun> {
        let sunList = [];
        for (let i = 0; i < this.children.length; i++) {
            if (this.children[i].isFloorEntity()) {
                sunList.push((this.children[i] as Floor).getSun());
            }
        }
        return sunList;
    }

    get geometryPrimitives() : Array<GeometryPrimitive> {
        let geomPrimList = [];
        for (let i = 0; i < this.children.length; i++) {
            if (this.children[i].isFloorEntity()) {
                var floorGeomPrim = (this.children[i] as Floor).geometryPrimitives;

                for (let j = 0; j < floorGeomPrim.length; j++) {
                    geomPrimList.push(floorGeomPrim[j]);
                }
            }
        }
        return geomPrimList;
    }

    get technicalElements() : Array<TechnicalElement> {
        let techElementList = [];
        for (let i = 0; i < this.children.length; i++) {
            if (this.children[i].isFloorEntity()) {
                var floorTechElement = (this.children[i] as Floor).technicalElements;

                for (let j = 0; j < floorTechElement.length; j++) {
                    techElementList.push(floorTechElement[j]);
                }
            }
        }
        return techElementList;
    }

    get stairCases() : Array<Staircase> {
        let stairCaseList = [];
        for (let i = 0; i < this.children.length; i++) {
            if (this.children[i].isFloorEntity()) {
                var floorStairCases = (this.children[i] as Floor).stairCases;

                for (let j = 0; j < floorStairCases.length; j++) {
                    stairCaseList.push(floorStairCases[j]);
                }
            }
        }
        return stairCaseList;
    }

    get joineries() : Array<Joinery> {
        let joins = [];
        for (let i = 0; i < this.children.length; i++) {
            if (this.children[i].isFloorEntity()) {
                var floorJoineries = (this.children[i] as Floor).joineries;

                for (let j = 0; j < floorJoineries.length; j++) {
                    joins.push(floorJoineries[j]);
                }
            }
        }
        return joins;
    }

    get renderCameras() : Array<RenderCamera> {
        let cam = [];
        for (let i = 0; i < this.children.length; i++) {
            if (this.children[i].isFloorEntity()) {
                var floorCam = (this.children[i] as Floor).renderCameras;

                for (let j = 0; j < floorCam.length; j++) {
                    cam.push(floorCam[j]);
                }
            }
        }
        return cam;
    }

    get coatings() : Array<Coating> {
        let coatingArray = [];
        let floorArray = this.floors;

        for (let i = 0; i < floorArray.length; i++) {
            let j, k;
            let childCoatings;
            let floor = floorArray[i];
            let walls = floor.walls;
            let rooms = floor.rooms;
            let joineries = floor.joineries;
            let techelems = floor.technicalElementsWithStaircases;
            let prims = floor.geometryPrimitives;
            let arr = floor.arrangementObjects;
            let worktops = floor.worktops;

            for (j = 0; j < walls.length; j++) {
                childCoatings = walls[j].getComponents(ComponentConstants.ComponentType.Coating);

                for (k = 0; k < childCoatings.length; k++) {
                    coatingArray.push(childCoatings[k]);
                }

                childCoatings = walls[j].getComponents(ComponentConstants.ComponentType.CoatingArea);

                for (k = 0; k < childCoatings.length; k++) {
                    coatingArray.push(childCoatings[k]);
                }
            }
            for (j = 0; j < rooms.length; j++) {
                childCoatings = rooms[j].getComponents(ComponentConstants.ComponentType.Coating);

                for (k = 0; k < childCoatings.length; k++) {
                    coatingArray.push(childCoatings[k]);
                }
            }
            for (j = 0; j < joineries.length; j++) {
                childCoatings = joineries[j].getComponents(ComponentConstants.ComponentType.Coating);

                for (k = 0; k < childCoatings.length; k++) {
                    coatingArray.push(childCoatings[k]);
                }
            }
            for (j = 0; j < techelems.length; j++) {
                childCoatings = techelems[j].getComponents(ComponentConstants.ComponentType.Coating);

                for (k = 0; k < childCoatings.length; k++) {
                    coatingArray.push(childCoatings[k]);
                }
            }
            for (j = 0; j < prims.length; j++) {
                childCoatings = prims[j].getComponents(ComponentConstants.ComponentType.Coating);

                for (k = 0; k < childCoatings.length; k++) {
                    coatingArray.push(childCoatings[k]);
                }
            }
            for (j = 0; j < arr.length; j++) {
                childCoatings = arr[j].getComponents(ComponentConstants.ComponentType.Coating);

                for (k = 0; k < childCoatings.length; k++) {
                    coatingArray.push(childCoatings[k]);
                }
            }
            for (j = 0; j < worktops.length; j++) {
                childCoatings = worktops[j].getComponents(ComponentConstants.ComponentType.Coating);

                for (k = 0; k < childCoatings.length; k++) {
                    coatingArray.push(childCoatings[k]);
                }

                childCoatings = worktops[j].getComponents(ComponentConstants.ComponentType.Credence);

                for (k = 0; k < childCoatings.length; k++) {
                    coatingArray.push(childCoatings[k]);
                }
            }
            childCoatings = floor.getComponents(ComponentConstants.ComponentType.CustomCoating);
            for (k = 0; k < childCoatings.length; k++) {
                coatingArray.push(childCoatings[k].coating);
            }
            childCoatings = floor.getComponents(ComponentConstants.ComponentType.FloorCoatingArea);
            for (k = 0; k < childCoatings.length; k++) {
                coatingArray.push(childCoatings[k]);
            }
        }
        return coatingArray;
    }

    getCustomizableObjectsByTypeAndManufacturer(objectTypeArray: Array<string>, objectManufacturerArray: Array<string>) : Array<ArrangementObject> {
        let arrangements = this.arrangementObjects;

        for (let i = arrangements.length - 1; i >= 0; i--) {
            let arr = arrangements[i];

            if (!arr.customization || !arr.objectType || !arr.manufacturer || objectTypeArray.indexOf(arr.objectType) === -1 || objectManufacturerArray.indexOf(arr.manufacturer._id) === -1) {
                arrangements.splice(i, 1);
            }
        }

        return arrangements;
    }

    getSchneiderSwitchCoatings() : {buttons: Array<string>, flatpieces: Array<string>} {
        let arrangements = this.getCustomizableObjectsByTypeAndManufacturer(SceneConstants.ArrangementType.switchCustomizableArrangements, ["6086d9bdfd9e0f6eb8360078"]);
        let switchFlatPieces = [];
        let switchButtons = [];

        for (let i = 0; i < arrangements.length; i++) {
            let coatings = arrangements[i].getComponents(ComponentConstants.ComponentType.Coating) as Array<Coating>;

            for (let j = 0; j < coatings.length; j++) {
                if (coatings[j].hangType === Coating.HangType.button) {
                    switchButtons.push(coatings[j].coatingId);
                }
                if (coatings[j].hangType === Coating.HangType.flatpiece) {
                    switchFlatPieces.push(coatings[j].coatingId);
                }
            }
        }

        return { buttons: switchButtons, flatpieces: switchFlatPieces };
    }

    getIxinaKitchenStyle() : any | null {
        // Get all kitchen arrangements of the scene
        let kitchenArrangements = this.kitchenArrangementObjects;

        // Parse all kitchen arrangement objects
        for (let i = 0; i < kitchenArrangements.length; i++) {
            // Get i-th object
            let arr = kitchenArrangements[i];

            // Is it Ixina manufacturer ?
            if (arr.manufacturer && arr.manufacturer._id === "662bb10916eb511a1c89d3ff") {
                if (arr.customization && arr.customization.customizationGroup) {
                    return arr.customization.customizationGroup;
                }
            }
        }

        return null;
    }

    getIxinaKitchenTriplets() : Array<{objectId: string, doorCoatingId: string, groupId: string}> {
        // Get all kitchen arrangements of the scene
        let kitchenArrangements = this.kitchenArrangementObjects;

        // Kitchen triplets to return
        let kitchenTriplets = [];

        // Parse all kitchen arrangement objects
        for (let i = 0; i < kitchenArrangements.length; i++) {
            // Get i-th object
            let arr = kitchenArrangements[i];
            // Kitchen object Id
            let kitchenObjectId = null;
            // Door coating Id
            let doorCoatingId = null;
            // Group Id
            let groupId = null;

            // Is it Ixina manufacturer ?
            if (arr.manufacturer && arr.manufacturer._id === "662bb10916eb511a1c89d3ff") {
                // Get all arrangement coating components
                let comps = arr.getComponents(ComponentConstants.ComponentType.Coating) as Array<Coating>;

                // Store object id
                kitchenObjectId = arr.objectId;

                // Parse all coating components
                for (let j = 0; j < comps.length; j++) {
                    // Get j-th component for the arrangement
                    let comp = comps[j];

                    // If this is a door hangtype
                    if (comp.hangType === Coating.HangType.door) {
                        // Store the door coating id
                        doorCoatingId = comp.coatingId;
                        break;
                    }
                }

                // Store group Id
                if (arr.customization && arr.customization.customizationGroup) {
                    groupId = arr.customization.customizationGroup._id;
                }

                // If entirely valid, add it to the array to return
                if (kitchenObjectId !== null && doorCoatingId !== null && groupId !== null) {
                    kitchenTriplets.push({
                        objectId: kitchenObjectId,
                        doorCoatingId: doorCoatingId,
                        groupId: groupId,
                    });
                }
            }
        }

        return kitchenTriplets;
    }

    getIxinaKitchenIds(): {
        doorIds: Array<string>,
        worktopIds: Array<string>,
        credenceIds: Array<string>,
        specialObjectsIds: Array<string>,
        electroIds: Array<string>
    } {
        // Get all kitchen arrangements of the scene
        let kitchenArrangements = this.kitchenArrangementObjects;
        // Get all worktops of the scene
        let kitchenWorktops = this.worktops;
        // Door ids
        let doorIds = [];
        // Worktop ids
        let worktopIds = [];
        // Credence ids
        let credenceIds = [];
        // Special objet id
        let specialObjectsIds = [];
        // Electromenager
        let electroIds = ["5f2bb2dbc4dc927232f448e0"];
        // Extra cards to fill specialObjectIds to complete up to 6 cards
        let extraIds = ["5f8447bc2c9b6b4d9d1226cc", "5f8447c52c9b6b4d9d1226d8", "5f8447c72c9b6b4d9d1226dc", "5f8447b22c9b6b4d9d1226bc", "5f8447ba2c9b6b4d9d1226c0", "5f8447c22c9b6b4d9d1226d0"];

        // Parse all kitchen arrangement objects
        for (let i = 0; i < kitchenArrangements.length; i++) {
            // Get i-th object
            let arr = kitchenArrangements[i];

            // Is it Ixina manufacturer ?
            if (arr.manufacturer && (arr.manufacturer._id === "662bb10916eb511a1c89d3ff" || arr.manufacturer._id === "5f16f507bd44175ef906a4d8")) {
                // Get all arrangement coating components
                let comps = arr.getComponents(ComponentConstants.ComponentType.Coating) as Array<Coating>;

                // Parse all coating components
                for (let j = 0; j < comps.length; j++) {
                    // Get j-th component for the arrangement
                    let comp = comps[j];

                    // If this is a door hangtype
                    if (comp.hangType === Coating.HangType.door && (comp.manufacturer._id === "662bb10916eb511a1c89d3ff" || comp.manufacturer._id === "5f16f507bd44175ef906a4d8")) {
                        // First id, add it
                        if (doorIds.length === 0) {
                            doorIds.push(comp.coatingId);
                        } else {
                            // Already one id, check it isn't the same and if it isn't add it and leave
                            if (doorIds.length < 2 && doorIds[0] !== comp.coatingId) {
                                doorIds.push(comp.coatingId);
                                break;
                            }
                        }
                    }
                }
            }
        }

        // Parse all worktops
        for (let i = 0; i < kitchenWorktops.length; i++) {
            // Get i-th object
            let worktop = kitchenWorktops[i];

            // Get worktop coating component
            let comp = worktop.getComponent(ComponentConstants.ComponentType.Coating) as Coating;

            // Not generic worktop material
            if (comp && (!comp.manufacturer || comp.manufacturer._id !== "5be2c40c2e50ee46876e546d")) {
                // If no worktopIds yet, add it
                if (worktopIds.length === 0) {
                    worktopIds.push(comp.coatingId);
                } else {
                    // If already one worktopId and not 2 doorIds and not same coating than at first index
                    if (doorIds.length < 2 && worktopIds.length === 1 && worktopIds[0] !== comp.coatingId) {
                        // Add worktop id
                        worktopIds.push(comp.coatingId);
                    }
                }
            }
        }

        // Parse all worktops again for credences
        for (let i = 0; i < kitchenWorktops.length; i++) {
            // Get i-th object
            let worktop = kitchenWorktops[i];

            // Only if there is at least one credence segment on the worktop
            if (worktop.credences.length > 0) {
                // Get credence coating component
                let comp = worktop.getComponent(ComponentConstants.ComponentType.Credence) as Credence;

                // Only if there is a credence and not generic
                if (comp && (!comp.manufacturer || comp.manufacturer._id !== "5be2c40c2e50ee46876e546d")) {
                    // If no credenceId yet, add it
                    if (credenceIds.length === 0) {
                        credenceIds.push(comp.coatingId);
                    } else {
                        // If already one credenceId and not 2 doorIds and not 2 worktopIds and not same coating than at first index
                        if (doorIds.length < 2 && worktopIds.length < 2 && credenceIds.length === 1 && credenceIds[0] !== comp.coatingId) {
                            // Add worktop id
                            credenceIds.push(comp.coatingId);
                        }
                    }
                }
            }
        }

        // Here parse all kitchen arranhement object to find special arrangements
        for (let i = 0; i < kitchenArrangements.length; i++) {
            // Get i-th object
            let arr = kitchenArrangements[i];

            // If the arrangement is special, fill the specialObjectsIds list
            //            if (arr.isSpecial)
            {
                // If we have 5 cards, we leave now
                if (doorIds.length + worktopIds.length + credenceIds.length + specialObjectsIds.length === 5) {
                    break;
                }
            }
        }

        // Check the number of cards we have now
        var cardNb = doorIds.length + worktopIds.length + credenceIds.length + specialObjectsIds.length + electroIds.length;

        // If less than 6 cards, fill specialObjectsIds with extra cards
        if (cardNb < 6) {
            // How many do we need to add ?
            let delta = 6 - cardNb;

            // Add to obtain a total of 6 cards
            for (let i = 0; i < delta; i++) {
                specialObjectsIds.push(extraIds[i]);
            }
        }

        // Return all lists
        return {
            doorIds: doorIds,
            worktopIds: worktopIds,
            credenceIds: credenceIds,
            specialObjectsIds: specialObjectsIds,
            electroIds: electroIds,
        };
    }

    /**
     * Add a new floor to the scene
     *
     */
    addFloor() : void {
        this.addChild(EntityFactory.createFloor());
    }

    /**
     * delete a entity floor of the scene
     *
     * @param {Entity} floorId
     */
    deleteFloor(floorId: number) : void {
        let count = this.floors.length;
        try {
            if (count === 1) {
                throw new TypeError("The scene has to have at least one floor");
            } else {
                this.deleteChild(floorId);
            }

            if (count === this.children.length) {
                throw new TypeError("There is no floor of id = parameter");
            }
        } catch (err) {
            console.log(err);
        }
    }

    /**
     * getter for the floor of id = parameter
     *
     * @param {Number} floorId
     */
    getFloor(floorId: number) : Floor | null {
        let floors = this.floors;
        for (let i = 0; i < floors.length; i++) {
            if (floors[i].id === floorId) {
                return floors[i];
            }
        }
        return null;
    }

    getFloorAtPosition(position: math.vec3) : Floor {
        let floors = this.floors;
        let i;

        floors.sort(function (a, b) {
            return a.transform.globalPosition[2] - b.transform.globalPosition[2];
        });

        for (i = 0; i < floors.length; i++) {
            let floorPosition = floors[i].transform.globalPosition;

            if (position[2] < floorPosition[2] && i === 0) {
                break;
            }

            if (i < floors.length - 1 && position[2] >= floorPosition[2] && position[2] < floors[i + 1].transform.globalPosition[2]) {
                break;
            }

            if (position[2] >= floorPosition[2] && i === floors.length - 1) {
                break;
            }
        }

        return floors[i];
    }
}
