import { TemporaryArrangementGroup } from "./temporary/TemporaryArrangementGroup";
import { Wall, Room, Floor, ArrangementObject, ArrangementZone, SceneConstants, SavaneConstants, math, SavaneMath, Transform, WorkTop } from "../SavaneJS";
import { SnappableEntity } from "./Interfaces/SnappableEntity";
import { Entity } from "./Entity";
import { SmartDesignerParameters } from "./Interfaces/SmartDesignerParameters";

/**
 * Group of arrangements Object
 */
export class ArrangementGroup extends Entity implements SnappableEntity, SmartDesignerParameters {
    private _objectId: string = "";
    private _arrangementGroupType: string = "None";
    private _length: number = null;
    private _width: number = null;
    private _height: number = null;
    private _masterObjectId: string;
    private _AMLayoutId: string;

    constructor(id: number) {
        super();

        this._id = id;
        this._transform = super.transform;
    }
    
    accessor smartDesignerParentId: string;

    isUnbreakableGroup(): boolean {
        return this._arrangementGroupType !== undefined && this._arrangementGroupType !== "None";
    }

    get transform(): Transform {
        if (this.temporary === null) {
            return this._transform;
        } else {
            return (this.temporary as TemporaryArrangementGroup).transform;
        }
    }

    /**
     * Getter for the Entity type
     *
     */
    get entityType(): SceneConstants.EntityType {
        return SceneConstants.EntityType.ArrangementGroup;
    }

    /**
     * Getter for the object id
     *
     */
    get objectId(): string {
        return this._objectId;
    }

    set objectId(amid: string) {
        this._objectId = amid;
    }

    set arrangementGroupType(t: string) {
        this._arrangementGroupType = t;
    }

    get arrangementGroupType(): string {
        return this._arrangementGroupType;
    }

    get masterObjectId(): string {
        return this._masterObjectId;
    }

    set masterObjectId(amid: string) {
        this._masterObjectId = amid;
    }

    get AMLayoutId(): string {
        return this._AMLayoutId;
    }

    set AMLayoutId(amid: string) {
        this._AMLayoutId = amid;
    }

    /**
     * Getter for the arrangementGroup angle
     *
     */
    get angle(): number {
        return this.transform.globalZRotation;
    }

    /**
     * Getter for the object angle
     *
     */
    get localAngle(): number {
        return this.transform.localZRotation;
    }

    get configurable() {
        for (let i = 0; i < this.children.length; i++) {
            if ((this.children[i] as (ArrangementObject | ArrangementGroup)).configurable) {
                return true;
            }
        }

        return false;
    }

    /**
     * set local variable of children and parent at null (recursively)
     *
     */
    recomputeRelated() {
        if (this.parent !== null && this.parent.isArrangementGroupEntity()) {
            (this.parent as ArrangementGroup).recomputeParent();
        }

        for (let i = 0; i < this.children.length; i++) {
            if (this.children[i].isArrangementGroupEntity()) {
                (this.children[i] as ArrangementGroup).recomputeChildren();
            }
        }
    }

    /**
     * set local variable of parent at null(recursively)
     *
     */
    recomputeParent() {
        this._length = null;
        this._width = null;
        this._height = null;
        if (this.parent !== null && this.parent.isArrangementGroupEntity()) {
            (this.parent as ArrangementGroup).recomputeParent();
        }
    }

    /**
     * set local variable of children at null(recursively)
     */
    recomputeChildren() {
        for (let i = 0; i < this.children.length; i++) {
            if (this.children[i].isArrangementGroupEntity()) {
                (this.children[i] as ArrangementGroup).recomputeChildren();
            }
        }
    }

    /**
     * Setter for absolute position of the arrangementGroup
     *
     * @param {*} newPosition
     */
    set position(p: math.vec3) {
        this.recomputeRelated();

        this.transform.globalPosition = p;
        if (this.temporary !== null) {
            (this.temporary as TemporaryArrangementGroup).recomputeValidity = true;
        }
    }

    get position(): math.vec3 {
        return this.transform.globalPosition;
    }

    /**
     * Setter for absolute position of ArrangementGroup
     *
     * @param {*} newPosition
     */
    set localPosition(p: math.vec3) {
        this.transform.localPosition = p;
    }

    get localPosition(): math.vec3 {
        return this.transform.localPosition;
    }

    /**
     * Set rotation on a top down view (plan2d)
     *
     * @param {Number} newAngle
     */
    setRotationZ(a: number) {
        this.recomputeRelated();

        this.transform.globalZRotation = a;
        if (this.temporary !== null) {
            (this.temporary as TemporaryArrangementGroup).recomputeValidity = true;
        }
    }

    /**
     * set for the arrangementGroup angle
     *
     */
    set angle(a: number) {
        this.setRotationZ(a);
    }

    get stackable(): boolean {
        for (let i = 0; i < this.children.length; i++) {
            if (!(this.children[i] as (ArrangementObject | ArrangementGroup)).stackable) {
                return false;
            }
        }

        return true;
    }

    get isAnchorActive(): boolean {
        for (let i = 0; i < this.children.length; i++) {
            if ((this.children[i] as (ArrangementObject | ArrangementGroup)).isAnchorActive) {
                return true;
            }
        }

        return false;
    }

    set isAnchorActive(a: boolean) {
        for (let i = 0; i < this.children.length; i++) {
            (this.children[i] as (ArrangementObject | ArrangementGroup)).isAnchorActive = a;
        }
    }

    get anchor(): math.vec3 {
        let anchor = math.vec3.create();
        math.vec3.set(anchor, 0, 0, 0);

        let childAnchor;

        if (this.children.length > 0) {
            childAnchor = (this.children[0] as (ArrangementObject | ArrangementGroup)).anchor;
            if (childAnchor) {
                math.vec3.set(anchor, childAnchor[0], childAnchor[1], childAnchor[2]);
            }
        }

        for (let i = 1; i < this.children.length; i++) {
            childAnchor = (this.children[i] as (ArrangementObject | ArrangementGroup)).anchor;
            if (childAnchor) {
                if (anchor[0] !== childAnchor[0]) {
                    anchor[0] = 0;
                }
                if (anchor[1] !== childAnchor[1]) {
                    anchor[1] = 0;
                }
                if (anchor[2] !== childAnchor[2]) {
                    anchor[2] = -1;
                    break;
                }
            }
        }

        return anchor;
    }

    /**
     *
     * Update floorHeight using anchor values depending on other objects above or underneath
     *
     * @param {Room} room
     */
    updateFloorHeightFromAnchor(room: Room, floor: Floor) {
        // Upper (anchor[2] === -1) or lower (anchor[2] === 1) entity underneath (anchor[2] === -1) or above (anchor[2] === 1) the arrangementObject being moved
        let hoveredEntity = null;

        // Does the object have an active anchor (does it anchor to something)
        if (this.isAnchorActive) {
            // Height where to anchor
            var baseValue = 0;

            // Does the arrangement object stacks on or under other arrangement objects
            if (this.stackable) {
                // Retrieve list of arrangement objecst collidable
                let list = floor.getChildren(
                    [
                        SceneConstants.EntityType.ArrangementObject,
                        /*SceneConstants.EntityType.ArrangementGroup,*/
                        SceneConstants.EntityType.WorkTop
                    ],
                    [SceneConstants.EntityType.Room, SceneConstants.EntityType.ArrangementGroup]
                ) as Array<ArrangementObject | WorkTop>;

                // Find object that are under entity and keep only the one with the max height
                for (var i = 0; i < list.length; i++) {
                    // Anchoring to floor look for higher object
                    if (this.anchor[2] === -1) {
                        if (list[i].id !== this.id && list[i].contains(this.position) && (hoveredEntity === null || hoveredEntity.maxHeight < list[i].maxHeight) && list[i].anchor[2] === this.anchor[2]) {
                            hoveredEntity = list[i];
                        }
                    }

                    // Anchoring to ceiling look for lower object
                    if (this.anchor[2] >= 1) {
                        if (list[i].id !== this.id && list[i].contains(this.position) && (hoveredEntity === null || hoveredEntity.maxHeight > list[i].maxHeight) && list[i].anchor[2] === this.anchor[2]) {
                            hoveredEntity = list[i];
                        }
                    }
                }

                // Any hovered object at the position where the object is right now ?
                if (hoveredEntity !== null && !this.isInGroup(this.children, hoveredEntity.id)) {
                    // Yes, get the maxHeight of this object
                    baseValue = hoveredEntity.getHeightAtPosition(this.position) - hoveredEntity.floor.transform.globalPosition[2];

                    // If we anchor from the top, we have to subtract the the object height to reach the point below the hovered object
                    if (this.anchor[2] >= 1) {
                        baseValue -= hoveredEntity.height;
                    }

                    // Check if the hoveredObject has a collidable zone and retrieve the height from this collidable zone
                    let zones = this.children;
                    let zone = null;
                    for (let i = 0; i < zones.length; i++) {
                        if (zones[i].isArrangementZoneEntity() && ((zones[i] as ArrangementZone).zoneType === SceneConstants.ArrangementZoneType.housing || (zones[i] as ArrangementZone).zoneType === SceneConstants.ArrangementZoneType.housable)) {
                            zone = zones[i];
                            break;
                        }
                    }

                    // Any zone found ?
                    if (zone !== null) {
                        // We anchor to bottom then we get the bottom of the collision zone
                        if (this.anchor[2] === -1) {
                            baseValue += zone.localPosition[1] - zone.height;
                        } else {
                            // We anchor to top then we get the top of the collision zone
                            if (this.anchor[2] >= 1) {
                                baseValue += zone.localPosition[1];
                            }
                        }
                    }
                } else {
                    hoveredEntity = null;
                    if (room !== null) {
                        baseValue = room.floorHeight;
                    }
                }
            } else {
                if (room !== null) {
                    baseValue = room.floorHeight;
                }
            }

            // Anchored to floor ?
            if (this.anchor[2] === -1) {
                // Place the object
                this.floorHeight = baseValue;
            } else {
                // Anchored to ceiling
                if (this.anchor[2] >= 1) {
                    let height;

                    // Anchored underneath lower object if any
                    if (hoveredEntity !== null) {
                        height = baseValue;
                    } else {
                        // Anchored to room height o to 0 if no room
                        if (room === null) {
                            height = 0;
                        } else {
                            height = room.roomHeight;
                            if (this.parent && this.parent.isArrangementGroupEntity()) {
                                height -= room.floorHeight;
                            }
                        }
                    }

                    // Finally set floor height
                    this.floorHeight = height * (this.anchor[2] > 0 ? 1 : -1) - this.height;
                }
            }
        }

        //this.updateChildrenFloorHeightCeilingAnchor(room, floor, this.children);
    }

    updateChildrenFloorHeightCeilingAnchor(room: Room, floor: Floor, children: Array<Entity>) {
        for (var i = 0; i < children.length; ++i) {
            var child = children[i];
            // snap to ceiling
            if (child.isArrangementObjectEntity() && (child as ArrangementObject).anchor[2] >= 1) {
                (child as ArrangementObject).updateFloorHeightFromAnchor(room, floor);
            }
            this.updateChildrenFloorHeightCeilingAnchor(room, floor, child.children);
        }
    }

    /**
     * add an arrangement to the arrangementGroup
     *
     * @param {IArrangement} arrangement
     */
    addArrangement(arr: (ArrangementObject | ArrangementGroup)) {
        let position = math.vec3.clone(arr.position);
        this.addChild(arr);
        arr.position = position;
    }

    /**
     * delete an arrangement of the arrangementGroup
     *
     * @param {Number} arrangementId
     */
    deleteArrangement(entityId: number) {
        for (let i = 0; i < this.children.length; i++) {
            if (this.children[i].id === entityId) {
                this.deleteChild(entityId);
                return true;
            }
        }
        return false;
    }

    isInGroup(children: Array<Entity>, entityId: Number): boolean {
        let result = false;
        for (let i = 0; i < children.length; i++) {
            if (children[i].id === entityId) {
                return true;
            }

            if (children[i].isArrangementGroupEntity()) {
                result = this.isInGroup(this.children[i].children, entityId);
            }
        }

        return result;
    }

    /**
     * delete child (override to update value)
     */
    deleteChild(entityId: number) {
        super.deleteChild(entityId);
        this._length = null;
        this._width = null;
        this._height = null;
        this.recomputeRelated();
    }

    /**
     * Add child (override to update value)
     *
     */
    addChild(e: Entity) {
        super.addChild(e);
        this._length = null;
        this._width = null;
        this._height = null;
        this.recomputeRelated();
    }

    /**
     * Setter for the holdout of the entity
     *
     * @param {Boolean} holdout
     */
    set holdout(h: boolean) {
        this._holdout = h;
        for (let i = 0; i < this.children.length; i++) {
            this.children[i].holdout = h;
        }
    }

    get arrangementObjectsRec(): Array<ArrangementObject> {
        let arrangements = [];
        for (let i = 0; i < this.children.length; i++) {
            if (this.children[i].isArrangementObjectEntity()) {
                arrangements.push(this.children[i]);
            } else if (this.children[i].isArrangementGroupEntity()) {
                arrangements = arrangements.concat((this.children[i] as ArrangementGroup).arrangementObjectsRec);
            }
        }
        return arrangements;
    }

    /**
     * Return list of all the object in the arrangementGroup (not in groups child)
     *
     * @returns {Array}
     */
    get arrangementObjects(): Array<ArrangementObject> {
        let arrangements = [];
        for (let i = 0; i < this.children.length; i++) {
            if (this.children[i].isArrangementObjectEntity()) {
                arrangements.push(this.children[i]);
            }
        }
        return arrangements;
    }

    /**
     * Return list of all the object in the arrangementGroup
     *
     * @returns {Array}
     */
    get arrangementGroups(): Array<ArrangementGroup> {
        let arrangements = [];
        for (let i = 0; i < this.children.length; i++) {
            if (this.children[i].isArrangementGroupEntity()) {
                arrangements.push(this.children[i]);
                arrangements = arrangements.concat((this.children[i] as ArrangementGroup).arrangementGroups);
            }
        }
        return arrangements;
    }

    /**
     * return the list of all IArrangement  in the arrangementGroup
     *
     * @returns {Array}
     */
    get iArrangements(): Array<(ArrangementObject | ArrangementGroup)> {
        let arrangements = [];
        for (let i = 0; i < this.children.length; i++) {
            if (this.children[i].isArrangementObjectEntity() || this.children[i].isArrangementGroupEntity()) {
                arrangements.push(this.children[i]);
            }
        }
        return arrangements;
    }

    /**
     * Get the length of the bounding box
     *
     * @returns {Number}
     */
    get length(): number {
        let arrangements = this.iArrangements;

        if (arrangements.length === 0) {
            return 1;
        } else if (this._length !== null) {
            return this._length;
        } else {
            let toLocal = this.transform.invertedGlobalMatrix;
            let minX = Number.POSITIVE_INFINITY,
                maxX = Number.NEGATIVE_INFINITY;

            for (let i = 0; i < arrangements.length; i++) {
                let boundingBox = arrangements[i].boundingBox;

                // convert global bbox to local
                for (let j = 0; j < boundingBox.length; ++j) {
                    math.vec3.transformMat4(boundingBox[j], boundingBox[j], toLocal);
                }

                if (boundingBox !== null) {
                    for (let j = 0; j < boundingBox.length; j++) {
                        minX = Math.min(minX, boundingBox[j][0]);
                        maxX = Math.max(maxX, boundingBox[j][0]);
                    }
                } else {
                    let position = arrangements[i].position;
                    minX = Math.min(minX, position[0]);
                    maxX = Math.max(maxX, position[0]);
                }
            }

            this._length = maxX - minX;
            return this._length;
        }
    }

    /**
     * Get the width of the bounding box
     *
     * @returns {Number}
     */
    get width(): number {
        let arrangements = this.iArrangements;

        if (arrangements.length === 0) {
            return 1;
        } else if (this._width !== null) {
            return this._width;
        } else {
            let toLocal = this.transform.invertedGlobalMatrix;
            let minY = Number.POSITIVE_INFINITY,
                maxY = Number.NEGATIVE_INFINITY;

            for (let i = 0; i < arrangements.length; i++) {
                let boundingBox = arrangements[i].boundingBox;

                // convert global bbox to local
                for (let j = 0; j < boundingBox.length; ++j) {
                    math.vec3.transformMat4(boundingBox[j], boundingBox[j], toLocal);
                }

                if (boundingBox !== null) {
                    for (let j = 0; j < boundingBox.length; j++) {
                        minY = Math.min(minY, boundingBox[j][1]);
                        maxY = Math.max(maxY, boundingBox[j][1]);
                    }
                } else {
                    let position = arrangements[i].position;
                    minY = Math.min(minY, position[0]);
                    maxY = Math.max(maxY, position[0]);
                }
            }
            this._width = maxY - minY;
            return this._width;
        }
    }

    /**
     * Get the max height of the arrangementGroup
     *
     * @returns {Number}
     */
    get height(): number {
        let arrangements = this.iArrangements;

        if (arrangements.length === 0) {
            return 1;
        } else if (this._height !== null) {
            return this._height;
        } else {
            let minZ = arrangements[0].position[2] - arrangements[0].height / 2;
            let maxZ = arrangements[0].position[2] - arrangements[0].height / 2;
            for (let i = 0; i < arrangements.length; i++) {
                minZ = Math.min(minZ, arrangements[i].position[2] - arrangements[i].height / 2);
                maxZ = Math.max(maxZ, arrangements[i].position[2] + arrangements[i].height / 2);
            }
            this._height = maxZ - minZ;
            return this._height;
        }
    }

    get realHeight(): number {
        return 0;
    }

    get center(): math.vec3 {
        let iArrangement = this.iArrangements;
        let iArrangementLength = iArrangement.length;

        if (iArrangementLength === 0) {
            return this.position;
        } else {
            let minX = Number.POSITIVE_INFINITY;
            let maxX = Number.NEGATIVE_INFINITY;
            let minY = Number.POSITIVE_INFINITY;
            let maxY = Number.NEGATIVE_INFINITY;
            let minZ = Number.POSITIVE_INFINITY;
            let maxZ = Number.NEGATIVE_INFINITY;

            let boundingBox, position;
            for (let i = 0; i < iArrangementLength; i++) {
                boundingBox = iArrangement[i].boundingBox;
                if (boundingBox !== null) {
                    for (let j = 0; j < boundingBox.length; j++) {
                        minX = Math.min(minX, boundingBox[j][0]);
                        maxX = Math.max(maxX, boundingBox[j][0]);
                        minY = Math.min(minY, boundingBox[j][1]);
                        maxY = Math.max(maxY, boundingBox[j][1]);
                    }
                } else {
                    position = iArrangement[i].position;
                    minX = Math.min(minX, position[0]);
                    maxX = Math.max(maxX, position[0]);
                    minY = Math.min(minY, position[1]);
                    maxY = Math.max(maxY, position[1]);
                }
                minZ = Math.min(minZ, iArrangement[i].position[2] - iArrangement[i].height / 2);
                maxZ = Math.max(maxZ, iArrangement[i].position[2] + iArrangement[i].height / 2);
            }

            let center = math.vec3.create();
            math.vec3.set(center, maxX - (maxX - minX) / 2, maxY - (maxY - minY) / 2, maxZ - (maxZ - minZ) / 2);
            return center;
        }
    }

    /**
     * set position = center
     *
     */
    recenter() {
        let matrices = [];
        let arrangements = this.iArrangements;
        for (let i = 0; i < arrangements.length; i++) {
            if (arrangements[i].isArrangementGroupEntity()) arrangements[i].recenter();
            matrices.push(math.mat4.clone(arrangements[i].transform.globalMatrix));
        }

        this.position = this.center;
        let toLocal = math.mat4.create();
        math.mat4.invert(toLocal, this.transform.globalMatrix);

        for (let i = 0; i < arrangements.length; i++) {
            let matrix = math.mat4.create();
            math.mat4.multiply(matrix, toLocal, matrices[i]);
            math.mat4.copy(arrangements[i].transform.localMatrix, matrix);
        }

        this._height = null;
        this._width = null;
        this._length = null;
    }

    /**
     * Get bounding box
     *
     * @returns {Array | null}
     */
    get boundingBox(): Array<math.vec3> {
        return this.getHooverBox(0);
    }

    /**
     * get 3D bounding box in local coordinate
     */
    get rawBoundingBox(): Array<math.vec3> {
        let points = [];
        let point1 = math.vec3.create();
        let point2 = math.vec3.create();
        let point3 = math.vec3.create();
        let point4 = math.vec3.create();
        let point5 = math.vec3.create();
        let point6 = math.vec3.create();
        let point7 = math.vec3.create();
        let point8 = math.vec3.create();

        math.vec3.set(point1, this.length / 2, -this.width / 2, -this.height / 2);
        math.vec3.set(point2, this.length / 2, this.width / 2, -this.height / 2);
        math.vec3.set(point3, -this.length / 2, this.width / 2, -this.height / 2);
        math.vec3.set(point4, -this.length / 2, -this.width / 2, -this.height / 2);

        points.push(point1);
        points.push(point2);
        points.push(point3);
        points.push(point4);

        math.vec3.set(point5, point1[0], point1[1], point1[2] + this.height);
        math.vec3.set(point6, point2[0], point2[1], point2[2] + this.height);
        math.vec3.set(point7, point3[0], point3[1], point3[2] + this.height);
        math.vec3.set(point8, point4[0], point4[1], point4[2] + this.height);

        points.push(point5);
        points.push(point6);
        points.push(point7);
        points.push(point8);
        return points;
    }

    /**
     * get bounding box bigger of the precision
     * @param precision
     */
    getHooverBox(precision: number): Array<math.vec3> {
        let points = [];
        points.push(math.vec3.fromValues(this.length / 2 + precision, -this.width / 2 - precision, 0));
        points.push(math.vec3.fromValues(this.length / 2 + precision, this.width / 2 + precision, 0));
        points.push(math.vec3.fromValues(-this.length / 2 - precision, this.width / 2 + precision, 0));
        points.push(math.vec3.fromValues(-this.length / 2 - precision, -this.width / 2 - precision, 0));

        var q = math.quat.create();
        math.quat.setAxisAngle(q, math.vec3.fromValues(0, 0, 1), this.angle);
        var m = math.mat4.create();
        math.mat4.fromRotationTranslation(m, q, this.position);
        for (var i = 0; i < points.length; ++i) {
            math.vec3.transformMat4(points[i], points[i], m);
            points[i][2] = 0;
        }

        return points;
    }

    /**
     * Check if bounding box contains and one of child contains too
     *
     * @param point
     * @param precision
     * @param currentEntity
     */
    contains(p: math.vec3, precision: number, currentEntity: Entity): Entity {
        precision = precision ? precision : SavaneConstants.PositionTolerance;
        let bbox = this.getHooverBox(precision);
        if (bbox !== null && SavaneMath.isInPoly(p, bbox)) {
            for (let i = 0; i < this.children.length; i++) {
                if ((this.children[i] as (ArrangementObject | ArrangementGroup)).contains(p, precision, currentEntity)) {
                    if (currentEntity && currentEntity !== null && currentEntity.parent.id === this.id) {
                        return this.children[i]; //If current entity is already selected allow to return subentity
                    } else {
                        return this;
                    }
                }
            }
        }
        if (bbox === null) {
            console.error("Empty group : " + this.id);
        }
        return null;
    }

    get maxHeight(): number {
        let maxVal = 0;
        for (let i = 0; i < this.children.length; i++) {
            if (this.children[i].maxHeight > maxVal) {
                maxVal = this.children[i].maxHeight;
            }
        }
        return maxVal;
    }

    get minHeight(): number {
        if (this.children.length === 0) {
            return 0;
        }

        let minVal = (this.children[0] as (ArrangementObject | ArrangementGroup)).minHeight;
        for (let i = 1; i < this.children.length; i++) {
            let minHeight = (this.children[i] as (ArrangementObject | ArrangementGroup)).minHeight;

            if (minHeight < minVal) {
                minVal = minHeight;
            }
        }
        return minVal;
    }

    get localMinHeight(): number {
        if (this.children.length === 0) {
            return 0;
        }

        let minVal = (this.children[0] as (ArrangementObject | ArrangementGroup)).localMinHeight;
        for (let i = 1; i < this.children.length; i++) {
            let minHeight = (this.children[i] as (ArrangementObject | ArrangementGroup)).localMinHeight;

            if (minHeight < minVal) {
                minVal = minHeight;
            }
        }
        return minVal;
    }

    /**
     * Test whether the wall cross the arrangementGroup
     * @param wall
     */
    crossWall(wall: Wall) {
        for (let i = 0; i < this.iArrangements.length; i++) {
            if (this.iArrangements[i].crossWall(wall)) {
                return true;
            }
        }
        return false;
    }

    set floorHeight(fh: number) {
        var newPos = math.vec3.create();
        math.vec3.set(newPos, this.localPosition[0], this.localPosition[1], fh + this.height * 0.5);
        this.localPosition = newPos;
    }

    get floorHeight(): number {
        return this.localPosition[2] - this.height * 0.5;
    }

    /**
     * Create the temporaryArrangementGroup that will be used during edition
     *
     */
    startTemporary() {
        this.temporary = new TemporaryArrangementGroup(this);
        this._length = null;
        this._width = null;
        this._height = null;
        this.recomputeRelated();
    }

    /**
     * delete the temporaryArrangementGroup
     *
     */
    endTemporary() {
        this.temporary = null;
        this._length = null;
        this._width = null;
        this._height = null;
        this.recomputeRelated();
    }

    /**
     * save the temporary data in the arrangementGroup and delete the temporaryArrangementGroup
     *
     */
    saveAndEndTemporary() {
        let positionTemp = this.position;
        let angleTemp = this.angle;
        this.temporary = null;
        this.position = positionTemp;

        //this.setRotationZ(angleTemp, false);
        this.transform.globalZRotation = angleTemp;

        this._length = null;
        this._width = null;
        this._height = null;
        this.recomputeRelated();
    }
}
