import { ComponentConstants, Floor, math } from '../SavaneJS';
import { Component } from './Component';

export class DepthBoundingBox extends Component {
    constructor() {
        super();
    }

    clone(): DepthBoundingBox {
        return new DepthBoundingBox();
    }

    get componentType(): ComponentConstants.ComponentType {
        return ComponentConstants.ComponentType.DepthBoundingBox;
    }

    get center2d(): math.vec2 {
        let center3 = this.center3d;
        let center2 = math.vec2.create();
        math.vec2.set(center2, center3[0], center3[1]);
        return center2;
    }

    get center3d(): math.vec3 {
        return this.box3d[0];
    }

    get box3d(): any {
        if (this.entity.isFloorEntity()) {
            let walls = (this.entity as Floor).walls;
            let topLeft = math.vec3.create();
            let botRight = math.vec3.create();
            let maxHeight = 0;
            if (walls.length > 0) {
                topLeft[0] = Math.min(walls[0].begin[0], walls[0].end[0]);
                botRight[0] = Math.max(walls[0].begin[0], walls[0].end[0]);
                topLeft[1] = Math.min(walls[0].begin[1], walls[0].end[1]);
                botRight[1] = Math.max(walls[0].begin[1], walls[0].end[1]);
                maxHeight = walls[0].height;
                for (let i = 0; i < walls.length; i++) {
                    maxHeight = Math.max(maxHeight, walls[i].height);
                    topLeft[0] = Math.min(topLeft[0], walls[i].end[0]);
                    botRight[0] = Math.max(botRight[0], walls[i].end[0]);
                    topLeft[1] = Math.min(topLeft[1], walls[i].end[1]);
                    botRight[1] = Math.max(botRight[1], walls[i].end[1]);
                    topLeft[0] = Math.min(topLeft[0], walls[i].begin[0]);
                    botRight[0] = Math.max(botRight[0], walls[i].begin[0]);
                    topLeft[1] = Math.min(topLeft[1], walls[i].begin[1]);
                    botRight[1] = Math.max(botRight[1], walls[i].begin[1]);
                }
            }

            let xLength = Math.abs(topLeft[0] - botRight[0]);
            let yLength = Math.abs(topLeft[1] - botRight[1]);
            let center = math.vec3.create();
            math.vec3.set(center, (topLeft[0] + botRight[0]) / 2, (topLeft[1] - botRight[1]) / 2, maxHeight / 2);
            return [center, xLength, yLength, maxHeight];
        }
        return null;
    }

    get box2d(): math.vec2 {
        let box3 = this.box3d;
        box3.splice(3, 1);
        return box3;
    }

    get xLength(): number {
        return this.box3d[1];
    }

    get yLength(): number {
        return this.box3d[2];
    }

    get zLength(): number {
        return this.box3d[3];
    }

    get topLeft2d(): math.vec2 {
        let topLeft3 = this.topLeft3d;
        let topLeft2 = math.vec2.create();
        math.vec2.set(topLeft2, topLeft3[0], topLeft3[1]);
        return topLeft2;
    }

    get bottomRight2d(): math.vec2 {
        let bottomRight3 = this.bottomRight3d;
        let bottomRight2 = math.vec2.create();
        math.vec2.set(bottomRight2, bottomRight3[0], bottomRight3[1]);
        return bottomRight2;
    }

    get topLeft3d(): math.vec3 {
        let box3 = this.box3d;
        let topLeft = math.vec3.create();
        math.vec3.set(topLeft, box3[0] - (box3[1] / 2), box3[0] + (box3[2] / 2), box3[0] + (box3[3] / 2));
        return topLeft;
    }

    get bottomRight3d(): math.vec3 {
        let box3 = this.box3d;
        let bottomRight = math.vec3.create();
        math.vec3.set(bottomRight, box3[0] - (box3[1] / 2), box3[0] + (box3[2] / 2), box3[0] + (box3[3] / 2));
        return bottomRight;
    }
}
