import { ComponentConstants } from '../SavaneJS';
import { Component } from './Component';

export class JoineryType extends Component {
    protected _joineryTypeId: string;
    protected _manufacturer: any;
    protected _retailer: any;

    constructor(idam: string, manufacturer: any, retailer: any) {
        super();
        this._joineryTypeId = idam;
        this._manufacturer = manufacturer;
        this._retailer = retailer;
        this._isUnique = true;
    }

    clone(): JoineryType {
        return new JoineryType(this._joineryTypeId, this._manufacturer, this._retailer);
    }

    get componentType(): ComponentConstants.ComponentType {
        return ComponentConstants.ComponentType.JoineryType;
    }

    get joineryTypeId(): string {
        return this._joineryTypeId;
    }

    set joineryTypeId(amid: string) {
        this._joineryTypeId = amid;
    }

    get manufacturer(): any {
        return this._manufacturer;
    }

    set manufacturer(manuf: any) {
        if (manuf) {
            this._manufacturer = {_id: manuf._id, name: manuf.name};
        }
        else {
            this._manufacturer = undefined;
        }
    }

    get retailer(): any {
        return this._retailer;
    }

    set retailer(retail: any) {
        if (retail) {
            this._retailer = {_id: retail._id, name: retail.name};
        }
        else {
            this._retailer = undefined;
        }
    }
}
