import { Command, CommandEnum } from './CommandModule';
import { Events } from '../events';
import { eventsManager } from '../managers/EventsManager';
import { Entity, Floor, math } from '../SavaneJS';

export class SymetryFloorCommand extends Command
{
    // Symetry axis stored as a string
    private _axis: string;
    // Floor symetry is applied to
    private _floor: Floor;

    constructor(axis, floor) {
        super();
        // Store symetry axis
        this._axis = axis
        // Store symetry floor
        this._floor = floor;
    };

    // Command name returned from a global enum
    name(): string {
        return CommandEnum.SymetryFloorCommand;
    }

    // Datas that allows the command to be executed
    execDatas(): any {
        return {
            axis: this._axis,
            floor: this._floor
        };
    }

    // Undo the current command
    undo() {
        // Apply symetry again that will cancel the original one from the execute method
        this.applySymetry();

        super.undo();

        eventsManager.instance.dispatch(Events.PROJECT_RELOAD);
    }

    // Execute current command (redo), call the applySymetry method
    execute() {
        this.applySymetry();

        super.execute();

        eventsManager.instance.dispatch(Events.PROJECT_RELOAD);
    }

    // Method that does the symetry the entire floor
    applySymetry() {
        // Loop variable
        let i: number;
        // Floor bounding box to recenter all entities
        let floorBBox = this._floor.boundingBox;
        // Center of the bounding box
        let center = math.vec3.create();

        // Retrieve center of bounding box
        math.vec3.set(center,
            floorBBox[0][0] + (floorBBox[3][0] - floorBBox[0][0]) * 0.5,
            floorBBox[0][1] + (floorBBox[3][1] - floorBBox[0][1]) * 0.5,
            floorBBox[0][2] + (floorBBox[3][2] - floorBBox[0][2]) * 0.5);

        // Symetry walls
        for (i = 0; i < this._floor.walls.length; i++) {
            // Current wall to scale
            let wall = this._floor.walls[i];
            // Begin and end coordinates
            let begin = wall.begin;
            let end = wall.end;
            // Find dist from center to begin
            let distanceBegin = math.vec3.create();

            math.vec3.set(distanceBegin, begin[0] - center[0], begin[1] - center[1], begin[2] - center[2]);
            // Find dist from center to end
            let distanceEnd = math.vec3.create();
            math.vec3.set(distanceEnd, end[0] - center[0], end[1] - center[1], end[2] - center[2]);

            // Apply the symetry
            if (this._axis === 'vertical') {
                distanceBegin[0] = -distanceBegin[0];
                distanceEnd[0] = -distanceEnd[0];
            }
            else {
                distanceBegin[1] = -distanceBegin[1];
                distanceEnd[1] = -distanceEnd[1];
            }

            // Apply dist to create new begin and end
            let newBegin = math.vec3.create();
            math.vec3.add(newBegin, center, distanceBegin);
            let newEnd = math.vec3.create();
            math.vec3.add(newEnd, center, distanceEnd);

            // Set new value for point of wall
            wall.begin = newBegin;
            wall.end = newEnd;

            // Redraw Node
            eventsManager.instance.dispatch(Events.REDRAW_GRAPHICAL_ENTITY,
                {
                    entity: wall
                });

            // Redraw joineries
            for (let j = 0; j < wall.joineries.length; j++) {
              wall.joineries[j].orientation = !wall.joineries[j].orientation;
              // Redraw Node
              eventsManager.instance.dispatch(Events.REDRAW_GRAPHICAL_ENTITY,
                  {
                      entity: wall.joineries[j]
                  });
            }
        }

        // Redraw rooms
        for (i = 0; i < this._floor.rooms.length; i++) {
            // GameObjectManager.drawRoom(this.floor.rooms[i], false, null, true);
            eventsManager.instance.dispatch(Events.REDRAW_GRAPHICAL_ROOM,
                {
                    entity: this._floor.rooms[i]
                });
        }
        // Symetry tech elements from center
        for (i = 0; i < this._floor.technicalElementsWithStaircases.length; i++) {
            this.symetryEntity(this._floor.technicalElementsWithStaircases[i], center);
        }
        // Symetry arrangementObjects
        for (i = 0; i < this._floor.arrangementObjects.length; i++) {
            if (!this._floor.arrangementObjects[i].parent.isArrangementObjectEntity() && !this._floor.arrangementObjects[i].parent.isArrangementGroupEntity() && !this._floor.arrangementObjects[i].parent.isArrangementZoneEntity()) {
                this.symetryEntity(this._floor.arrangementObjects[i], center);
            }
        }
        // Symetry arrangementGroups
        for (i = 0; i < this._floor.arrangementGroups.length; i++) {
            if (!this._floor.arrangementGroups[i].parent.isArrangementObjectEntity() && !this._floor.arrangementGroups[i].parent.isArrangementGroupEntity() && !this._floor.arrangementGroups[i].parent.isArrangementZoneEntity()) {
                this.symetryEntity(this._floor.arrangementGroups[i], center);
            }
        }
        // Symetry renderCameras
        for (i = 0; i < this._floor.renderCameras.length; i++) {
            this.symetryEntity(this._floor.renderCameras[i], center);
        }
        // Symetry geometryPrimitives
        for (i = 0; i < this._floor.geometryPrimitives.length; i++) {
            this.symetryEntity(this._floor.geometryPrimitives[i], center);
        }
    }

    // Perform the symetry of an entity thanks to the floor center
    symetryEntity(entity: Entity, center: math.vec3) {
        // Entity position from center
        let positionEntity = entity.position;
        // Find dist from center to tntity
        let distance = math.vec3.create();
        math.vec3.set(distance, positionEntity[0] - center[0], positionEntity[1] - center[1], positionEntity[2] - center[2]);

        // Apply the symetry
        if (this._axis === 'vertical') {
            distance[0] = -distance[0];
            // Adjust entity angle correctly
            entity.angle = -entity.angle;
        }
        else {
            distance[1] = -distance[1];
            // Adjust entity angle correctly
            entity.angle = Math.PI - entity.angle;
        }

        // Apply dist to create new position
        let newPosition = math.vec3.create();
        math.vec3.add(newPosition, center, distance);
        // Set new value for entity position
        entity.position = newPosition;

        // Redraw Node
        eventsManager.instance.dispatch(Events.REDRAW_GRAPHICAL_ENTITY,
            {
                entity: entity
            });
    }
};
