import {TemporaryStaircase} from './TemporaryStaircase';

export class TemporaryDoubleQuarterStaircase extends TemporaryStaircase
{
    public stepWidth: number;
    public hasBearing: boolean;
    public modifiedWidth: number;

    constructor(techElement) {
        // Call mother class
        super(techElement);
        // And save step width class member in addition to all standard members of staircases
        this.stepWidth = techElement._stepWidth;
        // And save bearing
        this.hasBearing = techElement._hasBearing;
        // And save last length
        this.modifiedWidth = techElement._modifiedWidth;
    }
}
