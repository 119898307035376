import { Command, CommandEnum } from './CommandModule';
import { Events } from '../events';
import { eventsManager } from '../managers/EventsManager';
import { EntityFactory, TechnicalElement, WaterSource, math } from '../SavaneJS';

export class EditTechnicalElementCommand extends Command
{
    private _modifiedTechElement: TechnicalElement;
    private _currentTechElement: TechnicalElement;

    // Constructor takes current tech element (with temporary) as parameter
    constructor(currentTechElement: TechnicalElement) {
        super();
        // Clone current tech element so it copies the temporary fields into the cloned tech element entity
        this._modifiedTechElement = EntityFactory.cloneEntity(currentTechElement, false) as TechnicalElement;
        // End the tech element temporary so it drops the temporary data
        currentTechElement.endTemporary();
        // Save the current tech element for future data exchange
        this._currentTechElement = currentTechElement;
    }

    // Command name returned from a global enum
    name(): string {
        return CommandEnum.EditTechnicalElementCommand;
    }

    execDatas(): any {
        return {
            id: this._currentTechElement.id,
            type: this._currentTechElement.entityType
        };
    }

    // Undo the current command
    undo() {
        // Execute the current command, the savedTech structure keeps the changes back and forth
        this.execute();
        super.undo();
    }

    // Execute current command (redo)
    execute() {
        // Get its position and exchange it with the saveTech one
        let tmpLocalMatrix = math.mat4.create();
        math.mat4.copy(tmpLocalMatrix, this._currentTechElement.transform.localMatrix);

        // Get its length and exchange it with the saveTech one
        let tmpLength = this._currentTechElement.length;
        this._currentTechElement.length = this._modifiedTechElement.length;
        this._modifiedTechElement.length = tmpLength;

        // Get its height and exchange it with the saveTech one
        let tmpHeight = this._currentTechElement.height;
        this._currentTechElement.height = this._modifiedTechElement.height;
        this._modifiedTechElement.height = tmpHeight;

        // Get its materialType and exchange it with the saveTech one
        let tmpMaterialType = this._currentTechElement.materialType;
        this._currentTechElement.materialType = this._modifiedTechElement.materialType;
        this._modifiedTechElement.materialType = tmpMaterialType;

        // Get its width and exchange it with the saveTech one
        let tmpWidth = this._currentTechElement.width;
        this._currentTechElement.width = this._modifiedTechElement.width;
        this._modifiedTechElement.width = tmpWidth;

        // Get its rotation and exchange it with the saveTech one
        math.mat4.copy(this._currentTechElement.transform.localMatrix, this._modifiedTechElement.transform.localMatrix);
        math.mat4.copy(this._modifiedTechElement.transform.localMatrix, tmpLocalMatrix);

        // Get its shape type and exchange it with the saveTech one
        let tmpShape = this._currentTechElement.shapeType;
        this._currentTechElement.shapeType = this._modifiedTechElement.shapeType;
        this._modifiedTechElement.shapeType = tmpShape;

        // Management of spotLight intensity
        if (this._currentTechElement.lightOn !== undefined) {
            let tmpLightOn = this._currentTechElement.lightOn;
            this._currentTechElement.lightOn = this._modifiedTechElement.lightOn;
            this._modifiedTechElement.lightOn = tmpLightOn;
        }

        if (this._currentTechElement.lightOff !== undefined) {
            let tmpLightOff = this._currentTechElement.lightOff;
            this._currentTechElement.lightOff = this._modifiedTechElement.lightOff;
            this._modifiedTechElement.lightOff = tmpLightOff;
        }

        if (this._currentTechElement.temperature !== undefined) {
            let tmpTemperature = this._currentTechElement.temperature;
            this._currentTechElement.temperature = this._modifiedTechElement.temperature;
            this._modifiedTechElement.temperature = tmpTemperature;
        }

        if (this._currentTechElement.lightColor !== undefined) {
            let tmpLightColor = this._currentTechElement.lightColor;
            this._currentTechElement.lightColor = this._modifiedTechElement.lightColor;
            this._modifiedTechElement.lightColor = tmpLightColor;
        }

        // Management of spotLight intensity
        if (this._currentTechElement.intensity !== undefined) {
            let tmpIntensity = this._currentTechElement.intensity;
            this._currentTechElement.intensity = this._modifiedTechElement.intensity;
            this._modifiedTechElement.intensity = tmpIntensity;
        }

        // Management of water source specific fields
        if ((this._currentTechElement as any).isIn !== undefined) {
            let tmpIsIn = (this._currentTechElement as any).isIn;
            (this._currentTechElement as any).isIn = (this._modifiedTechElement as any).isIn;
            (this._modifiedTechElement as any).isIn = tmpIsIn;
        }

        if ((this._currentTechElement as any).isOut !== undefined) {
            let tmpIsOut = (this._currentTechElement as any).isOut;
            (this._currentTechElement as any).isOut = (this._modifiedTechElement as any).isOut;
            (this._modifiedTechElement as any).isOut = tmpIsOut;
        }

        if (this._currentTechElement.symmetry !== undefined) {
            let tmpSymmetry = this._currentTechElement.symmetry;
            this._currentTechElement.symmetry = this._modifiedTechElement.symmetry;
            this._modifiedTechElement.symmetry = tmpSymmetry;
        }

        super.execute();

        // Select object at the end
        eventsManager.instance.dispatch(Events.CHANGE_EDITOR_SELECTION,
            {
                selection: [this._currentTechElement],
                keepSelected: false,
                showTulip: false
            });
    }
};
