import { ComponentConstants } from '../SavaneJS';
import { Component } from './Component';

export class Functionality extends Component {
    private _functionalityId: ComponentConstants.Functionalities;
    private _functionalityName: string;
    public _data: any;

    constructor(id: number, name: string, data: any) {
        super();

        this._functionalityId = id;
        this._functionalityName = name;
        this._isUnique = true;

        if (data !== null) {
            this._data = data;
        } else {
            this.initData(id);
        }
    }

    //GETTERS & SETTERS
    get componentType(): ComponentConstants.ComponentType {
        return ComponentConstants.ComponentType.Functionality;
    }

    set functionalityId(f: ComponentConstants.Functionalities) {
        this._functionalityId = f;
        this.initData(f);
    }

    get functionalityId(): ComponentConstants.Functionalities {
        return (this._functionalityId);
    }

    /**
     *  Set func name
     *
     * @param {String} value
     */
    set functionalityName(n: string) {
        this._functionalityName = n;
    }

    get rawFunctionalityName(): string {
        return(this._functionalityName);
    }

    /**
     * Generate name of this functionality
     *
     * @returns {String}
     */
    get functionalityName(): string {
        if (this._functionalityId === null && this._functionalityName === null) {
            return '';
        }
        let idFunc = 0;
        let totalFunc = 0;
        var scene = this._entity.scene;
        for (let j = 0; scene && j < scene.floors.length; ++j) {
            let rooms = scene.floors[j].rooms;
            for (let i = 0; i < rooms.length; i++) {
                let room = rooms[i];
                let roomFunctionalities = room.getComponent(ComponentConstants.ComponentType.Functionality) as Functionality;
                if (roomFunctionalities._functionalityId === this._functionalityId && roomFunctionalities._functionalityName === this._functionalityName) {
                    totalFunc++;
                }
                if (room.id === this._entity.id) {
                    idFunc = totalFunc;
                }
            }
        }
        if (totalFunc <= 1) {
            return this._functionalityName;
        } else {
            return this._functionalityName + ' ' + idFunc;
        }
    }

    isValid(): boolean {
        return this._functionalityId !== null;
    }

    setData(field: string, value: any) {
        this._data[field] = value;
    }

    initData(newFunction: ComponentConstants.Functionalities) {
        this._data = {};

        switch (newFunction) {
            case ComponentConstants.Functionalities.Livingroom:
                this.setData('wantTV', true);
                this.setData('sofaType', Functionality.SofaType.undetermined);
                this.setData('sofaNbSeats', 3);
                this.setData('banLeather', false);
                this.setData('banLin', false);
                this.setData('banVelor', false);
                this.setData('banCloth', false);
                break;

            case ComponentConstants.Functionalities.Diningroom:
                this.setData('expendableTable', false);
                this.setData('tableNbSeats', 6);
                this.setData('bannedTableMaterialType', Functionality.TableBannedMaterialType.undetermined);
                break;

            case ComponentConstants.Functionalities.Kitchen:
                this.setData('island', false);
                this.setData('kitchenShape', Functionality.KitchenShape.lshape);
                this.setData('mealZone', Functionality.MealZone.none);
                this.setData('doorType', Functionality.KitchenDoorType.lisse);
                break;

            case ComponentConstants.Functionalities.Library:
                this.setData('libraryType', Functionality.LibraryType.undetermined);
                break;

            case ComponentConstants.Functionalities.Office:
                this.setData('deskType', Functionality.DeskType.undetermined);
                break;
        }
    }

    clone(): Functionality {
        let func = new Functionality(this._functionalityId, this._functionalityName, this._data);

        return func;
    }
}

/**
 * Enum for functionnality parameters
 *
 *
 */
export namespace Functionality {
    export enum DeskType {
        undetermined = 0,
        withFeet = 1,
        withStorage = 2,
        withLotOfStorage = 3
    };

    export enum LibraryType {
        undetermined = 0,
        opened = 1,
        semiOpened = 2,
        closed = 3
    };

    export enum SofaType {
        undetermined = 0,
        convertible = 1,
        meridian = 2,
        straight = 3
    };

    export enum TableBannedMaterialType {
        undetermined = 0,
        wood = 1,
        glass = 2
    };

    export enum KitchenShape {
        undetermined = 0,
        lshape = 1,
        ushape = 2,
        doubleline = 3,
        line = 4
    };

    export enum MealZone {
        undetermined = 0,
        none = 1,
        meal = 2,
        bar = 3
    };

    export enum KitchenDoorType {
        lisse = 0,
        cadre = 1,
        gorge = 2,
        rainure = 3
    };
}
