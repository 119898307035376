import { SavaneCookie } from '../../utils/SavaneCookie';
import { TemporaryJoinery } from './../temporary/TemporaryJoinery';
import { JoineryType, ComponentConstants, SceneConstants, math } from '../../SavaneJS';
import { Joinery } from './Joinery';

/**
 * Joinery  of type : FrenchDoor
 */
export class FrenchDoor extends Joinery
{
    // Default nbDoors for a door is 2
    private _nbDoors: number = 2;
    // Default mode params
    private _handleSide: SceneConstants.HandleSide = SceneConstants.HandleSide.handle_center;
    public openingSymetry: SceneConstants.Symetry = SceneConstants.Symetry.default;
    // Default door isn't opened
    private _isOpened: boolean = false;
    private _leftOpeningAngle: number = 90;
    private _rightOpeningAngle: number = 90;

    constructor(joineryType: SceneConstants.JoineryType, position: math.vec3) {
        super(joineryType, position);

        // Adjust joinery 3D model thanks to the parameters set above
        this.setJoineryType();
    }

    // Getters and setters for number of doors
    get nbDoors(): number {
        if (this.temporary === null) {
            return this._nbDoors;
        }
        else {
            return (this.temporary as TemporaryJoinery).nbDoors;
        }
    }

    set nbDoors(d: number) {
        // Boolean to detect if a change is performed to update 3D model only if a change is performed
        let change = false;

        if (d > SceneConstants.MaxDoorJoineryDefault) {
            throw(new Error('Door count can\'t be more than {SceneConstants.MaxDoorJoineryDefault}:' + SceneConstants.MaxDoorJoineryDefault));
        }

        if (this.temporary === null) {
            if (this._nbDoors !== d) {
                this._nbDoors = d;
                change = true;
            }
        }
        else
        {
            if ((this.temporary as TemporaryJoinery).nbDoors !== d) {
                (this.temporary as TemporaryJoinery).nbDoors = d;
                change = true;
            }
        }

        if (d > 1) {
            this.handleSide = SceneConstants.HandleSide.handle_center; //Handle side forced for {SceneConstants.MaxDoorJoineryDefault} doors
        }
        else {
            if (this.handleSide === SceneConstants.HandleSide.handle_center) {
                this.handleSide = SceneConstants.HandleSide.handle_left; //Handle side forced for {SceneConstants.MaxDoorJoineryDefault} doors
            }
        }

        // Update 3D model only if a change is really performed
        if (change) {
            // Set default 3D model after this change
            this.setJoineryType();
        }
    }

    // Getters and setters for the opening side
    get handleSide(): SceneConstants.HandleSide {
        if (this.temporary === null) {
            return this._handleSide;
        }
        else {
            return((this.temporary as TemporaryJoinery).handleSide);
        }
    }

    set handleSide(hs: SceneConstants.HandleSide) {
        if (this.nbDoors === 1) {
            if (this.temporary === null) {
                this._handleSide          = hs;
            }
            else {
                (this.temporary as TemporaryJoinery).handleSide = hs;
            }
        }
        else {
            if (hs !== SceneConstants.HandleSide.handle_center) {
                // More than two doors handle side can't be changed
                throw(new Error('HandleSide is forced to handle_center with more than {SceneConstants.MaxDoorJoineryDefault}:' + SceneConstants.MaxDoorJoineryDefault + ' door on default opening mode'));
            }
            else {
                if (this.temporary === null) {
                    this._handleSide = hs;
                }
                else {
                    (this.temporary as TemporaryJoinery).handleSide = hs;
                }
            }
        }
    }

    /**
     * Set the correct AM joinery Type
     */
    setJoineryType() {
        // Get all JoineryType entity components
        let componentList = this.getComponents(ComponentConstants.ComponentType.JoineryType);

        // Is there any existing JoineryType component ?
        if (componentList.length > 0) {
            // Yes ! There is only one joinery type component for a joinery so it will be index 0 for sure

            // Single door model
            if (this.nbDoors === 1) {
                (componentList[0] as JoineryType).joineryTypeId = SavaneCookie.getCookie("Rhinov-Savane-DefaultJoineryModelFrenchWindow", SceneConstants.DefaultJoineryType.frenchWindow);
            }
            else {
                // Double door model
                (componentList[0] as JoineryType).joineryTypeId = SavaneCookie.getCookie("Rhinov-Savane-DefaultJoineryModelDoubleFrenchWindow", SceneConstants.DefaultJoineryType.doubleFrenchWindow);
            }
        }
        else {
            // Single door model
            if (this.nbDoors === 1) {
                this.addComponent(new JoineryType(SavaneCookie.getCookie("Rhinov-Savane-DefaultJoineryModelFrenchWindow", SceneConstants.DefaultJoineryType.frenchWindow), undefined, undefined));
            }
            else {
                // Double door model
                this.addComponent(new JoineryType(SavaneCookie.getCookie("Rhinov-Savane-DefaultJoineryModelDoubleFrenchWindow", SceneConstants.DefaultJoineryType.doubleFrenchWindow), undefined, undefined));
            }
        }
    }

    // Getters and setters for isOpened
    get isOpened(): boolean {
        if (this.temporary === null) {
            return this._isOpened;
        }
        else {
            return (this.temporary as TemporaryJoinery).isOpened;
        }
    }

    set isOpened(o:boolean)
    {
        if (this.temporary === null) {
            this._isOpened = o;
        }
        else {
            (this.temporary as TemporaryJoinery).isOpened = o;
        }
    }

    get leftOpeningAngle(): number {
        if (this.temporary === null) {
            return this._leftOpeningAngle;
        }
        else {
            return (this.temporary as TemporaryJoinery).leftOpeningAngle;
        }
    }

    set leftOpeningAngle(a: number) {
        if (this.temporary === null) {
            this._leftOpeningAngle = a;
        }
        else {
            (this.temporary as TemporaryJoinery).leftOpeningAngle = a;
        }
    }

    get rightOpeningAngle(): number {
        if (this.temporary === null) {
            return this._rightOpeningAngle;
        }
        else {
            return (this.temporary as TemporaryJoinery).rightOpeningAngle;
        }
    }

    set rightOpeningAngle(a: number) {
        if (this.temporary === null) {
            this._rightOpeningAngle = a;
        }
        else {
            (this.temporary as TemporaryJoinery).rightOpeningAngle = a;
        }
    }

    // Called when a property is changed to verify max and min boundaries or to perform computations before final assignment
    addToCappedProperty(property: string, value: any) : number {
        // -1 = unknwon property, 0 = no cap, 1 = minus cap, 2 max cap wil be returned at the end
        let capped = 0;

        if (value === 0) {
            return(capped);
        }

        if (property === "nbDoors") {
            if (this[property] + value <= 1) {
                this[property] = 1;
                capped = 1;
            }

            if (this[property] + value >= SceneConstants.MaxDoorJoineryDefault) {
                this[property] = SceneConstants.MaxDoorJoineryDefault;
                capped = 2;
            }

            if (capped === 0) {
                this[property] += value;
            }
        }
        else {
            return super.addToCappedProperty(property, value);
        }
        return capped;
    }
}
