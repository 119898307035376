import { ComponentConstants } from '../SavaneJS';
import { Component } from './Component';

export class WallQuotation extends Component {
    public wallCoveringDirect: string;
    public wallCoveringUndirect: string;
    public wallRepairDirect: boolean;
    public wallRepairUndirect: boolean;

    constructor() {
        super();
        this.wallCoveringDirect = "1";
        this.wallCoveringUndirect = "1";
        this.wallRepairDirect = true;
        this.wallRepairUndirect = true;
        this._isUnique = true;
    }

    get componentType(): ComponentConstants.ComponentType {
        return ComponentConstants.ComponentType.WallQuotation;
    }

    clone(): WallQuotation {
        let wallQuotation = new WallQuotation();
        wallQuotation.wallCoveringDirect = this.wallCoveringDirect;
        wallQuotation.wallCoveringUndirect = this.wallCoveringUndirect;
        wallQuotation.wallRepairDirect = this.wallRepairDirect;
        wallQuotation.wallRepairUndirect = this.wallRepairUndirect;
        return wallQuotation;
    }
}
