import { Command, CommandEnum } from './CommandModule';
import { Events } from '../events';
import { eventsManager } from '../managers/EventsManager';
import { Floor, TechnicalElement } from '../SavaneJS';

export class DeleteTechnicalElementCommand extends Command
{
    // To store deleted technical element
    private _deletedTechnicalElement: TechnicalElement;
    // Deleted technical element floor
    private _deletedTechnicalElementFloor: Floor;

    constructor(technicalElement: TechnicalElement) {
        super();
        this._deletedTechnicalElement = technicalElement;
        this._deletedTechnicalElementFloor = technicalElement.floor;
    };

    // Command name returned from a global enum
    name(): string {
        return CommandEnum.DeleteTechnicalElementCommand;
    }

    // Datas that allows the command to be executed
    execDatas(): any {
        return {
            id: this._deletedTechnicalElement.id,
            type: this._deletedTechnicalElement.entityType,
            entity: this._deletedTechnicalElement
        };
    }

    // Undo the current command
    undo() {
        // Add the technical element saved in the constructor to the plan manager
        this._deletedTechnicalElementFloor.addTechnicalElement(this._deletedTechnicalElement);
        eventsManager.instance.dispatch(Events.ADD_GRAPHICAL_ENTITY,
            {
                entity: this._deletedTechnicalElement,
            });

        // Execute parent function
        super.undo();

        eventsManager.instance.dispatch(Events.CHANGE_EDITOR_SELECTION,
            {
                selection: [this._deletedTechnicalElement],
                keepSelected: false,
                showTulip: false
            });
    }

    // Execute current command (redo)
    execute() {
        // Remove the technical element from the plan manager
        eventsManager.instance.dispatch(Events.REMOVE_GRAPHICAL_ENTITY,
            {
                entity: this._deletedTechnicalElement,
            });
        this._deletedTechnicalElementFloor.deleteTechnicalElement(this._deletedTechnicalElement.id);

        // Execute parent function
        super.execute();

        eventsManager.instance.dispatch(Events.CHANGE_EDITOR_SELECTION,
            {
                selection: [],
                keepSelected: false,
                showTulip: false
            });
    }
};
