import { Floor, GeometryPrimitive, Box, Cylinder, HalfArc, Hood, Oval, Sphere, Triangle, SceneConstants, Transform } from '../../SavaneJS';
import { TemporaryEntity } from './TemporaryEntity';

export class TemporaryGeometryPrimitive extends TemporaryEntity {
    // Parameters of all geometry primitives
    public length: number;
    public width: number;
    public diameter: number;
    public height: number;
    public topLength: number;
    public topWidth: number;
    public hideInAxo: boolean;
    public cutByJoineries: boolean;
    public shapeType: number;
    private _transform: Transform;

    // Constructor, save all fields of the geometry primitive into the temporary object
    constructor(geometryPrimitive: GeometryPrimitive) {
        super(geometryPrimitive);

        // Save geometryPrimitive parameters
        this.height = geometryPrimitive.height;
        this.hideInAxo = geometryPrimitive.hideInAxo;
        this.cutByJoineries = geometryPrimitive.cutByJoineries;

        switch(geometryPrimitive.primitiveType) {
            case SceneConstants.GeometryPrimitiveType.box:
                this.width = (geometryPrimitive as Box).width;
                this.length = (geometryPrimitive as Box).length;
                break;

            case SceneConstants.GeometryPrimitiveType.cylinder:
                this.diameter = (geometryPrimitive as Cylinder).diameter;
                break;

            case SceneConstants.GeometryPrimitiveType.halfarc:
                this.width = (geometryPrimitive as HalfArc).width;
                this.length = (geometryPrimitive as HalfArc).length;
                break;

            case SceneConstants.GeometryPrimitiveType.hood:
                this.width =(geometryPrimitive as Hood).width;
                this.length =(geometryPrimitive as Hood).length;
                this.topLength = (geometryPrimitive as Hood).topLength;
                this.topWidth = (geometryPrimitive as Hood).topWidth;
                this.shapeType = (geometryPrimitive as Hood).shapeType;
                break;

            case SceneConstants.GeometryPrimitiveType.oval:
                this.width = (geometryPrimitive as Oval).width;
                this.length = (geometryPrimitive as Oval).length;
                break;

            case SceneConstants.GeometryPrimitiveType.sphere:
                this.diameter = (geometryPrimitive as Sphere).diameter;
                break;

            case SceneConstants.GeometryPrimitiveType.triangle:
                this.width = (geometryPrimitive as Triangle).width;
                this.length = (geometryPrimitive as Triangle).length;
                break;
        }

        // Save the entity transformation too
        this._transform = new Transform(null);
        this._transform.clone(geometryPrimitive.transform);
    }

    get transform(): Transform {
        return this._transform;
    }

    computeValidity(floor: Floor) {
        this._isValid = true
    }
}
