import { Area, SceneConstants, Credence, Component, ComponentConstants, math, SavaneMath } from "../SavaneJS";
import { Entity } from "./Entity";

/**
 * WorkTop is an entity representing a work top. It must have a area component representing (at the bottom of the worktop)
 *
 * @constructor
 */
export class WorkTop extends Entity {
    public anchor: Array<number> = [0, 0, -1];
    public thickness: number;
    public legs: Array<any> = [];
    public credences: Array<any> = [];

    constructor(id: number, thickness: number) {
        super();
        this.id = id;
        this.thickness = thickness;
    }

    /**
     * Getter for the Entity type
     *
     */
    get entityType(): SceneConstants.EntityType {
        return SceneConstants.EntityType.WorkTop;
    }

    get localPosition(): math.vec3 {
        return this.transform.localPosition;
    }

    getCoatingQuantity(coating: Component): number {
        switch (coating.componentType) {
            case ComponentConstants.ComponentType.Credence:
                return this.credenceArea / (100 * 100 * 100);

            case ComponentConstants.ComponentType.Coating:
                return this.area / (100 * 100 * 100);
        }

        return 0;
    }

    /**
     * return the max height of the workTop
     * @returns {*}
     */
    get maxHeight(): number {
        return this.position[2] + this.thickness * 0.5;
    }
    get localMaxHeight(): number {
        return this.localPosition[2] + this.thickness * 0.5;
    }
    get maxHeightCm(): number {
        return (this.localPosition[2] + this.thickness * 0.5) / 10;
    }

    get credenceHeight(): number {
        let components = this.getComponents(ComponentConstants.ComponentType.Credence);

        if (components.length !== 0) {
            return (components[0] as Credence).height;
        } else {
            return undefined;
        }
    }
    get credenceHeightCm(): number {
        let components = this.getComponents(ComponentConstants.ComponentType.Credence);

        if (components.length !== 0) {
            return (components[0] as Credence).height / 10;
        } else {
            return undefined;
        }
    }

    set credenceHeight(h: number) {
        let components = this.getComponents(ComponentConstants.ComponentType.Credence);

        if (components.length !== 0) {
            (components[0] as Credence).height = h;
        }
    }
    set credenceHeightCm(h: number) {
        let components = this.getComponents(ComponentConstants.ComponentType.Credence);

        if (components.length !== 0) {
            (components[0] as Credence).height = h * 10;
        }
    }

    /**
     * set the max height of the workTop
     */
    set maxHeight(h: number) {
        let position = this.position;
        position[2] = h - this.thickness * 0.5;
        this.transform.globalPosition = position;
    }

    set maxHeightCm(h: number) {
        let position = this.localPosition;
        position[2] = h * 10 - this.thickness * 0.5;
        this.transform.localPosition = position;
    }

    getHeightAtPosition(): number {
        return this.position[2] + this.thickness * 0.5;
    }

    set floorHeight(h: number) {
        let position = this.position;
        position[2] = h + this.thickness * 0.5;
        this.transform.globalPosition = position;
    }

    get area(): number {
        const area = this.getComponent(ComponentConstants.ComponentType.Area);
        return SavaneMath.getPolygonArea((area as Area).vertices);
    }

    get credenceArea(): number {
        let length = 0;
        let height = this.credenceHeight;
        if (height === undefined) {
            height = 0;
        }
        for (let i = 0; i < this.credences.length; ++i) {
            length += math.vec3.distance(this.credences[i].begin, this.credences[i].end);
        }
        return length * height;
    }

    /**
     * Check if the area contains
     *
     * @param point
     */
    contains(point: math.vec3) {
        let area = this.getComponent(ComponentConstants.ComponentType.Area);
        if (area !== null && SavaneMath.isInPoly(point, (area as Area).vertices)) {
            return this;
        }
        for (var i = 0; i < (area as Area).vertices.length; ++i) {
            let distance = math.vec3.distance(point, (area as Area).vertices[i]);
            if (distance < 50) {
                return this;
            }
        }
        return null;
    }

    addCredence(begin: math.vec3, end: math.vec3, height: number) {
        let vertex1 = math.vec3.clone(begin);
        let vertex2 = math.vec3.clone(end);

        let area = this.getComponent(ComponentConstants.ComponentType.Area);
        let areaBeginIndex = -1;
        let areaEndIndex = -1;

        if (area !== null) {
            for (let i = 0; i < (area as Area).vertices.length; i++) {
                if (SavaneMath.equal((area as Area).vertices[i], begin)) {
                    areaBeginIndex = i;
                }

                if (SavaneMath.equal((area as Area).vertices[i], end)) {
                    areaEndIndex = i;
                }
            }
        }

        this.credences.push({
            begin: vertex1,
            end: vertex2,
            areaBeginIndex: areaBeginIndex,
            areaEndIndex: areaEndIndex,
            height: height ? height : ComponentConstants.CredenceDefaultHeight,
        });
    }

    /**
     * delete leg to the work plan
     * @param index
     */
    deleteCredence(index: number) {
        this.credences.splice(index, 1);
        let components = this.getComponents(ComponentConstants.ComponentType.Credence);
        for (let i = 0; i < components.length; ++i) {
            if ((components[i] as Credence).credenceIndex === index) {
                this.removeComponent(components[i]);
            }
        }
    }

    /**
     * get worktop credence area size (mm²)
     * @param index
     */
    getCredenceAreaSize(): number {
        let components = this.getComponents(ComponentConstants.ComponentType.Credence);

        if (components.length === 0) {
            return 0;
        }

        let length = 0;

        for (let i = 0; i < this.credences.length; i++) {
            length += math.vec3.distance(this.credences[i].begin, this.credences[i].end);
        }

        return length * (components[0] as Credence).height;
    }

     /**
     * Add a leg to the work plan
     * @param begin Point of area
     * @param end Point of area
     * @param isUnder
     */
    addLeg(begin: math.vec3, end: math.vec3, isUnder:boolean) {
        let i;
        let area = this.getComponent(ComponentConstants.ComponentType.Area);
        let areaBeginIndex = -1;
        let areaEndIndex = -1;

        let vertices = (area as Area).vertices;

        let normal = math.vec3.create();
        let vertex1 = math.vec3.create();
        let vertex2 = math.vec3.create();

        if (area !== null) {
            for (i = 0; i < (area as Area).vertices.length; i++) {
                if (SavaneMath.equal((area as Area).vertices[i], begin)) {
                    areaBeginIndex = i;
                }

                if (SavaneMath.equal((area as Area).vertices[i], end)) {
                    areaEndIndex = i;
                }
            }
        }

        for (i = 0; i < vertices.length; i++) {
            if (SavaneMath.equal(vertices[i], begin)) {
                // warning only the if OR the else if if usefull cuz vertices are always oriented, but dunno which yet
                if (SavaneMath.equal(vertices[(i + 1) % vertices.length], end)) {
                    math.vec3.set(normal, vertices[i][1] - vertices[(i + 1) % vertices.length][1], vertices[(i + 1) % vertices.length][0] - vertices[i][0], 0);
                    if ((area as Area).needReverse) {
                        math.vec3.negate(normal, normal);
                    }
                    math.vec3.normalize(normal, normal);
                    math.vec3.scale(normal, normal, -this.thickness);

                    math.vec3.add(vertex1, vertices[i], normal);
                    math.vec3.add(vertex2, vertices[(i + 1) % vertices.length], normal);

                    this.setVertice(i, vertex1);
                    this.setVertice((i + 1) % vertices.length, vertex2);
                } else if (SavaneMath.equal(vertices[(i - 1 + vertices.length) % vertices.length], end)) {
                    math.vec3.set(normal, vertices[(i - 1 + vertices.length) % vertices.length][1] - vertices[i][1], vertices[i][0] - vertices[(i - 1 + vertices.length) % vertices.length][0], 0);
                    if ((area as Area).needReverse) {
                        math.vec3.negate(normal, normal);
                    }
                    math.vec3.normalize(normal, normal);
                    math.vec3.scale(normal, normal, -this.thickness);

                    math.vec3.add(vertex1, vertices[(i - 1 + vertices.length) % vertices.length], normal);
                    math.vec3.add(vertex2, vertices[i], normal);

                    this.setVertice((i - 1 + vertices.length) % vertices.length, vertex1);
                    this.setVertice(i, vertex2);
                }
            }
        }

        math.vec3.add(begin, begin, normal);
        math.vec3.add(end, end, normal);

        this.legs.push({
            begin: begin,
            end: end,
            areaBeginIndex: areaBeginIndex,
            areaEndIndex: areaEndIndex,
            isUnder: isUnder,
        });
    }

    /**
     * Load a leg to the work plan (doesn't move worktop area vertices
     * @param begin                [begin,end] are inclued in the zone area perimeter
     * @param end
     * @param isUnder
     */
    loadLeg(begin: math.vec3, end: math.vec3, isUnder: boolean) {
        this.legs.push({
            begin: begin,
            end: end,
            isUnder: isUnder,
        });
    }

    /**
     * delete leg to the work plan
     * @param index
     */
    deleteLeg(index: number) {
        let leg = this.legs[index];
        this.legs.splice(index, 1);

        let area = this.getComponent(ComponentConstants.ComponentType.Area);
        let vertices = (area as Area).vertices;
        let normal = math.vec3.create();
        let vertex1 = math.vec3.create();
        let vertex2 = math.vec3.create();
        let begin = leg.begin;
        let end = leg.end;

        for (let i = 0; i < vertices.length; i++) {
            if (SavaneMath.equal(vertices[i], begin)) {
                // warning only the if OR the else if if usefull cuz vertices are always oriented, but dunno which yet
                if (SavaneMath.equal(vertices[(i + 1) % vertices.length], end)) {
                    math.vec3.set(normal, vertices[i][1] - vertices[(i + 1) % vertices.length][1], vertices[(i + 1) % vertices.length][0] - vertices[i][0], 0);
                    if ((area as Area).needReverse) {
                        math.vec3.negate(normal, normal);
                    }
                    math.vec3.normalize(normal, normal);
                    math.vec3.scale(normal, normal, -this.thickness);

                    math.vec3.subtract(vertex1, vertices[i], normal);
                    math.vec3.subtract(vertex2, vertices[(i + 1) % vertices.length], normal);

                    this.setVertice(i, vertex1);
                    this.setVertice((i + 1) % vertices.length, vertex2);
                } else if (SavaneMath.equal(vertices[(i - 1 + vertices.length) % vertices.length], end)) {
                    math.vec3.set(normal, vertices[(i - 1 + vertices.length) % vertices.length][1] - vertices[i][1], vertices[i][0] - vertices[(i - 1 + vertices.length) % vertices.length][0], 0);
                    if ((area as Area).needReverse) {
                        math.vec3.negate(normal, normal);
                    }
                    math.vec3.normalize(normal, normal);
                    math.vec3.scale(normal, normal, -this.thickness);

                    math.vec3.subtract(vertex1, vertices[(i - 1 + vertices.length) % vertices.length], normal);
                    math.vec3.subtract(vertex2, vertices[i], normal);

                    this.setVertice((i - 1 + vertices.length) % vertices.length, vertex1);
                    this.setVertice(i, vertex2);
                }
            }
        }
    }

    /**
     * FIXME This method should be removed when a proper refacto is done on legs
     * Set a vertices value and the associated leg
     * @param {Number} index Id of the points in Area's vertices array
     * @param {math.vec3} value New value for the point
     * @return {boolean} Return true if the vertices value has been replaced
     */
    setVertice(index: number, v: math.vec3): boolean {
        let area = this.getComponent(ComponentConstants.ComponentType.Area);

        if (area !== null) {
            let point = (area as Area).vertices[index];
            if (point !== undefined) {
                let i;

                //Move all associated legs
                for (i = 0; i < this.legs.length; i++) {
                    if (this.legs[i].areaBeginIndex === index) {
                        this.legs[i].begin = math.vec3.clone(v);
                    } else {
                        if (this.legs[i].areaEndIndex === index) {
                            this.legs[i].end = math.vec3.clone(v);
                        }
                    }
                }

                for (i = 0; i < this.credences.length; i++) {
                    if (this.credences[i].areaBeginIndex === index) {
                        this.credences[i].begin = math.vec3.clone(v);
                    } else {
                        if (this.credences[i].areaEndIndex === index) {
                            this.credences[i].end = math.vec3.clone(v);
                        }
                    }
                }

                // Move point
                (area as Area).vertices[index] = v;
                return(true);
            }
        }
        return false;
    }
}
