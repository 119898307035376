import { Command, CommandEnum } from './CommandModule';
import { Events } from '../events';
import { eventsManager } from '../managers/EventsManager';
import { EntityFactory, RenderCamera, SceneConstants, roomManager, math } from '../SavaneJS';

export class EditRenderCameraCommand extends Command {
    // To store current and modified camera
    private _currentCamera: RenderCamera;
    private _modifiedCamera: RenderCamera;
    // Do we have to reparent the modified camera
    private _preventReparenting: boolean;
    // Array of other cameras
    private _otherCameras: Array<any> = null;

    // Constructor takes current light (with temporary) as parameter
    constructor(currentCamera: RenderCamera, preventReparenting: boolean) {
        super();
        // Clone current camera so it copies the temporary fields into the cloned camera entity
        this._modifiedCamera = EntityFactory.cloneEntity(currentCamera, false) as RenderCamera;
        // End the camera temporary so it drops the temporary data
        currentCamera.endTemporary();
        // Save the current camera for future data exchange
        this._currentCamera = currentCamera;
        // Other cameras to renumber during execute process
        this._otherCameras = null;
        this._preventReparenting = preventReparenting;
    }

    // Command name returned from a global enum
    name(): string {
        return CommandEnum.EditRenderCameraCommand;
    }

    // Datas that allows the command to be executed
    execDatas(): any {
        return {
            id: this._currentCamera.id,
            type: this._currentCamera.entityType,
            entity: this._currentCamera,
        };
    }

    // Undo the current command
    undo() {
        // Execute the current command, the camera structure keeps the changes back and forth
        this.execute();

        //Execute parent function
        super.undo();
    }

    parentCameraEntityToRoom(entity: RenderCamera) {
        // No parent, forget parenting since we won't be able to find the entity floor and scene
        if (!entity.parent) {
            return;
        }

        if (this._preventReparenting) {
            return;
        }

        let floor = entity.scene.currentFloor;
        if (entity.cameraType === SceneConstants.CameraType.PhotoRender || entity.cameraType === SceneConstants.CameraType.Axonomic) {
            floor = entity.floor;
        }
        let entityPosition = math.vec3.clone(entity.position);
        // Get room under the object position
        let parent: any = roomManager.getRoomAtPosition(entity.position, floor);
        // No room found attach to floor
        if (parent === null) {
            parent = floor;
        }
        // Remove from old parent
        entity.parent.deleteChild(entity.id);
        // Attach to new parent
        parent.addChild(entity);
        // Reposition entity
        entity.position = entityPosition;
    }

    renumberAllCameras(cameraNb: number) {
        // Get its iso parameter and exchange it with the savedCamera one
        this._currentCamera.cameraNb = this._modifiedCamera.cameraNb;
        this._modifiedCamera.cameraNb = cameraNb;

        if (this._currentCamera.cameraNb !== this._modifiedCamera.cameraNb) {
            if (this._otherCameras) {
                for (let i = 0; i < this._otherCameras.length; i++) {
                    let camera = this._currentCamera.world.getDeepChild(this._otherCameras[i].id) as RenderCamera;

                    if (camera) {
                        camera.cameraNb = this._otherCameras[i].cameraNb;
                    }
                }
                this._otherCameras = null;
            } else {
                let allCameras = this._currentCamera.world.renderCameras;
                let proceedRenumber = false;

                for (let i = 0; i < allCameras.length; i++) {
                    if (allCameras[i].id === this._currentCamera.id) {
                        continue;
                    }

                    if (allCameras[i].cameraNb === this._currentCamera.cameraNb && allCameras[i].cameraType === this._currentCamera.cameraType) {
                        proceedRenumber = true;
                        break;
                    }
                }

                if (proceedRenumber) {
                    this._otherCameras = [];

                    let finished = false;
                    let searchedCameraNb = this._currentCamera.cameraNb;
                    let avoidCameraId = this._currentCamera.id;

                    while (!finished) {
                        let i = 0;

                        for (; i < allCameras.length; i++) {
                            if (allCameras[i].id === avoidCameraId) {
                                continue;
                            }

                            if (allCameras[i].cameraNb === searchedCameraNb && allCameras[i].cameraType === this._currentCamera.cameraType) {
                                this._otherCameras.push({
                                    id: allCameras[i].id,
                                    cameraNb: allCameras[i].cameraNb,
                                });
                                allCameras[i].cameraNb++;
                                searchedCameraNb = allCameras[i].cameraNb;
                                avoidCameraId = allCameras[i].id;
                                break;
                            }
                        }

                        if (i === allCameras.length) {
                            finished = true;
                        }
                    }
                }
            }
        }
    }

    // Execute current command (redo)
    execute() {
        // Get all parameters
        let matrix = math.mat4.clone(this._currentCamera.transform.localMatrix);
        let camType = this._currentCamera.cameraType;
        let fov = this._currentCamera.fov;
        let iso = this._currentCamera.iso;
        let cameraNb = this._currentCamera.cameraNb;
        let quality = this._currentCamera.quality;
        let isFinalRender = this._currentCamera.isFinalRender;
        let renderW = this._currentCamera.renderWidth;
        let renderH = this._currentCamera.renderHeight;
        let shutterSpeed = this._currentCamera.shutterSpeed;
        let fNumber = this._currentCamera.fNumber;
        let denoising = this._currentCamera.denoising;
        let autoexposure = this._currentCamera.autoexposure;
        let whitebalance = this._currentCamera.whitebalance;
        let renderType = this._currentCamera.renderType;
        let hd = this._currentCamera.hd;
        let format = this._currentCamera.format;
        let excludedObjectIds = this._currentCamera.excludedObjectIds;
        let exposure = this._currentCamera.exposure;
        let verticalShift = this._currentCamera.verticalShift;
        let dof = this._currentCamera.dof;
        let aperture = this._currentCamera.aperture;
        let nbImages = this._currentCamera.nbImages;
        let sunOffsetRotation = this._currentCamera.sunOffsetRotation;
        let sunOffsetAltitude = this._currentCamera.sunOffsetAltitude;

        // Get its cameraType parameter and exchange it with the savedCamera one
        // Set directly to _cameraType field this is normal not to mess up other fields
        this._currentCamera.rawCameraType = this._modifiedCamera.cameraType;
        this._modifiedCamera.rawCameraType = camType;

        // Get its fov and exchange it with the savedCamera one
        this._currentCamera.fov = this._modifiedCamera.fov;
        this._modifiedCamera.fov = fov;

        // Get local matrix and exchange it with the savedCamera one
        math.mat4.copy(this._currentCamera.transform.localMatrix, this._modifiedCamera.transform.localMatrix);
        math.mat4.copy(this._modifiedCamera.transform.localMatrix, matrix);

        // Get its iso parameter and exchange it with the savedCamera one
        this._currentCamera.iso = this._modifiedCamera.iso;
        this._modifiedCamera.iso = iso;

        // Renumber modified camera and other cameras
        this.renumberAllCameras(cameraNb);

        // Get its quality parameter and exchange it with the savedCamera one
        this._currentCamera.quality = this._modifiedCamera.quality;
        this._modifiedCamera.quality = quality;

        // Get its finalRender parameter and exhange it with the savedCamera one
        this._currentCamera.isFinalRender = this._modifiedCamera.isFinalRender;
        this._modifiedCamera.isFinalRender = isFinalRender;

        // Get its renderWidth parameter and exchange it with the savedCamera one
        this._currentCamera.renderWidth = this._modifiedCamera.renderWidth;
        this._modifiedCamera.renderWidth = renderW;

        // Get its renderHeight parameter and exchange it with the savedCamera one
        this._currentCamera.renderHeight = this._modifiedCamera.renderHeight;
        this._modifiedCamera.renderHeight = renderH;

        // Get its shutterSpeed parameter and exchange it with the savedCamera one
        this._currentCamera.shutterSpeed = this._modifiedCamera.shutterSpeed;
        this._modifiedCamera.shutterSpeed = shutterSpeed;

        // Get its fNumber parameter and exchange it with the savedCamera one
        this._currentCamera.fNumber = this._modifiedCamera.fNumber;
        this._modifiedCamera.fNumber = fNumber;

        // Exchange denoising values
        this._currentCamera.denoising = this._modifiedCamera.denoising;
        this._modifiedCamera.denoising = denoising;

        // Exchange autoexposure values
        this._currentCamera.autoexposure = this._modifiedCamera.autoexposure;
        this._modifiedCamera.autoexposure = autoexposure;

        // Exchange whitebalance values
        this._currentCamera.whitebalance = this._modifiedCamera.whitebalance;
        this._modifiedCamera.whitebalance = whitebalance;

        // Exchange renderTypes
        this._currentCamera.renderType = this._modifiedCamera.renderType;
        this._modifiedCamera.renderType = renderType;

        // Exchange format
        this._currentCamera.format = this._modifiedCamera.format;
        this._modifiedCamera.format = format;

        // Exchange hd
        this._currentCamera.hd = this._modifiedCamera.hd;
        this._modifiedCamera.hd = hd;

        // Exchange excludedObjectIds arrays
        this._currentCamera.excludedObjectIds = this._modifiedCamera.excludedObjectIds;
        this._modifiedCamera.excludedObjectIds = excludedObjectIds;

        this._currentCamera.exposure = this._modifiedCamera.exposure;
        this._modifiedCamera.exposure = exposure;

        this._currentCamera.verticalShift = this._modifiedCamera.verticalShift;
        this._modifiedCamera.verticalShift = verticalShift;

        this._currentCamera.dof = this._modifiedCamera.dof;
        this._modifiedCamera.dof = dof;

        this._currentCamera.aperture = this._modifiedCamera.aperture;
        this._modifiedCamera.aperture = aperture;

        this._currentCamera.nbImages = this._modifiedCamera.nbImages;
        this._modifiedCamera.nbImages = nbImages;

        this._currentCamera.sunOffsetRotation = this._modifiedCamera.sunOffsetRotation;
        this._modifiedCamera.sunOffsetRotation = sunOffsetRotation;

        this._currentCamera.sunOffsetAltitude = this._modifiedCamera.sunOffsetAltitude;
        this._modifiedCamera.sunOffsetAltitude = sunOffsetAltitude;

        // Parent objet to a room if any
        this.parentCameraEntityToRoom(this._currentCamera);

        // Execute parent function
        super.execute();

        // Select object at the end
        eventsManager.instance.dispatch(Events.CHANGE_EDITOR_SELECTION, {
            selection: [this._currentCamera],
            keepSelected: false,
            showTulip: false,
        });
    }
}
