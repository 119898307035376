import * as glMatrix from 'gl-matrix'

export class Ray {

    private _O : glMatrix.vec3;
    private _D : glMatrix.vec3;

    constructor(O: glMatrix.vec3, D: glMatrix.vec3) {
        this._O = O;
        glMatrix.vec3.normalize(D, D);
        this._D = D;
    }

    get O() : glMatrix.vec3 {
        return this._O;
    }

    get D() : glMatrix.vec3 {
        return this._D;
    }

}