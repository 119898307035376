import { Command, CommandEnum, AddEntityCommand } from './CommandModule';
import { Events } from '../events';
import { eventsManager } from '../managers/EventsManager';

export class AddEntitiesCommand  extends Command
{
    // Command list to execute and undo
    private _subCommands: Array<Command> = [];
    // Array of structures containing entity to add and their parent
    private _addedEntitiesAndParents: any;
    // Selected entity at the end of the command
    private _selected: boolean;

    constructor(addedEntitiesAndParents: any, selected: boolean, preventHeightUpdate: boolean, needsRefreshHullOverride: boolean) {
        super();
        // Record the entity Array
        this._addedEntitiesAndParents = addedEntitiesAndParents;
        // Parse all entity array passed to the constructor
        for (let i = 0; i < addedEntitiesAndParents.length; i++) {
            // And create a delete entity command for each of them
            this._subCommands.push(new AddEntityCommand(addedEntitiesAndParents[i].entity, addedEntitiesAndParents[i].parent, false, preventHeightUpdate, needsRefreshHullOverride));
        }
        // Select entities at the end of the command ?
        this._selected = selected;
    }

    name(): string {
        return CommandEnum.AddEntitiesCommand;
    }

    // Datas that allows the command to be executed
    execDatas(): any {
        return {
            entitiesAndParents: this._addedEntitiesAndParents
        };
    }

    needsRefreshHull(): boolean {
        for (let i = 0; i < this._subCommands.length; i++) {
            if (this._subCommands[i].needsRefreshHull()) {
                return (true);
            }
        }

        return (false);
    }

    // Undo the current command
    undo() {
        // Parse all commands created by the constructor and undo them all
        for (let i = 0; i < this._subCommands.length; i++) {
            this._subCommands[i].undo();
        }

        //Execute parent function
        super.undo();
    }

    // Execute current command (redo)
    execute() {
        // Select new objects at the end
        let newEntitiesToSelect = [];

        // Parse all commands created by the constructor and execute them all
        for (let i = 0; i < this._subCommands.length; i++) {
            this._subCommands[i].execute();
        }

        // Parse all entities and try to recenter them as a post process of the entity addition
        for (let i = 0; i < this._addedEntitiesAndParents.length; i++) {
            let entity = this._addedEntitiesAndParents[i].entity;
            // Push the new entity to be selected at the end of the function
            newEntitiesToSelect.push(entity);
            // Recenter the entity
            entity.recenter();
        }

        //Execute parent function
        super.execute();

        // Select new created objects at the end
        if (this._selected) {
            eventsManager.instance.dispatch(Events.CHANGE_EDITOR_SELECTION,
                {
                    selection: newEntitiesToSelect,
                    keepSelected: false,
                    showTulip: false
                });
        }
    }
};
