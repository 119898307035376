import { Command, CommandEnum } from './CommandModule';
import { Events } from '../events';
import { eventsManager } from '../managers/EventsManager';
import { ComponentConstants, EntityFactory, Entity, Wall, SavaneConstants, wallManager } from '../SavaneJS';

export class DeleteWallCommand  extends Command
{
    private _wallsDeleted: Array<Wall>;
    private _rooms: Array<any> = [];
    private _selectedEntities: Array<Entity>;
    private _wallsToMerge: Array<any> = [];
    private _wallsMerged: Array<Wall> = [];
    
    constructor(wallsDeleted: Array<Wall>, selectedEntities: Array<Entity>) {
        super();
        this._wallsDeleted = wallsDeleted.slice();
        // Selected entities array
        this._selectedEntities = selectedEntities;

        let toTest;

        for (let i = 0; i < this._wallsDeleted.length; i++) {
            let rooms = [];
            for (let j = 0; j < this._wallsDeleted[i].rooms.length; ++j) {
                rooms.push(EntityFactory.cloneRoom(this._wallsDeleted[i].rooms[j], false, false));
            }
            this._rooms.push(rooms);
            let wallsBegin = wallManager.getWallsAtCorner(this._wallsDeleted[i].begin, this._wallsDeleted[i].floor, SavaneConstants.PositionTolerance);
            if (wallsBegin !== null && wallsBegin.length === 3) {
                toTest = true;
                for (let j = 0; j < this._wallsDeleted.length; j++) {
                    if (i !== j && (wallsBegin[0].id === this._wallsDeleted[j].id || wallsBegin[1].id === this._wallsDeleted[j].id || wallsBegin[2].id === this._wallsDeleted[j].id)) {
                        toTest = false;
                    }
                }

                if (toTest) {
                    if (wallsBegin[0].id === this._wallsDeleted[i].id) {
                        if (Wall.mergeWall(wallsBegin[1], wallsBegin[2]) !== null) {
                            this._wallsToMerge.push([wallsBegin[1], wallsBegin[2]]);
                            this._wallsMerged.push(wallManager.mergeWall(wallsBegin[1], wallsBegin[2]));
                            this._wallsMerged[this._wallsMerged.length - 1].id = wallsBegin[1].id;
                        }
                    }
                    else if (wallsBegin[1].id === this._wallsDeleted[i].id) {
                        if (Wall.mergeWall(wallsBegin[0], wallsBegin[2]) !== null) {
                            this._wallsToMerge.push([wallsBegin[0], wallsBegin[2]]);
                            this._wallsMerged.push(wallManager.mergeWall(wallsBegin[0], wallsBegin[2]));
                            this._wallsMerged[this._wallsMerged.length - 1].id = wallsBegin[0].id;
                        }
                    }
                    else {
                        if (Wall.mergeWall(wallsBegin[0], wallsBegin[1]) !== null) {
                            this._wallsToMerge.push([wallsBegin[0], wallsBegin[1]]);
                            this._wallsMerged.push(wallManager.mergeWall(wallsBegin[0], wallsBegin[1]));
                            this._wallsMerged[this._wallsMerged.length - 1].id = wallsBegin[0].id;
                        }
                    }
                }
            }
            let wallsEnd = wallManager.getWallsAtCorner(this._wallsDeleted[i].end, this._wallsDeleted[i].floor, SavaneConstants.PositionTolerance);
            if (wallsEnd !== null && wallsEnd.length === 3) {
                toTest = true;
                for (let j = 0; j < this._wallsDeleted.length; j++) {
                    if (i !== j && (wallsEnd[0].id === this._wallsDeleted[j].id || wallsEnd[1].id === this._wallsDeleted[j].id || wallsEnd[2].id === this._wallsDeleted[j].id)) {
                        toTest = false;
                    }
                }

                if (toTest) {
                    if (wallsEnd[0].id === this._wallsDeleted[i].id) {
                        if (Wall.mergeWall(wallsEnd[1], wallsEnd[2]) !== null) {
                            this._wallsToMerge.push([wallsEnd[1], wallsEnd[2]]);
                            this._wallsMerged.push(wallManager.mergeWall(wallsEnd[1], wallsEnd[2]));
                            this._wallsMerged[this._wallsMerged.length - 1].id = wallsEnd[1].id;
                        }
                    }
                    else if (wallsEnd[1].id === this._wallsDeleted[i].id) {
                        if (Wall.mergeWall(wallsEnd[0], wallsEnd[2]) !== null) {
                            this._wallsToMerge.push([wallsEnd[0], wallsEnd[2]]);
                            this._wallsMerged.push(wallManager.mergeWall(wallsEnd[0], wallsEnd[2]));
                            this._wallsMerged[this._wallsMerged.length - 1].id = wallsEnd[0].id;
                        }
                    }
                    else {
                        if (Wall.mergeWall(wallsEnd[0], wallsEnd[1]) !== null) {
                            this._wallsToMerge.push([wallsEnd[0], wallsEnd[1]]);
                            this._wallsMerged.push(wallManager.mergeWall(wallsEnd[0], wallsEnd[1]));
                            this._wallsMerged[this._wallsMerged.length - 1].id = wallsEnd[0].id;
                        }
                    }
                }
            }
        }

        //Sort merged wall
        if (this._wallsMerged.length > 1) {
            for (let i = 0; i < this._wallsMerged.length; i++) {
                if (this._wallsMerged[i].id == this._wallsToMerge[(i + 1) % this._wallsMerged.length][0].id) {
                    this._wallsToMerge[i].splice(0, 0, this._wallsToMerge[(i + 1) % this._wallsMerged.length][1]);
                    this._wallsMerged[i] = wallManager.mergeWallArray(this._wallsToMerge[i]);
                    this._wallsMerged[i].id = this._wallsToMerge[i][0].id;
                    this._wallsToMerge.splice((i + 1) % this._wallsMerged.length, 1);
                    this._wallsMerged.splice((i + 1) % this._wallsMerged.length, 1);
                } else if (this._wallsMerged[i].id == this._wallsToMerge[(i + 1) % this._wallsMerged.length][1].id) {
                    this._wallsToMerge[i].splice(0, 0, this._wallsToMerge[(i + 1) % this._wallsMerged.length][0]);
                    this._wallsMerged[i] = wallManager.mergeWallArray(this._wallsToMerge[i]);
                    this._wallsMerged[i].id = this._wallsToMerge[i][0].id;
                    this._wallsToMerge.splice((i + 1) % this._wallsMerged.length, 1);
                    this._wallsMerged.splice((i + 1) % this._wallsMerged.length, 1);
                }
            }
        }
    }

    name(): string {
        return CommandEnum.DeleteWallCommand;
    }

    undo() {
        let i, j;

        let roomsToUpdate = [];
        for (i = 0; i < this._wallsMerged.length; i++) {
            wallManager.cutWallForCommand(this._wallsMerged[i], this._wallsToMerge[i], this._wallsMerged[i].floor, this._selectedEntities);
        }
        for (i = 0; i < this._wallsDeleted.length; i++) {
            wallManager.addWallForCommand(this._wallsDeleted[i], true, this._wallsDeleted[i].floor, this._wallsDeleted[i].height);
            if (this._rooms[i].length === this._wallsDeleted[i].rooms.length) {
                for (j = 0; j < this._rooms[i].length; ++j) {
                    let oldFunctionality = this._rooms[i][j].getComponent(ComponentConstants.ComponentType.Functionality);
                    let functionality = this._wallsDeleted[i].rooms[j].getComponent(ComponentConstants.ComponentType.Functionality);
                    oldFunctionality.entity = this._wallsDeleted[i].rooms[j];
                    this._wallsDeleted[i].rooms[j].replaceComponent(functionality, oldFunctionality);
                    roomsToUpdate.push(this._wallsDeleted[i].rooms[j]);
                }
            }
        }

        for (i = 0; i < roomsToUpdate.length; i++) {
            eventsManager.instance.dispatch(Events.REDRAW_GRAPHICAL_ROOM, {
                entity: roomsToUpdate[i]
            });
        }

        //update walls extremities :
        for (j = 0; j < this._wallsDeleted.length; j++) {
            let walls = wallManager.getWallsAtCorner(this._wallsDeleted[j].begin, this._wallsDeleted[j].floor, SavaneConstants.PositionTolerance);
            if (walls !== null) {
                for (i = 0; i < walls.length; i++) {
                    eventsManager.instance.dispatch(Events.REDRAW_GRAPHICAL_WALL,
                        {
                            entity: walls[i]
                        });
                }
            }
            walls = wallManager.getWallsAtCorner(this._wallsDeleted[j].end, this._wallsDeleted[j].floor, SavaneConstants.PositionTolerance);
            if (walls !== null) {
                for (i = 0; i < walls.length; i++) {
                    eventsManager.instance.dispatch(Events.REDRAW_GRAPHICAL_WALL,
                        {
                            entity: walls[i]
                        });
                }
            }
        }

        // Select deleted object at the end
        eventsManager.instance.dispatch(Events.CHANGE_EDITOR_SELECTION,
            {
                selection: this._wallsDeleted,
                keepSelected: false,
                showTulip: false
            });
    }

    execute() {
        let i, j;

        // unselect deleted object
        eventsManager.instance.dispatch(Events.CHANGE_EDITOR_SELECTION,
            {
                selection: [],
                keepSelected: false,
                showTulip: false
            });

        for (i = 0; i < this._wallsDeleted.length; i++) {
            wallManager.deleteWallForCommand(this._wallsDeleted[i].id, true, this._wallsDeleted[i].floor, this._selectedEntities);
        }

        for (i = 0; i < this._wallsMerged.length; i++) {
            wallManager.mergeWallsForCommand(this._wallsMerged[i], this._wallsToMerge[i], this._wallsMerged[i].floor, this._selectedEntities);
        }

        //update walls extremities :
        for (j = 0; j < this._wallsDeleted.length; j++) {
            let walls = wallManager.getWallsAtPosition(this._wallsDeleted[j].begin, this._wallsDeleted[j].floor, SavaneConstants.PositionTolerance, undefined);
            if (walls !== null) {
                for (i = 0; i < walls.length; i++) {
                    eventsManager.instance.dispatch(Events.REDRAW_GRAPHICAL_WALL,
                        {
                            entity: walls[i]
                        });
                }
            }
            walls = wallManager.getWallsAtPosition(this._wallsDeleted[j].end, this._wallsDeleted[j].floor, SavaneConstants.PositionTolerance, undefined);
            if (walls !== null) {
                for (i = 0; i < walls.length; i++) {
                    eventsManager.instance.dispatch(Events.REDRAW_GRAPHICAL_WALL,
                        {
                            entity: walls[i]
                        });
                }
            }
        }
    }
}
