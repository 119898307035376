import { Entity } from "../scene/Entity";

export class Animation extends Entity
{
    constructor(id: number) {
        super();

        if (this.constructor === Animation) {
            throw new TypeError('Abstract class "Animation" cannot be instantiated directly.');
        }

        if (this.entityType === undefined) {
            throw new TypeError('Classes extending the Animation abstract class require the entityType property');
        }

        this.id = id;
    }
}