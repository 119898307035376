import { Entity } from "./Entity";

/**
* Layer is a set of entity group fro display or access purpose
* FIXME Handle of adding and removing is not automated yet
**/
export class Layer {
    // Layer name
    private _name: string;
    // Array of entities inside the layer
    private _entityList: Array<Entity> = [];

    constructor(name: string) {
        this._name = name;
    }

    get name():string {
        return this._name;
    }

    get count(): number {
        return this._entityList.length;
    }

    get list(): Array<Entity> {
        return this._entityList;
    }

    addEntity(entity: Entity) {
        this._entityList.push(entity);
    }

    hasEntity(id: number): boolean {
        for(let i = 0; i < this.count; i++) {
            if (this._entityList[i].id === id) {
                return true;
            }
        }
        return false;
    }

    getEntity(id: number): Entity {
        for(let i = 0; i < this.count; i++) {
            if(this._entityList[i].id === id) {
                return this._entityList[i];
            }
        }
        return null;
    }
}
