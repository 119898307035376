import { Events } from '../events';
import { eventsManager } from './EventsManager';
import { EntityFactory, Floor, Wall, Room, math, SavaneMath } from '../SavaneJS';

class RoomPath {

    public length: number = 0;
    public invalid: boolean = false;
    public walls: Array<Wall> = new Array<Wall>();
    public room: Room = new Room(undefined);

    constructor() {
    }

    add(wall: Wall) {
        this.length += wall.length;
        this.walls.push(wall);
        this.room.addWall(wall);
    }

    clone() : RoomPath {
        let result = new RoomPath();

        result.length = this.length;
        result.invalid = this.invalid;
        result.walls = this.walls.slice();

        return result;
    }

    get area(): number {
        let result = 0;
        let polygon = this._polygon();
        let triangles = SavaneMath.triangulate(polygon);
        for (let i = 0; i < triangles.length; ++i) {
            let v1 = math.vec3.fromValues(triangles[i][1][0] - triangles[i][0][0], triangles[i][1][1] - triangles[i][0][1], 0);
            let v2 = math.vec3.fromValues(triangles[i][2][0] - triangles[i][0][0], triangles[i][2][1] - triangles[i][0][1], 0);
            let c = math.vec3.create();
            math.vec3.cross(c, v1, v2);
            result += math.vec3.length(c) / 2;
        }
        return result;
    }

    get first() : Wall {
        return this.walls[0];
    }

    get last() : Wall {
        return this.walls[this.walls.length - 1];
    }

    get pLast() : Wall {
        return this.walls[this.walls.length - 2];
    }

    get isClosed() : boolean {
        return this.last.isCorner(this.first.end);
    }

    setWallVisited() : void {
        for (let i = 0; i < this.walls.length; ++i) {
            (this.walls[i] as any).$$visited = true;
        }
    }

    get hasLoop() : boolean {
        for (let i = 0; i < this.walls.length; ++i) {
            let current = this.walls[i];
            for (let j = 1; j < i - 1; ++j) {
                let previous = this.walls[j];
                if (current.isCorner(previous.end) || current.isCorner(previous.begin)) {
                    return true;
                }
            }
        }
        return false;
    }

    _polygon() {
        let result = [this.walls[0].end, this.walls[0].begin];
        for (let i = 1; i < this.walls.length; ++i) {
            let end = result[result.length - 1];
            let wall = this.walls[i];
            if (wall.isBegin(end)) {
                result.push(wall.end);
            } else {
                result.push(wall.begin);
            }
        }

        return result;
    }
}

class RoomManager {
    constructor() {
    }

    /**
     * Getter for the room at the position given in parameters
     *
     * @param {*} position
     * @param {Floor} floor
     * @returns {*}
     */
    getRoomAtPosition(position: math.vec3, floor: Floor) : Room | null {
        if (!position || !floor) return null;
        
        let currentFloorRooms = floor.rooms;
        let res = -1;
        for (let i = 0; i < currentFloorRooms.length; i++) {
            if (currentFloorRooms[i].isInRoom(position)) {
                if (res === -1) {
                    res = i;
                } else {
                    if (SavaneMath.isWallInRoom(currentFloorRooms[i].walls[0], currentFloorRooms[res])) {
                        res = i;
                    }
                }
            }
        }
        if (res !== -1) {
            return currentFloorRooms[res];
        } else {
            return null;
        }
    }

    /**
     * Try to create a room from the wall, if a new room is created , add it to the roomManager
     *
     * @param {Wall} wallEntity
     * @param {Floor} floor
     */
    tryCreatingRoom(wallEntity: Wall, floor: Floor, roomHeight: number, walls: Array<Wall>, fusion: boolean, isB2B?: boolean) : Room | null {
        let currentFloorRooms = floor.rooms;
        let rooms = this.createRoomsFromWall(wallEntity, floor, walls, fusion);
        if (rooms) {
            let room = rooms[0];

            room.height = roomHeight;
            if (isB2B !== undefined) {
                room.plinthsMaterialType = (isB2B ? 1 : 0);
            }
            for (let i = 0 ; i < room.walls.length ; i++) {
                if (room.height < room.walls[i].height) {
                    room.height = room.walls[i].height;
                }
            }

            // Tutorial start
            eventsManager.instance.dispatch(Events.PLAN_TUTO_START, 20);
            // End tutorial

            for (let i = 0; i < room.walls.length; i++) {
                for (let j = 0; j < currentFloorRooms.length; j++) {
                    currentFloorRooms[j].deleteNonRoomedWall(room.walls[i].id);
                }
            }
            return room;
        }
        return null;
    }

    /**
     * Check and take in charge room in the temp room
     *
     * @param {Room} tempRoom
     * @param {Floor} floor
     */
    insideRoomHandling(tempRoom: Room, floor: Floor) : void {
        let currentFloorRooms = floor.rooms;
        let hasChanged = true;
        while (hasChanged) {
            hasChanged = false;
            let changingRoomIndex = -1;
            for (let i = 0; i < currentFloorRooms.length; i++) {
                //Loop throught all rooms to check if there is common walls
                let nbSameWalls = 0;
                let isInTempRoom = true;
                for (let j = 0; j < currentFloorRooms[i].walls.length; j++) {
                    //A common walls if this room has been found
                    if (tempRoom.isWallInRoom(currentFloorRooms[i].walls[j].id)) {
                        nbSameWalls++;
                    }
                    if (!SavaneMath.isWallInRoom(currentFloorRooms[i].walls[j], tempRoom)) {
                        isInTempRoom = false;
                    }
                }

                if (isInTempRoom && nbSameWalls !== 0) {
                    //avoid special case (with several room where (isInTempRoom && nbSameWalls !== 0)is true)
                    let nbCommonPoint = 0;
                    for (let j = 0; j < tempRoom.walls.length; j++) {
                        for (let k = 0; k < currentFloorRooms[i].walls.length; k++) {
                            if (tempRoom.walls[j].isCorner(currentFloorRooms[i].walls[k].begin)) {
                                nbCommonPoint++;
                            }
                            if (tempRoom.walls[j].isCorner(currentFloorRooms[i].walls[k].end)) {
                                nbCommonPoint++;
                            }
                        }
                    }
                    if (nbCommonPoint === 4 * nbSameWalls + 4) {
                        changingRoomIndex = i;
                        break;
                    }
                }
            }
            if (changingRoomIndex !== -1) {
                hasChanged = true;
                let wallsToAdd = new Array<Wall>();

                for (let j = 0; j < currentFloorRooms[changingRoomIndex].walls.length; j++) {
                    let toAdd = true;
                    for (let k = 0; k < tempRoom.walls.length; k++) {
                        if (tempRoom.walls[k].id === currentFloorRooms[changingRoomIndex].walls[j].id) {
                            toAdd = false;
                            break;
                        }
                    }
                    if (toAdd) {
                        wallsToAdd.push(currentFloorRooms[changingRoomIndex].walls[j]);
                    } else {
                        tempRoom.deleteWall(currentFloorRooms[changingRoomIndex].walls[j].id);
                    }
                }

                let wallSorted = [];
                wallSorted.push(tempRoom.walls[0]);
                tempRoom.deleteWall(tempRoom.walls[0].id);
                while (wallsToAdd.length !== 0 || tempRoom.walls.length !== 0) {
                    // To avoid infinite loops
                    let watchDog = true;

                    for (let j = 0; j < wallsToAdd.length; j++) {
                        if (wallSorted[wallSorted.length - 1].isCorner(wallsToAdd[j].begin) ||
                            wallSorted[wallSorted.length - 1].isCorner(wallsToAdd[j].end)) {
                            wallSorted.push(wallsToAdd[j]);
                            wallsToAdd.splice(j, 1);
                            // Next wall found, no need for watchdog
                            watchDog = false;
                            break;
                        }
                    }

                    for (let j = 0; j < tempRoom.walls.length; j++) {
                        if (wallSorted[wallSorted.length - 1].isCorner(tempRoom.walls[j].begin) ||
                            wallSorted[wallSorted.length - 1].isCorner(tempRoom.walls[j].end)) {
                            wallSorted.push(tempRoom.walls[j]);
                            tempRoom.deleteWall(wallSorted[wallSorted.length - 1].id);
                            // Next wall found, no need for watchdog
                            watchDog = false;
                            break;
                        }
                    }

                    // If the watchdog is triggered this means we are in an infinite loop and that we have to get out of it
                    if (watchDog) {
                        // We will search for the closest wall point from the latest wall added to the wallSorted list
                        let wallToFindClosest = wallSorted[wallSorted.length - 1];
                        // Do as if it will be the 1st wall of the old room wall list
                        let closestWallFound = wallsToAdd[0];
                        let roomClosestWall = null;
                        let minDist = Math.min(
                            Math.min(math.vec3.distance(wallToFindClosest.begin, closestWallFound.begin), math.vec3.distance(wallToFindClosest.begin, closestWallFound.end)),
                            Math.min(math.vec3.distance(wallToFindClosest.end, closestWallFound.begin), math.vec3.distance(wallToFindClosest.end, closestWallFound.end)));
                            let dist;

                        // Parse walls to add to find the closest wall
                        for (let i = 1; i < wallsToAdd.length; i++) {
                            dist = Math.min(
                                Math.min(math.vec3.distance(wallToFindClosest.begin, wallsToAdd[i].begin), math.vec3.distance(wallToFindClosest.begin, wallsToAdd[i].end)),
                                Math.min(math.vec3.distance(wallToFindClosest.end, wallsToAdd[i].begin), math.vec3.distance(wallToFindClosest.end, wallsToAdd[i].end)));
                            if (dist < minDist) {
                                minDist = dist;
                                closestWallFound = wallsToAdd[i];
                            }
                        }
                        // Parse temporary walls to find the closest wall
                        for (let i = 1; i < tempRoom.walls.length; i++) {
                            dist = Math.min(
                                Math.min(math.vec3.distance(wallToFindClosest.begin, tempRoom.walls[i].begin), math.vec3.distance(wallToFindClosest.begin, tempRoom.walls[i].end)),
                                Math.min(math.vec3.distance(wallToFindClosest.end, tempRoom.walls[i].begin), math.vec3.distance(wallToFindClosest.end, tempRoom.walls[i].end)));
                            if (dist < minDist) {
                                minDist = dist;
                                closestWallFound = tempRoom.walls[i];
                                roomClosestWall = tempRoom;
                            }
                        }

                        // Push closest wall into the wallSorted list and continue the algorithm normally
                        wallSorted.push(closestWallFound);
                        if (roomClosestWall) {
                            roomClosestWall.deleteWall(closestWallFound.id);
                        }
                    }
                }

                for (let j = 0; j < wallSorted.length; j++) {
                    tempRoom.addWall(wallSorted[j]);
                }
            }
        }
    }

    /**
     * delete the non surrounding wall in all room of the designated floor
     *
     * @param {Number} wallId
     */
    deleteNonRoomedWall(wallId: number, floor: Floor) : Room | null {
        let currentFloorRooms = floor.rooms;
        for (let i = 0; i < currentFloorRooms.length; i++) {
            if (currentFloorRooms[i].deleteNonRoomedWall(wallId)) {
                return currentFloorRooms[i];
            }
        }
        return null;
    }

    /**
     * test whether non surrounding walls are in rooms. In case it is, add a non surrounding wall to the room
     *
     * @param {Floor} floor
     */
    manageNonRoomedWalls(floor: Floor) : void {
        let currentFloorRooms = floor.rooms;
        for (let i = 0; i < currentFloorRooms.length; ++i) {
            currentFloorRooms[i].deleteRoomedWallsFromNonRoomedWalls();
        }

        let nonRoomedWalls = floor.nonRoomedWalls;
        for (let i = 0; i < nonRoomedWalls.length; i++) {
            let res = -1;
            for (let j = 0; j < currentFloorRooms.length; j++) {
                if (SavaneMath.isWallInRoom(nonRoomedWalls[i], currentFloorRooms[j])) {
                    if (res === -1) {
                        res = j;
                    } else {
                        if (SavaneMath.isWallInRoom(currentFloorRooms[j].walls[0], currentFloorRooms[res])) {
                            res = j;
                        }
                    }
                }
            }
            if (res !== -1) {
                currentFloorRooms[res].addNonRoomedWall(nonRoomedWalls[i]);
            }
        }
    }

    /**
     * Split the room (room.id === splittedRoomId) by creating two new room from  room and the pointsToCompute array
     *
     * @param {Number} splittedRoomId
     * @param {Wall} wallEntity
     * @param {Floor} floor
     */
    splitRoom(splittedRoomId: number, wallEntity: Wall, floor: Floor) {
        let rooms = [];
        let oldRoom = floor.getRoom(splittedRoomId);
        rooms.push(oldRoom);
        let createdRooms = this.createRoomsFromWall(wallEntity, floor);
        if (!createdRooms) {
            floor.addRoom(oldRoom);
            return rooms;
        }
        if (createdRooms.length === 1) {
            // the room was not split
            floor.addRoom(oldRoom);
        }
        for (let i = 0; i < createdRooms.length; ++i) {
            let createdRoom = createdRooms[i];
            createdRoom.height = oldRoom.height;
            createdRoom.floorHeight = oldRoom.floorHeight;
            createdRoom.isOrdered = oldRoom.isOrdered;
            createdRoom.hasPlinths = oldRoom.hasPlinths;
            createdRoom.plinthsHeight = oldRoom.plinthsHeight;
            createdRoom.plinthsMaterialType = oldRoom.plinthsMaterialType;
            createdRoom.hasCornices = oldRoom.hasCornices;

            for (let j = 0; j < oldRoom.components.length; j++) {
                let cloneComponent = oldRoom.components[j].clone();
                createdRoom.addComponent(cloneComponent);
            }
        }
        rooms = rooms.concat(createdRooms);
        return rooms;
    }

    /**
     * Fuse two rooms by deleting the wall; of id === wallId
     *
     * @param {Room} room1
     * @param {Room} room2
     * @param {Floor} floor
     */
    fuseRoom(wallDeleted: Wall, room1: Room, room2: Room, floor: Floor) : Room | null {
        room1.setPrimaryFunctionality(null, null);
        room2.setPrimaryFunctionality(null, null);
        let walls = room1.walls.concat(room2.walls);
        walls = walls.filter(item => item.id !== wallDeleted.id);
        walls = walls.filter((item, index) => walls.indexOf(item) === index);
        let wallsToBeUsed = walls.slice();
        for (let i = 0; i < walls.length; ++i) {
            let createdRoom = this.tryCreatingRoom(walls[i], floor, room1.height, wallsToBeUsed, true);
            if (createdRoom) {
                createdRoom.floorHeight = room1.floorHeight;
                createdRoom.isOrdered = room1.isOrdered;
                createdRoom.hasPlinths = room1.hasPlinths;
                createdRoom.plinthsHeight = room1.plinthsHeight;
                createdRoom.plinthsMaterialType = room1.plinthsMaterialType;
                createdRoom.hasCornices = room1.hasCornices;
                for (let j = 0; j < room1.components.length; j++) {
                    let cloneComponent = room1.components[j].clone();
                    createdRoom.addComponent(cloneComponent);
                }
                return createdRoom;
            }
        }

        return null;
    }

    /**
     * Test whether a room is already create and contained in the roomManager
     *
     * @param {Room} aRoom
     * @returns {*}
     */
    isAlreadyCreated(aRoom: Room, floor: Floor) : boolean {
        let currentFloorRooms = floor.rooms;
        for (let i = 0; i < currentFloorRooms.length; i++) {
            if (Room.equal(currentFloorRooms[i], aRoom)) {
                return true;
            }
        }
        return false;
    }

    _findConnectedWalls(current: Wall, previous: Wall, previousIsAddedWall: boolean, walls: Array<Wall>) : Array<Wall> {
        let result: Array<Wall> = new Array<Wall>;

        for (let i = 0; i < walls.length; ++i) {
            let w = walls[i];
            if ((w as any).$$visited === true) {
                continue;
            }

            if (current === w) {
                continue;
            }

            // this allow to create a triangle room
            if (previousIsAddedWall) {
                if (w.isCorner(previous.begin)) {
                    continue;
                }
            }
            else if (previous && (w.isCorner(previous.begin) || w.isCorner(previous.end))) {
                continue;
            }

            if (w.isCorner(current.begin)) {
                result.push(w);
                (w as any).$$visited = true;
            } else if (w.isCorner(current.end) && previous) {
                result.push(w);
                (w as any).$$visited = true;
            }
        }

        return result;
    }

    _setWallsUnvisited(walls: Array<Wall>) {
        for (let i = 0; i < walls.length; ++i) {
            delete (walls[i] as any).$$visited;
        }
    }

    _collectCandidatesWalls(room: Room, floor: Floor) : Array<Wall> {
        let result: Array<Wall> = new Array<Wall>();

        if (room) {
            result = room.walls.slice().concat(floor.nonRoomedWalls).concat(floor.getRoomedWallsInsideRoom(room));
        } else {
            let walls = floor.walls;
            for (let i = 0; i < walls.length; ++i) {
                let w = walls[i];
                if (w.rooms.length < 2) {
                    result.push(w);
                }
            }
        }

        return result;
    }

    _isRoomOverlap(rooms: Array<Room>, room: Room) : boolean {
        for (let i = 0; i < rooms.length; ++i) {
            for (let j = 0; j < rooms[i].walls.length; ++j) {
                if (rooms[i].walls[j].rooms.length == 2 && room.isInRoom(rooms[i].walls[j].center)) {
                    return true;
                }
            }
        }

        return false;
    }

    createRoomsFromWall(wall: Wall, floor: Floor, wallsPassed?: Array<Wall>, fusion?: boolean) {
        let paths : Array<RoomPath> = new Array<RoomPath>();

        let splitRoom = this.getRoomAtPosition(wall.center, floor);
        if (fusion) {
            splitRoom = null;
        }
        let split = splitRoom !== null;
        let walls = (wallsPassed ? wallsPassed : this._collectCandidatesWalls(splitRoom, floor));
        if (splitRoom) {
            floor.deleteRoom(splitRoom.id);
        }
        this._setWallsUnvisited(walls);
        // starting from aWall, find connected walls and create initial paths
        let connected = this._findConnectedWalls(wall, null, false, walls);
        for (let i = 0; i < connected.length; ++i) {
            let path = new RoomPath();
            path.add(wall); path.add(connected[i]);
            paths.push(path);
        }

        // extend path by finding connected walls to the last wall in a path
        for (let i = 0; i < paths.length; ++i) {

            // init floor walls visited to false
            this._setWallsUnvisited(walls);
            let path = paths[i];
            path.setWallVisited();
            do {
                // path is valid
                if (path.isClosed) {
                    break;
                }

                connected = this._findConnectedWalls(path.last, path.pLast, path.pLast === wall, walls);
                path.invalid = connected.length === 0;
                if (path.invalid) {
                    // the path cannot be closed -> exit
                    break;
                }

                let w = connected.pop();
                // create a new potential path
                while (connected.length > 0) {
                    let cloned = path.clone();
                    cloned.add(connected.pop());
                    paths.push(cloned);
                }

                // the path went back to the start - invalid -> exit
                if (w.isCorner(path.first.begin)) {
                    path.invalid = true;
                    break;
                }

                // add wall to current path
                path.add(w);
            } while (true);
        }

        paths = paths.filter(function (item) {
            return item.invalid === false && item.hasLoop === false;
        });

        if (paths.length === 0) {
            return null;
        }

        // sort by smallest area
        paths = paths.sort(function (a, b) {
            if (a.area < b.area) {
                return -1;
            }
            if (a.area > b.area) {
                return 1;
            }
            return 0;
        });

        if (!split) {
            paths = paths.slice(0, 1);
        }

        let result = [];
        let wallsToBeUsed = walls.slice();
        for (let i = 0; i < paths.length; ++i) {
            if (wallsToBeUsed.length === 0) { // all walls are used when room is splitted -> exit
                break;
            }

            let path = paths[i];
            let room = EntityFactory.createEmptyRoom();
            for (let j = 0; j < path.walls.length; ++j) {
                room.addWall(path.walls[j]);
                let index = wallsToBeUsed.indexOf(path.walls[j]);
                if (index !== -1) {
                    wallsToBeUsed.splice(index, 1);
                }
            }

            if (this._isRoomOverlap(result, room)) {
                wallsToBeUsed = wallsToBeUsed.concat(room.walls);
                continue;
            }

            if (this.isAlreadyCreated(room, floor)) {
                continue;
            }

            //all walls must be used when creating the second path
            if (result.length > 0 && wallsToBeUsed.length !== 0) {
                if (paths.length >= 2) {
                    // we only have more than one solutions - first both are the right solution even if all walls are not used (room inside room case)
                    result.push(room);
                    this.insideRoomHandling(room, floor);
                    floor.addRoom(room);
                }
                if (split === true && result.length === 2) {
                    break;
                }
                continue;
            }

            result.push(room);
            this.insideRoomHandling(room, floor);
            floor.addRoom(room);
        }

        if (result.length > 0) {
            return result;
        } else {
            return null;
        }
    }

}

export let roomManager = new RoomManager();
