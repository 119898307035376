import { Command, CommandEnum, AddEntitiesCommand, DeleteEntitiesCommand } from "./CommandModule";
import { Room, Wall, math } from "../SavaneJS";

// Constructor will take 2 parameters for the solution to apply,
// the wall entity parameter contains the wall entity on which the action is performed
// The entities array parameter contains a structure with entities elements to add with their parent in the scene hierarchy
export class ApplyWallPopulationCommand extends Command {
    private _wall: Wall;
    private _addedEntities: Array<any> = [];
    private _localMatrices: Array<math.mat4> = [];
    private _room: Room;
    // This will be used to save the commands created in the execute method so we can undo them in the undo method
    private _subCommands: Array<Command> = [];

    constructor(wall: Wall, addedEntities: Array<any>) {
        super();

        this._wall = wall;

        // find the room of the wall
        // for the moment there is a missing info because a wall can be in several rooms, so we take the first one for the moment
        this._room = this._wall.rooms[0];

        for (let i = 0; i < addedEntities.length; i++) {
            this._addedEntities.push({ entity: addedEntities[i].entity, parent: this._room });
            addedEntities[i].entity.smartDesignerParentId = this._wall.id;
            this._localMatrices.push(addedEntities[i].entity.transform.localMatrix);
        }

        // First clear room from any arrangement object a
        this.clearObjectsFromRoom();

        // Fill the room with smartDesigner solution
        this.fillRoom();
    }

    // Command name returned from a global enum
    name(): string {
        return CommandEnum.ApplyWallPopulationCommand;
    }

    // Datas that allows the command to be executed
    execDatas(): any {
        return {
            wall: this._wall,
            entities: this._addedEntities,
        };
    }

    needsRefreshHull(): boolean {
        for (let i = 0; i < this._subCommands.length; i++) {
            if (this._subCommands[i].needsRefreshHull()) {
                return true;
            }
        }

        return false;
    }

    // Clear the room and save (yes/no) its content
    clearObjectsFromRoom() {
        // Arrangements to remove
        let entitiesToRemove = [];

        let entities = this._room.children.filter((entity: any) => entity.smartDesignerParentId === this._wall.id);
        // Get the entities to remove from room
        for (let i = 0; i < entities.length; i++) {
            let entity = entities[i];
            if (entity.isArrangementObjectEntity() || entity.isArrangementGroupEntity() || entity.isWorktopEntity() || entity.isGeometryPrimitiveEntity()) {
                entitiesToRemove.push(entity);
                break;
            }
        }

        if (entitiesToRemove.length > 0) {
            // And store the command into the commands array for undo/redo
            this._subCommands.push(new DeleteEntitiesCommand(entitiesToRemove));
        }
    }

    // Fill the room with the smart designer solution the object was created with
    fillRoom() {
        // And store the command into the commands array for undo

        this._subCommands.push(new AddEntitiesCommand(this._addedEntities, false, undefined, undefined));
    }

    // Undo the current command
    undo() {
        // Undo all commands
        for (let i = this._subCommands.length - 1; i >= 0; i--) {
            this._subCommands[i].undo();
        }

        super.undo();
    }

    // Execute current command (redo)
    execute() {
        // Redo all commands
        for (let i = 0; i < this._subCommands.length; i++) {
            this._subCommands[i].execute();
        }

        for (let i = 0; i < this._addedEntities.length; i++) {
            let globalTransform = math.mat4.create();
            math.mat4.multiply(globalTransform, this._wall.transform.localMatrix, this._localMatrices[i]);
            let invertedLocalMatrix = this._addedEntities[i].entity.parent.transform.invertedGlobalMatrix;
            let localTransform = math.mat4.create();
            math.mat4.multiply(localTransform, invertedLocalMatrix, globalTransform);
            math.mat4.invert(invertedLocalMatrix, this._addedEntities[i].entity.parent.transform.globalMatrix);
            this._addedEntities[i].entity.transform.localTransform = localTransform;
        }

        super.execute();
    }
}
