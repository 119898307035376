import { SceneConstants, math } from "../SavaneJS";
import { Entity } from "./Entity";

/**
 * A picture placed on plan, old entity in theory not used anymore
 **/
export class UserPicture extends Entity {
    // Fake for 2d icon
    private width: number;
    private length: number;
    // Picture ref from the project (should be an number id but in case we give it any type)
    private _pictureRef: any;

    constructor(id: number, pictureRef: any) {
        super();
        this.id = id;

        this.width = 450;
        this.length = 450;

        this._pictureRef = pictureRef;
    }

    get entityType(): SceneConstants.EntityType {
        return SceneConstants.EntityType.UserPicture;
    }

    get pictureRef(): any {
        return this._pictureRef;
    }

    set pictureRef(p: any) {
        this.pictureRef = p;
    }
    
    get position(): math.vec3 {
        return this.transform.globalPosition;
    }
    
    get angle(): number {
        return this.transform.localZRotation;
    }

    set angle(a: number) {
        this.setRotationZ(a);
    }

    get floorHeight() : number {
        return 0;
    }

    set floorHeight(fh: number) {
    }

    set position(p: math.vec3) {
        this.transform.globalPosition = p;
    }

    setRotationZ(rz: number) {
        this.transform.globalZRotation = rz;
    }
}
