import { Command, CommandEnum } from './CommandModule';
import { Events } from '../events';
import { eventsManager } from '../managers/EventsManager';
import { EntityFactory, Staircase, math } from '../SavaneJS';

export class EditStaircaseCommand extends Command
{
    private _modifiedStaircase: Staircase;
    private _currentStaircase: Staircase;

    // Constructor takes current staircase (with temporary) as parameter
    constructor(currentStaircase) {
        super();
        // Clone current staircase so it copies the temporary fields into the cloned staircase entity
        this._modifiedStaircase = EntityFactory.cloneEntity(currentStaircase, false) as Staircase;
        // End the staircase temporary so it drops the temporary data
        currentStaircase.endTemporary();
        // Save the current staircase for future data exchange
        this._currentStaircase = currentStaircase;
    }

    // Command name returned from a global enum
    name(): string {
        return CommandEnum.EditStaircaseCommand;
    }

    execDatas(): any {
        return {
            id: this._currentStaircase.id,
            type: this._currentStaircase.entityType
        };
    }

    // Undo the current command
    undo() {
        // Execute the current command, the savedStair structure keeps the changes back and forth
        this.execute();
        super.undo();
    }

    // Execute current command (redo)
    execute() {
        // Get its position and exchange it with the savedStair one
        let tmpPosition = math.vec3.create();
        math.vec3.copy(tmpPosition, this._currentStaircase.position);
        this._currentStaircase.position = this._modifiedStaircase.position;
        this._modifiedStaircase.position = tmpPosition;

        // Get its length and exchange it with the savedStair one
        let tmpLength = this._currentStaircase.length;
        this._currentStaircase.length = this._modifiedStaircase.length;
        this._modifiedStaircase.length = tmpLength;

        // Get its height and exchange it with the savedStair one
        let tmpHeight = this._currentStaircase.height;
        this._currentStaircase.height = this._modifiedStaircase.height;
        this._modifiedStaircase.height = tmpHeight;

        // Get its width and exchange it with the savedStair one
        let tmpWidth = this._currentStaircase.width;
        this._currentStaircase.width = this._modifiedStaircase.width;
        this._modifiedStaircase.width = tmpWidth;

        // Get its rotation and exchange it with the savedStair one
        let tmpAngle = this._currentStaircase.angle;
        this._currentStaircase.setRotationZ(this._modifiedStaircase.angle);
        this._modifiedStaircase.setRotationZ(tmpAngle);

        // Get the hasLeftRamp boolean and exchange it with the savedStair one
        let tmpLeftRamp = this._currentStaircase.hasLeftRamp;
        this._currentStaircase.hasLeftRamp = this._modifiedStaircase.hasLeftRamp;
        this._modifiedStaircase.hasLeftRamp = tmpLeftRamp;

        // Get the hasRightRamp boolean and exchange it with the savedStair one
        let tmpRightRamp = this._currentStaircase.hasRightRamp;
        this._currentStaircase.hasRightRamp = this._modifiedStaircase.hasRightRamp;
        this._modifiedStaircase.hasRightRamp = tmpRightRamp;

        // Get the nose length value and exchange it with the savedStair one
        let tmpNoseLength = this._currentStaircase.noseLength;
        this._currentStaircase.noseLength = this._modifiedStaircase.noseLength;
        this._modifiedStaircase.noseLength = tmpNoseLength;

        // Get the hide in axo value and exchange it with the savedStair one
        let tmpHideInAxo = this._currentStaircase.hideInAxo;
        this._currentStaircase.hideInAxo = this._modifiedStaircase.hideInAxo;
        this._modifiedStaircase.hideInAxo = tmpHideInAxo;

        // Get the cut slope value and exchange it with the savedStair one
        let tmpCutSlope = this._currentStaircase.cutSlope;
        this._currentStaircase.cutSlope = this._modifiedStaircase.cutSlope;
        this._modifiedStaircase.cutSlope = tmpCutSlope;

        // Get closure type and exchange it with the savedStair one
        let tmpClosureType = this._currentStaircase.closureType;
        this._currentStaircase.closureType = this._modifiedStaircase.closureType;
        this._modifiedStaircase.closureType = tmpClosureType;

        // Get limon type and exchange it with the savedStair one
        let tmpLimonType = this._currentStaircase.limonType;
        this._currentStaircase.limonType = this._modifiedStaircase.limonType;
        this._modifiedStaircase.limonType = tmpLimonType;

        let tmpLimonWidth = this._currentStaircase.limonWidth;
        this._currentStaircase.limonWidth = this._modifiedStaircase.limonWidth;
        this._modifiedStaircase.limonWidth = tmpLimonWidth;

        let tmpLimonHeight = this._currentStaircase.limonHeight;
        this._currentStaircase.limonHeight = this._modifiedStaircase.limonHeight;
        this._modifiedStaircase.limonHeight = tmpLimonHeight;

        // Get material type and exchange it with the savedStair one
        let tmpMaterialType = this._currentStaircase.materialType;
        this._currentStaircase.materialType = this._modifiedStaircase.materialType;
        this._modifiedStaircase.materialType = tmpMaterialType;

        // Get material type and exchange it with the savedStair one
        let tmpRampMaterialType = this._currentStaircase.rampMaterialType;
        this._currentStaircase.rampMaterialType = this._modifiedStaircase.rampMaterialType;
        this._modifiedStaircase.rampMaterialType = tmpRampMaterialType;

        let tmpRampHeight = this._currentStaircase.rampHeight;
        this._currentStaircase.rampHeight = this._modifiedStaircase.rampHeight;
        this._modifiedStaircase.rampHeight = tmpRampHeight;

        if ((this._currentStaircase as any).hasBearing !== undefined) {
            let tmpBearing = (this._currentStaircase as any).hasBearing;
            (this._currentStaircase as any).hasBearing = (this._modifiedStaircase as any).hasBearing;
            (this._modifiedStaircase as any).hasBearing = tmpBearing;
        }

        if ((this._currentStaircase as any).modifiedWidth !== undefined) {
            let tmpmodifiedWidth = (this._currentStaircase as any).modifiedWidth;
            (this._currentStaircase as any).modifiedWidth = (this._modifiedStaircase as any).modifiedWidth;
            (this._modifiedStaircase as any).modifiedWidth = tmpmodifiedWidth;
        }

        if ((this._currentStaircase as any).stepWidth !== undefined) {
            let tmpStepWidth = (this._currentStaircase as any).stepWidth;
            (this._currentStaircase as any).stepWidth = (this._modifiedStaircase as any).stepWidth;
            (this._modifiedStaircase as any).stepWidth = tmpStepWidth;
        }

        if ((this._currentStaircase as any).revolutionAngle !== undefined) {
            let tmpRevolutionAngle = (this._currentStaircase as any).revolutionAngle;
            (this._currentStaircase as any).revolutionAngle = (this._modifiedStaircase as any).revolutionAngle;
            (this._modifiedStaircase as any).revolutionAngle = tmpRevolutionAngle;
        }

        super.execute();

        // Select object at the end
        eventsManager.instance.dispatch(Events.CHANGE_EDITOR_SELECTION,
            {
                selection: [this._currentStaircase],
                keepSelected: false,
                showTulip: false
            });
    }
};
