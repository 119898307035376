import { Command, CommandEnum } from './CommandModule';
import { Events } from '../events';
import { eventsManager } from '../managers/EventsManager';
import { Entity, Floor, Wall, Room, SavaneConstants, wallManager, roomManager, math } from '../SavaneJS';

export class CreateWallCommand  extends Command {
    private _isB2B: boolean = false;
    private _createdWalls: Array<Wall> = new Array<Wall>();
    private _currentFloor: Floor;
    private _selectedEntities: Array<Entity>;
    private _beginPoint: math.vec3;
    private _endPoint: math.vec3;

    // First time to execute this command (if yes we will build a command list and execute it, if not we will just execute the command list)
    private _firstTime: boolean = true;

    constructor(createdWall: Wall, selectedEntities: Array<Entity>, isB2B: boolean) {
        super();
        this._isB2B = isB2B;
        // Current floor
        this._currentFloor = createdWall.floor;
        // Selected entities array
        this._selectedEntities = selectedEntities;

        // Get all intersections between this wall and other walls (in theory none as we do not allow walls going through others, they'd be seen as invalid before commit can be done)
        let cuttingPoints = wallManager.getPointsCuttingWalls(createdWall);

        // Save start and end point of the new wall
        this._beginPoint = math.vec3.clone(createdWall.begin);
        this._endPoint = math.vec3.clone(createdWall.end);

        // Start from the beginning of the new wall
        var lastPoint = math.vec3.clone(createdWall.begin);
        // We are on the new created wall for the moment
        var currentWall = createdWall;

        // If there are intersections
        if (cuttingPoints.length !== 0) {
            // Let's parse all the intersections
            for (let i = 0; i < cuttingPoints.length; i++) {
                // Get created walls from the i-th intersection
                let cuttedWalls = wallManager.cutWall(currentWall, cuttingPoints[i]);
                let firstWall = cuttedWalls[0].isCorner(lastPoint) ? cuttedWalls[0] : cuttedWalls[1];
                currentWall = !cuttedWalls[0].isCorner(lastPoint) ? cuttedWalls[0] : cuttedWalls[1];

                let superpWallsCut = wallManager.getWallSuperposed(firstWall, SavaneConstants.PositionTolerance, 100 * SavaneConstants.Tolerance);
                if (superpWallsCut.length === 0) {
                    this._createdWalls.push(firstWall);
                }
                lastPoint = cuttingPoints[i];
            }
        }
        let superposedWalls = wallManager.getWallSuperposed(currentWall, SavaneConstants.PositionTolerance, 100 * SavaneConstants.Tolerance);
        if (superposedWalls.length === 0) {
            this._createdWalls.push(currentWall);
        }
    }

    // Command name returned from a global enum
    name () {
        return CommandEnum.CreateWallCommand;
    }

    // Undo the current command
    undo() {
        let i;

        // Parse all created command and undo them one after another
        for (i = this._actions.length - 1; i >= 0; i--) {
            this.undoAction(this._actions[i]);
        }

        // Check walls that do not belong to a room
        roomManager.manageNonRoomedWalls(this._currentFloor);

        // And update potential adjacent walls
        for (i = 0; i < this._createdWalls.length; i++) {
            this.updateAdjacentWall(this._createdWalls[i]);
        }
    }

    _mergeColinearWalls(position) {
        let walls = wallManager.getWallsAtCorner(position, this._currentFloor, SavaneConstants.PositionTolerance, false, -1);
        if (walls.length === 2 && Wall.mergeWall(walls[0], walls[1])) {
            var mergeAction = this.createMergeAction(walls[0], walls[1]);
            this.doAction(mergeAction);
        }
    }

    // Execute current command (redo)
    execute() {
        // Loop variables
        let i, j;
        // To retrieve walls at a specified position
        let walls;

        // If we are executing the command for the first time, we build a command list (laster we will just execute the command list)
        if (this._firstTime) {
            // Next time it won't be first time
            this._firstTime = false;
            // If we create at least one wall
            if (this._createdWalls.length !== 0) {
                // Will store the walls resulting from a wall split (cut)
                let cutAction;

                // Cut begin wall
                if (this._createdWalls[0].isCorner(this._beginPoint)) {
                    // Check if there is another wall at the beginning of the new wall to create (this wall will be split in two)
                    walls = wallManager.getWallsAtPosition(this._beginPoint, this._currentFloor, SavaneConstants.PositionTolerance, -1, null, false);

                    // Any wall and not the corner of a wall
                    if (walls !== null && walls.length === 1 && !walls[0].isCorner(this._beginPoint) && !walls[0].isCorner(this._endPoint)) {
                        // In case the wall to cut is shifted, recompute cut point for both created wall and...
                        for (i = 0; i < this._createdWalls.length; i++) {
                            var wall = this._createdWalls[i];

                            if ((wall.begin[0] === this._beginPoint[0]) && (wall.begin[1] === this._beginPoint[1])) {
                                var tmp = math.vec3.create();

                                tmp[0] = wall.begin[0] - (walls[0].wallDirection[1] * -walls[0].shiftOffset);
                                tmp[1] = wall.begin[1] - (walls[0].wallDirection[0] * walls[0].shiftOffset);

                                wall.begin = tmp
                            }

                            if ((wall.end[0] === this._beginPoint[0]) && (wall.end[1] === this._beginPoint[1])) {
                                var tmp = math.vec3.create();

                                tmp[0] = wall.end[0] - (walls[0].wallDirection[1] * -walls[0].shiftOffset);
                                tmp[1] = wall.end[1] - (walls[0].wallDirection[0] * walls[0].shiftOffset);

                                wall.end = tmp
                            }
                        }
                        // And wall being cut too
                        this._beginPoint[0] -= (walls[0].wallDirection[1] * -walls[0].shiftOffset);
                        this._beginPoint[1] -= (walls[0].wallDirection[0] * walls[0].shiftOffset);

                        // Create an action to cut walls
                        cutAction = this.createCutAction(walls[0], this._beginPoint);
                        // And execute it
                        this.doAction(cutAction);

                        cutAction.wallsCut[0].shiftDirection = walls[0].shiftDirection;
                        cutAction.wallsCut[1].shiftDirection = -walls[0].shiftDirection;
                    }
                }

                // Cut end Wall
                if (this._createdWalls[this._createdWalls.length - 1].isCorner(this._endPoint)) {
                    // Check if there is another wall at the ending of the new wall to create (this wall will be split in two)
                    walls = wallManager.getWallsAtPosition(this._endPoint, this._currentFloor, SavaneConstants.PositionTolerance, -1, null, false);

                    // Any wall and not the corner of a wall
                    if (walls !== null && walls.length === 1 && !walls[0].isCorner(this._endPoint) && !walls[0].isCorner(this._beginPoint)) {
                        // In case the wall to cut is shifted, recompute cut point for both created wall and...
                        for (i = 0; i < this._createdWalls.length; i++) {
                            var wall = this._createdWalls[i];

                            if ((wall.begin[0] === this._endPoint[0]) && (wall.begin[1] === this._endPoint[1])) {
                                var tmp = math.vec3.create();

                                tmp[0] = wall.begin[0] - (walls[0].wallDirection[1] * -walls[0].shiftOffset);
                                tmp[1] = wall.begin[1] - (walls[0].wallDirection[0] * walls[0].shiftOffset);

                                wall.begin = tmp
                            }

                            if ((wall.end[0] === this._endPoint[0]) && (wall.end[1] === this._endPoint[1])) {
                                var tmp = math.vec3.create();

                                tmp[0] = wall.end[0] - (walls[0].wallDirection[1] * -walls[0].shiftOffset);
                                tmp[1] = wall.end[1] - (walls[0].wallDirection[0] * walls[0].shiftOffset);

                                wall.end = tmp
                            }
                        }
                        // And wall being cut too
                        this._endPoint[0] -= (walls[0].wallDirection[1] * -walls[0].shiftOffset);
                        this._endPoint[1] -= (walls[0].wallDirection[0] * walls[0].shiftOffset);

                        // Create an action to cut walls
                        cutAction = this.createCutAction(walls[0], this._endPoint);
                        // And execute it
                        this.doAction(cutAction);
                    }
                }

                // Process each created wall to add them all
                for (i = 0; i < this._createdWalls.length; i++) {
                    let wall = this._createdWalls[i];
                    let addAction = { type: Command.ActionType.addWall, wall: wall };
                    this._actions.push(addAction);
                    this.doAction(addAction);
                    this._mergeColinearWalls(wall.begin);
                    this._mergeColinearWalls(wall.end);
                }
            }
        }
        else {
            // Let's just parse the command list created during the first command execution and execute them all (avoid recomputing everything many times)
            for (i = 0; i < this._actions.length; i++) {
                this.doAction(this._actions[i]);
            }
        }

        // Verify walls that do not belong to any room yet
        roomManager.manageNonRoomedWalls(this._currentFloor);

        // Build a list of rooms to update depending on the walls created
        let roomsToUpdate: Array<Room> = new Array<Room>();
        // Get all rooms from the current floor
        let roomsList = this._currentFloor.rooms;
        // Parse all created walls
        for (i = 0; i < this._createdWalls.length; i++) {
            // and parse all rooms
            for (j = 0; j < roomsList.length; j++) {
                // If the new wall is in the list of the walls belonging to the room but not border of it (and if not already in the list to update)
                if (roomsList[j].nonRoomedWalls.indexOf(this._createdWalls[i]) >= 0 && roomsToUpdate.indexOf(roomsList[j]) < 0) {
                    //Redraww room
                    roomsToUpdate.push(roomsList[j]);
                }
            }
            // Update all adjacent walls of the current created walls
            this.updateAdjacentWall(this._createdWalls[i]);
        }

        // Update all necessary rooms
        for (let i = 0 ; i < roomsToUpdate.length ; i++) {
            eventsManager.instance.dispatch(Events.REDRAW_GRAPHICAL_ROOM,
                {
                    entity: roomsToUpdate[i]
                });
        }

        // Select deleted object at the end if one was created
        if (this._createdWalls.length > 0) {
            let wallsEnd = wallManager.getWallsAtCorner(this._endPoint, this._currentFloor, SavaneConstants.PositionTolerance, false, -1);
            let wallsBeg = wallManager.getWallsAtCorner(this._beginPoint, this._currentFloor, SavaneConstants.PositionTolerance, false, -1);

            if (wallsBeg !== null) {
                if (wallsEnd !== null) {
                    walls = wallsBeg.concat(wallsEnd);
                }
                else {
                    walls = wallsBeg;
                }
            }
            else {
                walls = wallsEnd;
            }

            eventsManager.instance.dispatch(Events.CHANGE_EDITOR_SELECTION,
                {
                    selection: walls,
                    keepSelected: false,
                    showTulip: false
                });
        }
    }

    // Undo one action of the commandlist created above
    undoAction(action) {
        // Depending on the action type...
        switch (action.type) {
            // it was an add wall so undo is deletewall
            case Command.ActionType.addWall:
                wallManager.deleteWallForCommand(action.wall.id, true, this._currentFloor, this._selectedEntities);
                break;

            // It was a cut walls so undo will be a merge walls
            case Command.ActionType.cutWall:
                wallManager.mergeWallsForCommand(action.wallMerge, action.wallsCut, this._currentFloor, this._selectedEntities);
                break;

            // It was a merge wall so undo will be a cut walls
            case Command.ActionType.mergeWall:
                wallManager.cutWallForCommand(action.wallMerge, action.wallsCut, this._currentFloor, this._selectedEntities);
                break;

            // Nothing for default case (except error maybe)
            default:
                break;
        }
    }

    // Execute one action of the action list created above
    doAction(action) {
        // Depending on the action type...
        switch (action.type) {
            // We have to create a wall
            case Command.ActionType.addWall:
                wallManager.addWallForCommand(action.wall, true, this._currentFloor, action.wall.height, this._isB2B);
                break;

            // We have to cut a wall
            case Command.ActionType.cutWall:
                wallManager.cutWallForCommand(action.wallMerge, action.wallsCut, this._currentFloor, this._selectedEntities);
                // Copy again shift direction from original wall, it might have been cleared by the cutWall (1st wall mainly)
                action.wallsCut[0].shiftDirection = action.wallMerge.shiftDirection;
                action.wallsCut[1].shiftDirection = -action.wallMerge.shiftDirection;
                break;

            // We have to merge walls
            case Command.ActionType.mergeWall:
                wallManager.mergeWallsForCommand(action.wallMerge, action.wallsCut, this._currentFloor, this._selectedEntities);
                break;

            // Nothing for default case (except error maybe)
            default:
                break;
        }
    }

    // Update adjacent walls to a wall passed as parameter
    updateAdjacentWall(wall) {
        // Loop varoables
        let i, j;
        // To retrieve walls around a point and walls to draw
        let walls, wallToDraw;

        // Get walls around the beginning of the new wall
        walls = wallManager.getWallsAtPosition(wall.begin, this._currentFloor, SavaneConstants.PositionTolerance, -1, null, false);
        // If any wall around start wall begin
        if (walls !== null) {
            // Parse all the walls
            for (i = 0; i < walls.length; i++) {
                // Get the i-th wall
                wallToDraw = walls[i];
                // Redraw it
                eventsManager.instance.dispatch(Events.REDRAW_GRAPHICAL_WALL,
                    {
                        entity: wallToDraw,
                    });
                // Parse all rooms where this wall appears
                for (j = 0; j < wallToDraw.rooms.length; j++) {
                    // And draw them as well
                    eventsManager.instance.dispatch(Events.REDRAW_GRAPHICAL_ROOM,
                        {
                            entity: wallToDraw.rooms[j]
                        });
                }
            }
        }

        // Get walls around the ending of the new wall
        walls = wallManager.getWallsAtPosition(wall.end, this._currentFloor, SavaneConstants.PositionTolerance, -1, null, false);
        // If any wall around start wall ending
        if (walls !== null) {
            // Parse all the walls
            for (i = 0; i < walls.length; i++) {
                // Get the i-th wall
                wallToDraw = walls[i];
                // Redraw it
                eventsManager.instance.dispatch(Events.REDRAW_GRAPHICAL_WALL,
                    {
                        entity: wallToDraw
                    });
                // Parse all rooms where this wall appears
                for (j = 0; j < wallToDraw.rooms.length; j++) {
                    // And draw them as well
                    eventsManager.instance.dispatch(Events.REDRAW_GRAPHICAL_ROOM,
                        {
                            entity: wallToDraw.rooms[j]
                        });
                }
            }
        }
    }
}
