
import { math } from '../scene/Transform';
import { Events } from '../events';
import { eventsManager } from '../managers/EventsManager';
import { ComponentConstants, Entity, Wall, roomManager, wallManager } from '../SavaneJS';

// Generic command constructor does nothing
export class Command {

    protected _actions: Array<any> = new Array<any>();

    constructor() {
    }

    // Generic command execDatas are empty
    execDatas(): any {
        return {};//
    }

    // Generic command name is unkown (this is not a real command but a mother class of any command i.e. abstract in some ways)
    name(): string {
        return 'unknow'; //
    }

    // Generic command undo just sends a message to whoever listens to it
    undo() {
        eventsManager.instance.dispatch(Events.COMMAND_EXECUTED,
            {
                name: this.name(),
                undo: true,
                datas: this.execDatas()
            });
    }

    parentEntityToRoom(entity) {
        // No parent, forget parenting since we won't be able to find the entity floor and scene
        if (!entity.parent) {
            return;
        }

        let entityPosition = math.vec3.clone(entity.position);
        // Get floor at that position
        let floor = entity.scene.getFloorAtPosition(entityPosition);
        // Get room under the object position
        let parent = roomManager.getRoomAtPosition(entity.position, floor);
        // No room found attach to floor
        if (parent === null) {
            if (floor) {
                parent = floor;
            }
            else {
                parent = entity.scene.currentFloor;
            }
        }
        // Remove from old parent
        entity.parent.deleteChild(entity.id);
        // Attach to new parent
        parent.addChild(entity);
        // Reposition entity
        entity.position = entityPosition;
    }

    // Generic command execution just sends a message to whoever listens to it
    execute() {
        eventsManager.instance.dispatch(Events.COMMAND_EXECUTED,
            {
                name: this.name(),
                undo: false,
                datas: this.execDatas()
            });
    }

    doesTreeContainFurnitureFinishes(rootEntity: Entity) {
        let components = rootEntity.getComponents(ComponentConstants.ComponentType.FurnitureFinishes);

        if (components.length === 0) {
            for (let i = 0; i < rootEntity.children.length; i++) {
                if (this.doesTreeContainFurnitureFinishes(rootEntity.children[i])) {
                    return (true);
                }
            }
            return (false);
        }
        else {
            return (true);
        }
    }

    needsRefreshHull(): boolean {
        return (false);
    }

    //GENERIC SUBACTIONS
    /**
     * Create a cut action using a wall and a point
     * @param wall
     * @param point
     * @returns {{type: number, wallMerge: *, wallsCut: *}}
     */
    createCutAction(wall: Wall, point: math.vec3): any {
        let cuttedWalls = wallManager.cutWall(wall, point);

        let cutAction =
        {
            type: Command.ActionType.cutWall,
            wallMerge: wall,
            wallsCut: cuttedWalls
        };
        this._actions.push(cutAction);

        return cutAction;
    }

    /**
     * Create a merge action using two walls
     * and add it to the actions list
     *
     * @param wallA
     * @param wallB
     * @returns {{type: number, wallMerge: *, wallsCut: *[]}}
     */
    createMergeAction(wallA: Wall, wallB: Wall): any {
        let mergedWalls = wallManager.mergeWall(wallA, wallB);

        let mergeAction =
        {
            type: Command.ActionType.mergeWall,
            wallMerge: mergedWalls,
            wallsCut: [wallA, wallB]
        };
        this._actions.push(mergeAction);

        return mergeAction;
    }
}

export namespace Command {

    export enum ActionType {
        edit,
        addWall,
        cutWall,
        mergeWall
    }
}
