import * as glMatrix from "gl-matrix";

declare global {
    interface Number {
        clamp: (min: number, max: number) => number;
    }
}
  
Number.prototype.clamp = function(min: number, max: number): number {
    return Math.min(Math.max(Number(this), min), max);
};

export class Functions {

    static radian2degree(value) {
        return value * 180 / Math.PI;
    }

    static degree2radian(value) {
        return value * Math.PI / 180;
    }

    static OrientedAngle(A: glMatrix.vec3 ,B: glMatrix.vec3) {
        var cos = glMatrix.vec3.dot(A, B).clamp(-1, 1);
        var result = Math.acos(cos);
        var cross = glMatrix.vec3.create();
        glMatrix.vec3.cross(cross, A, B);
        if (glMatrix.vec3.dot(glMatrix.vec3.fromValues(0, 0, 1), cross) < 0) {
            result = -result;
        }

        return result;
    }

}
