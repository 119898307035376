import { Floor, SketchBlock, Transform, arrangementManager } from '../../SavaneJS';
import { TemporaryEntity } from './TemporaryEntity';

/**
 * TemporarySketchBlock is used for sketchBlock edition
 */
export class TemporarySketchBlock extends TemporaryEntity {
    private _transform: Transform;

    constructor(sketchBlock: SketchBlock) {
        super(sketchBlock);

        this._transform = new Transform(null);
        this._transform.clone(sketchBlock.transform);
    }

    computeValidity(floor: Floor) {
        this._isValid = true;
    }

    get transform(): Transform {
        return this._transform;
    }
}
