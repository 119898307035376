import { Command, CommandEnum, AddEntityCommand, DeleteEntityCommand } from './CommandModule';
import { Events } from '../events';
import { eventsManager } from '../managers/EventsManager';
import { Entity, ArrangementObject } from '../SavaneJS';

export class ReplaceEntitiesCommand  extends Command
{
    private _replacedEntities: Array<Entity>;
    private _replacingEntitiesAndParents: any;
    // Command list to execute and undo
    private _subCommands: Array<Command> = [];

    constructor(replacedEntities: Array<Entity>, replacingEntitiesAndParents: any) {
        super();
        
        // Record the entity Array
        this._replacedEntities = replacedEntities;
        this._replacingEntitiesAndParents = replacingEntitiesAndParents;

        for (let i = 0; i < replacingEntitiesAndParents.length; i++) {

            // Copy lights parameters
            if (replacedEntities[i].isArrangementObjectEntity() &&
                (replacedEntities[i] as ArrangementObject).lightOn !== undefined &&
                replacingEntitiesAndParents[i].entity.lightOn !== undefined) {
                replacingEntitiesAndParents[i].entity.lightOn = (replacedEntities[i] as ArrangementObject).lightOn;
                replacingEntitiesAndParents[i].entity.lightOff = (replacedEntities[i] as ArrangementObject).lightOff;
                replacingEntitiesAndParents[i].entity.temperature = (replacedEntities[i] as ArrangementObject).temperature;
                replacingEntitiesAndParents[i].entity.lightColor = (replacedEntities[i] as ArrangementObject).lightColor;
            }

            // And create a delete entity command for each of them
            this._subCommands.push(new DeleteEntityCommand(replacedEntities[i], true));
            this._subCommands.push(new AddEntityCommand(replacingEntitiesAndParents[i].entity, replacingEntitiesAndParents[i].parent, false, undefined, undefined));
        }
    }

    name(): string {
        return CommandEnum.ReplaceEntitiesCommand;
    }

    // Datas that allows the command to be executed
    execDatas(): any {
        return {
            replacedEntities: this._replacedEntities,
            replacingEntitiesAndParents: this._replacingEntitiesAndParents
        };
    }

    needsRefreshHull(): boolean {
        for (let i = 0; i < this._subCommands.length; i++) {
            if (this._subCommands[i].needsRefreshHull()) {
                return (true);
            }
        }

        return (false);
    }

    // Undo the current command
    undo() {
        let i: number;

        // Parse all commands created by the constructor and undo them all
        for (i = this._subCommands.length - 1; i >= 0 ; i--) {
            this._subCommands[i].undo();
        }

        for (i = 0; i < this._replacedEntities.length; i++) {
            this._replacedEntities[i].recenter();

            var parent = this._replacedEntities[i].parent;
            while (parent.isArrangementGroupEntity() || parent.isArrangementObjectEntity()) {
                parent.recenter();
                // Redraw Cocos Node
                eventsManager.instance.dispatch(Events.REDRAW_GRAPHICAL_ENTITY,
                    {
                        entity: this._replacedEntities[i].parent
                    });
                parent = parent.parent;
            }
        }

        //Execute parent function
        super.undo();

        // Select deleted object at the end
        eventsManager.instance.dispatch(Events.CHANGE_EDITOR_SELECTION,
            {
                selection: this._replacedEntities,
                keepSelected: false,
                showTulip: false
            });
    }

    // Execute current command (redo)
    execute() {
        let i: number;
        // Select new objects at the end
        let newEntitiesToSelect = [];

        // Parse all commands created by the constructor and execute them all
        for (i = 0; i < this._subCommands.length; i++) {
            this._subCommands[i].execute();
        }

        // Parse all entities and try to recenter them as a post process of the entity addition
        for (i = 0; i < this._replacingEntitiesAndParents.length; i++) {
            var entity = this._replacingEntitiesAndParents[i].entity;
            // Push the new entity to be selected at the end of the function
            newEntitiesToSelect.push(entity);
            // Recenter the entity
            entity.recenter();

            // Recompute parents if necessary
            var parent = entity.parent;
            while (parent.isArrangementGroupEntity() || parent.isArrangementObjectEntity()) {
                parent.recenter();
                // Redraw Cocos Node
                eventsManager.instance.dispatch(Events.REDRAW_GRAPHICAL_ENTITY,
                    {
                        entity: parent
                    });
                parent = parent.parent;
            }
        }

        //Execute parent function
        super.execute();

        // Select the new entities at the end
        eventsManager.instance.dispatch(Events.CHANGE_EDITOR_SELECTION,
            {
                selection: newEntitiesToSelect,
                keepSelected: false,
                showTulip: false
            });
    }
};
