import { glMatrix, Plane, Line, Ray } from "../../../math/Math";
import { Wall } from "./Wall";

export class Slope {

    private _type: string = "SLOPE";
    private _A: glMatrix.vec3;
    private _B: glMatrix.vec3;
    private _C : glMatrix.vec3;
    private _D : glMatrix.vec3;
    private _wall: Wall;
    private _plane: Plane;
    private _local: glMatrix.mat4;
    private _global: glMatrix.mat4;

    constructor(A: glMatrix.vec3, B: glMatrix.vec3, C: glMatrix.vec3, D: glMatrix.vec3, wall: Wall) {
        //wall line
        this._A = A;
        this._B = B;
        //ceil line
        this._C = C;
        this._D = D;

        this._wall = wall;

        this._computePlane();
    }

    _computePlane() : void {
        var AB = glMatrix.vec3.create();
        glMatrix.vec3.subtract(AB, this._B, this._A);
        glMatrix.vec3.normalize(AB, AB);

        var AC = glMatrix.vec3.create();
        glMatrix.vec3.subtract(AC, this._C, this._A);
        glMatrix.vec3.normalize(AC, AC);

        var N = glMatrix.vec3.create();
        glMatrix.vec3.cross(N, AB, AC);
        glMatrix.vec3.normalize(N, N);

        this._plane = new Plane(this._A, N);

        this._local = glMatrix.mat4.fromValues(
            AB[0], N[0], AC[0], 0,
            AB[1], N[1], AC[1], 0,
            AB[2], N[2], AC[2], 0,
            0, 0, 0, 1);

        this._global = glMatrix.mat4.create();
        glMatrix.mat4.invert(this._global, this._local);
    }

    get Type() : string {
        return this._type;
    }

    get A() : glMatrix.vec3 {
        return this._A;
    }

    get B() : glMatrix.vec3 {
        return this._B;
    }

    get C() : glMatrix.vec3 {
        return this._C;
    }

    get D() : glMatrix.vec3 {
        return this._D;
    }

    get N() : glMatrix.vec3 {
        return this._plane.N;
    }

    get Wall() : Wall {
        return this._wall;
    }

    get Height() : number {
        return this._A[2];
    }

    get Length() : number {
        var AC = glMatrix.vec3.create();
        var ceiling = new Plane(glMatrix.vec3.fromValues(0, 0, this._wall.Height), glMatrix.vec3.fromValues(0, 0, -1));
        glMatrix.vec3.subtract(AC, this._C,  ceiling.Project(this._A));
        return glMatrix.vec3.length(AC);
    }

    get AsoluteThickness() : number {
        return this._wall.AsoluteThickness;
    }

    get Thickness() : number {
        return this._wall.Thickness;
    }

    get Plane() : Plane {
        return this._plane;
    }

    get SavaneId() : number {
        return this._wall.SavaneId;
    }

    Intersect(ray: Ray, dualside: boolean, backface: boolean /*unused but required to math Wall prototype*/, infinite: boolean) : glMatrix.vec3 {
        var result = this._plane.Intersect(ray, dualside);
        if (!result) {
            return null;
        }

        var lA = this.ToLocal(this.A);
        var lB = this.ToLocal(this.B);
        var lResult = this.ToLocal(result);
        var segment = new Line(lA, lB);
        if (!infinite && (segment.BelongToSegment(lResult) === false || lResult[2] < lA[2])) {
            return null;
        }

        return result;
    }

    ToLocal(position: glMatrix.vec3) : glMatrix.vec3 {
        var result = glMatrix.vec3.create();
        glMatrix.vec3.transformMat4(result, position, this._local);
        return result;
    }

    ToGlobal(position: glMatrix.vec3) : glMatrix.vec3 {
        var result = glMatrix.vec3.create();
        glMatrix.vec3.transformMat4(result, position, this._global);
        return result;
    }

    ClampToBorder(position: glMatrix.vec3) : glMatrix.vec3 {
        return position;
    }

}