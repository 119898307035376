//Using Vanishing Points for Camera Calibration and Coarse 3D Reconstruction from A Single Image
//https://www.researchgate.net/publication/226987370_Using_Vanishing_Points_for_Camera_Calibration_and_Coarse_3D_Reconstruction_from_A_Single_Image

import { Functions, glMatrix, Line, Plane, Ray } from '../../math/Math';
import { Model } from './model/Model';
import { WallFactory } from './factory/WallFactory';
import { WallJoineriesFactory } from './factory/WallJoineriesFactory';
import { PartitionFactory } from './factory/PartitionFactory';
import { PartitionJoineriesFactory } from './factory/PartitionJoineriesFactory';
import { SlopeFactory } from './factory/SlopeFactory';
import { SlopePartitionFactory } from './factory/SlopePartitionFactory';
import { Partition } from './model/Partition';
import { Slope } from './model/Slope';
import { Wall } from './model/Wall';

export class Photo2World {

    private _width: number;
    private _height: number;
    private _verticalShift: number = 0;
    private _verticalShiftOffset: number = 0;
    private _Fv: glMatrix.vec3 = glMatrix.vec3.fromValues(Infinity, Infinity, Infinity);
    private _Fu: glMatrix.vec3 = glMatrix.vec3.fromValues(Infinity, Infinity, Infinity);
    private _offsetFvX: number = 0;
    private _offsetFvY: number = 0;
    private _offsetFuX: number = 0;
    private _offsetFuY: number = 0;
    private _offsetFocalLength = 0;
    private _P: glMatrix.vec3;
    private _focalLength: number = 0;
    private _fovX: number = 0;
    private _invertVanishingPoints: boolean = false;
    //projection
    private _camera2image: glMatrix.mat4 = glMatrix.mat4.create();
    private _image2camera: glMatrix.mat4 = glMatrix.mat4.create();

    //camera pose
    private _world2camera: glMatrix.mat4 = glMatrix.mat4.create();
    private _camera2world: glMatrix.mat4 = glMatrix.mat4.create();
    private _cameraPosition: glMatrix.vec3 = glMatrix.vec3.create();

    private _image2world: glMatrix.mat4 = glMatrix.mat4.create();

    private _worldHeight: number = 2500;
    private _wallHeight: number = 2500;
    private _thickness: number = 200;

    private _ceil_draw: boolean = false;
    private _ground: Plane = new Plane(glMatrix.vec3.create(), glMatrix.vec3.fromValues(0, 0, 1));
    private _ceil_partition_draw: boolean = false;
    private _ground_partition: Plane = new Plane(glMatrix.vec3.create(), glMatrix.vec3.fromValues(0, 0, 1));
    private _model: Model = new Model();

    private _wallFactory: WallFactory = new WallFactory(this);
    private _wallJoineriesFactory: WallJoineriesFactory = new WallJoineriesFactory(this);
    private _slopeFactory: SlopeFactory = new SlopeFactory(this);
    private _slopePartitionFactory: SlopePartitionFactory = new SlopePartitionFactory(this);
    private _partitionFactory: PartitionFactory = new PartitionFactory(this);
    private _partitionJoineriesFactory: PartitionJoineriesFactory = new PartitionJoineriesFactory(this);
    private _magnet: boolean = true;
    private _renormalize: boolean = false;

    private _APi: { A: glMatrix.vec3, B: glMatrix.vec3 };

    constructor(width: number, height: number) {

        this._width = width;
        this._height = height;
        this._P = glMatrix.vec3.fromValues(this._width / 2, this._height / 2, 0); //Principal point
    }

    get WorldHeight() : number {
        return this._worldHeight;
    }

    set WorldHeight(value: number) {
        this._worldHeight = value;
    }

    get WallHeight() : number {
        return this._wallHeight;
    }

    set WallHeight(value: number) {
        this._wallHeight = value;
        if (this._ceil_draw === true) {
            this._ground = new Plane(glMatrix.vec3.fromValues(0, 0, this._wallHeight), glMatrix.vec3.fromValues(0, 0, -1));
        }
    }

    get Width() : number {
        return this._width;
    }

    get Height(): number {
        return this._height;
    }

    get Thickness() : number {
        return this._thickness;
    }

    set Thickness(value: number) {
        this._thickness = value;
    }

    get ForceRenormalize() : boolean {
        return this._renormalize;
    }

    set ForceRenormalize(value: boolean) {
        this._renormalize = value;
        this._computeCamera();
        this.Rebuild();
    }

    get CeilDraw() : boolean {
        return this._ceil_draw;
    }

    set CeilDraw(value: boolean) {
        this._ceil_draw = value;
        if (value === true) {
            this._ground = new Plane(glMatrix.vec3.fromValues(0, 0, this._wallHeight), glMatrix.vec3.fromValues(0, 0, -1));
        } else {
            this._ground = new Plane(glMatrix.vec3.create(), glMatrix.vec3.fromValues(0, 0, 1));
        }
    }

    get CeilPartitionDraw() : boolean {
        return this._ceil_partition_draw;
    }

    set CeilPartitionDraw(value: boolean) {
        this._ceil_partition_draw = value;
        if (value === true) {
            this._ground_partition = new Plane(glMatrix.vec3.fromValues(0, 0, this._wallHeight), glMatrix.vec3.fromValues(0, 0, -1));
        } else {
            this._ground_partition = new Plane(glMatrix.vec3.create(), glMatrix.vec3.fromValues(0, 0, 1));
        }
    }

    get FovX() : number {
        return this._fovX;
    }

    get FovY() : number {
        return Functions.radian2degree(2.0 * Math.atan(Math.tan(Functions.degree2radian(this.FovX) * 0.5) * (this._height / this._width)));
    }

    get VerticalShift() : number {
        return this._verticalShift + this._verticalShiftOffset;
    }

    get VerticalShiftOffset() : number {
        return this._verticalShiftOffset;
    }

    set VerticalShiftOffset(value: number) {
        this._verticalShiftOffset = value;
        this._computeCamera();
        this.Rebuild();
    }

    get FocalLength() : number {
        return this._focalLength;
    }

    get CameraPosition() : glMatrix.vec3 {
        return this._cameraPosition;
    }

    get Camera2Image() : glMatrix.mat4 {
        return this._camera2image;
    }

    get Image2Camera() : glMatrix.mat4 {
        return this._image2camera;
    }

    get Camera2World() : glMatrix.mat4 {
        return this._camera2world;
    }

    get World2Camera() : glMatrix.mat4 {
        return this._world2camera;
    }

    get Fv() : glMatrix.vec3 {
        return glMatrix.vec3.fromValues(this._Fv[0] + this._offsetFvX, this._Fv[1] + this._offsetFvY, 0);
    }

    get OffsetFvX() : number {
        return this._offsetFvX;
    }

    set OffsetFvX(value: number) {
        this._offsetFvX = value;
        this._computeCamera();
        this.Rebuild();
    }

    get OffsetFvY() : number {
        return this._offsetFvY;
    }

    set OffsetFvY(value: number) {
        this._offsetFvY = value;
        this._computeCamera();
        this.Rebuild();
    }

    get Fu() : glMatrix.vec3 {
        return glMatrix.vec3.fromValues(this._Fu[0] + this._offsetFuX, this._Fu[1] + this._offsetFuY, 0);
    }

    get FocalLengthOffset() : number {
        return this._offsetFocalLength;
    }

    set FocalLengthOffset(value: number) {
        this._offsetFocalLength = value;
        this._computeCamera();
        this.Rebuild();
    }

    get OffsetFuX() : number {
        return this._offsetFuX;
    }

    set OffsetFuX(value: number) {
        this._offsetFuX = value;
        this._computeCamera();
        this.Rebuild();
    }

    get OffsetFuY() : number {
        return this._offsetFuY;
    }

    set OffsetFuY(value: number) {
        this._offsetFuY = value;
        this._computeCamera();
        this.Rebuild();
    }

    get Model() : Model {
        return this._model;
    }

    get Magnet() : boolean {
        return this._magnet;
    }

    set Magnet(value: boolean) {
        this._magnet = value;
    }

    get VanishingPointsInverted() : boolean {
        return this._invertVanishingPoints;
    }

    get PartitionFactory() : PartitionFactory {
        return this._partitionFactory;
    }

    Clear() : void {
        this._offsetFvY = 0;
        this._offsetFuY = 0;
        this._offsetFuX = 0;
        this._offsetFvX = 0;
        this._verticalShiftOffset = 0;
        this._offsetFocalLength = 0;

        if (this._APi) {
            this._computeCamera();
            this.Rebuild();
        }
    }

    EstimateCamera(FLines: Array<Line>, APi: { A: glMatrix.vec3, B: glMatrix.vec3 }) : void {
        this._APi = APi;
        if (this._APi.A[1] < this._APi.B[1]) { //A must be on the ground, invert A and B if not
            var C = this._APi.A;
            this._APi.A = this._APi.B;
            this._APi.B = C;
        }

        if (FLines[0].IsParallel(FLines[1])) {
            return;
        }

        if (FLines[2].IsParallel(FLines[3])) {
            return;
        }

        this._Fu = FLines[0].Intersect(FLines[1]);
        if (!this._Fu) {
            this._Fu = glMatrix.vec3.fromValues(Infinity, Infinity, Infinity);
            return;
        }
        this._Fv = FLines[2].Intersect(FLines[3]);
        if (!this._Fv) {
            this._Fv = glMatrix.vec3.fromValues(Infinity, Infinity, Infinity);
            return;
        }
        this._invertVanishingPoints = this._Fv[0] > this._Fu[0];
        if (this._Fv[0] > this._Fu[0]) { //Fv must be the left vanishing point, invert Fv and Fu if not
            var F = this._Fv;
            this._Fv = this._Fu;
            this._Fu = F;
        }

        this._computeCamera();
    }

    BuildWalls(pixels?: Array<glMatrix.vec2>) : void   {
        if (pixels) {
            this._wallFactory.Pixels = pixels;
        }
        this._wallFactory.Build();
    }

    CloseWalls() : void {
        this.Rebuild();
        this._wallFactory.CloseRoom(this._model.Walls);
    }

    BuildWallJoineries(pixels?: Array<glMatrix.vec2>, joineryDatas?: any) : void {
        if (pixels) {
            this._wallJoineriesFactory.Pixels = pixels;
        }

        if (joineryDatas) {
            this._wallJoineriesFactory.JoineryDatas = joineryDatas;
        }
        this._wallJoineriesFactory.Build();
    }

    BuildSlopes(pixels?: Array<glMatrix.vec2>, slopeDatas?: any) : void {
        if (pixels) {
            this._slopeFactory.Pixels = pixels;
        }

        if (slopeDatas) {
            this._slopeFactory.SlopeDatas = slopeDatas;
        }
        this._slopeFactory.Build();
    }

    BuildPartitions(pixels?: Array<glMatrix.vec2>, values?: Array<number>, values2?: Array<number>, zoom?: number) : void {
        if (pixels) {
            this._partitionFactory.Pixels = pixels;
        }

        if (values) {
            this._partitionFactory.Values = values;
        }

        if (values2) {
            this._partitionFactory.Values2 = values2;
        }

        if (zoom) {
            this._partitionFactory.Zoom = zoom;
        }

        this._partitionFactory.Build();
    }

    BuildSlopesPartition(pixels?: Array<glMatrix.vec2>, slopeDatas?: any) : void {
        if (pixels) {
            this._slopePartitionFactory.Pixels = pixels;
        }

        if (slopeDatas) {
            this._slopePartitionFactory.SlopeDatas = slopeDatas;
        }
        this._slopePartitionFactory.Build();
    }

    BuildPartitionJoineries(pixels?: Array<glMatrix.vec2>, joineryDatas?: any) : void {
        if (pixels) {
            this._partitionJoineriesFactory.Pixels = pixels;
        }

        if (joineryDatas) {
            this._partitionJoineriesFactory.JoineryDatas = joineryDatas;
        }
        this._partitionJoineriesFactory.Build();
    }

    Rebuild() : void {
        this.BuildWalls();
        this.BuildSlopes();
        this.BuildWallJoineries();
        this.BuildPartitions();
        this.BuildSlopesPartition();
        this.BuildPartitionJoineries();
    }

    _NDC(value: glMatrix.vec3) : glMatrix.vec3 {
        return glMatrix.vec3.fromValues((value[0] / this._width) * 2.0 - 1.0, (1.0 - value[1] / this._height) * 2.0 - 1.0, 1);
    }

    _computeCamera() : void {
        this._computeFocalLength();
        this._computeCameraPose();
    }

    _computeFocalLength() : void {
        var vanishingLine = new Line(this.Fv, this.Fu);
        var Puv = vanishingLine.Project(glMatrix.vec3.fromValues(this._P[0], this._P[1], 0));

        var FvPuv = glMatrix.vec3.create();
        glMatrix.vec3.subtract(FvPuv, Puv, this.Fv);
        var PuvFu = glMatrix.vec3.create();
        glMatrix.vec3.subtract(PuvFu, this.Fu, Puv);
        var OPuv2 = glMatrix.vec3.length(FvPuv) * glMatrix.vec3.length(PuvFu);
        var PPuv = glMatrix.vec3.create();
        glMatrix.vec3.subtract(PPuv, Puv, this._P);
        var PPuv2 = glMatrix.vec3.squaredLength(PPuv);
        this._focalLength = Math.max(1, Math.sqrt(OPuv2 - PPuv2) * 35 / this._width + this._offsetFocalLength);
        this._fovX = Functions.radian2degree(2.0 * Math.atan(35 / (2.0 * this._focalLength)));

        this._verticalShift = -PPuv[1] / this._width;

        var shift_height = this._width * this.VerticalShift;
        this._camera2image = glMatrix.mat4.fromValues(
            2 * this._focalLength / 35, 0, 0, 0,
            0, 2 * this._focalLength / (35 * (this._height / this._width)), 0, 0,
            2 * (this._P[0] / this._width) - 1, 2 * ((this._P[1] - shift_height) / this._height) - 1, -1000001 / 999999, -1, 
            0, 0, 2 * 1000000 / -999999, 0);
        glMatrix.mat4.invert(this._image2camera, this._camera2image);
    }

    _computeCameraPose() : void {
        var uRc = glMatrix.vec3.create();
        glMatrix.vec3.transformMat4(uRc, this._NDC(this.Fu), this._image2camera);
        glMatrix.vec3.normalize(uRc, uRc);

        var vRc = glMatrix.vec3.create();
        glMatrix.vec3.transformMat4(vRc, this._NDC(this.Fv), this._image2camera);
        glMatrix.vec3.normalize(vRc, vRc);

        var wRc = glMatrix.vec3.create();
        glMatrix.vec3.cross(wRc, uRc, vRc);
        glMatrix.vec3.normalize(wRc, wRc);

        if (this._renormalize) {
            glMatrix.vec3.cross(vRc, wRc, uRc);
            glMatrix.vec3.cross(uRc, vRc, wRc);
        }

        this._world2camera = glMatrix.mat4.fromValues(
            uRc[0], uRc[1], uRc[2], 0,
            vRc[0], vRc[1], vRc[2], 0,
            wRc[0], wRc[1], wRc[2], 0,
            0, 0, 0, 1);
        glMatrix.mat4.invert(this._camera2world, this._world2camera);

        var AP = glMatrix.vec3.fromValues(0, this._worldHeight, 0);
        var ApRc = glMatrix.vec3.create();
        glMatrix.vec3.transformMat4(ApRc, this._NDC(this._APi.A), this._image2camera);
        var PpRc = glMatrix.vec3.create();
        glMatrix.vec3.transformMat4(PpRc, this._NDC(this._APi.B), this._image2camera);
        var APpp = glMatrix.vec3.create();
        glMatrix.vec3.add(APpp, ApRc, AP);
        var D = new Line(glMatrix.vec3.fromValues(ApRc[1], ApRc[2], 0), glMatrix.vec3.fromValues(APpp[1], APpp[2], 0));
        var OPpRc = new Line(glMatrix.vec3.fromValues(0, 0, 0), glMatrix.vec3.fromValues(PpRc[1], PpRc[2], 0));
        var PppRc = D.Intersect(OPpRc);
        if (!PppRc) {
            return;
        }
        PppRc = glMatrix.vec3.fromValues(PpRc[0], PppRc[0], PppRc[1]);

        var APRc = glMatrix.vec3.create();
        glMatrix.vec3.transformMat4(APRc, AP, this._world2camera);
        var ApPppRc = glMatrix.vec3.create();
        glMatrix.vec3.subtract(ApPppRc, PppRc, ApRc);

        var lOARc = (glMatrix.vec3.length(ApRc) * glMatrix.vec3.length(APRc)) / glMatrix.vec3.length(ApPppRc);

        var nApRc = glMatrix.vec3.create();
        glMatrix.vec3.normalize(nApRc, ApRc);

        var OARc = glMatrix.vec3.fromValues(-nApRc[0] * lOARc, -nApRc[1] * lOARc, -nApRc[2] * lOARc);
        glMatrix.vec3.transformMat4(this._cameraPosition, OARc, this._camera2world);

        this._camera2world[12] = this._cameraPosition[0];
        this._camera2world[13] = this._cameraPosition[1];
        this._camera2world[14] = this._cameraPosition[2];
        glMatrix.mat4.invert(this._world2camera, this._camera2world);

        glMatrix.mat4.multiply(this._image2world, this._camera2world, this._image2camera);
    }

    OutsideNDC(pixel: glMatrix.vec3) : boolean {
        var NDC = this._NDC(pixel);
        return (NDC[0] < -1 || NDC[0] > 1 || NDC[1] < -1 || NDC[1] > 1);
    }

    Unproject(pixel: glMatrix.vec3) : glMatrix.vec3 {
        var far = glMatrix.vec3.create();
        var ndc = this._NDC(pixel);
        glMatrix.vec3.transformMat4(far, ndc, this._image2world);
        var near = glMatrix.vec3.create();
        ndc[2] = 0;
        glMatrix.vec3.transformMat4(near, ndc, this._image2world);
        var direction = glMatrix.vec3.create();
        glMatrix.vec3.subtract(direction, far, near);
        return direction;
    }

    ProjectOnGround(pixel: glMatrix.vec3) : glMatrix.vec3 {
        var direction = this.Unproject(pixel);

        //Return null if no intersection
        var result = this._ground.Intersect(new Ray(this._cameraPosition, direction));
        if (result !== null) {
            result[2] = 0;
        }
        return result;
    }

    ProjectOnPartitionGround(pixel: glMatrix.vec3) : glMatrix.vec3 {
        var direction = this.Unproject(pixel);

        //Return null if no intersection
        var result = this._ground_partition.Intersect(new Ray(this._cameraPosition, direction));
        if (result !== null) {
            result[2] = 0;
        }
        return result;
    }

    ProjectJoineryOnGround(pixel: glMatrix.vec3) : glMatrix.vec3 {
        var plane = new Plane(glMatrix.vec3.create(), glMatrix.vec3.fromValues(0, 0, 1));
        var direction = this.Unproject(pixel);
        return plane.Intersect(new Ray(this._cameraPosition, direction));
    }

    ProjectJoineryOnCeiling(pixel: glMatrix.vec3) : glMatrix.vec3 {
        var plane = new Plane(glMatrix.vec3.fromValues(0, 0, this._wallHeight), glMatrix.vec3.fromValues(0, 0, -1));
        var direction = this.Unproject(pixel);
        return plane.Intersect(new Ray(this._cameraPosition, direction));
    }

    FindClosestWall(pixel: glMatrix.vec3, backface: boolean) : {intersection: glMatrix.vec3, wall: Wall} | null {
        var direction = this.Unproject(pixel);

        //Search all walls that intersect with this ray
        return this.FindClosestWallWithRay(new Ray(this._cameraPosition, direction), false, backface, false) as {intersection: glMatrix.vec3, wall: Wall} | null;
    }

    FindClosestWallOrSlope(pixel, dualside?: boolean, infinite?: boolean) : {intersection: glMatrix.vec3, wall: Wall | Slope} | null {
        var direction = this.Unproject(pixel);

        //Search all walls that intersect with this ray
        return this.FindClosestWallOrSlopeWithRay(new Ray(this._cameraPosition, direction), dualside, infinite);
    }

    _getClosestCandidate(candidates: Array<{intersection: glMatrix.vec3, wall: Wall | Slope | Partition}>, ray: Ray) : {intersection: glMatrix.vec3, wall: Wall | Slope | Partition} | null {
        var result = candidates[0];
        var vD = glMatrix.vec3.create();
        glMatrix.vec3.subtract(vD, result.intersection, ray.O);
        var minDistance = glMatrix.vec3.length(vD);
        for (var i = 1; i < candidates.length; ++i) {
            var current = candidates[i];
            glMatrix.vec3.subtract(vD, current.intersection, ray.O);
            var distance = glMatrix.vec3.length(vD);
            if (distance < minDistance) {
                minDistance = distance;
                result = current;
            }
        }

        return result;
    }

    FindClosestWallWithRay(ray: Ray, dualside: boolean, backface: boolean, infinite: boolean) : {intersection: glMatrix.vec3, wall: Wall} | null {
        //Search all walls that intersect with this ray
        var candidates = new Array<{intersection: glMatrix.vec3, wall: Wall}>();
        for (var i = 0; i < this.Model.Walls.length; ++i) {
            let wall = this.Model.Walls[i];
            var intersection = wall.Intersect(ray, dualside, backface, infinite);
            if (intersection !== null) {
                candidates.push({
                    intersection: intersection,
                    wall: wall
                });
            }
        }

        if (candidates.length === 0) {
            return null;
        }

        //find the closest wall
        return this._getClosestCandidate(candidates, ray) as {intersection: glMatrix.vec3, wall: Wall} | null;
    }

    FindClosestWallOrSlopeWithRay(ray: Ray, dualside: boolean, infinite: boolean) : {intersection: glMatrix.vec3, wall: Wall | Slope} | null {
        //Search all walls that intersect with this ray
        var candidates = new Array<{intersection: glMatrix.vec3, wall: Wall | Slope}>;
        for (var i = 0; i < this.Model.Walls.length; ++i) {
            let wall = this.Model.Walls[i];
            var intersection = wall.Intersect(ray, dualside, false, infinite);
            if (intersection !== null) {
                candidates.push({
                    intersection: intersection,
                    wall: wall
                });
            }
        }

        for (var i = 0; i < this.Model.Slopes.length; ++i) {
            let slope = this.Model.Slopes[i];
            var intersection = slope.Intersect(ray, dualside, false, infinite);
            if (intersection !== null) {
                candidates.push({
                    intersection: intersection,
                    wall: slope
                });
            }
        }

        if (candidates.length === 0) {
            return null;
        }

        //find the closest wall
        return this._getClosestCandidate(candidates, ray);
    }

    FindClosestPartition(pixel: glMatrix.vec3) : {intersection: glMatrix.vec3, wall: Partition} | null {
        var direction = this.Unproject(pixel);

        //Search all walls that intersect with this ray
        return this.FindClosestPartitionWithRay(new Ray(this._cameraPosition, direction)) as {intersection: glMatrix.vec3, wall: Partition} | null;
    }

    FindClosestPartitionWithRay(ray: Ray, dualside: boolean = false) : {intersection: glMatrix.vec3, wall: Partition} | null {
        var candidates = new Array<{intersection: glMatrix.vec3, wall: Partition}>;
        //Search all partitions that intersect with this ray
        for (var i = 0; i < this.Model.Partitions.length; ++i) {
            let partition = this.Model.Partitions[i];
            var intersection = partition.Intersect(ray, dualside, false, false);
            if (intersection !== null) {
                candidates.push({
                    intersection: intersection,
                    wall: partition
                });
            }
        }

        if (candidates.length === 0) {
            return null;
        }

        //find the closest wall
        return this._getClosestCandidate(candidates, ray) as {intersection: glMatrix.vec3, wall: Partition} | null;
    }

    FindClosestWallOrPartitionWithRay(ray: Ray, dualside: boolean) : {intersection: glMatrix.vec3, wall: Wall | Partition} | null {
        //Search all walls that intersect with this ray
        var candidates = new Array<{intersection: glMatrix.vec3, wall: Wall | Partition}>();
        for (var i = 0; i < this.Model.Walls.length; ++i) {
            let wall = this.Model.Walls[i];
            var intersection = wall.Intersect(ray, dualside, false, false);
            if (intersection !== null) {
                candidates.push({
                    intersection: intersection,
                    wall: wall
                });
            }
        }

        //Search all partitions that intersect with this ray
        for (var i = 0; i < this.Model.Partitions.length; ++i) {
            let partition = this.Model.Partitions[i];
            var intersection = partition.Intersect(ray, dualside, false, false);
            if (intersection !== null) {
                candidates.push({
                    intersection: intersection,
                    wall: partition
                });
            }
        }

        if (candidates.length === 0) {
            return null;
        }

        //find the closest wall
        return this._getClosestCandidate(candidates, ray) as {intersection: glMatrix.vec3, wall: Wall | Partition} | null;
    }

    FindClosestPartitionOrSlope(pixel: glMatrix.vec3, dualside: boolean, infinite: boolean) : {intersection: glMatrix.vec3, wall: Slope | Partition} | null {
        var direction = this.Unproject(pixel);

        //Search all walls that intersect with this ray
        return this.FindClosestPartitionOrSlopeWithRay(new Ray(this._cameraPosition, direction), dualside, infinite) as {intersection: glMatrix.vec3, wall: Slope | Partition} | null;
    }

    FindClosestPartitionOrSlopeWithRay(ray: Ray, dualside: boolean, infinite: boolean) : {intersection: glMatrix.vec3, wall: Slope | Partition} | null {
        //Search all walls that intersect with this ray
        var candidates = [];
        for (var i = 0; i < this.Model.Partitions.length; ++i) {
            let wall = this.Model.Partitions[i];
            var intersection = wall.Intersect(ray, dualside, false, infinite);
            if (intersection !== null) {
                candidates.push({
                    intersection: intersection,
                    wall: wall
                });
            }
        }

        for (var i = 0; i < this.Model.SlopesPartition.length; ++i) {
            let slope = this.Model.SlopesPartition[i];
            var intersection = slope.Intersect(ray, dualside, false, infinite);
            if (intersection !== null) {
                candidates.push({
                    intersection: intersection,
                    wall: slope
                });
            }
        }

        if (candidates.length === 0) {
            return null;
        }

        //find the closest wall
        return this._getClosestCandidate(candidates, ray) as {intersection: glMatrix.vec3, wall: Slope | Partition} | null;
    }

    Project(value : glMatrix.vec3) : glMatrix.vec3 {
        var world2image = glMatrix.mat4.create();
        glMatrix.mat4.invert(world2image, this._image2world);
        var result = glMatrix.vec3.create();
        glMatrix.vec3.transformMat4(result, value, world2image);
        return glMatrix.vec3.fromValues(((result[0] + 1) / 2) * this._width, (1 - (result[1] + 1) / 2) * this._height, 0);
    }

}
