import { RenderCamera, SceneConstants, Transform } from '../../SavaneJS';
import { TemporaryEntity } from './TemporaryEntity';

/**
 * TemporaryRenderCamera is used for renderCamera edition
 */
export class TemporaryRenderCamera extends TemporaryEntity {
    public fov: number;
    public iso: number;
    public cameraType: SceneConstants.CameraType;
    public quality: number;
    public isFinalRender: boolean;
    public renderWidth: number;
    public renderHeight: number;
    public shutterSpeed: number;
    public fNumber: number;
    public cameraNb: number;
    public denoising: boolean;
    public autoexposure: boolean;
    public whitebalance: boolean;
    public hd: boolean;
    public renderType: number;
    public format: SceneConstants.CameraPreset;
    public excludedObjectIds: Array<number>;
    public shoppingList: Array<string>;
    public entityShoppingList: Array<number>;
    public exposure: number;
    public verticalShift: number;
    public dof: number;
    public aperture: number;
    public nbImages: number;
    public sunOffsetRotation: number;
    public sunOffsetAltitude: number;
    private _transform: Transform;

    constructor(renderCamera: RenderCamera) {
        super(renderCamera);

        this.fov = renderCamera.fov;
        this.iso = renderCamera.iso;
        this.cameraType = renderCamera.cameraType;

        this.quality = renderCamera.quality;
        this.isFinalRender = renderCamera.isFinalRender;
        this.renderWidth = renderCamera.renderWidth;
        this.renderHeight = renderCamera.renderHeight;
        this.shutterSpeed = renderCamera.shutterSpeed;
        this.fNumber = renderCamera.fNumber;
        this.cameraNb = renderCamera.cameraNb;
        this.denoising = renderCamera.denoising;
        this.autoexposure = renderCamera.autoexposure;
        this.whitebalance = renderCamera.whitebalance;
        this.hd = renderCamera.hd;
        this.renderType = renderCamera.renderType;
        this.format = renderCamera.format;
        this.excludedObjectIds = renderCamera.excludedObjectIds.slice();
        this.shoppingList = renderCamera.shoppingList.slice();
        this.entityShoppingList = renderCamera.entityShoppingList.slice();
        this.exposure = renderCamera.exposure;
        this.verticalShift = renderCamera.verticalShift;
        this.dof = renderCamera.dof;
        this.aperture = renderCamera.aperture;
        this.nbImages = renderCamera.nbImages;
        this.sunOffsetRotation = renderCamera.sunOffsetRotation;
        this.sunOffsetAltitude = renderCamera.sunOffsetAltitude;

        this._transform = new Transform(null);
        this._transform.clone(renderCamera.transform);
    }

    computeValidity() {
        this._isValid = true;
    }

    get transform(): Transform {
        return this._transform;
    }
}
