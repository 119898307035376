import { Command, CommandEnum, EditEntityCommand, EditGeometryPrimitiveCommand } from './CommandModule';
import { Events } from '../events';
import { eventsManager } from '../managers/EventsManager';
import { Entity, GeometryPrimitive } from '../SavaneJS';

export class EditEntitiesCommand  extends Command
{
    // Command list to execute and undo
    private _subCommands: Array<Command> = [];
    // Record the entity Array
    private _editedEntitiesArray: Array<Entity>;

    constructor(editedEntitiesArray: Array<Entity>) {
        super();
        this._editedEntitiesArray = editedEntitiesArray;
        // Parse all entity array passed to the constructor
        for (let i = 0; i < editedEntitiesArray.length; i++) {
            // And create a delete entity command for each of them
            if (editedEntitiesArray[i].isGeometryPrimitiveEntity()) {
                this._subCommands.push(new EditGeometryPrimitiveCommand(editedEntitiesArray[i] as GeometryPrimitive));
            }
            else {
                this._subCommands.push(new EditEntityCommand(editedEntitiesArray[i]));
            }
        }
    }

    // Command name returned from a global enum
    name(): string {
        return CommandEnum.EditEntitiesCommand;
    }

    needsRefreshHull(): boolean {
        for (let i = 0; i < this._subCommands.length; i++) {
            if (this._subCommands[i].needsRefreshHull()) {
                return (true);
            }
        }

        return (false);
    }

    // Undo the current command
    undo() {
        // Parse all commands created by the constructor and undo them all
        for (let i = 0; i < this._subCommands.length; i++) {
            this._subCommands[i].undo();
        }

        // Execute parent function
        super.undo();

        // Select edited object at the end
        eventsManager.instance.dispatch(Events.CHANGE_EDITOR_SELECTION,
            {
                selection: this._editedEntitiesArray,
                keepSelected: false,
                showTulip: false
            });
    }

    // Execute current command (redo)
    execute() {
        // Parse all commands created by the constructor and execute them all
        for (let i = 0; i < this._subCommands.length; i++) {
            this._subCommands[i].execute();
        }

        //Execute parent function
        super.execute();

        // Select edited object at the end
        eventsManager.instance.dispatch(Events.CHANGE_EDITOR_SELECTION,
            {
                selection: this._editedEntitiesArray,
                keepSelected: false,
                showTulip: false
            });
    }
};
