import { Command, CommandEnum, DeleteTechnicalElementCommand } from './CommandModule';
import { Events } from '../events';
import { eventsManager } from '../managers/EventsManager';
import { TechnicalElement } from '../SavaneJS';

export class DeleteTechnicalElementsCommand extends Command
{
    // Command list to execute and undo
    private _subCommands: Array<Command> = [];
    // Array of technical elements to delete
    private _deletedTechnicalElementsArray: Array <TechnicalElement>;

    constructor(technicalElementsArray: Array <TechnicalElement>) {
        super();
        // Record the staircase entities Array
        this._deletedTechnicalElementsArray = technicalElementsArray;
        // Parse all staircase array passed to the constructor
        for (let i = 0; i < technicalElementsArray.length; i++) {
            // And create a staircase command for each of them
            this._subCommands.push(new DeleteTechnicalElementCommand(technicalElementsArray[i]));
        }
    };

    // Command name returned from a global enum
    name(): string {
        return CommandEnum.DeleteTechnicalElementsCommand;
    }

    // Undo the current command
    undo() {
        // Parse all commands created by the constructor and undo them all
        for (let i = 0; i < this._subCommands.length; i++) {
            this._subCommands[i].undo();
        }

        //Execute parent function
        super.undo();

        // Select deleted objects at the end
        eventsManager.instance.dispatch(Events.CHANGE_EDITOR_SELECTION,
            {
                selection: this._deletedTechnicalElementsArray,
                keepSelected: false,
                showTulip: false
            });
    }

    // Execute current command (redo)
    execute() {
        // Parse all commands created by the constructor and execute them all
        for (let i = 0; i < this._subCommands.length; i++) {
            this._subCommands[i].execute();
        }

        //Execute parent function
        super.execute();
    }
};
