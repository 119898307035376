
import { SavaneCookie } from '../../utils/SavaneCookie';
import { TemporaryJoinery } from './../temporary/TemporaryJoinery';
import { JoineryType, ComponentConstants, SceneConstants, math } from '../../SavaneJS';
import { Joinery } from './Joinery';

/**
 * Joinery  of type : Door
 */
export class Door extends Joinery
{
    // Default nbDoors for a door is 1
    private _nbDoors: number = 1;
    // Opening mode slide or default
    private _openingMode: SceneConstants.OpeningMode = SceneConstants.OpeningMode.mode_default;
    // Slide mode params
    public slideDirection: SceneConstants.SlideDirection = SceneConstants.SlideDirection.slide_left;
    // Default mode params
    private _handleSide: SceneConstants.HandleSide = SceneConstants.HandleSide.handle_left;
    public openingSymetry: SceneConstants.Symetry = SceneConstants.Symetry.default;
    // Default door isn't opened
    private _isOpened: boolean = false;
    private _leftOpeningAngle: number = 90;
    private _rightOpeningAngle: number = 90;
    
    constructor(joineryType: SceneConstants.JoineryType, position: math.vec3) {
        super(joineryType, position);

        // Adjust joinery 3D model thanks to the parameters set above
        this.setJoineryType();
    }

    // Getters and setters for opening mode (normal or sliding)
    get openingMode(): SceneConstants.OpeningMode {
        if (this.temporary === null) {
            return this._openingMode;
        }
        else {
            return (this.temporary as TemporaryJoinery).openingMode;
        }
    }

    /**
     * Change the opening mode to a value from SceneConstants.OpeningMode
     *
     * @param om
     */
    set openingMode(om: SceneConstants.OpeningMode) {
        // Boolean to detect if a change is performed to update 3D model only if a change is performed
        let change = false;

        if (this.temporary === null) {
            if (this._openingMode !== om) {
                this._openingMode = om;
                change = true;
            }
            if (this.nbDoors > SceneConstants.MaxDoorJoineryDefault && om === SceneConstants.OpeningMode.mode_default) {
                throw(new Error('Door count can\'t be more than {SceneConstants.MaxDoorJoineryDefault}:' + SceneConstants.MaxDoorJoineryDefault));
            }
        }
        else
        {
            if ((this.temporary as TemporaryJoinery).openingMode !== om) {
                (this.temporary as TemporaryJoinery).openingMode = om;
                change = true;
            }
        }

        // Set joinery 3D model only if a change is really done
        if (change) {
            // Set default 3D model after this change
            this.setJoineryType();
        }
    }

    /**
     * Set the correct AM joinery 3D model depending on door parameters
     */
    setJoineryType() {
        // Get all JoineryType entity components
        let componentList = this.getComponents(ComponentConstants.ComponentType.JoineryType);

        // Is there any existing JoineryType component ?
        if (componentList.length > 0) {
            // Yes ! There is only one joinery type component for a joinery so it will be index 0 for sure

            if (this.joineryType === SceneConstants.JoineryType.frontDoor) {
                (componentList[0] as JoineryType).joineryTypeId = SavaneCookie.getCookie("Rhinov-Savane-DefaultJoineryModelDoorFront", SceneConstants.DefaultJoineryType.frontDoor);
            }
            else {
                // Normal (default) opening mode or sliding ?
                if (this.openingMode === SceneConstants.OpeningMode.mode_default) {
                    // Single door model
                    if (this.nbDoors === 1) {
                        (componentList[0] as JoineryType).joineryTypeId = SavaneCookie.getCookie("Rhinov-Savane-DefaultJoineryModelDoor", SceneConstants.DefaultJoineryType.door);
                    }
                    else {
                        // Double door model
                        (componentList[0] as JoineryType).joineryTypeId = SavaneCookie.getCookie("Rhinov-Savane-DefaultJoineryModelDoubleDoor", SceneConstants.DefaultJoineryType.doubleDoor);
                    }
                }
                else {
                    // Single door model only 1 door is linked to cookies, 2 is hardcoded;
                    if (this.nbDoors === 1) {
                        // Sliding door model
                        (componentList[0] as JoineryType).joineryTypeId = SavaneCookie.getCookie("Rhinov-Savane-DefaultJoineryModelSlidingDoor", SceneConstants.DefaultJoineryType.slidingDoor);
                    }
                    else {
                        (componentList[0] as JoineryType).joineryTypeId = SceneConstants.DefaultJoineryType.slidingDoubleDoor;
                    }
                }
            }
        }
        else {
            // No so we will create the component now
            if (this.joineryType === SceneConstants.JoineryType.frontDoor) {
                this.addComponent(new JoineryType(SavaneCookie.getCookie("Rhinov-Savane-DefaultJoineryModelDoorFront", SceneConstants.DefaultJoineryType.frontDoor), undefined, undefined));
            }
            else {
                // Normal (default) opening mode or sliding ?
                if (this.openingMode === SceneConstants.OpeningMode.mode_default) {
                    // Single door model
                    if (this.nbDoors === 1) {
                        this.addComponent(new JoineryType(SavaneCookie.getCookie("Rhinov-Savane-DefaultJoineryModelDoor", SceneConstants.DefaultJoineryType.door), undefined, undefined));
                    }
                    else {
                        // Double door model
                        this.addComponent(new JoineryType(SavaneCookie.getCookie("Rhinov-Savane-DefaultJoineryModelDoubleDoor", SceneConstants.DefaultJoineryType.doubleDoor), undefined, undefined));
                    }
                }
                else {
                    // Single door model only 1 door is linked to cookies, 2 is hardcoded;
                    if (this.nbDoors === 1) {
                        // Sliding door model
                        this.addComponent(new JoineryType(SavaneCookie.getCookie("Rhinov-Savane-DefaultJoineryModelSlidingDoor", SceneConstants.DefaultJoineryType.slidingDoor), undefined, undefined));
                    }
                    else {
                        this.addComponent(new JoineryType(SceneConstants.DefaultJoineryType.slidingDoubleDoor, undefined, undefined));
                    }
                }
            }
        }
    }

    // Getters and setters for isOpened
    get isOpened(): boolean {
        if (this.temporary === null) {
            return this._isOpened;
        }
        else {
            return (this.temporary as TemporaryJoinery).isOpened;
        }
    }

    set isOpened(o: boolean) {
        if (this.temporary === null) {
            this._isOpened = o;
        }
        else {
            (this.temporary as TemporaryJoinery).isOpened = o;
        }
    }

    get leftOpeningAngle(): number {
        if (this.temporary === null) {
            return this._leftOpeningAngle;
        }
        else {
            return (this.temporary as TemporaryJoinery).leftOpeningAngle;
        }
    }

    set leftOpeningAngle(oa: number) {
        if (this.temporary === null) {
            this._leftOpeningAngle = oa;
        }
        else {
            (this.temporary as TemporaryJoinery).leftOpeningAngle = oa;
        }
    }

    get rightOpeningAngle(): number {
        if (this.temporary === null) {
            return this._rightOpeningAngle;
        }
        else {
            return (this.temporary as TemporaryJoinery).rightOpeningAngle;
        }
    }

    set rightOpeningAngle(oa: number) {
        if (this.temporary === null) {
            this._rightOpeningAngle = oa;
        }
        else {
            (this.temporary as TemporaryJoinery).rightOpeningAngle = oa;
        }
    }

    // Getters and setters for number of doors
    get nbDoors(): number {
        if (this.temporary === null) {
            return this._nbDoors;
        }
        else {
            return (this.temporary as TemporaryJoinery).nbDoors;
        }
    }

    set nbDoors(d: number) {
        // Boolean to detect if a change is performed to update 3D model only if a change is performed
        let change = false;

        if (this.openingMode === SceneConstants.OpeningMode.mode_default) {
            if (d > SceneConstants.MaxDoorJoineryDefault) {
                throw(new Error('Door count can\'t be more than {SceneConstants.MaxDoorJoineryDefault}:' + SceneConstants.MaxDoorJoineryDefault));
            }
            if (d > 1) {
                this.handleSide = SceneConstants.HandleSide.handle_center; //Handle side forced for {SceneConstants.MaxDoorJoineryDefault} doors
            }
        }
        else {
            if (this.openingMode === SceneConstants.OpeningMode.mode_slide) {
                if (d > SceneConstants.MaxDoorJoinerySlide) {
                    throw(new Error('Door count can\'t be more than {SceneConstants.MaxDoorJoinerySlide}:' + SceneConstants.MaxDoorJoinerySlide));
                }
                if (d > 1) {
                    this.handleSide = SceneConstants.HandleSide.handle_center; //Handle side forced for {SceneConstants.MaxDoorJoineryDefault} doors
                }
            }
        }

        if (this.temporary === null) {
            if (this._nbDoors !== d) {
                this._nbDoors = d;
                change = true;
            }
        }
        else {
            if ((this.temporary as TemporaryJoinery).nbDoors !== d) {
                (this.temporary as TemporaryJoinery).nbDoors  = d;
                change = true;
            }
        }

        // Set joinery 3D model only if a change is really performed
        if (change) {
            // Set default 3D model after this change
            this.setJoineryType();
        }
    }

    // Getters and setters for the opening side
    get handleSide(): SceneConstants.HandleSide {
        if (this.temporary === null) {
            return this._handleSide;
        }
        else {
            return((this.temporary as TemporaryJoinery).handleSide);
        }
    }

    set handleSide(s: SceneConstants.HandleSide) {
        if (this._nbDoors === 1) {
            if (this.temporary === null) {
                this._handleSide = s;
            }
            else {
                (this.temporary as TemporaryJoinery).handleSide = s;
            }
        }
        else {
            if (s !== SceneConstants.HandleSide.handle_center) {
                // More than two doors handle side can't be changed
                throw(new Error('HandleSide is forced to handle_center with more than {SceneConstants.MaxDoorJoineryDefault}:' + SceneConstants.MaxDoorJoineryDefault + ' door on default opening mode'));
            }
            else {
                if (this.temporary === null) {
                    this._handleSide = s;
                }
                else {
                    (this.temporary as TemporaryJoinery).handleSide = s;
                }
            }
        }
    }

    // Called when a property is changed to verify max and min boundaries or to perform computations before final assignment
    addToCappedProperty(property: string, value: any) : number {
        // -1 = unknwon property, 0 = no cap, 1 = minus cap, 2 max cap will be returned at the end
        let capped = 0;

        if (value === 0) {
            return(capped);
        }

        if (property === "nbDoors") {
            if (this[property] + value <= 1) {
                this[property]  = 1;
                capped          = 1;
            }

            if (this.openingMode === SceneConstants.OpeningMode.mode_default) {
                if (this[property] + value >= SceneConstants.MaxDoorJoineryDefault) {
                    this[property]  = SceneConstants.MaxDoorJoineryDefault;
                    capped          = 2;
                }
            }
            else {
                if (this[property] + value >= SceneConstants.MaxDoorJoinerySlide) {
                    this[property]  = SceneConstants.MaxDoorJoinerySlide;
                    capped          = 2;
                }
            }

            if (capped === 0) {
                this[property] += value;
            }
        }
        else {
            return super.addToCappedProperty(property, value);
        }

        return capped;
    }
}
