import { SavaneCookie } from "../utils/SavaneCookie";
import { TemporaryRoom } from "./temporary/TemporaryModule";
import { Component, Coating, CorniceType, FloorCoatingArea, Functionality, ComponentConstants, EntityFactory, Wall, FunctionalityChip, ArrangementGroup, ArrangementObject, SceneConstants, SavaneConstants, wallManager, roomManager, math, SavaneMath, Segment } from "../SavaneJS";
import { Entity } from "./Entity";

/**
 * Room is an Entity representing a room.
 *
 * @constructor
 */
export class Room extends Entity {
    // Room walls
    private _walls: Array<Wall> = [];
    // Walls not part of the room's enclosure
    public nonRoomedWalls: Array<Wall> = [];
    public wallsOrderer: boolean = false;
    private lastTriangles: Array<Array<math.vec3>> = null;
    private lastAestheticCenter: math.vec3 = null;
    private _height: number = 2500;
    private _floorHeight: number = 0;
    private _isOrdered: boolean = false;
    // Plinths
    private _hasPlinths: boolean = true;
    private _plinthsHeight: number = 100;
    private _plinthsMaterialType: number = 0;
    // Cornices
    private _hasCornices: boolean = false;

    private preCalcInnerPoints: Array<math.vec3> = null;
    private preCalcOutterPoints: Array<math.vec3> = null;
    private preCalcInnerPointsAndWalls: any = null;

    constructor(id: number) {
        super();

        this.id = id;
        this.name = "Room " + id;
    }

    // Returns the hang type to apply to a coating for a wall so that the coating faces the room
    getHangTypeForWall(wall: Wall): Coating.HangType {
        return wall.getHangTypeForRoom(this);
    }

    /**
     * return the type of entity : room
     *
     * @returns {*}
     */
    get entityType(): SceneConstants.EntityType {
        return SceneConstants.EntityType.Room;
    }

    get plinths(): boolean {
        if (this.temporary === null) {
            return this._hasPlinths;
        } else {
            return (this.temporary as TemporaryRoom).hasPlinths;
        }
    }

    set plinths(p: boolean) {
        if (this.temporary === null) {
            this._hasPlinths = p;
        } else {
            (this.temporary as TemporaryRoom).hasPlinths = p;
        }
    }

    get hasPlinths(): boolean {
        return this.plinths;
    }

    set hasPlinths(p: boolean) {
        this.plinths = p;
    }

    get cornices():boolean {
        if (this.temporary === null) {
            return this._hasCornices;
        } else {
            return (this.temporary as TemporaryRoom).hasCornices;
        }
    }

    set cornices(c: boolean) {
        if (this.temporary === null) {
            this._hasCornices = c;
        } else {
            (this.temporary as TemporaryRoom).hasCornices = c;
        }
        if (c === true) {
            this.addComponent(new CorniceType(SavaneCookie.getCookie("Rhinov-Savane-DefaultCorniceModel", SceneConstants.DefaultWallType.cornice)));
        } else {
            // Get all cornice type components
            let componentList = this.getComponents(ComponentConstants.ComponentType.CorniceType);
            // And remove them all
            for (let i = 0; i < componentList.length; i++) {
                this.removeComponent(componentList[i]);
            }
        }
    }

    get isOrdered(): boolean {
        return this._isOrdered;
    }

    set isOrdered(o: boolean) {
        this._isOrdered = o;
    }

    get hasCornices(): boolean {
        return this.cornices;
    }

    set hasCornices(c: boolean) {
        this.cornices = c;
    }

    get height(): number {
        if (this.temporary === null) {
            return this._height;
        } else {
            return (this.temporary as TemporaryRoom).height;
        }
    }

    set height(h: number) {
        if (this.temporary === null) {
            this._height = h;
        } else {
            (this.temporary as TemporaryRoom).height = h;
        }
    }

    get floorHeight(): number {
        if (this.temporary === null) {
            return this._floorHeight;
        } else {
            return (this.temporary as TemporaryRoom).floorHeight;
        }
    }

    set floorHeight(fh: number) {
        if (this.temporary === null) {
            for (let i = 0; i < this.walls.length; i++) {
                let wall = this.walls[i];

                for (let j = 0; j < wall.joineries.length; j++) {
                    if (wall.joineries[j].floorHeight === this._floorHeight) {
                        wall.joineries[j].floorHeight = fh;
                    }
                    if (wall.joineries[j].floorHeight <= fh) {
                        wall.joineries[j].floorHeight = fh;
                    }

                    if (wall.joineries[j].joineryType !== SceneConstants.JoineryType.velux) {
                        if (wall.joineries[j].floorHeight + wall.joineries[j].height > wall.height) {
                            wall.joineries[j].height = wall.height - wall.joineries[j].floorHeight;
                            if (wall.joineries[j].transom) {
                                wall.joineries[j].transom = false;
                            }
                        }

                        if (wall.joineries[j].bottomTransom) {
                            if (wall.joineries[j].bottomTransomHeight === this._floorHeight) {
                                wall.joineries[j].bottomTransomHeight = fh;
                            }
                        }
                    }
                }
            }
            for (let i = 0; i < this.nonRoomedWalls.length; i++) {
                let wall = this.nonRoomedWalls[i];

                for (let j = 0; j < wall.joineries.length; j++) {
                    if (wall.joineries[j].floorHeight === this._floorHeight) {
                        wall.joineries[j].floorHeight = fh;
                    }
                    if (wall.joineries[j].floorHeight <= fh) {
                        wall.joineries[j].floorHeight = fh;
                    }

                    if (wall.joineries[j].joineryType !== SceneConstants.JoineryType.velux) {
                        if (wall.joineries[j].floorHeight + wall.joineries[j].height > wall.height) {
                            wall.joineries[j].height = wall.height - wall.joineries[j].floorHeight;
                            if (wall.joineries[j].transom) {
                                wall.joineries[j].transom = false;
                            }
                        }

                        if (wall.joineries[j].bottomTransom) {
                            if (wall.joineries[j].bottomTransomHeight === this._floorHeight) {
                                wall.joineries[j].bottomTransomHeight = fh;
                            }
                        }
                    }
                }
            }
            if (this.parent) {
                for (let i = 0; i < this.floor.technicalElementsWithStaircases.length; i++) {
                    let techElement = this.floor.technicalElementsWithStaircases[i];

                    let room = roomManager.getRoomAtPosition(techElement.position, this.floor);

                    if (room) {
                        if (room.id === this.id) {
                            if (techElement.objectId === SceneConstants.TechnicalElementType.beam || techElement.objectId === SceneConstants.TechnicalElementType.frame || techElement.objectId === SceneConstants.TechnicalElementType.rosette) {
                                // Don't lift up technical elements attached to ceiling
                                techElement.floorHeight -= fh - this.floorHeight;
                            }
                        }
                    }
                }
            }
            this._floorHeight = fh;
        } else {
            (this.temporary as TemporaryRoom).floorHeight = fh;
        }
    }

    get plinthsHeight(): number {
        if (this.temporary === null) {
            return this._plinthsHeight;
        } else {
            return (this.temporary as TemporaryRoom).plinthsHeight;
        }
    }

    set plinthsHeight(ph: number) {
        if (this.temporary === null) {
            this._plinthsHeight = ph;
        } else {
            (this.temporary as TemporaryRoom).plinthsHeight = ph;
        }
    }

    get plinthsMaterialType(): number {
        if (this.temporary === null) {
            return this._plinthsMaterialType;
        } else {
            return (this.temporary as TemporaryRoom).plinthsMaterialType;
        }
    }

    set plinthsMaterialType(pmt: number) {
        if (this.temporary === null) {
            this._plinthsMaterialType = pmt;
        } else {
            (this.temporary as TemporaryRoom).plinthsMaterialType = pmt;
        }
    }

    // coating can be Coating or FloorCoatingArea
    getCoatingQuantity(coating: Component): number {
        let functionalityComponent = this.getComponent(ComponentConstants.ComponentType.Functionality);

        if (functionalityComponent !== null) {
            if ((functionalityComponent as Functionality).functionalityId === ComponentConstants.Functionalities.TechnicalRoom) {
                return 0;
            }
        }

        switch(coating.componentType) {
            case ComponentConstants.ComponentType.Coating:
                return this.area / (100 * 100 * 100);
        
            case ComponentConstants.ComponentType.FloorCoatingArea:
                return (coating as FloorCoatingArea).area / (100 * 100 * 100);
            
            default:
                return(0);
        }
    }

    get walls(): Array<Wall> {
        //Order walls clockwise
        if (!this.wallsOrderer && this._walls.length > 1) {
            let closed = SavaneMath.findCommonPoint(this._walls[0], this._walls[this._walls.length - 1]);
            //Test if first and last walls have a common point
            if (closed[0] !== 0 || closed[1] !== 0) {
                //retrieve points list
                let points = [];
                for (let i = 0; i < this._walls.length; i++) {
                    points[points.length] = SavaneMath.findCommonPoint(this._walls[i], this._walls[(i + 1) % this._walls.length]);
                }
                //Calculate fake area
                let sum = 0;
                for (let i = 0; i < points.length; i++) {
                    sum += (points[(i + 1 + points.length) % points.length][0] - points[i][0]) * (points[(i + 1 + points.length) % points.length][1] + points[i][1]);
                }
                if (sum < 0) {
                    this._walls.reverse();
                }
            }
            this.wallsOrderer = true;
        }
        //Return walls array
        return this._walls;
    }

    get arrangementGroups(): Array<ArrangementGroup> {
        let arrangementGroupArray = [];
        for (let i = 0; i < this.children.length; i++) {
            if (this.children[i].isArrangementGroupEntity()) {
                arrangementGroupArray.push(this.children[i]);
                arrangementGroupArray = arrangementGroupArray.concat((this.children[i] as ArrangementGroup).arrangementGroups);
            }
        }

        return arrangementGroupArray;
    }

    get arrangementObjectsRec(): Array<ArrangementObject> {
        let arrangementArray = [];
        for (let i = 0; i < this.children.length; i++) {
            if (this.children[i].isArrangementObjectEntity()) {
                arrangementArray.push(this.children[i]);
            } else if (this.children[i].isArrangementGroupEntity()) {
                arrangementArray = arrangementArray.concat((this.children[i] as ArrangementGroup).arrangementObjectsRec);
            }
        }
        return arrangementArray;
    }

    /**
     * Ask if the room needs ceiling or not
     *
     * @param polys
     */
    doesntNeedCeiling(): boolean {
        // Get main functionnality
        let functionalityComponent = this.getComponent(ComponentConstants.ComponentType.Functionality);

        if (functionalityComponent !== null) {
            if ((functionalityComponent as Functionality).functionalityId === ComponentConstants.Functionalities.Terrace ||
                (functionalityComponent as Functionality).functionalityId === ComponentConstants.Functionalities.Driveway ||
                (functionalityComponent as Functionality).functionalityId === ComponentConstants.Functionalities.Garden ||
                (functionalityComponent as Functionality).functionalityId === ComponentConstants.Functionalities.Patio) {
                return true;
            } else {
                return false;
            }
        }
        return false;
    }

    /**
     * Ask if the room needs floor or not
     *
     * @param polys
     */
    doesntNeedFloor(): boolean {
        // Get main functionnality
        let functionalityComponent = this.getComponent(ComponentConstants.ComponentType.Functionality);

        if (functionalityComponent !== null) {
            if ((functionalityComponent as Functionality).functionalityId === ComponentConstants.Functionalities.Void) {
                return true;
            } else {
                return false;
            }
        }
        return false;
    }

    /**
     * Ask if the room is an exterior
     */
    get isExterior(): boolean {
        // Get main functionnality
        let functionalityComponent = this.getComponent(ComponentConstants.ComponentType.Functionality);

        if (functionalityComponent !== null) {
            if ((functionalityComponent as Functionality).functionalityId === ComponentConstants.Functionalities.Terrace ||
                (functionalityComponent as Functionality).functionalityId === ComponentConstants.Functionalities.Driveway ||
                (functionalityComponent as Functionality).functionalityId === ComponentConstants.Functionalities.Garden ||
                (functionalityComponent as Functionality).functionalityId === ComponentConstants.Functionalities.Balcony ||
                (functionalityComponent as Functionality).functionalityId === ComponentConstants.Functionalities.Pool) {
                return true;
            } else {
                return false;
            }
        }
        return false;
    }

    get isPool(): boolean {
        // Get main functionnality
        let functionalityComponent = this.getComponent(ComponentConstants.ComponentType.Functionality);

        if (functionalityComponent !== null) {
            if ((functionalityComponent as Functionality).functionalityId === ComponentConstants.Functionalities.Pool) {
                return true;
            } else {
                return false;
            }
        }
        return false;
    }

    /**
     * Ask if the room needs plinths or not
     *
     * @param polys
     */
    doesntNeedPlinth(): boolean {
        // Get main functionnality
        let functionalityComponent = this.getComponent(ComponentConstants.ComponentType.Functionality);

        if (functionalityComponent !== null) {
            if ((functionalityComponent as Functionality).functionalityId === ComponentConstants.Functionalities.Terrace ||
                (functionalityComponent as Functionality).functionalityId === ComponentConstants.Functionalities.Driveway ||
                (functionalityComponent as Functionality).functionalityId === ComponentConstants.Functionalities.Garden ||
                (functionalityComponent as Functionality).functionalityId === ComponentConstants.Functionalities.Balcony ||
                (functionalityComponent as Functionality).functionalityId === ComponentConstants.Functionalities.Void ||
                (functionalityComponent as Functionality).functionalityId === ComponentConstants.Functionalities.Pool) {
                return true;
            } else {
                return false;
            }
        }
        return false;
    }

    /**
     * Ask if the room needs small walls (balcony)
     *
     * @param polys
     */
    needsSmallWalls(): boolean {
        // Get main functionnality
        let functionalityComponent = this.getComponent(ComponentConstants.ComponentType.Functionality);

        if (functionalityComponent !== null) {
            if ((functionalityComponent as Functionality).functionalityId === ComponentConstants.Functionalities.Balcony) {
                return true;
            } else {
                return false;
            }
        }
        return false;
    }

    /**
     * Remove holes of triangulated poly
     *
     * @param polys
     */
    removeHoles(polys) {
        //Prepare functions
        let IsConvex = function (p1, p2, p3) {
            let tmp = (p3[1] - p1[1]) * (p2[0] - p1[0]) - (p3[0] - p1[0]) * (p2[1] - p1[1]);
            return tmp > 0;
        };
        let InCone = function (p1, p2, p3, p) {
            let convex = IsConvex(p1, p2, p3);
            if (convex) {
                if (!IsConvex(p1, p2, p)) {
                    return false;
                }
                if (!IsConvex(p2, p3, p)) {
                    return false;
                }
                return true;
            } else {
                if (IsConvex(p1, p2, p)) {
                    return true;
                }
                if (IsConvex(p2, p3, p)) {
                    return true;
                }
                return false;
            }
        };
        //Retrive holes list
        let roomsHolesList = this.getInnerRoomList(true);
        if (roomsHolesList.length <= 0) {
            //No holes in room
            return polys;
        }
        let roomHoles = [];
        //Rooms
        for (let i = 0; i < roomsHolesList.length; i++) {
            /*let roomsPolys = roomsHolesList[i].convexPartition;
             for (let i = 0; i <roomsPolys.length; i++) {
             roomHoles.push(roomsPolys[i]);
             }*/
            roomHoles.push(roomsHolesList[i].getInnerPointsList(false));
        }
        //Walls
        /*for (let i = 0; i < this.nonRoomedWalls.length; i++) {
         roomHoles.push(this.nonRoomedWalls[i].boundingBoxCut);
         }*/
        //Risky infinit Loop
        while (true) {
            let hasholes = false;
            let curHole = null;
            let holePointIndex = null;
            //Find the point with the largest x in all holes
            //Randomize choise logic to improve result
            let indexAxis = 1;
            if (roomHoles.length % 2 === 1) {
                indexAxis = 0;
            }

            for (let i = 0; i < roomHoles.length; i++) {
                if (!hasholes) {
                    hasholes = true;
                    curHole = roomHoles[i];
                    holePointIndex = 0;
                }
                for (let j = 0; j < roomHoles[i].length; j++) {
                    if (roomHoles[i][j][indexAxis] > curHole[holePointIndex][indexAxis]) {
                        curHole = roomHoles[i];
                        holePointIndex = j;
                    }
                }
            }
            if (!hasholes) {
                //No more holes leaves the loop
                break;
            }

            let holePoint = curHole[holePointIndex];

            let pointfound = false;
            let bestpolypoint = null;
            let v1 = null;
            let v2 = null;
            let polyiter = null;
            let polyiterpoint = null;
            //Loop in the current room polygon triangles
            for (let i = 0; i < polys.length; i++) {
                for (let j = 0; j < polys[i].length; j++) {
                    if (polys[i][j][indexAxis] <= holePoint[indexAxis]) {
                        //This point is in the wrong side of the other polygon
                        continue;
                    }
                    if (!InCone(polys[i][(j - 1 + polys[i].length) % polys[i].length], polys[i][j], polys[i][(j + 1) % polys[i].length], holePoint)) {
                        continue;
                    }
                    let polypoint = polys[i][j];
                    if (pointfound) {
                        v1 = math.vec3.create();
                        math.vec3.subtract(v1, polypoint, holePoint);
                        math.vec3.normalize(v1, v1);
                        v2 = math.vec3.create();
                        math.vec3.subtract(v1, bestpolypoint, holePoint);
                        math.vec3.normalize(v1, v1);
                    }
                    let pointvisible = true;
                    //Loop in other polys of current room
                    for (let k = 0; k < polys.length; k++) {
                        for (let l = 0; l < polys[k].length; l++) {
                            let linep1 = polys[k][l];
                            let linep2 = polys[k][(l + 1) % polys[k].length];
                            //Check intersections of lines
                            if (Segment.areSegmentCrossing(new Segment(holePoint, polypoint), new Segment(linep1, linep2), SavaneConstants.PositionTolerance)) {
                                pointvisible = false;
                                break;
                            }
                        }
                        if (!pointvisible) {
                            break;
                        }
                    }
                    if (pointvisible) {
                        pointfound = true;
                        bestpolypoint = polypoint;
                        polyiter = polys[i];
                        polyiterpoint = j;
                    }
                }
            }
            if (!pointfound) {
                //Error ?
                return null;
            }
            //Create new poly
            let newPoly = [];
            for (let i = 0; i <= polyiterpoint; i++) {
                newPoly.push(polyiter[i]);
            }
            for (let i = 0; i <= curHole.length; i++) {
                newPoly.push(curHole[(i + holePointIndex) % curHole.length]);
            }
            for (let i = polyiterpoint; i < polyiter.length; i++) {
                newPoly.push(polyiter[i]);
            }
            //Remove merged polys from list
            roomHoles.splice(roomHoles.indexOf(curHole), 1);
            polys.splice(polys.indexOf(polyiter), 1);
            polys.push(newPoly);
        }
        //Build the final list
        let outputpolys = [];
        for (let i = 0; i < polys.length; i++) {
            outputpolys.push(polys[i]);
        }
        return outputpolys;
    }

    /**
     * returns the smallest room height of the room
     *
     * @ returns {*}
     */
    get roomHeight(): number {
        return this.height;
    }

    addToCappedProperty(property: string, value: any) : number {
        let capped = 0;

        if (property === "roomHeight") {
            let smallest = this.height;
            let minimal = 0;
            for (let i = 0; i < this.walls.length; ++i) {
                for (let j = 0; j < this.walls[i].joineries.length; ++j) {
                    if (this.walls[i].joineries[j].joineryType !== SceneConstants.JoineryType.velux) {
                        if (this.walls[i].joineries[j].height + this.walls[i].joineries[j].floorHeight >= minimal) {
                            minimal = this.walls[i].joineries[j].height + this.walls[i].joineries[j].floorHeight;
                        }
                    }
                }
            }

            if (smallest + value <= minimal) {
                smallest = minimal;
                capped = 1;
            } else {
                smallest += value;
            }

            if (value !== 0) {
                this.height = smallest;

                for (let i = 0; i < this.walls.length; ++i) {
                    // Don't modify the walls that are smaller than 105cm
                    if (this.walls[i].height <= 1050 || this.walls[i].isSpecialWall) {
                        continue;
                    }
                    if (this.walls[i].rooms.length < 2) {
                        this.walls[i].height = smallest;
                    } else {
                        let otherRoom;
                        if (this.walls[i].rooms[0].id === this.id) {
                            otherRoom = this.walls[i].rooms[1];
                        } else {
                            otherRoom = this.walls[i].rooms[0];
                        }

                        if (otherRoom.roomHeight <= smallest) {
                            this.walls[i].height = smallest;
                        } else {
                            this.walls[i].height = otherRoom.roomHeight;
                        }
                    }
                }

                for (let i = 0; i < this.nonRoomedWalls.length; ++i) {
                    // Don't modify the walls that are smaller than 105cm
                    if (this.nonRoomedWalls[i].height <= 1050 || this.walls[i].isSpecialWall) {
                        continue;
                    }
                    this.nonRoomedWalls[i].height = smallest;
                }
            }

            return capped;
        }

        if (property === "plinthsHeight") {
            if (this.plinthsHeight + value <= SceneConstants.PlinthsCapValues.minHeight) {
                this.plinthsHeight = SceneConstants.PlinthsCapValues.minHeight;
                capped = 1;
            } else if (this.plinthsHeight + value >= SceneConstants.PlinthsCapValues.maxHeight) {
                this.plinthsHeight = SceneConstants.PlinthsCapValues.maxHeight;
                capped = 2;
            } else {
                this.plinthsHeight += value;
            }

            return capped;
        }

        if (property === "floorHeight") {
            if (this.floorHeight + value >= this.height) {
                this.floorHeight = this.height;
                capped = 2;
            } else {
                this.floorHeight += value;
            }

            return capped;
        }

        return super.addToCappedProperty(property, value);
    }

    /**
     * Cut the polygon into convex Polygons
     * Using Hertel-Mehlhorn algorithm
     * @returns {*}
     */
    get convexPartition(): any {
        let convexPolys;
        if (this.lastTriangles === null) {
            convexPolys = this.triangulate();
        } else {
            try {
                convexPolys = [];
                for (let i = 0; i < this.lastTriangles.length; i++) {
                    let clone = [];
                    clone.push([this.lastTriangles[i][0][0], this.lastTriangles[i][0][1], this.lastTriangles[i][0][2]]);
                    clone.push([this.lastTriangles[i][1][0], this.lastTriangles[i][1][1], this.lastTriangles[i][1][2]]);
                    clone.push([this.lastTriangles[i][2][0], this.lastTriangles[i][2][1], this.lastTriangles[i][2][2]]);
                    convexPolys.push(clone);
                }
            } catch (err) {
                console.log("LastTriangles list is not avaible");
                convexPolys = this.triangulate();
            }
        }
        let IsConvex = function (p1, p2, p3) {
            let tmp = (p3[1] - p1[1]) * (p2[0] - p1[0]) - (p3[0] - p1[0]) * (p2[1] - p1[1]);
            return tmp > 0;
        };

        for (let i = 0; i < convexPolys.length; i++) {
            //Loop throught triangles
            let polyA = convexPolys[i];
            for (let j = 0; j < polyA.length; j++) {
                //Loop throught points of poly
                //Get two point index to make a line
                let AA = j;
                let AB = (j + 1) % polyA.length;
                let pointAA = polyA[AA];
                let pointAB = polyA[AB];
                //Prepare variable to store second polygon infos
                let polyB = null;
                let BA = null;
                let BB = null;
                //Check if it's a diagonal
                let isDiagonal = false;
                for (let k = i; k < convexPolys.length; k++) {
                    polyB = convexPolys[k];
                    if (polyB === polyA) {
                        continue;
                    }
                    //Loop in points
                    for (let l = 0; l < polyB.length; l++) {
                        if (!SavaneMath.equal(polyB[l], pointAB)) {
                            continue;
                        }
                        if (!SavaneMath.equal(polyB[(l + 1) % polyB.length], pointAA)) {
                            continue;
                        }
                        BA = l;
                        BB = (l + 1) % polyB.length;
                        isDiagonal = true;
                        break;
                    }
                    if (isDiagonal) {
                        break;
                    }
                }
                if (!isDiagonal) {
                    //The line is not a diagonal switch to next point
                    continue;
                }
                //Found a diagonal now test if angle are convex
                if (!IsConvex(polyA[(AA - 1 + polyA.length) % polyA.length], pointAA, polyB[(BB + 1) % polyB.length])) {
                    //This angle is not reflex, switch to next point
                    continue;
                }
                if (!IsConvex(polyB[(BA - 1 + polyB.length) % polyB.length], pointAB, polyA[(AB + 1) % polyA.length])) {
                    //This angle is not reflex, switch to next point
                    continue;
                }
                //The diagonal can be removed
                let newPoly = [];
                for (let k = AB; k !== AA; k = (k + 1) % polyA.length) {
                    newPoly.push(polyA[k]);
                }
                for (let k = BB; k !== BA; k = (k + 1) % polyB.length) {
                    newPoly.push(polyB[k]);
                }

                //Remove two poly
                //convexPolys.splice(i, 1);
                let polyBIndex = convexPolys.indexOf(polyB);
                if (polyBIndex >= 0) {
                    convexPolys.splice(polyBIndex, 1);
                } else {
                    console.log("Error");
                }
                let indexOfA = convexPolys.indexOf(polyA);
                convexPolys[indexOfA] = newPoly;
                j = -1;
                polyA = newPoly;
                i = -1;
                break;
            }
        }
        return convexPolys;
    }

    /**
     * Return the centroid point of the room polygon
     */
    get aestheticCenter(): math.vec3 {
        if (this.lastAestheticCenter !== null) {
            return this.lastAestheticCenter;
        }
        let convexPolys = this.convexPartition;
        //Fidn the biggest poly
        let MaxArea = 0;
        let MaxAreaIndex = -1;
        for (let i = 0; i < convexPolys.length; i++) {
            let currentArea = SavaneMath.getPolygonArea(convexPolys[i]);
            if (MaxAreaIndex < 0 || currentArea > MaxArea) {
                MaxAreaIndex = i;
                MaxArea = currentArea;
            }
        }
        // Prevent crash and return the first wall center if available - Usefull to see what happens
        if (MaxAreaIndex === -1) {
            console.error("ERROR::Wrong aestheticCenter, the room is invalid");
            let walls = this.walls;
            if (walls.length > 0) {
                return walls[0].center;
            }
            return math.vec3.fromValues(0, 0, 0);
        }
        return SavaneMath.getCentroid(convexPolys[MaxAreaIndex]);
    }

    /**
     * Return the centroid point of the room polygon
     */
    get centroid(): math.vec3 {
        let points = this.getOutterPointList();
        return SavaneMath.getCentroid(points);
    }

    /**
     * add a surrounding wall
     *
     * @param {Wall} aWall
     */
    addWall(aWall: Wall) {
        this.wallsOrderer = false;
        this._walls.push(aWall);
    }

    /**
     * add a surrounding wall at the given index
     *
     * @param {*} aWall
     * @param {Number} index
     */
    addWallAtIndex(aWall: Wall, index: number) {
        this.wallsOrderer = false;
        this._walls.splice(index, 0, aWall);
    }

    /**
     * Delete a surrounding wall
     *
     * @param {Number} wallId
     */
    deleteWall(wallId: number) {
        this.wallsOrderer = false;
        let curWalls = this.walls;
        for (let i = 0; i < curWalls.length; i++) {
            if (curWalls[i].id === wallId) {
                curWalls.splice(i, 1);
                return i;
            }
        }
    }

    clearWalls() {
        this.wallsOrderer = false;
        this._walls = [];
    }

    /**
     * add a non surrounding wall
     *
     * @param {Wall} aWall
     */
    addNonRoomedWall(aWall: Wall) {
        this.wallsOrderer = false;
        let toAdd = true;
        for (let i = 0; i < this.nonRoomedWalls.length; i++) {
            if (this.nonRoomedWalls[i].id === aWall.id) {
                toAdd = false;
            }
        }
        if (toAdd) {
            this.nonRoomedWalls.push(aWall);
        }
    }

    /**
     * delete a non surrounding wall
     *
     * @param {Number} wallId
     */
    deleteNonRoomedWall(wallId: number) {
        this.wallsOrderer = false;
        for (let i = 0; i < this.nonRoomedWalls.length; i++) {
            if (this.nonRoomedWalls[i].id === wallId) {
                this.nonRoomedWalls.splice(i, 1);
                return true;
            }
        }
        return false;
    }

    /**
     * delete non roomed walls that belong to a room in place
     */
    deleteRoomedWallsFromNonRoomedWalls() {
        let nonRoomedWalls = this.nonRoomedWalls.slice();
        let result = [];
        for (let i = 0; i < nonRoomedWalls.length; ++i) {
            let wall = nonRoomedWalls[i];
            if (wall.rooms.length > 0) {
                continue;
            }
            result.push(wall);
        }
        this.nonRoomedWalls = result;
    }

    /**
     * test whether the wall is part of the room (belong to the surrounding wall of the room).
     *
     * @param {Number} wallId
     */
    isWallInRoom(wallId: number) {
        for (let i = 0; i < this.walls.length; i++) {
            if (wallId === this.walls[i].id) {
                return true;
            }
        }
        return false;
    }

    /**
     * Test whether the point is in the room
     *
     * @param {*} position
     * @returns {*}
     */
    isInRoom(position: math.vec3): boolean {
        let points = [];
        for (let i = 0; i < this.walls.length; i++) {
            points.push(SavaneMath.findCommonPoint(this.walls[i], this.walls[(i + 1) % this.walls.length]));
        }
        return SavaneMath.isInPoly(position, points);
    }

    /**
     * Retrieve the list of rooms contains in the room
     *
     * @param oneStep if set to true will avoid to return room contained in contained room
     */
    getInnerRoomList(oneStep: boolean): Array<Room> {
        let roomsHolesList = [];

        if (!this.parent) {
            return roomsHolesList;
        }

        for (let i = 0; i < this.floor.rooms.length; i++) {
            let currentroom = this.floor.rooms[i];

            if (currentroom.id === this.id) {
                continue;
            }

            // New code just check 1st wall of room is enough to determine if in or not
            if (SavaneMath.isWallInRoom(currentroom.walls[0], this)) {
                //the room is contained add it to the list)
                roomsHolesList.push(currentroom);
            }
        }

        //Return all the rooms
        if (!oneStep || roomsHolesList.length === 0) {
            return roomsHolesList;
        }

        //Remove rooms contains in other room of list
        let toRemoveRoom = [];

        for (let i = 0; i < roomsHolesList.length; i++) {
            if (toRemoveRoom.indexOf(roomsHolesList[i]) >= 0) {
                continue;
            }

            let toTestRoom = roomsHolesList[i];

            for (let j = 0; j < roomsHolesList.length; j++) {
                let currentroom = roomsHolesList[j];

                if (currentroom.id === toTestRoom.id) {
                    continue;
                }

                // New code
                if (SavaneMath.isWallInRoom(currentroom.walls[0], toTestRoom)) {
                    if (toRemoveRoom.indexOf(currentroom) === -1) {
                        toRemoveRoom.push(currentroom);
                    }
                }
            }
        }

        for (let i = 0; i < toRemoveRoom.length; i++) {
            roomsHolesList.splice(roomsHolesList.indexOf(toRemoveRoom[i]), 1);
        }

        return roomsHolesList;
    }

    /**
     * Invalidate inner points list because they are changing
     */
    invalidateRoomPointsList() {
        this.preCalcInnerPoints = null;
        this.preCalcOutterPoints = null;
        this.preCalcInnerPointsAndWalls = null;
    }

    /**
     * Retrieve inner points list in clockwise order
     * If {true} as parameter will return a structure containing the points list and a list of walls pairs
     * that can be associated with each point
     *
     * @param requestWall
     * @returns {*}
     */
    getInnerPointsList(requestWall: boolean) {
        // If already computed, return them
        if (requestWall) {
            if (this.preCalcInnerPointsAndWalls !== null) {
                return Object.assign({}, this.preCalcInnerPointsAndWalls);
            }
        } else {
            if (this.preCalcInnerPoints !== null) {
                return this.preCalcInnerPoints.slice();
            }
        }

        let pointsIN = [];
        let pointsWalls = [];
        //Debug computing when two consecutive wall are colinear
        let walls = this.walls;
        let precalcpoints = this.points;
        let innerRooms = this.getInnerRoomList(false);

        for (let i = 0; i < walls.length; i++) {
            let wall = walls[i];

            //Ignore wall with no length
            if (wall.length <= SavaneConstants.PositionTolerance) {
                continue;
            }

            let b = this.wallOrientationInRoom(wall, precalcpoints);
            let adjacentWall = walls[(i + 1) % walls.length];
            if (adjacentWall.length < SavaneConstants.PositionTolerance) {
                adjacentWall = walls[(i + 2) % walls.length];
            }
            let b2 = this.wallOrientationInRoom(adjacentWall, precalcpoints);
            //Storage for nonroomed points
            let lastWall = wall;
            let extraPoint = null;
            let meetInnerRoom = false;
            if (b) {
                //Use begin                                                                                                                                //FIXME  : passing rooms.walls assure temporary walls (not child of floor) will be in the array
                let neighWall = wallManager.getWallsAtPositionAngleOrdered(wall.end, this.floor, SavaneConstants.PositionTolerance, null, this._walls); // Can it be called on a room that is outside of floor ? May fail due to parent not being a floor
                neighWall.reverse();
                let startIndex = -1;
                for (let k = 0; k < neighWall.length; ++k) {
                    if (wall.id === neighWall[k].id) {
                        startIndex = k;
                        break;
                    }
                }
                for (let j = 0; j < neighWall.length; j++) {
                    let testedWall = neighWall[(j + startIndex) % neighWall.length];
                    if (this.nonRoomedWalls.indexOf(testedWall) >= 0) {
                        if (extraPoint !== null) {
                            //Change the adjacent wall of last non room point list
                            pointsWalls[pointsWalls.length - 1][1] = testedWall;
                        }
                        extraPoint = this._getNonRoomedWallPList(testedWall, wall.end, lastWall);
                        for (let k = 0; k < extraPoint.points.length; k++) {
                            pointsIN.push(extraPoint.points[k]);
                            pointsWalls.push(extraPoint.walls[k]);
                        }
                        lastWall = testedWall;
                        //} else if (testedWall.rooms.length === 1 && innerRoomsIndex.indexOf(testedWall.rooms[0].id) >= 0) {
                    } else if (testedWall.rooms.length === 1 && innerRooms.indexOf(testedWall.rooms[0]) >= 0) {
                        meetInnerRoom = true;
                        //A wall is at this point and it's not a nonRoomedWall
                        //It's an inner room built on a current room wall extermity
                        let bbox = wall.boundingBoxCut;
                        pointsIN.push(bbox[2]);
                        pointsWalls.push([wall, wall]);
                        let bboxAdj = adjacentWall.boundingBoxCut;
                        if (b2) {
                            pointsIN.push(bboxAdj[1]);
                        } else {
                            pointsIN.push(bboxAdj[3]);
                        }
                        pointsWalls.push([wall, wall]);
                    }
                }
            } else {
                //Use end                                                                                                                                     //FIXME  : passing rooms.walls assure temporary walls (not child of floor) will be in the array
                let neighWall = wallManager.getWallsAtPositionAngleOrdered(wall.begin, this.floor, SavaneConstants.PositionTolerance, null, this._walls); // Can it be called on a room that is outside of floor ? May fail due to parent not being a floor
                neighWall.reverse();
                let startIndex = -1;
                for (let k = 0; k < neighWall.length; ++k) {
                    if (wall.id === neighWall[k].id) {
                        startIndex = k;
                        break;
                    }
                }
                for (let j = 0; j < neighWall.length; j++) {
                    let testedWall = neighWall[(j + startIndex) % neighWall.length];
                    if (this.nonRoomedWalls.indexOf(testedWall) >= 0) {
                        if (extraPoint !== null) {
                            //Change the adjacent wall of last non room point list
                            pointsWalls[pointsWalls.length - 1][1] = testedWall;
                        }
                        extraPoint = this._getNonRoomedWallPList(testedWall, wall.begin, lastWall);
                        for (let k = 0; k < extraPoint.points.length; k++) {
                            pointsIN.push(extraPoint.points[k]);
                            pointsWalls.push(extraPoint.walls[k]);
                        }
                        lastWall = testedWall;
                        //} else if (testedWall.rooms.length === 1 && innerRoomsIndex.indexOf(testedWall.rooms[0].id) >= 0) {
                    } else if (testedWall.rooms.length === 1 && innerRooms.indexOf(testedWall.rooms[0]) >= 0) {
                        meetInnerRoom = true;
                        //A wall is at this point and it's not a nonRoomedWall
                        //It's an inner room built on a current room wall extermity
                        let bbox = wall.boundingBoxCut;
                        pointsIN.push(bbox[0]);
                        pointsWalls.push([wall, wall]);
                        let bboxAdj = adjacentWall.boundingBoxCut;
                        if (b2) {
                            pointsIN.push(bboxAdj[1]);
                        } else {
                            pointsIN.push(bboxAdj[3]);
                        }
                        pointsWalls.push([wall, wall]);
                    }
                }
            }
            if (extraPoint !== null || meetInnerRoom) {
                pointsWalls[pointsWalls.length - 1][1] = adjacentWall;
            } else if (!Wall.areColinear(wall, adjacentWall, SavaneConstants.ToleranceCollinearLight)) {
                pointsIN.push(this.calculateWallIntersection(wall, b, adjacentWall, b2));
                pointsWalls.push([wall, adjacentWall]);
            } else if ((!b && wall.canBeginHaveOffset()) || (b && wall.canEndHaveOffset()) || (!b2 && adjacentWall.canBeginHaveOffset()) || (b2 && adjacentWall.canEndHaveOffset()) || wall.thickness !== adjacentWall.thickness) {
                if (b) {
                    pointsIN.push(wall.boundingBox[2]);
                    pointsWalls.push([wall, adjacentWall]);
                } else {
                    pointsIN.push(wall.boundingBox[0]);
                    pointsWalls.push([wall, adjacentWall]);
                }
                if (b2) {
                    pointsIN.push(adjacentWall.boundingBox[1]);
                    pointsWalls.push([wall, adjacentWall]);
                } else {
                    pointsIN.push(adjacentWall.boundingBox[3]);
                    pointsWalls.push([wall, adjacentWall]);
                }
            }
        }
        this.preCalcInnerPoints = pointsIN;
        this.preCalcInnerPointsAndWalls = { points: pointsIN, walls: pointsWalls };

        if (requestWall === undefined || !requestWall) {
            return this.preCalcInnerPoints.slice();
        } else {
            return Object.assign({}, this.preCalcInnerPointsAndWalls);
        }
    }

    /**
     *
     * RECURSIVE
     * ******* This methods is used by getInnerPointsList methods *****
     * ******* Avoid using it directly *****
     *
     * Calculate the point going around a flow of non roomed wall
     * to add them to the point list representing room polygon
     *
     * @param wall
     * @param startPoint
     * @param caller
     * @returns {{points: Array, walls: Array}}
     */
    _getNonRoomedWallPList(wall: Wall, startPoint: math.vec3, caller: any): any {
        let pointsAround = [];
        let wallsPoint = [];
        let cutedBBox = wall.boundingBoxCut;
        let callerIsNonRoomed = this.nonRoomedWalls.indexOf(caller) >= 0;
        if (wall.isBegin(startPoint, SavaneConstants.PositionTolerance)) {
            //start with the begin point
            pointsAround.push(cutedBBox[1]);
            wallsPoint.push([caller, wall]);
            let wallAround = wallManager.getWallsAtPositionAngleOrdered(wall.end, this.floor); // Can it be called on a room that is outside of floor ? May fail due to parent not being a floor
            let nextWall = wallAround[(wallAround.indexOf(wall) + 1) % wallAround.length];
            if (wallAround.length <= 1 || this.nonRoomedWalls.indexOf(nextWall) < 0) {
                //This wall is an extremitie
                pointsAround.push(cutedBBox[2]);
                wallsPoint.push([wall, wall]);
                pointsAround.push(cutedBBox[3]);
                wallsPoint.push([wall, wall]);
                pointsAround.push(cutedBBox[0]);
                if (callerIsNonRoomed) {
                    wallsPoint.push([wall, caller]);
                } else {
                    wallsPoint.push([wall, wall]);
                }
            } else if (this.nonRoomedWalls.indexOf(nextWall) < 0 || (caller !== undefined && nextWall.id === caller.id)) {
                //end of loop
                pointsAround.push(cutedBBox[2]);
                wallsPoint.push([wall, wall]);
                pointsAround.push(cutedBBox[3]);
                wallsPoint.push([wall, wall]);
            } else {
                let otherList = this._getNonRoomedWallPList(nextWall, wall.end, wall);
                for (let i = 0; i < otherList.points.length; i++) {
                    pointsAround.push(otherList.points[i]);
                    wallsPoint.push(otherList.walls[i]);
                }
                //Another wall to go
                pointsAround.push(cutedBBox[0]);
                wallsPoint.push([wall, wall]);
            }
        } else {
            //start with end point
            pointsAround.push(cutedBBox[3]);
            wallsPoint.push([caller, wall]);
            let wallAround = wallManager.getWallsAtPositionAngleOrdered(wall.begin, this.floor); // Can it be called on a room that is outside of floor ? May fail due to parent not being a floor
            let previousWall = wallAround[(wallAround.indexOf(wall) - 1 + wallAround.length) % wallAround.length];
            if (wallAround.length <= 1 || (this.nonRoomedWalls.indexOf(previousWall) < 0 && previousWall.id !== caller.id)) {
                //This wall is an extremitie
                pointsAround.push(cutedBBox[0]);
                wallsPoint.push([wall, wall]);
                pointsAround.push(cutedBBox[1]);
                wallsPoint.push([wall, wall]);
                pointsAround.push(cutedBBox[2]);
                if (callerIsNonRoomed) {
                    wallsPoint.push([wall, caller]);
                } else {
                    wallsPoint.push([wall, wall]);
                }
            } else if (this.nonRoomedWalls.indexOf(previousWall) < 0 || (caller !== undefined && previousWall.id === caller.id)) {
                pointsAround.push(cutedBBox[0]);
                wallsPoint.push([wall, wall]);
                pointsAround.push(cutedBBox[1]);
                wallsPoint.push([wall, wall]);
            } else {
                let otherList = this._getNonRoomedWallPList(previousWall, wall.begin, wall);
                for (let i = 0; i < otherList.points.length; i++) {
                    pointsAround.push(otherList.points[i]);
                    wallsPoint.push(otherList.walls[i]);
                }
                pointsAround.push(cutedBBox[2]);
                wallsPoint.push([wall, wall]);
            }
        }
        return { points: pointsAround, walls: wallsPoint };
    }

    calculateWallIntersection(wallA: Wall, beginToEndA: boolean, wallB: Wall, beginToEndB: boolean) {
        let bounding1 = wallA.boundingBox;
        let bounding2 = wallB.boundingBox;

        let pointI;
        if (!beginToEndA) {
            if (!beginToEndB) {
                pointI = SavaneMath.getCrossPoint(bounding1[0], bounding1[3], bounding2[0], bounding2[3]);
            } else {
                pointI = SavaneMath.getCrossPoint(bounding1[0], bounding1[3], bounding2[1], bounding2[2]);
            }
        } else {
            if (!beginToEndB) {
                pointI = SavaneMath.getCrossPoint(bounding1[1], bounding1[2], bounding2[0], bounding2[3]);
            } else {
                pointI = SavaneMath.getCrossPoint(bounding1[1], bounding1[2], bounding2[1], bounding2[2]);
            }
        }
        return pointI;
    }

    /**
     * get the area of the room
     *
     * @returns {Number}
     */
    get area(): number {
        //Retrieve the list of inner point clockwise ordered
        let orderedWallIn = this.getInnerPointsList(true);

        let wallsIn = orderedWallIn.walls;
        let pointsIN = orderedWallIn.points;
        let nonRoomedWallCopy = [];

        for (let i = 0; i < this.nonRoomedWalls.length; i++) {
            let skip = false;
            for (let j = 0; j < wallsIn.length && !skip; j++) {
                skip = wallsIn[j].indexOf(this.nonRoomedWalls[i]) >= 0;
            }
            if (!this.nonRoomedWalls[i].isRoomedWall && !skip) {
                nonRoomedWallCopy.push(this.nonRoomedWalls[i]);
            }
        }

        let areaI = 0;
        /*        if (this.walls.length !== 0) {
                    this.height = this.walls[0].height;
                }*/
        //Calculate area of polygon defined by innerpoints list
        for (let i = 0; i < pointsIN.length; ++i) {
            areaI = areaI + (pointsIN[i][0] + pointsIN[(i + 1) % pointsIN.length][0]) * (pointsIN[i][1] - pointsIN[(i + 1) % pointsIN.length][1]);
            let spliceIndex = nonRoomedWallCopy.indexOf(wallsIn[i][1]);
            if (spliceIndex > 0) {
                nonRoomedWallCopy.splice(spliceIndex, 1);
            }
        }
        areaI = Math.abs(areaI * 0.5);

        //Remove external area of contained rooms from current room area
        let innerRoomList = this.getInnerRoomList(true);
        for (let i = 0; i < innerRoomList.length; i++) {
            areaI -= innerRoomList[i].externalArea;
        }
        //Remove area of the walls from total area
        for (let i = 0; i < nonRoomedWallCopy.length; i++) {
            let bbbox = nonRoomedWallCopy[i].boundingBoxCut;
            let wallArea = 0;
            for (let j = 0; j < bbbox.length; j++) {
                wallArea += (bbbox[j][0] + bbbox[(j + 1) % bbbox.length][0]) * (bbbox[j][1] - bbbox[(j + 1) % bbbox.length][1]);
            }
            wallArea *= 0.5;
            areaI -= Math.abs(wallArea);
        }
        return areaI;
    }

    /**
     * Retrieve outter points list in clockwise order
     *
     * @returns {*}
     */
    getOutterPointList(): Array<math.vec3> {
        if (this.preCalcOutterPoints !== null) {
            return this.preCalcOutterPoints.slice();
        }

        let pointsOut = [];
        let walls = this.walls;
        let precalcpoints = this.points;
        for (let i = 0; i < walls.length; i++) {
            //Ignore wall with no length
            if (walls[i].length <= SavaneConstants.PositionTolerance) {
                continue;
            }
            let b = this.wallOrientationInRoom(walls[i], precalcpoints);
            let adjacentWall = walls[(i + 1 + walls.length) % walls.length];
            let b2 = this.wallOrientationInRoom(adjacentWall, precalcpoints);
            if (!Wall.areColinear(walls[i], adjacentWall)) {
                let bbbox = walls[i].boundingBoxCut;
                if (!b) {
                    pointsOut.push(bbbox[1]);
                } else {
                    pointsOut.push(bbbox[3]);
                }
                bbbox = adjacentWall.boundingBoxCut;
                let secondPoint = null;
                if (!b2) {
                    secondPoint = bbbox[2];
                } else {
                    secondPoint = bbbox[0];
                }
                if (!SavaneMath.equal(secondPoint, pointsOut[pointsOut.length - 1])) {
                    pointsOut.push(secondPoint);
                }
                //pointsOut.push(this.calculateWallIntersection(walls[i], !b, adjacentWall, !b2));
            }
        }

        this.preCalcOutterPoints = pointsOut;

        return this.preCalcOutterPoints.slice(0);
    }

    get externalArea(): number {
        let areaO = 0;

        //Debug computing when two consecutive wall are colinear
        let walls = [];
        for (let i = 0; i < this.walls.length; i++) {
            if (!Wall.areColinear(this.walls[i], this.walls[(i + 1) % this.walls.length])) {
                walls.push(this.walls[i]);
            }
        }

        let pointsOUT = [];
        var precalcpoints = this.points;
        for (let i = 0; i < walls.length; i++) {
            let bounding1 = walls[i].boundingBox;
            let bounding2 = walls[(i - 1 + walls.length) % walls.length].boundingBox;
            let b = this.wallOrientationInRoom(walls[i], precalcpoints);
            let b2 = this.wallOrientationInRoom(walls[(i - 1 + walls.length) % walls.length], precalcpoints);
            let pointI;
            let pointO;
            if ((b && b2) || (!b && !b2)) {
                pointI = SavaneMath.getCrossPoint(bounding1[0], bounding1[3], bounding2[0], bounding2[3]);
                pointO = SavaneMath.getCrossPoint(bounding1[1], bounding1[2], bounding2[1], bounding2[2]);
            } else {
                pointI = SavaneMath.getCrossPoint(bounding1[1], bounding1[2], bounding2[0], bounding2[3]);
                pointO = SavaneMath.getCrossPoint(bounding1[0], bounding1[3], bounding2[1], bounding2[2]);
            }
            if (pointI && !this.isInRoom(pointI)) {
                let pointTmp;
                pointTmp = pointI;
                pointI = pointO;
                pointO = pointTmp;
            }
            if (pointO) {
                pointsOUT.push(pointO);
            }
        }
        for (let i = 0; i < pointsOUT.length; ++i) {
            areaO = areaO + (pointsOUT[i][0] + pointsOUT[(i + 1) % pointsOUT.length][0]) * (pointsOUT[i][1] - pointsOUT[(i + 1) % pointsOUT.length][1]);
        }
        areaO = Math.abs(areaO * 0.5);
        return areaO;
    }

    wallOrientationInRoom(wallEntity: Wall, precalcpoints: Array<math.vec3>): boolean {
        let points = precalcpoints;
        if (points === undefined) {
            points = this.points;
        }
        let ordered = false;
        let clockwise = false;
        /*var sum = 0;
         for (let i = 0; i < points.length; ++i) {
         sum += (points[(i + 1) % points.length][0] - points[i][0]) * (points[(i + 1) % points.length][1] + points[i][1]);
         }
         if (sum <= 0) {
         clockwise = true;
         }*/

        for (let i = 0; i < points.length; ++i) {
            if (wallEntity.isBegin(points[i])) {
                if (clockwise) {
                    if (wallEntity.isEnd(points[(i - 1 + points.length) % points.length])) {
                        ordered = true;
                    }
                } else {
                    if (wallEntity.isEnd(points[(i + 1) % points.length])) {
                        ordered = true;
                    }
                }
                break;
            }
        }
        return ordered;
    }

    /**
     * test whether the point is link to the room. A point is link to the room if there is a succession of walls which
     * goes from the point to a point on a surrounding wall of the room.
     * Return null if the point is not link.
     * Return the point of room surrounding where the succession of walls arrived if the point is linked.
     * pointsDone  are allow to leave cycle
     *
     * @param {*} point
     * @param {Number} ignoreWallsId
     * @param {*} pointsDone
     */
    isPointLinkToRoom(point: math.vec3, ignoreWallsId: number, pointsDone: Array<math.vec3>): math.vec3 {
        let res = null;
        let walls = wallManager.getWallsAtCorner(point, this.floor, SavaneConstants.PositionTolerance); // Can be called in a room that is not inside a floor ? May fail due to parent being null
        if (walls === null || walls.length === 0) {
            return null;
        } else {
            for (let i = 0; i < walls.length; i++) {
                if (this.isWallInRoom(walls[i].id)) {
                    return point;
                } else if (ignoreWallsId !== walls[i].id) {
                    let newPoint = SavaneMath.equal(point, walls[i].begin) ? walls[i].end : walls[i].begin;
                    let toCompute = true;
                    for (let j = 0; j < pointsDone.length; j++) {
                        if (SavaneMath.equal(newPoint, pointsDone[j])) {
                            toCompute = false;
                        }
                    }
                    if (toCompute) {
                        pointsDone.push(point);
                        if (res === null) {
                            let tempRes = this.isPointLinkToRoom(newPoint, walls[i].id, pointsDone);
                            if (tempRes !== null) {
                                res = tempRes;
                            }
                        }
                    }
                }
            }
        }
        return res;
    }

    /**
     * returns the array of the room's point
     */
    get points(): Array<math.vec3> {
        let points = [];
        for (let i = 0; i < this.walls.length; i++) {
            //if(!Wall.areColinear(this.walls[i], this.walls[(i + 1) % this.walls.length])){
            points[points.length] = SavaneMath.findCommonPoint(this.walls[i], this.walls[(i + 1) % this.walls.length]);
            // }
        }
        return points;
    }

    /**
     * Returns the array conataining triangles representing the room
     */
    triangulate(): Array<Array<math.vec3>> {
        //Retrieve points list and set it counter clock wise
        let points = this.getInnerPointsList(false);
        points.reverse();

        let roomsHolesList = this.getInnerRoomList(true);
        let holes = [];
        for (let i = 0; i < roomsHolesList.length; i++) {
            holes[i] = roomsHolesList[i].getInnerPointsList(false);
        }

        let trianglesNoHoles: Array<Array<math.vec3>> = SavaneMath.triangulate(points, holes);
        //**LAZY** Clone to store in room and avoid recalculating on next call
        this.lastTriangles = [];
        this.lastAestheticCenter = null;
        for (var i = 0; i < trianglesNoHoles.length; i++) {
            let clone: Array<math.vec3> = new Array<math.vec3>();
            clone.push([trianglesNoHoles[i][0][0], trianglesNoHoles[i][0][1], trianglesNoHoles[i][0][2]]);
            clone.push([trianglesNoHoles[i][1][0], trianglesNoHoles[i][1][1], trianglesNoHoles[i][1][2]]);
            clone.push([trianglesNoHoles[i][2][0], trianglesNoHoles[i][2][1], trianglesNoHoles[i][2][2]]);
            this.lastTriangles.push(clone);
        }
        return trianglesNoHoles;
    }

    /**
     * test whether two rooms are equals
     *
     * @param {Room} room1
     * @param {Room} room2
     * @returns {*}
     */
    static equal(room1: Room, room2: Room): boolean {
        if (room1.walls.length !== room2.walls.length) {
            return false;
        }
        let nbWallEqual = 0;
        for (let i = 0; i < room1.walls.length; i++) {
            for (let j = 0; j < room2.walls.length; j++) {
                if (room1.walls[i].id === room2.walls[j].id) {
                    nbWallEqual++;
                }
            }
        }
        return nbWallEqual === room1.walls.length;
    }

    /**
     *  Set main functionalityId of the room
     */
    setPrimaryFunctionality(newFunction: number, functionName: string): boolean {
        //If main functionalityId change clear secondary functionalities
        let functionalityComponent = this.getComponent(ComponentConstants.ComponentType.Functionality);
        if (functionalityComponent !== null && (functionalityComponent as Functionality).functionalityId !== newFunction) {
            //Remove secondary functionalities
            for (let i = 0; i < this.children.length; ) {
                if (this.children[i].entityType === SceneConstants.EntityType.FunctionalityChip) {
                    this.deleteChild(this.children[i].id);
                } else {
                    i++;
                }
            }
            (functionalityComponent as Functionality).functionalityId = newFunction;
            (functionalityComponent as Functionality).functionalityName = functionName;
            return true;
        }
        return false;
    }

    /**
     *  get functionalityId chip of the room
     */
    get functionalityChips(): Array<FunctionalityChip> {
        let chips = [];
        for (let i = 0; i < this.children.length; i++) {
            if (this.children[i].entityType === SceneConstants.EntityType.FunctionalityChip) {
                chips.push(this.children[i]);
            }
        }
        return chips;
    }

    /**
     *  get secondary functionalities of the room
     */
    get secondaryFunctionalities(): Array<Functionality> {
        let secondaryFuncs = [];
        for (let i = 0; i < this.children.length; i++) {
            if (this.children[i].entityType === SceneConstants.EntityType.FunctionalityChip) {
                secondaryFuncs.push(this.children[i].getComponent(ComponentConstants.ComponentType.Functionality));
            }
        }
        return secondaryFuncs;
    }

    /**
     *  add a secondary functionalities of the room
     */
    addSecondaryFunctionality(newFunction, name: string, imgUrl: string, imgSecondaryUrl: string, position: math.vec3) {
        //Create chip and add it to room and array
        let chip = EntityFactory.createFunctionalityChip(newFunction, name, imgUrl, imgSecondaryUrl, -1);
        this.addChild(chip);
        if (position !== null && position !== undefined) {
            chip.position = math.vec3.clone(position);
            if (chip.isValid(this.scene.currentFloor)) {
                chip.isOnPlan = true;
            }
        }

        return true;
    }

    /**
     * remove a secondary functionalityId
     */
    removeFunctionality(toRemoveFunc: ComponentConstants.Functionalities) {
        for (let i = 0; i < this.children.length; i++) {
            if (this.children[i].entityType === SceneConstants.EntityType.FunctionalityChip && (this.children[i] as FunctionalityChip).functionality === toRemoveFunc) {
                this.deleteChild(this.children[i].id);
                break;
            }
        }
    }

    /**
     * remove a secondary functionalityId
     */
    removeFunctionalities() {
        for (let i = 0; i < this.children.length; ) {
            if (this.children[i].entityType === SceneConstants.EntityType.FunctionalityChip) {
                this.deleteChild(this.children[i].id);
            } else {
                i++;
            }
        }
    }

    /**
     * change a secondary functionalityId
     */
    changeFunctionality(oldFunc, newFunction, name, imgUrl, imgSecondaryUrl) {
        for (let i = 0; i < this.children.length; i++) {
            if (this.children[i].entityType === SceneConstants.EntityType.FunctionalityChip && (this.children[i] as FunctionalityChip).functionality === oldFunc) {
                (this.children[i] as FunctionalityChip).changeFunctionality(newFunction, name, imgUrl, imgSecondaryUrl);
                break;
            }
        }
    }

    set data(value: any) {
        let component = this.getComponent(ComponentConstants.ComponentType.Functionality);

        if (component !== null) {
            (component as Functionality)._data = value;
        }
    }

    get data(): any {
        let component = this.getComponent(ComponentConstants.ComponentType.Functionality);

        if (component !== null) {
            return (component as Functionality)._data;
        }
        return undefined;
    }

    setData(field: string, value: any) {
        let component = this.getComponent(ComponentConstants.ComponentType.Functionality);

        if (component !== null) {
            (component as Functionality)._data[field] = value;
        }
    }

    getData(field: string): any {
        let component = this.getComponent(ComponentConstants.ComponentType.Functionality);

        if (component !== null) {
            return (component as Functionality)._data[field];
        }
        return undefined;
    }

    /**
     * check chips validity
     */
    checkChipsValidity() {
        for (let i = 0; i < this.functionalityChips.length; i++) {
            if (!this.functionalityChips[i].isValid(this.scene.currentFloor)) {
                this.functionalityChips[i].isOnPlan = false;
            }
        }
    }

    /**
     * Create the temporaryWall that will be used during edition for all the walls room
     */
    startTemporary() {
        let temp = new TemporaryRoom(this);
        this.temporary = temp;

        for (let i = 0; i < this.walls.length; i++) {
            this.walls[i].startTemporary();
        }
        for (let i = 0; i < this.nonRoomedWalls.length; i++) {
            this.nonRoomedWalls[i].startTemporary();
        }
    }

    /**
     * delete the temporaryWall of all the walls room
     */
    endTemporary() {
        this.temporary = null;

        for (let i = 0; i < this.walls.length; i++) {
            this.walls[i].endTemporary();
        }
        for (let i = 0; i < this.nonRoomedWalls.length; i++) {
            this.nonRoomedWalls[i].endTemporary();
        }
    }

    /**
     * save the temporary data in the room and delete the temporaryRoom
     */
    saveAndEndTemporary() {
        let heightTemp = this.height;
        let floorHeightTemp = this.floorHeight;
        let plinthsTemp = this.hasPlinths;
        let plinthsHeightTemp = this.plinthsHeight;
        let plinthsMaterialTypeTemp = this.plinthsMaterialType;
        let hasCornicesTemp = this.hasCornices;

        this.temporary = null;

        this.height = heightTemp;
        this.floorHeight = floorHeightTemp;
        this.hasPlinths = plinthsTemp;
        this.plinthsHeight = plinthsHeightTemp;
        this.plinthsMaterialType = plinthsMaterialTypeTemp;
        this.hasCornices = hasCornicesTemp;
    }
}
