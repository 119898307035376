/**
 * Created by rhinov15 on 02/09/2016.
 */
import { SceneConstants } from "../SceneConstants";
import { TechnicalElement } from "./TechnicalElement";
import { ComponentConstants } from "../../components/ComponentConstants";
import { TechnicalElementType } from "../../components/ComponentModule";
import { SavaneCookie } from "../../utils/SavaneCookie";
import { TemporaryTechnicalElement } from "../temporary/TemporaryTechnicalElement";

/**
 * WaterSource technical element in the scene
 */
export class WaterSource extends TechnicalElement {
    private _isIn: boolean;
    private _isOut: boolean;

    constructor(id: number, objectId: SceneConstants.TechnicalElementType, length: number, width: number, height: number, objectType: string) {
        super(id, objectId, length, width, height, objectType);

        // Water in and water out parameters
        this._isIn = true;
        this._isOut = true;

        // Set correct 3D model
        this.setTechnicalElementType();
    }

    // Setters and getters
    get isIn() {
        if (this.temporary === null) {
            return this._isIn;
        } else {
            return (this.temporary as  TemporaryTechnicalElement).isIn;
        }
    }

    set isIn(value) {
        if (this.temporary === null) {
            this._isIn = value;
        } else {
            (this.temporary as TemporaryTechnicalElement).isIn = value;
        }
    }

    get isOut() {
        if (this.temporary === null) {
            return this._isOut;
        } else {
            return (this.temporary as TemporaryTechnicalElement).isOut;
        }
    }

    set isOut(value) {
        if (this.temporary === null) {
            this._isOut = value;
        } else {
            (this.temporary as TemporaryTechnicalElement).isOut = value;
        }
    }

    /**
     * Set the correct AM tech Elt Type
     */
    setTechnicalElementType() {
        var componentList = this.getComponents(ComponentConstants.ComponentType.TechnicalElementType) as Array<TechnicalElementType>;

        if (componentList.length > 0) {
            componentList[0].technicalElementTypeId = SavaneCookie.getCookie("Rhinov-Savane-DefaultTechnicalElementModelRectangularPole", SceneConstants.DefaultTechnicalElementType.rectangularPole);
        } else {
            this.addComponent(new TechnicalElementType(SavaneCookie.getCookie("Rhinov-Savane-DefaultTechnicalElementModelRectangularPole", SceneConstants.DefaultTechnicalElementType.rectangularPole)));
        }
    }

    saveAndEndTemporary(): void {
        var isInTemp;
        var isOutTemp;

        if (this.isIn !== undefined) {
            isInTemp = this.isIn;
        }
        if (this.isOut !== undefined) {
            isOutTemp = this.isOut;
        }

        super.saveAndEndTemporary();

        if (isInTemp !== undefined) {
            this.isIn = isInTemp;
        }
        if (isOutTemp !== undefined) {
            this.isOut = isOutTemp;
        }
    }
}
