import { GeometryPrimitive } from './GeometryPrimitive';
import { math } from './../Transform';
import { TemporaryGeometryPrimitive } from './../temporary/TemporaryGeometryPrimitive';
import { Component } from '../../components/Component';

/**
 * GeometryPrimitive  of type : box
 */
export class Box extends GeometryPrimitive {
    // Onyl width and length in addition of height
    protected _width: number;
    protected _length: number;

    constructor(primitiveType: number, h: number, l = 0, w = 0) {
        super(primitiveType, h);

        this._width = w;
        this._length = l;
    }

    getCoatingQuantity(coating: Component): number {
        return(((this._width * this.height * 2 + this._width * this._length * 2 + this.height * this._length * 2) / 2) / (100 * 100 * 100));
    }

    /**
     * Getter for the width of the box
     */
    get width(): number {
        if (this.temporary === null) {
            return this._width;
        }
        else {
            return (this.temporary as TemporaryGeometryPrimitive).width;
        }
    }

    /**
     * Setter for the width of the box
     *
     * @param {*} w
     */
    set width(w: number) {
        if (this.temporary === null) {
            this._width = w;
        }
        else {
            (this.temporary as TemporaryGeometryPrimitive).width = w;
        }
    }

    /**
     * Getter for the length of the box
     */
    get length(): number {
        if (this.temporary === null) {
            return this._length;
        }
        else {
            return (this.temporary as TemporaryGeometryPrimitive).length;
        }
    }

    /**
     * Setter for the length of the box
     *
     * @param {*} l
     */
    set length(l: number) {
        if (this.temporary === null) {
            this._length = l;
        }
        else {
            (this.temporary as TemporaryGeometryPrimitive).length = l;
        }
    }

    /**
     * Returns the array of 4 point deliminting the box (2d)
     */
    get boundingBox(): Array<math.vec3> {
        let points = [];
        let point1 = math.vec3.create();
        let point2 = math.vec3.create();
        let point3 = math.vec3.create();
        let point4 = math.vec3.create();
        //Precalculate sin/cos
        let sAngle = Math.sin(this.angle);
        let cAngle = Math.cos(this.angle);
        let invSAngle = Math.sin(Math.PI / 2 - this.angle);
        let invCAngle = Math.cos(Math.PI / 2 - this.angle);

        math.vec3.set(point1, this.position[0] + cAngle *
            this.length / 2, this.position[1] +
            sAngle * this.length / 2, 0);

        math.vec3.set(point2, point1[0] -
            invCAngle *
            this.width / 2, point1[1] +
            invSAngle *
            this.width / 2, 0);

        math.vec3.set(point1, point1[0] +
            invCAngle *
            this.width / 2, point1[1] -
            invSAngle *
            this.width / 2, 0);

        math.vec3.set(point3, this.position[0] -
            cAngle *
            this.length / 2, this.position[1] -
            sAngle * this.length / 2, 0);

        math.vec3.set(point4, point3[0] +
            invCAngle *
            this.width / 2, point3[1] -
            invSAngle *
            this.width / 2, 0);
        math.vec3.set(point3, point3[0] -
            invCAngle *
            this.width / 2, point3[1] +
            invSAngle *
            this.width / 2, 0);

        points.push(point1);
        points.push(point2);
        points.push(point3);
        points.push(point4);

        return points;
    }

    /**
     * save the temporary data in the joinery and delete the temporaryJoinery
     */
    saveAndEndTemporary() {
        // Common parameters to all joineries, we get the from getter getting them from the temporary object
        let savedLocalMatrix = math.mat4.create();
        math.mat4.copy(savedLocalMatrix, this.transform.localMatrix);
        let heightTemp = this.height;
        let hideInAxoTemp = this.hideInAxo;
        let cutByJoineriesTemp = this.cutByJoineries;
        // Specific parameters
        let lengthTemp = this._length;
        let widthTemp = this._width;

        // Destroy the temporary object so that getters and setter now modify the real object
        this.temporary = null;

        // Reassign parameters with setters setting them to the real object now (temporary is null now)
        math.mat4.copy(this.transform.localMatrix, savedLocalMatrix);
        this.height = heightTemp;
        this.hideInAxo = hideInAxoTemp;
        this.cutByJoineries = cutByJoineriesTemp;

        // Reassign specific parameters
        this._length = lengthTemp;
        this._width = widthTemp;
    }
}
