import { Floor, TechnicalElement, WaterSource, SceneConstants, Transform, arrangementManager } from '../../SavaneJS';
import { TemporaryEntity } from './TemporaryEntity';

/**
 * TemporaryJoinery is used for arrangementObject edition
 */
export class TemporaryTechnicalElement extends TemporaryEntity {
    public width: number;
    public length: number;
    public height: number;
    public materialType: number;
    public lightOn: boolean;
    public lightOff: boolean;
    public temperature: SceneConstants.LightTemperature;
    public lightColor: string;
    public intensity: SceneConstants.SpotLightIntensity;
    public shapeType: SceneConstants.ShapeType | SceneConstants.FrameShapes;
    public isIn: boolean;
    public isOut: boolean;
    public symmetry: boolean;

    private _transform: Transform;

    constructor(techElement: TechnicalElement) {
        super(techElement);

        // Save size parameters
        this.width        = techElement.width;
        this.length       = techElement.length;
        this.height       = techElement.height;

        // Save material type of the joinery
        this.materialType = techElement.materialType;

        // Save technical element state
        if (techElement.lightOn !== undefined) {
            this.lightOn    = techElement.lightOn;
        }
        if (techElement.lightOff !== undefined) {
            this.lightOff    = techElement.lightOff;
        }
        if (techElement.temperature !== undefined) {
            this.temperature = techElement.temperature;
        }
        if (techElement.lightColor !== undefined) {
            this.lightColor = techElement.lightColor;
        }

        // Save technical element state
        if (techElement.intensity !== undefined)
        {
            this.intensity    = techElement.intensity;
        }

        // Save shape type for poles
        this.shapeType    = techElement.shapeType;

        if ((techElement as WaterSource).isIn !== undefined)
        {
            this.isIn     = (techElement as WaterSource).isIn;
        }

        if ((techElement as WaterSource).isOut !== undefined)
        {
            this.isOut    = (techElement as WaterSource).isOut;
        }

        if (techElement.symmetry !== undefined)
        {
            this.symmetry    = techElement.symmetry;
        }

        this._transform   = new Transform(null);
        this._transform.clone(techElement.transform);
    }

    get transform() : Transform {
        return this._transform;
    }

    computeValidity(floor: Floor) {
        this._isValid = arrangementManager.isTechnicalElementValid(this.entity as TechnicalElement, floor);
    }
}
