import { Comment, Floor, Transform } from '../../SavaneJS';
import { TemporaryEntity } from './TemporaryEntity';

/**
 * TemporaryComment is used for comment entity edition
 */
export class TemporaryComment extends TemporaryEntity {
    public text: string;
    public images: Array<string>;
    private _transform: Transform;

    constructor(comment: Comment) {
        super(comment);

        // Save light parameters
        this.text    = comment.text;
        this.images  = comment.images;

        this._transform = new Transform(null);
        this._transform.clone(comment.transform);
    }

    computeValidity(floor: Floor) {
        this._isValid = true;
    }

    get transform(): Transform {
        return this._transform;
    }
}
