import { TemporarySketchBlock } from "./temporary/TemporaryModule";
import { Wall, ArrangementObject, SceneConstants, SavaneConstants, math, Transform, SavaneMath, Segment } from "../SavaneJS";
import { SnappableEntity } from "./Interfaces/SnappableEntity";
import { Entity } from "./Entity";

export class SketchBlock extends Entity implements SnappableEntity {
    private _sketchId: string;
    private _length: number;
    private _width: number;
    private _height: number;
    
    constructor(id: number, sketchId: string, length: number, width: number, height: number) {
        super();

        this._id = id;
        this._sketchId = sketchId;

        this._length = length;
        this._width = width;
        this._height = height;

        this._transform = super.transform;
        let res = math.vec3.create();
        math.vec3.set(res, 0, 0, this._height * 0.5);
        this.position = res;
    }

    accessor stackable: boolean;
    accessor isAnchorActive: boolean;
    accessor anchor: math.vec3;
    accessor minHeight: number;
    accessor localMinHeight: number;

    get transform(): Transform {
        if (this.temporary === null) {
            return this._transform;
        } else {
            return (this.temporary as TemporarySketchBlock).transform;
        }
    }

    get sketchId(): string {
        return this._sketchId;
    }

    /**
     * Getter for the object width
     *
     */
    get width(): number {
        if (this._width === undefined) {
            return 0;
        }
        return this._width;
    }

    /**
     * Getter for the object height
     *
     */
    get height(): number {
        if (this._height === undefined) {
            return 0;
        }
        return this._height;
    }

    /**
     * Getter for the object length
     *
     */
    get length(): number {
        if (this._length === undefined) {
            return 0;
        }
        return this._length;
    }

    /**
     * Getter for the Entity type
     *
     */
    get entityType(): SceneConstants.EntityType {
        return SceneConstants.EntityType.SketchBlock;
    }

    /**
     * Setter for absolute position of ArrangementObject
     *
     * @param {*} p
     */
    set position(p: math.vec3) {
        if (this.temporary === null) {
            this.transform.globalPosition = p;
        } else {
            (this.temporary as TemporarySketchBlock).transform.globalPosition = math.vec3.clone(p);
        }
    }

    get position(): math.vec3 {
        return this.transform.globalPosition;
    }

    get floorHeight(): number {
        let parent = this.parent;
        while (parent && !parent.isFloorEntity()) {
            parent = parent.parent;
        }

        if (!parent) {
            return this.transform.localPosition[2] - this.height * 0.5;
        } else {
            return this.transform.globalPosition[2] - this.height * 0.5 - parent.transform.globalPosition[2];
        }
    }

    set floorHeight(fh: number) {
        let newPos = math.vec3.create();
        math.vec3.set(newPos, this.transform.localPosition[0], this.transform.localPosition[1], fh + this.height * 0.5);
        this.transform.localPosition = newPos;
    }

    /**
     * Getter for the object angle
     *
     */
    get angle(): number {
        return this.transform.globalZRotation;
    }

    /**
     * Set rotation on a top down view (plan2d)
     *
     * @param {*} z
     */
    setRotationZ(z: number) {
        this.transform.globalZRotation = z;
        if (this.temporary !== null) {
            this.temporary.recomputeValidity = true;
        }
    }

    /**
     * Set rotation on a top down view (plan2d)
     *
     * @param {*} a
     */
    set angle(a: number) {
        this.setRotationZ(a);
    }

    /**
     * Returns the array of 4 point deliminting the arrangement (2d)
     */
    get boundingBox(): Array<math.vec3> {
        return this.getHooverBox(0);
    }

    /**
     * get bounding box bigger of the precision
     * @param precision
     */
    getHooverBox(precision: number): Array<math.vec3> {
        let length = this.length;
        let width = this.width;

        if (this.parent && this.parent.isArrangementObjectEntity()) {
            let parent = (this.parent as ArrangementObject);
            if (parent.originalLength) {
                length *= parent.length / parent.originalLength;
            }
            if (parent.originalWidth) {
                width *= parent.width / parent.originalWidth;
            }
        }

        let points = [];
        points.push(math.vec3.fromValues(length / 2 + precision, -width / 2 - precision, 0));
        points.push(math.vec3.fromValues(length / 2 + precision, width / 2 + precision, 0));
        points.push(math.vec3.fromValues(-length / 2 - precision, width / 2 + precision, 0));
        points.push(math.vec3.fromValues(-length / 2 - precision, -width / 2 - precision, 0));

        var q = math.quat.create();
        math.quat.setAxisAngle(q, math.vec3.fromValues(0, 0, 1), this.angle);
        var m = math.mat4.create();
        math.mat4.fromRotationTranslation(m, q, this.position);
        for (var i = 0; i < points.length; ++i) {
            math.vec3.transformMat4(points[i], points[i], m);
            points[i][2] = 0;
        }

        return points;
    }

    /**
     * Create the temporaryArrangementObject that will be used during edition
     */
    startTemporary() {
        var temp = new TemporarySketchBlock(this);
        this.temporary = temp;
    }

    /**
     * delete the temporaryArrangementObject
     */
    endTemporary() {
        this.temporary = null;
    }

    /**
     * save the temporary data in the arrangementObject and delete the temporaryArrangementObject
     */
    saveAndEndTemporary() {
        let positionTemp = this.position;
        let angleTemp = this.angle;
        this.temporary = null;
        this.position = positionTemp;
        this.setRotationZ(angleTemp);
    }

    /**
     * Check if bounding box contains
     *
     * @param p
     * @param precision
     */
    contains(p: math.vec3, precision: number = SavaneConstants.PositionTolerance): SketchBlock {
        if (SavaneMath.isInPoly(p, this.getHooverBox(precision))) {
            return this;
        }
        return null;
    }

    /**
     * Test whether the wall cross the object
     *
     * @param {*} wall
     */
    crossWall(wall: Wall): boolean {
        let box = this.boundingBox;
        for (let i = 0; i < box.length; i++) {
            if (Segment.areSegmentCrossing(wall.segment, new Segment(box[i], box[(i + 1) % box.length]), SavaneConstants.PositionTolerance)) {
                return true;
            }
        }
        return false;
    }
}
