import { ComponentConstants } from '../SavaneJS';
import { Coating } from './Coating';

export class Credence extends Coating {
    public height: number;
    public credenceIndex: number;

    constructor(coatingId: string, manufacturer: any, retailer: any, hangType: Coating.HangType, colors: any, randomization: any, height: number, credenceIndex:  number) {
        super(coatingId, manufacturer, retailer, hangType, colors, randomization, undefined, undefined);
        this.height = height !== undefined ? height : ComponentConstants.CredenceDefaultHeight;
        this.credenceIndex = (credenceIndex === undefined ? null : credenceIndex);
    }

    clone(): Credence {
        let coat = new Credence(this._coatingId, this._manufacturer, this._retailer, this._hangType, this._colors, this._randomization, this.height, this.credenceIndex);
        coat.colorIndex = this._colorIndex;
        coat.offset = this._offset;
        return coat;
    }

    get componentType(): ComponentConstants.ComponentType {
        return ComponentConstants.ComponentType.Credence;
    }
}
