import { Command, CommandEnum, ScaleFloorCommand } from './CommandModule';
import { Scene } from '../SavaneJS';

export class ScaleProjectCommand extends Command
{
    // Command list to execute and undo
    private _subCommands: Array<Command> = [];
    // Scale factor
    private _scaleFactor: number;

    constructor(scaleFactor: number, scene: Scene) {
        super();
        // Store scale factor
        this._scaleFactor= scaleFactor;
        // Build commands
        for (let i = 0; i < scene.floors.length; i++) {
            // Create a ScaleFloorCommand for each floor of the array
            this._subCommands.push(new ScaleFloorCommand(null, true, scaleFactor, scene.floors[i]));
        }
    }

    // Command name returned from a global enum
    name(): string {
        return CommandEnum.ScaleProjectCommand;
    }

    // Datas that allows the command to be executed
    execDatas(): any {
        return {
            _scaleFactor: this._scaleFactor
        };
    }

    // Undo the current command
    undo() {
        // Call scale floor undo for each command created by the consructor
        for (let i = 0; i < this._subCommands.length; i++) {
            this._subCommands[i].undo();
        }

        super.undo();
    }

    // Execute current command (redo)
    execute() {

        // Call scale floor execute for each command created by the consructor
        for (let i = 0; i < this._subCommands.length; i++) {
            // Execute the command
            this._subCommands[i].execute();
        }

        super.execute();
    }
};
