/**
 * Created by rhinov8e on 02/10/2015.
 */
export class SceneConstants {
  constructor() {}
}

export namespace SceneConstants {
  export const CameraPerspectiveOriginalFOV = 55;

  export enum CameraType {
    Perspective = 0,
    Panoramic = 1,
    Axonomic = 2,
    PhotoRender = 3,
    Video = 4,
    Storage = 5,
  };

  export enum CameraPreset {
    NormalPaysage = 0,
    NormalPortrait = 1,
    A4Paysage = 2,
    A4Portrait = 3,
    A3Paysage = 4,
    A3Portrait = 5,
    A0Paysage = 6,
    A0Portrait = 7,
    Free = 8,
    QuizzSquare = 9,
    QuizzLandscape = 10,
    NormalSquare = 11,
    Instragram = 12,
    RangementPaysage = 13,
    RangementPortrait = 14,
  };

  export enum RenderType {
    Inactive = 0,
    Day = 1,
    Night = 2,
    DayAndNight = 3,
  };

  export const CameraPresetSize = {
    sd: [
      { width: 1600, height: 1200 },
      { width: 1080, height: 1440 },
      { width: 3508, height: 1973 },
      { width: 2339, height: 3508 },
      { width: 4964, height: 2787 },
      { width: 3307, height: 4964 },
      { width: 13000, height: 7303 },
      { width: 8667, height: 13000 },
      { width: 0, height: 0 },
      { width: 900, height: 900 },
      { width: 900, height: 600 },
      { width: 1200, height: 1200 },
      { width: 1080, height: 1350 },
      { width: 1180, height: 636 },
      { width: 1080, height: 1440 },
    ],
    hd: [
      { width: 3200, height: 2400 },
      { width: 1920, height: 2560 },
      { width: 3508, height: 1973 },
      { width: 2339, height: 3508 },
      { width: 4964, height: 2787 },
      { width: 3307, height: 4964 },
      { width: 13000, height: 7303 },
      { width: 8667, height: 13000 },
      { width: 0, height: 0 },
      { width: 1800, height: 1800 },
      { width: 1800, height: 1200 },
      { width: 2400, height: 2400 },
      { width: 2160, height: 2700 },
      { width: 1180, height: 636 },
      { width: 1920, height: 2560 },
    ],
  };

  export enum LightType {
    Sphere = 0,
    Plane = 1,
    Dome = 2,
  };

  export enum EntityType {
    Wall = 0,
    Joinery = 1,
    Scene = 2,
    ArrangementObject = 3,
    Floor = 4,
    ArrangementGroup = 5,
    Room = 6,
    FunctionalityChip = 7,
    TechnicalElement = 8,
    Staircase = 9,
    // Deprecated don't use
    Light = 10,
    RenderCamera = 11,
    ArrangementZone = 12,
    // Deprecated don't use
    ArrangementDecorated = 13,
    SketchBlock = 14,
    WorkTop = 15,
    UserPicture = 16,
    Sun = 17,
    Comment = 18,
    GeometryPrimitive = 19,
    World = 20,
    CameraAnimation = 21,
  };

  export enum JoineryType {
    door = 0,
    opening = 1,
    window = 2,
    frenchWindow = 3,
    pictureWindow = 4,
    openUpWall = 5,
    canopy = 6,
    cupboardDoor = 7,
    garageDoor = 8,
    frontDoor = 9,
    niche = 10,
    fixedWindow = 11,
    velux = 12,
  };

  export enum GeometryPrimitiveType {
    box = 0,
    cylinder = 1,
    triangle = 2,
    oval = 3,
    sphere = 4,
    hood = 5,
    halfarc = 6,
  };

  export enum DefaultJoineryType {
    pictureWindow = "601a870e899e082196fee24a",
    simplePictureWindow = "5e09d242e5355361e98d3011",
    triplePictureWindow = "5c3607cca3fb5a381ca7c68f",
    quadPictureWindow = "5e0a0f91e5355361e98d3019",
    canopy = "5fad1c4a8f1b8b7ad9c2ce98",
    cupboardDoor = "5ff48b45bf75301c610dff42",
    singleCupboardDoor = "5ff486976324651be13128e7",
    door = "6220d4f707454c4d9284a5a5",
    doubleDoor = "593fac3a9c22107c0a288dca",
    slidingDoor = "593faccf9c22107c0a288dcd",
    slidingDoubleDoor = "5c3f3ef1ef2a5262de8a3152",
    frenchWindow = "5937b4df6bfdc6427509c6c8",
    doubleFrenchWindow = "593fae49e9f7797c5d28249b",
    garageDoor = "5937b4980cf9223f0679fdaa",
    window = "5937b4f2cec7f144718f5ed0",
    doubleWindow = "601a8708899e082196fee232",
    fixedWindow = "5bbb501a779c363f523544ad",
    velux = "5c6ff4e6dd8e860c3a620963",
    frontDoor = "601bb041a3e7941d2b98fdeb",
    niche = "5f47c9e036dfda08e66c2ae0",
    opening = "5f3bac3356d3e0116aa5f467",
  };

  export enum StaircaseType {
    straight = 0,
    quarterTurn = 1,
    doubleQuarterTurn = 2,
    circular = 3,
  };

  export enum StaircaseCloseType {
    opened = 0,
    opened_riser = 1,
    closed = 2,
    boxed = 3,
  };

  export enum StaircaseLimonType {
      none = 0,
      center_slope = 1,
      center_square = 2,
      side_slope = 3,
      side_square = 4,
    };

  export enum TechnicalElementType {
    pole = 0,
    stove = 1,
    fireplace = 2,
    radiator = 3,
    serviceShaft = 4,
    boiler = 5,
    waterSource = 6,
    ceilingBox = 7,
    switchBoard = 8,
    socketTV = 9,
    socketSixteen = 10,
    socketThirtyTwo = 11,
    wardRobe = 12,
    airConditioner = 13,
    spotLight = 14,
    beam = 15,
    rosette = 16,
    frame = 17,
    guardrail = 18,
    wallDecoration = 19,
  };

  export enum FrameShapes {
    oneCentralBeam = 0,
    threeCentralBeams = 1,
    fiveCentralBeams = 2,
  };

  export enum SpotLightIntensity {
    off = 0,
    lowPower = 1,
    mediumPower = 2,
    highPower = 3,
  };

  export enum LightTemperature {
    normal = 0,
    warm = 1,
    cold = 2,
    color = 3,
  };

  export enum DefaultWallType {
    balconyRail = "5bbf55c704045920d53b6096",
    cornice = "5d724e5a6e53b6633623607c",
  };

  export enum DefaultTechnicalElementType {
    rectangularPole = "5a670a7f06d2f177ee67628d",
    circularPole = "5c5ab1efecaf5d169b2e32aa",
    stove = "5dd269d0571a1b22bf5ae639",
    fireplace = "5a670a3f06d2f177ee676285",
    //old '5a670a6306d2f177ee676289'
    radiator = "60a50e3f4ef1ae302baa4425",
    airConditioner = "5ab279e71c42d4481a6c8ad3",
    rectangularServiceShaft = "cacabad",
    circularServiceShaft = "cacabad",
    boiler = "5ab281481c42d4481a6c8b03",
    waterSource = "",
    ceilingBox = "5b08138a3f889a112ce5f9ad",
    switchBoard = "5c5c34579949dc1671cde6e7",
    socketTV = "5b572acc25a1ce4748b6d33d",
    socketSixteen = "5b572b7e09f59547600d2c03",
    socketThirtyTwo = "5b572b7e09f59547600d2c03",
    wardRobe = "5b07de2f4f056c044ce64ec4",
    spotLight = "5bbb505a6baacc6a64f18afd",
    rosette = "5d07624bb846d52308a35130",
    beam = "5bbb5100f4fc543e54e5de23",
    staircase = "",
    circularStaircase = "",
    guardrail = "5bbf55c704045920d53b6096",
    wallDecoration = "614206fe562f3338b5b8d2c1",
  };

  /* TODO refacto
 Temporary enum using dev database ids
 */

  //5970ab1e39b350195e997c12,5970af2139b350195e997c17,5970af5939b350195e997c19,58580a622424b106b566ef93
  export const ArrangementType = {
    kitchenCooking: "579b65c5bb7be9f5111c280e",
    kitchenCold: "579b65cebb7be9f5111c280f",
    kitchenHood: "579b65d6bb7be9f5111c2810",
    kitchenWash: "579b65e6bb7be9f5111c2811",
    kitchenFurnitureBottom: "57dbf17bc292672a7def4724",
    // Kitchen bottom + Dish washers + small refrigerators
    snappableBottomArrangements: [
      // Ixina Kitchen
      "57dbf17bc292672a7def4724",
      // Lave linge
      "5c34c11ef48dcd380728545c",
      // Small fridge
      "5c34c0cc96634b38261ca95d",
      // Rangements Lapeyre
      "6708f801d52af3f7af79634a",
      // SDB Lapeyre
      "5bc06df64772d35c2d8839e6",
      "5bc06dd44772d35c2d8839e4",
      "5bc06db6d103a35c134d6c19",
    ],
    // Elements genarating bottom worktops Kitchen bottom + Dish washers + small refrigerators
    generateWorktopBottomArrangements: [
      "57dbf17bc292672a7def4724",
      "5c34c11ef48dcd380728545c",
      "5c34c0cc96634b38261ca95d",
    ],
    kitchenFurnitureTop: "57dbf191c292672a7def4725",
    // Kitchen top only today
    snappableTopArrangements: [
      // Ixina Kitchen
      "57dbf191c292672a7def4725",
      "5fdcc6ae27e7501c51147790",
      // Shelves
      "5f1feb93b8c4f42d4cd88e5d",
      // SDB Lapeyre
      "5a743d3af299274c61ac69bd",
    ],
    kitchenFurnitureColumn: "585809052424b106b566ef92",
    // Kitchen column + refrigerators + wine cellars
    snappableColumnArrangements: [
      // Ixina Kitchen
      "585809052424b106b566ef92",
      // Fridge
      "58c129d9178b09562a342ff9",
      // Wine cellar
      "5c34cbb8a24b85380cc236bb",
      // SDB Lapeyre
      "5bc06d434706b05c32efd8c5",
    ],
    kitchenVisibleSide: "5970ab1e39b350195e997c12",
    kitchenEndPanel: "5970af2139b350195e997c17",
    kitchenPlinth: "5970af5939b350195e997c19",
    kitchenFiller: "58580a622424b106b566ef93",
    kitchenHandle: "58933a3992143406c482fb2b",
    cookingPlate: "58bfe35826a00f3d9dfecb5b",
    oven: "5979e263a0542b2d94582301",
    microwave: "5979e27aa0542b2d94582303",
    fridge: "58c129d9178b09562a342ff9",
    sink: "58bfe33b26a00f3d9dfecb58",
    hood: "579b65d6bb7be9f5111c2810",
    washingMachine: "597f665820e6ae25c1eba27c",
    dishWasher: "598032ad20e6ae25c1eba288",
    dryer: "5980327720e6ae25c1eba286",
    kitchenCustomizableArrangements: [
                                      "57dbf17bc292672a7def4724",
                                      "57dbf191c292672a7def4725",
                                      "585809052424b106b566ef92",
                                      "5f1feb93b8c4f42d4cd88e5d",
    ],
    storageCustomizableArrangements: [
                                      // Rangements Lapeyre
                                      "6708f801d52af3f7af79634a",
                                      "678a70c30136b3444d1ba098",
                                    ],
    /* OLD bathroomArrangements: [
                                      "5bc06e68be6c755c1fb2d5e7",
                                      "5f2d0b3325fb361ec54b8e02",
                                      "5f2d0ac7f1402b4bf320bb13",
                                      "5f2d0d2100e9fe3ffe1d0f61",
                                      "5bc06ff5fc81215c3e9b98d1",
                                      "5bc06f5de94f625c082226b9",
                                      "5bc06ce4038c865c16041a66",
                                      "5bc06e3dfc81215c3e9b98cb",
                                      "5bc06db6d103a35c134d6c19",
                                      "5bc06f154772d35c2d8839ea",
                                      "5bc06e98008fef5c0a7e3f33",
                                      "5bc06f81d08ceb5c26f750cd",
                                      "5bc06d924706b05c32efd8c7",
                                      "5bc06df64772d35c2d8839e6",
                                      "5bc06fd7038c865c16041a68",
                                      "5bc06ef4fc81215c3e9b98cd",
                                      "5bc06fb8fc81215c3e9b98cf",
                                      "5bc06d6fefe98c5c27d0a811",
                                      "5bc06d434706b05c32efd8c5",
                                      "5bc06ed04772d35c2d8839e8",
                                      "5bc06e19d103a35c134d6c1b",
                                      "5d1f1910977741717a9e8f00",
                                      "5a743d3af299274c61ac69bd",
                                      "5a746488f299274c61ac69c1",
                                      "5a6ef1d76bde096d6e447623",
                                      "5bc06dd44772d35c2d8839e4",
                                      "5bc06d08be6c755c1fb2d5e5",
                                      "5f2d0e8a52c15644da70f4ea",
                                      "5f51f74ee23c0d6f78b5f548",
                                      "5f562dcd49357e143aa42717",
                                      "5f6b6699e9de7056bb9155d1",
                                    ],*/
    bathroomCustomizableArrangements: [
                                        // SDB Lapeyre
                                        "5bc06df64772d35c2d8839e6",
                                        "5bc06dd44772d35c2d8839e4",
                                        "5a743d3af299274c61ac69bd",
                                        "5bc06d434706b05c32efd8c5",
                                        "5bc06db6d103a35c134d6c19",
                                        // Plan de travail
                                        "5bc06e68be6c755c1fb2d5e7",
                                        // SDB mirror
                                        "5f562dcd49357e143aa42717",
                                      ],

    switchCustomizableArrangements: ["579b6541bb7be9f5111c2807"],
  };

  export enum defaultParameters {
    defaultCoating = "5f21626db36b241d008438de",
    defaultPlinthCoating = "5f21626db36b241d008438de",
    defaultSinkCoating = "5f76f4eaa0eac504c0fbda90",
    defaultWorktopCoating = "5f21642cd3b0c41d4ea4a489",
    defaultCredenceCoating = "5f21636d966cb31cd7425f78",
    defaultHandle = "5f2296ef00fed26e9ed2ea58",
  };

  export const GhostArrangementType = [
    "5970ab1e39b350195e997c12",
    "5970af5939b350195e997c19",
    "5970af2139b350195e997c17",
    "58580a622424b106b566ef93",
    "58933a3992143406c482fb2b",
    "5d00daa0b846d52308a350e4",
    // Poignées lapeyre
    "670f6f295ab9af159dd955b5",
    // Poignées SDB
    "5f6b6699e9de7056bb9155d1",
    // Poignées SDB rangement
    "6793a50125716ef37a5cc007"
  ];

  export const WebGLGhostArrangementType = [
    "5970ab1e39b350195e997c12",
    "5970af5939b350195e997c19",
    "5970af2139b350195e997c17",
    "58580a622424b106b566ef93",
  ];

  export enum ArrangementZoneType {
    housing = 0,
    housable = 1,
    collider = 2,
    hole = 3,
    handleleft = 4,
    handleright = 5,
    handlecenter = 6,
    handle = 7,
    free = 255,
  };

  export enum CeilingBoxType {
    rollingShutter = 0,
  };

  export const TechnicalElementFakeDimensions = [
    {},
    { length: 128 * (40 / 128) * 10, width: 78 * (40 / 128) * 10 },
    { length: 128 * (40 / 128) * 10, width: 48 * (40 / 128) * 10 },
    { length: 128 * (40 / 128) * 10, width: 17 * (40 / 128) * 10 },
    {},
    { length: 128 * (40 / 128) * 10, width: 128 * (40 / 128) * 10 },
  ];

  export enum ShapeType {
    quad = 0,
    ellipse = 1,
  };

  export enum OpeningMode {
    mode_default = 0,
    mode_slide = 1,
  };
  /**
   *
   * @type {{slide_left: number, slide_right: number}}
   */
  export enum SlideDirection {
    slide_left = 0,
    slide_right = 1,
  };
  /**
   * Define the side of the handle
   * @type {{handle_right: number, handle_left: number}}
   */
  export enum HandleSide {
    handle_right = 0,
    handle_left = 1,
    handle_center = 2, //Value for 2 doors
  };

  export enum WallInstallType {
    install_outside = -1,
    install_center = 0,
    install_inside = 1
  }

  export enum Symetry {
    default = 0,
    flip = 1,
  };

  export enum PlinthsCapValues {
    minHeight = 50,
    maxHeight = 1500,
  };

  export const TechElementMinLength = [
    50, 500, 100, 400, 100, 300, 200, 0, 10, 80, 80, 80, 600, 700, 60, 100, 100,
    100, 100, 10,
  ];

  export const TechElementDefaultLength = [
    200, 613, 1628, 550, 500, 400, 200, 0, 400, 80, 80, 80, 1000, 900, 130,
    2000, 544, 2000, 2000, 1000,
  ];

  export const TechElementMaxLength = [
    10000, 1100, 2500, 4000, 1200, 2500, 200, 0, 3500, 80, 80, 80, 2000, 1100,
    500, 20000, 2000, 20000, 20000, 20000,
  ];

  export const TechElementMinWidth = [
    50, 400, 120, 60, 100, 300, 200, 0, 10, 20, 20, 20, 400, 200, 60, 50, 100,
    100, 50, 10,
  ];

  export const TechElementDefaultWidth = [
    200, 500, 320, 120, 500, 300, 200, 0, 100, 20, 20, 20, 600, 250, 130, 150,
    544, 200, 100, 50,
  ];

  export const TechElementMaxWidth = [
    10000, 1100, 1200, 300, 1200, 1000, 200, 0, 600, 20, 20, 20, 1000, 300, 500,
    20000, 2000, 4000, 300, 5000,
  ];

  export const TechElementMinHeight = [
    200, 800, 400, 200, 1000, 600, 200, 0, 10, 80, 80, 80, 600, 190, 20, 20, 10,
    500, 300, 10,
  ];

  export const TechElementDefaultHeight = [
    3000, 2500, 1318, 550, 2500, 700, 200, 0, 400, 80, 80, 80, 800, 200, 20,
    250, 29, 750, 1000, 1000,
  ];

  export const TechElementMaxHeight = [
    9999, 9999, 9999, 2500, 9999, 2000, 200, 0, 1000, 80, 80, 80, 1600, 400,
    150, 20000, 50, 2500, 2500, 5000,
  ];

  export const TechElementDefaultFloorHeight = [
    0, // 0
    0,
    0,
    150,
    0,
    1400, // 5
    0,
    0,
    1000,
    200,
    200, // 10
    200,
    400,
    2250,
    0,
    0,
    0,
    0,
    0,
    150,
  ];

  export const MinimalJoineryThickness = 1;

  export const DefaultLongJoineryLength = 1400;
  export const DefaultShortJoineryLength = 900;
  export const MinimalJoineryLength = 150.0;

  export const DefaultWindowHeight = 1300;
  export const DefaultJoineryHeight = 2150;
  export const DefaultCanopyHeight = 950;
  export const MinimalJoineryHeight = 100;
  export const MaxJoineryHeight = 2040;
  export const DefaultWindowFloorHeight = 900;
  export const DefaultCupboardDoorHeight = 2500;
  export const DefaultVeluxLength = 780;
  export const DefaultVeluxHeight = 780;

  export const MinimalLapeyreCanopyHeight = 600;
  export const MaxLapeyreCanopyHeight = 1800;
  export const MinimalLapeyreCanopyLength = 200;
  export const MaxLapeyreCanopyLength = 3600;

  export const MinimalCuinellaSimpleCupboardHeight = 600;
  export const MaxCuinellaSimpleCupboardHeight = 2700;
  export const MinimalCuinellaSimpleCupboardLength = 500;
  export const MaxCuinellaSimpleCupboardLength = 1100;

  export const MinimalCuinellaDoubleCupboardHeight = 600;
  export const MaxCuinellaDoubleCupboardHeight = 2700;
  export const MinimalCuinellaDoubleCupboardLength = 1000;
  export const MaxCuinellaDoubleCupboardLength = 2200;

  export const WallMinLength = 100.0;
  export const DefaultWallThickness = 640;
  export const DefaultWallHeight = 2500;
  export const MinimalWallHeight = 0; //1100;
  export const MinimalWallThickness = 20;
  export const MaxWallThickness = 1000;

  export const DefaultInterfloorHeight = 200;

  //Maximum door or casement for value for each openingmode
  export const MaxDoorJoineryDefault = 2;
  export const MinDoorJoinerySlide = 2;
  export const MaxDoorJoinerySlide = 2;
  export const MinDoorPictureWindow = 1;
  export const StandardDoorPictureWindow = 2;
  export const MaxDoorPictureWindow = 4;

  export const MinWorktopverticesDistance = 9;
}
