import { Box } from './Box';

/**
 * GeometryPrimitive  of type : box
 */
export class Triangle extends Box {
    constructor(primitiveType: number, height: number, length = 0, width = 0) {
        super(primitiveType, height, length, width);
    }
}
