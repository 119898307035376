import * as Math from './math/Math';
export {Math}

import * as Commands from './commands/CommandModule';
export {Commands}

var SupportsPassive = false;
try {
  var opts = Object.defineProperty({}, 'passive', {
    get: function() {
        SupportsPassive = true;
    }
  });
  window.addEventListener("testPassive", () => {}, opts);
  window.removeEventListener("testPassive", () => {}, opts);
} catch (e) {}
export {SupportsPassive}

export * from './animations/AnimationModule';
export * from './components/ComponentModule';
export * from './scene/SceneModule';
export * from './managers/ManagerModule';
export * from './utils/SavaneMath';
export * from './utils/SavaneCookie';
export * from './utils/SavaneConstants';
export * from './network/NetworkModule';
export * from './utils/Segment';
export * from './utils/JSONSerializer';
export * from './utils/JSONImporter';
export * from './utils/VersionHandler';
export * from './events';
export * from './photo2Savane/Photo2Savane';
export * as KitchenTool from './kitchenTool/kitchenTool';
