import { math, Transform } from './../Transform';
import { TemporaryJoinery } from './../temporary/TemporaryJoinery';
import { SavaneCookie } from '../../utils/SavaneCookie';
import { CeilingBox, Room, SceneConstants, Wall } from '../../SavaneJS';
import { TechnicalElement } from '../TechElements/TechnicalElement';
import { Entity } from './../Entity';

/**
 * Joinery is an Entity representing door, windows, french windows etc
 *
 * @constructor
 */
export class Joinery extends Entity {
    // Create position vector
    private _position: math.vec3;
    // Save joinery type
    public joineryType: SceneConstants.JoineryType;
    // Set all default parameters
    private _subModelId: number;
    private _scalable: boolean = true;
    private _freeColor: boolean = true;
    private _length: number = 0;
    private _height: number = 0;
    private _floorHeight: number = 0;
    // Standard material type of a joinery (get it from cookie and set default to 0 PVC_BLANC)
    protected _materialType: number;
    // Orientation is a boolean. The real orientation depends of this boolean and the sens of the wall
    private _orientation: boolean = true;
    // Top transom (imposte) when it isn't said we talk about top transom
    private _transom: boolean = false;
    private _transomHeight: number = 2500;
    // Bottom transom (imposte)
    private _bottomTransom: boolean = false;
    private _bottomTransomHeight: number = 0;
    // Frosted glass
    private _frosted: boolean = false;
    // Cut slope under the joinery
    private _cutSlope: boolean = true;
    //wallInstallType = 1 = inside
    protected _wallInstallType: SceneConstants.WallInstallType = SceneConstants.WallInstallType.install_inside;

    constructor(joineryType: SceneConstants.JoineryType, position: math.vec3) {
        super();
        // Create position vector
        this._position = math.vec3.create();
        // Save joinery type
        this.joineryType = joineryType;
        // Standard material type of a joinery (get it from cookie and set default to 0 PVC_BLANC)
        this._materialType = parseInt(SavaneCookie.getCookie('Rhinov-Savane-DefaultJoineryColor', '0'));
    }

    /**
     * Getter for the Entity type
     */
    get entityType(): SceneConstants.EntityType {
        return SceneConstants.EntityType.Joinery;
    }

    /**
     * Getter for the center of the joinery
     */
    get position(): math.vec3 {
        if (this.temporary === null) {
            return this.transform.globalPosition;
        }
        else {
            return (this.temporary as TemporaryJoinery).transform.globalPosition;
        }
    }

    get subModelId(): number {
        return this._subModelId;
    }

    set subModelId(value: number) {
        this._subModelId = value;
    }

    get scalable(): boolean {
        if (this.temporary === null) {
            return this._scalable;
        } else {
            return (this.temporary as TemporaryJoinery).scalable;
        }
    }

    set scalable(s: boolean) {
        if (this.temporary === null) {
            this._scalable = s;
        } else {
            (this.temporary as TemporaryJoinery).scalable = s;
        }
    }

    get freeColor(): boolean {
        if (this.temporary === null) {
            return this._freeColor;
        } else {
            return (this.temporary as TemporaryJoinery).freeColor;
        }
    }

    set freeColor(c: boolean) {
        if (this.temporary === null) {
            this._freeColor = c;
        } else {
            (this.temporary as TemporaryJoinery).freeColor = c;
        }
    }

    get wallInstallType(): SceneConstants.WallInstallType {
        if (this.temporary === null) {
            return this._wallInstallType;
        }
        else {
            return (this.temporary as TemporaryJoinery).wallInstallType;
        }
    }

    set wallInstallType(it: SceneConstants.WallInstallType) {
        if (this.temporary === null) {
            this._wallInstallType = it;
        }
        else {
            (this.temporary as TemporaryJoinery).wallInstallType = it;
        }
    }

    // Get transformation matrix
    get transform(): Transform {
        if (this.temporary === null) {
            return this._transform;
        }
        else {
            return (this.temporary as TemporaryJoinery).transform;
        }
    }

    /**
     * Setter for the center of the joinery
     *
     * @param {*} position
     */
    set position(p: math.vec3) {
        if (this.temporary === null) {
            math.vec3.copy(this._position, p);
            this.transform.globalPosition = p;
        }
        else {
            (this.temporary as TemporaryJoinery).position = math.vec3.clone(p);
            (this.temporary as TemporaryJoinery).transform.globalPosition = p;
            (this.temporary as TemporaryJoinery).recomputeValidity = true;
        }
    }

    /**
     * Getter for the wall of the joinery
     */
    get wall(): Wall {
        if (this.temporary === null) {
            return (this.parent as Wall);
        }
        else {
            return (this.temporary as TemporaryJoinery).wall;
        }
    }

    /**
     * Setter for the wall of the joinery
     *
     * @param {*} wall
     */
    set wall(w: Wall) {
        if (this.temporary === null) {
            let pos = math.vec3.create();
            math.vec3.copy(pos, this.position);
            this.parent = w;
            pos[2] = this.position[2];
            this.position = pos;
        }
        else {
            (this.temporary as TemporaryJoinery).wall = w;
        }
    }

    /**
     * Getter for the length of the joinery
     */
    get length(): number {
        if (this.temporary === null) {
            return this._length;
        }
        else {
            return (this.temporary as TemporaryJoinery).length;
        }
    }

    /**
     * Setter for the length of the joinery
     *
     * @param {*} l
     */
    set length(l: number) {
        if (this.temporary === null) {
            this._length = l;
        }
        else {
            (this.temporary as TemporaryJoinery).length = l;
            (this.temporary as TemporaryJoinery).recomputeValidity = true;
        }
    }

    /**
     * Getter for the height of the joinery
     */
    get height() {
        if (this.temporary === null) {
            return this._height;
        }
        else {
            return (this.temporary as TemporaryJoinery).height;
        }
    }

    /**
     * Setter for the height of the joinery
     *
     * @param {*} h
     */
    set height(h: number) {
        if (this.temporary === null) {
            this._height = h;
        }
        else {
            (this.temporary as TemporaryJoinery).height = h;
        }
    }

    /**
     * Getter for the height of the joinery
     */
    get materialType(): number {
        if (this.temporary === null) {
            return this._materialType;
        }
        else {
            return (this.temporary as TemporaryJoinery).materialType;
        }
    }

    /**
     * Setter for the height of the joinery
     *
     * @param {*} mt
     */
    set materialType(mt: number) {
        if (this.temporary === null) {
            this._materialType = mt;
        }
        else {
            (this.temporary as TemporaryJoinery).materialType = mt;
        }
    }

    /**
     * Getter for frosted joinery
     */
    get frosted(): boolean {
        if (this.temporary === null) {
            return this._frosted;
        }
        else {
            return (this.temporary as TemporaryJoinery).frosted;
        }
    }

    /**
     * Setter for frosted joinery
     *
     * @param {*} newHeight
     */
    set frosted(f: boolean) {
        if (this.temporary === null) {
            this._frosted = f;
        }
        else {
            (this.temporary as TemporaryJoinery).frosted = f;
        }
    }

    /**
     * Getter for the height of the joinery
     */
    get cutSlope(): boolean {
        if (this.temporary === null) {
            return this._cutSlope;
        }
        else {
            return (this.temporary as TemporaryJoinery).cutSlope;
        }
    }

    /**
     * Setter for the height of the joinery
     *
     * @param {*} cs
     */
    set cutSlope(cs: boolean) {
        if (this.temporary === null) {
            this._cutSlope = cs;
        }
        else {
            (this.temporary as TemporaryJoinery).cutSlope = cs;
        }
    }

    /**
     * Getter for the floorHeight of the joinery
     */
    get floorHeight(): number {
        if (this.temporary === null) {
            return this._floorHeight;
        }
        else {
            return (this.temporary as TemporaryJoinery).floorHeight;
        }
    }

    /**
     * Setter for the floorHeight of the joinery
     *
     * @param {*} fh
     */
    set floorHeight(fh) {
        if (this.temporary === null) {
            this._floorHeight = fh;
        }
        else {
            (this.temporary as TemporaryJoinery).floorHeight = fh;
        }
    }

    /**
     * Getter for the upper transom of the joinery
     */
    get transom(): boolean {
        if (this.temporary === null) {
            return this._transom;
        }
        else {
            return (this.temporary as TemporaryJoinery).transom;
        }
    }

    /**
     * Setter for the transom of the joinery
     *
     * @param {*} t
     */
    set transom(t) {
        if (this.temporary === null) {
            this._transom = t;
        }
        else {
            (this.temporary as TemporaryJoinery).transom = t;
        }
    }

    /**
     * Getter for the upper transom of the joinery
     */
    get transomHeight(): number {
        if (this.temporary === null) {
            return this._transomHeight;
        }
        else {
            return (this.temporary as TemporaryJoinery).transomHeight;
        }
    }

    /**
     * Setter for the transom of the joinery
     *
     * @param {*} th
     */
    set transomHeight(th: number) {
        if (this.temporary === null) {
            this._transomHeight = th;
        }
        else {
            (this.temporary as TemporaryJoinery).transomHeight = th;
        }
    }

    /**
     * Getter for the bottom transom of the joinery
     */
    get bottomTransom(): boolean {
        if (this.temporary === null) {
            return this._bottomTransom;
        }
        else {
            return (this.temporary as TemporaryJoinery).bottomTransom;
        }
    }

    /**
     * Setter for the bottom transom of the joinery
     *
     * @param {*} t
     */
    set bottomTransom(t) {
        if (this.temporary === null) {
            this._bottomTransom = t;
        }
        else {
            (this.temporary as TemporaryJoinery).bottomTransom = t;
        }
    }

    /**
     * Getter for the bottom transom of the joinery
     */
    get bottomTransomHeight(): number {
        if (this.temporary === null) {
            return this._bottomTransomHeight;
        }
        else {
            return (this.temporary as TemporaryJoinery).bottomTransomHeight;
        }
    }

    /**
     * Setter for the bottom transom of the joinery
     *
     * @param {*} th
     */
    set bottomTransomHeight(th: number) {
        if (this.temporary === null) {
            this._bottomTransomHeight = th;
        }
        else {
            (this.temporary as TemporaryJoinery).bottomTransomHeight = th;
        }
    }

    /**
     * Getter for the orientation of the joinery
     */
    get orientation(): boolean {
        if (this.temporary === null) {
            return this._orientation;
        }
        else {
            return (this.temporary as TemporaryJoinery).orientation;
        }
    }

    /**
     * Setter for the orientation of the joinery
     *
     * @param {*} o
     */
    set orientation(o: boolean) {
        if (this.temporary === null) {
            this._orientation = o;
        }
        else {
            (this.temporary as TemporaryJoinery).orientation = o;
        }
    }

    get isExterior(): boolean {
        if (this.wall.rooms.length === 1) {
            return this.wall.rooms[0].isWallInRoom(this.wall.id);
        } else if (this.wall.rooms.length === 2) {
            return this.wall.rooms[0].isExterior || this.wall.rooms[1].isExterior;
        }
    }

    // Called when a property is changed to verify max and min boundaries or to perform computations before final assignment
    addToCappedProperty(property: string, value: any) : number {
        // -1 = unknwon property, 0 = no cap, 1 = minus cap, 2 max cap wil be returned at the end
        let capped = 0;

        // No attached wall or requested property doesn't exist, quit here
        if (this.wall === null || this[property] === undefined) {
            return -1;
        }

        if (property === "floorHeight") {
            let minHeight = 0;

            for (let i = 0 ; i < this.wall.rooms.length ; i++) {
                if (this.wall.rooms[i].floorHeight < minHeight) {
                    minHeight = this.wall.rooms[i].floorHeight;
                }
            }

            if (this[property] + value <= minHeight) {
                this[property] = minHeight;
                capped = 1;
            }
            if (this[property] + value + this.height >= this.wall.height) {
                let tmpValue = this.wall.height - (this[property] + value + this.height);

                if (this.height + tmpValue < SceneConstants.MinimalJoineryHeight) {
                    this.height = SceneConstants.MinimalJoineryHeight;
                    this[property] = this.wall.height - this.height;
                    capped = 1;
                }
                else {
                    this.height += tmpValue;
                }
            }
        }

        if (property === "height") {
            if (this[property] + value <= SceneConstants.MinimalJoineryHeight) {
                this[property] = SceneConstants.MinimalJoineryHeight;
                capped = 1;
            }

            if (this.joineryType !== SceneConstants.JoineryType.velux) {
                if (this[property] + value + this.floorHeight >= this.wall.height) {
                    let tmpValue = this.wall.height - (this[property] + value + this.floorHeight);

                    if (this.floorHeight + tmpValue < 0) {
                        this.floorHeight = 0;
                        this[property] = this.wall.height;
                        capped = 2;
                    }
                    else {
                        this.floorHeight += tmpValue;
                    }
                }
            }
            else {

            }
        }

        if (property === "length") {
            if (this[property] + value <= SceneConstants.MinimalJoineryLength) {
                this[property] = SceneConstants.MinimalJoineryLength;
                capped = 1;
            }
        }

        if (property === "transomHeight") {
            if (this[property] + value <= this.floorHeight + this.height + 100) {
                this[property] = this.floorHeight + this.height + 100;
                capped = 1;
            }

            if (this[property] + value >= (this.parent as Wall).height) {
                this[property] = (this.parent as Wall).height;
                capped = 2;
            }
        }

        if (property === "bottomTransomHeight") {
            let transomMinHeight = 0;

            // Search for highest room
            if ((this.parent as Wall).rooms.length !== 0) {
                for (let i = 0; i < (this.parent as Wall).rooms.length; i++) {
                    if ((this.parent as Wall).rooms[i].floorHeight >= transomMinHeight) {
                        transomMinHeight = (this.parent as Wall).rooms[i].floorHeight;
                    }
                }
            }

            if (this[property] + value <= transomMinHeight) {
                this[property] = transomMinHeight;
                capped = 1;
            }

            if (this[property] + value >= this.floorHeight - 100) {
                this[property] = this.floorHeight - 100;
                capped = 2;
            }
        }

        if (capped === 0) {
            this[property] += value;
        }

        return capped;
    }

    isClosedToWall(w: Wall) {
        let p = this.transform.globalPosition;
        let d = this.wall.wallDirection;
        let beginJoinery = math.vec3.fromValues(p[0] - d[0] * this.length / 2, p[1] - d[1] * this.length / 2, 0);
        let endJoinery = math.vec3.fromValues(p[0] + d[0] * this.length / 2, p[1] + d[1] * this.length / 2, 0);
        let plinthThickness = 100;
        if (math.vec3.distance(w.begin, beginJoinery) < w.thickness / 2 + 2 * plinthThickness) {
            return true;
        }
        if (math.vec3.distance(w.begin, endJoinery) < w.thickness / 2 + 2 * plinthThickness) {
            return true;
        }
        if (math.vec3.distance(w.end, beginJoinery) < w.thickness / 2 + 2 * plinthThickness) {
            return true;
        }
        if (math.vec3.distance(w.end, endJoinery) < w.thickness / 2 + 2 * plinthThickness) {
            return true;
        }

        return false;
    }

    /**
     * Create the temporaryJoinery that will be used during edition
     */
    startTemporary() {
        this.temporary = new TemporaryJoinery(this);
    }

    /**
     * delete the temporaryJoinery
     */
    endTemporary() {
        this.temporary = null;
    }

    /**
     * save the temporary data in the joinery and delete the temporaryJoinery
     */
    saveAndEndTemporary() {
        // Common parameters to all joineries, we get the from getter getting them from the temporary object
        let positionTemp = this.position;
        let lengthTemp = this.length;
        let heightTemp = this.height;
        let materialTypeTemp = this.materialType;
        let floorHeightTemp = this.floorHeight;
        let orientationTemp = this.orientation;
        let wallTemp = this.wall;
        let transomTemp = this.transom;
        let transomHeightTemp = this.transomHeight;
        let bottomTransomTemp = this.bottomTransom;
        let bottomTransomHeightTemp = this.bottomTransomHeight;
        let scalableTemp = this.scalable;
        let freeColorTemp = this.freeColor;

        // Specific parameters to this or that type of joinery
        let nbDoorsTemp;
        let nbCasementTemp;
        let openingModeTemp;
        let handleSideTemp;
        let isOpenedTemp;
        let thicknessTemp;
        let wallInstallTypeTemp;

        if ((this as any).nbDoors !== undefined) {
            nbDoorsTemp = (this as any).nbDoors;
        }

        if ((this as any).nbCasement !== undefined) {
            nbCasementTemp = (this as any).nbCasement;
        }

        if ((this as any).openingMode !== undefined) {
            openingModeTemp = (this as any).openingMode;
        }

        if ((this as any).handleSide !== undefined) {
            handleSideTemp = (this as any).handleSide;
        }

        if ((this as any).isOpened !== undefined) {
            isOpenedTemp = (this as any).isOpened;
        }

        if ((this as any).thickness !== undefined) {
            thicknessTemp = (this as any).thickness;
        }

        if ((this as any).wallInstallType !== undefined) {
            wallInstallTypeTemp = (this as any).wallInstallType;
        }

        // Destroy the temporary object so that getters and setter now modify the real object
        this.temporary = null;

        // Reassign parameters with setters setting them to the real object now (temporary is null now)
        this.position = positionTemp;
        this.length = lengthTemp;
        this.height = heightTemp;
        this.materialType = materialTypeTemp;
        this.floorHeight = floorHeightTemp;
        this.orientation = orientationTemp;
        this.wall = wallTemp;
        this.transom = transomTemp;
        this.transomHeight = transomHeightTemp;
        this.bottomTransom = bottomTransomTemp;
        this.bottomTransomHeight = bottomTransomHeightTemp;
        this.scalable = scalableTemp;
        this.freeColor = freeColorTemp;

        // Reassign specific parameters
        if (nbDoorsTemp !== undefined) {
            (this as any).nbDoors = nbDoorsTemp;
        }

        if (nbCasementTemp !== undefined) {
            (this as any).nbCasement = nbCasementTemp;
        }

        if (openingModeTemp !== undefined) {
            (this as any).openingMode = openingModeTemp;
        }

        if (handleSideTemp !== undefined) {
            (this as any).handleSide = handleSideTemp;
        }

        if (isOpenedTemp !== undefined) {
            (this as any).isOpened = isOpenedTemp;
        }

        if (thicknessTemp !== undefined) {
            (this as any).thickness = thicknessTemp;
        }

        if (wallInstallTypeTemp !== undefined) {
            (this as any).wallInstallType = wallInstallTypeTemp;
        }
    }
    /**
     * @function hasRollingShutter
     * @returns {boolean} true if there is a rollingShutter on this joinery
     */
    hasRollingShutter(): boolean {
        for (let j = 0; j < this.children.length; j++) {
            if (this.children[j].entityType === SceneConstants.EntityType.TechnicalElement &&
                (this.children[j] as TechnicalElement).objectId === SceneConstants.TechnicalElementType.ceilingBox &&
                (this.children[j] as CeilingBox).ceilingBoxType === SceneConstants.CeilingBoxType.rollingShutter) {
                return true;
            }
        }
        return false;
    }

    /**
     * @function isInRoom
     * @param r
     * @returns {boolean} true if the joinery in the room l_room
     */
    isInRoom(r: Room): boolean
    {
        let wall = this.wall;
        let thickness = wall.thickness;

        const positionJoinery = (this.temporary === null) ? this.transform.globalPosition : (this.temporary as TemporaryJoinery).transform.globalPosition;
        if (!this.orientation) thickness = -thickness;

        const translatedPos = math.vec3.create();
        math.vec3.scaleAndAdd(translatedPos, positionJoinery, wall.normal, thickness);

        return r.isInRoom(translatedPos);
    }
}
