import { GeometryPrimitive, math, SavaneConstants, SavaneMath, SceneConstants, Transform, Wall } from "../../SavaneJS";
import { Entity } from "../Entity";
import { TemporaryGeometryGroup } from "../temporary/TemporaryGeometryGroup";

/**
 * Group of geometry primitives
 */
export class GeometryGroup extends Entity {
    private _length: number = null;
    private _width: number = null;
    private _height: number = null;

    constructor(id: number) {
        super();

        this._id = id;
        this._transform = super.transform;
    }

    get transform(): Transform {
        if (this.temporary === null) {
            return this._transform;
        } else {
            return (this.temporary as TemporaryGeometryGroup).transform;
        }
    }

    get entityType(): SceneConstants.EntityType {
        return SceneConstants.EntityType.GeometryGroup;
    }

    get angle(): number {
        return this.transform.globalZRotation;
    }

    get localAngle(): number {
        return this.transform.localZRotation;
    }

    recomputeRelated() {
        if (this.parent !== null && this.parent.isGeometryGroupEntity()) {
            (this.parent as GeometryGroup).recomputeParent();
        }

        for (let i = 0; i < this.children.length; i++) {
            if (this.children[i].isGeometryGroupEntity()) {
                (this.children[i] as GeometryGroup).recomputeChildren();
            }
        }
    }

    recomputeParent() {
        this._length = null;
        this._width = null;
        this._height = null;
        if (this.parent !== null && this.parent.isGeometryGroupEntity()) {
            (this.parent as GeometryGroup).recomputeParent();
        }
    }

    recomputeChildren() {
        for (let i = 0; i < this.children.length; i++) {
            if (this.children[i].isGeometryGroupEntity()) {
                (this.children[i] as GeometryGroup).recomputeChildren();
            }
        }
    }

    set position(p: math.vec3) {
        this.recomputeRelated();

        this.transform.globalPosition = p;
        if (this.temporary !== null) {
            (this.temporary as TemporaryGeometryGroup).recomputeValidity = true;
        }
    }

    get position(): math.vec3 {
        return this.transform.globalPosition;
    }

    set localPosition(p: math.vec3) {
        this.transform.localPosition = p;
    }

    get localPosition(): math.vec3 {
        return this.transform.localPosition;
    }

    setRotationZ(a: number) {
        this.recomputeRelated();

        this.transform.globalZRotation = a;
        if (this.temporary !== null) {
            (this.temporary as TemporaryGeometryGroup).recomputeValidity = true;
        }
    }

    set angle(a: number) {
        this.setRotationZ(a);
    }

    addGeometry(arr: (GeometryPrimitive)) {
        let position = math.vec3.clone(arr.position);
        this.addChild(arr);
        arr.position = position;
    }

    deleteGeometry(entityId: number) {
        for (let i = 0; i < this.children.length; i++) {
            if (this.children[i].id === entityId) {
                this.deleteChild(entityId);
                return true;
            }
        }
        return false;
    }

    isInGroup(children: Array<GeometryPrimitive>, entityId: Number): boolean {
        let result = false;
        for (let i = 0; i < children.length; i++) {
            if (children[i].id === entityId) {
                return true;
            }

            if (children[i].isGeometryGroupEntity()) {
                result = this.isInGroup(this.children[i].children as Array<GeometryPrimitive>, entityId);
            }
        }

        return result;
    }

    deleteChild(entityId: number) {
        super.deleteChild(entityId);
        this._length = null;
        this._width = null;
        this._height = null;
        this.recomputeRelated();
    }

    addChild(e: GeometryPrimitive | GeometryGroup) {
        super.addChild(e);
        this._length = null;
        this._width = null;
        this._height = null;
        this.recomputeRelated();
    }

    set holdout(h: boolean) {
        this._holdout = h;
        for (let i = 0; i < this.children.length; i++) {
            this.children[i].holdout = h;
        }
    }

    get geometryPrimitiveRec(): Array<GeometryPrimitive> {
        let result = [];
        for (let i = 0; i < this.children.length; i++) {
            if (this.children[i].isGeometryPrimitiveEntity()) {
                result.push(this.children[i]);
            } else if (this.children[i].isGeometryGroupEntity()) {
                result = result.concat((this.children[i] as GeometryGroup).geometryPrimitiveRec);
            }
        }
        return result;
    }

    get geometryPrimitives(): Array<GeometryPrimitive> {
        let result = [];
        for (let i = 0; i < this.children.length; i++) {
            if (this.children[i].isGeometryPrimitiveEntity()) {
                result.push(this.children[i]);
            }
        }
        return result;
    }

    get geometryGroups(): Array<GeometryGroup> {
        let result = [];
        for (let i = 0; i < this.children.length; i++) {
            if (this.children[i].isGeometryGroupEntity()) {
                result.push(this.children[i]);
                result = result.concat((this.children[i] as GeometryGroup).geometryGroups);
            }
        }
        return result;
    }

    get iGeometries(): Array<(GeometryPrimitive | GeometryGroup)> {
        let result = [];
        for (let i = 0; i < this.children.length; i++) {
            if (this.children[i].isGeometryPrimitiveEntity() || this.children[i].isGeometryGroupEntity()) {
                result.push(this.children[i]);
            }
        }
        return result;
    }

    get length(): number {
        let geometries = this.iGeometries;

        if (geometries.length === 0) {
            return 1;
        } else if (this._length !== null) {
            return this._length;
        } else {
            let toLocal = this.transform.invertedGlobalMatrix;
            let minX = Number.POSITIVE_INFINITY,
                maxX = Number.NEGATIVE_INFINITY;

            for (let i = 0; i < geometries.length; i++) {
                let boundingBox = geometries[i].boundingBox;

                // convert global bbox to local
                for (let j = 0; j < boundingBox.length; ++j) {
                    math.vec3.transformMat4(boundingBox[j], boundingBox[j], toLocal);
                }

                if (boundingBox !== null) {
                    for (let j = 0; j < boundingBox.length; j++) {
                        minX = Math.min(minX, boundingBox[j][0]);
                        maxX = Math.max(maxX, boundingBox[j][0]);
                    }
                } else {
                    let position = geometries[i].position;
                    minX = Math.min(minX, position[0]);
                    maxX = Math.max(maxX, position[0]);
                }
            }

            this._length = maxX - minX;
            return this._length;
        }
    }

    get width(): number {
        let geometries = this.iGeometries;

        if (geometries.length === 0) {
            return 1;
        } else if (this._width !== null) {
            return this._width;
        } else {
            let toLocal = this.transform.invertedGlobalMatrix;
            let minY = Number.POSITIVE_INFINITY,
                maxY = Number.NEGATIVE_INFINITY;

            for (let i = 0; i < geometries.length; i++) {
                let boundingBox = geometries[i].boundingBox;

                // convert global bbox to local
                for (let j = 0; j < boundingBox.length; ++j) {
                    math.vec3.transformMat4(boundingBox[j], boundingBox[j], toLocal);
                }

                if (boundingBox !== null) {
                    for (let j = 0; j < boundingBox.length; j++) {
                        minY = Math.min(minY, boundingBox[j][1]);
                        maxY = Math.max(maxY, boundingBox[j][1]);
                    }
                } else {
                    let position = geometries[i].position;
                    minY = Math.min(minY, position[0]);
                    maxY = Math.max(maxY, position[0]);
                }
            }
            this._width = maxY - minY;
            return this._width;
        }
    }

    get height(): number {
        let geometries = this.iGeometries;

        if (geometries.length === 0) {
            return 1;
        } else if (this._height !== null) {
            return this._height;
        } else {
            let minZ = geometries[0].position[2] - geometries[0].height / 2;
            let maxZ = geometries[0].position[2] - geometries[0].height / 2;
            for (let i = 0; i < geometries.length; i++) {
                minZ = Math.min(minZ, geometries[i].position[2] - geometries[i].height / 2);
                maxZ = Math.max(maxZ, geometries[i].position[2] + geometries[i].height / 2);
            }
            this._height = maxZ - minZ;
            return this._height;
        }
    }

    get realHeight(): number {
        return 0;
    }

    get center(): math.vec3 {
        let geometries = this.iGeometries;
        let geometryLength = geometries.length;

        if (geometryLength === 0) {
            return this.position;
        } else {
            let minX = Number.POSITIVE_INFINITY;
            let maxX = Number.NEGATIVE_INFINITY;
            let minY = Number.POSITIVE_INFINITY;
            let maxY = Number.NEGATIVE_INFINITY;
            let minZ = Number.POSITIVE_INFINITY;
            let maxZ = Number.NEGATIVE_INFINITY;

            let boundingBox, position;
            for (let i = 0; i < geometryLength; i++) {
                boundingBox = geometries[i].boundingBox;
                if (boundingBox !== null) {
                    for (let j = 0; j < boundingBox.length; j++) {
                        minX = Math.min(minX, boundingBox[j][0]);
                        maxX = Math.max(maxX, boundingBox[j][0]);
                        minY = Math.min(minY, boundingBox[j][1]);
                        maxY = Math.max(maxY, boundingBox[j][1]);
                    }
                } else {
                    position = geometries[i].position;
                    minX = Math.min(minX, position[0]);
                    maxX = Math.max(maxX, position[0]);
                    minY = Math.min(minY, position[1]);
                    maxY = Math.max(maxY, position[1]);
                }
                minZ = Math.min(minZ, geometries[i].position[2] - geometries[i].height / 2);
                maxZ = Math.max(maxZ, geometries[i].position[2] + geometries[i].height / 2);
            }

            let center = math.vec3.create();
            math.vec3.set(center, maxX - (maxX - minX) / 2, maxY - (maxY - minY) / 2, maxZ - (maxZ - minZ) / 2);
            return center;
        }
    }

    recenter() {
        let matrices = [];
        let geometries = this.iGeometries;
        for (let i = 0; i < geometries.length; i++) {
            if (geometries[i].isArrangementGroupEntity()) geometries[i].recenter();
            matrices.push(math.mat4.clone(geometries[i].transform.globalMatrix));
        }

        this.position = this.center;
        let toLocal = math.mat4.create();
        math.mat4.invert(toLocal, this.transform.globalMatrix);

        for (let i = 0; i < geometries.length; i++) {
            let matrix = math.mat4.create();
            math.mat4.multiply(matrix, toLocal, matrices[i]);
            math.mat4.copy(geometries[i].transform.localMatrix, matrix);
        }

        this._height = null;
        this._width = null;
        this._length = null;
    }

    get boundingBox(): Array<math.vec3> {
        return this.getHooverBox(0);
    }

    get rawBoundingBox(): Array<math.vec3> {
        let points = [];
        let point1 = math.vec3.create();
        let point2 = math.vec3.create();
        let point3 = math.vec3.create();
        let point4 = math.vec3.create();
        let point5 = math.vec3.create();
        let point6 = math.vec3.create();
        let point7 = math.vec3.create();
        let point8 = math.vec3.create();

        math.vec3.set(point1, this.length / 2, -this.width / 2, -this.height / 2);
        math.vec3.set(point2, this.length / 2, this.width / 2, -this.height / 2);
        math.vec3.set(point3, -this.length / 2, this.width / 2, -this.height / 2);
        math.vec3.set(point4, -this.length / 2, -this.width / 2, -this.height / 2);

        points.push(point1);
        points.push(point2);
        points.push(point3);
        points.push(point4);

        math.vec3.set(point5, point1[0], point1[1], point1[2] + this.height);
        math.vec3.set(point6, point2[0], point2[1], point2[2] + this.height);
        math.vec3.set(point7, point3[0], point3[1], point3[2] + this.height);
        math.vec3.set(point8, point4[0], point4[1], point4[2] + this.height);

        points.push(point5);
        points.push(point6);
        points.push(point7);
        points.push(point8);
        return points;
    }

    getHooverBox(precision: number): Array<math.vec3> {
        let points = [];
        points.push(math.vec3.fromValues(this.length / 2 + precision, -this.width / 2 - precision, 0));
        points.push(math.vec3.fromValues(this.length / 2 + precision, this.width / 2 + precision, 0));
        points.push(math.vec3.fromValues(-this.length / 2 - precision, this.width / 2 + precision, 0));
        points.push(math.vec3.fromValues(-this.length / 2 - precision, -this.width / 2 - precision, 0));

        var q = math.quat.create();
        math.quat.setAxisAngle(q, math.vec3.fromValues(0, 0, 1), this.angle);
        var m = math.mat4.create();
        math.mat4.fromRotationTranslation(m, q, this.position);
        for (var i = 0; i < points.length; ++i) {
            math.vec3.transformMat4(points[i], points[i], m);
            points[i][2] = 0;
        }

        return points;
    }

    contains(p: math.vec3, precision: number, currentEntity: Entity): Entity {
        precision = precision ? precision : SavaneConstants.PositionTolerance;
        let bbox = this.getHooverBox(precision);
        if (bbox !== null && SavaneMath.isInPoly(p, bbox)) {
            for (let i = 0; i < this.children.length; i++) {
                if ((this.children[i] as (GeometryPrimitive | GeometryGroup)).contains(p, precision, currentEntity)) {
                    if (currentEntity && currentEntity !== null && currentEntity.parent.id === this.id) {
                        return this.children[i]; //If current entity is already selected allow to return subentity
                    } else {
                        return this;
                    }
                }
            }
        }
        if (bbox === null) {
            console.error("Empty group : " + this.id);
        }
        return null;
    }

    get maxHeight(): number {
        let maxVal = 0;
        for (let i = 0; i < this.children.length; i++) {
            if (this.children[i].maxHeight > maxVal) {
                maxVal = this.children[i].maxHeight;
            }
        }
        return maxVal;
    }

    get minHeight(): number {
        if (this.children.length === 0) {
            return 0;
        }

        let minVal = (this.children[0] as (GeometryPrimitive | GeometryGroup)).minHeight;
        for (let i = 1; i < this.children.length; i++) {
            let minHeight = (this.children[i] as (GeometryPrimitive | GeometryGroup)).minHeight;

            if (minHeight < minVal) {
                minVal = minHeight;
            }
        }
        return minVal;
    }

    get localMinHeight(): number {
        if (this.children.length === 0) {
            return 0;
        }

        let minVal = (this.children[0] as (GeometryPrimitive | GeometryGroup)).localMinHeight;
        for (let i = 1; i < this.children.length; i++) {
            let minHeight = (this.children[i] as (GeometryPrimitive | GeometryGroup)).localMinHeight;

            if (minHeight < minVal) {
                minVal = minHeight;
            }
        }
        return minVal;
    }

    crossWall(wall: Wall) {
        let geometries = this.iGeometries;
        for (let i = 0; i < geometries.length; i++) {
            if (geometries[i].crossWall(wall)) {
                return true;
            }
        }
        return false;
    }

    set floorHeight(fh: number) {
        var newPos = math.vec3.create();
        math.vec3.set(newPos, this.localPosition[0], this.localPosition[1], fh + this.height * 0.5);
        this.localPosition = newPos;
    }

    get floorHeight(): number {
        return this.localPosition[2] - this.height * 0.5;
    }

    startTemporary() {
        this.temporary = new TemporaryGeometryGroup(this);
        this._length = null;
        this._width = null;
        this._height = null;
        this.recomputeRelated();
    }

    endTemporary() {
        this.temporary = null;
        this._length = null;
        this._width = null;
        this._height = null;
        this.recomputeRelated();
    }

    saveAndEndTemporary() {
        let positionTemp = this.position;
        let angleTemp = this.angle;
        this.temporary = null;
        this.position = positionTemp;

        this.transform.globalZRotation = angleTemp;

        this._length = null;
        this._width = null;
        this._height = null;
        this.recomputeRelated();
    }
}
