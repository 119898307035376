import { Command, CommandEnum, DeleteStaircaseCommand } from './CommandModule';
import { Events } from '../events';
import { eventsManager } from '../managers/EventsManager';
import { Staircase } from '../SavaneJS';

export class DeleteStaircasesCommand extends Command
{
    // Command list to execute and undo
    private _subCommands: Array<Command> = [];
    // Array of staircases to delete
    private _deletedStaircasesArray: Array<Staircase>;

    constructor(staircasesArray: Array<Staircase>) {
        super();
        // Record the staircase entities Array
        this._deletedStaircasesArray = staircasesArray;
        // Parse all staircase array passed to the constructor
        for (let i = 0; i < staircasesArray.length; i++) {
            // And create a staircase command for each of them
            this._subCommands.push(new DeleteStaircaseCommand(staircasesArray[i]));
        }
    }

    // Command name returned from a global enum
    name(): string {
        return CommandEnum.DeleteStaircasesCommand;
    }

    // Undo the current command
    undo() {
        // Parse all commands created by the constructor and undo them all
        for (let i = 0; i < this._subCommands.length; i++) {
            this._subCommands[i].undo();
        }

        //Execute parent function
        super.undo();

        // Select deleted objects at the end
        eventsManager.instance.dispatch(Events.CHANGE_EDITOR_SELECTION,
            {
                selection: this._deletedStaircasesArray,
                keepSelected: false,
                showTulip: false
            });
    }

    // Execute current command (redo)
    execute() {
        // Parse all commands created by the constructor and execute them all
        for (let i = 0; i < this._subCommands.length; i++) {
            this._subCommands[i].execute();
        }

        //Execute parent function
        super.execute();
    }
};
