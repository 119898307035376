import { SavaneCookie } from '../../utils/SavaneCookie';
import { JoineryType, SceneConstants, math } from '../../SavaneJS';
import { Joinery } from './Joinery';

/**
 * Joinery  of type : Opening
 */
export class Opening extends Joinery {
    constructor(joineryType: SceneConstants.JoineryType, position: math.vec3) {
        super(joineryType, position);

        this.addComponent(new JoineryType(SavaneCookie.getCookie("Rhinov-Savane-DefaultJoineryModelOpening", SceneConstants.DefaultJoineryType.opening), undefined, undefined));
    }
}
