
import { SavaneCookie } from '../../utils/SavaneCookie';
import { JoineryType, SceneConstants, math, SavaneMath } from '../../SavaneJS';
import { Joinery } from './Joinery';

/**
 * Joinery  of type : velux
 */
export class Velux extends Joinery
{
    constructor(joineryType: SceneConstants.JoineryType, position: math.vec3) {
        super(joineryType, position);

        //wallInstallType = -1 = outside for velux
        this._wallInstallType = SceneConstants.WallInstallType.install_outside;

        this.addComponent(new JoineryType(SavaneCookie.getCookie("Rhinov-Savane-DefaultJoineryModelVelux", SceneConstants.DefaultJoineryType.velux), undefined, undefined));
    }

    updateFloorHeight() {
        let slope = this.wall.getSlope(this);
        if (slope === 0) {
            let position = math.vec3.create();
            math.vec3.set(position, this.position[0], this.position[1], 0);
            this.position = position;
            return;
        }
        let slopeStartHeight;
        let slopeLength;
        let distToWall = SavaneMath.distanceToLine(this.position, this.wall.begin, this.wall.end);
        distToWall -= this.wall.thickness / 2;

        slopeStartHeight = this.wall.slopeHeight;
        if (slope === 1) {
            slopeLength = this.wall.slopeLength1
        }
        else {
            slopeLength = this.wall.slopeLength2;
        }

        let height = slopeStartHeight + (this.wall.height - slopeStartHeight) / (slopeLength / distToWall);
        let position = math.vec3.create();
        math.vec3.set(position, this.position[0], this.position[1], height);
        this.position = position;
    }

    updateFromFloorHeight() {
        let slope = this.wall.getSlope(this);
        let slopeStartHeight;
        let slopeLength;

        slopeStartHeight = this.wall.slopeHeight;
        if (slope === 1) {
            slopeLength = this.wall.slopeLength1
        }
        else {
            slopeLength = this.wall.slopeLength2;
        }

        let dist = (this.position[2] - slopeStartHeight) * slopeLength / (this.wall.height - slopeStartHeight);
        this.transform.localMatrix[13] = -dist;
    }

    getVerticalHeight() {
        let slope   = this.wall.getSlope(this);
        let height  = this.wall.height - this.wall.slopeHeight;
        let length;

        if (slope === 1) {
            length = this.wall.slopeLength1;
        }
        else {
            length = this.wall.slopeLength2;
        }

        if (length !== 0) {
            let slopeAngle = Math.atan(height / length);

            return(this.height * Math.sin(slopeAngle));
        }

        return(this.height);
    }

    getHorizontaleHeight() {
        let slope   = this.wall.getSlope(this);
        let height  = this.wall.height - this.wall.slopeHeight;
        let length;

        if (slope === 1) {
            length = this.wall.slopeLength1;
        }
        else {
            length = this.wall.slopeLength2;
        }

        if (length !== 0) {
            let slopeAngle = Math.atan(height / length);

            return(this.height * Math.cos(slopeAngle));
        }

        return(this.height);
    }

    getSlopeAngle() {
        let slope   = this.wall.getSlope(this);
        let height  = this.wall.height - this.wall.slopeHeight;
        let length;

        if (slope === 1) {
            length = this.wall.slopeLength1;
        }
        else {
            length = this.wall.slopeLength2;
        }

        if (length !== 0) {
            return Math.atan(height / length);
        }

        return(0);
    }

    // Called when a property is changed to verify max and min boundaries or to perform computations before final assignment
    addToCappedProperty(property: string, value: any) : number {
        // -1 = unknwon property, 0 = no cap, 1 = minus cap, 2 max cap will be returned at the end
        let capped = 0;

        if (value === 0) {
            return(capped);
        }

        return super.addToCappedProperty(property, value);
    }
}
