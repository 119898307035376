import {SceneConstants} from '../../scene/SceneConstants';

export class Converters {

    static JoineryType(value: SceneConstants.JoineryType | SceneConstants.DefaultJoineryType) : SceneConstants.JoineryType | SceneConstants.DefaultJoineryType {
        switch(value) {
            case SceneConstants.DefaultJoineryType.door:
                return SceneConstants.JoineryType.door;
            case SceneConstants.DefaultJoineryType.frontDoor:
                return SceneConstants.JoineryType.frontDoor;
            case SceneConstants.DefaultJoineryType.window:
                return SceneConstants.JoineryType.window;
            case SceneConstants.DefaultJoineryType.frenchWindow:
                return SceneConstants.JoineryType.frenchWindow;
            case SceneConstants.DefaultJoineryType.pictureWindow:
                return SceneConstants.JoineryType.pictureWindow;
            case SceneConstants.DefaultJoineryType.canopy:
                return SceneConstants.JoineryType.canopy;
            case SceneConstants.DefaultJoineryType.cupboardDoor:
                return SceneConstants.JoineryType.cupboardDoor;
            case SceneConstants.DefaultJoineryType.garageDoor:
                return SceneConstants.JoineryType.garageDoor;
            case SceneConstants.DefaultJoineryType.fixedWindow:
                return SceneConstants.JoineryType.fixedWindow;
            case SceneConstants.DefaultJoineryType.velux:
                return SceneConstants.JoineryType.velux;
            default:
                return value
        }
    }

    static DefaultType(value: number) : SceneConstants.DefaultJoineryType | SceneConstants.JoineryType | SceneConstants.DefaultTechnicalElementType {
        switch(value) {
            case 0:
                return SceneConstants.DefaultJoineryType.door;
            case 1:
                return SceneConstants.DefaultJoineryType.door;
            case 2:
                return SceneConstants.DefaultJoineryType.frontDoor;
            case 3:
                return SceneConstants.DefaultJoineryType.frontDoor;
            case 4:
                return SceneConstants.DefaultJoineryType.window;
            case 5:
                return SceneConstants.DefaultJoineryType.window;
            case 6:
                return SceneConstants.DefaultJoineryType.frenchWindow;
            case 7:
                return SceneConstants.DefaultJoineryType.frenchWindow;
            case 8:
                return SceneConstants.DefaultJoineryType.pictureWindow;
            case 9:
                return SceneConstants.DefaultJoineryType.canopy;
            case 10:
                return SceneConstants.JoineryType.opening;
            case 11:
                return SceneConstants.DefaultJoineryType.cupboardDoor;
            case 12:
                return SceneConstants.DefaultJoineryType.garageDoor;
            case 13:
                return SceneConstants.JoineryType.niche;
            case 14:
                return SceneConstants.DefaultTechnicalElementType.radiator;
            case 15:
                return SceneConstants.DefaultTechnicalElementType.stove;
            case 16:
                return SceneConstants.DefaultTechnicalElementType.airConditioner;
            case 17:
                return SceneConstants.DefaultJoineryType.fixedWindow;
            case 18:
                return SceneConstants.DefaultTechnicalElementType.fireplace;
            case 19:
                return SceneConstants.DefaultJoineryType.velux;
            case 20:
                return SceneConstants.DefaultTechnicalElementType.beam;
            case 21:
                return SceneConstants.DefaultTechnicalElementType.boiler;
            case 22:
                return SceneConstants.DefaultTechnicalElementType.rosette;
            case 23:
                return SceneConstants.DefaultTechnicalElementType.spotLight;
            case 24:
                return SceneConstants.DefaultTechnicalElementType.circularPole;
            case 25:
                return SceneConstants.DefaultTechnicalElementType.rectangularPole;
            case 26:
                return SceneConstants.DefaultTechnicalElementType.switchBoard;
            case 27:
                return SceneConstants.DefaultTechnicalElementType.wallDecoration;
        }
    }

}
