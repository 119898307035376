import { Joinery } from '../model/Joinery';
import { SlopeJoinery } from '../model/SlopeJoinery';
import { glMatrix } from "../../../math/Math";
import { SceneConstants } from '../../../scene/SceneConstants';
import { Ray } from '../../../math/Math';
import { Photo2World } from '../Photo2World';
import { Partition } from '../model/Partition';
import { Slope } from '../model/Slope';

export class PartitionJoineriesFactory {

    private _pixels: Array<glMatrix.vec2> = new Array<glMatrix.vec2>();
    private _joineryDatas: Array<any> = new Array<any>();
    private _photo2world: Photo2World;

    constructor(photo2world: Photo2World) {
        this._photo2world = photo2world;
    }

    _handleWallJoinery(wall1 : { wall: Partition, intersection: glMatrix.vec3}, wall2: { wall: Partition, intersection: glMatrix.vec3}, orientation, joineryIndex: number) : void {
        if (wall1.wall.Type !== 'PARTITION' || wall2.wall.Type !== 'PARTITION') {
            return;
        }

        wall1.intersection[2] = Math.max(0, wall1.intersection[2]); //cannot be under the ground
        wall2.intersection[2] = Math.min(wall2.wall.Height, wall2.intersection[2]); //cannot be over the ceiling

        wall1.intersection = wall1.wall.ClampToBorder(wall1.intersection);
        wall2.intersection = wall2.wall.ClampToBorder(wall2.intersection);

        let data = this._joineryDatas[joineryIndex];
        var A = glMatrix.vec3.clone(wall1.intersection);
        A[2] = 0;
        var B = glMatrix.vec3.clone(wall2.intersection);
        B[2] = 0;
        if (data.invert) {
            this._photo2world.Model.PartitionJoineries.push(new Joinery(B, A, Math.abs(wall2.intersection[2] - wall1.intersection[2]), wall1.intersection[2], wall1.wall, data, orientation));
        } else {
            this._photo2world.Model.PartitionJoineries.push(new Joinery(A, B, Math.abs(wall2.intersection[2] - wall1.intersection[2]), wall1.intersection[2], wall1.wall, data, orientation));
        }
    }

    _handleSlopeJoinery(wall1: { wall: Slope, intersection: glMatrix.vec3}, wall2: { wall: Slope, intersection: glMatrix.vec3}, orientation, joineryIndex: number) : void {
        if (wall1.wall.Type !== 'SLOPE' || wall2.wall.Type !== 'SLOPE') {
            return;
        }

        var slope = wall1.wall;
        var localSlopeA = slope.ToLocal(slope.A);
        var localA = slope.ToLocal(wall1.intersection);
        localA[2] = Math.max(localSlopeA[2], localA[2]); //cannot be under the slope

        var localSlopeD = slope.ToLocal(slope.D);
        var localD = slope.ToLocal(wall2.intersection);
        localD[2] = Math.min(localSlopeD[2], localD[2]); //cannot be over the slope
        var height = localD[2] - localA[2];

        var localC = glMatrix.vec3.clone(localA);
        localC[2] += height;
        var localB = glMatrix.vec3.clone(localD);
        localB[2] -= height;

        let data = this._joineryDatas[joineryIndex];
        this._photo2world.Model.PartitionJoineries.push(new SlopeJoinery(
            slope.ToGlobal(localA), slope.ToGlobal(localB), slope.ToGlobal(localC), slope.ToGlobal(localD), slope, data, orientation));
    }

    set Pixels(value: Array<glMatrix.vec2>) {
        this._pixels = value;
    }

    set JoineryDatas(value: Array<any>) {
        this._joineryDatas = value;
    }

    Build() : void {
        this._photo2world.Model.PartitionJoineries = [];

        if (this._photo2world.Model.Partitions.length === 0) {
            return;
        }

        if (this._joineryDatas.length == 0) {
            return;
        }

        if (this._pixels.length < 2) {
            return;
        }

        var joineryIndex = 0;
        //find the closest partition
        for (var i = 0; i < this._pixels.length; i += 2) {
            let p1 = this._pixels[i];
            let p2 = this._pixels[i + 1];

            var orientation =  SceneConstants.HandleSide.handle_left;
            if (p2[0] > p1[0]) {
                orientation = SceneConstants.HandleSide.handle_right;
            }

            if (p1[1] < p2[1]) { //p1 must always be the lowest point to simplify next computations
                let temp = p1;
                p1 = p2;
                p2 = temp;
            }

            let data = this._joineryDatas[joineryIndex];
            if (data.wallIndex >= this._photo2world.Model.Partitions.length) {
                joineryIndex++;
                continue;
            }

            var partition: Partition | Slope = this._photo2world.Model.Partitions[data.wallIndex];
            for (var j = 0; j < this._photo2world.Model.SlopesPartition.length; ++j) {
                var slope = this._photo2world.Model.SlopesPartition[j];
                if (slope.Wall === partition) {
                    if (slope.Intersect(new Ray(this._photo2world.CameraPosition, this._photo2world.Unproject(p1)), true, false)) {
                        partition = slope;
                    }
                }
            }

            var partitionOutsideImage = this._photo2world.OutsideNDC(this._photo2world.Project(partition.A)) || this._photo2world.OutsideNDC(this._photo2world.Project(partition.B))
            var i1 = null;
            if (partitionOutsideImage || partition instanceof Partition) { //partition is outside the image - compute intersection with plane directly
                i1 = partition.Plane.Intersect(new Ray(this._photo2world.CameraPosition, this._photo2world.Unproject(p1)), false, partition.Thickness < 0)
            } else {
                i1 = partition.Intersect(new Ray(this._photo2world.CameraPosition, this._photo2world.Unproject(p1)), true, false);
            }

            var i2 = null;
            if (partitionOutsideImage || partition instanceof Partition) { //partition is outside the image - compute intersection with plane directly
                i2 = partition.Plane.Intersect(new Ray(this._photo2world.CameraPosition, this._photo2world.Unproject(p2)), false, partition.Thickness < 0)
            } else {
                i2 = partition.Intersect(new Ray(this._photo2world.CameraPosition, this._photo2world.Unproject(p2)), true, false);
            }
            if (!i1 || !i2) {
                joineryIndex++;
                continue;
            }

            var wall1 = {
                wall: partition,
                intersection: i1
            }

            var wall2 = {
                wall: partition,
                intersection: i2
            }

            if (wall1.wall instanceof Partition && wall2.wall instanceof Partition) {
                var partition1 = {
                    wall: wall1.wall as Partition,
                    intersection: wall1.intersection
                }

                var partition2 = {
                    wall: wall2.wall as Partition,
                    intersection: wall2.intersection
                }
                this._handleWallJoinery(partition1, partition2, orientation, joineryIndex);
            }

            if (wall1.wall instanceof Slope && wall2.wall instanceof Slope) {
                var slope1 = {
                    wall: wall1.wall as Slope,
                    intersection: wall1.intersection
                }

                var slope2 = {
                    wall: wall2.wall as Slope,
                    intersection: wall2.intersection
                }
                this._handleSlopeJoinery(slope1, slope2, orientation, joineryIndex);
            }

            joineryIndex++;
        }
    }

}