import { Command, CommandEnum, AddComponentCommand, DeleteComponentCommand } from './CommandModule';
import { Component, Entity } from '../SavaneJS';

export class ReplaceComponentCommand  extends Command
{
    // Entity owning the component
    private _entity: Entity;
    // Index of the component to remove
    private _index: number;
    // Command list to execute and undo
    private _subCommands: Array<Command> = [];

    constructor(oldComponent: Component, newComponent: Component, entity: Entity) {
        super();
        // Store entity and component index
        this._entity = entity;
        this._index = entity.components.indexOf(oldComponent);
        // Command list to execute and undo
        this._subCommands = [new DeleteComponentCommand(oldComponent, false), new AddComponentCommand(newComponent, entity, false, true)];
    }

    // Command name returned from a global enum
    name(): string {
        return CommandEnum.ReplaceComponentCommand;
    }

    needsRefreshHull(): boolean {
        for (let i = 0; i < this._subCommands.length; i++) {
            if (this._subCommands[i].needsRefreshHull()) {
                return (true);
            }
        }

        return (false);
    }

    // Undo the current command
    undo() {
        // Parse all commands created by the constructor and undo them all
        for (let i = 0; i < this._subCommands.length; i++) {
            this._subCommands[i].undo();
        }

        let newComponent = this._entity.components.splice(this._entity.components.length - 1, 1);
        this._entity.components.splice(this._index, 0, newComponent[0]);

        // Execute parent function
        super.undo();
    }

    // Execute current command (redo)
    execute() {
        // Parse all commands created by the constructor and execute them all
        for (let i = 0; i < this._subCommands.length; i++) {
            this._subCommands[i].execute();
        }

        // move to old component position
        let newComponent = this._entity.components.splice(this._entity.components.length - 1, 1);
        this._entity.components.splice(this._index, 0, newComponent[0]);

        //Execute parent function
        super.execute();
    }
};
