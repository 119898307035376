export namespace Events {
    export const UNREGISTER = 'unregister_event';
    export const FINISHES_DONE = 'finishes_done_event';

    export const OPEN_FRONT_VIEW = 'OPEN_FRONT_VIEW';
    export const CLOSE_FRONT_VIEW = 'CLOSE_FRONT_VIEW';
    export const FLIP_COCOSFRONT_VIEW = 'FLIP_COCOSFRONT_VIEW';
    export const FRONT_VIEW_MOUSE_MOVED = 'FRONT_VIEW_MOUSE_MOVED';

    export const SET_WORK_TOP = 'set_work_top';
    export const TOGGLE_TECHNICAL_WORKTOP = 'toggle_technical_worktop';
    export const SET_WORK_TOP_EDGE = 'set_work_top_edge';
    export const TOOGLE_WORK_TOP_LEG = 'toggle_work_top_leg';
    export const TOOGLE_WORK_TOP_CREDENCE = 'toggle_work_top_credence';
    export const UPDATE_WORK_TOP_COATING = 'update_work_top_coating';

    export const SELECT_AREA_LINE = 'select_area_line';
    export const SELECT_AREA_POINT = 'select_area_point';

    export const RESIZE_PLAN_FRAME = 'resize_plan_frame';
    export const USER_NOTIFICATION = 'user_notification';

    export const RESIZE_WINDOW = 'resize_window';

    export const SHOW_CONTEXTUAL_MENU = 'show_contextual_menu';
    export const SHOW_CONTEXTUAL_MENU_COATING_AREA = "show_contextual_menu_coating_area";

    export const SAVE_SCENE = 'save_scene';
    export const CLOSE_MODAL = 'close_modal';

    export const CANCEL_CLICK = 'click_cancelled';
    export const TOOL_UNSELLECT = 'tool_unsellect';
    export const ZOOM_IN_EVENT = 'zoom_in_scrollwindow';
    export const ZOOM_OUT_EVENT = 'zoom_out_scrollwindow';
    export const UNDO_EVENT = 'undo_plan';
    export const REDO_EVENT = 'redo_plan';
    export const IMPORT_EVENT = 'import_event';

    export const UPDATE_RHINOV_FORMAT = 'rhinovFormat_updated';
    export const PLAN_STATE_CHANGED = 'plan_state_changed';

    export const PLAN_FLOOR_CHANGED = 'plan_floor_changed';
    export const DELETE_EVENT = 'delete_wall_event';

    export const ZOOM_SCROLLWIN_CHANGED = 'zoom_scrollwin_changer';
    export const POS_WIN_CHANGED = 'pos_scrollwin_changer';
    export const HIDE_CHIPS = 'hide_chips_event';
    export const TOGGLE_SKETCH_MODE = 'toggle_sketch_mode_event';

    export const PLAN_UPLOADED = 'plan_uploaded_event';
    export const PLAN_TUTO_START = 'plan_tuto_start';

    export const DISPLAY_ALERT = 'display_alert';
    export const DISPLAY_TOASTER = 'display_toaster';

    export const UNSELECT_CAMERA_OBJECT = 'unselect_camera_object';
    export const EDIT_CAMERA_COMMENT = 'edit_camera_object';
    export const NEW_CAMERA_OBJECT = 'new_camera_object';
    export const REMOVE_CAMERA_OBJECT = 'remove_camera_object';

    export const SUN_DRAG_ON = 'sun_drag_on';
    export const SUN_DRAG_OFF = 'sun_drag_off';

    export const ACTIONS_COUNT_CHANGE = 'action_count_change';
    export const TOGGLE_WALL_DOTS = 'toggle_wall_dots';
    export const WALL_SET_TRANSPARENCY = 'wall_set_transparency';
    export const HIDE_AXO = 'hide_axo';
    export const HIDE_ARRANGEMENTS = 'hide_arrangements';
    export const SHOW_DETAILED_HEIGHT = 'show_detailed_height';
    export const DESIGNER_FILTERS_NEXT_OBJECTS = 'designer_filters_next_objects';

    export const TOGGLE_HIGHLIGHT_MODE = 'toggle_highligth_mode';
    //UI
    export const UI_GLOBAL_NEXT_PRESSED = 'ui_global_next_pressed';

    export const DO_APPLYJOINERY_COLOR = 'do_applyjoinery_color';

    export const DO_PROJECT_ROTATION = 'do_project_rotation';
    export const DO_PROJECT_TRANSLATION = 'do_project_translation';
    export const DO_PROJECT_SYMETRY = 'do_project_symetry';
    export const DO_PROJECT_SCALE = 'do_project_scale';
    export const DO_SET_FLOOR_HEIGHT = 'do_set_floor_height';
    export const DO_SET_PROJECT_FLOOR_HEIGHT = 'do_set_project_floor_height';
    export const DO_SET_ROOM_FLOOR_HEIGHT = 'do_set_room_floor_height';
    export const DO_SET_FLOOR_CORNICES = 'do_set_floor_cornices';
    export const DO_SET_PROJECT_CORNICES = 'do_set_project_cornices';

    export const HIDE_GRID_PRESSED = 'hide_grid_pressed';
    export const HIDE_BACKGROUND_PRESSED = 'hide_background_pressed';
    export const HIDE_RULERS_PRESSED = 'hide_rulers_pressed';

    export const SHOW_WALL_PROPERTIES = 'show_wall_properties';
    export const EDIT_TOOL_THICKNESS = 'edit_tool_thickness';
    export const HIDE_PROPERTIES = 'hide_wall_properties';
    export const UNSELECT_PRESSED = 'unselect_pressed';
    export const HIDE_DIMENSION_PRESSED = 'hide_dimension_pressed';

    export const PLUS_CLICKED = 'ui_plus_button';
    export const LESS_CLICKED = 'ui_less_button';
    export const TEXTFIELD_EDIT_ENDED = 'ui_textField_edit_ended';
    export const TEXTFIELD_EDIT = 'ui_textField_edit';
    export const SLIDER_BUTTON = 'ui_slider_button';
    export const DROP_DOWN_BUTTON = 'ui_drop_down_button';
    export const IMAGE_CLICKED = 'ui_image_clicked';

    //export const CAPPED_VALUE = 'ui_capped_value';
    export const FUNCTIONALITY_TOGGLE = 'ui_func_toggle';
    export const MAINFUNCTIONALITY_CHANGE = 'ui_room_main_func_change';
    export const UPDATE_MAINFUNCTIONALITY_CHANGE_NODE = 'update_ui_room_main_func_change_node';

    //Secondary functionalitites
    // Ask to add secondary func on a room
    export const ADD_ROOM_SECONDARY_FUNCTIONALITY = 'add_room_secondary_functionality';
    //The secondary funcs of a room has been changed
    export const ROOM_SECONDARY_FUNCTIONALITY_CHANGED = 'room_secondary_functionality_changed';
    //Ask tpo remove secondary func on a room
    export const REMOVE_ROOM_SECONDARY_FUNCTIONALITY = 'remove_room_functionality';

    export const TOGGLE_ROOM_NAME = 'toggle_room_name';
    export const TOGGLE_ROOM_AREA = 'toggle_room_area';
    export const TOGGLE_PLAN = 'toggle_plan';
    export const TOGGLE_USER_PICTURES = 'toggle_user_pictures';

    export const SHOW_UIB_MOVABLE = 'SHOW_UIB_MOVABLE';
    export const SHOW_TULIP = 'showHtmlTulip';
    export const SHOW_TULIP_COCOS = 'showHtmlTulipCocos';
    export const HIDE_TULIP_COCOS = 'hideHtmlTulipCocos';
    export const HIDE_TULIP = 'hideHtmlTulip';
    export const CAMERA_TULIP_SHOW = 'camera_tulip_show';
    export const NOTE_TULIP_SHOW = "note_tulip_show";
    export const CAMERA_ZOOM_PHOTO = 'camera_zoom_photo';
    export const CAMERA_SELECTED = 'camera_selected';
    export const CAMERA_UNSELECTED = 'camera_unselected';
    export const PROCESS_CAMERAS = 'process_cameras';
    export const LIGHT_SELECTED = 'light_selected';
    export const LIGHT_UNSELECTED = 'light_unselected';
    export const ENTITY_HOVER = 'entity_hover_in_out';
    export const SELECT_ROOM_FOR_ORDERS = 'select_room_for_orders';
    export const CHANGE_ROOM_ORDERED_STATE = 'change_room_ordered_state';
    export const HIDE_TOOLTIP_EVENT = 'hide_tooltip_asset';

    export const PROJECT_DID_LOAD = 'project_did_load';
    export const PROJECT_RELOAD = 'project_reload';
    export const PROJECT_REFRESH_HULL = 'project_refresh_hull';

    export const CAMERA_EDITION = 'camera_editon_call';
    export const ENTITY_EDITION = 'entity_editon_call';
    export const ENTITY_ADD = 'entity_add_call';
    export const COMPONENT_EDITION = 'component_editon_call';
    export const CHIP_FUNC_EDITION = 'chip_func_editon_call';

    export const UNGROUP_ENTITIES = 'ungroup_entities';
    export const UNGROUP_ENTITY = 'ungroup_entity';
    export const GROUP_ENTITIES = 'group_entities';
    export const MERGE_ENTITIES = 'merge_entities';
    export const SAVE_GROUP = 'save_group_entities';

    export const COATING_DRAG = 'coating_drag';
    export const COATING_REMOVE = 'coating_remove';
    export const COATING_ROTATION_CHANGED = 'coating_rotation_changed';

    export const JOINERY_COMPONENT_DRAG = 'joinery_component_drag';
    export const TECHNICAL_ELEMENT_COMPONENT_DRAG = 'technical_element_component_drag';

    export const OPEN_DECORATE_MODAL = 'open_decoration_modal';

    export const DIRECTIVE_COCOS_INIT = 'directive_cocos_init';
    export const DIRECTIVE_COCOS_RESIZE = 'directive_cocos_resize';

    export const SMART_DESIGNER_PREV_CONFIG = 'smart_designer_prev_config';
    export const SMART_DESIGNER_NEXT_CONFIG = 'smart_designer_next_config';
    export const SMART_DESIGNER_CLEAR_ROOM = 'smart_designer_clear_room';
    export const ACTIVATE_HIGHLIGHT_ANIMATION = 'activate_highlight_animation';
    export const COCOS_TOOL_CLICKED = 'cocos-tool-clicked';

    export const COCOS_MANUAL_INIT = 'cocos-manual-init';

    //COMPONENTS command
    export const COMPONENT_ADDED = 'component_added_savane';
    export const COMPONENT_REMOVED = 'component_remove_savane';
    export const COMPONENT_EDITED = 'component_edited_savane';
    //ENTITY
    // THIS EVENT IS NOT GUARANTEED, IT CAN BE FIRE TO NOTIFY SOME OF THE PARAMS EDITION
    export const ENTITY_PARAM_EDITED = 'savane_entity_param_edited';
    //COMMAND
    export const COMMAND_EXECUTED = 'savane_command_executed';
    //Edit Dimensions
    export const EDIT_DIMENSION = 'edit_dimension';
    //LOCAL CACHE
    export const LOCAL_CACHE_POPUP_DISPLAY = 'local_cache_popup_display';
    export const LOAD_LOCAL_CACHE = 'load_local_cache';
    export const CLEAR_LOCAL_CACHE = 'clear_local_cache';

    export const UNSELECT_WEBGL_ITEMS = 'unselect_webgl_items';
    export const SELECT_WEBGL_ITEMS = 'select_webgl_items';

    export const SET_FILTERS = 'set_filters';
    export const ENABLE_OBJECT_FILTERS_CHANGED = 'ENABLE_OBJECT_FILTERS_CHANGED';
    export const REPLACE_SELECTED_ARRANGEMENT = 'replace_selected_arrangement';
    export const DESIGNER_FILTER_SELECTION_CHANGED = 'designer_filter_selection_changed';
    export const REPLACE_SELECTED_ARRANGEMENTS_WITH_SHOPPING_LIST = 'replace_selected_arrangements_with_shopping_list';

    /**
     *
     */
    export const FLOOR_DELETE = 'floor_delete';
    export const FLOOR_CHANGE = 'floor_change';
    export const FLOOR_ADD = 'floor_add';
    export const FLOOR_SWAP = 'floor_swap';
    export const BACK_PLAN_IMPORT = 'back_floor_plan';

    export const CAMERA_CHANGED = 'camera_changed';
    export const CAMERA_RESIZED = 'camera_resized';
    export const CAMERA_CLICKED = 'clickOnCamera';

    export const RENDER_ALL = 'launchAllRender';
    export const RENDER_PREVIEW = 'launchPreview';
    export const SHOW_WEBGL_VIEW = 'openWebGl';
    export const DRAG_OVER_WEBGL = 'drag_over_webgl';
    export const WEBGL_ACTIVE_CAMERA = "webgl_active_camera";
    export const WEBGL_FREE_CAMERA = "webgl_free_camera";

    export const PLAN_VISITPHOTO_TRANSFER = "plan-visitphotos-transfer";
    export const CAMERA_HOVER_ENTER = "camera_hover_enter";
    export const CAMERA_HOVER_LEAVE = "camera_hover_leave";
    export const PHOTO2SAVANE_FINISHED = "photo2savane_finished";
    export const REMOVE_NOTE_OBJECT = "remove_note_object";
    export const SUN_ADDED = 'sun_added';
    export const DOME_ADDED = 'dome_added';

    export const UPDATE_ARRANGEMENT_COATING = "update_arrangement_coating";
    export const SNAP_UPDATED = "snap_updated";
    export const ANGLE_SNAP_UPDATED = "angle_snap_updated";
    export const SELECTION_CHANGED = "selection_changed";

    export const STOP_UPDATING_ENVS = "stop_updating_envs";
    export const START_UPDATING_ENVS = "start_updating_envs";
    export const COMPUTE_CAMERA_SHOPPING_LIST = "compute_camera_shopping_list";
    export const FLOOR_GENERATOR_CHANGED = "floor_generator_changed";
    export const CANCEL_FLOOR_GENERATOR_CHANGED = "cancel_floor_generator_changed";
    export const FRONTVIEW_FLOOR_GENERATOR_CHANGED = "frontview_floor_generator_changed";
    export const FULLSCREEN_CHANGED = "fullscreen_changed";
    export const FULLSCREEN_ASKED = "fullscreen_asked";

    // interactive project
    export const SELECTED_ARRANGEMENT = "selected_arrangement";
    export const SELECTED_ARRANGEMENT_REPLACED = "selected_arrangement_replaced";
    export const SELECTED_COATING = "selected_coating";
    export const SELECTED_COATING_REPLACED = "selected_coating_replaced";
    export const SELECTED_KITCHEN_COATING_REPLACED = "selected_kitchen_coating_replaced";
    export const SELECTED_KITCHEN_HANDLE_REPLACED = "selected_kitchen_handle_replaced";
    export const SELECTED_PLINTH_COATING_REPLACED = "selected_plinth_coating_replaced";
    export const CHANGE_KITCHEN_STYLE = "change_kitchen_style";
    export const HIDE_ARRANGEMENT = "hide_arrangement";
    export const MOVE_ARRANGEMENT_LEFT = "move_arrangement_left";
    export const MOVE_ARRANGEMENT_RIGHT = "move_arrangement_right";
    export const MOVE_ARRANGEMENT_FORWARD = "move_arrangement_forward";
    export const MOVE_ARRANGEMENT_BACKWARD = "move_arrangement_backward";
    export const MOVE_ARRANGEMENT_UP = "move_arrangement_up";
    export const MOVE_ARRANGEMENT_DOWN = "move_arrangement_down";
    export const ROTATE_ARRANGEMENT_LEFT = "rotate_arrangement_left";
    export const ROTATE_ARRANGEMENT_RIGHT = "rotate_arrangement_right";
    export const START_EDIT_MODE = "start_edit_mode";
    export const EXIT_EDIT_MODE = "exit_edit_mode";
    export const RHINOV_FORMAT_UPDATED = "rhinov_format_updated";
    export const RHINOV_FORMAT_RESETED = "rhinov_format_reseted";

    export const NEW_PINBOARD = "new_pinboard";
    export const EDIT_PINBOARD = "edit_pinboard";
    export const REFRESH_PINBOARDS = "refresh_pinboards";
    export const ADD_OR_REMOVE_FROM_PINBOARD = "add_or_remove_from_pinboard";

    export const TOGGLE_DISPLAY_GROUPS = "toggle_display_groups";
    export const NEW_ASSETS_GROUPS_LIST = "new_assets_groups_list";
    export const ADD_OR_REMOVE_FROM_ASSETS_GROUPS_LIST = "add_or_remove_from_assets_groups_list";
    export const EDIT_ASSETS_GROUPS_LIST = "edit_assets_groups_list";
    export const REFRESH_ASSETS_GROUPS_LIST = "refresh_assets_groups_list";

    export const AM_ENTITY_STATS = "am_entity_stats";

    export const HULL_LOADING = "hull_loading";
    export const HULL_LOADED = "hull_loaded";

    export const CHANGE_EDITOR_SELECTION = "change_editor_selection";
    export const UNSELECT_EDITOR_SELECTION = "unselect_editor_selection";

    export const HIDE_OTHER_ROOMS = "hide_other_rooms";
    export const SHOW_ALL_ROOMS = "show_all_rooms";
    export const SHOW_THIS_ROOM = "show_this_room";

    /**
     *********WARNING**********
    *  This event should only be dispatch from the current state
    * @type {string}
    */
    export const CHANGE_STATE = 'change_state';
    export const ADD_GRAPHICAL_ENTITY = 'add_graphical_entity';
    export const REMOVE_GRAPHICAL_ENTITY = 'remove_graphical_entity';
    export const REDRAW_GRAPHICAL_ENTITY = 'redraw_graphical_entity';
    export const REDRAW_GRAPHICAL_ROOM = 'redraw_graphical_room';
    export const REDRAW_GRAPHICAL_WALL = 'redraw_graphical_wall';
    export const REDRAW_FRONTVIEW_AREA = 'redraw_frontview_area';
    export const DELETE_FRONTVIEW_AREA = 'delete_frontview_area';
    export const CREATE_USER_MAP = 'create_user_map';
    export const DELETE_USER_MAP = 'delete_user_map';
}
