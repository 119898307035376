import { ComponentConstants, Functionality, FunctionalityChip, Transform} from '../../SavaneJS';
import { TemporaryEntity } from './TemporaryEntity';

/**
 * TemporaryFunctionnalityChip is used for functionnality chips entity edition
 */
export class TemporaryFunctionalityChip extends TemporaryEntity {
    public data: any;
    private _transform: Transform;

    constructor(functionnalityChip: FunctionalityChip) {
        super(functionnalityChip);

        this._transform = new Transform(null);
        this._transform.clone(functionnalityChip.transform);

        let component = functionnalityChip.getComponent(ComponentConstants.ComponentType.Functionality) as Functionality;

        if (component !== null) {
            this.data = Object.assign({}, component._data);
        }
        else {
            this.data = {};
        }
    }

    computeValidity() {
        this._isValid = true;
    }

    get transform(): Transform {
        return this._transform;
    }
}
