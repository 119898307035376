import { EditPointCommand } from './EditPointCommand';

export * from './Command';
export * from './AddComponentsCommand';
export * from './AddComponentCommand';
export * from './AddEntitiesCommand';
export * from './AddEntityCommand';
export * from './AddJoineryCommand';
export * from './AddRoomCommand';
export * from './ApplySmartDesignerSolutionCommand';
export * from './ApplySmartRestylerSolutionCommand';
export * from './ApplyWallPopulationCommand';
export * from './ChangeCoatingCommand';
export * from './ChangeCoatingsCommand';
export * from './ChangeKitchenCoatingCommand';
export * from './CreateWallCommand';
export * from './CreateGroupCommand';
export * from './CutWallCommand';
export * from './DeleteComponentCommand';
export * from './DeleteComponentsCommand';
export * from './DeleteEntitiesCommand';
export * from './DeleteEntityCommand';
export * from './DeleteGroupCommand';
export * from './DeleteJoineriesCommand';
export * from './DeleteRoomsCommand';
export * from './DeleteStaircaseCommand';
export * from './DeleteStaircasesCommand';
export * from './DeleteTechnicalElementCommand';
export * from './DeleteTechnicalElementsCommand';
export * from './DeleteWallCommand';
export * from './EditCommentCommand';
export * from './EditComponentCommand';
export * from './EditEntitiesCommand';
export * from './EditEntityCommand';
export * from './EditFunctionalityChipCommand';
export * from './EditGeometryPrimitiveCommand';
export * from './EditJoineryCommand';
export * from './EditPointCommand';
export * from './EditRenderCamerasCommand';
export * from './EditRenderCameraCommand';
export * from './EditRoomPropertiesCommand';
export * from './EditStaircaseCommand';
export * from './EditSunCommand';
export * from './EditTechnicalElementCommand';
export * from "./EditWallCommand";
export * from './EditWallPropertiesCommand';
export * from './CopyPasteWallAndComponentsCommand'
export * from './EntitiesLeaveGroupCommand';
export * from './LeaveGroupCommand';
export * from './MergeGroupCommand';
export * from './ReplaceComponentCommand';
export * from './ReplaceEntitiesCommand';
export * from './RotateFloorCommand';
export * from './RotateProjectCommand';
export * from './ScaleFloorCommand';
export * from './ScaleProjectCommand';
export * from './SetFloorCornicesCommand';
export * from './SetFloorHeightCommand';
export * from './SetProjectCornicesCommand';
export * from './SetProjectFloorHeightCommand';
export * from './SetRoomFloorHeightCommand';
export * from './SnapEntitiesCommand';
export * from './SymetryFloorCommand';
export * from './SymetryProjectCommand';
export * from './TranslateFloorCommand';
export * from './TranslateProjectCommand';

// List of command names
export const CommandEnum =
{
    AddComponentsCommand: 'addComponentsCommand',
    AddComponentCommand: 'addComponentCommand',
    ReplaceComponentCommand: 'replaceComponentCommand',
    AddEntityCommand: 'addEntityCommand',
    AddEntitiesCommand: 'addEntitiesCommand',
    AddJoineryCommand: 'addJoineryCommand',
    AddRoomCommand: 'addRoomCommand',
    ApplySmartDesignerSolutionCommand: 'applySmartDesignerSolutionCommand',
    ApplySmartRestylerSolutionCommand: 'applySmartRestylerSolutionCommand',
    ApplyWallPopulationCommand: 'applyWallPopulationCommand',
    ApplyAreaPopulation : 'applyAreaPopulation',
    
    ChangeCoatingCommand: 'changeCoatingCommand',
    ChangeCoatingsCommand: 'changeCoatingsCommand',
    ChangeKitchenCoatingCommand: 'changeKitchenCoatingCommand',
    CreateGroupCommand: 'createGroupCommand',
    CreateWallCommand: 'createWallCommand',
    CutWallCommand: 'cutWallCommand',
    DeleteComponentsCommand: 'deleteComponentsCommand',
    DeleteComponentCommand: 'deleteComponentCommand',
    DeleteEntitiesCommand: 'deleteEntitiesCommand',
    ReplaceEntitiesCommand: 'replaceEntitiesCommand',
    DeleteEntityCommand: 'deleteEntityCommand',
    DeleteGroupCommand: 'deleteGroupCommand',
    DeleteJoineriesCommand: 'deleteJoineriesCommand',
    DeleteRoomsCommand: 'deleteRoomsCommand',
    DeleteStaircaseCommand: 'deleteStaircaseCommand',
    DeleteStaircasesCommand: 'deleteStaircasesCommand',
    DeleteTechnicalElementCommand: 'deleteTechnicalElementCommand',
    DeleteTechnicalElementsCommand: 'deleteTechnicalElementsCommand',
    DeleteWallCommand: 'deleteWallCommand',
    EditComponentCommand: 'editComponentCommand',
    EditEntityCommand: 'editEntityCommand',
    EditEntitiesCommand: 'editEntitiesCommand',
    EditGeometryPrimitiveCommand: 'editGeometryPrimitiveCommand',
    EditJoineryCommand: 'editJoineryCommand',
    EditCommentCommand: 'editCommentCommand',
    EditFunctionalityChipCommand: 'EditFunctionalityChipCommand',
    EditPointCommand: 'editPointCommand',
    EditRenderCamerasCommand: 'editRenderCamerasCommand',
    EditRenderCameraCommand: 'editRenderCameraCommand',
    EditRoomPropertiesCommand: 'editRoomPropertiesCommand',
    EditStaircaseCommand: 'editStaircaseCommand',
    EditSunCommand: 'editSunCommand',
    EditTechnicalElementCommand: 'editTechnicalElementCommand',
    EditWallCommand: 'editWallCommand',
    EditWallPropertiesCommand: 'editWallPropertiesCommand',
    CopyPasteWallAndComponentsCommand: 'copyPasteWallAndComponentsCommand',
    EntitiesLeaveGroupCommand: 'entitiesLeaveGroupCommand',
    LeaveGroupCommand: 'leaveGroupCommand',
    MergeGroupCommand: 'mergeGroupeCommand',
    RotateFloorCommand: 'rotateFloorCommand',
    RotateProjectCommand: 'rotateProjectCommand',
    ScaleFloorCommand: 'scaleFloorCommand',
    ScaleProjectCommand: 'scaleProjectCommand',
    SymetryFloorCommand: 'symetryFloorCommand',
    SymetryProjectCommand: 'symetryProjectCommand',
    TranslateFloorCommand: 'translateFloorCommand',
    TranslateProjectCommand: 'translateProjectCommand',
    SetFloorHeightCommand: 'setFloorHeightCommand',
    SetProjectFloorHeightCommand: 'setProjectFloorHeightCommand',
    SetRoomFloorHeightCommand: 'setRoomFloorHeightCommand',
    SnapEntitiesCommand: 'snapEntitiesCommand',
    SetFloorCornicesCommand: 'setFloorCornicesCommand',
    SetProjectCornicesCommand: 'setProjectCornicesCommand'
};
