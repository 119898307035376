import { Command, CommandEnum, DeleteWallCommand } from './CommandModule';
import { Events } from '../events';
import { eventsManager } from '../managers/EventsManager';
import { Area, ComponentConstants, Entity, Floor, Wall, Room, roomManager } from '../SavaneJS'; 

export class DeleteRoomsCommand  extends Command
{
    // Array of rooms to delete
    private _roomsDeleted: Array<Room>;
    // Current selected entities before room deletion
    private _selectedEntities: Array <Entity>;
    // Array of walls to delete
    private _deletedWalls: Array<Wall>;
    // Current floor of the deletion
    private _currentFloor: Floor;
    // Items to recompute parents
    private _itemToRecompute: Array<Entity>;
    // Deleted walls
    private _deleteWallsCommand: Array<DeleteWallCommand>;

    constructor(roomsDeleted: Array<Room>, selectedEntities: Array<Entity>) {
        super();
        // Array of walls to delete
        this._deletedWalls = [];
        // STore the list of rooms to delete
        this._roomsDeleted = roomsDeleted;
        // Selected entities array
        this._selectedEntities = selectedEntities;
        // Floor of the operation
        this._currentFloor = roomsDeleted[0].floor;

        // List of item to recompute
        this._itemToRecompute = [];

        // Parse all rooms to delete to get all walls to delete
        for (let i = 0; i < roomsDeleted.length; i++) {
            // Add walls to list of wall to delete
            for (let j = 0; j < roomsDeleted[i].walls.length; j++) {
                let toDelete = true;
                for (let k = 0; k < roomsDeleted[i].walls[j].rooms.length; k++) {
                    if (roomsDeleted.indexOf(roomsDeleted[i].walls[j].rooms[k]) === -1) {
                        toDelete = false;
                    }
                }

                if (toDelete) {
                    if (this._deletedWalls.indexOf(roomsDeleted[i].walls[j]) === -1) {
                        this._deletedWalls.push(roomsDeleted[i].walls[j]);
                    }
                }
            }
            // Add to list all children to recompute
            for (let k = 0; k < roomsDeleted[i].children.length; k++) {
                this._itemToRecompute.push(roomsDeleted[i].children[k]);
            }
        }

        // List of delete wall command that will be filled by execute function
        this._deleteWallsCommand = [];

        eventsManager.instance.dispatch(Events.CHANGE_EDITOR_SELECTION,
            {
                selection: [],
                keepSelected: false,
                showTulip: false
            });
    }

    // Command name returned from a global enum
    name(): string {
        return CommandEnum.DeleteRoomsCommand;
    }

    // Undo the current command
    undo() {
        // Parse all delete wall commands and undo them all, this will recreate everything
        for (let i = this._deleteWallsCommand.length - 1; i >= 0; i--) {
            this._deleteWallsCommand[i].undo();
        }

        eventsManager.instance.dispatch(Events.CHANGE_EDITOR_SELECTION,
            {
                selection: [],
                keepSelected: false,
                showTulip: false
            });
    }

    // Execute current command (redo)
    execute() {
        // Loop variables
        let i, j;
        // Update room list array
        let updatedRooms = [];

        // Parse all rooms of the scene
        for (i = 0; i < this._currentFloor.rooms.length; i++) {
            // Get the list of adjacent rooms to the room to delete
            let innerRoom = this._currentFloor.rooms[i].getInnerRoomList(false);
            let done = false;

            // If any room of the adjacent room list touches a room to delete
            for (j = 0; j < this._roomsDeleted.length; j++) {
                if (innerRoom.indexOf(this._roomsDeleted[j]) >= 0) {
                    done = true;
                    // We will have to update the room
                    updatedRooms.push(this._currentFloor.rooms[i]);
                    break;
                }
            }
            if (done)
                break;
        }

        // Parse all walls to delete and create a new delete wall command for each of them
        for (i = 0; i < this._deletedWalls.length; i++) {
            this._deleteWallsCommand[i] = new DeleteWallCommand([this._deletedWalls[i]], this._selectedEntities);
            this._deleteWallsCommand[i].execute();
        }

        // Recompute all items
        for (i = 0; i < this._itemToRecompute.length; i++) {
            let item = this._itemToRecompute[i];

            // Recompute parent if the entity is an arrangement object (the room containing the object might have changed due to the room deletion)
            if (item.isArrangementObjectEntity() || item.isArrangementGroupEntity() || item.isRenderCameraEntity() || item.isGeometryPrimitiveEntity() || item.isWorktopEntity()) {
                // Find containing room
                let roomContaining = null;

                if (item.isWorktopEntity()) {
                    let area = item.getComponent(ComponentConstants.ComponentType.Area);
                    if (area) {
                        roomContaining = roomManager.getRoomAtPosition((area as Area).vertices[0], this._currentFloor);
                    }
                }
                else {
                    roomContaining = roomManager.getRoomAtPosition(item.position, this._currentFloor);
                }

                // Remove the object from the previous room
                if (item.parent !== null) {
                    item.parent.deleteChild(item.id);
                }

                // Any room ?
                if (roomContaining !== null) {
                    // Add the object to the new room it belongs to
                    roomContaining.addChild(item, 100);
                }
                else {
                    // Set item child of floor
                    this._currentFloor.addChild(item);
                }

                // Graphical node
                eventsManager.instance.dispatch(Events.ADD_GRAPHICAL_ENTITY,
                    {
                        entity: item,
                    });
            }
        }

        // Redraw all rooms that need to be updated
        for (i = 0; i < updatedRooms.length; i++) {
            eventsManager.instance.dispatch(Events.REDRAW_GRAPHICAL_ROOM,
                {
                    entity: updatedRooms[i],
                });
        }

        eventsManager.instance.dispatch(Events.CHANGE_EDITOR_SELECTION,
            {
                selection: [],
                keepSelected: false,
                showTulip: false
            });
    }
}
