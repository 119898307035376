export class KitchenToolConstants {
    constructor() {
    }
}

export namespace KitchenToolConstants {

    export const DefaultPlinthHeight = 120;
    export const DefaultPlinthThickness = 10;
    export const DefaultPlinthLengthKit = 4000;
    
    export const DefaultEndPanelThickness = 10;
    
    export const DefaultFillerLength = 140;
    export const DefaultFillerThickness = 10;
    
    export const FillerPrecision = 10;
    
    export const workTopDefaultThickness = 40;
    export const workTopDefaultHeight = 932;
    export const workTopDefaultBorder = 30;
    
    export const WorkTopEightPrecision = 100;   

}
