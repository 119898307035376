import { glMatrix } from 'gl-matrix';
import * as math from 'gl-matrix';
import { Entity } from './Entity';

export { math };

/**
 * Transform store spatial information (position, rotation, scale)
 *
 * @constructor
 */
export class Transform {

    public entity: Entity;
    public localMatrix: math.mat4 = math.mat4.create();

    constructor(e: Entity) {
        this.entity = e;
        math.mat4.identity(this.localMatrix);
    }

    /**
     * Set a new local position of the transform
     *
     * @param {math.vec3} position
     */
    set localPosition(p: math.vec3) {
        this.localMatrix[12] = p[0];
        this.localMatrix[13] = p[1];
        this.localMatrix[14] = p[2];
    }

    /**
     * Getter for the local position of the transform
     *
     * @returns {*}
     */
    get localPosition(): math.vec3 {
        let res = math.vec3.create();
        math.vec3.set(res, this.localMatrix[12], this.localMatrix[13], this.localMatrix[14]);
        return res;
    }

    /**
     * Set a new local scale of the transform
     *
     * @param {math.vec3} scale
     */
    set localScale(s: math.vec3) {
        let currentScale = math.vec3.create();
        math.mat4.getScaling(currentScale, this.localMatrix);
        math.vec3.div(currentScale, s, currentScale);
        math.mat4.scale(this.localMatrix, this.localMatrix, currentScale);
    }

    /**
     * Getter for the local scale of the transform
     *
     * @returns {*}
     */
    get localScale(): math.vec3 {
        let scale = math.vec3.create();
        math.mat4.getScaling(scale, this.localMatrix);
        return scale;
    }

    static extractRotation(mat: math.mat4): math.mat4 {
        let m = math.mat4.clone(mat);
        // remove scale
        let scale = math.vec3.create();
        math.mat4.getScaling(scale, m);
        math.vec3.inverse(scale, scale);
        math.mat4.scale(m, m, scale);
        m[12] = 0; m[13] = 0; m[14] = 0;
        return m;
    }

    static extractEulerAngles(mat: math.mat4): math.vec3 {
        let m4 = this.extractRotation(mat);
        let m3 = math.mat3.create();
        math.mat3.fromMat4(m3, m4);
        let q = math.quat.create();
        math.quat.fromMat3(q, m3);

        let x = q[0],
        y = q[1],
        z = q[2],
        w = q[3],
        x2 = x * x,
        y2 = y * y,
        z2 = z * z,
        w2 = w * w;
        let unit = x2 + y2 + z2 + w2;
        let test = x * w - y * z;
        let yaw, pitch, roll;
        if (test > (0.5 - glMatrix.EPSILON) * unit) {
            // singularity at the north pole
            roll = Math.PI / 2;
            pitch = 2 * Math.atan2(y, x);
            yaw = 0;
        } else if (test < (-0.5 + glMatrix.EPSILON) * unit) {
            // singularity at the south pole
            roll = -Math.PI / 2;
            pitch = 2 * Math.atan2(y, x);
            yaw = 0;
        } else {
            roll = Math.asin(2 * (x * z - w * y));
            pitch = Math.atan2(2 * (x * w + y * z), 1 - 2 * (z2 + w2));
            yaw = Math.atan2(2 * (x * y + z * w), 1 - 2 * (y2 + z2));
        }

        return math.vec3.fromValues(-roll, -pitch + Math.PI, yaw);
    }

    getEulerAngles(): math.vec3 {
        return Transform.extractEulerAngles(this.localMatrix);
    }

    /**
     * get the local x rotation
     */
    get localXRotation(): number {
        return Transform.extractEulerAngles(this.localMatrix)[0];
    }

    /**
     * get the local y rotation
     */
    get localYRotation(): number {
        return Transform.extractEulerAngles(this.localMatrix)[1];
    }

    /**
     * get the local z rotation
     */
    get localZRotation(): number {
        return Transform.extractEulerAngles(this.localMatrix)[2];
    }

    /**
     * local rotate around the x axis
     *
     * @param {Number} xr
     */
    set localXRotation(xr: number) {
        let scale = this.localScale;
        this.localScale = math.vec3.fromValues(1, 1, 1);

        let rotation = xr - this.localXRotation;
        math.mat4.rotateX(this.localMatrix, this.localMatrix, rotation);

        this.localScale = scale;
    }

    /**
     * local rotate around the y axis
     *
     * @param {Number} yr
     */
    set localYRotation(yr: number) {
        let scale = this.localScale;
        this.localScale = math.vec3.fromValues(1, 1, 1);

        let rotation = yr - this.localYRotation;
        math.mat4.rotateY(this.localMatrix, this.localMatrix, rotation);

        this.localScale = scale;
    }

    /**
     * local rotate around the z axis
     * input in radian
     *
     * @param {Number} zr
     */
    set localZRotation(zr: number) {
        let scale = this.localScale;
        this.localScale = math.vec3.fromValues(1, 1, 1);

        let rotation = zr - this.localZRotation;
        math.mat4.rotateZ(this.localMatrix, this.localMatrix, rotation);

        this.localScale = scale;
    }

    /**
     * Getter for the invert local matrix of the transform
     *
     * @returns {*}
     */
    get invertedLocalMatrix(): math.mat4 {
        let result = math.mat4.create();
        math.mat4.invert(result, this.localMatrix);
        return result;
    }

    /**
     * rotate around the world x axis
     *
     * @param {Number} xr
     */
    set globalXRotation(xr: number) {
        let scale = this.localScale;
        this.localScale = math.vec3.fromValues(1, 1, 1);

        let X = math.vec3.fromValues(1, 0, 0);
        let rMatrix = Transform.extractRotation(this.invertedGlobalMatrix);
        math.vec3.transformMat4(X, X, rMatrix);

        let rotation = xr - this.globalXRotation;
        math.mat4.rotate(this.localMatrix, this.localMatrix, rotation, X);

        this.localScale = scale;
    }

    /**
     * rotate around the world y axis
     *
     * @param {Number} yr
     */
    set globalYRotation(yr: number) {
        let scale = this.localScale;
        this.localScale = math.vec3.fromValues(1, 1, 1);

        let Y = math.vec3.fromValues(0, 1, 0);
        let rMatrix = Transform.extractRotation(this.invertedGlobalMatrix);
        math.vec3.transformMat4(Y, Y, rMatrix);

        let rotation = yr - this.globalYRotation;
        math.mat4.rotate(this.localMatrix, this.localMatrix, rotation, Y);

        this.localScale = scale;
    }

    /**
     * rotate around the world z axis
     * input in radian
     *
     * @param {Number} zr
     */
    set globalZRotation(zr: number) {
        let scale = this.localScale;
        this.localScale = math.vec3.fromValues(1, 1, 1);;

        let Z = math.vec3.fromValues(0, 0, 1);
        let rMatrix = Transform.extractRotation(this.invertedGlobalMatrix);
        math.vec3.transformMat4(Z, Z, rMatrix);

        let rotation = zr - this.globalZRotation;
        math.mat4.rotate(this.localMatrix, this.localMatrix, rotation, Z);

        this.localScale = scale;
    }

    get globalZRotation(): number {
        return Transform.extractEulerAngles(this.globalMatrix)[2];
    }

    get globalYRotation(): number {
        return Transform.extractEulerAngles(this.globalMatrix)[1];
    }

    get globalXRotation(): number {
        return Transform.extractEulerAngles(this.globalMatrix)[0];
    }

    /**
     * Getter for the global matrix of the transform
     *
     * @returns {*}
     */
    get globalMatrix(): math.mat4 {
        let globalMatrix = math.mat4.create();
        math.mat4.copy(globalMatrix, this.localMatrix);
        if (this.entity.parent !== null) {
            math.mat4.multiply(globalMatrix, this.entity.parent.transform.globalMatrix, globalMatrix);
        }
        return globalMatrix;
    }

    /**
     * Getter for the invert global matrix of the transform
     *
     * @returns {*}
     */
    get invertedGlobalMatrix(): math.mat4 {
        let result = math.mat4.create();
        math.mat4.invert(result, this.globalMatrix);
        return result;
    }

    /**
     * Getter for the global position of the transform
     *
     * @returns {*}
     */
    get globalPosition(): math.vec3 {
        let global = this.globalMatrix;
        let result = math.vec3.fromValues(global[12], global[13], global[14]);
        return result;
    }

    /**
     * Setter for the global position of the transform
     *
     * @param {math.vec3} p
     */
    set globalPosition(p: math.vec3) {
        let localMatrix = math.mat4.create();
        math.mat4.translate(localMatrix, localMatrix, p);
        if (this.entity.parent !== null) {
            let invert = math.mat4.create();
            math.mat4.invert(invert, this.entity.parent.transform.globalMatrix);
            math.mat4.multiply(localMatrix, invert, localMatrix);
        }
        let res = math.vec3.create();
        math.vec3.set(res, localMatrix[12], localMatrix[13], localMatrix[14]);
        this.localPosition = res;
    }

    /**
     * Getter for the global scale of the transform
     *
     * @returns {*}
     */
    get globalScale(): math.vec3 {
        let result = math.vec3.create();
        math.mat4.getScaling(result, this.globalMatrix);
        return result;
    }

    /**
     * Clone the transform
     * @param t
     */
    clone(t: Transform) {
        this.entity = t.entity;
        this.localMatrix = math.mat4.clone(t.localMatrix);
    }

}
