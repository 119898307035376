import { Command, CommandEnum } from "./CommandModule";
import { Events } from "../events";
import { eventsManager } from "../managers/EventsManager";
import { Entity, ArrangementObject, ArrangementGroup, Staircase, TechnicalElement, SceneConstants, roomManager, math } from "../SavaneJS";

export class AddEntityCommand extends Command {
    private _addedEntity: Entity;
    private _addedEntityParent: Entity;
    private _selected: boolean;
    private _preventHeightUpdate: boolean;
    private _needsRefreshHullOverride: boolean;
    
    // Constructor will take 4 parameters for the item added, the parent, the selected state after the operation and whether or not we need to update its floorHeight
    constructor(addedEntity: Entity, addedEntityParent: Entity, selected: boolean, preventHeightUpdate: boolean, needsRefreshHullOverride: boolean) {
        super();
        // We store them all within member variables
        this._addedEntity = addedEntity;
        this._addedEntityParent = addedEntityParent;
        if (selected === undefined) {
            this._selected = true;
        } else {
            this._selected = selected;
        }
        if (preventHeightUpdate === undefined) {
            this._preventHeightUpdate = false;
        } else {
            this._preventHeightUpdate = preventHeightUpdate;
        }

        this._needsRefreshHullOverride = needsRefreshHullOverride;

        // End temporary state of the entity
        if (this._addedEntity.temporary !== null) {
            this._addedEntity.saveAndEndTemporary();
        }
    }

    // Command name returned from a global enum
    name(): string {
        return CommandEnum.AddEntityCommand;
    }

    // Datas that allows the command to be executed
    execDatas(): any {
        return {
            id: this._addedEntity.id,
            type: this._addedEntity.entityType,
            entity: this._addedEntity,
        };
    }

    needsRefreshHull(): boolean {
        if (this._needsRefreshHullOverride !== undefined) {
            return this._needsRefreshHullOverride;
        }

        return this.doesTreeContainFurnitureFinishes(this._addedEntity) || this._addedEntity.isWorktopEntity() || this._addedEntity.isGeometryPrimitiveEntity();
    }

    // Undo the current command
    undo() {
        math.mat4.copy(this._addedEntity.transform.localMatrix, this._addedEntity.transform.globalMatrix);
        // Remove from SavaneFormat
        this._addedEntityParent.deleteChild(this._addedEntity.id);
        this._addedEntity.parent = null;

        // Remove from entity manager
        eventsManager.instance.dispatch(Events.REMOVE_GRAPHICAL_ENTITY, {
            entity: this._addedEntity,
        });

        // Execute parent function
        super.undo();
    }

    // Execute current command (redo)
    execute() {
        // Try to remove the entity from the graphical entity manager
        eventsManager.instance.dispatch(Events.REMOVE_GRAPHICAL_ENTITY, {
            entity: this._addedEntity,
        });

        // Add item to parent
        let toLocal = math.mat4.create();
        math.mat4.invert(toLocal, this._addedEntityParent.transform.globalMatrix);
        let world = this._addedEntity.transform.globalMatrix;
        let matrix = math.mat4.create();
        math.mat4.multiply(matrix, toLocal, world);
        math.mat4.copy(this._addedEntity.transform.localMatrix, matrix);

        // Add child to parent
        this._addedEntityParent.addChild(this._addedEntity);

        // Update floor height
        let room = roomManager.getRoomAtPosition(this._addedEntity.position, this._addedEntity.floor);

        switch (this._addedEntity.entityType) {
            case SceneConstants.EntityType.ArrangementObject:
                (this._addedEntity as ArrangementObject).updateFloorHeightFromAnchor(room, this._addedEntity.floor);
                break;

            case SceneConstants.EntityType.ArrangementGroup:
                (this._addedEntity as ArrangementGroup).updateFloorHeightFromAnchor(room, this._addedEntity.floor);
                break;

            case SceneConstants.EntityType.Staircase:
                if (!this._preventHeightUpdate) {
                    if (room !== null) {
                        (this._addedEntity as Staircase).height = room.height; // Set height of the pole with the room pole
                    }
                }
                break;

            // Spotlight automatic ceiling placement
            case SceneConstants.EntityType.TechnicalElement:
                if (!this._preventHeightUpdate) {
                    switch ((this._addedEntity as TechnicalElement).objectId) {
                        case SceneConstants.TechnicalElementType.spotLight:
                            if (room !== null) {
                                this._addedEntity.floorHeight = room.height - room.floorHeight - 20; // Place the spotlight 1cm underneath the ceiling so it is a bit out of the ceiling
                            }
                            break;

                        case SceneConstants.TechnicalElementType.beam:
                        case SceneConstants.TechnicalElementType.rosette:
                        case SceneConstants.TechnicalElementType.frame:
                            if (room !== null) {
                                this._addedEntity.floorHeight = room.height - room.floorHeight - (this._addedEntity as TechnicalElement).height; // Place the beam, rosette and frame to touch the ceiling
                            }
                            break;

                        case SceneConstants.TechnicalElementType.pole:
                        case SceneConstants.TechnicalElementType.stove:
                            if (room !== null && room.height !== 0) {
                                (this._addedEntity as TechnicalElement).height = room.height - room.floorHeight; // Set height of the pole with the room pole
                            }

                            this._addedEntity.floorHeight = SceneConstants.TechElementDefaultFloorHeight[(this._addedEntity as TechnicalElement).objectId];
                            break;
                    }
                    break;
                }
        }

        // Add the entity to the graphic display
        eventsManager.instance.dispatch(Events.ADD_GRAPHICAL_ENTITY, {
            entity: this._addedEntity,
        });

        //Execute parent function
        super.execute();

        // Select object at the end if needed
        if (this._selected) {
            eventsManager.instance.dispatch(Events.CHANGE_EDITOR_SELECTION, {
                selection: [this._addedEntity],
                keepSelected: false,
                showTulip: false,
            });
        }
    }
}
