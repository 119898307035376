import { SavaneCookie } from '../../utils/SavaneCookie';
import { TemporaryJoinery } from './../temporary/TemporaryJoinery';
import { JoineryType, ComponentConstants, SceneConstants, math } from '../../SavaneJS';
import { Joinery } from './Joinery';

/**
 * Joinery  of type : CupboardDoor
 */
export class CupboardDoor extends Joinery {
    // Default nb doors for a CupboardDoor is 2
    private _nbDoors = 2;
    // Default door isn't opened
    private _isOpened = false;
    private _leftOpeningAngle = 90;
    private _rightOpeningAngle = 90;
    // Default mode params
    private _handleSide    = SceneConstants.HandleSide.handle_center;

    constructor(joineryType: SceneConstants.JoineryType, position: math.vec3) {
        super(joineryType, position);

        // Adjust joinery 3D model thanks to the parameters set above
        this.setJoineryType();

        let componentList = this.getComponents(ComponentConstants.ComponentType.JoineryType);
        if (componentList) {
            let assetId = (componentList[0] as JoineryType).joineryTypeId;

            switch (assetId) {
                case '5ff486976324651be13128e7':
                case '5ff48b45bf75301c610dff42':
                    this._materialType = 1008; // for single color cuisinella
                    break;

                case '5ff48f4ac904e750cb52cd99':
                case '5ff4943c4b141c1c07598803':
                    this._materialType = 2001; // for double color cuisinella
                    break;
            }
        }
    }

    /**
     * Set the correct AM joinery 3D model depending on door parameters
     */
    setJoineryType() {
        // Get all JoineryType entity components
        let componentList = this.getComponents(ComponentConstants.ComponentType.JoineryType);

        // Is there any existing JoineryType component ?
        if (componentList.length > 0) {
            // Single cupboard door model
            if (this.nbDoors === 1) {
                (componentList[0] as JoineryType).joineryTypeId = SceneConstants.DefaultJoineryType.singleCupboardDoor;
            }
            else {
                // Double cupboard door model

                // To remove in 2020 when we estimate all users have used the app and updated this value
                SavaneCookie.setCookie("Rhinov-Savane-DefaultJoineryModelCupboardDoor", SceneConstants.DefaultJoineryType.cupboardDoor);
                // End to remove in 2020

                (componentList[0] as JoineryType).joineryTypeId = SavaneCookie.getCookie("Rhinov-Savane-DefaultJoineryModelCupboardDoor", SceneConstants.DefaultJoineryType.cupboardDoor);
            }
        }
        else {
            // No so we will create the component now

            // Singlecupboard door model only 2 doors is linked to cookies, 1 is hardcoded;
            if (this.nbDoors === 1) {
                this.addComponent(new JoineryType(SceneConstants.DefaultJoineryType.singleCupboardDoor, undefined, undefined));
            }
            else {
                // To remove in 2020 when we estimate all users have used the app and updated this value
                SavaneCookie.setCookie("Rhinov-Savane-DefaultJoineryModelCupboardDoor", SceneConstants.DefaultJoineryType.cupboardDoor);
                // End to remove in 2020

                this.addComponent(new JoineryType(SavaneCookie.getCookie("Rhinov-Savane-DefaultJoineryModelCupboardDoor", SceneConstants.DefaultJoineryType.cupboardDoor), undefined, undefined));
            }
        }
    }

    get nbDoors(): number {
        if (this.temporary === null) {
            return this._nbDoors;
        }
        else {
            return (this.temporary as TemporaryJoinery).nbDoors;
        }
    }

    set nbDoors(d: number) {
        // Boolean to detect if a change is performed to update 3D model only if a change is performed
        let change = false;

        if (this.temporary === null) {
            if (this._nbDoors !== d) {
                this._nbDoors = d;
                change = true;
            }
        }
        else {
            if ((this.temporary as TemporaryJoinery).nbDoors !== d) {
                (this.temporary as TemporaryJoinery).nbDoors = d;
                change = true;
            }
        }

        if (d > 1) {
            this.handleSide = SceneConstants.HandleSide.handle_center; //Handle side forced for {SceneConstants.MaxDoorJoineryDefault} doors
        }
        else {
            if (this.handleSide === SceneConstants.HandleSide.handle_center) {
                this.handleSide = SceneConstants.HandleSide.handle_left;
            }
        }

        // Set joinery 3D model only if a change is really done
        if (change) {
            // Set default 3D model after this change
            this.setJoineryType();
        }
    }

    // Getters and setters for the opening side
    get handleSide(): SceneConstants.HandleSide
    {
        if (this.temporary === null) {
            return this._handleSide;
        }
        else {
            return((this.temporary as TemporaryJoinery).handleSide);
        }
    }

    set handleSide(hs: SceneConstants.HandleSide)
    {
        if (this.nbDoors === 1) {
            if (this.temporary === null) {
                this._handleSide = hs;
            }
            else {
                (this.temporary as TemporaryJoinery).handleSide = hs;
            }
        }
        else {
            if (hs !== SceneConstants.HandleSide.handle_center) {
                // More than two doors handle side can't be changed
                let error = 'HandleSide is forced to handle_center with more than {SceneConstants.MaxDoorJoineryDefault}:' + SceneConstants.MaxDoorJoineryDefault + ' door on default opening mode';
                if (this.temporary) {
                    error += ' object has temporary with ' + (this.temporary as TemporaryJoinery).nbDoors + ' doors';
                }
                error += ' and ' + this._nbDoors + ' doors';

                throw(new Error(error));
            }
            else {
                if (this.temporary === null) {
                    this._handleSide = hs;
                }
                else {
                    (this.temporary as TemporaryJoinery).handleSide = hs;
                }
            }
        }
    }

    // Getters and setters for isOpened
    get isOpened(): boolean {
        if (this.temporary === null) {
            return this._isOpened;
        }
        else {
            return (this.temporary as TemporaryJoinery).isOpened;
        }
    }

    set isOpened(o: boolean) {
        if (this.temporary === null) {
            this._isOpened = o;
        }
        else {
            (this.temporary as TemporaryJoinery).isOpened = o;
        }
    }

    get leftOpeningAngle(): number {
        if (this.temporary === null) {
            return this._leftOpeningAngle;
        }
        else {
            return (this.temporary as TemporaryJoinery).leftOpeningAngle;
        }
    }

    set leftOpeningAngle(a: number) {
        if (this.temporary === null) {
            this._leftOpeningAngle = a;
        }
        else {
            (this.temporary as TemporaryJoinery).leftOpeningAngle = a;
        }
    }

    get rightOpeningAngle(): number {
        if (this.temporary === null) {
            return this._rightOpeningAngle;
        }
        else {
            return (this.temporary as TemporaryJoinery).rightOpeningAngle;
        }
    }

    set rightOpeningAngle(a: number) {
        if (this.temporary === null) {
            this._rightOpeningAngle = a;
        }
        else {
            (this.temporary as TemporaryJoinery).rightOpeningAngle = a;
        }
    }

    // Called when a property is changed to verify max and min boundaries or to perform computations before final assignment
    addToCappedProperty(property: string, value: any) : number {
        let capped = 0;

        if (property === "nbDoors") {
            if (this[property] + value <= 1) {
                this[property] = 1;
                capped = 1;
            }

            if (this[property] + value >= 2) {
                this[property] = 2;
                capped = 2;
            }

            if (capped === 0) {
                this[property] += value;
            }
        }
        else {
            return super.addToCappedProperty(property, value);
        }
        return capped;
    }
}
