import { SavaneCookie } from '../../utils/SavaneCookie';
import { JoineryType, SceneConstants, math } from '../../SavaneJS';
import { Joinery } from './Joinery';

/**
 * Joinery  of type : fixed window
 */
export class FixedWindow extends Joinery
{
    constructor(joineryType: SceneConstants.JoineryType, position: math.vec3) {
        super(joineryType, position);

        this.addComponent(new JoineryType(SavaneCookie.getCookie("Rhinov-Savane-DefaultJoineryModelFixedWindow", SceneConstants.DefaultJoineryType.fixedWindow), undefined, undefined));
    }

    // Called when a property is changed to verify max and min boundaries or to perform computations before final assignment
    addToCappedProperty(property: string, value: any) : number {
        // -1 = unknwon property, 0 = no cap, 1 = minus cap, 2 max cap will be returned at the end
        let capped = 0;

        if (value === 0) {
            return(capped);
        }

        return super.addToCappedProperty(property, value);
    }
}
