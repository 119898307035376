import { Command, CommandEnum, DeleteComponentCommand } from './CommandModule';
import { Component } from '../SavaneJS';

export class DeleteComponentsCommand  extends Command
{
    // Command list to execute and undo
    private _subCommands: Array<Command> = [];

    constructor(components: Array<Component>, updateWebGL: boolean) {
        super();
        // Parse all entity array passed to the constructor
        for (let i = 0; i < components.length; i++) {
            // And create a delete entity command for each of them
            this._subCommands.push(new DeleteComponentCommand(components[i], updateWebGL));
        }
    }

    name(): string {
        return CommandEnum.DeleteComponentsCommand;
    }

    // Undo the current command
    undo() {
        // Parse all commands created by the constructor and undo them all
        for (let i = 0; i < this._subCommands.length; i++) {
            this._subCommands[i].undo();
        }

        //Execute parent function
        super.undo();
    }

    // Execute current command (redo)
    execute() {
        // Parse all commands created by the constructor and execute them all
        for (let i = 0; i < this._subCommands.length; i++) {
            this._subCommands[i].execute();
        }

        //Execute parent function
        super.execute();
    }
};
