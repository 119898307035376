import * as glMatrix from 'gl-matrix';

export class HermitePoint
{
    private _position: glMatrix.vec3;
    private _tangent: glMatrix.vec3;

    constructor(position: glMatrix.vec3, tangent: glMatrix.vec3) {
        this._position = position;
        this._tangent = tangent;
    }

    get Position() : glMatrix.vec3 {
        return this._position;
    }

    get Tangent() : glMatrix.vec3 {
        return this._tangent;
    }

    set Position(value: glMatrix.vec3) {
        this._position = value;
    }

    set Tangent(value: glMatrix.vec3) {
        this._tangent = value;
    }
}
