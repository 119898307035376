import { GeometryPrimitive } from './GeometryPrimitive';
import { math } from '../Transform';
import { TemporaryGeometryPrimitive } from './../temporary/TemporaryGeometryPrimitive';

/**
 * GeometryPrimitive  of type : Cylinder
 */
export class Sphere extends GeometryPrimitive {
    protected _diameter: number;

    constructor(primitiveType, height) {
        super(primitiveType, height);

        this._diameter = height;
    }

    /**
     * Getter for the diameter of the cylinder
     */
    get diameter() {
        if (this.temporary === null) {
            return this._diameter;
        }
        else {
            return (this.temporary as TemporaryGeometryPrimitive).diameter;
        }
    }

    /**
     * Setter for the diameter of the cylinder
     *
     * @param {*} d
     */
    set diameter(d: number) {
        if (this.temporary === null) {
            this.height = d;
            this._diameter = d;
        }
        else {
            (this.temporary as TemporaryGeometryPrimitive).height = d;
            (this.temporary as TemporaryGeometryPrimitive).diameter = d;
        }
    }

    /**
     * Returns the array of 4 point deliminting the cylinder (2d)
     */
    get boundingBox(): Array<math.vec3> {
        let points = [];
        let point1 = math.vec3.create();
        let point2 = math.vec3.create();
        let point3 = math.vec3.create();
        let point4 = math.vec3.create();
        //Precalculate sin/cos
        let sAngle = Math.sin(this.angle);
        let cAngle = Math.cos(this.angle);
        let invSAngle = Math.sin(Math.PI / 2 - this.angle);
        let invCAngle = Math.cos(Math.PI / 2 - this.angle);

        math.vec3.set(point1, this.position[0] + cAngle *
            this.diameter / 2, this.position[1] +
            sAngle * this.diameter / 2, 0);

        math.vec3.set(point2, point1[0] -
            invCAngle *
            this.diameter / 2, point1[1] +
            invSAngle *
            this.diameter / 2, 0);

        math.vec3.set(point1, point1[0] +
            invCAngle *
            this.diameter / 2, point1[1] -
            invSAngle *
            this.diameter / 2, 0);

        math.vec3.set(point3, this.position[0] -
            cAngle *
            this.diameter / 2, this.position[1] -
            sAngle * this.diameter / 2, 0);

        math.vec3.set(point4, point3[0] +
            invCAngle *
            this.diameter / 2, point3[1] -
            invSAngle *
            this.diameter / 2, 0);
        math.vec3.set(point3, point3[0] -
            invCAngle *
            this.diameter / 2, point3[1] +
            invSAngle *
            this.diameter / 2, 0);

        points.push(point1);
        points.push(point2);
        points.push(point3);
        points.push(point4);

        return points;
    }

    /**
     * save the temporary data in the joinery and delete the temporaryJoinery
     */
    saveAndEndTemporary() {
        // Common parameters to all joineries, we get the from getter getting them from the temporary object
        let savedLocalMatrix = math.mat4.create();
        math.mat4.copy(savedLocalMatrix, this.transform.localMatrix);
        let heightTemp = this.height;
        let hideInAxoTemp = this.hideInAxo;
        let cutByJoineriesTemp = this.cutByJoineries;
        // Specific parameters
        let diameterTemp = this._diameter;

        // Destroy the temporary object so that getters and setter now modify the real object
        this.temporary = null;

        // Reassign parameters with setters setting them to the real object now (temporary is null now)
        math.mat4.copy(this.transform.localMatrix, savedLocalMatrix);
        this.height = heightTemp;
        this.hideInAxo = hideInAxoTemp;
        this.cutByJoineries = cutByJoineriesTemp;

        // Reassign specific parameters
        this._diameter = diameterTemp;
    }
}
