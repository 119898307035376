import { SavaneCookie } from '../../utils/SavaneCookie';
import { JoineryType, SceneConstants, math } from '../../SavaneJS';
import { Joinery } from './Joinery';

/**
 * Joinery  of type : GarageDoor
 */
export class  GarageDoor extends Joinery {
    constructor(joineryType: number, position: math.vec3) {
        super(joineryType, position);
        this.addComponent(new JoineryType(SavaneCookie.getCookie("Rhinov-Savane-DefaultJoineryModelGarageDoor", SceneConstants.DefaultJoineryType.garageDoor), undefined, undefined));
    }
}
