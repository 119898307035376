import { SavaneCookie } from "../../utils/SavaneCookie";
import { TemporaryJoinery } from './../temporary/TemporaryJoinery';
import { JoineryType, ComponentConstants, SceneConstants, math } from "../../SavaneJS";
import { Joinery } from "./Joinery";

/**
 * Joinery  of type : PictureWindow (Baie vitrée)
 */
export class PictureWindow extends Joinery {
    // Default nbDoors for a door is 2
    private _nbDoors: number = SceneConstants.MinDoorJoinerySlide;
    //Slide params
    public slideDirection: SceneConstants.SlideDirection = SceneConstants.SlideDirection.slide_left;
    // Default mode params
    private _handleSide: SceneConstants.HandleSide = SceneConstants.HandleSide.handle_left;
    // Default door isn't opened
    private _isOpened: boolean = false;
    private _leftOpeningAngle: number = 90;
    private _rightOpeningAngle: number = 90;    

    constructor(joineryType: SceneConstants.JoineryType, position: math.vec3) {
        super(joineryType, position);

        // Adjust joinery 3D model thanks to the parameters set above
        this.setJoineryType();
    }

    // Getters and setters for number of doors
    get nbDoors(): number {
        if (this.temporary === null) {
            return this._nbDoors;
        } else {
            return (this.temporary as TemporaryJoinery).nbDoors;
        }
    }

    set nbDoors(d: number) {
        // Boolean to detect if a change is performed to update 3D model only if a change is performed
        let change = false;

        if (d > SceneConstants.MaxDoorPictureWindow) {
            throw new Error("Door count can't be more than {SceneConstants.MaxDoorJoinerySlide}:" + SceneConstants.MaxDoorJoinerySlide);
        }

        if (this.temporary === null) {
            if (this._nbDoors !== d) {
                this._nbDoors = d;
                change = true;
            }
        } else {
            if ((this.temporary as TemporaryJoinery).nbDoors !== d) {
                (this.temporary as TemporaryJoinery).nbDoors = d;
                change = true;
            }
        }

        if (d === 1) {
            if (this.handleSide === SceneConstants.HandleSide.handle_center) {
                this.handleSide = SceneConstants.HandleSide.handle_left; //Handle side forced for {SceneConstants.MaxDoorJoineryDefault} doors
            }
        }

        // Update 3D model only if a change is really performed
        if (change) {
            // Set default 3D model after this change
            this.setJoineryType();
        }
    }

    // Getters and setters for isOpened
    get isOpened(): boolean {
        if (this.temporary === null) {
            return this._isOpened;
        } else {
            return (this.temporary as TemporaryJoinery).isOpened;
        }
    }

    set isOpened(o: boolean) {
        if (this.temporary === null) {
            this._isOpened = o;
        } else {
            (this.temporary as TemporaryJoinery).isOpened = o;
        }
    }

    get leftOpeningAngle(): number {
        if (this.temporary === null) {
            return this._leftOpeningAngle;
        } else {
            return (this.temporary as TemporaryJoinery).leftOpeningAngle;
        }
    }

    set leftOpeningAngle(oa: number) {
        if (this.temporary === null) {
            this._leftOpeningAngle = oa;
        } else {
            (this.temporary as TemporaryJoinery).leftOpeningAngle = oa;
        }
    }

    get rightOpeningAngle(): number {
        if (this.temporary === null) {
            return this._rightOpeningAngle;
        } else {
            return (this.temporary as TemporaryJoinery).rightOpeningAngle;
        }
    }

    set rightOpeningAngle(oa: number) {
        if (this.temporary === null) {
            this._rightOpeningAngle = oa;
        } else {
            (this.temporary as TemporaryJoinery).rightOpeningAngle = oa;
        }
    }

    // Getters and setters for the opening side
    get handleSide(): SceneConstants.HandleSide {
        if (this.temporary === null) {
            return this._handleSide;
        }
        else {
            return((this.temporary as TemporaryJoinery).handleSide);
        }
    }

    set handleSide(s: SceneConstants.HandleSide) {
        if (this._nbDoors === 1) {
            if (this.temporary === null) {
                this._handleSide = s;
            }
            else {
                (this.temporary as TemporaryJoinery).handleSide = s;
            }
        }
    }

    /**
     * Set the correct AM joinery 3D model depending on door parameters
     */
    setJoineryType() {
        // Get all JoineryType entity components
        let componentList = this.getComponents(ComponentConstants.ComponentType.JoineryType);

        // Is there any existing JoineryType component ?
        if (componentList.length > 0) {
            // Yes ! There is only one joinery type component for a joinery so it will be index 0 for sure

            // Double door model (battant)
            switch (this.nbDoors) {
                case SceneConstants.MinDoorPictureWindow:
                    // Single door picture window
                    (componentList[0] as JoineryType).joineryTypeId = SceneConstants.DefaultJoineryType.simplePictureWindow;
                    break;

                case SceneConstants.MaxDoorPictureWindow - 1:
                    // Triple door model
                    (componentList[0] as JoineryType).joineryTypeId = SceneConstants.DefaultJoineryType.triplePictureWindow;
                    break;

                case SceneConstants.MaxDoorPictureWindow:
                    // Quad door model
                    (componentList[0] as JoineryType).joineryTypeId = SceneConstants.DefaultJoineryType.quadPictureWindow;
                    break;

                default:
                    (componentList[0] as JoineryType).joineryTypeId = SavaneCookie.getCookie("Rhinov-Savane-DefaultJoineryModelPictureWindow", SceneConstants.DefaultJoineryType.pictureWindow);
            }
        } else {
            // No so we will create the component now

            // Single casement model
            switch (this.nbDoors) {
                case SceneConstants.MinDoorPictureWindow:
                    // Single door picture window
                    this.addComponent(new JoineryType(SceneConstants.DefaultJoineryType.simplePictureWindow, undefined, undefined));
                    break;

                case SceneConstants.MaxDoorPictureWindow - 1:
                    // Triple door model
                    this.addComponent(new JoineryType(SceneConstants.DefaultJoineryType.triplePictureWindow, undefined, undefined));
                    break;

                case SceneConstants.MaxDoorPictureWindow:
                    // Quad door model
                    this.addComponent(new JoineryType(SceneConstants.DefaultJoineryType.quadPictureWindow, undefined, undefined));
                    break;

                default:
                    this.addComponent(new JoineryType(SavaneCookie.getCookie("Rhinov-Savane-DefaultJoineryModelPictureWindow", SceneConstants.DefaultJoineryType.pictureWindow), undefined, undefined));
            }
        }
    }

    // Called when a property is changed to verify max and min boundaries or to perform computations before final assignment
    addToCappedProperty(property: string, value: any) : number {
        let capped = 0;

        if (value === 0) {
            return capped;
        }

        if (capped === -1) {
            return capped;
        }

        if (property === "nbDoors") {
            if (this[property] + value <= SceneConstants.MinDoorPictureWindow) {
                this[property] = SceneConstants.MinDoorPictureWindow;
                capped = 1;
            }

            if (this[property] + value >= SceneConstants.MaxDoorPictureWindow) {
                this[property] = SceneConstants.MaxDoorPictureWindow;
                capped = 2;
            }
            if (capped === 0) {
                this[property] += value;
            }
        } else {
            return super.addToCappedProperty(property, value);
        }
        return capped;
    }
}
