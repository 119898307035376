import { ComponentConstants, math, SavaneMath } from '../SavaneJS';
import { Coating } from './Coating';

export class FloorCoatingArea extends Coating {
    private _vertices: Array<math.vec3>;
    private _altitude: number;

    constructor(coatingId: string, manufacturer: any, retailer: any, hangType: Coating.HangType, colors: any, randomization: any, floorGeneratorSettings: any, urlPreview: string) {
        super(coatingId, manufacturer, retailer, hangType, colors, randomization, floorGeneratorSettings, urlPreview);
        this._vertices = [];
        this._altitude = 0;
    }

    isInArea(p: math.vec3): boolean {
        return SavaneMath.isInPoly(p, this._vertices);
    }

    get area(): number {
        return SavaneMath.getPolygonArea(this._vertices);
    }

    get componentType(): ComponentConstants.ComponentType {
        return ComponentConstants.ComponentType.FloorCoatingArea;
    }

    get vertices(): Array<math.vec3> {
        return this._vertices;
    }

    set vertices(v: Array<math.vec3>) {
        this._vertices = v;
    }

    get altitude(): number {
        return this._altitude;
    }

    set altitude(a: number) {
        this._altitude = a;
    }

    clone(): FloorCoatingArea {
        let obj = new FloorCoatingArea(this._coatingId, this._manufacturer, this._retailer, this._hangType, this._colors, this._randomization, this._floorGeneratorSettings, this.urlPreview);
        obj.colorIndex = this._colorIndex;
        obj.offset = this._offset;
        obj.altitude = this.altitude;

        let vertices = [];
        for (let i = 0; i < this._vertices.length; i++) {
            vertices.push(math.vec3.clone(this._vertices[i]));
        }
        obj.vertices = vertices;
        return obj;
    }
}
