import { Events } from '../events';

export class Listener {

    public callback: CallableFunction = null;

    constructor(callback) {
        this.callback = callback;
    }
}
export class Event {

    public name: string;
    public userData: any;

    constructor(name: string) {
        this.name = name;
        this.userData = null;
    }
    //TODO Refacto using real get/set, it's keeped to match cocos format
    setUserData(value: any) {
        this.userData = value;
    }
    getUserData() : any {
        return this.userData;
    }
}

class EventsManager {

    listeners: { dictionary: Array<any> };

    constructor() {
        this.listeners = {
            dictionary: []
        };
    }

    /**
     * Add listener create from a callback
     *
     * @param {String} eventName
     * @param {Function} callback
     * @returns {Listener}
     */
    addListener(eventName: string, callback: CallableFunction) : Listener | null {
        let listener = new Listener(callback);
        return this.addListenerObject(eventName, listener);
    }

    /**
     * Add a listener objet to the list
     *
     * @param {String} eventName
     * @param {Listener} listener
     * @returns {Listener|null}
     */
    addListenerObject(eventName: string, listener: Listener) : Listener | null {
        if (this.listeners[eventName] === undefined) {
            this.listeners[eventName] = [];
            this.listeners.dictionary.push(eventName);
        }else if (this.listeners[eventName].indexOf(listener) >= 0) {
            //Listener already added
            return null;
        }
        this.listeners[eventName].push(listener);
        return listener;
    }

    _isDictionaryContains(eventName: string) : boolean {
        for (let i = 0; i < this.listeners.dictionary.length; i++) {
            if (this.listeners.dictionary[i] === eventName) {
                return true;
            }
        }
        return false;
    }

    _removeFromDictionary(eventName: string) : boolean {
        for (let i = 0; i < this.listeners.dictionary.length; i++) {
            if (this.listeners.dictionary[i] === eventName) {
                this.listeners.dictionary.splice(i,1);
                return true;
            }
        }
        return false;
    }

    /**
     *  Remove all occurence from listeners list
     *
     * @param {String} eventName
     * @returns {Boolean}
     */
    removeAllListeners(eventName: string) : boolean {
        if (this.listeners[eventName] !== undefined) {
            this._removeFromDictionary(eventName);
            delete this.listeners[eventName];
            return true;
        }
        return false;
    }

    /**
     * Remove listener from list
     *
     * @param {Listener} listener
     */
    removeListener(listener: Listener) : void {
        for (let i = 0; i < this.listeners.dictionary.length; i++) {
            let curDicEntry = this.listeners.dictionary[i];
            let listenerIndex = this.listeners[curDicEntry].indexOf(listener);
            if (listenerIndex >= 0) {
                this.listeners[curDicEntry].splice(listenerIndex,1);
                if (this.listeners[curDicEntry].length <= 0) {
                    this._removeFromDictionary(curDicEntry);
                    delete this.listeners[curDicEntry];
                    return;
                }
                continue;
            }
        }
    }

    /**
     * Dispatch event to all listeners (synchronous)
     *
     * @param {String} eventName
     * @param {*} datas
     * @returns {Boolean}
     */
    dispatch(eventName: string, datas?: any) : boolean {
        let event =  new Event(eventName);
        event.setUserData(datas);
        return this.dispatchEvent(event);
    }
    /**
     * Dispatch a pre-created event
     *
     * @param {Event} event
     * @returns {Boolean}
     */
    dispatchEvent(event: Event) : boolean {
        let listenerList = this.listeners[event.name];
        if (listenerList !== undefined) {
            listenerList = listenerList.slice();
            let toRemove = [];
            for (let i = 0; i < listenerList.length; i++) {
                let value = listenerList[i].callback(event);
                //Process return values
                switch (value) {
                    case Events.UNREGISTER:
                        //Event will be unregistered at the end of dispatch loop
                        toRemove.push(listenerList[i]);
                        break;
                }
            }
            //Post dispatch process
            for (let j = 0; j < toRemove.length; j++) {
                this.removeListener(toRemove[j]);
            }
            return true;
        }else {
            //No callbacks registered to this event
            return false;
        }
    }
}

let singleton;

export class eventsManager {
    static get instance() {
        if (!singleton) {
            singleton = new EventsManager();
        }

        return singleton;
    }
}
