import { Command, CommandEnum } from './CommandModule';
import { Events } from '../events';
import { eventsManager } from '../managers/EventsManager';
import { Floor, Staircase } from '../SavaneJS';

export class DeleteStaircaseCommand extends Command
{
    // To store deleted staircase
    private _deletedStaircase: Staircase;
    // Deleted staircase floor
    private _deletedStaircaseFloor: Floor;

    constructor(staircase: Staircase) {
        super();
        this._deletedStaircase = staircase;
        this._deletedStaircaseFloor = staircase.floor;
    }

    // Command name returned from a global enum
    name(): string {
        return CommandEnum.DeleteStaircaseCommand;
    }

    execDatas(): any {
        return {
            id: this._deletedStaircase.id,
            type: this._deletedStaircase.entityType,
            entity: this._deletedStaircase
        };
    }

    // Undo the current command
    undo() {
      // Add the staircase saved in the constructor to the plan manager
      this._deletedStaircaseFloor.addStaircase(this._deletedStaircase);
      eventsManager.instance.dispatch(Events.ADD_GRAPHICAL_ENTITY,
          {
              entity: this._deletedStaircase,
          });

      // Execute parent function
      super.undo();

      eventsManager.instance.dispatch(Events.CHANGE_EDITOR_SELECTION,
          {
              selection: [this._deletedStaircase],
              keepSelected: false,
              showTulip: false
          });
    }

    // Execute current command (redo)
    execute() {
      // Remove the staircase element from the plan manager
      eventsManager.instance.dispatch(Events.REMOVE_GRAPHICAL_ENTITY,
          {
              entity: this._deletedStaircase,
          });
      this._deletedStaircaseFloor.deleteStaircase(this._deletedStaircase.id);

      // Execute parent function
      super.execute();

      eventsManager.instance.dispatch(Events.CHANGE_EDITOR_SELECTION,
          {
              selection: [],
              keepSelected: false,
              showTulip: false
          });
    }
};
