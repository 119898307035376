import { glMatrix } from "../../../math/Math";
import { SceneConstants } from "../../../SavaneJS";
import { Slope } from "./Slope";
import { Wall } from "./Wall";

export class Joinery {

    protected _type: string = "WALL";
    protected _wall: Wall | Slope;

    private _A: glMatrix.vec3;
    private _B: glMatrix.vec3;
    private _height: number;
    private _bottomHeight: number;
    private _joineryType: number;
    private _materialType: number;
    private _thickness: number;
    private _frosted: boolean;
    private _model: string;
    private _opened: boolean;
    private _rollingShutter: boolean;
    private _sliding: boolean;
    private _wallInstallType: number;
    private _nbDoors: number;
    private _handleSide: SceneConstants.HandleSide;
    private _transom: boolean;
    private _transomHeight: number;
    private _bottomTransom: boolean;
    private _bottomTransomHeight: number;
    private _dimensions: {width: number, length: number, height: number};
    private _savaneId: number;

    constructor(A: glMatrix.vec3, B: glMatrix.vec3, height: number, bottomHeight: number, wall: Wall | Slope, joineryData: any, orientation: SceneConstants.HandleSide){
        this._A = A;
        this._B = B;
        this._height = Math.abs(height);
        this._bottomHeight = Math.abs(bottomHeight);
        this._wall = wall;
        this._joineryType = joineryData.value;
        this._materialType = joineryData.materialType;
        this._thickness = joineryData.thickness;
        this._frosted = joineryData.frosted;
        this._model = joineryData.model;
        this._opened = joineryData.opened;
        this._rollingShutter = joineryData.rollingShutter;
        this._sliding = joineryData.sliding;
        this._wallInstallType = joineryData.wallInstallType;
        this._nbDoors = joineryData.nbDoors;
        this._handleSide = orientation;
        this._transom = joineryData.transom;
        this._transomHeight = Math.abs(joineryData.transomHeight);
        this._bottomTransom = joineryData.bottomTransom;
        this._bottomTransomHeight = Math.abs(joineryData.bottomTransomHeight);
        this._dimensions = joineryData.dimensions;
        this._savaneId = null;
    }

    get Type() {
        return this._type;
    }

    get A() : glMatrix.vec3 {
        if (this.Wall && this.Wall.Thickness < 0) {
            var result = glMatrix.vec3.clone(this._A);
            glMatrix.vec3.subtract(result, result, glMatrix.vec3.fromValues(this.Wall.N[0] * this.Wall.AsoluteThickness, this.Wall.N[1] * this.Wall.AsoluteThickness, 0));
            return result;
        }
        return this._A;
    }

    get B() : glMatrix.vec3 {
        if (this.Wall && this.Wall.Thickness < 0) {
            var result = glMatrix.vec3.clone(this._B);
            glMatrix.vec3.subtract(result, result, glMatrix.vec3.fromValues(this.Wall.N[0] * this.Wall.AsoluteThickness, this.Wall.N[1] * this.Wall.AsoluteThickness, 0));
            return result;
        }
        return this._B;
    }

    get WallThicknessOffset() : number {
        if (this.Wall.Thickness < 0) {
            return -this.Wall.AsoluteThickness / 2;
        } else {
            return this.Wall.AsoluteThickness / 2;
        }
    }

    get WallDirection() : glMatrix.vec3 {
        return this.Wall.D;
    }

    get BottomHeight() : number {
        return this._bottomHeight;
    }

    get TopHeight() : number {
        return this._bottomHeight + this._height;
    }

    get Height() : number {
        return this._height;
    }

    get JoineryType() : number {
        return this._joineryType;
    }

    get Center() : glMatrix.vec3 {
        return glMatrix.vec3.fromValues((this.A[0] + this.B[0]) / 2, (this.A[1] + this.B[1]) / 2, 0);
    }

    get Length() : number {
        var D = glMatrix.vec3.create();
        glMatrix.vec3.subtract(D, this.B, this.A);
        return glMatrix.vec3.length(D);
    }

    get Wall() : Wall | Slope {
        return this._wall;
    }

    get Model() : string {
        return this._model;
    }

    get MaterialType() : number {
        return this._materialType;
    }

    get Thickness() : number {
        return this._thickness;
    }

    get Frosted() : boolean {
        return this._frosted;
    }

    get Opened() : boolean {
        return this._opened;
    }

    get Sliding() : boolean {
        return this._sliding;
    }

    get NbDoors() : number {
        return this._nbDoors;
    }

    get Transom() : boolean {
        return this._transom;
    }

    get TransomHeight() : number {
        return this._transomHeight;
    }

    get BottomTransom() : boolean {
        return this._bottomTransom;
    }

    get BottomTransomHeight() : number {
        return this._bottomTransomHeight;
    }

    get WallInstallType() : number {
        return this._wallInstallType;
    }

    get RollingShutter() : boolean {
        return this._rollingShutter;
    }

    get HandleSide() : SceneConstants.HandleSide {
        return this._handleSide
    }

    set HandleSide(value: SceneConstants.HandleSide) {
        this._handleSide = value;
    }

    get Dimensions() : {width: number, length: number, height: number} {
        return this._dimensions;
    }

    get SavaneId() : number {
        return this._savaneId;
    }

    set SavaneId(value: number) {
        this._savaneId = value;
    }

}
