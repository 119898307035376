import { Command, CommandEnum, DeleteEntityCommand } from './CommandModule';
import { Events } from '../events';
import { eventsManager } from '../managers/EventsManager';
import { Entity } from '../SavaneJS';

export class DeleteEntitiesCommand  extends Command
{
    // Command list to execute and undo
    private _subCommands: Array<Command> = [];
    // Record the entity Array
    private _deletedEntitiesArray: Array<Entity>;

    constructor(deletedEntitiesArray: Array<Entity>) {
        super();
        this._deletedEntitiesArray = deletedEntitiesArray;
        // Parse all entity array passed to the constructor
        for (let i = 0; i < deletedEntitiesArray.length; i++) {
            // And create a delete entity command for each of them
            this._subCommands.push(new DeleteEntityCommand(deletedEntitiesArray[i], false));
        }
    }

    name(): string {
        return CommandEnum.DeleteEntitiesCommand;
    }

    needsRefreshHull(): boolean {
        for (let i = 0; i < this._subCommands.length; i++) {
            if (this._subCommands[i].needsRefreshHull()) {
                return (true);
            }
        }

        return (false);
    }

    // Undo the current command
    undo() {
        // Parse all commands created by the constructor and undo them all
        for (let i = 0; i < this._subCommands.length; i++) {
            this._subCommands[i].undo();
        }

        //Execute parent function
        super.undo();

        // Select deleted object at the end
        eventsManager.instance.dispatch(Events.CHANGE_EDITOR_SELECTION,
            {
                selection: this._deletedEntitiesArray,
                keepSelected: false,
                showTulip: false
            });
    }

    // Execute current command (redo)
    execute() {
        // Parse all commands created by the constructor and execute them all
        for (let i = 0; i < this._subCommands.length; i++) {
            this._subCommands[i].execute();
        }

        //Execute parent function
        super.execute();
    }
};
