import { Command, CommandEnum } from './CommandModule';
import { Events } from '../events';
import { eventsManager } from '../managers/EventsManager';
import { Entity, Floor, math } from '../SavaneJS';

export class TranslateFloorCommand extends Command
{
    // Delta X, Y of the translation
    private _deltaX: number;
    private _deltaY: number;
    // Floor of the translation
    private _floor: Floor;

    constructor (deltaX: number, deltaY: number, floor: Floor) {
       super();
       // Translation values
       this._deltaX = deltaX;
       this._deltaY = deltaY;
       this._floor = floor;
    }

    // Command name returned from a global enum
    name(): string {
        return CommandEnum.TranslateFloorCommand;
    }

    // Datas that allows the command to be executed
    execDatas(): any {
        return {
            deltaX: this._deltaX,
            deltaY: this._deltaY,
            floor: this._floor
        };
    }

    // Undo the current command
    undo() {
        // Apply inverse values of the values used for the execute method
        this.applyTranslation(-this._deltaX, -this._deltaY);

        super.undo();

        eventsManager.instance.dispatch(Events.PROJECT_RELOAD);
    }

    // Execute current command (redo), call the applyTranslation method with the translation values initialised at start
    execute() {
        this.applyTranslation(this._deltaX, this._deltaY);

        super.execute();

        eventsManager.instance.dispatch(Events.PROJECT_RELOAD);
    }

    // Method that applies a translation to the entire floor
    applyTranslation(deltaX: number, deltaY: number) {
        // Loop variable
        let i: number;

        // Translate walls
        for (i = 0; i < this._floor.walls.length; i++) {
            // Current wall to translate
            let wall = this._floor.walls[i];
            // Begin and end coordinates
            let begin = wall.begin;
            let end = wall.end;

            // Create final begin/end vectors
            let newBegin = math.vec3.create();
            let newEnd = math.vec3.create();

            // Move the wall
            math.vec3.set(newBegin, begin[0] + deltaX, begin[1] + deltaY, begin[2]);
            math.vec3.set(newEnd, end[0] + deltaX, end[1] + deltaY, end[2]);

            // Set new value for point of wall
            wall.begin = newBegin;
            wall.end = newEnd;

            // Redraw Node
            eventsManager.instance.dispatch(Events.REDRAW_GRAPHICAL_ENTITY,
                {
                    entity: wall
                });

            // Redraw joineries
            for (let j = 0; j < wall.joineries.length; j++) {
                // Redraw Node
                eventsManager.instance.dispatch(Events.REDRAW_GRAPHICAL_ENTITY,
                    {
                        entity: wall.joineries[j]
                    });
            }
        }

        // Redraw rooms
        for (i = 0; i < this._floor.rooms.length; i++) {
            eventsManager.instance.dispatch(Events.REDRAW_GRAPHICAL_ROOM,
                {
                    entity: this._floor.rooms[i]
                });
        }
        // Translate tech elements
        for (i = 0; i < this._floor.technicalElementsWithStaircases.length; i++) {
            this.translateEntity(this._floor.technicalElementsWithStaircases[i], deltaX, deltaY);
        }
        // Translate arrangementObjects
        for (i = 0; i < this._floor.arrangementObjects.length; i++) {
            if (!this._floor.arrangementObjects[i].parent.isArrangementObjectEntity() && !this._floor.arrangementObjects[i].parent.isArrangementGroupEntity()&& !this._floor.arrangementObjects[i].parent.isArrangementZoneEntity()) {
                this.translateEntity(this._floor.arrangementObjects[i], deltaX, deltaY);
            }
        }
        // Translate arrangementGroups
        for (i = 0; i < this._floor.arrangementGroups.length; i++) {
            if (!this._floor.arrangementGroups[i].parent.isArrangementObjectEntity() && !this._floor.arrangementGroups[i].parent.isArrangementGroupEntity() && !this._floor.arrangementGroups[i].parent.isArrangementZoneEntity()) {
                this.translateEntity(this._floor.arrangementGroups[i], deltaX, deltaY);
            }
        }
        // Translate renderCameras
        for (i = 0; i < this._floor.renderCameras.length; i++) {
            this.translateEntity(this._floor.renderCameras[i], deltaX, deltaY);
        }
        // Translate geometryPrimitives
        for (i = 0; i < this._floor.geometryPrimitives.length; i++) {
            this.translateEntity(this._floor.geometryPrimitives[i], deltaX, deltaY);
        }
    }

    // Translate an entity by deltaX, deltaY mm
    translateEntity(entity: Entity, deltaX: number, deltaY: number) {
        let pos = entity.position;

        pos[0] += deltaX;
        pos[1] += deltaY;

        entity.position = pos;

        // Redraw Node
        eventsManager.instance.dispatch(Events.REDRAW_GRAPHICAL_ENTITY,
            {
                entity: entity
            });
    }
};
