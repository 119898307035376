import { Command, CommandEnum } from './CommandModule';
import { Events } from '../events';
import { eventsManager } from '../managers/EventsManager';
import { Entity, Floor, math } from '../SavaneJS';

declare var Sentry;

export class DeleteEntityCommand  extends Command
{
    private _deletedEntity: Entity;
    private _deletedEntityParent: Entity;
    private _deletedEntityFloor: Floor;

    private _keepCameraExcludedIds: boolean;
    
    constructor(deletedEntity: Entity, keepCameraExcludedIds: boolean) {
        super();
        // Store the deleted object entity
        this._deletedEntity = deletedEntity;
        // Stode the deleted object entity parent
        this._deletedEntityParent = deletedEntity.parent;
        // Store the deleted entity floor
        this._deletedEntityFloor = deletedEntity.floor;

        // Clean excluded ids or not
        this._keepCameraExcludedIds = keepCameraExcludedIds;

// MOUCHARD A RETIRER
        if (!deletedEntity.parent) {
            try {
                (deletedEntity.parent as any).forceCrash = "Sentry Log";
            } catch (error) {
                Sentry.captureException(error);
            }
        }
        if (!deletedEntity.floor) {
            try {
                (deletedEntity.floor as any).forceCrash = "Sentry Log";
            } catch (error) {
                Sentry.captureException(error);
            }
        }
// MOUCHARD A RETIRER
    };

    // Command name returned from a global enum
    name(): string {
        return CommandEnum.DeleteEntityCommand;
    }

    // Datas that allows the command to be executed
    execDatas(): any {
        return {
            id: this._deletedEntity.id,
            type: this._deletedEntity.entityType,
            entity: this._deletedEntity
        };
    }

    needsRefreshHull(): boolean {
        return (this.doesTreeContainFurnitureFinishes(this._deletedEntity) || (this._deletedEntity.isWorktopEntity()));
    }

    // Undo the current command
    undo() {
        let toLocal = math.mat4.create();
        math.mat4.invert(toLocal, this._deletedEntityParent.transform.globalMatrix);
        let world = this._deletedEntity.transform.globalMatrix;
        let matrix = math.mat4.create();
        math.mat4.multiply(matrix, toLocal, world);
        math.mat4.copy(this._deletedEntity.transform.localMatrix, matrix);
        // Add the saved entity as a child of its past parent
        this._deletedEntityParent.addChild(this._deletedEntity);
        // If entity attached to floor or room, add its display to rhe graphical engine
        if (this._deletedEntityParent.isFloorEntity() || this._deletedEntityParent.isRoomEntity()) {
            eventsManager.instance.dispatch(Events.ADD_GRAPHICAL_ENTITY,
                {
                    entity: this._deletedEntity,
                });
        }

        // Special treatment for arrangement groups
        let entity = this._deletedEntityParent;
        while (entity.isArrangementGroupEntity()) {
            entity.recenter();
            entity = entity.parent;
        }

        // Select deleted object at the end
        eventsManager.instance.dispatch(Events.CHANGE_EDITOR_SELECTION,
            {
                selection: [this._deletedEntity],
                keepSelected: false,
                showTulip: false
            });

        // Execute parent function
        super.undo();
    }

    removeEntityFromCamerasExcludedObjectIds(id) {
        let cameras = this._deletedEntityFloor.renderCameras;
        for (let i = 0; i < cameras.length; i++) {
            let camera = cameras[i];
            let index = camera.excludedObjectIds.indexOf(id);
            if (index !== -1) {
                camera.excludedObjectIds.splice(index, 1);
            }
        }
    }

    // Execute current command (redo)
    execute() {
        // Unselect selection if needed
        eventsManager.instance.dispatch(Events.UNSELECT_EDITOR_SELECTION,
            {
                unselectedEntity: this._deletedEntity,
            });

        math.mat4.copy(this._deletedEntity.transform.localMatrix, this._deletedEntity.transform.globalMatrix);
        // Delete entity from its parent but keep the parent it somewhere for undo
        this._deletedEntityParent.deleteChild(this._deletedEntity.id);
        this._deletedEntity.parent = null;

        eventsManager.instance.dispatch(Events.REMOVE_GRAPHICAL_ENTITY,
            {
                entity: this._deletedEntity,
            });

        // Special treatment for arrangement groups
        let entity = this._deletedEntityParent;
        while (entity !== null && entity.isArrangementGroupEntity()) {
            entity.recenter();
            // Redraw all parents (their size might have changed due to recenter call above)
            eventsManager.instance.dispatch(Events.REDRAW_GRAPHICAL_ENTITY,
                {
                    entity: entity,
                });

            entity = entity.parent;
        }

        // If the entity is an arrangement object, free its memory explicitely
        if (this._deletedEntity.isArrangementObjectEntity() && !this._keepCameraExcludedIds) {
            this.removeEntityFromCamerasExcludedObjectIds(this._deletedEntity.id);
        }

        // Execute parent function
        super.execute();
    }
};
