import { HermitePoint } from "./hermitePoint";
import * as glMatrix from 'gl-matrix';

export class HermiteCurve
{
    private _begin: HermitePoint;
    private _end: HermitePoint;

    constructor(begin: HermitePoint, end: HermitePoint) {
        this._begin = begin;
        this._end = end;
    }

    get Begin() {
        return this._begin;
    }

    get End() {
        return this._end;
    }

    set Begin(value) {
        this._begin = value;
    }

    set End(value) {
        this._end = value;
    }

    Evaluate(t: number) : glMatrix.vec3 {
        const h00 = 2 * t * t * t - 3 * t * t + 1;
        const h10 = t * t * t  - 2 * t * t + t;
        const h01 = -2 * t * t * t + 3 * t * t;
        const h11 = t * t * t - t * t;

        let h00p0 = glMatrix.vec3.create();
        glMatrix.vec3.scale(h00p0, this.Begin.Position, h00);
        let h10m0 = glMatrix.vec3.create();
        glMatrix.vec3.scale(h10m0, this.Begin.Tangent, h10);
        let h01p1 = glMatrix.vec3.create();
        glMatrix.vec3.scale(h01p1, this.End.Position, h01);
        let h11m1 = glMatrix.vec3.create();
        glMatrix.vec3.scale(h11m1, this.End.Tangent, h11);

        let result = glMatrix.vec3.create();
        glMatrix.vec3.add(result, h00p0, h10m0);
        glMatrix.vec3.add(result, result, h01p1);
        glMatrix.vec3.add(result, result, h11m1);
        return result;
    }
}
