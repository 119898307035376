import { Floor, ArrangementGroup, Transform, arrangementManager } from '../../SavaneJS';
import { TemporaryEntity } from './TemporaryEntity';

/**
 * TemporaryArrangementGroup is used for arrangementGroup edition
 */
export class TemporaryArrangementGroup extends TemporaryEntity {

    private _transform: Transform;

    constructor(arrangementGroup: ArrangementGroup) {
        super(arrangementGroup);

        this._transform = new Transform(null);
        this._transform.clone(arrangementGroup.transform);
    }

    computeValidity(floor: Floor) {
        this._isValid = true;
    }

    get transform(): Transform {
        return this._transform;
    }
}
