import { glMatrix } from "../../../math/Math";
import { Joinery } from "./Joinery";

export class GroundJoinery extends Joinery {

    constructor(A: glMatrix.vec3, B: glMatrix.vec3, joineryData){
        super(A, B, 0, A[2], null, joineryData, null);
        this._type = "GROUND";
    }

    get Center() : glMatrix.vec3 {
        return glMatrix.vec3.fromValues((this.A[0] + this.B[0]) / 2, (this.A[1] + this.B[1]) / 2, (this.A[2] + this.B[2]) / 2);
    }

}