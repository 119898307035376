import { SceneConstants } from "../SceneConstants";
import { TemporaryStaircase } from "../temporary/TemporaryStaircase";
import { Staircase } from "./Staircase";

export class CircularStaircase extends Staircase {
    
    private _revolutionAngle: number = 360;

    constructor(id: number) {
        super(id, SceneConstants.StaircaseType.circular, 1500, 1500, 2500, null);
        this._shapeType = SceneConstants.ShapeType.ellipse;
    }

    // Getters and setters for revolution angle of circular staircase
    get revolutionAngle() : number {
        if (this.temporary === null) {
            return this._revolutionAngle;
        } else {
            return (this.temporary as TemporaryStaircase).revolutionAngle;
        }
    }

    set revolutionAngle(value: number) {
        if (this.temporary === null) {
            this._revolutionAngle = value;
        } else {
            (this.temporary as TemporaryStaircase).revolutionAngle = value;
        }
    }

    saveAndEndTemporary() : void {
        let revolutionAngle = this.revolutionAngle;

        super.saveAndEndTemporary();

        this.revolutionAngle = revolutionAngle;
    }
}
