import { Command, CommandEnum } from './CommandModule';
import { Events } from '../events';
import { eventsManager } from '../managers/EventsManager';
import { EntityFactory, GeometryPrimitive, Box, Cylinder, HalfArc, Hood, Oval, Sphere, Triangle, SceneConstants, math } from '../SavaneJS';

export class EditGeometryPrimitiveCommand extends Command
{
    // To store current and modified geometry primitive
    private _currentGeometryPrimitive: GeometryPrimitive;
    private _modifiedGeometryPrimitive: GeometryPrimitive;

    constructor(currentGeometryPrimitive: GeometryPrimitive) {
        super();
        // Clone current geometry primitive so it copies the temporary fields into the cloned geometry primitive entity
        this._modifiedGeometryPrimitive = EntityFactory.cloneEntity(currentGeometryPrimitive, false) as GeometryPrimitive;
        // End the geometry primitive temporary so it drops the temporary data
        currentGeometryPrimitive.endTemporary();
        // Save the current geometry primitive for future data exchange
        this._currentGeometryPrimitive = currentGeometryPrimitive;
    }

    // Command name returned from a global enum
    name(): string {
        return CommandEnum.EditGeometryPrimitiveCommand;
    }

    // Datas that allows the command to be executed
    execDatas(): any {
        return {
            id: this._currentGeometryPrimitive.id,
            type: this._currentGeometryPrimitive.entityType
        };
    }

    // Undo the current command
    undo() {
        // Execute the current command, the geometry primitive structure keeps the changes back and forth
        this.execute();

        //Execute parent function
        super.undo();
    }

    // Execute current command (redo)
    execute() {
        // Get its transform and exchange it with the _modifiedGeometryPrimitive one
        let tmpLocalMatrix = math.mat4.create();
        math.mat4.copy(tmpLocalMatrix, this._currentGeometryPrimitive.transform.localMatrix);
        math.mat4.copy(this._currentGeometryPrimitive.transform.localMatrix, this._modifiedGeometryPrimitive.transform.localMatrix);
        math.mat4.copy(this._modifiedGeometryPrimitive.transform.localMatrix, tmpLocalMatrix);

        let tmpHeight = this._currentGeometryPrimitive.height;
        this._currentGeometryPrimitive.height = this._modifiedGeometryPrimitive.height;
        this._modifiedGeometryPrimitive.height = tmpHeight;
        
        let tmpHideInAxo = this._currentGeometryPrimitive.hideInAxo;
        this._currentGeometryPrimitive.hideInAxo = this._modifiedGeometryPrimitive.hideInAxo;
        this._modifiedGeometryPrimitive.hideInAxo = tmpHideInAxo;

        let tmpCutByJoineries = this._currentGeometryPrimitive.cutByJoineries;
        this._currentGeometryPrimitive.cutByJoineries = this._modifiedGeometryPrimitive.cutByJoineries;
        this._modifiedGeometryPrimitive.cutByJoineries = tmpCutByJoineries;

        let tmpWidth, tmpLength, tmpDiameter, tmpTopWidth, tmpTopLength, tmpShapeType;

        // Get its specific parameter and exchange it with the _modifiedGeometryPrimitive one
        switch(this._currentGeometryPrimitive.primitiveType) {
            case SceneConstants.GeometryPrimitiveType.box:
                tmpWidth = (this._currentGeometryPrimitive as Box).width;
                (this._currentGeometryPrimitive as Box).width = (this._modifiedGeometryPrimitive as Box).width;
                (this._modifiedGeometryPrimitive as Box).width = tmpWidth;

                tmpLength = (this._currentGeometryPrimitive as Box).length;
                (this._currentGeometryPrimitive as Box).length = (this._modifiedGeometryPrimitive as Box).length;
                (this._modifiedGeometryPrimitive as Box).length = tmpLength;
                break;

            case SceneConstants.GeometryPrimitiveType.cylinder:
                tmpDiameter = (this._currentGeometryPrimitive as Cylinder).diameter;
                (this._currentGeometryPrimitive as Cylinder).diameter = (this._modifiedGeometryPrimitive as Cylinder).diameter;
                (this._modifiedGeometryPrimitive as Cylinder).diameter = tmpDiameter;
                break;

            case SceneConstants.GeometryPrimitiveType.halfarc:
                tmpWidth = (this._currentGeometryPrimitive as HalfArc).width;
                (this._currentGeometryPrimitive as HalfArc).width = (this._modifiedGeometryPrimitive as HalfArc).width;
                (this._modifiedGeometryPrimitive as HalfArc).width = tmpWidth;

                tmpLength = (this._currentGeometryPrimitive as HalfArc).length;
                (this._currentGeometryPrimitive as HalfArc).length = (this._modifiedGeometryPrimitive as HalfArc).length;
                (this._modifiedGeometryPrimitive as HalfArc).length = tmpLength;
                break;

            case SceneConstants.GeometryPrimitiveType.hood:
                tmpWidth = (this._currentGeometryPrimitive as Hood).width;
                (this._currentGeometryPrimitive as Hood).width = (this._modifiedGeometryPrimitive as Hood).width;
                (this._modifiedGeometryPrimitive as Hood).width = tmpWidth;

                tmpLength = (this._currentGeometryPrimitive as Hood).length;
                (this._currentGeometryPrimitive as Hood).length = (this._modifiedGeometryPrimitive as Hood).length;
                (this._modifiedGeometryPrimitive as Hood).length = tmpLength;

                tmpTopWidth = (this._currentGeometryPrimitive as Hood).topWidth;
                (this._currentGeometryPrimitive as Hood).topWidth = (this._modifiedGeometryPrimitive as Hood).topWidth;
                (this._modifiedGeometryPrimitive as Hood).topWidth = tmpTopWidth;

                tmpTopLength = (this._currentGeometryPrimitive as Hood).topLength;
                (this._currentGeometryPrimitive as Hood).topLength = (this._modifiedGeometryPrimitive as Hood).topLength;
                (this._modifiedGeometryPrimitive as Hood).topLength = tmpTopLength;

                tmpShapeType = (this._currentGeometryPrimitive as Hood).shapeType;
                (this._currentGeometryPrimitive as Hood).shapeType = (this._modifiedGeometryPrimitive as Hood).shapeType;
                (this._modifiedGeometryPrimitive as Hood).shapeType = tmpShapeType;
                break;

            case SceneConstants.GeometryPrimitiveType.oval:
                tmpWidth = (this._currentGeometryPrimitive as Oval).width;
                (this._currentGeometryPrimitive as Oval).width = (this._modifiedGeometryPrimitive as Oval).width;
                (this._modifiedGeometryPrimitive as Oval).width = tmpWidth;

                tmpLength = (this._currentGeometryPrimitive as Oval).length;
                (this._currentGeometryPrimitive as Oval).length = (this._modifiedGeometryPrimitive as Oval).length;
                (this._modifiedGeometryPrimitive as Oval).length = tmpLength;
                break;

            case SceneConstants.GeometryPrimitiveType.sphere:
                tmpDiameter = (this._currentGeometryPrimitive as Sphere).diameter;
                (this._currentGeometryPrimitive as Sphere).diameter = (this._modifiedGeometryPrimitive as Sphere).diameter;
                (this._modifiedGeometryPrimitive as Sphere).diameter = tmpDiameter;
                break;

            case SceneConstants.GeometryPrimitiveType.triangle:
                tmpWidth = (this._currentGeometryPrimitive as Triangle).width;
                (this._currentGeometryPrimitive as Triangle).width = (this._modifiedGeometryPrimitive as Triangle).width;
                (this._modifiedGeometryPrimitive as Triangle).width = tmpWidth;

                tmpLength = (this._currentGeometryPrimitive as Oval).length;
                (this._currentGeometryPrimitive as Triangle).length = (this._modifiedGeometryPrimitive as Triangle).length;
                (this._modifiedGeometryPrimitive as Triangle).length = tmpLength;
                break;
        }

        // Parent objet to a room if any
        this.parentEntityToRoom(this._currentGeometryPrimitive);

        // Execute parent function
        super.execute();

        // Select object at the end
        eventsManager.instance.dispatch(Events.CHANGE_EDITOR_SELECTION,
            {
                selection: [this._currentGeometryPrimitive],
                keepSelected: false,
                showTulip: false
            });
    }
};
