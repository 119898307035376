import { Command, CommandEnum } from './CommandModule';
import { Events } from '../events';
import { eventsManager } from '../managers/EventsManager';
import { EntityFactory, Comment, math } from '../SavaneJS';

export class EditCommentCommand  extends Command
{
    // To store current and modified comments
    private _currentComment: Comment;
    private _modifiedComment: Comment;

    constructor(currentComment: Comment) {
        super();
        // Clone current comment so it copies the temporary fields into the cloned comment entity
        this._modifiedComment = EntityFactory.cloneEntity(currentComment, false) as Comment;
        // End the comment temporary so it drops the temporary data
        currentComment.endTemporary();
        // Save the current comment for future data exchange
        this._currentComment = currentComment;
    }

    // Command name returned from a global enum
    name(): string {
        return CommandEnum.EditCommentCommand;
    }

    // Undo the current command
    undo() {
        // Execute the current command, the comment structure keeps the changes back and forth
        this.execute();
    }

    // Execute current command (redo)
    execute() {
        // Get its position and exchange it with the savedComment one
        let tmpPosition = math.vec3.create();
        math.vec3.copy(tmpPosition, this._currentComment.position);
        this._currentComment.position = this._modifiedComment.position;
        this._modifiedComment.position = tmpPosition;

        // Get its text and exchange it with the savedComment one
        let tmpText = this._currentComment.text;
        this._currentComment.text = this._modifiedComment.text;
        this._modifiedComment.text = tmpText;

        let tmpImages = this._currentComment.images;
        this._currentComment.images = this._modifiedComment.images;
        this._modifiedComment.images = tmpImages;

        // Select object at the end
        eventsManager.instance.dispatch(Events.CHANGE_EDITOR_SELECTION,
            {
                selection: [this._currentComment],
                keepSelected: false,
                showTulip: false
            });
    }
};
