import { Command, CommandEnum, SetFloorHeightCommand } from './CommandModule';
import { Scene } from '../SavaneJS';

export class SetProjectFloorHeightCommand extends Command
{
    // Command list to execute and undo
    private _subCommands: Array<Command> = [];
    // New floor height to set over all project
    private _newHeight: number;

    constructor(newHeight: number, scene: Scene) {
        super();
        // New height values
        this._newHeight = newHeight;

        // Parse all floors
        for (let i = 0; i < scene.floors.length; i++) {
            // Create a SetFloorHeightCommand for each floor of the array
            let command = new SetFloorHeightCommand(newHeight, scene.floors[i])

            // Can't execute ?
            if (!command.canExecute()) {
                // Empty command array and break loop
                this._subCommands = [];
                break;
            }

            // And store the command into the commands array for undo
            this._subCommands.push(command);
        }
    }

    // Command name returned from a global enum
    name(): string {
        return CommandEnum.SetProjectFloorHeightCommand;
    }

    // Datas that allows the command to be executed
    execDatas(): any {
        return {
            height: this._newHeight
        };
    }

    // Undo the current command
    undo() {
        // Call set floor height undo for each command created by the constructor
        for (let i = 0; i < this._subCommands.length; i++) {
            this._subCommands[i].undo();
        }

        // Call parent undo to broadcast a global message
        super.undo();
    }

    // Execute current command (redo), call the set floor cornices command creates by the constructor
    execute() {
        // Execute all pushed commands (if any)
        for (let i = 0; i < this._subCommands.length; i++) {
            this._subCommands[i].execute();
        }

        // Call parent execute to broadcast a global message
        super.execute();
    }
};
