import { Scene, Floor, Sun, GeometryGroup, GeometryPrimitive, RenderCamera, ArrangementGroup, ArrangementObject, TechnicalElement, Staircase, QuarterStaircase, DoubleQuarterStaircase, SceneConstants, SavaneMath, math, Comment } from "../SavaneJS";

/**
 * ArrangementManager is a singleton managing all class extending Iarrangements of the current floor
 *
 * @constructor
 */
class ArrangementManager {
    constructor() {
    }

    /**
     * Getter for render camera of the current floor
     */
    getRenderCamera(cameraId: number, scene: Scene) : RenderCamera | null {
        let floors = scene.floors;
        for (let i = 0; i < floors.length; ++i) {
            let floor = floors[i];
            for (let j = 0; j < floor.renderCameras.length; ++j) {
                if (floor.renderCameras[j].id === cameraId) {
                    return floor.renderCameras[j];
                }
            }
        }
        return null;
    }

    /**
     * Getter for render camera of the current floor
     */
    getComment(commentId: number, scene: Scene) : Comment | null {
        let floors = scene.floors;
        for (let i = 0; i < floors.length; ++i) {
            let floor = floors[i];
            let comments = floor.comments;
            for (let j = 0; j < comments.length; ++j) {
                if (comments[j].id === commentId) {
                    return comments[j];
                }
            }
        }
        return null;
    }

    /**
     * Getter for geometry primitive of the current floor
     */
    getGeometryPrimitive(geomPrimId: number, scene: Scene) : GeometryPrimitive | null {
        let floors = scene.floors;
        for (let i = 0; i < floors.length; ++i) {
            let floor = floors[i];
            for (let j = 0; j < floor.geometryPrimitives.length; j++) {
                if (floor.geometryPrimitives[j].id === geomPrimId) {
                    return floor.geometryPrimitives[j];
                }
            }
        }
        return null;
    }

    /**
     * Getter for the sun of the current floor
     */
    getSun(sunId: number, scene: Scene) : Sun | null {
        let floors = scene.floors;
        for (let i = 0; i < floors.length; ++i) {
            let floor = floors[i];
            let sun = floor.getChild(sunId) as Sun;
            if (sun) {
                return sun;
            }
        }
        return null;
    }

    /**
     * Getter for the arrangement of id
     */
    getArrangement(arrangementId : number, scene: Scene) : (ArrangementObject | ArrangementGroup) | null {
        let findArrangement = function (iArrangementsArray : Array<ArrangementObject | ArrangementGroup>, arrangementId: number) : (ArrangementObject | ArrangementGroup) | null {
            for (let i = 0; i < iArrangementsArray.length; i++) {
                if (iArrangementsArray[i].id === arrangementId) {
                    return iArrangementsArray[i];
                }
                if (iArrangementsArray[i].isArrangementGroupEntity()) {
                    let arrangement = findArrangement((iArrangementsArray[i] as ArrangementGroup).iArrangements, arrangementId);
                    if (arrangement !== null) {
                        return arrangement;
                    }
                }
            }
            return null;
        };

        let floors = scene.floors;
        let currentFloorIArrangements = [];
        for (let i = 0; i < floors.length; ++i) {
            currentFloorIArrangements = currentFloorIArrangements.concat(floors[i].iArrangements);
        }
        return findArrangement(currentFloorIArrangements, arrangementId);
    }

    /**
     * Getter for the technicalElement of id
     *
     * @param {Number} technicalElementId
     */
    getTechnicalElement(technicalElementId: number, scene: Scene) : TechnicalElement | null {
        let floors = scene.floors;
        for (let i = 0; i < floors.length; ++i) {
            let floor = floors[i];
            let technicalElements = floor.technicalElementsWithStaircases;
            for (let j = 0; j < technicalElements.length; j++) {
                if (technicalElements[j].id === technicalElementId) {
                    return technicalElements[j];
                }
            }
        }
        return null;
    }

    /**
     * Getter for the TechnicalElement at the position given in parameters (the highest one)
     *
     * @param {*} position
     * @returns {*}
     */
    getTechnicalElementAtPosition(position: math.vec3, floor: Floor) : TechnicalElement | null {
        let techElements = floor.technicalElementsWithStaircases;
        let res = null;
        for (let i = 0; i < techElements.length; i++) {
            let boundingBox = null;
            if (!techElements[i].isStaircaseEntity()) {
                boundingBox = techElements[i].boundingBox;
            } else {
                boundingBox = (techElements[i] as Staircase).realBoundingBox;
            }
            if (SavaneMath.isInPoly(position, boundingBox)) {
                if (res !== null) {
                    if (res.position[2] < techElements[i].position[2]) {
                        res = techElements[i];
                    }
                } else {
                    res = techElements[i];
                }
            }
        }
        return res;
    }

    /**
     * Getter for the geometryprimitive at the position given in parameters (the highest one)
     *
     * @param {*} position
     * @returns {*}
     */
    getGeometryPrimitiveAtPosition(position: math.vec3, floor: Floor) : GeometryPrimitive | null {
        let geomPrims = floor.geometryPrimitives;
        let res = null;
        for (let i = 0; i < geomPrims.length; i++) {
            let boundingBox = geomPrims[i].boundingBox;

            if (SavaneMath.isInPoly(position, boundingBox)) {
                if (res !== null) {
                    if (res.floorHeight + res.height < geomPrims[i].floorHeight + geomPrims[i].floorHeight) {
                        res = geomPrims[i];
                    }
                } else {
                    res = geomPrims[i];
                }
            }
        }
        return res;
    }

    /**
     * Getter for the arrangementGroup at the position given in parameters (the highest one)
     *
     * @param {*} position
     * @returns {*}
     */
    getGeometryGroupAtPosition(position: math.vec3, floor: Floor) : GeometryGroup | null {
        let groups = floor.getChildren([SceneConstants.EntityType.GeometryGroup]) as Array<GeometryGroup>;
        let res = null;

        for (let i = 0; i < groups.length; i++) {
            let boundingBox = groups[i].boundingBox;

            if (SavaneMath.isInPoly(position, boundingBox)) {
                if (res !== null) {
                    if (res.maxHeight < groups[i].maxHeight) {
                        res = groups[i];
                    }
                } else {
                    res = groups[i];
                }
            }
        }

        return res;
    }

    /**
     * Getter for the arrangementObject or arrangementGroup at the position given in parameters (the highest one)
     *
     * @param {*} position
     * @param {ArrangementGroup} arrangementGroupParent if null find in floor , else find in arrangement group
     * @returns {*}
     */
    getArrangementAtPosition(position: math.vec3, arrangementGroupParent: ArrangementGroup, floor: Floor) {
        let arrangements = [];
        if (arrangementGroupParent === null || arrangementGroupParent === undefined || arrangementGroupParent.iArrangements === undefined) {
            arrangements = floor.getChildren([SceneConstants.EntityType.ArrangementGroup, SceneConstants.EntityType.ArrangementObject], [SceneConstants.EntityType.Room]); //floor.iArrangements;
        } else {
            arrangements = arrangementGroupParent.iArrangements;
        }

        let res = null;
        for (let i = 0; i < arrangements.length; i++) {
            let boundingBox = arrangements[i].getHooverBox(30); //boundingBox;
            if ((arrangements[i].isArrangementGroupEntity() || arrangements[i].isArrangementObjectEntity()) && SavaneMath.isInPoly(position, boundingBox)) {
                if (res !== null) {
                    if (res.maxHeight < arrangements[i].maxHeight) {
                        res = arrangements[i];
                    }
                } else {
                    res = arrangements[i];
                }
            }
        }
        return res;
    }

    /**
     * Getter for the arrangementObject at the position given in parameters (the highest one)
     *
     * @param {*} position
     * @returns {*}
     */
    getArrangementObjectAtPosition(position: math.vec3, floor: Floor) : ArrangementObject | null {
        let arrangementObjects = floor.getChildren([SceneConstants.EntityType.ArrangementObject]) as Array<ArrangementObject>;
        let res = null;

        for (let i = 0; i < arrangementObjects.length; i++) {
            if (!arrangementObjects[i].isGhost()) {
                let boundingBox = arrangementObjects[i].boundingBox;

                if (SavaneMath.isInPoly(position, boundingBox)) {
                    if (res !== null) {
                        if (res.position[2] < arrangementObjects[i].position[2]) {
                            res = arrangementObjects[i];
                        }
                    } else {
                        res = arrangementObjects[i];
                    }
                }
            }
        }

        return res;
    }

    /**
     * Getter for the arrangementObjects at the position given in parameters (all of them)
     *
     * @param {*} position
     * @returns {*}
     */
    getArrangementObjectsAtPosition(position: math.vec3, floor: Floor) : Array<ArrangementObject> {
        let arrangementObjects = floor.getChildren([SceneConstants.EntityType.ArrangementObject]) as Array<ArrangementObject>;
        let res = new Array<ArrangementObject>();

        for (let i = 0; i < arrangementObjects.length; i++) {
            if (!arrangementObjects[i].isGhost()) {
                let boundingBox = arrangementObjects[i].boundingBox;

                if (SavaneMath.isInPoly(position, boundingBox)) {
                    res.push(arrangementObjects[i]);
                }
            }
        }

        return res;
    }

    /**
     * Getter for the arrangementGroup at the position given in parameters (the highest one)
     *
     * @param {*} position
     * @returns {*}
     */
    getArrangementGroupAtPosition(position: math.vec3, floor: Floor) : ArrangementGroup | null {
        let arrangementGroups = floor.getChildren([SceneConstants.EntityType.ArrangementGroup]) as Array<ArrangementGroup>;
        let res = null;

        for (let i = 0; i < arrangementGroups.length; i++) {
            let boundingBox = arrangementGroups[i].boundingBox;

            if (SavaneMath.isInPoly(position, boundingBox)) {
                if (res !== null) {
                    if (res.maxHeight < arrangementGroups[i].maxHeight) {
                        res = arrangementGroups[i];
                    }
                } else {
                    res = arrangementGroups[i];
                }
            }
        }

        return res;
    }

    /**
     * Test whether the arrangement is valid
     *
     * @param {ArrangementObject} testedArrangement
     * @returns {*}
     */
    isArrangementValid(testedArrangement: ArrangementObject) : boolean {
        if (testedArrangement.stretchability !== undefined) {
            if (testedArrangement.stretchability.x !== undefined) {
                if (testedArrangement.stretchability.x.isStretchable) {
                    if (testedArrangement.stretchability.x.min > testedArrangement.length) {
                        return false;
                    }
                    if (testedArrangement.stretchability.x.max < testedArrangement.length) {
                        return false;
                    }
                }
            }

            if (testedArrangement.stretchability.y !== undefined) {
                if (testedArrangement.stretchability.y.isStretchable) {
                    if (testedArrangement.stretchability.y.min > testedArrangement.width) {
                        return false;
                    }
                    if (testedArrangement.stretchability.y.max < testedArrangement.width) {
                        return false;
                    }
                }
            }

            if (testedArrangement.stretchability.z !== undefined) {
                if (testedArrangement.stretchability.z.isStretchable) {
                    if (testedArrangement.stretchability.z.min > testedArrangement.height) {
                        return false;
                    }
                    if (testedArrangement.stretchability.z.max < testedArrangement.height) {
                        return false;
                    }
                }
            }
        }

        return true;
    }

    /**
     * Test whether the technicalElement || arrangement || StaireCase is valid
     *
     * @param {ArrangementObject | TechnicalElement} testedObject
     * @returns {*}
     */
    isObjectValid(testedObject: ArrangementObject | TechnicalElement , floor: Floor) : boolean {
        if (testedObject.isTechnicalElementEntity()) {
            return this.isTechnicalElementValid(testedObject as TechnicalElement, floor);
        } else if (testedObject.isStaircaseEntity()) {
            return this.isStaircaseValid(testedObject as Staircase);
        } else if (testedObject.isArrangementObjectEntity() || testedObject.isArrangementGroupEntity()) {
            return this.isArrangementValid(testedObject as ArrangementObject);
        }
        return false;
    }

    /**
     * Test whether the technicalElement is valid
     *
     * @param {TechnicalElement} testedTechnicalElement
     * @returns {*}
     */
    isTechnicalElementValid(testedTechnicalElement: TechnicalElement, floor: Floor) : boolean {
        let walls = floor.walls;

        if (testedTechnicalElement.length > SceneConstants.TechElementMaxLength[testedTechnicalElement.objectId] || testedTechnicalElement.length < SceneConstants.TechElementMinLength[testedTechnicalElement.objectId]) {
            return false;
        }
        if (testedTechnicalElement.width > SceneConstants.TechElementMaxWidth[testedTechnicalElement.objectId] || testedTechnicalElement.width < SceneConstants.TechElementMinWidth[testedTechnicalElement.objectId]) {
            return false;
        }

        if (testedTechnicalElement.height > SceneConstants.TechElementMaxHeight[testedTechnicalElement.objectId] || testedTechnicalElement.height < SceneConstants.TechElementMinHeight[testedTechnicalElement.objectId]) {
            return false;
        }

        if (testedTechnicalElement.objectId !== SceneConstants.TechnicalElementType.beam && testedTechnicalElement.objectId !== SceneConstants.TechnicalElementType.fireplace && testedTechnicalElement.objectId !== SceneConstants.TechnicalElementType.pole && testedTechnicalElement.objectId !== SceneConstants.TechnicalElementType.frame && testedTechnicalElement.objectId !== SceneConstants.TechnicalElementType.guardrail) {
            for (let i = 0; i < walls.length; i++) {
                if (testedTechnicalElement.crossWall(walls[i])) {
                    return false;
                }
            }
        }

        return true;
    }

    isStaircaseValid(testedStaircase: Staircase) : boolean {
        if (testedStaircase.objectId === SceneConstants.StaircaseType.doubleQuarterTurn) {
            if ((testedStaircase as DoubleQuarterStaircase).stepWidth > testedStaircase.width) {
                return false;
            }
            if ((testedStaircase as DoubleQuarterStaircase).stepWidth * 2 > testedStaircase.length) {
                return false;
            }
        }
        if (testedStaircase.objectId === SceneConstants.StaircaseType.quarterTurn) {
            if ((testedStaircase as QuarterStaircase).stepWidth > testedStaircase.width) {
                return false;
            }
            if ((testedStaircase as QuarterStaircase).stepWidth > testedStaircase.length) {
                return false;
            }
        }
        if (testedStaircase.length < 600 || testedStaircase.width < 300) {
            return false;
        }

        return true;
    }

    /**
     * Test whether all arrangement are valid
     *
     * @returns {*}
     */
    areArrangementsValid(floor: Floor) : boolean {
        let currentFloor = floor;
        for (let i = 0; i < currentFloor.arrangementObjects.length; i++) {
            if (!this.isArrangementValid(currentFloor.arrangementObjects[i])) {
                return false;
            }
        }
        return true;
    }
}

export let arrangementManager = new ArrangementManager();
