import { Command, CommandEnum, TranslateFloorCommand } from './CommandModule';
import { Scene } from '../SavaneJS';

export class TranslateProjectCommand extends Command
{
    // Command list to execute and undo
    private _subCommands: Array<Command> = [];
    // Delta X, Y of the translation
    private _deltaX: number;
    private _deltaY: number;

    constructor(deltaX: number, deltaY: number, scene: Scene) {
        super();
        // Translation values
        this._deltaX = deltaX;
        this._deltaY = deltaY;

        // Parse all floors
        for (let i = 0; i < scene.floors.length; i++) {
            // Create a TranslateFloorCommand for each floor of the array
            this._subCommands.push(new TranslateFloorCommand(deltaX, deltaY, scene.floors[i]));
        }
    }

    // Command name returned from a global enum
    name(): string {
        return CommandEnum.TranslateProjectCommand;
    }

    // Datas that allows the command to be executed
    execDatas(): any {
        return {
            deltaX: this._deltaX,
            deltaY: this._deltaY
        };
    }

    // Undo the current command
    undo() {
        // Call translate floor undo for each command created by the constructor
        for (let i = 0; i < this._subCommands.length; i++) {
            this._subCommands[i].undo();
        }

        // Call parent undo to broadcast a global message
        super.undo();
    }

    // Execute current command (redo), call the translate floor commands created by the constructor
    execute() {
        // Parse all commands
        for (let i = 0; i < this._subCommands.length; i++) {
            this._subCommands[i].execute();
        }

        // Call parent execute to broadcast a global message
        super.execute();
    }
}
