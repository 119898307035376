import { AMFurnitureFinishesReference } from '../components/AMFurnitureFinishesReference';
import { SceneConstants } from '../../SavaneJS';
import * as AssetManagerServices from "@rhinov/asset-manager-service"

/**
 * This class will get asset manager reference to filler/plinth/endPanel
 */
export class AMComponentFactory {

    private static _loadedAssets = {
        fillers: [],
        endPanels: [],
        plinths: []
    };

    private static _coatingsLoaded = [];
    private static _coatingLoading = null;
    private static _AMCallbacks = [];

    constructor() {
    }

    /**
     * return asset loaded locally
     *
     * @returns {{fillers: Array, endPanels: Array, plinths: Array}|*}
     */
    static get loadedAssets() {
        return AMComponentFactory._loadedAssets;
    }

    static assetManagerRequest(coatingId, callback) {
        let i, j, found;

        if (coatingId && coatingId.length > 0) {
            if (AMComponentFactory._coatingsLoaded.indexOf(coatingId) === -1) {
                if (AMComponentFactory._coatingLoading === coatingId) {
                    //the coating is already being load
                    AMComponentFactory._AMCallbacks.push(callback);
                } else {
                    //the coating is yet to be loaded
                    AMComponentFactory._coatingLoading = coatingId;
                    AMComponentFactory._AMCallbacks.push(callback);

                    //get plinth
                    AssetManagerServices.getAssets(AssetManagerServices._ASSET_TYPE.ARRANGEMENTS,
                        '&q.objectType._id.eq=' + SceneConstants.ArrangementType.kitchenPlinth,
                        function(response, noError) {
                            if (noError && response.data !== null && response.data !== undefined &&
                                response.data.resources !== undefined && response.data.resources !== null) {
                                for (i = 0; i < response.data.resources.length; i++) {
                                    found = false;
                                    for (j = 0; j < AMComponentFactory._loadedAssets.plinths.length; j++) {
                                        if (response.data.resources[i]._id === AMComponentFactory._loadedAssets.plinths[j]._id) {
                                            found = true;
                                        }
                                    }
                                    if (!found) {
                                        AMComponentFactory._loadedAssets.plinths.push(response.data.resources[i]);
                                    }
                                }
                            }
                            //get filler/endPanel link to the same coating
                            AssetManagerServices.getAssets(AssetManagerServices._ASSET_TYPE.ARRANGEMENTS,
                                '&q.objectType._id.in=' +
                                SceneConstants.ArrangementType.kitchenEndPanel + ',' +
                                SceneConstants.ArrangementType.kitchenVisibleSide + ',' +
                                SceneConstants.ArrangementType.kitchenFiller +
                                '&q.configs.coating.item.eq=' + coatingId,
                                function(response, noError) {
                                    if (noError && response.data !== null && response.data !== undefined &&
                                        response.data.resources !== undefined && response.data.resources !== null) {
                                        for (i = 0; i < response.data.resources.length; i++) {
                                            found = false;

                                            if (response.data.resources[i].objectType._id === SceneConstants.ArrangementType.kitchenEndPanel ||
                                                response.data.resources[i].objectType._id === SceneConstants.ArrangementType.kitchenVisibleSide) {
                                                for (j = 0; j < AMComponentFactory._loadedAssets.endPanels.length; j++) {
                                                    if (response.data.resources[i]._id === AMComponentFactory._loadedAssets.endPanels[j]._id) {
                                                        found = true;
                                                    }
                                                }
                                                if (!found) {
                                                    AMComponentFactory._loadedAssets.endPanels.push(response.data.resources[i]);
                                                }
                                            } else if (response.data.resources[i].objectType._id === SceneConstants.ArrangementType.kitchenFiller) {
                                                for (j = 0; j < AMComponentFactory._loadedAssets.fillers.length; j++) {
                                                    if (response.data.resources[i]._id === AMComponentFactory._loadedAssets.fillers[j]._id) {
                                                        found = true;
                                                    }
                                                }
                                                if (!found) {
                                                    AMComponentFactory._loadedAssets.fillers.push(response.data.resources[i]);
                                                }
                                            }

                                        }
                                    }
                                    for (i = 0; i < AMComponentFactory._AMCallbacks.length; i++) {
                                        AMComponentFactory._AMCallbacks[i]();
                                    }

                                    AMComponentFactory._coatingsLoaded.push(coatingId);
                                    AMComponentFactory._AMCallbacks = [];
                                    AMComponentFactory._coatingLoading = null;
                                });
                        });
                }
            } else {
                //the coating is already loaded
                callback();
            }
        } else {
            callback();
        }
    }

    /**
     * get coating id on server if needed
     *
     * @param {Object} arrangement
     * @param {*} callback
     */
    static getCoatingId(arrangement, callback) {
        if (arrangement.coatingId !== null && arrangement.coatingId !== undefined) {
            callback(arrangement.coatingId);
        } else if (arrangement.objectId !== null && arrangement.objectId !== undefined &&
            arrangement.colorId !== undefined && arrangement.colorId !== null) {
            AssetManagerServices.getAsset(AssetManagerServices._ASSET_TYPE.ARRANGEMENTS, arrangement.objectId,
                undefined,//ungly useless parameters!
                function(response) {
                    if (response.configs !== undefined && response.configs !== null && response.configs.length > 0) {
                        for (var i = 0; i < response.configs.length; i++) {
                            if (response.configs[i].coating !== null && response.configs[i].coating !== undefined &&
                                response.configs[i].coating.item !== null && response.configs[i].coating.item !== undefined &&
                                response.configs[i].index === arrangement.colorId) {
                                callback(response.configs[i].coating.item._id);
                            }
                        }
                    } else {
                        callback(null);
                    }
                });
        } else {
            callback(null);
        }
    }

    /**
     * get associated asset reference from the assetManager
     *
     * @param {Object} arrangement
     * @param {*} callback
     */
    static findReferences(arrangement, callback) {
        AMComponentFactory.getCoatingId(arrangement, function(coatingId) {
            //make sure assetCoating  is saved
            arrangement._coatingId = coatingId;
            AMComponentFactory.assetManagerRequest(coatingId, function() {
                callback(AMComponentFactory.findLocalPlinth(arrangement),
                    AMComponentFactory.findLocalEndPanels(arrangement),
                    AMComponentFactory.findLocalFiller(arrangement));
            });
        });
    }

    /**
     * return the associated plinth of the arrangement (from local array)
     *
     * @param arrangement
     * @returns {*}
     */
    static findLocalPlinth(arrangement) {
        if (AMComponentFactory.loadedAssets.plinths.length >= 1) {
            return AMComponentFactory.loadedAssets.plinths[0];
        }
        return null;
    }

    /**
     * return the associated endPanel of the arrangement (from local array)
     *
     * @param arrangement
     * @returns {*}
     */
    static findLocalEndPanels(arrangement) {
        let i, j, endPanel, chosenEndPanels = [],
            size = arrangement.getChildren([SceneConstants.EntityType.SketchBlock]).length === 1 ?
                arrangement.getChildren([SceneConstants.EntityType.SketchBlock])[0] : arrangement;
        for (i = 0; i < AMComponentFactory.loadedAssets.endPanels.length; i++) {
            endPanel = AMComponentFactory.loadedAssets.endPanels[i];
            for (j = 0; j < endPanel.configs.length; j++) {
                if (endPanel.configs[j].coating !== null && endPanel.configs[j].coating !== undefined &&
                    endPanel.configs[j].coating.item !== null && endPanel.configs[j].coating.item !== undefined &&
                    endPanel.configs[j].coating.item._id === arrangement.coatingId &&
                    endPanel.sketchBlock !== undefined && endPanel.sketchBlock !== null &&
                    endPanel.sketchBlock.dimensions !== undefined && endPanel.sketchBlock.dimensions !== null &&
                    endPanel.sketchBlock.dimensions.length <= size.width && endPanel.sketchBlock.dimensions.height <= size.height) {
                    if (chosenEndPanels.length === 0 || (
                        endPanel.sketchBlock.dimensions.height > chosenEndPanels[0].sketchBlock.dimensions.height &&
                        endPanel.sketchBlock.dimensions.length > chosenEndPanels[0].sketchBlock.dimensions.length
                    )) {
                        chosenEndPanels = [endPanel];
                    } else if (endPanel.sketchBlock.dimensions.height === chosenEndPanels[0].sketchBlock.dimensions.height &&
                        endPanel.sketchBlock.dimensions.length === chosenEndPanels[0].sketchBlock.dimensions.length) {
                        chosenEndPanels.push(endPanel);
                    }
                }
            }
        }
        return chosenEndPanels;
    }

    /**
     * return the associated filler of the arrangement (from local array)
     *
     * @param arrangement
     * @returns {*}
     */
    static findLocalFiller(arrangement) {
        let i, j, filler;
        for (i = 0; i < AMComponentFactory.loadedAssets.fillers.length; i++) {
            filler = AMComponentFactory.loadedAssets.fillers[i];
            for (j = 0; j < filler.configs.length; j++) {
                if (filler.configs[j].coating !== null && filler.configs[j].coating !== undefined &&
                    filler.configs[j].coating.item !== null && filler.configs[j].coating.item !== undefined &&
                    filler.configs[j].coating.item._id === arrangement.coatingId) {
                    return filler;
                }
            }
        }
        return null;
    }

    /**
     * create component reference from assets data
     *
     * @param plinth
     * @param endPanel
     * @param filler
     * @param coatingId
     * @returns {AMFurnitureFinishesReference}
     */
    static createComponentFromAssets(plinth, endPanels, filler, coatingId) {
        let am = new AMFurnitureFinishesReference();
        am.updateFromAssets(plinth, endPanels, filler, coatingId);
        return am;
    }

    /**
     * Return new component given the arrangement
     * @param arrangement
     */
    static createComponent(arrangement) {
        //try to find object in already loaded object
        let filler = AMComponentFactory.findLocalFiller(arrangement);
        let endPanels = AMComponentFactory.findLocalEndPanels(arrangement);
        let plinth = AMComponentFactory.findLocalPlinth(arrangement);

        //create AMFurnitureFinishesReference with local data, if some data are missing, an request will be made and the data will be updated after receiving
        let am = AMComponentFactory.createComponentFromAssets(plinth, endPanels, filler, arrangement.coatingId);

        //ask assetManager for reference
        if (filler === null || endPanels === null || plinth === null) {
            //refresh data of the component/entity
            // Removed because not usefull now
            /*AMComponentFactory.findReferences(arrangement, function(plinth, endPanels, filler) {
                am.updateFromAssets(plinth, endPanels, filler, arrangement.coatingId);
                am.updateEntityData();
            });*/
        }

        return am;
    }
}
