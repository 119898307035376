import * as glMatrix from 'gl-matrix';
import { Ray } from './ray';

export class Plane {

    private _P: glMatrix.vec3;
    private _N: glMatrix.vec3;

    constructor(P, N) {
        this._P = P;
        glMatrix.vec3.normalize(N, N);
        this._N = N;
    }

    get P() : glMatrix.vec3 {
        return this._P;
    }

    get N() : glMatrix.vec3 {
        return this._N;
    }

    Intersect(R: Ray, dualside: boolean = false, backface: boolean = false) : glMatrix.vec3 | null {
        var OP = glMatrix.vec3.create();
        glMatrix.vec3.subtract(OP, this.P, R.O);
        var N = glMatrix.vec3.clone(this.N);
        if (backface) {
            glMatrix.vec3.negate(N, N);
        }

        var dot = glMatrix.vec3.dot(N, R.D);
        if (Math.abs(dot) < 0.01) { //ray parallel to the plane
            return null;
        }

        if (dot > 0 && !dualside) {//backface culling
            return null;
        }

        var d = glMatrix.vec3.dot(OP, N) / dot;
        if (d < 0 && !dualside) {
            return null;
        }
        
        return glMatrix.vec3.fromValues(R.O[0] + d * R.D[0], R.O[1] + d * R.D[1], R.O[2] + d * R.D[2]);
    }

    Project(position: glMatrix.vec3, dualside: boolean = false) : glMatrix.vec3 | null  {
        var d = glMatrix.vec3.create();
        glMatrix.vec3.negate(d, this.N);
        var ray = new Ray(position, d);
        return this.Intersect(ray, dualside);
    }

}