import { Command, CommandEnum } from './CommandModule';
import { Events } from '../events';
import { eventsManager } from '../managers/EventsManager';
import { Joinery } from '../scene/Joineries/Joinery'

export class DeleteJoineriesCommand extends Command
{
    // Joineries to delete
    private _deletedJoineryArray: Array<Joinery>;

    constructor(deletedJoineryArray: Array<Joinery>) {
        super();
        this._deletedJoineryArray = deletedJoineryArray.slice();
    }

    // Command name returned from a global enum
    name(): string {
        return CommandEnum.DeleteJoineriesCommand;
    }

    // Datas that allows the command to be executed
    execDatas(): any {
        return {
            entities: this._deletedJoineryArray
        };
    }

    // Undo the current command
    undo() {
        // For each joinery deleted add it back to the plan manager
        for (let i = 0; i < this._deletedJoineryArray.length; i++) {
            eventsManager.instance.dispatch(Events.ADD_GRAPHICAL_ENTITY,
                {
                    entity: this._deletedJoineryArray[i],
                });
            this._deletedJoineryArray[i].wall.addJoinery(this._deletedJoineryArray[i]);
        }

        // Execute parent function
        super.undo();

        // Re-select deleted joineries at the end
        eventsManager.instance.dispatch(Events.CHANGE_EDITOR_SELECTION,
            {
                selection: this._deletedJoineryArray,
                keepSelected: false,
                showTulip: false
            });
    }

    // Execute current command (redo)
    execute() {
        // For each joinery to delete remove it from the plan manager
        for (let i = 0; i < this._deletedJoineryArray.length; i++) {
            eventsManager.instance.dispatch(Events.REMOVE_GRAPHICAL_ENTITY,
                {
                    entity: this._deletedJoineryArray[i],
                });
            if (this._deletedJoineryArray[i].wall) {
                this._deletedJoineryArray[i].wall.deleteJoinery(this._deletedJoineryArray[i].id);
            }
        }

        // Execute parent function
        super.execute();
    }
};
