import { Slope } from '../model/Slope';
import { glMatrix, Plane, Ray } from '../../../math/Math';
import { Photo2World } from '../Photo2World';

export class SlopePartitionFactory {

    private _pixels: Array<glMatrix.vec2> = new Array<glMatrix.vec2>();
    private _slopeDatas: Array<any> = new Array<any>();
    private _photo2world: Photo2World;

    constructor(photo2world: Photo2World) {
        this._photo2world = photo2world;
    }

    set Pixels(value: Array<glMatrix.vec2>) {
        this._pixels = value;
    }

    set SlopeDatas(value: Array<any>) {
        this._slopeDatas = value;
    }

    Build() : void {
        this._photo2world.Model.SlopesPartition = [];

        if (this._pixels.length < 2) {
            return;
        }

        var slopeIndex = 0;
        var ceilingPlane = new Plane(glMatrix.vec3.fromValues(0, 0, this._photo2world.WallHeight), glMatrix.vec3.fromValues(0, 0, -1));
        for (var i = 0; i < this._pixels.length; i += 2) {
            let p1 = this._pixels[i];
            let p2 = this._pixels[i + 1];

            if (p1[1] < p2[1]) { //p1 must always be the lowest point to simplify next computations
                let temp = p1;
                p1 = p2;
                p2 = temp;
            }

            let data = this._slopeDatas[slopeIndex];
            var partition = this._photo2world.Model.Partitions[data.wallIndex];

            var wall = {
                wall: partition,
                intersection: partition.Plane.Intersect(new Ray(this._photo2world.CameraPosition, this._photo2world.Unproject(p1)), false, partition.Thickness < 0)
            }

            if (!wall.intersection) {
                slopeIndex++;
                continue;
            }

            wall.intersection[2] = Math.max(0, wall.intersection[2]); //cannot be under the ground
            if (wall.intersection[2] >= wall.wall.Height) { //invalid slope - the bottom point is over the wall
                slopeIndex++;
                continue;
            }

            var A = glMatrix.vec3.clone(wall.wall.A);
            A[2] = wall.intersection[2];
            var B = glMatrix.vec3.clone(wall.wall.B);
            B[2] = wall.intersection[2];

            //perpendicular wall plane
            var wallPlane = new Plane(wall.intersection, wall.wall.D);
            var direction = this._photo2world.Unproject(p2);
            var wallPlaneIntersection = wallPlane.Intersect(new Ray(this._photo2world.CameraPosition, direction), true);
            if (wallPlaneIntersection === null) {
                slopeIndex++;
                continue;
            }

            //compute the slope direction and intersect with the ceiling plane
            var slopeDirection = glMatrix.vec3.create();
            glMatrix.vec3.subtract(slopeDirection, wallPlaneIntersection, wall.intersection);
            var C = ceilingPlane.Intersect(new Ray(A, slopeDirection));
            if (C === null) {
                slopeIndex++;
                continue;
            }
            var D = ceilingPlane.Intersect(new Ray(B, slopeDirection));
            if (D === null) {
                slopeIndex++;
                continue;
            }

            this._photo2world.Model.SlopesPartition.push(new Slope(A, B, C, D, wall.wall));
            
            slopeIndex++;
        }
    }

}
