import { Command, CommandEnum } from "./CommandModule";
import { Coating, ComponentConstants, Entity, Wall, Joinery, TechnicalElement, CeilingBox, SceneConstants, wallManager, math } from "../SavaneJS";

export class CutWallCommand extends Command {
    private _cutWall: Wall;
    private _wallsCreated: Array<Wall>;
    private _selectedEntities: Array<Entity>;

    constructor(cutWall: Wall, cutPosition: math.vec3, selectedEntities: Array<Entity>) {
        super();
        // Keep currently cut wall
        this._cutWall = cutWall;
        // Get wall created as a result of the cutting operation
        this._wallsCreated = wallManager.cutWall(cutWall, cutPosition);
        // Selected entities array
        this._selectedEntities = selectedEntities;
    }

    name(): string {
        return CommandEnum.CutWallCommand;
    }

    updateRollingShutterPosition(joinery: Joinery, thickness: number) {
        // Parse all children of the joinery
        for (let i= 0; i < joinery.children.length; i++) {
            let joinChild = joinery.children[i];

            // Check if there is a rolling shutter attached to it
            if ((joinChild.entityType === SceneConstants.EntityType.TechnicalElement) && ((joinChild as TechnicalElement).objectId === SceneConstants.TechnicalElementType.ceilingBox) && ((joinChild as CeilingBox).ceilingBoxType === SceneConstants.CeilingBoxType.rollingShutter)) {
                // Adapt length to joinery length
                (joinChild as CeilingBox).length = joinery.length;

                // Set default position
                let position = math.vec3.fromValues(0, thickness / 2, joinery.height + joinery.floorHeight);

                // If inverted orientation, invert position[1]
                if (joinery.orientation) {
                    position[1] = -position[1];
                }
                // Transom ? change rolling shutter height
                if (joinery.transom) {
                    position[2] = joinery.transomHeight;
                }
                joinChild.transform.localPosition = position;
            }
        }
    }

    undo() {
        wallManager.mergeWallsForCommand(this._cutWall, this._wallsCreated, this._cutWall.floor, this._selectedEntities);
        for (let i = 0; i < this._cutWall.children.length; i++) {
            if (this._cutWall.children[i].isJoineryEntity()) {
                this.updateRollingShutterPosition((this._cutWall.children[i] as Joinery), this._cutWall.thickness);
            }
        }
    }

    execute() {
        wallManager.cutWallForCommand(this._cutWall, this._wallsCreated, this._cutWall.floor, this._selectedEntities);
        for (let i = 0; i < this._wallsCreated.length; i++) {
            let j: number;
            let wall = this._wallsCreated[i];

            for (j = 0; j < this._cutWall.components.length; j++) {
                if (this._cutWall.components[j].componentType === ComponentConstants.ComponentType.Coating) {
                    let clonedCoating = this._cutWall.components[j].clone();

                    if (math.vec3.dot(this._cutWall.normal, wall.normal) < 0) {
                        if ((clonedCoating as Coating).hangType === Coating.HangType.wallDirect) {
                            (clonedCoating as Coating).hangType = Coating.HangType.wallUndirect;
                        } else {
                            (clonedCoating as Coating).hangType = Coating.HangType.wallDirect;
                        }
                    }
                    wall.addComponent(clonedCoating);
                }
            }

            for (j = 0; j < wall.children.length; j++) {
                if (wall.children[j].isJoineryEntity()) {
                    this.updateRollingShutterPosition((wall.children[j] as Joinery), wall.thickness);
                }
            }
        }
    }
}
