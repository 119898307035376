import { Line } from "../math/Math";
import { Events } from "../events";
import { eventsManager } from "./EventsManager";
import { ComponentConstants, Coating, EntityFactory, Entity, Floor, Room, Wall, SceneConstants, SavaneConstants, roomManager, joineryManager, math, SavaneMath, TechnicalElement } from "../SavaneJS";

// TODO cleanup when crash found
declare var Sentry;

/**
 * WallManager is a singleton managing all wall of the scene
 *
 * @constructor
 */
class WallManager {
    constructor() {
    }

    /**
     * Getter for the wall at the position given in parameters.
     * Test if the walls is on an corner or the body of the wall
     *
     * @param {*} position
     * @param {Floor} floor
     * @param {Number} precision
     * @param {Number} ignoreWallId
     * @param {*} additionalWalls array of walls that may not be child of floor which need to be tested  //FIXME : this is a quickfix to handle temporary wall created while wall or point edition
     * @returns {*}
     */
    getWallsAtPosition(position: math.vec3, floor: Floor, precision: number, ignoreWallId: number, additionalWalls?: Array<Wall>, strictly?: boolean) : Array<Wall> | null {
        let wallAtPoint = new Array<Wall>();
        let testedWall = floor.walls;
        let i, j, found;
        if (additionalWalls !== undefined && additionalWalls !== null) {
            for (i = 0; i < additionalWalls.length; i++) {
                found = false;
                for (j = 0; j < testedWall.length; j++) {
                    if (testedWall[j].id === additionalWalls[i].id) {
                        found = true;
                        break;
                    }
                }
                if (!found) {
                    testedWall.push(additionalWalls[i]);
                }
            }
        }

        for (i = 0; i < testedWall.length; i++) {
            if (ignoreWallId !== testedWall[i].id && testedWall[i].length > SavaneConstants.PositionTolerance) {
                if (strictly) {
                    if (testedWall[i].isCorner(position, precision) || testedWall[i].isPointStrictlyOnWall(position, precision)) {
                        wallAtPoint.push(testedWall[i]);
                    }
                } else {
                    if (testedWall[i].isCorner(position, precision) || testedWall[i].isPointOnWall(position, precision)) {
                        wallAtPoint.push(testedWall[i]);
                    }
                }
            }
        }

        if (wallAtPoint.length !== 0) {
            return wallAtPoint;
        }
        return null;
    }

    /**
     * Getter for the wall at the position given in parameters. Ordered by angle
     * Test if the walls is on an corner or the body of the wall
     *
     * @param {*} position
     * @param {Floor} floor
     * @param {Number} precision
     * @param {Number} ignoreWallId
     * @param {*} additionalWalls array of walls that may not be child of floor which need to be tested  //FIXME : this is a quickfix to handle temporary wall created while wall or point edition
     * @returns {*}
     */
    getWallsAtPositionAngleOrdered(position: math.vec3, floor: Floor, precision?: number, ignoreWallId?: number, additionalWalls?: Array<Wall>) : Array<Wall> {
        let wallAtPoint = this.getWallsAtPosition(position, floor, precision, ignoreWallId, additionalWalls, true);
        if (wallAtPoint !== null) {
            let comparefunc = function compare(a, b) {
                if (a.getWallAngle(a.isBegin(position)) < b.getWallAngle(b.isBegin(position))) {
                    return -1;
                } else {
                    return 1;
                }
            };
            wallAtPoint.sort(comparefunc);
        } else {
            wallAtPoint = new Array<Wall>();
        }
        return wallAtPoint;
    }

    /**
     * Getter for the wall at the position given in parameters.
     * Test if the walls is on the body of the wall
     *
     * @param {*} position
     * @param {Floor} floor
     * @param {Number} precision
     * @param {Number} ignoreWallId
     * @returns {*}
     */
    getWallsAtPositionWithoutCorner(position: math.vec3, floor: Floor, precision: number, ignoreWallId: number) : Array<Wall> | null {
        let wallAtPoint = new Array<Wall>();
        for (let i = 0; i < floor.walls.length; i++) {
            let wall = floor.walls[i];
            let projection = wall.segment.orthogonalProjection(position);
            if (!projection) {
                continue;
            }
            if (wall.segment.isPointOnSegment(projection, 1) === false) {
                continue;
            }
            if (math.vec3.distance(position, projection) > precision) {
                continue;
            }
            if (ignoreWallId === wall.id) {
                continue;
            }
            wallAtPoint.push(wall);
        }

        if (wallAtPoint.length !== 0) {
            return wallAtPoint;
        }
        return null;
    }

    /**
     * Getter for the wall which have a corner at the position given in parameters
     *
     * @param {*} point
     * @param {Floor} floor
     * @param {Number} precision
     * @param {Boolean} wallThick
     * @returns {*}
     */
    getWallsAtCorner(point: math.vec3, floor: Floor, precision: number, wallThick?: boolean, ignoreWallId?: number) : Array<Wall> | null {
        if (!floor) {
            return null;
        }
        if (!floor.walls) {
            // this is a tracker - TODO delete when fixed
            try {
                throw new TypeError('Walls undefined on ' + (typeof floor));
            } catch (error) {
                Sentry.captureException(error);
            }
            return null;
        }
        let wallAtPoint = new Array<Wall>();
        for (let i = 0; i < floor.walls.length; i++) {
            if (floor.walls[i].id === ignoreWallId) {
                continue;
            }
            if (floor.walls[i].isCorner(point, precision, wallThick)) {
                wallAtPoint.push(floor.walls[i]);
            }
        }
        if (wallAtPoint.length === 0) {
            return null;
        }
        return wallAtPoint;
    }

    /**
     * Getter for the wall which have a corner at the position given in parameters. Ordered by angle
     *
     * @param {*} position
     * @param {Entity} floor
     * @param {Number} precision
     * @param {Number} ignoreWallId
     * @returns {*}
     */
    getWallsAtCornerAngleOrdered(position: math.vec3, floor: Floor, precision: number, ignoreWallId: number) : Array<Wall> {
        let wallAtPoint = this.getWallsAtCorner(position, floor, precision, null, ignoreWallId);
        if (wallAtPoint !== null) {
            let comparefunc = function compare(a, b) {
                if (a.getWallAngle(a.isBegin(position)) < b.getWallAngle(b.isBegin(position))) {
                    return -1;
                } else {
                    return 1;
                }
            };
            wallAtPoint.sort(comparefunc);
        } else {
            wallAtPoint = new Array<Wall>();
        }
        return wallAtPoint;
    }

    /**
     * return walls under the tested wall
     *
     * @param {Wall} testedWall
     * @param {Number} precision
     * @param {Number} colinearPrecision
     */
    getWallSuperposed(testedWall: Wall, precision?: number, colinearPrecision?: number) : Array<Wall> {
        if (precision === undefined) {
            precision = SavaneConstants.PositionTolerance;
        }
        if (colinearPrecision === undefined) {
            colinearPrecision = SavaneConstants.Tolerance;
        }
        let currentFloor = testedWall.floor;

        let currentFloorWalls = currentFloor.walls;
        let superposedWalls = new Array<Wall>();

        for (let i = 0; i < currentFloor.walls.length; i++) {
            if (currentFloorWalls[i].id === testedWall.id) {
                continue;
            }

            if (currentFloorWalls[i].isCorner(testedWall.begin, precision, true) && currentFloorWalls[i].isCorner(testedWall.end, precision, true)) {
                superposedWalls.push(currentFloorWalls[i]);
                continue;
            }

            if (Wall.areColinear(testedWall, currentFloorWalls[i], colinearPrecision)) {
                // consider only half of the thickness for this test instead of the full thickness as you have only half of the thickness on each side of the wall segment

                if ((currentFloorWalls[i].isPointOnWall(testedWall.begin, precision) && !currentFloorWalls[i].isCorner(testedWall.begin)) || (currentFloorWalls[i].isPointOnWall(testedWall.end, precision) && !currentFloorWalls[i].isCorner(testedWall.end))) {
                    superposedWalls.push(currentFloorWalls[i]);
                }
            }
        }
        return superposedWalls;
    }

    /**
     * return ths list of point where the walls must be cut
     *
     * @param {Wall} testedWall
     */
    getPointsCuttingWalls(testedWall: Wall) : Array<math.vec3> {
        let currentFloor = testedWall.floor;
        let currentFloorWalls = currentFloor.walls;
        let cuttingPoints = new Array<math.vec3>();

        for (let i = 0; i < currentFloor.walls.length; i++) {
            let wall = currentFloorWalls[i];
            if (wall.id !== testedWall.id) {
                if (testedWall.isPointOnWall(wall.begin, SavaneConstants.PositionTolerance) && !testedWall.isCorner(wall.begin, SavaneConstants.PositionTolerance, true)) {
                    let toAdd = true;
                    for (let j = 0; j < cuttingPoints.length; j++) {
                        if (math.vec3.distance(cuttingPoints[j], wall.begin) < SavaneConstants.PositionTolerance) {
                            toAdd = false;
                            break;
                        }
                    }
                    if (toAdd) {
                        cuttingPoints.push(math.vec3.clone(wall.begin));
                    }
                }
                if (testedWall.isPointOnWall(wall.end, SavaneConstants.PositionTolerance) && !testedWall.isCorner(wall.end, SavaneConstants.PositionTolerance, true)) {
                    let toAdd = true;
                    for (let j = 0; j < cuttingPoints.length; j++) {
                        if (math.vec3.distance(cuttingPoints[j], wall.end) < SavaneConstants.PositionTolerance) {
                            toAdd = false;
                            break;
                        }
                    }
                    if (toAdd) {
                        cuttingPoints.push(math.vec3.clone(wall.end));
                    }
                }
            }
        }
        cuttingPoints.sort(function (point1, point2) {
            return math.vec3.distance(testedWall.begin, point1) - math.vec3.distance(testedWall.begin, point2);
        });

        return cuttingPoints;
    }

    /**
     * Test whether the wall is valid
     *
     * @param {Wall} testedWall
     * @returns {*}
     */
    isWallValid(testedWall: Wall) : boolean {
        let currentFloor = testedWall.floor;
        if (!currentFloor) {
            return false;
        }

        if (testedWall.length < SceneConstants.WallMinLength) {
            return false;
        }

        let otherWallsAtBegin = this.getWallsAtPosition(testedWall.begin, currentFloor, SavaneConstants.PositionTolerance, testedWall.id);
        if (otherWallsAtBegin !== null) {
            for (let i = 0; i < otherWallsAtBegin.length; i++) {
                if (testedWall.length <= otherWallsAtBegin[i].thickness / 2) {
                    return false;
                }
            }
        }
        let otherWallsAtEnd = this.getWallsAtPosition(testedWall.end, currentFloor, SavaneConstants.PositionTolerance, testedWall.id);
        if (otherWallsAtEnd !== null) {
            for (let i = 0; i < otherWallsAtEnd.length; i++) {
                if (testedWall.length <= otherWallsAtEnd[i].thickness / 2) {
                    return false;
                }
            }
        }

        let superposedWalls = this.getWallSuperposed(testedWall, SavaneConstants.PositionTolerance, 100 * SavaneConstants.Tolerance);
        if (superposedWalls.length !== 0) {
            return false;
        }

        if (joineryManager.getJoineryAtPosition(testedWall.end, SavaneConstants.PositionTolerance, currentFloor) !== null || joineryManager.getJoineryAtPosition(testedWall.begin, SavaneConstants.PositionTolerance, currentFloor) !== null) {
            return false;
        }

        for (let i = 0; i < testedWall.joineries.length; i++) {
            if (!joineryManager.isJoineryValid(testedWall.joineries[i])) {
                return false;
            }
        }

        let cutWallList1 = this.getWallsAtPosition(testedWall.begin, currentFloor, SavaneConstants.PositionTolerance, testedWall.id);
        if (cutWallList1 !== null) {
            for (let i = 0; i < cutWallList1.length; i++) {
                if (!cutWallList1[i].isCorner(testedWall.begin)) {
                    let cutWall = cutWallList1[i];
                    if (math.vec3.dist(testedWall.begin, cutWall.begin) < SceneConstants.WallMinLength || math.vec3.dist(testedWall.begin, cutWall.end) < SceneConstants.WallMinLength) {
                        return false;
                    }
                }
            }
        }

        let beginWalls = this.getWallsAtCorner(testedWall.begin, currentFloor, SavaneConstants.PositionTolerance);
        if (beginWalls !== null) {
            for (let i = 0; i < beginWalls.length; i++) {
                if (testedWall.id !== beginWalls[i].id) {
                    if (Wall.areColinear(beginWalls[i], testedWall)) {
                        if (beginWalls[i].isBegin(testedWall.begin)) {
                            if (math.vec3.dist(beginWalls[i].wallDirection, testedWall.wallDirection) < SavaneConstants.Tolerance) {
                                return false;
                            }
                        } else {
                            let dif = math.vec3.create();
                            math.vec3.set(dif, beginWalls[i].wallDirection[0] + testedWall.wallDirection[0], beginWalls[i].wallDirection[1] + testedWall.wallDirection[1], 0);
                            if (math.vec3.length(dif) < SavaneConstants.Tolerance) {
                                return false;
                            }
                        }
                    }
                }
            }
        }

        let endWalls = this.getWallsAtCorner(testedWall.end, currentFloor, SavaneConstants.PositionTolerance);
        if (endWalls !== null) {
            for (let i = 0; i < endWalls.length; i++) {
                if (testedWall.id !== endWalls[i].id) {
                    if (Wall.areColinear(endWalls[i], testedWall)) {
                        if (endWalls[i].isEnd(testedWall.end)) {
                            if (math.vec3.dist(endWalls[i].wallDirection, testedWall.wallDirection) < SavaneConstants.Tolerance) {
                                return false;
                            }
                        } else {
                            let dif = math.vec3.create();
                            math.vec3.set(dif, endWalls[i].wallDirection[0] + testedWall.wallDirection[0], endWalls[i].wallDirection[1] + testedWall.wallDirection[1], 0);
                            if (math.vec3.length(dif) < SavaneConstants.Tolerance) {
                                return false;
                            }
                        }
                    }
                }
            }
        }

        let cutWallList2 = this.getWallsAtPosition(testedWall.end, currentFloor, SavaneConstants.PositionTolerance, testedWall.id);
        if (cutWallList2 !== null) {
            for (let i = 0; i < cutWallList2.length; i++) {
                if (!cutWallList2[i].isCorner(testedWall.end)) {
                    let cutWall1 = cutWallList2[i];
                    if (math.vec3.dist(testedWall.end, cutWall1.begin) < SceneConstants.WallMinLength || math.vec3.dist(testedWall.end, cutWall1.end) < SceneConstants.WallMinLength) {
                        return false;
                    }
                }
            }
        }

        if (cutWallList1 !== null && cutWallList2 !== null) {
            for (let i = 0; i < cutWallList1.length; i++) {
                for (let j = 0; j < cutWallList2.length; j++) {
                    if (cutWallList1[i].id === cutWallList2[j].id) {
                        return false;
                    }
                }
            }
        }

        for (let i = 0; i < currentFloor.walls.length; i++) {
            if (currentFloor.walls[i].id !== testedWall.id) {
                let wall = currentFloor.walls[i];
                let toTest = true;

                if (cutWallList1 !== null) {
                    for (let j = 0; j < cutWallList1.length; j++) {
                        if (cutWallList1[j].id === wall.id) {
                            toTest = false;
                        }
                    }
                }

                if (cutWallList2 !== null) {
                    for (let j = 0; j < cutWallList2.length; j++) {
                        if (cutWallList2[j].id === wall.id) {
                            toTest = false;
                        }
                    }
                }

                if (toTest) {
                    if (Wall.areWallCrossing(testedWall, wall, -SavaneConstants.PositionTolerance) && !(testedWall.isCorner(wall.begin) || testedWall.isCorner(wall.end))) {
                        return false;
                    }
                }
            }
        }

        return true;
    }

    isWallValidWithSuperposition(testedWall: Wall) : boolean {
        let currentFloor = testedWall.floor;
        let cutting = this.getPointsCuttingWalls(testedWall);
        let superposedWall = this.getWallSuperposed(testedWall, SavaneConstants.PositionTolerance, 100 * SavaneConstants.Tolerance);
        if (cutting.length <= 0 && superposedWall.length > 0) {
            if (superposedWall.length > 1) {
                //Avoid wall nearly colinear but not creating any cut points
                return false;
            } else {
                if ((testedWall.isEnd(superposedWall[0].end) && testedWall.isBegin(superposedWall[0].begin)) || (testedWall.isEnd(superposedWall[0].begin) && testedWall.isBegin(superposedWall[0].end))) {
                    return true; //Perfectly superposed wall
                }
                let subCutting = this.getPointsCuttingWalls(superposedWall[0]);
                if (subCutting.length < 1) {
                    return false;
                }
                //Wall is contained in the superposed wall
            }
        }
        for (let j = 0; j < superposedWall.length; j++) {
            let minL = SceneConstants.WallMinLength;

            if ((math.vec3.distance(superposedWall[j].end, testedWall.end) < minL && !testedWall.isEnd(superposedWall[j].end)) || (math.vec3.distance(superposedWall[j].begin, testedWall.end) < minL && !testedWall.isEnd(superposedWall[j].begin)) || (math.vec3.distance(superposedWall[j].end, testedWall.begin) < minL && !testedWall.isBegin(superposedWall[j].end)) || (math.vec3.distance(superposedWall[j].begin, testedWall.begin) < minL && !testedWall.isBegin(superposedWall[j].begin))) {
                return false;
            }
            for (let i = 0; i < superposedWall[j].rooms.length; i++) {
                let room1 = superposedWall[j].rooms[i];
                for (let k = 0; k < testedWall.rooms.length; k++) {
                    let room2 = testedWall.rooms[k];
                    if (room1.id === room2.id) {
                        return false;
                    }
                }
            }
        }

        if (testedWall.length < SceneConstants.WallMinLength) {
            return false;
        } else {
            let otherWallsAtBegin = this.getWallsAtPosition(testedWall.begin, currentFloor, SavaneConstants.PositionTolerance, testedWall.id);
            if (otherWallsAtBegin !== null) {
                for (let i = 0; i < otherWallsAtBegin.length; i++) {
                    if (testedWall.length <= otherWallsAtBegin[i].thickness / 2) {
                        return false;
                    }
                }
            }
        }

        if (joineryManager.getJoineryAtPosition(testedWall.end, SavaneConstants.PositionTolerance, currentFloor) !== null || joineryManager.getJoineryAtPosition(testedWall.begin, SavaneConstants.PositionTolerance, currentFloor) !== null) {
            return false;
        }

        for (let i = 0; i < testedWall.joineries.length; i++) {
            if (!joineryManager.isJoineryValid(testedWall.joineries[i])) {
                return false;
            }
        }

        let arrangements = currentFloor.iArrangements;
        for (let i = 0; i < arrangements.length; i++) {
            if (arrangements[i].isTechnicalElementEntity() && (arrangements[i] as TechnicalElement).objectId !== SceneConstants.TechnicalElementType.beam && (arrangements[i] as TechnicalElement).objectId !== SceneConstants.TechnicalElementType.pole && (arrangements[i] as TechnicalElement).objectId !== SceneConstants.TechnicalElementType.frame) {
                if ((arrangements[i] as TechnicalElement).crossWall(testedWall)) {
                    return false;
                }
            }
        }

        let cutWallList1 = this.getWallsAtPosition(testedWall.begin, currentFloor, SavaneConstants.PositionTolerance, testedWall.id);
        if (cutWallList1 !== null) {
            for (let i = 0; i < cutWallList1.length; i++) {
                if (!cutWallList1[i].isCorner(testedWall.begin)) {
                    let cutWall = cutWallList1[i];
                    if (math.vec3.dist(testedWall.begin, cutWall.begin) < SceneConstants.WallMinLength || math.vec3.dist(testedWall.begin, cutWall.end) < SceneConstants.WallMinLength) {
                        return false;
                    }
                }
            }
        }

        let cutWallList2 = this.getWallsAtPosition(testedWall.end, currentFloor, SavaneConstants.PositionTolerance, testedWall.id);
        if (cutWallList2 !== null) {
            for (let i = 0; i < cutWallList2.length; i++) {
                if (!cutWallList2[i].isCorner(testedWall.end)) {
                    let cutWall1 = cutWallList2[i];
                    if (math.vec3.dist(testedWall.end, cutWall1.begin) < SceneConstants.WallMinLength || math.vec3.dist(testedWall.end, cutWall1.end) < SceneConstants.WallMinLength) {
                        return false;
                    }
                }
            }
        }

        let currentFloorsWalls = currentFloor.walls;
        for (let j = 0; j < currentFloorsWalls.length; j++) {
            if (currentFloorsWalls[j].id !== testedWall.id) {
                let wall = currentFloorsWalls[j];
                let toTest = true;

                for (let k = 0; k < superposedWall.length; k++) {
                    let wallAtBegin = this.getWallsAtCorner(superposedWall[k].begin, currentFloor, SavaneConstants.PositionTolerance);
                    let wallAtEnd = this.getWallsAtCorner(superposedWall[k].end, currentFloor, SavaneConstants.PositionTolerance);

                    if (wallAtBegin.indexOf(wall) !== -1 || wallAtEnd.indexOf(wall) !== -1) {
                        toTest = false;
                    }
                }

                if (cutWallList1 !== null) {
                    for (let k = 0; k < cutWallList1.length; k++) {
                        if (cutWallList1[k].id === wall.id) {
                            toTest = false;
                        }
                    }
                }

                if (cutWallList2 !== null) {
                    for (let k = 0; k < cutWallList2.length; k++) {
                        if (cutWallList2[k].id === wall.id) {
                            toTest = false;
                        }
                    }
                }

                if (toTest) {
                    if (Wall.areWallCrossing(wall, testedWall, SavaneConstants.PositionTolerance)) {
                        toTest = false;
                    }
                    if (Wall.areWallCrossing(wall, testedWall, SavaneConstants.PositionTolerance) && !(testedWall.isCorner(wall.begin) || testedWall.isCorner(wall.end))) {
                        return false;
                    }
                }
            }
        }

        return true;
    }

    /**
     * Test whether all walls are valid on the designated floor
     *
     * @param {Floor} floor
     * @returns {*}
     */
    areWallsValid(floor: Floor): boolean {
        for (let i = 0; i < floor.walls.length; i++) {
            if (!this.isWallValid(floor.walls[i])) {
                return false;
            }
        }
        return true;
    }

    /**
     * Create a new Wall by merging two wall
     *
     * @param {Wall} wall1
     * @param {Wall} wall2
     */
    mergeWall(wall1: Wall, wall2: Wall) : Wall {
        let begin = math.vec3.create();
        let end = math.vec3.create();
        let wall = EntityFactory.createWall(begin, end);

        wall.parent = wall1.floor;
        wall.thickness = wall1.thickness;
        wall.height = wall1.height;
        if (wall1.shiftDirection * wall1.shiftOffset === wall2.shiftDirection * wall2.shiftOffset) {
            wall.shiftDirection = wall1.shiftDirection;
        }

        // Copy special property and components
        if (wall1.isSpecialWall) {
            let wallType = wall1.getComponents(ComponentConstants.ComponentType.WallType);

            if (wallType.length > 0) {
                wall.addComponent(wallType[0].clone());
            }
        }

        // Copy coating
        for (let j = 0; j < wall1.components.length; j++) {
            if (wall1.components[j].componentType === ComponentConstants.ComponentType.Coating) {
                let clonedCoating = (wall1.components[j].clone() as Coating);

                if (math.vec3.dot(wall1.normal, wall.normal) < 0) {
                    if (clonedCoating.hangType === Coating.HangType.wallDirect) {
                        clonedCoating.hangType = Coating.HangType.wallUndirect;
                    } else {
                        clonedCoating.hangType = Coating.HangType.wallDirect;
                    }
                }
                wall.addComponent(clonedCoating);
            }
        }

        let changeOrientation1 = false;
        let changeOrientation2 = false;
        if (SavaneMath.equal(wall1.begin, wall2.begin)) {
            wall.begin = wall1.end;
            wall.end = wall2.end;
            changeOrientation2 = true;
        } else if (SavaneMath.equal(wall1.end, wall2.begin)) {
            wall.begin = wall1.begin;
            wall.end = wall2.end;
        } else if (SavaneMath.equal(wall1.begin, wall2.end)) {
            wall.begin = wall2.begin;
            wall.end = wall1.end;
        } else if (SavaneMath.equal(wall1.end, wall2.end)) {
            wall.begin = wall1.begin;
            wall.end = wall2.begin;
            changeOrientation1 = true;
        }

        for (let i = 0; i < wall2.joineries.length; i++) {
            let joinery = EntityFactory.cloneJoinery(wall2.joineries[i], false);
            wall.addJoinery(joinery);
            if (changeOrientation1) {
                joinery.orientation = !joinery.orientation;
            }
        }
        for (let i = 0; i < wall1.joineries.length; i++) {
            let joinery = EntityFactory.cloneJoinery(wall1.joineries[i], false);
            wall.addJoinery(joinery);
            if (changeOrientation2) {
                joinery.orientation = !joinery.orientation;
            }
        }
        return wall;
    }

    /**
     * Merge an array of walls in to one wall
     *
     * @param {Array} walls
     * @returns {*}
     */
    mergeWallArray(walls: Array<Wall>) : Wall {
        let mergedWall = walls[0];
        for (let i = 1; i < walls.length; i++) {
            mergedWall = this.mergeWall(mergedWall, walls[i]);
        }
        return mergedWall;
    }

    /**
     * Create a two Wall by cutting a wall
     *
     * @param {Wall} wallCut
     * @param {*} cutPosition
     */
    cutWall(wallCut: Wall, cutPosition: math.vec3) : Array<Wall> {
        let walls = new Array<Wall>();
        let begin = math.vec3.create();
        math.vec3.copy(begin, wallCut.begin);
        let end = math.vec3.create();
        math.vec3.copy(end, cutPosition);
        walls[0] = EntityFactory.createWall(begin, end);
        walls[0].parent = wallCut.floor;
        walls[0].thickness = wallCut.thickness;
        walls[0].height = wallCut.height;
        walls[0].shiftDirection = wallCut.shiftDirection;
        walls[0].slope = wallCut.slope;
        walls[0].slopeHeight = wallCut.slopeHeight;
        walls[0].slopeLength1 = wallCut.slopeLength1;
        walls[0].slopeLength2 = wallCut.slopeLength2;

        let begin1 = math.vec3.create();
        math.vec3.copy(begin1, cutPosition);
        let end1 = math.vec3.create();
        math.vec3.copy(end1, wallCut.end);
        walls[1] = EntityFactory.createWall(begin1, end1);
        walls[1].parent = wallCut.floor;
        walls[1].thickness = wallCut.thickness;
        walls[1].height = wallCut.height;
        walls[1].shiftDirection = -wallCut.shiftDirection;
        walls[1].slope = wallCut.slope;
        walls[1].slopeHeight = wallCut.slopeHeight;
        walls[1].slopeLength1 = wallCut.slopeLength1;
        walls[1].slopeLength2 = wallCut.slopeLength2;

        // Copy special property and components
        if (wallCut.isSpecialWall) {
            let wallType = wallCut.getComponents(ComponentConstants.ComponentType.WallType);

            if (wallType.length > 0) {
                walls[0].addComponent(wallType[0].clone());
                walls[1].addComponent(wallType[0].clone());
            }
        }

        let wallLine = new Line(walls[0].begin, walls[0].end);
        for (let i = 0; i < wallCut.joineries.length; i++) {
            let joinery = EntityFactory.cloneJoinery(wallCut.joineries[i], false);
            if (wallLine.BelongToSegment(joinery.position) === false) {
                walls[1].addJoinery(joinery);
            } else {
                walls[0].addJoinery(joinery);
            }
        }
        return walls;
    }

    /**
     * Inscrut a wall inside another
     *
     * @param {Wall} wallCreated
     * @param {Wall} wallGlobal
     * @returns {*}
     */
    incrustWall(wallCreated: Wall, wallGlobal: Wall) : Array<Wall> {
        let distBegin = math.vec3.distance(wallGlobal.begin, wallCreated.begin);
        let distEnd = math.vec3.distance(wallGlobal.begin, wallCreated.end);
        let walls = new Array<Wall>();
        let cuttedWallBegin = null;
        let subcutted = null;
        if (distEnd < distBegin) {
            cuttedWallBegin = this.cutWall(wallGlobal, wallCreated.end);

            subcutted = this.cutWall(cuttedWallBegin[1], wallCreated.begin);
        } else {
            cuttedWallBegin = this.cutWall(wallGlobal, wallCreated.begin);
            subcutted = this.cutWall(cuttedWallBegin[1], wallCreated.end);
        }
        walls.push(cuttedWallBegin[0]);
        walls.push(subcutted[1]);
        walls.push(subcutted[0]);
        return walls;
    }

    getAnglesPoint(wall: Wall, point: math.vec3, wallList: Array<Wall>) : { 
        min: number,
        minThickness: number,
        max: number,
        maxThickness: number
    } {
        let currentAngleBeg = wall.getWallAngle(point === wall.begin);
        //Calculate Decal for top point
        let minAngle = Math.PI * 2.0;
        let minAngleThickness = wall.thickness;
        //CalculateDecal for bot point
        let maxAngle = 0;
        let maxAngleThickness = wall.thickness;
        //Calculate Angle begin
        //var listWallBegin = this.getWallsAtPosition(point,SavaneConstants.PositionTolerance);
        if (wallList !== null) {
            for (let i = 0; i < wallList.length; i++) {
                let testedWall = wallList[i];
                //Avoid test whit himself
                if (testedWall.id !== wall.id) {
                    let begToBegDist = (testedWall.begin[0] - point[0]) * (testedWall.begin[0] - point[0]) + (testedWall.begin[1] - point[1]) * (testedWall.begin[1] - point[1]);
                    let endToBegDist = (testedWall.end[0] - point[0]) * (testedWall.end[0] - point[0]) + (testedWall.end[1] - point[1]) * (testedWall.end[1] - point[1]);

                    let newAngle;
                    //Try to retrieve precalculated angle
                    if (begToBegDist > endToBegDist) {
                        newAngle = testedWall.getWallAngle(false);
                    } else {
                        newAngle = testedWall.getWallAngle(true);
                    }
                    let diffAngle = SavaneMath.normalizeAngle(newAngle - currentAngleBeg);
                    if (diffAngle < minAngle) {
                        minAngle = diffAngle;
                        minAngleThickness = testedWall.thickness;
                    }
                    if (diffAngle > maxAngle) {
                        maxAngle = diffAngle;
                        maxAngleThickness = testedWall.thickness;
                    }
                    //Not a corner test add opposite angle too
                    if (!testedWall.isCorner(point, SavaneConstants.PositionTolerance)) {
                        if (begToBegDist < endToBegDist) {
                            newAngle = testedWall.getWallAngle(false);
                        } else {
                            newAngle = testedWall.getWallAngle(true);
                        }
                        let diffAngle = SavaneMath.normalizeAngle(newAngle - currentAngleBeg);
                        if (diffAngle < minAngle) {
                            minAngle = diffAngle;
                            minAngleThickness = testedWall.thickness;
                        }
                        if (diffAngle > maxAngle) {
                            maxAngle = diffAngle;
                            maxAngleThickness = testedWall.thickness;
                        }
                    }
                }
            }
        }
        if (minAngle < Math.PI / 50 || minAngle > (Math.PI * 99) / 50) {
            minAngle = 0;
        }
        if (maxAngle < Math.PI / 50 || maxAngle > (Math.PI * 99) / 50) {
            maxAngle = 0;
        }
        return {
            min: minAngle,
            minThickness: minAngleThickness,
            max: maxAngle,
            maxThickness: maxAngleThickness,
        };
    }

    /**
     *
     * FIXME
     *
     * @param {Array} modifiedWalls
     * @param {Wall} editedWall
     * @returns {*}
     */
    followedWall(modifiedWalls: Array<Wall>, editedWall: Wall) : number {
        if (modifiedWalls !== null && modifiedWalls.length === 1) {
            //FIXME ??? None or only one wall is edited
            return -1;
        } else {
            for (let i = 0; i < modifiedWalls.length; i++) {
                if (modifiedWalls[i].id !== editedWall.id) {
                    for (let j = 0; j < modifiedWalls.length; j++) {
                        if (modifiedWalls[j].id !== editedWall.id && !Wall.areColinear(modifiedWalls[i], modifiedWalls[j])) {
                            //FIXME ??? One of the editedWall is not colinear with one or more of the others editedWall
                            return -2;
                        }
                    }
                }
            }
        }
        //FIXME Return ?????
        if (modifiedWalls[0].id === editedWall.id) {
            return modifiedWalls[1].id;
        }
        return modifiedWalls[0].id;
    }

    addWallForCommand(wall: Wall, tryCreateRoom: boolean, currentFloor: Floor, height: number, isB2B?: boolean) : Array<Room> | null {
        let i, j;

        if (tryCreateRoom === null || tryCreateRoom === undefined) {
            tryCreateRoom = true;
        }

        eventsManager.instance.dispatch(Events.REMOVE_GRAPHICAL_ENTITY, {
            entity: wall,
        });
        for (i = 0; i < wall.rooms.length; ++i) {
            eventsManager.instance.dispatch(Events.REMOVE_GRAPHICAL_ENTITY, {
                entity: wall.rooms[i],
            });
        }

        let affectedRooms = currentFloor.addWall(wall, tryCreateRoom, height, isB2B);
        let itemToRecompute = [];
        if (affectedRooms !== null) {
            for (i = 0; i < affectedRooms.length; ++i) {
                for (j = 0; j < affectedRooms[i].children.length; ++j) {
                    itemToRecompute.push(affectedRooms[i].children[j]);
                }
            }
        }

        if (affectedRooms) {
            for (i = 0; i < affectedRooms.length; ++i) {
                eventsManager.instance.dispatch(Events.REMOVE_GRAPHICAL_ENTITY, {
                    entity: affectedRooms[i],
                });
            }

            for (i = 0; i < affectedRooms.length; ++i) {
                if (affectedRooms[i].parent) {
                    eventsManager.instance.dispatch(Events.REDRAW_GRAPHICAL_ROOM, {
                        entity: affectedRooms[i],
                    });
                }
            }
        }

        eventsManager.instance.dispatch(Events.REDRAW_GRAPHICAL_WALL, {
            entity: wall,
        });

        //Recompute all items
        for (i = 0; i < itemToRecompute.length; ++i) {
            let item = itemToRecompute[i];
            //Recompute parent
            if (item.isArrangementObjectEntity() || item.isArrangementGroupEntity() || item.isRenderCameraEntity() || item.isGeometryPrimitiveEntity() || item.isWorktopEntity()) {
                // Find containing room
                let roomContaining = null;

                if (item.isWorktopEntity()) {
                    let area = item.getComponent(ComponentConstants.ComponentType.Area);
                    if (area) {
                        roomContaining = roomManager.getRoomAtPosition(area.vertices[0], currentFloor);
                    }
                } else {
                    roomContaining = roomManager.getRoomAtPosition(item.position, currentFloor);
                }

                if (roomContaining !== null) {
                    //set item child of room
                    if (item.parent !== null) {
                        item.parent.deleteChild(item.id);
                    }
                    roomContaining.addChild(item, 100);
                } else {
                    //set item child of floor
                    if (item.parent !== null) {
                        item.parent.deleteChild(item.id);
                    }
                    currentFloor.addChild(item);
                }
                //Graphical node
                eventsManager.instance.dispatch(Events.ADD_GRAPHICAL_ENTITY, {
                    entity: item,
                });
            }
        }
        return affectedRooms;
    }

    deleteWallForCommand(id: number, tryCreateRoom: boolean, currentFloor: Floor, selectedEntities: Array<Entity>) : Array<Room> {
        let i, j;

        if (tryCreateRoom === null || tryCreateRoom === undefined) {
            tryCreateRoom = true;
        }
        // Retrieve wall framework entity
        let wall = currentFloor.getWall(id);

        if (wall === null) {
            return null;
        }

        //Remove graphical node even for unprocessed wall
        eventsManager.instance.dispatch(Events.REMOVE_GRAPHICAL_ENTITY, {
            entity: wall,
        });

        for (i = 0; i < wall.rooms.length; ++i) {
            eventsManager.instance.dispatch(Events.REMOVE_GRAPHICAL_ENTITY, {
                entity: wall.rooms[i],
            });
        }

        let rooms = [];
        let itemToRecompute = [];
        for (i = 0; i < wall.rooms.length; i++) {
            rooms.push(wall.rooms[i]);
            for (j = 0; j < wall.rooms[i].children.length; j++) {
                itemToRecompute.push(wall.rooms[i].children[j]);
            }
        }
        //Remove from framework and update display
        let roomsToUpdate = currentFloor.deleteWall(id, tryCreateRoom);
        for (i = 0; i < roomsToUpdate.length; ++i) {
            eventsManager.instance.dispatch(Events.REDRAW_GRAPHICAL_ROOM, {
                entity: roomsToUpdate[i],
            });
        }
        for (i = 0; i < selectedEntities.length; i++) {
            if (selectedEntities[i].id === id && selectedEntities[i].isWallEntity()) {
                selectedEntities.splice(i, 1);
                break;
            }
        }

        //Recompute all items
        for (i = 0; i < itemToRecompute.length; i++) {
            let item = itemToRecompute[i];
            //Recompute parent
            if (item.isArrangementObjectEntity() || item.isArrangementGroupEntity() || item.isRenderCameraEntity() || item.isGeometryPrimitiveEntity() || item.isWorktopEntity()) {
                // Find containing room
                let roomContaining = null;

                if (item.isWorktopEntity()) {
                    let area = item.getComponent(ComponentConstants.ComponentType.Area);
                    if (area) {
                        roomContaining = roomManager.getRoomAtPosition(area.vertices[0], currentFloor);
                    }
                } else {
                    roomContaining = roomManager.getRoomAtPosition(item.position, currentFloor);
                }

                if (roomContaining !== null) {
                    //set item child of room
                    if (item.parent !== null) {
                        item.parent.deleteChild(item.id);
                    }
                    roomContaining.addChild(item, 100);
                } else {
                    //set item child of floor
                    if (item.parent !== null) {
                        item.parent.deleteChild(item.id);
                    }
                    currentFloor.addChild(item);
                }
                //Graphical node
                eventsManager.instance.dispatch(Events.ADD_GRAPHICAL_ENTITY, {
                    entity: item,
                });
            }
        }
        return roomsToUpdate;
    }

    cutWallForCommand(wall: Wall, cuttedWalls: Array<Wall>, currentFloor: Floor, selectedEntities: Array<Entity>) : void {
        let i, j;
        //Store wall rooms :
        let rooms = [];
        let itemToRecompute = [];
        //FIXME wall.rooms is undefined and length can't be retrieved
        for (i = 0; i < wall.rooms.length; i++) {
            rooms.push({
                room: EntityFactory.cloneRoom(wall.rooms[i], false, false),
                side: wall.rooms[i].wallOrientationInRoom(wall, undefined),
            });
            for (j = 0; j < wall.rooms[i].walls.length; j++) {
                rooms[i].room.addWall(wall.rooms[i].walls[j]);
            }
            for (j = 0; j < wall.rooms[i].children.length; j++) {
                itemToRecompute.push(wall.rooms[i].children[j]);
            }
        }
        this.deleteWallForCommand(wall.id, false, currentFloor, selectedEntities);
        for (i = 0; i < cuttedWalls.length; i++) {
            this.addWallForCommand(cuttedWalls[i], false, currentFloor, cuttedWalls[i].height);
        }
        for (i = 0; i < rooms.length; i++) {
            let idRemoved = rooms[i].room.deleteWall(wall.id);
            if (idRemoved >= rooms[i].room._walls.length) {
                if (!rooms[i].side) {
                    for (j = cuttedWalls.length - 1; j >= 0; j--) {
                        rooms[i].room.addWall(cuttedWalls[j]);
                    }
                } else {
                    for (j = 0; j < cuttedWalls.length; j++) {
                        rooms[i].room.addWall(cuttedWalls[j]);
                    }
                }
            } else {
                if (!rooms[i].side) {
                    for (j = 0; j < cuttedWalls.length; j++) {
                        rooms[i].room.addWallAtIndex(cuttedWalls[j], idRemoved);
                    }
                } else {
                    for (j = cuttedWalls.length - 1; j >= 0; j--) {
                        rooms[i].room.addWallAtIndex(cuttedWalls[j], idRemoved);
                    }
                }
            }

            currentFloor.addRoom(rooms[i].room);
            roomManager.manageNonRoomedWalls(currentFloor);
            eventsManager.instance.dispatch(Events.REDRAW_GRAPHICAL_ROOM, {
                entity: rooms[i].room,
            });
        }
        //Recompute all items
        for (i = 0; i < itemToRecompute.length; i++) {
            let item = itemToRecompute[i];
            //Recompute parent
            if (item.isArrangementObjectEntity() || item.isArrangementGroupEntity() || item.isRenderCameraEntity() || item.isGeometryPrimitiveEntity() || item.isWorktopEntity()) {
                // Find containing room
                let roomContaining = null;

                if (item.isWorktopEntity()) {
                    let area = item.getComponent(ComponentConstants.ComponentType.Area);
                    if (area) {
                        roomContaining = roomManager.getRoomAtPosition(area.vertices[0], currentFloor);
                    }
                } else {
                    roomContaining = roomManager.getRoomAtPosition(item.position, currentFloor);
                }

                if (roomContaining !== null) {
                    //set item child of room
                    if (item.parent !== null) {
                        item.parent.deleteChild(item.id);
                    }
                    roomContaining.addChild(item);
                } else {
                    //set item child of floor
                    if (item.parent !== null) {
                        item.parent.deleteChild(item.id);
                    }
                    currentFloor.addChild(item);
                }
                //Graphical node
                eventsManager.instance.dispatch(Events.ADD_GRAPHICAL_ENTITY, {
                    entity: item,
                });
            }
        }
    }

    mergeWallsForCommand(wall: Wall, mergedWalls: Array<Wall>, currentFloor: Floor, selectedEntities: Array<Entity>) : void {
        let i, j;
        //Store wall rooms :
        let rooms = [];
        let itemToRecompute = [];
        for (i = 0; i < mergedWalls[0].rooms.length; i++) {
            rooms.push({
                room: EntityFactory.cloneRoom(mergedWalls[0].rooms[i], false, false),
                side: mergedWalls[0].rooms[i].wallOrientationInRoom(mergedWalls[0], undefined),
            });
            for (j = 0; j < mergedWalls[0].rooms[i].children.length; j++) {
                itemToRecompute.push(mergedWalls[0].rooms[i].children[j]);
            }
            for (j = 0; j < mergedWalls[0].rooms[i].walls.length; j++) {
                rooms[i].room.addWall(mergedWalls[0].rooms[i].walls[j]);
            }
        }
        for (i = 0; i < mergedWalls.length; i++) {
            this.deleteWallForCommand(mergedWalls[i].id, rooms.length === 0, currentFloor, selectedEntities);
        }
        this.addWallForCommand(wall, false, currentFloor, wall.height);
        for (i = 0; i < rooms.length; i++) {
            for (j = 1; j < mergedWalls.length; j++) {
                rooms[i].room.deleteWall(mergedWalls[j].id);
            }
            let idRemoved = rooms[i].room.deleteWall(mergedWalls[0].id);
            if (idRemoved >= rooms[i].room._walls.length) {
                rooms[i].room.addWall(wall);
            } else {
                rooms[i].room.addWallAtIndex(wall, idRemoved);
            }

            eventsManager.instance.dispatch(Events.REMOVE_GRAPHICAL_ENTITY, {
                entity: rooms[i].room,
            });
            currentFloor.addRoom(rooms[i].room);
            roomManager.manageNonRoomedWalls(currentFloor);
            eventsManager.instance.dispatch(Events.REDRAW_GRAPHICAL_ROOM, {
                entity: rooms[i].room,
            });
        }
        //Recompute all items
        for (i = 0; i < itemToRecompute.length; i++) {
            let item = itemToRecompute[i];
            //Recompute parent
            if (item.isArrangementObjectEntity() || item.isArrangementGroupEntity() || item.isRenderCameraEntity() || item.isGeometryPrimitiveEntity() || item.isWorktopEntity()) {
                // Find containing room
                let roomContaining = null;

                if (item.isWorktopEntity()) {
                    let area = item.getComponent(ComponentConstants.ComponentType.Area);
                    if (area) {
                        roomContaining = roomManager.getRoomAtPosition(area.vertices[0], currentFloor);
                    }
                } else {
                    roomContaining = roomManager.getRoomAtPosition(item.position, currentFloor);
                }

                if (roomContaining !== null) {
                    //set item child of room
                    if (item.parent !== null) {
                        item.parent.deleteChild(item.id);
                    }
                    roomContaining.addChild(item, 100);
                } else {
                    //set item child of floor
                    if (item.parent !== null) {
                        item.parent.deleteChild(item.id);
                    }
                    currentFloor.addChild(item);
                }
                //Graphical node
                eventsManager.instance.dispatch(Events.ADD_GRAPHICAL_ENTITY, {
                    entity: item,
                });
            }
        }
    }
}

export let wallManager = new WallManager();
