import { Command, CommandEnum, LeaveGroupCommand } from './CommandModule';
import { Events } from '../events';
import { eventsManager } from '../managers/EventsManager';
import { Entity } from '../SavaneJS';

export class EntitiesLeaveGroupCommand  extends Command
{
    // Command list to execute and undo
    private _subCommands: Array<Command> = [];
    // Array of entities leaving their parent + the parent to set the selection after the command is executed
    private _leavingItemList: Array<Entity>;
    // Parent being left
    private _parent: Entity;

    constructor (leavingItemList: Array<Entity>, parent: Entity) {
        super();
        // Record the entity Array
        this._leavingItemList = leavingItemList;
        // Record parent
        this._parent = parent;
        // Parse all entity array passed to the constructor
        for (let i = 0; i < this._leavingItemList.length; i++) {
            // And create a LeaveGroupCommand for each of them
            this._subCommands.push(new LeaveGroupCommand(this._leavingItemList[i]));
        }
        this._leavingItemList.push(parent);
    }

    // Command name returned from a global enum
    name(): string {
        return CommandEnum.EntitiesLeaveGroupCommand;
    }

    // Undo the current command
    undo() {
        // Parse all commands created by the constructor and undo them all
        for (let i = 0; i < this._subCommands.length; i++) {
            this._subCommands[i].undo();
        }

        // Execute parent function
        super.undo();

        // Select edited object at the end
        eventsManager.instance.dispatch(Events.CHANGE_EDITOR_SELECTION,
            {
                selection: [this._parent],
                keepSelected: false,
                showTulip: false
            });
    }

    // Execute current command (redo)
    execute() {
        // Parse all commands created by the constructor and execute them all
        for (let i = 0; i < this._subCommands.length; i++) {
            this._subCommands[i].execute();
        }

        //Execute parent function
        super.execute();

        // Select edited object at the end
        eventsManager.instance.dispatch(Events.CHANGE_EDITOR_SELECTION,
            {
                selection: this._leavingItemList,
                keepSelected: false,
                showTulip: false
            });
    }
};
