import { TemporaryEntity } from "./temporary/TemporaryEntity";
import { Component, ComponentConstants, World, Scene, Floor, Room, SceneConstants, math, Transform } from "../SavaneJS";

/** Polyfill ie */
if (!Array.prototype.find) {
  Object.defineProperty(Array.prototype, "find", {
    value: function (predicate) {
      if (this == null) {
        throw new TypeError("this is null or not defined");
      }

      var obj = Object(this);
      var len = obj.length >>> 0;

      if (typeof predicate !== "function") {
        throw new TypeError("predicate must be a function");
      }

      var thisArg = arguments[1];

      var index = 0;

      while (index < len) {
        var iValue = obj[index];
        if (predicate.call(thisArg, iValue, index, obj)) {
          return iValue;
        }
        index++;
      }

      return undefined;
    },
  });
}

/**
 * Entity is a scene object, an entity just has a spatial and hierarchy information, components can add more
 * behavior on it
 *
 * @constructor
 */
export class Entity {
    accessor entityType: SceneConstants.EntityType = null;

    public temporary: TemporaryEntity | null;
    public components: Array<Component>;
    public children: Array<Entity>;

    protected _id: number = -1;
    private _name: string | null;
    protected _transform: Transform;
    private _parent: Entity | null;
    private _locked: boolean;
    protected _holdout: boolean;
    private _hideAtRendering: boolean;

    constructor() {
        if (this.constructor === Entity) {
            throw new TypeError('Abstract class "Entity" cannot be instantiated directly.');
        }

        if (this.entityType === null) {
            throw new TypeError("Classes extending the Entity abstract class require the entityType property");
        }
        //temporary entity used during edition
        this.temporary = null;

        this._name = null;
        this._transform = new Transform(this);
        this.components = new Array<Component>();
        this.children = new Array<Entity>();
        this._parent = null;
        this._locked = false;
        this._holdout = false;
        this._hideAtRendering = false;
    }

    get id() : number {
        return this._id;
    }

    set id(id: number) {
        this._id = id;
    }

    get parent(): Entity {
        return this._parent;
    }

    set parent(p: Entity) {
        this._parent = p;
    }

    /**
     * Getter for the locked of the entity
     *
     * @returns {*}
     */
    get locked(): boolean {
        return this._locked;
    }

    /**
     * Setter for the locked of the entity
     *
     * @param {Number} locked
     */
    set locked(l: boolean) {
        this._locked = l;
    }

    /**
     * Getter for the holdout of the entity
     *
     * @returns {*}
     */
    get holdout(): boolean {
        return this._holdout;
    }

    /**
     * Setter for the holdout of the entity
     *
     * @param {Boolean} holdout
     */
    set holdout(h: boolean) {
        this._holdout = h;
    }

    /**
     * Getter for the hideAtRendering of the entity
     *
     * @returns {*}
     */
    get hideAtRendering(): boolean {
        return this._hideAtRendering;
    }

    /**
     * Setter for the hideAtRendering of the entity
     *
     * @param {Boolean} hideAtRendering
     */
    set hideAtRendering(h: boolean) {
        this._hideAtRendering = h;
    }

    /**
     * Getter for the name of the entity
     *
     * @returns {*}
     */
    get name(): string {
        return this._name;
    }

    /**
     * Setter for the name of the entity
     *
     * @param {String} name
     */
    set name(n: string) {
        this._name = n;
    }

    /**
     * Setter for the transform of the entity
     *
     * @param {Transform} transform
     */
    set transform(t: Transform) {
        this._transform = t;
    }

    /**
     * Getter for the transform of the entity
     *
     * @returns {*}
     */
    get transform(): Transform {
        return this._transform;
    }

    /**
     * Getter for the global position of the entity
     *
     * @returns {*}
     */
    get position(): math.vec3 {
        return this._transform.globalPosition;
    }

    /**
     * Setter for the global position of the entity
     *
     * @returns {*}
     */
    set position(p: math.vec3) {
        throw new TypeError('Not implemented on entity type ' + this.entityType + ' - Abstract class');
    }

    set angle(a: number) {
        throw new TypeError('Not implemented on entity type ' + this.entityType + ' - Abstract class');
    }

    get floorHeight(): number {
        throw new TypeError('Not implemented on entity type ' + this.entityType + ' - Abstract class');
    }

    set floorHeight(fh: number) {
        throw new TypeError('Not implemented on entity type ' + this.entityType + ' - Abstract class');
    }

    /**
     * set position = center
     *
     */
    recenter() {
    }

    get world(): World {
        if (this.isWorldEntity()) return this as unknown as World;

        let parent = this.parent;
        while (parent && !parent.isWorldEntity()) {
            parent = parent.parent;
        }

        return (parent as World);
    }

    get scene() : Scene | null {
        if (this.isSceneEntity()) return this as unknown as Scene;

        if (this.isWorldEntity()) return null;

        let parent = this.parent;
        while (parent && !parent.isSceneEntity()) {
            parent = parent.parent;
        }

        return (parent as Scene);
    }

    get floor(): Floor | null {
        if (this.isFloorEntity()) return this as unknown as Floor;

        if (this.isWorldEntity() || this.isSceneEntity()) return null;

        let parent = this.parent;
        while (parent && !parent.isFloorEntity()) {
            parent = parent.parent;
        }

        return (parent as Floor);
    }

    get room(): Room | null {
        if (this.isRoomEntity()) return this as unknown as Room;

        if (this.isWorldEntity() || this.isSceneEntity() || this.isFloorEntity()) return null;

        let parent = this.parent;
        while (parent && !parent.isRoomEntity()) {
            parent = parent.parent;
        }

        return (parent as Room);
    }

    isSnappableColumnArrangement(): boolean {
        return false;
    }

    isSnappableBottomArrangement(): boolean {
        return false;
    }

    isWorktopBottomArrangement(): boolean {
        return false;
    }

    isSnappableTopArrangement(): boolean {
        return false;
    }

    getCoatingQuantity(component: Component): number {
        return(0);
    }

    /**
     * Add a component to the entity, some components can not be added multiple time
     * Use component.isUnique to determine if components can be added multiple time
     * if can be multiple set an id for the component
     *
     * @param {Component} component
     */
    addComponent(component: Component) {
        component.entity = this;

        if (!component.isUnique) {
            this.components.push(component);
            let areas = this.getComponents(ComponentConstants.ComponentType.CoatingArea);
            areas = areas.concat(this.getComponents(ComponentConstants.ComponentType.CutArea));
            areas = areas.concat(this.getComponents(ComponentConstants.ComponentType.AddArea));

            component.id = areas.reduce(function (a, b) {
              return b.id !== undefined && b.id !== null && b.id >= 0 && b.id >= a
                ? b.id + 1
                : a;
            }, 0);
        } else {
            let foundSameComponentType = false;
            for (let i = 0; i < this.components.length; i++) {
                if (this.components[i].componentType === component.componentType) {
                  this.components[i] = component;
                  foundSameComponentType = true;
                  break;
                }
            }
            if (!foundSameComponentType) {
                this.components.push(component);
            }
        }
    }

    /**
     * Remove a componenet from the entity
     *
     * @param component
     * @returns {*}
     */
    removeComponent(component: Component) {
        let index = this.components.indexOf(component);
        if (index < 0) {
            console.error("This component doesn't belong to that entity");
            return null;
        }
        this.components.splice(index, 1);
        return component;
    }

    /**
     * Replace a component by another one
     * @param {*} oldComponent
     * @param {*} newComponent
     */
    replaceComponent(oldComponent: Component, newComponent: Component) {
        let index = this.components.indexOf(oldComponent);
        if (index !== -1) {
          this.components[index] = newComponent;
        }
    }

    /**
     * Shift a component
     * @param component
     * @returns {*}
     */
    shiftComponent(component: Component) {
        let index = this.components.indexOf(component);
        if (index !== -1) {
            let newIndex = this.components.length - 1;
            for (let i = index + 1; i < this.components.length; ++i) {
                if (this.components[i].componentType === component.componentType) {
                    newIndex = i;
                    break;
                }
            }
            this.components.splice(index, 1);
            this.components.splice(newIndex, 0, component);
        }
    }

    /**
     * Unshift a component
     * @param component
     * @returns {*}
     */
    unshiftComponent(component: Component) {
        let index = this.components.indexOf(component);
        if (index !== -1) {
            let newIndex = 0;
            for (let i = index - 1; i >= 0; --i) {
                if (this.components[i].componentType === component.componentType) {
                    newIndex = i;
                    break;
                }
            }
            this.components.splice(index, 1);
            this.components.splice(newIndex, 0, component);
        }
    }

    /**
     * Retrieve component using componentType
     ********DEPRECATED PLEASE USE getComponents************
    * @param {Number} componentType
    * @returns {*}
    */
    getComponent(componentType: ComponentConstants.ComponentType): Component {
        for (let i = 0; i < this.components.length; i++) {
            if (this.components[i].componentType === componentType) {
                return this.components[i];
            }
        }
        return null;
    }

    /**
     * Retrieve component's array using componentType
     *
     * @param {Number} componentType
     * @returns {*}
     */
    getComponents(componentType: ComponentConstants.ComponentType): Array<Component> {
        let components = new Array<Component>();
        for (let i = 0; i < this.components.length; i++) {
            if (this.components[i].componentType === componentType) {
              components.push(this.components[i]);
            }
        }
        return components;
    }

    /**
     * set  value of a property within cap value.
     *
     * return value :
     * -1 : property not found
     * 0 : value set not capped
     * 1 : value set min capped
     * 2 : value set max capped
     */
    addToCappedProperty(property: string, value: any) : number {
        if (this[property] === undefined) {
            return -1;
        } else {
            this[property] += value;
        }
        return 0;
    }

    /**
     * Add a child to the entity
     *
     * @param {Entity} entity
     */
    addChild(entity: Entity) {
        if (!this.children.find((item) => item.id === entity.id)) {
            this.children.push(entity);
            entity.parent = this;
        } else {
            console.warn("Try to add a child twice");
        }
    }

    /**
     * delete a child of id = entityId
     *
     * @param {Number} entityId
     */
    deleteChild(entityId: number) {
        let count = this.children.length;
        this.children = this.children.filter(function (item) {
            if (item.id !== entityId) {
                return true;
            } else {
                // WORKAROUND POUR NICO a chercher creation de pièce nécessite ça
                if (item.isRoomEntity()) {
                    item.parent = null;
                }
                return false;
            }
        });
        try {
            if (count === this.children.length) {
                throw new TypeError("There is no children of id = parameter");
            }
        } catch (err) {
            console.log(err);
        }
    }

    /**
     * delete the current entity from his parent children array
     */
    deleteFromParent() {
        if (this.parent !== null) {
            this.parent.deleteChild(this.id);
        }
    }

    /**
     * Remove all child from entity
     */
    deleteAllChild() {
        this.children = [];
    }

    /**
     * Retrieve child using id
     *
     * @param {Number} entityId
     * @returns {*}
     */
    getChild(entityId: number): Entity {
        let count = this.children.length;
        for (let i = 0; i < count; i++) {
            if (this.children[i].id === entityId) {
              return this.children[i];
            }
        }
        console.log("There is no children of id = parameter");
        return null;
    }

    /**
     * Retrieve child of child of child using id
     *
     * @param {Number} entityId
     * @returns {*}
     */
    getDeepChild(entityId: number): Entity {
        let count = this.children.length;
        for (let i = 0; i < count; i++) {
            if (this.children[i].id === entityId) {
                return this.children[i];
            } else {
                let subChild = this.children[i].getDeepChild(entityId);
                if (subChild !== null) {
                    return subChild;
                }
            }
        }
        //        console.log('There is no children of id = parameter');
        return null;
    }

    /**
     * Retrieve all children of type contains in entityTypes through all children of type contains in entityTypesToBrowse
     *
     * @param {Array} entityTypes : null === all types
     * @param {Array} entityTypesToBrowse : null === all types
     * @returns {Array}
     */
    getChildren(entityTypes: Array<SceneConstants.EntityType> | null = null, entityTypesToBrowse: Array<SceneConstants.EntityType> | null = null): Array<Entity> {
        let children = new Array<Entity>();
        for (let i = 0; i < this.children.length; i++) {
            if (entityTypes === null || entityTypes.indexOf(this.children[i].entityType) !== -1) {
                children.push(this.children[i]);
            }

            if (entityTypesToBrowse === null || entityTypesToBrowse.indexOf(this.children[i].entityType) !== -1) {
                children = children.concat(this.children[i].getChildren(entityTypes, entityTypesToBrowse));
            }
        }

        return children;
    }

    getComponentsFromChildren(entityTypes: Array<SceneConstants.EntityType> = null, entityTypesToBrowse: Array<SceneConstants.EntityType> = null, componentTypes: Array<ComponentConstants.ComponentType> | null = null): Array<Component> {
        let children = this.getChildren(entityTypes, entityTypesToBrowse);
        let componentsFromChildren = new Array<Component>();

        if (componentTypes !== null) {
            for (let i = 0; i < children.length; i++) {
                let child = children[i];

                for (let j = 0; j < componentTypes.length; j++) {
                    let componentType = componentTypes[j];
                    let components = child.getComponents(componentType);

                    componentsFromChildren = componentsFromChildren.concat(components);
                }
            }
        }

        return componentsFromChildren;
    }

    /**
     * Test for entity types
     */
    isWorldEntity(): boolean {
        return(this.entityType === SceneConstants.EntityType.World);
    }

    isSceneEntity(): boolean {
        return(this.entityType === SceneConstants.EntityType.Scene);
    }

    isFloorEntity(): boolean {
        return(this.entityType === SceneConstants.EntityType.Floor);
    }

    isRoomEntity(): boolean {
        return(this.entityType === SceneConstants.EntityType.Room);
    }

    isWallEntity(): boolean {
        return(this.entityType === SceneConstants.EntityType.Wall);
    }

    isJoineryEntity(): boolean {
        return(this.entityType === SceneConstants.EntityType.Joinery);
    }

    isArrangementObjectEntity(): boolean {
        return(this.entityType === SceneConstants.EntityType.ArrangementObject);
    }

    isArrangementGroupEntity(): boolean {
        return(this.entityType === SceneConstants.EntityType.ArrangementGroup);
    }

    isIArrangementEntity(): boolean {
        return(this.isArrangementObjectEntity() || this.isArrangementGroupEntity());
    }

    isIGeometryEntity(): boolean {
        return(this.isGeometryPrimitiveEntity() || this.isGeometryGroupEntity());
    }

    isArrangementZoneEntity(): boolean {
        return(this.entityType === SceneConstants.EntityType.ArrangementZone);
    }

    isSketchBlockEntity(): boolean {
        return(this.entityType === SceneConstants.EntityType.SketchBlock);
    }
    
    isTechnicalElementEntity(): boolean {
        return(this.entityType === SceneConstants.EntityType.TechnicalElement);
    }

    isStaircaseEntity(): boolean {
        return(this.entityType === SceneConstants.EntityType.Staircase);
    }

    isFunctionalityChipEntity(): boolean {
        return(this.entityType === SceneConstants.EntityType.FunctionalityChip);
    }

    isWorktopEntity(): boolean {
        return(this.entityType === SceneConstants.EntityType.WorkTop);
    }

    isRenderCameraEntity(): boolean {
        return(this.entityType === SceneConstants.EntityType.RenderCamera);
    }

    isUserPictureEntity(): boolean {
      return(this.entityType === SceneConstants.EntityType.UserPicture);
    }

    isCommentEntity(): boolean {
        return(this.entityType === SceneConstants.EntityType.Comment);
    }

    isGeometryPrimitiveEntity(): boolean {
        return(this.entityType === SceneConstants.EntityType.GeometryPrimitive);
    }

    isSunEntity(): boolean {
      return(this.entityType === SceneConstants.EntityType.Sun);
    }

    isGeometryGroupEntity(): boolean {
        return(this.entityType === SceneConstants.EntityType.GeometryGroup);
    }

    /**
     * Test whether the entity is temporary
     */
    isTemporary(): boolean {
        if (this.temporary === null) {
            return false;
        }
        return true;
    }

    /**
     * Getter for the validity of the entity
     */
    isValid(floor: Floor): boolean {
        if (this.temporary === null) {
            return true;
        } else {
            return this.temporary.isValid(floor);
        }
    }

    inverseScaleOnHandles(invScale: math.vec3) {
        for (let i = 0; i < this.children.length; ++i) {
            let child = this.children[i];
            if (child.isArrangementObjectEntity() && child.parent && child.parent.isArrangementZoneEntity()) {
                child.transform.localScale = invScale;
            }
            let scale = child.transform.globalScale;
            child.inverseScaleOnHandles([1 / scale[0], 1 / scale[1], 1 / scale[2]]);
        }
    }

    /**
     * If return true will be consider as a ghost
     * Will be ingored by designated methods
     *
     **/
    isGhost(): boolean {
        return false;
    }

    // Default behaviour of start, end and saveAndEndTemporary ==> do nothing
    startTemporary() {
    }

    endTemporary() {
    }

    saveAndEndTemporary() {
    }

    //Default return null (used to draw 2d level)
    get maxHeight(): number{
        return null;
    }
}
