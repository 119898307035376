import { Command, CommandEnum } from './CommandModule';
import { Events } from '../events';
import { eventsManager } from '../managers/EventsManager';
import { Entity, GeometryGroup, GeometryPrimitive, math } from '../SavaneJS';

export class DeleteGeometryGroupCommand  extends Command
{
    // Group to delete
    private _deletedGroup: GeometryGroup;
    // Parent of the group to delete (to reparent children)
    private _deletedGroupParent: Entity;
    // Geometry removed from the group
    private _deletedGroupGeometries: Array<(GeometryPrimitive | GeometryGroup)>;
    
    constructor(deletedGroup: GeometryGroup) {
        super();
        // Store group parent
        this._deletedGroupParent = deletedGroup.parent;
        // Clone group
        this._deletedGroup = deletedGroup;
        // Store geometries in group
        this._deletedGroupGeometries = deletedGroup.iGeometries;
    };

    // Command name returned from a global enum
    name(): string {
        return CommandEnum.DeleteGeometryGroupCommand;
    }

    // Datas that allows the command to be executed
    execDatas(): any {
        return {
            group: this._deletedGroup,
        };
    }

    // Undo the current command
    undo() {
        // Add deleted group to the scene
        this._deletedGroupParent.addChild(this._deletedGroup);
        let toLocal = this._deletedGroup.transform.invertedGlobalMatrix;
        // Parse all objects in the group
        for (let i = 0; i < this._deletedGroupGeometries.length; i++) {
            // Get object from current floor
            let arr = this._deletedGroupGeometries[i];

            // Remove object graphical node
            eventsManager.instance.dispatch(Events.REMOVE_GRAPHICAL_ENTITY,
                {
                    entity: arr,
                });

            let world = arr.transform.globalMatrix;

            arr.deleteFromParent();
            // Set object parent to the group itself
            this._deletedGroup.addChild(arr);

            let matrix = math.mat4.create();
            math.mat4.multiply(matrix, toLocal, world);
            math.mat4.copy(arr.transform.localMatrix, matrix);
        }

        this._deletedGroup.recenter();
        // Recreate group graphical node
        eventsManager.instance.dispatch(Events.ADD_GRAPHICAL_ENTITY,
            {
                entity: this._deletedGroup,
            });

        // Recompute group content
        let entity = this._deletedGroupParent;
        while (entity.isGeometryGroupEntity()) {
            entity.recenter();

            // Redraw all parents (their size might have changed due to recenter call above)
            eventsManager.instance.dispatch(Events.REDRAW_GRAPHICAL_ENTITY,
                {
                    entity: entity
                });

            entity = entity.parent;
        }

        // Select created group at the end
        eventsManager.instance.dispatch(Events.CHANGE_EDITOR_SELECTION,
            {
                selection: [this._deletedGroup],
                keepSelected: false,
                showTulip: false
            });

        // Execute parent function
        super.undo();
    }

    // Execute current command (redo)
    execute() {
        // Remove group entity
        eventsManager.instance.dispatch(Events.REMOVE_GRAPHICAL_ENTITY,
            {
                entity: this._deletedGroup,
            });

        let toWorld = this._deletedGroupParent.transform.invertedGlobalMatrix;

        // Parse all objects on the group
        for (let i = 0; i < this._deletedGroupGeometries.length; i++) {
            // Get object from current floor
            let arr = this._deletedGroupGeometries[i];

            let global = arr.transform.globalMatrix;

            arr.deleteFromParent();
            // get deleted group parent
            this._deletedGroupParent.addChild(arr);

            // Reassign position and orientation
            let matrix = math.mat4.create();
            math.mat4.multiply(matrix, toWorld, global);
            math.mat4.copy(arr.transform.localMatrix, matrix);

            // Recreate each sub entity graphical node
            eventsManager.instance.dispatch(Events.ADD_GRAPHICAL_ENTITY,
                {
                    entity: arr
                });
        }

        // Remove the group from its parent so it disapears
        this._deletedGroup.deleteFromParent();
        // Recompute group content
        let entity = this._deletedGroupParent;
        while (entity && entity.isGeometryGroupEntity()) {
            entity.recenter();

            // Redraw all parents (their size might have changed due to recenter call above)
            eventsManager.instance.dispatch(Events.REDRAW_GRAPHICAL_ENTITY,
                {
                    entity: entity
                });

            entity = entity.parent;
        }

        // Select the objects used to create the group originally
        eventsManager.instance.dispatch(Events.CHANGE_EDITOR_SELECTION,
            {
                selection: this._deletedGroupGeometries,
                keepSelected: false,
                showTulip: false
            });

        //Execute parent function
        super.execute();
    }
}
