import { Command, CommandEnum } from './CommandModule';
import { Events } from '../events';
import { eventsManager } from '../managers/EventsManager';
import { EntityFactory, Floor, Room } from '../SavaneJS';

export class SetRoomFloorHeightCommand extends Command
{
    // Dekta floor height to apply
    private _deltaFloorHeight: number;
    // Current floor
    private _floor: Floor;
    // Array of rooms to modify
    private _clonedRooms: Array<Room> = [];

    constructor(deltaFloorHeight: number, floor: Floor) {
        super();
        // Activate of not value
        this._deltaFloorHeight = deltaFloorHeight;
        // Store floor to set cornices on, default will be current floor if not specified in the parameters
        this._floor = floor;

        // Parse all rooms of the floor
        for (let i = 0; i < floor.rooms.length; i++) {
            // Keep a cloned room for undo
            this._clonedRooms.push(EntityFactory.cloneEntity(floor.rooms[i], false) as Room);
        }
    }

    // Command name returned from a global enum
    name(): string {
        return CommandEnum.SetRoomFloorHeightCommand;
    }

    // Datas that allows the command to be executed
    execDatas(): any {
        return {
            deltaFloorHeight: this._deltaFloorHeight
        };
    }

    // Undo the current command
    undo() {
        // Restore all room height values
        for (let i = 0; i < this._floor.rooms.length; i++) {
            this._floor.rooms[i].floorHeight = this._clonedRooms[i].floorHeight;

            // Redraw Node
            eventsManager.instance.dispatch(Events.REDRAW_GRAPHICAL_ENTITY,
                {
                    entity: this._floor.rooms[i],
                    extraActions: ['updateName'],
                });
        }

        super.undo();
    }

    // Execute current command (redo),
    execute() {
        // Parse all rooms of the floor
        for (let i = 0; i < this._floor.rooms.length; i++) {
            // Modify floor height of room
            this._floor.rooms[i].floorHeight += this._deltaFloorHeight;

            // Redraw Node
            eventsManager.instance.dispatch(Events.REDRAW_GRAPHICAL_ENTITY,
                {
                    entity: this._floor.rooms[i],
                    extraActions: ['updateName'],
                });
        }

        super.execute();
    }
};
