import { SavaneConstants } from "./SavaneConstants";
import { SceneConstants } from "../scene/SceneConstants";
import { ComponentConstants } from "../components/ComponentConstants";
import { EntityFactory } from "../scene/EntityFactory";
import { JSONSerializer } from "./JSONSerializer";
import { math } from "../scene/Transform";
import { Coating } from "../components/Coating";

/**
 * Class which contains functions for converting format
 */
export class VersionHandler {
    constructor() {}

    /**
     * convert to a given format
     * @param jsonObject
     * @param version
     * @returns {*}
     */
    static convertToVersion(jsonObject: any, version: number) : any {
        if (jsonObject.formatVersion === null || jsonObject.formatVersion === undefined) {
            throw new TypeError("Cannot convert object version");
        } else {
            if (jsonObject.formatVersion < version) {
                return VersionHandler.convertToVersion(VersionHandler.goToNextVersion(jsonObject), version);
            } else if (jsonObject.formatVersion > version) {
                return VersionHandler.convertToVersion(VersionHandler.goToPreviousVersion(jsonObject), version);
            } else {
                return jsonObject;
            }
        }
    }

    static goToPreviousVersion(jsonObject: any) : any | null {
        switch (jsonObject.formatVersion) {
            default:
                return null;
        }
    }

    static goToNextVersion(jsonObject: any) : any | null {
        switch (jsonObject.formatVersion) {
            case 1:
            case 1.1:
                let lastId = VersionHandler.findLastId(jsonObject);
                VersionHandler.go1p1To1p2Rec(jsonObject, lastId);
                jsonObject.formatVersion = 1.2;
                return jsonObject;
            case 1.2:
                VersionHandler.go1p2To1p3Rec(jsonObject, null);
                jsonObject.formatVersion = 1.3;
                return jsonObject;
            case 1.3:
                //delete old furniture finishes
                VersionHandler.go1p3To1p4Rec(jsonObject);
                jsonObject.formatVersion = 1.4;
                return jsonObject;
            case 1.4:
                // set new root as world not scene
                jsonObject = VersionHandler.go1p4to2p0Rec(jsonObject);
                jsonObject.formatVersion = 2.0;
                return jsonObject;
            case 2.0:
            case 2.1:
                // the version 2.1 handle the slope coating change
                jsonObject = VersionHandler.go2p0to2p1Rec(jsonObject);
                jsonObject.formatVersion = 2.1;
                return jsonObject;
            default:
                return null;
        }
    }

    /**
     * Convert the format to the last version
     * @param JSONScene
     * @returns {*}
     */
    static toLastVersion(JSONScene: any) : any {
        return VersionHandler.convertToVersion(JSONScene, SavaneConstants.formatVersion);
    }

    /**
     * find the last id of entity in the scene
     * @param JSONObject
     */
    static findLastId(JSONObject: any) : number {
        let lastId = JSONObject.id;
        for (let i = 0; i < JSONObject.children.length; i++) {
            var childId = VersionHandler.findLastId(JSONObject.children[i]);
            if (lastId < childId) {
                lastId = childId;
            }
        }
        return lastId;
    }

    /**
     * convert 1.2 to 1.3 recursively
     */
    static go1p2To1p3Rec(JSONObject: any, globalParentMatrix: math.mat4) : any {
        if ((globalParentMatrix !== null && JSONObject.entityType === SceneConstants.EntityType.ArrangementGroup) || JSONObject.entityType === SceneConstants.EntityType.ArrangementGroup || JSONObject.entityType === SceneConstants.EntityType.Staircase || JSONObject.entityType === SceneConstants.EntityType.TechnicalElement) {
            var globalAngle = Math.atan2(globalParentMatrix[1], globalParentMatrix[0]);
            math.mat4.rotateZ(JSONObject.transform.localMatrix, JSONObject.transform.localMatrix, -globalAngle);
            math.mat4.rotateZ(JSONObject.transform.localMatrix, JSONObject.transform.localMatrix, JSONObject.angle);
        }
        if (globalParentMatrix !== null) {
            math.mat4.multiply(globalParentMatrix, JSONObject.transform.localMatrix, globalParentMatrix);
        } else {
            globalParentMatrix = math.mat4.clone(JSONObject.transform.localMatrix);
        }

        for (let i = 0; i < JSONObject.children.length; i++) {
            VersionHandler.go1p2To1p3Rec(JSONObject.children[i], globalParentMatrix);
        }

        return JSONObject;
    }

    /**
     * convert 1.1 to 1.2 recursively
     */
    static go1p1To1p2Rec(JSONObject: any, lastId: number) : any {
        if (JSONObject.entityType === SceneConstants.EntityType.Room) {
            for (let i = 0; i < JSONObject.children.length; ) {
                if (JSONObject.children[i].entityType === SceneConstants.EntityType.FunctionalityChip) {
                    JSONObject.children.splice(i, 1);
                } else {
                    i++;
                }
            }

            for (let i = 0; i < JSONObject.components.length; i++) {
                if (JSONObject.components[i].componentType === ComponentConstants.ComponentType.Functionality) {
                    let funcComponent = JSONObject.components[i];
                    let secondaryFuncs = funcComponent.secondaryFunctionalities;
                    let mainFunc = funcComponent.mainFunctionality;
                    JSONObject.components[i] = {
                        componentType: ComponentConstants.ComponentType.Functionality,
                        functionalityId: mainFunc.id,
                        functionalityName: mainFunc.name,
                    };

                    for (let j = 0; j < secondaryFuncs.length; j++) {
                        var chip = EntityFactory.createFunctionalityChip(secondaryFuncs[j].id, secondaryFuncs[j].name, secondaryFuncs[j].url, secondaryFuncs[j].secondaryUrl, ++lastId);
                        if (secondaryFuncs[j].position !== null) {
                            chip.position = [secondaryFuncs[j].position.x, secondaryFuncs[j].position.y, secondaryFuncs[j].position.z];
                            chip.isOnPlan = true;
                        } else {
                            chip.isOnPlan = false;
                        }
                        JSONObject.children.push(JSONSerializer.serializeEntity(chip));
                    }
                }
            }
        } else {
            for (let i = 0; i < JSONObject.children.length; i++) {
                VersionHandler.go1p1To1p2Rec(JSONObject.children[i], lastId);
            }
        }
        return JSONObject;
    }

    static go1p3To1p4Rec(JSONObject: any) : any {
        let i;
        for (i = JSONObject.components.length - 1; i >= 0; i--) {
            if (JSONObject.components[i].componentType === ComponentConstants.ComponentType.FurnitureFinishes) {
                JSONObject.components.splice(i, 1);
            }
        }

        for (i = 0; i < JSONObject.children.length; i++) {
            VersionHandler.go1p3To1p4Rec(JSONObject.children[i]);
        }
        return JSONObject;
    }

    static go1p4to2p0Rec(JSONObject: any) : any {
        var JSONWorld = JSONSerializer.serializeEntity(EntityFactory.createWorld());
        JSONWorld.children[0] = JSONObject;
        if (JSONObject.activeDrawTime !== undefined) {
            JSONWorld._activeDrawTime = JSONObject.activeDrawTime;
        }
        if (JSONObject.idleDrawTime !== undefined) {
            JSONWorld._idleDrawTime = JSONObject.idleDrawTime;
        }
        if (JSONObject.idleDrawDurations !== undefined) {
            JSONWorld._idleDrawDurations = JSONObject.idleDrawDurations;
        }

        if (JSONObject.activeDrawCRTime !== undefined) {
            JSONWorld._activeDrawCRTime = JSONObject.activeDrawCRTime;
        }
        if (JSONObject.idleDrawCRTime !== undefined) {
            JSONWorld._idleDrawCRTime = JSONObject.idleDrawCRTime;
        }
        if (JSONObject.idleDrawCRDurations !== undefined) {
            JSONWorld._idleDrawCRDurations = JSONObject.idleDrawCRDurations;
        }

        if (JSONObject.activeDrawCRVisioTime !== undefined) {
            JSONWorld._activeDrawCRVisioTime = JSONObject.activeDrawCRVisioTime;
        }
        if (JSONObject.idleDrawCRVisioTime !== undefined) {
            JSONWorld._idleDrawCRVisioTime = JSONObject.idleDrawCRVisioTime;
        }
        if (JSONObject.idleDrawCRVisioDurations !== undefined) {
            JSONWorld._idleDrawCRVisioDurations = JSONObject.idleDrawCRVisioDurations;
        }

        if (JSONObject.activeConstructionTime !== undefined) {
            JSONWorld._activeConstructionTime = JSONObject.activeConstructionTime;
        }
        if (JSONObject.idleConstructionTime !== undefined) {
            JSONWorld._idleConstructionTime = JSONObject.idleConstructionTime;
        }
        if (JSONObject.idleConstructionDurations !== undefined) {
            JSONWorld._idleConstructionDurations = JSONObject.idleConstructionDurations;
        }

        if (JSONObject.activeConstructionCRTime !== undefined) {
            JSONWorld._activeConstructionCRTime = JSONObject.activeConstructionCRTime;
        }
        if (JSONObject.idleConstructionCRTime !== undefined) {
            JSONWorld._idleConstructionCRTime = JSONObject.idleConstructionCRTime;
        }
        if (JSONObject.idleConstructionCRDurations !== undefined) {
            JSONWorld._idleConstructionCRDurations = JSONObject.idleConstructionCRDurations;
        }

        if (JSONObject.activeConstructionCRVisioTime !== undefined) {
            JSONWorld._activeConstructionCRVisioTime = JSONObject.activeConstructionCRVisioTime;
        }
        if (JSONObject.idleConstructionCRVisioTime !== undefined) {
            JSONWorld._idleConstructionCRVisioTime = JSONObject.idleConstructionCRVisioTime;
        }
        if (JSONObject.idleConstructionCRVisioDurations !== undefined) {
            JSONWorld._idleConstructionCRVisioDurations = JSONObject.idleConstructionCRVisioDurations;
        }

        if (JSONObject.activeDecorationTime !== undefined) {
            JSONWorld._activeDecorationTime = JSONObject.activeDecorationTime;
        }
        if (JSONObject.idleDecorationTime !== undefined) {
            JSONWorld._idleDecorationTime = JSONObject.idleDecorationTime;
        }
        if (JSONObject.idleDecorationDurations !== undefined) {
            JSONWorld._idleDecorationDurations = JSONObject.idleDecorationDurations;
        }

        if (JSONObject.activeDecorationCRTime !== undefined) {
            JSONWorld._activeDecorationCRTime = JSONObject.activeDecorationCRTime;
        }
        if (JSONObject.idleDecorationCRTime !== undefined) {
            JSONWorld._idleDecorationCRTime = JSONObject.idleDecorationCRTime;
        }
        if (JSONObject.idleDecorationCRDurations !== undefined) {
            JSONWorld._idleDecorationCRDurations = JSONObject.idleDecorationCRDurations;
        }

        if (JSONObject.activeDecorationCRVisioTime !== undefined) {
            JSONWorld._activeDecorationCRVisioTime = JSONObject.activeDecorationCRVisioTime;
        }
        if (JSONObject.idleDecorationCRVisioTime !== undefined) {
            JSONWorld._idleDecorationCRVisioTime = JSONObject.idleDecorationCRVisioTime;
        }
        if (JSONObject.idleDecorationCRVisioDurations !== undefined) {
            JSONWorld._idleDecorationCRVisioDurations = JSONObject.idleDecorationCRVisioDurations;
        }

        if (JSONObject.logActiveTime !== undefined) {
            JSONWorld._logActiveTime = JSONObject.logActiveTime;
        }
        if (JSONObject.logIdleTime !== undefined) {
            JSONWorld._logIdleTime = JSONObject.logIdleTime;
        }

        if (JSONObject.nbAMRequests !== undefined) {
            JSONWorld._nbAMRequests = JSONObject.nbAMRequests;
        }
        if (JSONObject.totalTimeAMRequests !== undefined) {
            JSONWorld._totalTimeAMRequests = JSONObject.totalTimeAMRequests;
        }
        if (JSONObject.maxTimeAMRequests !== undefined) {
            JSONWorld._maxTimeAMRequests = JSONObject.maxTimeAMRequests;
        }
        if (JSONObject.minTimeAMRequests !== undefined) {
            JSONWorld._minTimeAMRequests = JSONObject.minTimeAMRequests;
        }
        if (JSONObject.nbStaticHullRequests !== undefined) {
            JSONWorld._nbStaticHullRequests = JSONObject.nbStaticHullRequests;
        }
        if (JSONObject.totalTimeStaticHullRequests !== undefined) {
            JSONWorld._totalTimeStaticHullRequests = JSONObject.totalTimeStaticHullRequests;
        }
        if (JSONObject.nbDynamicHullRequests !== undefined) {
            JSONWorld._nbDynamicHullRequests = JSONObject.nbDynamicHullRequests;
        }
        if (JSONObject.totalTimeDynamicHullRequests !== undefined) {
            JSONWorld._totalTimeDynamicHullRequests = JSONObject.totalTimeDynamicHullRequests;
        }
        if (JSONObject.nbFloorHullRequests !== undefined) {
            JSONWorld._nbFloorHullRequests = JSONObject.nbFloorHullRequests;
        }
        if (JSONObject.totalTimeFloorHullRequests !== undefined) {
            JSONWorld._totalTimeFloorHullRequests = JSONObject.totalTimeFloorHullRequests;
        }
        return JSONWorld;
    }

    static go2p0to2p1Rec(JSONObject: any) : any {
        for (let i = JSONObject.children.length - 1; i >= 0; i--) {
            let child = JSONObject.children[i];
            if (child.entityType === SceneConstants.EntityType.Wall && child.slope === true) {
                let newComponents = [];
                for (let j = 0; j < child.components.length; ++j) {
                    let component = child.components[j];
                    newComponents.push(component);
                    if (component.componentType === ComponentConstants.ComponentType.Coating && component.hangType === Coating.HangType.wallDirect) {
                        if (child.slopeLength1 > 0) {
                            let clone = JSON.parse(JSON.stringify(component));
                            clone.hangType = Coating.HangType.slopeDirect;
                            newComponents.push(clone);
                        }
                        if (child.slopeLength2 > 0) {
                            let clone = JSON.parse(JSON.stringify(component));
                            clone.hangType = Coating.HangType.slopeUndirect;
                            newComponents.push(clone);
                        }
                    }
                }
                child.components = newComponents;
            }
        }

        for (let i = 0; i < JSONObject.children.length; i++) {
            VersionHandler.go2p0to2p1Rec(JSONObject.children[i]);
        }

        return JSONObject;
    }
}
