import { SceneConstants } from "../SceneConstants";
import { math } from "../Transform";
import { TemporaryDoubleQuarterStaircase } from "../temporary/TemporaryDoubleQuarterStaircase";
import { Staircase } from "./Staircase";

export class DoubleQuarterStaircase extends Staircase {

    private _stepWidth: number = 800;
    private _hasBearing: boolean = false;
    private _modifiedWidth: number = 2000;

    constructor(id: number) {
        super(id, SceneConstants.StaircaseType.doubleQuarterTurn, 2000, 2000, 2500, null);
        this._shapeType = SceneConstants.ShapeType.quad;
    }

    get stepWidth() : number {
        if (this.temporary === null) {
            return this._stepWidth;
        } else {
            return (this.temporary as TemporaryDoubleQuarterStaircase).stepWidth;
        }
    }

    set stepWidth(value: number) {
        if (this.temporary === null) {
            this._stepWidth = value;
        } else {
            (this.temporary as TemporaryDoubleQuarterStaircase).stepWidth = value;
        }
    }

    get hasBearing() : boolean {
        if (this.temporary === null) {
            return this._hasBearing;
        } else {
            return (this.temporary as TemporaryDoubleQuarterStaircase).hasBearing;
        }
    }

    set hasBearing(value: boolean) {
        if (this.temporary === null) {
            this._hasBearing = value;
        } else {
            (this.temporary as TemporaryDoubleQuarterStaircase).hasBearing = value;
        }
    }

    get modifiedWidth() : number {
        if (this.temporary === null) {
            return this._modifiedWidth;
        } else {
            return (this.temporary as TemporaryDoubleQuarterStaircase).modifiedWidth;
        }
    }

    set modifiedWidth(value: number) {
        if (this.temporary === null) {
            this._modifiedWidth = value;
        } else {
            (this.temporary as TemporaryDoubleQuarterStaircase).modifiedWidth = value;
        }
    }

    saveAndEndTemporary() : void {
        var stepWidth = this.stepWidth;
        var hasBearing = this.hasBearing;
        var modifiedWidth = this.modifiedWidth;

        super.saveAndEndTemporary();

        this.stepWidth = stepWidth;
        this.hasBearing = hasBearing;
        this.modifiedWidth = modifiedWidth;
    }

    get realBoundingBox() : Array<math.vec3> {
        let points = [];
        let point1 = math.vec3.create();
        let point2 = math.vec3.create();
        let point3 = math.vec3.create();
        let point4 = math.vec3.create();
        let point5 = math.vec3.create();
        let point6 = math.vec3.create();
        let point7 = math.vec3.create();
        let point8 = math.vec3.create();

        let partLength = this.stepWidth;

        //        X = translated.X * Math.Cos(angle) - translated.Y * Math.Sin(angle),
        //        Y = translated.X * Math.Sin(angle) + translated.Y * Math.Cos(angle),

        var sin = Math.sin(this.angle);
        var cos = Math.cos(this.angle);
        var w = this.length / 2;
        var h = this.width / 2;

        math.vec3.set(point1, w * cos - -h * sin, w * sin + -h * cos, 0);
        math.vec3.set(point2, w * cos - h * sin, w * sin + h * cos, 0);
        math.vec3.set(point3, (w - partLength) * cos - h * sin, (w - partLength) * sin + h * cos, 0);
        math.vec3.set(point4, (w - partLength) * cos - (-h + partLength) * sin, (w - partLength) * sin + (-h + partLength) * cos, 0);
        math.vec3.set(point5, (-w + partLength) * cos - (-h + partLength) * sin, (-w + partLength) * sin + (-h + partLength) * cos, 0);
        math.vec3.set(point6, (-w + partLength) * cos - h * sin, (-w + partLength) * sin + h * cos, 0);
        math.vec3.set(point7, -w * cos - h * sin, -w * sin + h * cos, 0);
        math.vec3.set(point8, -w * cos - -h * sin, -w * sin + -h * cos, 0);

        points.push(point1);
        points.push(point2);
        points.push(point3);
        points.push(point4);
        points.push(point5);
        points.push(point6);
        points.push(point7);
        points.push(point8);

        for (let i = 0; i < points.length; ++i) {
            points[i][0] += this.position[0];
            points[i][1] += this.position[1];
        }

        return points;
    }

    addToCappedProperty(property: string, value: any) : number {
        let capped = 0;

        if (this[property] + value <= 0) {
            this[property] = 0;
            capped = 1;
        }

        if (property === "stepWidth") {
            if (this[property] + value >= this.width) {
                this[property] = this.width;
                capped = 2;
            }

            // Forbidden to have the stepWidth = to half the stair length. We need to cap it to half stair length - 1
            if (this[property] + value >= this.length / 2) {
                this[property] = this.length / 2 - 1;
                capped = 2;
            }
        }

        if (property === "modifiedWidth") {
            if (this[property] + value < this["stepWidth"]) {
                this[property] = this["stepWidth"];
                capped = 1;
            }
        }

        if (capped === 0) {
            this[property] += value;
        }

        return capped;
    }

    startTemporary() : void {
        this.temporary = new TemporaryDoubleQuarterStaircase(this);
    }
}
