export var HttpVerb = Object.freeze({POST: 'POST', GET: 'GET', PUT: 'PUT', DELETE: 'DELETE'});
import * as StringUtils from '../utils/StringUtils';

export class HttpRequest {

    private _xmlHttpRequest: XMLHttpRequest = new XMLHttpRequest();
    private _baseUrl: string;
    private _resource: string;
    private _verb: string;
    private _data: any = null;

    constructor(baseUrl: string, resource: string, verb: string) {
        this._baseUrl = baseUrl;
        this._resource = resource;
        this._verb = verb;
        this._xmlHttpRequest.open(this._verb, this.baseUrl + '/' + this._resource, true);
    }

    addBasicAuth(login: string, password: string) {
        this._xmlHttpRequest.setRequestHeader('Authorization',
            'Basic ' + StringUtils.Base64.encode(login + ':' + password));
    }

    addToken(token: string) {
        this._xmlHttpRequest.setRequestHeader('Authorization',
            'Bearer ' + token);
    }

    addApiKey(apiKey: string) {
        this._xmlHttpRequest.setRequestHeader('api_key', apiKey);
    }

    addContentType(contentType: string) {
        this._xmlHttpRequest.setRequestHeader('Content-Type', contentType);
    }

    setData(data: any) {
        this._data = data;
    }

    get resource() {
        return this._resource;
    }

    get baseUrl() {
        return this._baseUrl;
    }

    get webRequest() {
        return this._xmlHttpRequest;
    }

    send() {
        if (this._data === null) {
            this._xmlHttpRequest.send();
        } else {
            this._xmlHttpRequest.send(this._data);
        }
    }
}
