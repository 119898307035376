import { SceneConstants } from "../SceneConstants";
import { math } from "../Transform";
import { Segment } from "../../utils/Segment";
import { Transform } from "../Transform";
import { Plane } from "../../math/primitives/plane";
import { SavaneConstants } from "../../utils/SavaneConstants";
import { TemporaryTechnicalElement } from "../temporary/TemporaryTechnicalElement";
import { Coating, TechnicalElementType, WallType } from "../../components/ComponentModule";
import { ComponentConstants } from "../../components/ComponentConstants";
import { SavaneCookie } from "../../utils/SavaneCookie";
import { Entity } from "../Entity";
import { Wall } from "../Wall";
import { SnappableEntity } from "../Interfaces/SnappableEntity";

/**
 * Technical element in the scene
 */
export class TechnicalElement extends Entity implements SnappableEntity {
    protected _objectId: SceneConstants.TechnicalElementType | SceneConstants.StaircaseType;
    private _objectType: string;
    private _subModelId: number;
    private _length: number;
    private _width: number;
    private _height: number;
    protected _shapeType: SceneConstants.ShapeType | SceneConstants.FrameShapes;
    private _temperature: SceneConstants.LightTemperature;
    private _symmetry: boolean;
    private _materialType: number;
    private _lightOn: boolean;
    private _lightOff: boolean;
    private _lightColor: string;
    private _intensity: SceneConstants.SpotLightIntensity;

    accessor stackable: boolean;
    accessor isAnchorActive: boolean;
    accessor anchor: math.vec3;
    accessor minHeight: number;
    accessor localMinHeight: number;

    constructor(id: number, objectId: SceneConstants.TechnicalElementType | SceneConstants.StaircaseType, length: number, width: number, height: number, objectType: string) {
        super();

        this._id = id;
        this._objectId = objectId;
        this._objectType = objectType;
        this._subModelId = 0;
        this._length = length;
        this._width = width;
        this._height = height;
        this._shapeType = SceneConstants.ShapeType.quad;
        this._temperature = SceneConstants.LightTemperature.normal;

        this._symmetry = false;

        // Standard material type of a technical element (0 = neutral)
        this._materialType = 0;

        // Technical element state (for spotlight typically)
        switch (objectId) {
            case SceneConstants.TechnicalElementType.pole:
            case SceneConstants.TechnicalElementType.beam:
                this._materialType = 7;
                break;

            case SceneConstants.TechnicalElementType.spotLight:
                this._lightOn = false;
                this._lightOff = false;
                this._intensity = SceneConstants.SpotLightIntensity.highPower;
                break;

            case SceneConstants.TechnicalElementType.frame:
                this._materialType = 7;
                this._shapeType = SceneConstants.FrameShapes.threeCentralBeams;
                break;
        }
        this.setTechnicalElementType();
    }

    contains(p: math.vec3, precision: number, currentEntity: Entity): Entity {
        throw new Error("Method not implemented.");
    }

    getCoatingQuantity(coating: Coating): number {
        switch (this._objectId) {
            case SceneConstants.TechnicalElementType.pole:
            case SceneConstants.TechnicalElementType.beam:
                return (this.width * this.height * 2 + this.width * this.length * 2 + this.height * this.length * 2) / 2 / (100 * 100 * 100);
                break;
        }

        return 0;
    }

    /**
     * Set the correct AM component type 3D model depending on tech element parameters
     */
    setTechnicalElementType(): void {
        // Get all technical element type entity components
        var componentList = this.getComponents(ComponentConstants.ComponentType.TechnicalElementType) as Array<TechnicalElementType>;

        // If not staircase, then assign a default 3D model for the technical Element
        if (this._objectType !== "stairCase") {
            // Is there any existing JoineryType component ?
            if (componentList.length > 0) {
                // Only poles can change shape during edition
                switch (this.objectId) {
                    case SceneConstants.TechnicalElementType.pole:
                        if (this.shapeType === SceneConstants.ShapeType.quad) {
                            componentList[0].technicalElementTypeId = SavaneCookie.getCookie("Rhinov-Savane-DefaultTechnicalElementModelRectangularPole", SceneConstants.DefaultTechnicalElementType.rectangularPole);
                        } else {
                            componentList[0].technicalElementTypeId = SavaneCookie.getCookie("Rhinov-Savane-DefaultTechnicalElementModelCircularPole", SceneConstants.DefaultTechnicalElementType.circularPole);
                        }
                        break;
                }
            } else {
                switch (this.objectId) {
                    case SceneConstants.TechnicalElementType.pole:
                        if (this.shapeType === SceneConstants.ShapeType.quad) {
                            this.addComponent(new TechnicalElementType(SavaneCookie.getCookie("Rhinov-Savane-DefaultTechnicalElementModelRectangularPole", SceneConstants.DefaultTechnicalElementType.rectangularPole)));
                        } else {
                            this.addComponent(new TechnicalElementType(SavaneCookie.getCookie("Rhinov-Savane-DefaultTechnicalElementModelCircularPole", SceneConstants.DefaultTechnicalElementType.circularPole)));
                        }
                        break;

                    case SceneConstants.TechnicalElementType.stove:
                        this.addComponent(new TechnicalElementType(SavaneCookie.getCookie("Rhinov-Savane-DefaultTechnicalElementModelStove", SceneConstants.DefaultTechnicalElementType.stove)));
                        break;

                    case SceneConstants.TechnicalElementType.fireplace:
                        this.addComponent(new TechnicalElementType(SavaneCookie.getCookie("Rhinov-Savane-DefaultTechnicalElementModelFireplace", SceneConstants.DefaultTechnicalElementType.fireplace)));
                        break;

                    case SceneConstants.TechnicalElementType.radiator:
                        this.addComponent(new TechnicalElementType(SavaneCookie.getCookie("Rhinov-Savane-DefaultTechnicalElementModelRadiator", SceneConstants.DefaultTechnicalElementType.radiator)));
                        break;

                    case SceneConstants.TechnicalElementType.airConditioner:
                        this.addComponent(new TechnicalElementType(SavaneCookie.getCookie("Rhinov-Savane-DefaultTechnicalElementModelAirConditioner", SceneConstants.DefaultTechnicalElementType.airConditioner)));
                        break;

                    case SceneConstants.TechnicalElementType.serviceShaft:
                        if (this.shapeType === SceneConstants.ShapeType.quad) {
                            this.addComponent(new TechnicalElementType(SavaneCookie.getCookie("Rhinov-Savane-DefaultTechnicalElementModelRectangularServiceShaft", SceneConstants.DefaultTechnicalElementType.rectangularServiceShaft)));
                        } else {
                            this.addComponent(new TechnicalElementType(SavaneCookie.getCookie("Rhinov-Savane-DefaultTechnicalElementModelCircularServiceShaft", SceneConstants.DefaultTechnicalElementType.circularServiceShaft)));
                        }
                        break;

                    case SceneConstants.TechnicalElementType.boiler:
                        this.addComponent(new TechnicalElementType(SavaneCookie.getCookie("Rhinov-Savane-DefaultTechnicalElementModelBoiler", SceneConstants.DefaultTechnicalElementType.boiler)));
                        break;

                    case SceneConstants.TechnicalElementType.waterSource:
                        this.addComponent(new TechnicalElementType(SavaneCookie.getCookie("Rhinov-Savane-DefaultTechnicalElementModelWaterSource", SceneConstants.DefaultTechnicalElementType.waterSource)));
                        break;

                    case SceneConstants.TechnicalElementType.ceilingBox:
                        this.addComponent(new TechnicalElementType(SavaneCookie.getCookie("Rhinov-Savane-DefaultTechnicalElementModelCeilingBox", SceneConstants.DefaultTechnicalElementType.ceilingBox)));
                        break;

                    case SceneConstants.TechnicalElementType.socketTV:
                        this.addComponent(new TechnicalElementType(SavaneCookie.getCookie("Rhinov-Savane-DefaultTechnicalElementModelSocketTV", SceneConstants.DefaultTechnicalElementType.socketTV)));
                        break;

                    case SceneConstants.TechnicalElementType.switchBoard:
                        this.addComponent(new TechnicalElementType(SavaneCookie.getCookie("Rhinov-Savane-DefaultTechnicalElementModelSwitchBoard", SceneConstants.DefaultTechnicalElementType.switchBoard)));
                        break;
                    case SceneConstants.TechnicalElementType.socketSixteen:
                        this.addComponent(new TechnicalElementType(SavaneCookie.getCookie("Rhinov-Savane-DefaultTechnicalElementModelSocketSixteen", SceneConstants.DefaultTechnicalElementType.socketSixteen)));
                        break;

                    case SceneConstants.TechnicalElementType.socketThirtyTwo:
                        this.addComponent(new TechnicalElementType(SavaneCookie.getCookie("Rhinov-Savane-DefaultTechnicalElementModelSocketThirtyTwo", SceneConstants.DefaultTechnicalElementType.socketThirtyTwo)));
                        break;

                    case SceneConstants.TechnicalElementType.wardRobe:
                        this.addComponent(new TechnicalElementType(SavaneCookie.getCookie("Rhinov-Savane-DefaultTechnicalElementModelWardRobe", SceneConstants.DefaultTechnicalElementType.wardRobe)));
                        break;

                    case SceneConstants.TechnicalElementType.spotLight:
                        this.addComponent(new TechnicalElementType(SavaneCookie.getCookie("Rhinov-Savane-DefaultTechnicalElementModelSpotLight", SceneConstants.DefaultTechnicalElementType.spotLight)));
                        break;

                    case SceneConstants.TechnicalElementType.rosette:
                        this.addComponent(new TechnicalElementType(SavaneCookie.getCookie("Rhinov-Savane-DefaultTechnicalElementModelRosette", SceneConstants.DefaultTechnicalElementType.rosette)));
                        break;

                    case SceneConstants.TechnicalElementType.beam:
                        this.addComponent(new TechnicalElementType(SavaneCookie.getCookie("Rhinov-Savane-DefaultTechnicalElementModelBeam", SceneConstants.DefaultTechnicalElementType.beam)));
                        break;

                    case SceneConstants.TechnicalElementType.guardrail:
                        this.addComponent(new WallType(SavaneCookie.getCookie("Rhinov-Savane-DefaultTechnicalElementModelGuardRail", SceneConstants.DefaultTechnicalElementType.guardrail)));
                        break;

                    case SceneConstants.TechnicalElementType.wallDecoration:
                        this.addComponent(new TechnicalElementType(SavaneCookie.getCookie("Rhinov-Savane-DefaultTechnicalElementModelWallDecoration", SceneConstants.DefaultTechnicalElementType.wallDecoration)));
                        break;
                }
            }
        } else {
            // Staircase, assign a 3D element for the ramp
            // Is there any existing JoineryType component ?
            if (componentList.length > 0) {
                if (this._objectId !== SceneConstants.StaircaseType.circular) {
                    componentList[0].technicalElementTypeId = "5e691023db010c019c9f6392";
                } else {
                    componentList[0].technicalElementTypeId = "5c5aa4bbab09a316a51cefb5";
                }
            } else {
                if (this._objectId !== SceneConstants.StaircaseType.circular) {
                    this.addComponent(new TechnicalElementType("5e691023db010c019c9f6392"));
                } else {
                    this.addComponent(new TechnicalElementType("5c5aa4bbab09a316a51cefb5"));
                }
            }
        }
    }

    getTechnicalElementType(): string {
        var componentList = this.getComponents(ComponentConstants.ComponentType.TechnicalElementType) as Array<TechnicalElementType>;

        if (componentList.length > 0) {
            return componentList[0].technicalElementTypeId;
        } else {
            return null;
        }
    }

    get lightOn(): boolean {
        if (this.temporary === null) {
            return this._lightOn;
        } else {
            return (this.temporary as TemporaryTechnicalElement).lightOn;
        }
    }

    set lightOn(value: boolean) {
        if (this.temporary === null) {
            this._lightOn = value;
        } else {
            (this.temporary as TemporaryTechnicalElement).lightOn = value;
        }
    }

    get lightOff() : boolean {
        if (this.temporary === null) {
            return this._lightOff;
        } else {
            return (this.temporary as TemporaryTechnicalElement).lightOff;
        }
    }

    set lightOff(value: boolean) {
        if (this.temporary === null) {
            this._lightOff = value;
        } else {
            (this.temporary as TemporaryTechnicalElement).lightOff = value;
        }
    }

    get temperature() : SceneConstants.LightTemperature {
        if (this.temporary === null) {
            return this._temperature;
        } else {
            return (this.temporary as TemporaryTechnicalElement).temperature;
        }
    }

    set temperature(value: SceneConstants.LightTemperature) {
        if (this.temporary === null) {
            this._temperature = value;
        } else {
            (this.temporary as TemporaryTechnicalElement).temperature = value;
        }
    }

    get symmetry(): boolean {
        if (this.temporary === null) {
            return this._symmetry;
        } else {
            return (this.temporary as TemporaryTechnicalElement).symmetry;
        }
    }

    set symmetry(value: boolean) {
        if (this.temporary === null) {
            this._symmetry = value;
        } else {
            (this.temporary as TemporaryTechnicalElement).symmetry = value;
        }
    }

    get lightColor() : string {
        if (this.temporary === null) {
            return this._lightColor;
        } else {
            return (this.temporary as TemporaryTechnicalElement).lightColor;
        }
    }

    set lightColor(value: string) {
        if (this.temporary === null) {
            this._lightColor = value;
        } else {
            (this.temporary as TemporaryTechnicalElement).lightColor = value;
        }
    }

    // Setters and getters
    get subModelId(): number {
        return this._subModelId;
    }

    set subModelId(value: number) {
        this._subModelId = value;
    }

    /**
     * Getter for the Entity type
     */
    get entityType() : SceneConstants.EntityType {
        return SceneConstants.EntityType.TechnicalElement;
    }

    /**
     * Getter for the object type
     */
    get objectType() : string {
        return this._objectType;
    }

    /**
     * Getter for the object id
     */
    get objectId(): SceneConstants.TechnicalElementType | SceneConstants.StaircaseType {
        return this._objectId;
    }

    set shapeType(sType: SceneConstants.ShapeType | SceneConstants.FrameShapes) {
        if (this.temporary !== null) {
            (this.temporary as TemporaryTechnicalElement).shapeType = sType;
            this.temporary.recomputeValidity = true;
        } else {
            this._shapeType = sType;
        }

        if (this.objectId === SceneConstants.TechnicalElementType.pole || this.objectId === SceneConstants.TechnicalElementType.serviceShaft) {
            this.setTechnicalElementType();
        }
    }

    get shapeType() : SceneConstants.ShapeType | SceneConstants.FrameShapes {
        if (this.temporary !== null) {
            return (this.temporary as TemporaryTechnicalElement).shapeType;
        } else {
            return this._shapeType;
        }
    }

    /**
     * Getter for the object width
     *
     */
    get width() : number {
        if (this._width === undefined) {
            return 0;
        }

        if (this.temporary !== null) {
            return (this.temporary as TemporaryTechnicalElement).width;
        } else {
            return this._width;
        }
    }

    set width(value: number) {
        if (this.temporary !== null) {
            (this.temporary as TemporaryTechnicalElement).width = value;
            this.temporary.recomputeValidity = true;
        } else {
            this._width = value;
        }
    }

    set height(value: number) {
        if (this.temporary !== null) {
            if (this.entityType === SceneConstants.EntityType.TechnicalElement) {
                var delta = value - (this.temporary as TemporaryTechnicalElement).height;
                (this.temporary as TemporaryTechnicalElement).height = value;
                var position = this.transform.localPosition;
                position[2] += delta / 2;
                this.transform.localPosition = position;
                this.temporary.recomputeValidity = true;
            } else {
                (this.temporary as TemporaryTechnicalElement).height = value;
                this.temporary.recomputeValidity = true;
            }
        } else {
            this._height = value;
        }
    }

    set length(value: number) {
        if (this.temporary !== null) {
            (this.temporary as TemporaryTechnicalElement).length = value;
            this.temporary.recomputeValidity = true;
        } else {
            this._length = value;
        }
    }

    /**
     * Getter for the object height
     *
     */
    get height() : number {
        if (this._height === undefined) {
            return 0;
        }

        if (this.temporary !== null) {
            return (this.temporary as TemporaryTechnicalElement).height;
        } else {
            return this._height;
        }
    }

    /**
     * Getter for the height of the joinery
     */
    get materialType() : number {
        if (this.temporary === null) {
            return this._materialType;
        } else {
            return (this.temporary as TemporaryTechnicalElement).materialType;
        }
    }

    /**
     * Setter for the height of the joinery
     *
     * @param {*} newHeight
     */
    set materialType(newType: number) {
        if (this.temporary === null) {
            this._materialType = newType;
        } else {
            (this.temporary as TemporaryTechnicalElement).materialType = newType;
        }
    }

    /**
     * Getter for the intensity of the technical element
     */
    get intensity() : SceneConstants.SpotLightIntensity {
        if (this.temporary === null) {
            return this._intensity;
        } else {
            return (this.temporary as TemporaryTechnicalElement).intensity;
        }
    }

    /**
     * Setter for the intensity of the technical element
     *
     * @param {*} newIntensity
     */
    set intensity(newIntensity: SceneConstants.SpotLightIntensity) {
        if (this.temporary === null) {
            this._intensity = newIntensity;
        } else {
            (this.temporary as TemporaryTechnicalElement).intensity = newIntensity;
        }
    }

    get transform(): Transform {
        if (this.temporary === null) {
            return this._transform;
        } else {
            return (this.temporary as TemporaryTechnicalElement).transform;
        }
    }

    /**
     * Getter for the object length
     *
     */
    get length(): number {
        if (this._length === undefined) {
            return 0;
        }
        if (this.temporary !== null) {
            return (this.temporary as TemporaryTechnicalElement).length;
        } else {
            return this._length;
        }
    }

    /**
     * Getter for the object angle
     *
     */
    get angle() : number {
        return this.transform.globalZRotation;
    }

    /**
     * Setter for absolute position of ArrangementObject
     *
     * @param {*} newPosition
     */
    set position(newPosition: math.vec3) {
        if (this.temporary === null) {
            this.transform.globalPosition = newPosition;
        } else {
            (this.temporary as TemporaryTechnicalElement).transform.globalPosition = math.vec3.clone(newPosition);
        }
    }

    get position() : math.vec3 {
        if (this.temporary === null) {
            return this.transform.globalPosition;
        } else {
            return (this.temporary as TemporaryTechnicalElement).transform.globalPosition;
        }
    }

    set floorHeight(value: number) {
        if (this.temporary === null) {
            var newPos = math.vec3.create();
            math.vec3.set(newPos, this.transform.localPosition[0], this.transform.localPosition[1], value + this.height / 2);
            this.transform.localPosition = newPos;
        } else {
            let newPos = math.vec3.create();
            math.vec3.set(newPos, (this.temporary as TemporaryTechnicalElement).transform.localPosition[0], (this.temporary as TemporaryTechnicalElement).transform.localPosition[1], value + this.height / 2);
            (this.temporary as TemporaryTechnicalElement).transform.localPosition = newPos;
        }
    }

    get floorHeight() : number {
        if (this.temporary === null) {
            return this.transform.localPosition[2] - this.height / 2;
        } else {
            return (this.temporary as TemporaryTechnicalElement).transform.localPosition[2] - this.height / 2;
        }
    }

    /**
     * Set rotation on a top down view (plan2d)
     *
     * @param {*} newAngle
     */
    setRotationZ(newAngle: number) {
        this.transform.globalZRotation = newAngle;

        if (this.temporary !== null) {
            this.temporary.recomputeValidity = true;
        }
    }

    setRotationX(newAngle: number) {
        this.transform.globalXRotation = newAngle;
        if (this.temporary !== null) {
            this.temporary.recomputeValidity = true;
        }
    }

    /**
     * Set rotation on a top down view (plan2d)
     *
     * @param {*} newAngle
     */
    set angle(newAngle: number) {
        this.setRotationZ(newAngle);
    }

    set inclinaison(newAngle: number) {
        this.transform.localXRotation = (newAngle * Math.PI) / 180;
        if (this.temporary !== null) {
            this.temporary.recomputeValidity = true;
        }
    }

    get inclinaison() : number {
        return (this.transform.localXRotation * 180) / Math.PI;
    }

    get rawBoundingBox() : Array<math.vec3> {
        let points = [];

        let point1 = math.vec3.create();
        let point2 = math.vec3.create();
        let point3 = math.vec3.create();
        let point4 = math.vec3.create();
        let point5 = math.vec3.create();
        let point6 = math.vec3.create();
        let point7 = math.vec3.create();
        let point8 = math.vec3.create();

        math.vec3.set(point1, this.length / 2, -this.width / 2, -this.height / 2);
        math.vec3.set(point2, this.length / 2, this.width / 2, -this.height / 2);
        math.vec3.set(point3, -this.length / 2, this.width / 2, -this.height / 2);
        math.vec3.set(point4, -this.length / 2, -this.width / 2, -this.height / 2);

        points.push(point1);
        points.push(point2);
        points.push(point3);
        points.push(point4);

        math.vec3.set(point5, point1[0], point1[1], point1[2] + this.height);
        math.vec3.set(point6, point2[0], point2[1], point2[2] + this.height);
        math.vec3.set(point7, point3[0], point3[1], point3[2] + this.height);
        math.vec3.set(point8, point4[0], point4[1], point4[2] + this.height);

        points.push(point5);
        points.push(point6);
        points.push(point7);
        points.push(point8);

        return points;
    }

    get realHeight() : number {
        var localBBox = this.rawBoundingBox;
        var min = Number.POSITIVE_INFINITY;
        var max = Number.NEGATIVE_INFINITY;

        var transform = new Transform(null);
        transform.clone(this.transform);

        let scale = math.vec3.create();
        math.vec3.set(scale, 1, 1, 1);
        transform.localScale = scale;

        for (var i = 0; i < localBBox.length; ++i) {
            var p = math.vec3.create();
            math.vec3.transformMat4(p, localBBox[i], transform.localMatrix);
            min = Math.min(min, p[2]);
            max = Math.max(max, p[2]);
        }

        return max - min;
    }

    get realWidth() : number {
        var axes = [math.vec3.fromValues(-this.length / 2, -this.width / 2, -this.height / 2), math.vec3.fromValues(this.length / 2, this.width / 2, this.height / 2)];
        var rotatedAxes = [];
        var O = math.vec3.fromValues(0, 0, 0);
        var N = math.vec3.fromValues(1, 0, 0);

        var transform = new Transform(null);
        transform.clone(this.transform);
        transform.localScale = math.vec3.fromValues(1, 1, 1);
        transform.localPosition = math.vec3.fromValues(0, 0, 0);

        var rotation = transform.globalMatrix;
        var angles = Transform.extractEulerAngles(rotation);

        math.vec3.rotateZ(N, N, O, angles[2]);
        var plane = new Plane(O, N);

        for (var i = 0; i < axes.length; ++i) {
            var p = math.vec3.create();
            math.vec3.transformMat4(p, axes[i], rotation);
            p[2] = 0;
            rotatedAxes.push(p);
        }

        return math.vec3.distance(plane.Project(rotatedAxes[0], true), plane.Project(rotatedAxes[1], true));
    }

    get realLength() : number {
        var axes = [math.vec3.fromValues(-this.length / 2, -this.width / 2, -this.height / 2), math.vec3.fromValues(this.length / 2, this.width / 2, this.height / 2)];
        var rotatedAxes = [];
        var O = math.vec3.fromValues(0, 0, 0);
        var N = math.vec3.fromValues(0, 1, 0);

        var transform = new Transform(null);
        transform.clone(this.transform);
        transform.localScale = math.vec3.fromValues(1, 1, 1);
        transform.localPosition = math.vec3.fromValues(0, 0, 0);

        var rotation = transform.globalMatrix;
        var angles = Transform.extractEulerAngles(rotation);

        math.vec3.rotateZ(N, N, O, angles[2]);
        var plane = new Plane(O, N);

        for (var i = 0; i < axes.length; ++i) {
            var p = math.vec3.create();
            math.vec3.transformMat4(p, axes[i], rotation);
            p[2] = 0;
            rotatedAxes.push(p);
        }

        return math.vec3.distance(plane.Project(rotatedAxes[0], true), plane.Project(rotatedAxes[1], true));
    }

    // Called when a property is changed to verify max and min boundaries or to perform computations before final assignment
    addToCappedProperty(property: string, value: any) : number {
        let capped = 0; // 0 = no cap, 1 = minus cap, 2 max cap

        if (this[property] === undefined) {
            return -1;
        }
        this[property] += value;

        if (property === "height") {
            if (this[property] > SceneConstants.TechElementMaxHeight[this.objectId]) {
                this[property] = SceneConstants.TechElementMaxHeight[this.objectId];
                capped = 2;
            }
            if (this[property] < SceneConstants.TechElementMinHeight[this.objectId]) {
                this[property] = SceneConstants.TechElementMinHeight[this.objectId];
                capped = 1;
            }
        }
        if (property === "length") {
            if (this[property] > SceneConstants.TechElementMaxLength[this.objectId]) {
                this[property] = SceneConstants.TechElementMaxLength[this.objectId];
                capped = 2;
            }
            if (this[property] < SceneConstants.TechElementMinLength[this.objectId]) {
                this[property] = SceneConstants.TechElementMinLength[this.objectId];
                capped = 1;
            }
        }
        if (property === "width") {
            if (this[property] > SceneConstants.TechElementMaxWidth[this.objectId]) {
                this[property] = SceneConstants.TechElementMaxWidth[this.objectId];
                capped = 2;
            }
            if (this[property] < SceneConstants.TechElementMinWidth[this.objectId]) {
                this[property] = SceneConstants.TechElementMinWidth[this.objectId];
                capped = 1;
            }
        }
        return capped;
    }

    /**
     * Returns the array of 4 point deliminting the technicalElement (2d)
     */
    get boundingBox() : Array<math.vec3> {
        let points = [];
        let point1 = math.vec3.create();
        let point2 = math.vec3.create();
        let point3 = math.vec3.create();
        let point4 = math.vec3.create();

        math.vec3.set(point1, this.position[0] + (Math.cos(this.angle) * this.realLength) / 2, this.position[1] + (Math.sin(this.angle) * this.realLength) / 2, 0);

        math.vec3.set(point2, point1[0] - (Math.cos(Math.PI / 2 - this.angle) * this.realWidth) / 2, point1[1] + (Math.sin(Math.PI / 2 - this.angle) * this.realWidth) / 2, 0);

        math.vec3.set(point1, point1[0] + (Math.cos(Math.PI / 2 - this.angle) * this.realWidth) / 2, point1[1] - (Math.sin(Math.PI / 2 - this.angle) * this.realWidth) / 2, 0);

        math.vec3.set(point3, this.position[0] - (Math.cos(this.angle) * this.realLength) / 2, this.position[1] - (Math.sin(this.angle) * this.realLength) / 2, 0);

        math.vec3.set(point4, point3[0] + (Math.cos(Math.PI / 2 - this.angle) * this.realWidth) / 2, point3[1] - (Math.sin(Math.PI / 2 - this.angle) * this.realWidth) / 2, 0);

        math.vec3.set(point3, point3[0] - (Math.cos(Math.PI / 2 - this.angle) * this.realWidth) / 2, point3[1] + (Math.sin(Math.PI / 2 - this.angle) * this.realWidth) / 2, 0);

        points.push(point1);
        points.push(point2);
        points.push(point3);
        points.push(point4);

        return points;
    }

    /**
     * Test whether the wall cross the object
     *
     * @param {*} wall
     */
    crossWall(wall: Wall) : boolean {
        var box = this.boundingBox;

        for (let i = 0; i < box.length; i++) {
            if (Segment.areSegmentCrossing(wall.segment, new Segment(box[i], box[(i + 1) % box.length]), SavaneConstants.PositionTolerance)) {
                return true;
            }
        }

        return false;
    }

    /**
     * Create the temporaryArrangementObject that will be used during edition
     */
    startTemporary() : void {
        this.temporary = new TemporaryTechnicalElement(this);
    }

    /**
     * delete the temporaryArrangementObject
     */
    endTemporary() : void {
        this.temporary = null;
    }

    /**
     * save the temporary data in the arrangementObject and delete the temporaryArrangementObject
     */
    saveAndEndTemporary() : void {
        var matrix = math.mat4.clone(this.transform.localMatrix);
        var wTemp = this.width;
        var hTemp = this.height;
        var lTemp = this.length;
        var materialTypeTemp = this.materialType;
        var shapeTemp = this.shapeType;

        var lightOnTemp;
        var lightOffTemp;
        var temperatureTemp;
        var lightColorTemp;
        var intensityTemp;
        var symmetryTemp;

        if (this.lightOn !== undefined) {
            lightOnTemp = this.lightOn;
        }
        if (this.lightOff !== undefined) {
            lightOffTemp = this.lightOff;
        }
        if (this.temperature !== undefined) {
            temperatureTemp = this.temperature;
        }
        if (this.lightColor !== undefined) {
            lightColorTemp = this.lightColor;
        }
        if (this.intensity !== undefined) {
            intensityTemp = this.intensity;
        }
        
        if (this.symmetry !== undefined) {
            symmetryTemp = this.symmetry;
        }

        this.temporary = null;

        math.mat4.copy(this.transform.localMatrix, matrix);
        this.height = hTemp;
        this.length = lTemp;
        this.width = wTemp;
        this.materialType = materialTypeTemp;
        this.shapeType = shapeTemp;

        if (lightOnTemp !== undefined) {
            this.lightOn = lightOnTemp;
        }
        if (lightOffTemp !== undefined) {
            this.lightOff = lightOffTemp;
        }
        if (temperatureTemp !== undefined) {
            this.temperature = temperatureTemp;
        }
        if (lightColorTemp !== undefined) {
            this.lightColor = lightColorTemp;
        }
        if (intensityTemp !== undefined) {
            this.intensity = intensityTemp;
        }
        if (symmetryTemp !== undefined) {
            this.symmetry = symmetryTemp;
        }
    }
}
