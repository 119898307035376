import {ComponentConstants} from '../SavaneJS';
import {Component} from './Component';

export class RendererTopView extends Component {
    private _urlTopView: string;
    private _urlSecondary: string;

    constructor(urlTopView: string, urlSecondary?: string) {
        super();
        this._urlTopView = urlTopView;
        this._urlSecondary = urlSecondary;
    }

    set urlTopview(urlTopView: string) {
        this._urlTopView = urlTopView;
    }

    get urlTopview(): string {
        return this._urlTopView;
    }

    set urlSecondary(urlSecondary: string) {
        this._urlSecondary = urlSecondary;
    }

    get urlSecondary(): string {
        return this._urlSecondary;
    }

    get componentType(): ComponentConstants.ComponentType {
        return ComponentConstants.ComponentType.RendererTopView;
    }

    clone(): RendererTopView {
        return new RendererTopView(this._urlTopView, this._urlSecondary);
    }
}
