import { SavaneCookie } from '../../utils/SavaneCookie';
import { TemporaryJoinery } from './../temporary/TemporaryJoinery';
import { JoineryType, ComponentConstants, SceneConstants, math } from '../../SavaneJS';
import { Joinery } from './Joinery';

/**
 * Joinery  of type : canopy
 */
export class Canopy extends Joinery {
    // Default windows/casement or a canopy is 3
    private _nbCasement: number = 3;
    // Default canopy isn't opened
    private _isOpened: boolean = false;

    constructor(joineryType: SceneConstants.JoineryType, position: math.vec3) {
        super(joineryType, position);
        
        //wallInstallType = 0 = center
        this._wallInstallType = SceneConstants.WallInstallType.install_center;

        this.addComponent(new JoineryType(SavaneCookie.getCookie("Rhinov-Savane-DefaultJoineryModelCanopy", SceneConstants.DefaultJoineryType.canopy), undefined, undefined));
    }

    // Getters and setters for isOpened
    get isOpened(): boolean {
        if (this.temporary === null) {
            return this._isOpened;
        }
        else {
            return (this.temporary as TemporaryJoinery).isOpened;
        }
    }

    set isOpened(o: boolean) {
        if (this.temporary === null) {
            this._isOpened = o;
        }
        else {
            (this.temporary as TemporaryJoinery).isOpened = o;
        }
    }

    // Getters and setters for number of doors/casements
    get nbCasement(): number {
        if (this.temporary === null) {
            return this._nbCasement;
        }
        else {
            return (this.temporary as TemporaryJoinery).nbCasement;
        }
    }

    set nbCasement(c: number) {
        if (this.temporary === null) {
            this._nbCasement = c;
        }
        else {
            (this.temporary as TemporaryJoinery).nbCasement = c;
        }
    }

    computeLapeyreCanopyCasement() {
        this.nbCasement = Math.trunc(this.length / 400);
        if (this.length % 400 !== 0) {
            this.nbCasement++;
        }

        // 8 Casements are forbidden for lapeyre canopy
        if (this.nbCasement === 8) {
            this.nbCasement = 9;
        }
    }

    // Called when a property is changed to verify max and min boundaries or to perform computations before final assignment
    addToCappedProperty(property: string, value: any) : number {
        // -1 = unknwon property, 0 = no cap, 1 = minus cap, 2 max cap will be returned at the end
        let capped = 0;

        let component = this.getComponent(ComponentConstants.ComponentType.JoineryType);

        if (component && (component as JoineryType).joineryTypeId === "5fad1c4a8f1b8b7ad9c2ce98") {
            if (property === "height") {
                /*if (this[property] + value <= SceneConstants.MinimalLapeyreCanopyHeight) {
                    this[property] = SceneConstants.MinimalLapeyreCanopyHeight;
                    capped = 1;
                }

                if (this[property] + value >= SceneConstants.MaxLapeyreCanopyHeight) {
                    this[property] = SceneConstants.MaxLapeyreCanopyHeight;
                    capped = 2;
                }*/
                if (this[property] + value <= SceneConstants.MinimalJoineryHeight) {
                    this[property] = SceneConstants.MinimalJoineryHeight;
                    capped = 1;
                }

                if (this[property] + value + this.floorHeight >= this.wall.height) {
                    let tmpValue = this.wall.height - (this[property] + value + this.floorHeight);

                    if (this.floorHeight + tmpValue < 0) {
                        this.floorHeight = 0;
                        this[property] = this.wall.height;
                        capped = 2;
                    }
                    else {
                        this.floorHeight += tmpValue;
                    }
                }

                if (capped === 0) {
                    this[property] += value;
                }

                return (capped);
            }

            if (property === "length") {
                if (this[property] + value <= SceneConstants.MinimalLapeyreCanopyLength) {
                    this[property] = SceneConstants.MinimalLapeyreCanopyLength;
                    capped = 1;
                }

                if (this[property] + value >= SceneConstants.MaxLapeyreCanopyLength) {
                    this[property] = SceneConstants.MaxLapeyreCanopyLength;
                    capped = 2;
                }

                if (capped === 0) {
                    this[property] += value;
                }

                this.computeLapeyreCanopyCasement();

                return (capped);
            }

            if (property === "nbCasement") {
                capped = 3;

                return (capped);
            }

            return super.addToCappedProperty(property, value);
        }

        if (property === "nbCasement") {
            if (this[property] + value <= 1) {
                this[property] = 1;
                capped = 1;
            }

            if (capped === 0) {
                this[property] += value;
            }
        }
        else {
            return super.addToCappedProperty(property, value);
        }
        return capped;
    }
}
