import { Command, CommandEnum } from './CommandModule';
import { Events } from '../events';
import { eventsManager } from '../managers/EventsManager';
import { Entity, Floor, math } from '../SavaneJS';

export class RotateFloorCommand extends Command
{
    // Rotation agle
    private _angle: number;
    // Floor to apply to
    private _floor: Floor;

    constructor(angle: number, floor: Floor) {
        super();
        this._angle = angle;
        this._floor = floor;
    }

    // Command name returned from a global enum
    name(): string {
        return CommandEnum.RotateFloorCommand;
    }

    // Datas that allows the command to be executed
    execDatas(): any {
        return {
            angle: this._angle,
            floor: this._floor
        };
    }

    // Undo the current command
    undo() {
        // Rotate inverse angle of the angle used for the execute method
        this.applyRotation(-this._angle * Math.PI / 180);

        super.undo();

        eventsManager.instance.dispatch(Events.PROJECT_RELOAD);
    }

    // Execute current command (redo), call the applyRotation method with the angle initialised at start
    execute() {
        this.applyRotation(this._angle * Math.PI / 180);

        super.execute();

        eventsManager.instance.dispatch(Events.PROJECT_RELOAD);
    }

    // Method that applies a rotation to the entire floor
    applyRotation(angle: number) {
        // Loop variable
        let i, j;
        // Floor bounding box to recenter all entities
        let floorBBox = this._floor.boundingBox;
        // Center of the bounding box
        let center = math.vec3.create();

        // Retrieve center of bounding box
        math.vec3.set(center, floorBBox[0][0] + (floorBBox[3][0] - floorBBox[0][0]) * 0.5,
            floorBBox[0][1] + (floorBBox[3][1] - floorBBox[0][1]) * 0.5,
            floorBBox[0][2] + (floorBBox[3][2] - floorBBox[0][2]) * 0.5);

        // Rotate walls
        for (i = 0; i < this._floor.walls.length; i++) {
            // Current wall to rotate
            let wall = this._floor.walls[i];
            // Begin and end coordinates
            let begin = wall.begin;
            let end = wall.end;
            // Find dist from center to begin
            let distanceBegin = math.vec3.create();

            math.vec3.set(distanceBegin, begin[0] - center[0], begin[1] - center[1], begin[2] - center[2]);
            // Find dist from center to end
            let distanceEnd = math.vec3.create();
            math.vec3.set(distanceEnd, end[0] - center[0], end[1] - center[1], end[2] - center[2]);

            // Rotate points
            let tmp1 = distanceBegin[0] * Math.cos(angle) + distanceBegin[1] * Math.sin(angle);
            let tmp2 = distanceBegin[1] * Math.cos(angle) - distanceBegin[0] * Math.sin(angle);
            distanceBegin[0] = tmp1;
            distanceBegin[1] = tmp2

            tmp1 = distanceEnd[0] * Math.cos(angle) + distanceEnd[1] * Math.sin(angle);
            tmp2 = distanceEnd[1] * Math.cos(angle) - distanceEnd[0] * Math.sin(angle);
            distanceEnd[0] = tmp1;
            distanceEnd[1] = tmp2;

            // Translate point to final position thanks to center
            let newBegin = math.vec3.create();
            math.vec3.add(newBegin, center, distanceBegin);
            let newEnd = math.vec3.create();
            math.vec3.add(newEnd, center, distanceEnd);

            // Set new value for point of wall
            wall.begin = newBegin;
            wall.end = newEnd;

            // Redraw Node
            eventsManager.instance.dispatch(Events.REDRAW_GRAPHICAL_ENTITY,
                {
                    entity: wall
                });

            // Redraw joineries
            for (j = 0; j < wall.joineries.length; j++) {
                // Redraw Node
                eventsManager.instance.dispatch(Events.REDRAW_GRAPHICAL_ENTITY,
                    {
                        entity: wall.joineries[j]
                    });
            }
        }

        // Redraw rooms
        for (i = 0; i < this._floor.rooms.length; i++) {
            eventsManager.instance.dispatch(Events.REDRAW_GRAPHICAL_ROOM,
                {
                    entity: this._floor.rooms[i]
                });
        }

        // Rotate tech elements
        for (i = 0; i < this._floor.technicalElementsWithStaircases.length; i++) {
            this.rotateEntity(this._floor.technicalElementsWithStaircases[i], center, angle);
        }
        // Rotate arrangementObjects
        for (i = 0; i < this._floor.arrangementObjects.length; i++) {
            if (!this._floor.arrangementObjects[i].parent.isArrangementObjectEntity() && !this._floor.arrangementObjects[i].parent.isArrangementGroupEntity() && !this._floor.arrangementObjects[i].parent.isArrangementZoneEntity()) {
                this.rotateEntity(this._floor.arrangementObjects[i], center, angle);
            }
        }
        // Rotate arrangementGroups
        for (i = 0; i < this._floor.arrangementGroups.length; i++) {
            if (!this._floor.arrangementGroups[i].parent.isArrangementObjectEntity() && !this._floor.arrangementGroups[i].parent.isArrangementGroupEntity() && !this._floor.arrangementGroups[i].parent.isArrangementZoneEntity()) {
                this.rotateEntity(this._floor.arrangementGroups[i], center, angle);
            }
        }
        // Rotate renderCameras
        for (i = 0; i < this._floor.renderCameras.length; i++) {
            this.rotateEntity(this._floor.renderCameras[i], center, angle);
        }
        // Rotate geometryPrimitives
        for (i = 0; i < this._floor.geometryPrimitives.length; i++) {
            this.rotateEntity(this._floor.geometryPrimitives[i], center, angle);
        }
    }

    // Perform the rotation of an entity thanks to the floor center
    rotateEntity(entity: Entity, center: math.vec3, angle: number) {
        // Rotate position from center
        let positionEntity = entity.position;
        // Find dist from center to begin
        let distance = math.vec3.create();
        math.vec3.set(distance, positionEntity[0] - center[0], positionEntity[1] - center[1], positionEntity[2] - center[2]);

        // Rotate position
        let tmp1 = distance[0] * Math.cos(angle) + distance[1] * Math.sin(angle);
        let tmp2 = distance[1] * Math.cos(angle) - distance[0] * Math.sin(angle);
        distance[0] = tmp1;
        distance[1] = tmp2;

        // Apply dist to create new begin and end
        let newPosition = math.vec3.create();
        math.vec3.add(newPosition, center, distance);
        // Set new value for entity position
        entity.position = newPosition;

        // Adjust entity angle correctly
        entity.angle -= angle;

        // Redraw Node
        eventsManager.instance.dispatch(Events.REDRAW_GRAPHICAL_ENTITY,
            {
                entity: entity
            });
    }
};
