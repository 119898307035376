import { Command, CommandEnum } from './CommandModule';
import { Events } from '../events';
import { eventsManager } from '../managers/EventsManager';
import { Coating, ComponentConstants, Entity } from '../SavaneJS';

export class ChangeCoatingCommand extends Command
{
    // Store edited entity from which the coating is edited
    private _entity: Entity;
    // Save coating to attach to the entity
    private _coatingResource: any;
    // New and previous coating components set to null first
    private _newCoatingComponent: Coating = null;
    private _previousCoatingComponents: Array<Coating> = [];
    // Component locked ?
    private _componentLocked: boolean = false;
    
    constructor(entity: Entity, coatingResource: any, hangType: Coating.HangType, usemtlName: string) {
        super();
        this._entity = entity;
        this._coatingResource = coatingResource;

        // If this. coating is null this means the entity is clearing its coating
        if (this._coatingResource !== null) {
            // Create new coating to apply

            // Get config from old configs or new real colors
            let configs = this._coatingResource.configs;
            if (this._coatingResource.colors !== undefined) {
                configs = [this._coatingResource.colors];
            }

            this._newCoatingComponent = new Coating(this._coatingResource._id,
                                                    this._coatingResource.manufacturer,
                                                    this._coatingResource.retailer,
                                                    hangType,
                                                    configs,
                                                    this._coatingResource.randomization,
                                                    this._coatingResource.isHpFloorGenerator ? this._coatingResource.hpFloorGeneratorSettings : undefined,
                                                    undefined);

            if (usemtlName) {
                this._newCoatingComponent.usemtlName = usemtlName;
            }
        }

        // Look for previous coating with same hang type
        let currentCoatings = this._entity.getComponents(ComponentConstants.ComponentType.Coating);
        // Parse all coatings and try to find the same hanging type
        for (let i = 0; i < currentCoatings.length; i++) {
            // Found, then save it
            if ((currentCoatings[i] as Coating).hangType === hangType) {
                if (hangType === Coating.HangType.usemtl) {
                    if (this._newCoatingComponent.usemtlName === (currentCoatings[i] as Coating).usemtlName) {
                        this._previousCoatingComponents.push(currentCoatings[i] as Coating);
                        break;
                    }
                }
                else {
                    if ((currentCoatings[i] as Coating).locked) {
                        this._componentLocked = true;
                    }
                    this._previousCoatingComponents.push(currentCoatings[i] as Coating);
                    break;
                }
            }

            if (((currentCoatings[i] as Coating).hangType === Coating.HangType.usemtl && hangType === Coating.HangType.technicalElement) ||
                ((currentCoatings[i] as Coating).hangType === Coating.HangType.technicalElement && hangType === Coating.HangType.usemtl)) {
                  if ((currentCoatings[i] as Coating).locked) {
                      this._componentLocked = true;
                  }
                  this._previousCoatingComponents.push(currentCoatings[i] as Coating);
            }
        }
    }

    // Command name returned from a global enum
    name(): string {
        return CommandEnum.ChangeCoatingCommand;
    }

    // Datas that allows the command to be executed
    execDatas(): any {
        return {
            entity: this._entity,
            component: this._newCoatingComponent,
        };
    }

    // Do anything specific for redraw for specific nodes
    redrawAndUpdateWebGL() {
        eventsManager.instance.dispatch(Events.REDRAW_GRAPHICAL_ENTITY,
            {
                entity: this._entity
            });

        for (let i = 0 ; i < this._previousCoatingComponents.length ; i++) {
            if (this._newCoatingComponent === null) {
                if (this._previousCoatingComponents[i].floorGeneratorSettings) {
                    eventsManager.instance.dispatch(Events.FLOOR_GENERATOR_CHANGED);
                    break;
                }
            } else {
                if (this._previousCoatingComponents[i].floorGeneratorSettings !== this._newCoatingComponent.floorGeneratorSettings) {
                    eventsManager.instance.dispatch(Events.FLOOR_GENERATOR_CHANGED);
                    break;
                }
            }
        }
        if (this._previousCoatingComponents.length === 0 && this._newCoatingComponent.floorGeneratorSettings) {
            eventsManager.instance.dispatch(Events.FLOOR_GENERATOR_CHANGED);
        }
    }

    // Undo the current command
    undo() {
        if (!this._componentLocked) {
            // Delete the new coating applied at execute
            if (this._newCoatingComponent !== null) {
                this._entity.removeComponent(this._newCoatingComponent);
            }
            // Put back the previous one
            for (let i = 0 ; i < this._previousCoatingComponents.length ; i++) {
                this._entity.addComponent(this._previousCoatingComponents[i]);
            }
            // Execute parent function
            super.undo();
            // Perform specific redraw depending on node type
            this.redrawAndUpdateWebGL();
        }
    }

    // Execute current command (redo)
    execute() {
        if (!this._componentLocked) {
            // Delete current applied coating (we will change it with the new one) if any
            for (let i = 0 ; i < this._previousCoatingComponents.length ; i++) {
                this._entity.removeComponent(this._previousCoatingComponents[i]);
            }
            // Add the new one
            if (this._newCoatingComponent !== null) {
                this._entity.addComponent(this._newCoatingComponent);
            }
            // Execute parent function
            super.execute();
            // Perform specific redraw depending on node type
            this.redrawAndUpdateWebGL();
        }
    }
};
