import { Command, CommandEnum, CreateWallCommand } from './CommandModule';
import { Events } from '../events';
import { eventsManager } from '../managers/EventsManager';
import { Wall } from '../SavaneJS';

export class AddRoomCommand  extends Command
{
    private _roomWallsToAdd: Array<Wall>;
    // This stores all add wall commands created by the execute function
    private _subCommands: Array<Command> = [];
    
    constructor(roomWallsToAdd: Array<Wall>, isB2B: boolean) {
        super();

        // Store new created room
        this._roomWallsToAdd = roomWallsToAdd;

        // Add all wall addition command to execute
        for (let i = 0; i < roomWallsToAdd.length; i++) {
            this._subCommands.push(new CreateWallCommand(roomWallsToAdd[i], [], isB2B));
        }
    }

    // Command name returned from a global enum
    name(): string {
        return CommandEnum.AddRoomCommand;
    }

    // Undo the current command
    undo() {
        // Parse all add wall commands and undo them all
        for (let i = this._subCommands.length - 1; i >= 0; i--) {
            this._subCommands[i].undo();
        }
    }

    // Execute current command (redo)
    execute() {
        // Parse all add wall commands and execute them all
        for (let i = 0; i < this._subCommands.length; i++) {
            this._subCommands[i].execute();
        }

        // Select object at the end
        eventsManager.instance.dispatch(Events.CHANGE_EDITOR_SELECTION,
            {
                selection: null,
                keepSelected: false,
                showTulip: false
            });
    }
}
