import { Command, CommandEnum, RotateFloorCommand } from './CommandModule';
import { Scene } from '../SavaneJS';

export class RotateProjectCommand extends Command
{
    // Command list to execute and undo
    private _subCommands: Array<Command> = [];
    // Rotation angle
    private _angle: number;

    constructor(angle: number, scene: Scene) {
        super();
        // Rotation angle value
        this._angle = angle;
        // Parse all floors
        for (let i = 0; i < scene.floors.length; i++) {
            // Create a RotateFloorCommand for each floor of the array
            this._subCommands.push(new RotateFloorCommand(angle, scene.floors[i]));
        }
    }

    // Command name returned from a global enum
    name(): string {
        return CommandEnum.RotateProjectCommand;
    }

    // Datas that allows the command to be executed
    execDatas(): any {
        return {
            angle: this._angle
        };
    }

    // Undo the current command
    undo() {
        // Call rotation floor undo for each command created by the constructor
        for (let i = 0; i < this._subCommands.length; i++) {
            this._subCommands[i].undo();
        }

        super.undo();
    }

    // Execute current command (redo), call the rotation floor commands created by the constructor
    execute() {
        // Parse all commands
        for (let i = 0; i < this._subCommands.length; i++) {
            this._subCommands[i].execute();
        }

        super.execute();
    }
};
