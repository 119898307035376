import { AMFurnitureFinishesReference } from "../components/AMFurnitureFinishesReference";
import { FurnitureFinishes } from "../components/FurnitureFinishes";
import { ComponentConstants } from "../../components/ComponentConstants"

/**
 * Class which contains importation functions
 */
export class Importer {
    constructor() {}

    /**
     * Function to pass to savane importer to import component of kitchenTool
     *
     * @param JSONComponent
     */
    static importComponent(JSONComponent, entity) {
        switch (JSONComponent.componentType) {
            case ComponentConstants.ComponentType.AMFurnitureFinishesReference:
                return new AMFurnitureFinishesReference(JSONComponent.plinthData, JSONComponent.endPanelData, JSONComponent.fillerData);
            case ComponentConstants.ComponentType.FurnitureFinishes:
                var furnitureFinishes = new FurnitureFinishes();

                furnitureFinishes.plinths.front = JSONComponent.plinths.front > 0 ? entity.getChild(JSONComponent.plinths.front) : null;
                furnitureFinishes.plinths.back = JSONComponent.plinths.back > 0 ? entity.getChild(JSONComponent.plinths.back) : null;
                furnitureFinishes.plinths.left = JSONComponent.plinths.left > 0 ? entity.getChild(JSONComponent.plinths.left) : null;
                furnitureFinishes.plinths.right = JSONComponent.plinths.right > 0 ? entity.getChild(JSONComponent.plinths.right) : null;

                furnitureFinishes.endPanels.front = JSONComponent.endPanels.front > 0 ? entity.getChild(JSONComponent.endPanels.front) : null;
                furnitureFinishes.endPanels.back = JSONComponent.endPanels.back > 0 ? entity.getChild(JSONComponent.endPanels.back) : null;
                furnitureFinishes.endPanels.left = JSONComponent.endPanels.left > 0 ? entity.getChild(JSONComponent.endPanels.left) : null;
                furnitureFinishes.endPanels.right = JSONComponent.endPanels.right > 0 ? entity.getChild(JSONComponent.endPanels.right) : null;

                furnitureFinishes.fillers.front = JSONComponent.fillers.front > 0 ? entity.getChild(JSONComponent.fillers.front) : null;
                furnitureFinishes.fillers.back = JSONComponent.fillers.back > 0 ? entity.getChild(JSONComponent.fillers.back) : null;
                furnitureFinishes.fillers.left = JSONComponent.fillers.left > 0 ? entity.getChild(JSONComponent.fillers.left) : null;
                furnitureFinishes.fillers.right = JSONComponent.fillers.right > 0 ? entity.getChild(JSONComponent.fillers.right) : null;

                furnitureFinishes.fillers.front_wall = JSONComponent.fillers.front_wall;
                furnitureFinishes.fillers.back_wall = JSONComponent.fillers.back_wall;
                furnitureFinishes.fillers.left_wall = JSONComponent.fillers.left_wall;
                furnitureFinishes.fillers.right_wall = JSONComponent.fillers.right_wall;
                return furnitureFinishes;
            default:
                return null;
        }
    }
}
