import { Command, CommandEnum, EditRenderCameraCommand } from './CommandModule';
import { RenderCamera } from '../SavaneJS';

export class EditRenderCamerasCommand  extends Command
{
    // Command list to execute and undo
    private _subCommands: Array<Command> = [];

    constructor(editedCameras: Array<RenderCamera>, preventReparenting: boolean) {
        super();
        // Parse all entity array passed to the constructor
        for (let i = 0; i < editedCameras.length; i++) {
            // And create a camera edit command for each of them
            this._subCommands.push(new EditRenderCameraCommand(editedCameras[i], preventReparenting));
        }
    }

    // Command name returned from a global enum
    name(): string {
        return CommandEnum.EditRenderCamerasCommand;
    }

    needsRefreshHull(): boolean {
        for (let i = 0; i < this._subCommands.length; i++) {
            if (this._subCommands[i].needsRefreshHull()) {
                return (true);
            }
        }

        return (false);
    }

    // Undo the current command
    undo() {
        // Parse all commands created by the constructor and undo them all
        for (let i = 0; i < this._subCommands.length; i++) {
            this._subCommands[i].undo();
        }

        // Execute parent function
        super.undo();
    }

    // Execute current command (redo)
    execute() {
        // Parse all commands created by the constructor and execute them all
        for (let i = 0; i < this._subCommands.length; i++) {
            this._subCommands[i].execute();
        }

        //Execute parent function
        super.execute();
    }
};
