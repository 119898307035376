import { Sun, math } from '../../SavaneJS';
import { TemporaryEntity } from './TemporaryEntity';

/**
 * TemporarySun is used for sun entity edition
 */
export class TemporarySun extends TemporaryEntity {
    public altitude: number;
    public weather: number;
    public southDirection: math.vec2;
    public exterior: string;
    public additionalExterior: string;
    public increaseDecorsSize: boolean;
    public decorsRotation: number;
    public shadowBlurRadius: number;
    public temperature: number;
    public skyIntensity: number;

    constructor(sun: Sun) {
        super(sun);

        // Save sun parameters
        this.altitude        = sun.altitude;
        this.weather         = sun.weather;
        this.southDirection  = math.vec2.create();
        math.vec2.set(this.southDirection, sun.southDirection[0], sun.southDirection[1]);
        this.exterior        = sun.exterior;
        this.additionalExterior = sun.additionalExterior;
        this.increaseDecorsSize = sun.increaseDecorsSize;
        this.decorsRotation  = sun.decorsRotation;
        this.shadowBlurRadius = sun.shadowBlurRadius;
        this.temperature = sun.temperature;
        this.skyIntensity = sun.skyIntensity;
    }
}
