import { math } from "../scene/Transform";
import { SavaneMath } from "./SavaneMath";
import { SavaneConstants } from "./SavaneConstants";

/**
 * Segment store a segment (two vec3)
 *
 * @constructor
 */
export class Segment {
    public begin: math.vec3;
    public end: math.vec3;

    constructor(begin: math.vec3, end: math.vec3) {
        this.begin = math.vec3.clone(begin);
        this.end = math.vec3.clone(end);
    }

    get direction() : math.vec3 {
        let dir = math.vec3.create();
        dir[0] = this.end[0] - this.begin[0];
        dir[1] = this.end[1] - this.begin[1];
        dir[2] = this.end[2] - this.begin[2];
        var length = Math.sqrt(dir[0] * dir[0] + dir[1] * dir[1] + dir[2] * dir[2]);
        var res = math.vec3.create();
        res[0] = dir[0] / length;
        res[1] = dir[1] / length;
        res[2] = dir[2] / length;
        return res;
    }

    /**
     * test wether the point is begin
     * @param point
     * @param precision
     * @returns {boolean}
     */
    isBegin(point: math.vec3, precision: number): boolean {
        return math.vec3.squaredDistance(this.begin, point) < precision * precision;
    }

    /**
     * test wether the point is end
     * @param point
     * @param precision
     * @returns {boolean}
     */
    isEnd(point: math.vec3, precision: number) : boolean {
        return math.vec3.squaredDistance(this.end, point) < precision * precision;
    }

    get length() : number {
        var distX = this.end[0] - this.begin[0];
        var distY = this.end[1] - this.begin[1];
        var distZ = this.end[2] - this.begin[2];

        return Math.sqrt(distX * distX + distY * distY + distZ * distZ);
    }

    /**
     * return the orthogonal project on the segment of a point
     * @param position
     */
    orthogonalProjection(position: math.vec3) : math.vec3 {
        var C = math.vec3.create();
        var D = math.vec3.create();
        math.vec3.subtract(C, position, this.begin);
        math.vec3.subtract(D, this.end, this.begin);
        if (math.vec3.length(D) === 0) {
            return null;
        }
        math.vec3.normalize(D, D);
        var a1 = math.vec3.dot(C, D);
        return math.vec3.fromValues(this.begin[0] + a1 * D[0], this.begin[1] + a1 * D[1], this.begin[2] + a1 * D[2]);
    }

    /**
     * test if the point given in parameter is on the segment
     *
     * @param {vec3} aPoint
     * @param {Number} precision
     */
    isPointOnSegment(aPoint: math.vec3, precision: number) : boolean {
        let squaredLength = (this.length + precision) * (this.length + precision);
        if (math.vec3.squaredDistance(this.begin, aPoint) > squaredLength || math.vec3.squaredDistance(this.end, aPoint) > squaredLength) {
            return false;
        }
        let points = [];
        let direction = this.direction;
        // OLD CODE ETT
        let point1 = [this.begin[0] - direction[0] * precision - direction[1] * precision, this.begin[1] - direction[1] * precision + direction[0] * precision, 0];
        let point2 = [this.begin[0] - direction[0] * precision + direction[1] * precision, this.begin[1] - direction[1] * precision - direction[0] * precision, 0];
        let point3 = [this.end[0] + direction[0] * precision + direction[1] * precision, this.end[1] + direction[1] * precision - direction[0] * precision, 0];
        let point4 = [this.end[0] + direction[0] * precision - direction[1] * precision, this.end[1] + direction[1] * precision + direction[0] * precision, 0];

        points.push(point1);
        points.push(point2);
        points.push(point3);
        points.push(point4);

        return SavaneMath.isInPoly(aPoint, points);
    }

    /**
     * test if segments are crossing each others
     *
     * @param {Segment} segment1
     * @param {Segment} segment2
     * @param precision
     */
    static areSegmentCrossing(segment1: Segment, segment2: Segment, precision: number) : boolean {
        let slope = 0;
        let origin = 0;
        let verticalX = 0;
        let isVertical = false;
        if (Math.abs(segment1.begin[0] - segment1.end[0]) > SavaneConstants.PositionTolerance) {
            slope = (segment1.begin[1] - segment1.end[1]) / (segment1.begin[0] - segment1.end[0]);
            origin = segment1.begin[1] - segment1.begin[0] * slope;
        } else {
            verticalX = segment1.begin[0];
            isVertical = true;
        }
        let minX = Math.min(segment1.end[0], segment1.begin[0]);
        let maxX = Math.max(segment1.end[0], segment1.begin[0]);
        let minY = Math.min(segment1.end[1], segment1.begin[1]);
        let maxY = Math.max(segment1.end[1], segment1.begin[1]);

        let slopeI = 0;
        let originI = 0;
        let verticalXi = 0;
        let isVerticalI = false;

        if (Math.abs(segment2.begin[0] - segment2.end[0]) > SavaneConstants.PositionTolerance) {
            slopeI = (segment2.end[1] - segment2.begin[1]) / (segment2.end[0] - segment2.begin[0]);
            originI = segment2.begin[1] - segment2.begin[0] * slopeI;
        } else {
            verticalXi = segment2.begin[0];
            isVerticalI = true;
        }
        let minXi = Math.min(segment2.end[0], segment2.begin[0]);
        let maxXi = Math.max(segment2.end[0], segment2.begin[0]);
        let minYi = Math.min(segment2.end[1], segment2.begin[1]);
        let maxYi = Math.max(segment2.end[1], segment2.begin[1]);

        //compute the equation system
        if (isVerticalI && isVertical) {
            if (Math.abs(verticalX - verticalXi) < precision && maxYi + precision > minY && minYi < maxY + precision) {
                return true;
            }
        } else if (!isVertical && isVerticalI) {
            let intersectY = slope * verticalXi + origin;
            let intersectX = verticalXi;

            if (intersectX < maxX + precision && intersectX + precision > minX && ((slopeI < SavaneConstants.Tolerance && Math.abs(maxYi - intersectY) < SavaneConstants.Tolerance) || intersectY < maxYi + precision) && ((slopeI < SavaneConstants.Tolerance && Math.abs(intersectY - minYi) < SavaneConstants.Tolerance) || intersectY + precision > minYi)) {
                return true;
            }
        } else if (isVertical) {
            let intersectY1 = slopeI * verticalX + originI;
            let intersectX1 = verticalX;

            if (intersectX1 < maxXi + precision && intersectX1 + precision > minXi && ((slope < SavaneConstants.Tolerance && Math.abs(maxY - intersectY1) < SavaneConstants.Tolerance) || intersectY1 < maxY + precision) && ((slope < SavaneConstants.Tolerance && Math.abs(minY - intersectY1) < SavaneConstants.Tolerance) || intersectY1 + precision > minY)) {
                return true;
            }
        } else {
            if (Math.abs(slopeI - slope) < SavaneConstants.Tolerance) {
                //Parrell
                if (maxXi + precision > minX && minXi < maxX + precision && Math.abs(origin - originI) < precision) {
                    return true;
                }
            } else {
                let intersectX11 = (originI - origin) / (slope - slopeI);

                //test y too because of rounding when an segment is almost vertical
                let intersectY11 = origin + intersectX11 * slope;

                if (intersectX11 < maxXi + precision && intersectX11 + precision > minXi && intersectX11 < maxX + precision && intersectX11 + precision > minX && ((slopeI < SavaneConstants.Tolerance && Math.abs(maxYi - intersectY11) < SavaneConstants.Tolerance) || intersectY11 < maxYi + precision) && ((slopeI < SavaneConstants.Tolerance && Math.abs(intersectY11 - minYi) < SavaneConstants.Tolerance) || intersectY11 + precision > minYi) && ((slope < SavaneConstants.Tolerance && Math.abs(maxY - intersectY11) < SavaneConstants.Tolerance) || intersectY11 < maxY + precision) && ((slope < SavaneConstants.Tolerance && Math.abs(minY - intersectY11) < SavaneConstants.Tolerance) || intersectY11 + precision > minY)) {
                    return true;
                }
            }
        }
        return false;
    }

    /**
     * test if segments are colinear
     *
     * @param {Segment} segment1
     * @param {Segment} segment2
     * @param {Number} colinearPrecision
     */
    static areColinear(segment1: Segment, segment2: Segment, colinearPrecision = SavaneConstants.Tolerance) : boolean {
        let crossVector = math.vec3.create();
        math.vec3.cross(crossVector, segment1.direction, segment2.direction);
        return math.vec3.length(crossVector) < colinearPrecision;
    }

    /**
     * test if segments are orthogonal
     *
     * @param {Segment} segment1
     * @param {Segment} segment2
     */
    static areOrthogonal(segment1: Segment, segment2: Segment, orthogonalPrecision = SavaneConstants.Tolerance) : boolean {
        return Math.abs(math.vec3.dot(segment1.direction, segment2.direction)) < orthogonalPrecision;
    }
}
