import {ComponentConstants} from '../SavaneJS';
import {Component} from './Component';

export class TechnicalElementType extends Component {
    protected _technicalElementTypeId: string;

    constructor(amid: string) {
        super();
        this._technicalElementTypeId = amid;
        this._isUnique = true;
    }

    clone(): TechnicalElementType {
        return new TechnicalElementType(this._technicalElementTypeId);
    }

    get componentType(): ComponentConstants.ComponentType {
        return ComponentConstants.ComponentType.TechnicalElementType;
    }

    get technicalElementTypeId(): string {
        return this._technicalElementTypeId;
    }

    set technicalElementTypeId(amid: string) {
        this._technicalElementTypeId = amid;
    }
}
