import { Command, CommandEnum } from './CommandModule';
import { Events } from '../events';
import { eventsManager } from '../managers/EventsManager';
import { Component, Coating, ComponentConstants, Entity } from '../SavaneJS';

export class DeleteComponentCommand  extends Command
{
    // Deleted component
    private _deletedComponent: Component;
    // Entity the component os deleted from
    private _deletedComponentEntity: Entity;
    // Do we have to update planWebGL
    private _updateWebGL: boolean;

    constructor(deletedComponent: Component, updateWebGL: boolean) {
        super();
        // Store the component to be removed
        this._deletedComponent = deletedComponent;
        // Store the entity from which the component will be removed
        this._deletedComponentEntity = deletedComponent.entity;
        // Keep update webGL parameters
        this._updateWebGL = updateWebGL;
    }

    // Command name returned from a global enum
    name(): string{
        return CommandEnum.DeleteComponentCommand;
    }

    // Datas that allows the command to be executed
    execDatas(): any {
        return {
            component: this._deletedComponent,
            entity: this._deletedComponentEntity
        };
    }

    // Undo the current command
    undo() {
        // Put back the removed component
        this._deletedComponentEntity.addComponent(this._deletedComponent);

        switch (this._deletedComponent.componentType) {
            case ComponentConstants.ComponentType.CoatingArea:
            case ComponentConstants.ComponentType.CutArea:
                eventsManager.instance.dispatch(Events.REDRAW_FRONTVIEW_AREA,
                    {
                        component: this._deletedComponent,
                    });
                break;

            case ComponentConstants.ComponentType.TemplateImage:
                eventsManager.instance.dispatch(Events.CREATE_USER_MAP,
                    {
                        component: this._deletedComponent,
                    });
                break;

            case ComponentConstants.ComponentType.Coating:
            case ComponentConstants.ComponentType.FloorCoatingArea:
                if ((this._deletedComponent as Coating).floorGeneratorSettings) {
                    eventsManager.instance.dispatch(Events.FLOOR_GENERATOR_CHANGED);
                }
                break;

            default:
        }

        // Perform specific redraw depending on node type
        eventsManager.instance.dispatch(Events.REDRAW_GRAPHICAL_ENTITY,
            {
                entity: this._deletedComponentEntity
            });

        // Inform a component has been added
        eventsManager.instance.dispatch(Events.COMPONENT_ADDED, this._deletedComponent);

        // Update webgl only if needed
        if (this._updateWebGL) {
            // Execute parent function
            super.undo();
        }
    }

    // Execute current command (redo)
    execute() {
        // Remove the component from the entity
        this._deletedComponentEntity.removeComponent(this._deletedComponent);

        switch (this._deletedComponent.componentType) {
            case ComponentConstants.ComponentType.CoatingArea:
            case ComponentConstants.ComponentType.CutArea:
                eventsManager.instance.dispatch(Events.DELETE_FRONTVIEW_AREA,
                    {
                        component: this._deletedComponent,
                    });
                break;

            case ComponentConstants.ComponentType.TemplateImage:
                eventsManager.instance.dispatch(Events.DELETE_USER_MAP);
                break;

            case ComponentConstants.ComponentType.Coating:
            case ComponentConstants.ComponentType.FloorCoatingArea:
                if ((this._deletedComponent as Coating).floorGeneratorSettings) {
                    eventsManager.instance.dispatch(Events.FLOOR_GENERATOR_CHANGED);
                }
                break;

            default:
        }

        // Perform specific redraw depending on node type
        eventsManager.instance.dispatch(Events.REDRAW_GRAPHICAL_ENTITY,
            {
                entity: this._deletedComponentEntity
            });

        // Inform a component has been removed
        eventsManager.instance.dispatch(Events.COMPONENT_REMOVED, {
            component: this._deletedComponent,
            from: this._deletedComponentEntity
        });

        // Update webgl only if needed
        if (this._updateWebGL) {
            // Execute parent function
          super.execute();
        }
    }
};
