
import { TemporaryGeometryPrimitive } from '../temporary/TemporaryGeometryPrimitive';
import { SceneConstants, math, Transform, Wall } from '../../SavaneJS';
import { Entity } from '../Entity';
import { SnappableEntity } from '../Interfaces/SnappableEntity';
/**
 * GeometryPrimitive is an Entity representing basic shapes like boxes and cylinder
 *
 * @constructor
 */
export class GeometryPrimitive extends Entity implements SnappableEntity {

    private _height: number;
    protected _hideInAxo: boolean = false;
    private _cutByJoineries: boolean = false;
    public primitiveType: SceneConstants.GeometryPrimitiveType;

    accessor boundingBox: Array<math.vec3>;
    accessor length: number;
    accessor width: number;
    accessor stackable: boolean;
    accessor isAnchorActive: boolean;
    accessor anchor: math.vec3;
    accessor minHeight: number;
    accessor localMinHeight: number;
    
    constructor(primitiveType: SceneConstants.GeometryPrimitiveType, h: number) {
        super();
        // Save primitive type
        this.primitiveType = primitiveType;
        // Set all default parameters
        this._height = h;
    }

    crossWall(wall: Wall): boolean {
        throw new Error('Method not implemented.');
    }
    contains(p: math.vec3, precision: number, currentEntity: Entity): Entity {
        throw new Error('Method not implemented.');
    }

    /**
     * Getter for the Entity type
     */
    get entityType() : SceneConstants.EntityType {
        return SceneConstants.EntityType.GeometryPrimitive;
    }

    /**
     * Getter for the center of the joinery
     */
    get position() : math.vec3 {
        if (this.temporary === null) {
            return this.transform.globalPosition;
        }
        else {
            return (this.temporary as TemporaryGeometryPrimitive).transform.globalPosition;
        }
    }

    // Get transformation matrix
    get transform() : Transform {
        if (this.temporary === null) {
            return this._transform;
        }
        else {
            return (this.temporary as TemporaryGeometryPrimitive).transform;
        }
    }

    /**
     * Setter for the center of the joinery
     *
     * @param {*} position
     */
    set position(p: math.vec3) {
        if (this.temporary === null) {
            this.transform.globalPosition = p;
        }
        else {
            (this.temporary as TemporaryGeometryPrimitive).transform.globalPosition = p;
            this.temporary.recomputeValidity = true;
        }
    }

    /**
     * Setter for absolute position of ArrangementObject
     *
     * @param {*} newPosition
     */
    set localPosition(p: math.vec3) {
        this.transform.localPosition = p;
    }

    get localPosition() : math.vec3 {
        return this.transform.localPosition;
    }

    /**
     * Getter for the object angle
     *
     */
    get angle(): number {
        return this.transform.globalZRotation;
    }

    /**
     * Set rotation on a top down view (plan2d)
     *
     * @param {*} newAngle
     */
    setRotationZ(a: number) {
        this.transform.globalZRotation = a;

        if (this.temporary !== null) {
            this.temporary.recomputeValidity = true;
        }
    }

    /**
     * set for the arrangementGroup angle
     *
     */
    set angle(a: number) {
        this.setRotationZ(a);
    }

    /**
     * Getter for the height of the joinery
     */
    get height() : number {
        if (this.temporary === null) {
            return this._height;
        }
        else {
            return (this.temporary as TemporaryGeometryPrimitive).height;
        }
    }

    /**
     * Setter for the height of the joinery
     *
     * @param {*} newHeight
     */
    set height(h: number) {
        if (this.temporary === null) {
            this._height = h;
        }
        else {
            let delta = h - (this.temporary as TemporaryGeometryPrimitive).height;
            (this.temporary as TemporaryGeometryPrimitive).height = h;
            let position = this.transform.localPosition;
            position[2] += delta / 2;
            this.transform.localPosition = position;
        }
    }

    get hideInAxo() : boolean {
        if (this.temporary === null) {
            return this._hideInAxo;
        }
        else {
            return (this.temporary as TemporaryGeometryPrimitive).hideInAxo;
        }
    }

    set hideInAxo(hia: boolean) {
        if (this.temporary === null) {
            this._hideInAxo = hia;
        }
        else {
            (this.temporary as TemporaryGeometryPrimitive).hideInAxo = hia;
        }
    }

    get cutByJoineries() : boolean {
        if (this.temporary === null) {
            return this._cutByJoineries;
        }
        else {
            return (this.temporary as TemporaryGeometryPrimitive).cutByJoineries;
        }
    }

    set cutByJoineries(cbj: boolean) {
        if (this.temporary === null) {
            this._cutByJoineries = cbj;
        }
        else {
            (this.temporary as TemporaryGeometryPrimitive).cutByJoineries = cbj;
        }
    }

    /**
     * Getter for the floorHeight of the geometry primitive
     */
    get floorHeight() : number {
        if (this.temporary === null) {
            return this.transform.localPosition[2] - this.height / 2;
        }
        else {
            return (this.temporary as TemporaryGeometryPrimitive).transform.localPosition[2] - this.height / 2;
        }
    }

    /**
     * Setter for the floorHeight of the geometry primitive
     */
    set floorHeight(fh: number) {
        if (this.temporary === null) {
            let newPos = math.vec3.create();
            math.vec3.set(newPos, this.transform.localPosition[0], this.transform.localPosition[1], fh + this.height / 2);
            this.transform.localPosition = newPos;
        }
        else {
            let newPos = math.vec3.create();
            math.vec3.set(newPos, (this.temporary as TemporaryGeometryPrimitive).transform.localPosition[0], (this.temporary as TemporaryGeometryPrimitive).transform.localPosition[1], fh + this.height / 2);
            (this.temporary as TemporaryGeometryPrimitive).transform.localPosition = newPos;
        }
    }

    // Called when a property is changed to verify max and min boundaries or to perform computations before final assignment
    addToCappedProperty(property: string, value: any) : number {
        // -1 = unknwon property, 0 = no cap, 1 = minus cap, 2 max cap wil be returned at the end
        let capped = 0;

        if ((property === "height") || (property === "width") || (property === "length") || (property === "diameter")) {
            if (this[property] + value <= 10) {
                this[property] = 10;
                capped = 1;
            }
        }

        if (property === "floorHeight") {
            if (this[property] + value <= 0) {
                this[property] = 0;
                capped = 1;
            }
        }

        if (capped === 0) {
            this[property] += value;
        }

        return capped;
    }

    /**
     * Create the TemporaryGeometryPrimitive that will be used during edition
     */
    startTemporary() {
        this.temporary = new TemporaryGeometryPrimitive(this);
    }

    /**
     * delete the TemporaryGeometryPrimitive
     */
    endTemporary() {
        this.temporary = null;
    }

    /**
     * save the temporary data in the joinery and delete the temporaryJoinery
     */
    saveAndEndTemporary() {
        throw new TypeError('Not implemented on ' + this.entityType + ' ' + this.primitiveType + ' - Abstract class');
    }
}
