import { Line } from "../math/primitives/line";
import { TemporaryWall } from "./temporary/TemporaryWall";
import { Coating, WallType, ComponentConstants, Room, Joinery, SceneConstants, SavaneConstants, wallManager, Functionality, math, Transform, SavaneMath, Segment } from "../SavaneJS";
import { Entity } from "./Entity";

/**
 * Wall is an Entity representing a wall.
 *
 * @constructor
 */
export class Wall extends Entity {

    private _thickness: number = SceneConstants.DefaultWallThickness;
    private _height: number = SceneConstants.DefaultWallHeight;
    private _wallDirection: math.vec3 = math.vec3.create();
    private _end: math.vec3 = math.vec3.create();
    private _length: number = 0;
    private _joineries: Array<Joinery> = new Array<Joinery>();
    private _lengthModifier: Wall.PossibleLengthModifier = Wall.PossibleLengthModifier.MIDDLE;
    private _shiftDirection: number = 0;
    private _slope: boolean = false;
    private _slopeHeight: number = 1500;
    private _slopeLength1: number = 0;
    private _slopeLength2: number = 0;
    private _forceNoPlinth: boolean = false;
    private _forceNoCornice: boolean = false;
    public isRoomedWall: boolean = false;

    public rooms: Array<Room> = new Array<Room>();
    public shiftOffset: number = 0; 

    constructor() {
        super();
    }

    get entityType() : SceneConstants.EntityType {
        return SceneConstants.EntityType.Wall;
    }

    // Returns the hang type to apply to a coating the wall so that the coating faces the room passed as parameter
    getHangTypeForRoom(room: Room): Coating.HangType {
        // Get wall segment
        let segment = this.segment;
        // Compute middle of wall segment
        let middle = math.vec3.create();

        middle[0] = segment.begin[0] + (segment.end[0] - segment.begin[0]) / 2;
        middle[1] = segment.begin[1] + (segment.end[1] - segment.begin[1]) / 2;
        middle[2] = segment.begin[2] + (segment.end[2] - segment.begin[2]) / 2;

        // Move way from the wall 90° orthogonally
        let orthogonalPoint = math.vec3.create();
        orthogonalPoint[0] = middle[0] + this.wallDirection[1] * (this.thickness / 2);
        orthogonalPoint[1] = middle[1] - this.wallDirection[0] * (this.thickness / 2);
        orthogonalPoint[2] = middle[2];

        // Check if the orthogonal point is in the room or not
        if (!room || room.isInRoom(orthogonalPoint)) {
            return Coating.HangType.wallDirect;
        } else {
            return Coating.HangType.wallUndirect;
        }
    }

    /**
     * Getter for the shiftdirection
     *
     */
    get shiftDirection(): number {
        if (this.temporary === null) {
            return this._shiftDirection;
        } else {
            return (this.temporary as TemporaryWall).shiftDirection;
        }
    }

    set shiftDirection(sd: number) {
        if (this.temporary === null) {
            this._shiftDirection = sd;
        } else {
            (this.temporary as TemporaryWall).shiftDirection = sd;
        }
    }

    /**
     * Getters and setters for forceNoPlinth
     *
     */
    get forceNoPlinth(): boolean {
        if (this.temporary === null) {
            return this._forceNoPlinth;
        } else {
            return (this.temporary as TemporaryWall).forceNoPlinth;
        }
    }

    set forceNoPlinth(p: boolean) {
        if (this.temporary === null) {
            this._forceNoPlinth = p;
        } else {
           (this.temporary as TemporaryWall).forceNoPlinth = p;
        }
    }

    /**
     * Getters and setters for forceNoCornice
     *
     */
    get forceNoCornice(): boolean {
        if (this.temporary === null) {
            return this._forceNoCornice;
        } else {
            return (this.temporary as TemporaryWall).forceNoCornice;
        }
    }

    set forceNoCornice(c: boolean) {
        if (this.temporary === null) {
            this._forceNoCornice = c;
        } else {
            (this.temporary as TemporaryWall).forceNoCornice = c;
        }
    }

    /**
     * Getters and setters for slopes
     *
     */
    get slope(): boolean {
        if (this.temporary === null) {
            return this._slope;
        } else {
            return (this.temporary as TemporaryWall).slope;
        }
    }

    set slope(s: boolean) {
        if (this.temporary === null) {
            this._slope = s;
        } else {
            (this.temporary as TemporaryWall).slope = s;
        }
    }

    get slopeHeight(): number {
        if (this.temporary === null) {
            return this._slopeHeight;
        } else {
            return (this.temporary as TemporaryWall).slopeHeight;
        }
    }

    set slopeHeight(sh: number) {
        if (this.temporary === null) {
            this._slopeHeight = sh;
        } else {
            (this.temporary as TemporaryWall).slopeHeight = sh;
        }
    }

    get slopeLength1(): number {
        if (this.temporary === null) {
            return this._slopeLength1;
        } else {
            return (this.temporary as TemporaryWall).slopeLength1;
        }
    }

    set slopeLength1(sl: number) {
        if (this.temporary === null) {
            this._slopeLength1 = sl;
        } else {
            (this.temporary as TemporaryWall).slopeLength1 = sl;
        }
    }

    get slopeLength2(): number {
        if (this.temporary === null) {
            return this._slopeLength2;
        } else {
            return (this.temporary as TemporaryWall).slopeLength2;
        }
    }

    set slopeLength2(sl: number) {
        if (this.temporary === null) {
            this._slopeLength2 = sl;
        } else {
            (this.temporary as TemporaryWall).slopeLength2 = sl;
        }
    }

    get maxHeight(): number {
        return this.height;
    }

    getCoatingQuantity(coating): number {
        if (this.rooms.length < 2) {
            let room = this.rooms[0];
            let functionalityComponent = room.getComponent(ComponentConstants.ComponentType.Functionality);

            if (functionalityComponent !== null) {
                if ((functionalityComponent as Functionality).functionalityId === ComponentConstants.Functionalities.TechnicalRoom) {
                    return 0;
                }
            }

            let hangType = coating.hangType;
            if (hangType === Coating.HangType.slopeDirect || hangType === Coating.HangType.plinthDirect || hangType === Coating.HangType.corniceDirect) {
                hangType = Coating.HangType.wallDirect;
            }
            if (hangType === Coating.HangType.slopeUndirect || hangType === Coating.HangType.plinthUndirect || hangType === Coating.HangType.corniceUndirect) {
                hangType = Coating.HangType.wallUndirect;
            }

            if (this.getHangTypeForRoom(room) !== hangType) {
                return 0;
            }
        }

        if (coating.componentType === ComponentConstants.ComponentType.Coating) {
            switch (coating.hangType) {
                case Coating.HangType.wallDirect:
                case Coating.HangType.wallUndirect:
                    let wallHeight = this.height;

                    if (this.slope) {
                        if (coating.hangType === Coating.HangType.wallDirect) {
                            if (this.slopeLength1 > 0) {
                                wallHeight = this.slopeHeight;
                            }
                        } else {
                            if (this.slopeLength2 > 0) {
                                wallHeight = this.slopeHeight;
                            }
                        }
                    }

                    let surface = this.length * wallHeight;
                    let joineries = this.joineries;
                    for (let i = 0; i < joineries.length; i++) {
                        let joineryBottom = joineries[i].floorHeight;
                        let joineryTop = joineries[i].floorHeight + joineries[i].height;
                        if (joineries[i].transom) {
                            joineryTop = joineries[i].transomHeight;
                        }
                        if (joineries[i].bottomTransom) {
                            joineryBottom = joineries[i].bottomTransomHeight;
                        }
                        if (joineryTop > wallHeight) {
                            joineryTop = wallHeight;
                        }
                        surface -= this.joineries[i].length * (joineryTop - joineryBottom);
                    }
                    return surface / (100 * 100 * 100);

                case Coating.HangType.slopeDirect:
                    if (this.slope && this.slopeLength1 > 0) {
                        let height = this.height - this.slopeHeight;
                        let width = this.slopeLength1;
                        return (Math.sqrt(height * height + width * width) * this.length) / (100 * 100 * 100);
                    }
                    break;

                case Coating.HangType.slopeUndirect:
                    if (this.slope && this.slopeLength2 > 0) {
                        let height = this.height - this.slopeHeight;
                        let width = this.slopeLength2;
                        return (Math.sqrt(height * height + width * width) * this.length) / (100 * 100 * 100);
                    }
                    break;

                case Coating.HangType.plinthDirect:
                case Coating.HangType.plinthUndirect:
                    // Approximation with 10cm plinth height (instead of getting it from the room)
                    return (this.length * 100) / (100 * 100 * 100);

                case Coating.HangType.corniceDirect:
                case Coating.HangType.corniceUndirect:
                    // Approximation with 15cm height surface ~ sqrt(10 * 10 + 10 * 10)
                    return (this.length * 150) / (100 * 100 * 100);
            }
        } else {
            // Coating area
            return coating.area / (100 * 100 * 100);
        }

        return 0;
    }

    /**
     * Getter for the wall type
     *
     */
    getWallType(): string {
        var componentList = this.getComponents(ComponentConstants.ComponentType.WallType);
        if (componentList.length > 0) {
            return (componentList[0] as WallType).wallTypeId;
        } else {
            return null;
        }
    }

    get isSpecialWall(): boolean{
        return this.getWallType() !== null;
    }

    /**
     * Getter for the thickness of the wall
     *
     */
    get thickness(): number {
        if (this.temporary === null) {
            return this._thickness;
        } else {
            return (this.temporary as TemporaryWall).thickness;
        }
    }

    /**
     * Setter for the thickness of the wall
     *
     * @param {*} t
     */
    set thickness(t: number) {
        if (this.temporary === null) {
            this._thickness = t;
        } else {
            (this.temporary as TemporaryWall).thickness = t;
            this.temporary.recomputeValidity = true;
        }
    }

    /**
     * Getter for the height of the wall
     *
     */
    get height(): number {
        if (this.temporary === null) {
            return this._height;
        } else {
            return (this.temporary as TemporaryWall).height;
        }
    }

    /**
     * Setter for the height of the wall
     *
     * @param {*} h
     */
    set height(h: number) {
        if (this.temporary === null) {
            this._height = h;
        } else {
            (this.temporary as TemporaryWall).height = h;
        }
    }

    get transform(): Transform {
        if (this.temporary === null) {
            return this._transform;
        } else {
            return (this.temporary as TemporaryWall).transform;
        }
    }

    /**
     * Gettter for transform depend on temporary state
     * @returns {*}
     */
    get globalMatrix() {
        if (this.temporary === null) {
            return this.transform.globalMatrix;
        } else {
            return (this.temporary as TemporaryWall).transform.globalMatrix;
        }
    }

    /**
     * Getter for the beginning of the wall
     *
     */
    get begin(): math.vec3 {
        if (this.temporary === null) {
            return this.transform.localPosition;
        } else {
            return (this.temporary as TemporaryWall).begin;
        }
    }

    /**
     * Setter for the beginning of the wall
     *
     * @param {*} newBegin
     */
    set begin(b: math.vec3) {
        if (this.temporary === null) {
            let newWallVec = math.vec3.create();
            math.vec3.subtract(newWallVec, this._end, b);
            let wallVec = math.vec3.create();
            math.vec3.subtract(wallVec, this._end, this.begin);
            this.transform.localPosition = b;
            this.transform.globalZRotation = Math.atan2(newWallVec[1], newWallVec[0]);

            this._length = math.vec3.length(newWallVec);
            math.vec3.set(this._wallDirection, this.transform.localMatrix[0], this.transform.localMatrix[1], this.transform.localMatrix[2]);
        } else {
            (this.temporary as TemporaryWall).begin = b;
            this.temporary.recomputeValidity = true;
        }
        for (let i = 0; i < this.rooms.length; i++) {
            this.rooms[i].wallsOrderer = false;
        }
    }

    get shiftedBegin(): math.vec3 {
        let result = math.vec3.clone(this.begin);
        let normal = this.normal;
        math.vec3.multiply(normal, normal, math.vec3.fromValues(this.shiftOffset, this.shiftOffset, this.shiftOffset));
        math.vec3.subtract(result, result, normal);
        return result;
    }

    /**
     * Getter for the end of the wall
     *
     */
    get end(): math.vec3 {
        if (this.temporary === null) {
            return this._end;
        } else {
            return (this.temporary as TemporaryWall).end;
        }
    }

    /**
     * Setter for the end of the wall
     *
     * @param {*} newEnd
     */
    set end(e: math.vec3) {
        if (this.temporary === null) {
            let newWallVec = math.vec3.create();
            math.vec3.subtract(newWallVec, e, this.begin);
            let wallVec = math.vec3.create();
            math.vec3.subtract(wallVec, this._end, this.begin);
            this.transform.globalZRotation = Math.atan2(newWallVec[1], newWallVec[0]);

            this._length = math.vec3.length(newWallVec);
            math.vec3.set(this._wallDirection, this.transform.localMatrix[0], this.transform.localMatrix[1], this.transform.localMatrix[2]);

            math.vec3.set(this._end, this._wallDirection[0] * this._length, this._wallDirection[1] * this._length, this._wallDirection[2] * this._length);
            math.vec3.add(this._end, this._end, this.begin);
        } else {
            (this.temporary as TemporaryWall).end = e;
            this.temporary.recomputeValidity = true;
        }
        for (let i = 0; i < this.rooms.length; i++) {
            this.rooms[i].wallsOrderer = false;
        }
    }

    get shiftedEnd(): math.vec3 {
        let result = math.vec3.clone(this.end);
        let normal = this.normal;
        math.vec3.multiply(normal, normal, math.vec3.fromValues(this.shiftOffset, this.shiftOffset, this.shiftOffset));
        math.vec3.subtract(result, result, normal);
        return result;
    }

    get center(): math.vec3 {
        let result = math.vec3.create();
        math.vec3.add(result, this.begin, this.end);
        math.vec3.divide(result, result, math.vec3.fromValues(2, 2, 2));
        return result;
    }

    get shiftedCenter(): math.vec3 {
        let result = math.vec3.create();
        math.vec3.add(result, this.shiftedBegin, this.shiftedEnd);
        math.vec3.divide(result, result, math.vec3.fromValues(2, 2, 2));
        return result;
    }

    /**
     * Getter for the segment of the wall
     *
     */
    get segment(): Segment {
        let begin = math.vec3.create();
        let end = math.vec3.create();

        if (this.temporary === null) {
            math.vec3.set(begin, this.begin[0] + this.wallDirection[1] * -this.shiftOffset, this.begin[1] + this.wallDirection[0] * this.shiftOffset, 0);
            math.vec3.set(end, this.end[0] + this.wallDirection[1] * -this.shiftOffset, this.end[1] + this.wallDirection[0] * this.shiftOffset, 0);
        } else {
            math.vec3.set(begin, (this.temporary as TemporaryWall).begin[0] + this.wallDirection[1] * -this.shiftOffset, (this.temporary as TemporaryWall).begin[1] + this.wallDirection[0] * this.shiftOffset, 0);
            math.vec3.set(end, (this.temporary as TemporaryWall).end[0] + this.wallDirection[1] * -this.shiftOffset, (this.temporary as TemporaryWall).end[1] + this.wallDirection[0] * this.shiftOffset, 0);
        }

        return new Segment(begin, end);
    }

    /**
     * Setter for the segment of the wall
     *
     * @param {*} s
     */
    set segment(s: Segment) {
        this.begin = s.begin;
        this.end = s.end;
    }

    get lengthModifier(): number {
        return this._lengthModifier;
    }

    set lengthModifier(newValue) {
        this._lengthModifier = newValue;
    }

    /**
     * Getter for the length of the wall
     *
     */
    get length(): number {
        if (this.temporary === null) {
            return this._length;
        } else {
            return (this.temporary as TemporaryWall).length;
        }
    }

    /**
     * Set the begin and the end of the wall
     *
     */
    set length(l: number) {
        this.setWallLength(l, true);
    }

    /**
     * Getter for the direction of the wall
     *
     */
    get wallDirection(): math.vec3 {
        if (this.temporary === null) {
            return this._wallDirection;
        } else {
            return (this.temporary as TemporaryWall).wallDirection;
        }
    }

    get normal(): math.vec3 {
        let result = math.vec3.create();
        let up = math.vec3.fromValues(0, 0, 1);
        math.vec3.cross(result, this.wallDirection, up);
        return result;
    }

    /**
     * Getter for the joineries tabular
     *
     */
    get joineries(): Array<Joinery> {
        return this._joineries;
    }

    recomputeJoineriesPosition() {
        for (let i = 0; i < this.joineries.length; i++) {
            let position = math.vec3.create();

            position[2] = this.joineries[i].position[2];

            if (this.joineries[i].joineryType === SceneConstants.JoineryType.velux) {
                let slope = this.getSlope(this.joineries[i]);
                let sign = 1;
                if (slope == 2) {
                    sign = -1;
                }

                let distToWall = SavaneMath.distanceToLine(this.joineries[i].position, this.begin, this.end);
                let dist = math.vec3.dist(this.begin, SavaneMath.getProjection(this.joineries[i].position, this.begin, this.end));

                position[0] = this.begin[0] + this.wallDirection[0] * dist + sign * this.wallDirection[1] * (-this.shiftOffset + distToWall);
                position[1] = this.begin[1] + this.wallDirection[1] * dist + sign * this.wallDirection[0] * (this.shiftOffset - distToWall);
            } else {
                let joineryPosition = math.vec3.clone(this.joineries[i].position);

                joineryPosition[2] = 0;

                position[0] = this.begin[0] + this.wallDirection[0] * math.vec3.dist(this.begin, joineryPosition) + this.wallDirection[1] * -this.shiftOffset;
                position[1] = this.begin[1] + this.wallDirection[1] * math.vec3.dist(this.begin, joineryPosition) + this.wallDirection[0] * this.shiftOffset;
            }

            this.joineries[i].position = position;
        }
    }

    getSlope(joinery: Joinery): number {
        // No slope, the joinery can't be on a slope
        if (this.slope === false) {
            return 0;
        } else {
            // Get distance of joinery to wall segment
            let dist = SavaneMath.distanceToLine(joinery.position, this.begin, this.end);
            // We want the distance to the edge of the wall and not the center so reduce distance by half thickness of the wall
            dist -= this.thickness / 2;

            let line = new Line(this.begin, this.end);
            let projection = line.Project(joinery.position);

            if (!line.BelongToSegment(projection)) {
                return(0);
            }

            let sideVector = math.vec3.create();
            math.vec3.subtract(sideVector, joinery.position, projection);
            math.vec3.normalize(sideVector, sideVector);

            // Check ortho distance between joinery and wall and if smaller than slope distance then joinery on slope
            if (math.vec3.dot(sideVector, this.normal) > 0) {
                if (dist < this.slopeLength1) {
                    return 1;
                }
            }

            if (math.vec3.dot(sideVector, this.normal) < 0) {
                if (dist < this.slopeLength2) {
                    return 2;
                }
            }
            return 0;
        }
    }

    get boundingBox(): Array<math.vec3> {
        let i;
        let points: Array<math.vec3> = new Array<math.vec3>();
        let point1: math.vec3 = math.vec3.create();
        let halfThick = this.thickness * 0.5;
        let point2: math.vec3 = math.vec3.create();
        let point3: math.vec3 = math.vec3.create();
        let point4: math.vec3 = math.vec3.create();
        let oldShiftOffset = this.shiftOffset;

        if (this.shiftDirection !== 0) {
            let found = false;
            let ordonatedWallBegin;

            if (this.temporary === null) {
                ordonatedWallBegin = wallManager.getWallsAtCornerAngleOrdered(this.begin, this.floor, SavaneConstants.PositionTolerance, -1); // Can it be called on a wall that is outside of floor ? May fail due to parent not being a floor
            } else {
                ordonatedWallBegin = wallManager.getWallsAtPositionAngleOrdered(this.begin, this.floor, SavaneConstants.PositionTolerance, -1, null); // Can it be called on a wall that is outside of floor ? May fail due to parent not being a floor
            }

            if (ordonatedWallBegin !== null && ordonatedWallBegin.length > 1) {
                for (i = 0; i < ordonatedWallBegin.length; i++) {
                    let previousWall = ordonatedWallBegin[i];

                    if (previousWall !== this) {
                        if (Wall.areColinear(this, previousWall, SavaneConstants.ToleranceCollinear)) {
                            if (this.thickness <= previousWall.thickness) {
                                if (this.thickness !== previousWall.thickness || previousWall.shiftOffset !== 0) {
                                    found = true;

                                    switch (this.shiftDirection) {
                                        case -1:
                                            this.shiftOffset = -Math.abs(previousWall.shiftOffset) - (previousWall.thickness - this.thickness) / 2;
                                            break;

                                        case 1:
                                            this.shiftOffset = Math.abs(previousWall.shiftOffset) + (previousWall.thickness - this.thickness) / 2;
                                            break;
                                    }
                                }

                                break;
                            }
                        }
                    }
                }
            }

            if (!found) {
                let ordonatedWallEnd;

                if (this.temporary === null) {
                    ordonatedWallEnd = wallManager.getWallsAtCornerAngleOrdered(this.end, this.floor, SavaneConstants.PositionTolerance, -1);
                } else {
                    ordonatedWallEnd = wallManager.getWallsAtPositionAngleOrdered(this.end, this.floor, SavaneConstants.PositionTolerance, -1, null); // Can it be called on a wall that is outside of floor ? May fail due to parent not being a floor
                }

                if (ordonatedWallEnd !== null && ordonatedWallEnd.length > 1) {
                    for (i = 0; i < ordonatedWallEnd.length; i++) {
                        let nextWall = ordonatedWallEnd[i];

                        if (nextWall !== this) {
                            if (Wall.areColinear(this, nextWall, SavaneConstants.ToleranceCollinear)) {
                                if (this.thickness <= nextWall.thickness) {
                                    found = true;

                                    switch (this.shiftDirection) {
                                        case -1:
                                            this.shiftOffset = -Math.abs(nextWall.shiftOffset) - (nextWall.thickness - this.thickness) / 2;
                                            break;

                                        case 1:
                                            this.shiftOffset = Math.abs(nextWall.shiftOffset) + (nextWall.thickness - this.thickness) / 2;
                                            break;
                                    }

                                    break;
                                }
                            }
                        }
                    }
                }
            }

            if (!found) {
                this.shiftDirection = 0;
                this.shiftOffset = 0;
            }
        } else {
            this.shiftOffset = 0;
        }

        // Shift change, shift joineries on the wall too
        if (oldShiftOffset != this.shiftOffset) {
            this.recomputeJoineriesPosition();
        }

        math.vec3.set(point1, this.begin[0] + this.wallDirection[1] * (-halfThick - this.shiftOffset), this.begin[1] + this.wallDirection[0] * (halfThick + this.shiftOffset), 0);
        math.vec3.set(point2, this.begin[0] + this.wallDirection[1] * (halfThick - this.shiftOffset), this.begin[1] + this.wallDirection[0] * (-halfThick + this.shiftOffset), 0);
        math.vec3.set(point3, this.end[0] + this.wallDirection[1] * (halfThick - this.shiftOffset), this.end[1] + this.wallDirection[0] * (-halfThick + this.shiftOffset), 0);
        math.vec3.set(point4, this.end[0] + this.wallDirection[1] * (-halfThick - this.shiftOffset), this.end[1] + this.wallDirection[0] * (halfThick + this.shiftOffset), 0);

        points.push(point1);
        points.push(point2);
        points.push(point3);
        points.push(point4);
        return points;
    }

    /**
     * add a joinery on the wall
     *
     */
    addJoinery(aJoinery: Joinery) {
        try {
            if (aJoinery.isJoineryEntity()) {
                let position = math.vec3.clone(aJoinery.position);
                this.addChild(aJoinery);
                this._joineries.push(aJoinery);
                aJoinery.position = position;
            } else {
                throw new TypeError("the parameter is not a joineryEntity");
            }
        } catch (err) {
            console.log(err);
        }
    }

    /**
     * delete a joinery on the wall of id = joineryId
     *
     * @param {Number} joineryId
     */
    deleteJoinery(joineryId: number) {
        let count = this.children.length;
        for (let i = 0; i < count; i++) {
            if (this.children[i].id === joineryId) {
                try {
                    if (this.children[i].isJoineryEntity()) {
                        this.children.splice(i, 1);
                        for (let j = 0; j < this._joineries.length; j++) {
                            if (this._joineries[j].id === joineryId) {
                                this._joineries.splice(j, 1);
                            }
                        }
                    } else {
                        throw new TypeError("the entity of id = parameter, is not a joineryEntity");
                    }
                } catch (err) {
                    console.log(err);
                }
                break;
            }
        }
        try {
            if (count === this.children.length) {
                throw new TypeError("There is no joineryEntity of id = parameter");
            }
        } catch (err) {
            console.log(err);
        }
    }

    deleteJoineries() {
        this.children = this.children.filter(function (item) {
            return !item.isJoineryEntity();
        });
        this._joineries = [];
    }

    /**
     * test if the point given in parameter is the beginning of the wall
     *
     * @param {vec3} aPoint
     * @param {Number} precision
     * @param {Boolean} wallThick
     */
    isBegin(aPoint: math.vec3, precision?: number, wallThick?: boolean): boolean {
        if (precision === undefined) {
            return SavaneMath.equal(this.begin, aPoint);
        } else if (wallThick === undefined || !wallThick) {
            return math.vec3.squaredDistance(this.begin, aPoint) < precision * precision;
        } else {
            let dist = math.vec3.squaredDistance(this.begin, aPoint);
            let squaredPrecision = precision * precision;
            if (dist < squaredPrecision) {
                return true;
            }

            return false;
        }
    }

    /**
     * test if the point given in parameter is the end of the wall
     *
     * @param {vec3} aPoint
     * @param {Number} precision
     * @param {Boolean} wallThick
     */
    isEnd(aPoint: math.vec3, precision?: number, wallThick?: boolean): boolean {
        if (precision === undefined) {
            return SavaneMath.equal(this.end, aPoint);
        } else if (wallThick === undefined || !wallThick) {
            return math.vec3.squaredDistance(this.end, aPoint) < precision * precision;
        } else {
            let dist = math.vec3.squaredDistance(this.end, aPoint);
            let squaredPrecision = precision * precision;
            if (dist < squaredPrecision) {
                return true;
            }

            return false;
        }
    }

    /**
     * test if the point given in parameter is a corner of the wall
     *
     * @param {vec3} aPoint
     * @param {?Number} precision
     * @param {?Boolean} wallThick
     */
    isCorner(aPoint: math.vec3, precision?: number, wallThick?: boolean) {
        return this.isBegin(aPoint, precision, wallThick) || this.isEnd(aPoint, precision, wallThick);
    }

    addToCappedProperty(property: string, value: any) : number {
        let capped = 0;
        if (this[property] === undefined) {
            return -1;
        }

        if (property === "height") {
            let minValue = SceneConstants.MinimalWallHeight;
            let joineries = this.joineries;
            for (var i = 0; i < joineries.length; ++i) {
                if (joineries[i].joineryType !== SceneConstants.JoineryType.velux) {
                    if (joineries[i].height + joineries[i].floorHeight > minValue) {
                        minValue = joineries[i].height + joineries[i].floorHeight;
                    }
                }
            }
            if (this[property] + value < minValue) {
                this[property] = minValue;
                capped = 1;
            }
            // Check against slope height too
            if (this.slope) {
                if (this[property] + value < this.slopeHeight) {
                    this[property] = this.slopeHeight;
                    capped = 1;
                }
            }
        }
        if (property === "slopeHeight") {
            if (this[property] + value > this.height) {
                this[property] = this.height;
                capped = 2;
            }
        }
        if (property === "thickness") {
            if (this[property] + value <= SceneConstants.MinimalWallThickness) {
                this[property] = SceneConstants.MinimalWallThickness;
                capped = 1;
            }
            if (this[property] + value >= SceneConstants.MaxWallThickness) {
                this[property] = SceneConstants.MaxWallThickness;
                capped = 2;
            }
        }
        if (capped === 0) {
            this[property] += value;
        }
        return capped;
    }

    canBeOffset(): boolean {
        let ordonatedWallBegin;

        if (this.temporary === null) {
            ordonatedWallBegin = wallManager.getWallsAtCornerAngleOrdered(this.begin, this.floor, SavaneConstants.PositionTolerance, -1); // Can it be called on a wall that is outside of floor ? May fail due to parent not being a floor
        } else {
            ordonatedWallBegin = wallManager.getWallsAtPositionAngleOrdered(this.begin, this.floor, SavaneConstants.PositionTolerance, -1, null); // Can it be called on a wall that is outside of floor ? May fail due to parent not being a floor
        }

        if (ordonatedWallBegin !== null && ordonatedWallBegin.length > 1) {
            for (let i = 0; i < ordonatedWallBegin.length; ++i) {
                let previousWall = ordonatedWallBegin[i];

                if (previousWall !== this) {
                    if (Wall.areColinear(this, previousWall, SavaneConstants.ToleranceCollinear)) {
                        if (this.thickness < previousWall.thickness) {
                            return true;
                        }

                        if (this.thickness === previousWall.thickness && previousWall.shiftOffset !== 0) {
                            return true;
                        }
                    }
                }
            }
        }

        let ordonatedWallEnd;

        if (this.temporary === null) {
            ordonatedWallEnd = wallManager.getWallsAtCornerAngleOrdered(this.end, this.floor, SavaneConstants.PositionTolerance, -1);
        } else {
            ordonatedWallEnd = wallManager.getWallsAtPositionAngleOrdered(this.end, this.floor, SavaneConstants.PositionTolerance, -1, null); // Can it be called on a wall that is outside of floor ? May fail due to parent not being a floor
        }

        if (ordonatedWallEnd !== null && ordonatedWallEnd.length > 1) {
            for (let i = 0; i < ordonatedWallEnd.length; ++i) {
                let nextWall = ordonatedWallEnd[i];

                if (nextWall !== this) {
                    if (Wall.areColinear(this, nextWall, SavaneConstants.ToleranceCollinear)) {
                        if (this.thickness < nextWall.thickness) {
                            return true;
                        }

                        if (this.thickness === nextWall.thickness && nextWall.shiftOffset !== 0) {
                            return true;
                        }
                    }
                }
            }
        }

        return false;
    }

    /**
     * test whether the wall can have offset at the begining
     */
    canBeginHaveOffset(): boolean {
        let ordonatedWallBegin;
        if (this.temporary === null) {
            ordonatedWallBegin = wallManager.getWallsAtCornerAngleOrdered(this.begin, this.floor, SavaneConstants.PositionTolerance, -1); // Can it be called on a wall that is outside of floor ? May fail due to parent not being a floor
        } else {
            ordonatedWallBegin = wallManager.getWallsAtPositionAngleOrdered(this.begin, this.floor, SavaneConstants.PositionTolerance, -1, null); // Can it be called on a wall that is outside of floor ? May fail due to parent not being a floor
        }

        if (ordonatedWallBegin !== null && ordonatedWallBegin.length > 1) {
            let previousWall = ordonatedWallBegin[(ordonatedWallBegin.indexOf(this) - 1 + ordonatedWallBegin.length) % ordonatedWallBegin.length];
            let nextWall = ordonatedWallBegin[(ordonatedWallBegin.indexOf(this) + 1) % ordonatedWallBegin.length];

            let angle1 = this.getWallAngle(true) - nextWall.getWallAngle(nextWall.isBegin(this.begin));
            while (angle1 < -Math.PI) {
                angle1 += 2 * Math.PI;
            }
            while (angle1 > Math.PI) {
                angle1 -= 2 * Math.PI;
            }
            let angle2 = this.getWallAngle(true) - previousWall.getWallAngle(previousWall.isBegin(this.begin));
            while (angle2 < -Math.PI) {
                angle2 += 2 * Math.PI;
            }
            while (angle2 > Math.PI) {
                angle2 -= 2 * Math.PI;
            }
            if (ordonatedWallBegin.length === 2) {
                var otherWallThickness = nextWall.id === this.id ? previousWall.thickness : nextWall.thickness;
                var angle = nextWall.id === this.id ? angle2 : angle1;
                if (Math.abs(angle) > Math.PI / 2 + SavaneConstants.Tolerance && this.thickness - otherWallThickness < -SavaneConstants.PositionTolerance) {
                    let boundingbox = this.boundingBox;
                    let boundingBoxPrev = previousWall.boundingBox;
                    let boundingBoxNext = nextWall.boundingBox;
                    var pointA;
                    var pointB;
                    if (previousWall.isBegin(this.begin)) {
                        pointA = SavaneMath.getCrossPoint(boundingbox[0], boundingbox[3], boundingBoxPrev[0], boundingBoxPrev[1]);
                    } else {
                        pointA = SavaneMath.getCrossPoint(boundingbox[0], boundingbox[3], boundingBoxPrev[2], boundingBoxPrev[3]);
                    }
                    if (nextWall.isBegin(this.begin)) {
                        pointB = SavaneMath.getCrossPoint(boundingbox[1], boundingbox[2], boundingBoxNext[0], boundingBoxNext[1]);
                    } else {
                        pointB = SavaneMath.getCrossPoint(boundingbox[1], boundingbox[2], boundingBoxNext[2], boundingBoxNext[3]);
                    }
                    if (pointB === null || math.vec3.distance(pointB, this.begin) > nextWall.thickness / 2 || pointA === null || math.vec3.distance(pointA, this.begin) > previousWall.thickness / 2) {
                        return false;
                    }
                    return true;
                }
            } else if (ordonatedWallBegin.length > 2) {
                if (Wall.areColinear(this, nextWall, 100 * SavaneConstants.Tolerance) && this.thickness - nextWall.thickness < -SavaneConstants.PositionTolerance) {
                    return true;
                }
                if (Wall.areColinear(this, previousWall, 100 * SavaneConstants.Tolerance) && this.thickness - previousWall.thickness < -SavaneConstants.PositionTolerance) {
                    return true;
                }
            }
        }
        return false;
    }

    /**
     * test whether the wall can have offset at the begining
     */
    canEndHaveOffset(): boolean {
        let ordonatedWallEnd;
        if (this.temporary === null) {
            ordonatedWallEnd = wallManager.getWallsAtCornerAngleOrdered(this.end, this.floor, SavaneConstants.PositionTolerance, -1);
        } else {
            ordonatedWallEnd = wallManager.getWallsAtPositionAngleOrdered(this.end, this.floor, SavaneConstants.PositionTolerance, -1); // Can it be called on a wall that is outside of floor ? May fail due to parent not being a floor
        }

        if (ordonatedWallEnd !== null && ordonatedWallEnd.length > 1) {
            let previousWall = ordonatedWallEnd[(ordonatedWallEnd.indexOf(this) - 1 + ordonatedWallEnd.length) % ordonatedWallEnd.length];
            let nextWall = ordonatedWallEnd[(ordonatedWallEnd.indexOf(this) + 1) % ordonatedWallEnd.length];

            let angle1 = this.getWallAngle(true) - nextWall.getWallAngle(nextWall.isEnd(this.end));
            while (angle1 < -Math.PI) {
                angle1 += 2 * Math.PI;
            }
            while (angle1 > Math.PI) {
                angle1 -= 2 * Math.PI;
            }
            let angle2 = this.getWallAngle(true) - previousWall.getWallAngle(previousWall.isEnd(this.end));
            while (angle2 < -Math.PI) {
                angle2 += 2 * Math.PI;
            }
            while (angle2 > Math.PI) {
                angle2 -= 2 * Math.PI;
            }
            if (ordonatedWallEnd.length === 2) {
                var otherWallThickness = nextWall.id === this.id ? previousWall.thickness : nextWall.thickness;
                var angle = nextWall.id === this.id ? angle2 : angle1;
                if (Math.abs(angle) > Math.PI / 2 + SavaneConstants.Tolerance && this.thickness - otherWallThickness < -SavaneConstants.PositionTolerance) {
                    let boundingbox = this.boundingBox;
                    let boundingBoxPrev = previousWall.boundingBox;
                    let boundingBoxNext = nextWall.boundingBox;
                    var pointC;
                    var pointD;
                    if (nextWall.isBegin(this.end)) {
                        pointD = SavaneMath.getCrossPoint(boundingbox[0], boundingbox[3], boundingBoxNext[0], boundingBoxNext[1]);
                    } else {
                        pointD = SavaneMath.getCrossPoint(boundingbox[0], boundingbox[3], boundingBoxNext[2], boundingBoxNext[3]);
                    }
                    if (previousWall.isBegin(this.end)) {
                        pointC = SavaneMath.getCrossPoint(boundingbox[1], boundingbox[2], boundingBoxPrev[0], boundingBoxPrev[1]);
                    } else {
                        pointC = SavaneMath.getCrossPoint(boundingbox[1], boundingbox[2], boundingBoxPrev[2], boundingBoxPrev[3]);
                    }

                    if (pointD === null || math.vec3.distance(pointD, this.end) > nextWall.thickness / 2 || pointC === null || math.vec3.distance(pointC, this.end) > previousWall.thickness / 2) {
                        return false;
                    }

                    return true;
                }
            } else if (ordonatedWallEnd.length > 2) {
                if (Wall.areColinear(this, nextWall, 100 * SavaneConstants.Tolerance) && this.thickness - nextWall.thickness < -SavaneConstants.PositionTolerance) {
                    return true;
                }
                if (Wall.areColinear(this, previousWall, 100 * SavaneConstants.Tolerance) && this.thickness - previousWall.thickness < -SavaneConstants.PositionTolerance) {
                    return true;
                }
            }
        }
        return false;
    }

    /**
     * test if the point given in parameter is on the wall
     *
     * @param {vec3} aPoint
     * @param {Number} precision
     */
    isPointOnWall(aPoint: math.vec3, precision: number): boolean {
        // var bbox = this.boundingBox;

        // return SavaneMath.isInPoly(aPoint, bbox);
        return this.segment.isPointOnSegment(aPoint, precision + this.thickness * 0.5);
    }

    /**
     * test if the point given in parameter is on the wall
     *
     * @param {vec3} aPoint
     * @param {Number} precision
     */
    isPointStrictlyOnWall(aPoint: math.vec3, precision: number) {
        return this.segment.isPointOnSegment(aPoint, precision);
    }

    /**
     * add a room component to the wall
     *
     * @param {Room} aRoom
     */
    addRoom(aRoom: Room) {
        try {
            if (aRoom.isRoomEntity() && this.rooms.indexOf(aRoom) === -1) {
                this.rooms.push(aRoom);
            } else {
                throw new TypeError("the parameter is not a roomComponent");
            }
        } catch (err) {
            console.log(err);
        }
    }

    /**
     * delete a room component of the wall
     *
     * @param {Number} roomId
     */
    deleteRoom(roomId: number) {
        for (let j = 0; j < this.rooms.length; j++) {
            if (this.rooms[j].id === roomId) {
                this.rooms.splice(j, 1);
                break;
            }
        }
    }

    /**
     * Get rotate angle of wall (around Z-Axis)
     * @param beginToEnd If true the angle will be calculated from begin to end, if false from end to begin
     * @returns {Number} Angle (rad)
     */
    getWallAngle(beginToEnd: boolean): number {
        let normalSide = beginToEnd !== undefined ? beginToEnd : true;
        let lengthDirect = math.vec2.create();
        if (normalSide) {
            math.vec2.set(lengthDirect, this.end[0] - this.begin[0], this.end[1] - this.begin[1]);
        } else {
            math.vec2.set(lengthDirect, this.begin[0] - this.end[0], this.begin[1] - this.end[1]);
        }
        let lengthDistance = Math.sqrt(lengthDirect[0] * lengthDirect[0] + lengthDirect[1] * lengthDirect[1]);
        let angle = Math.atan2(-lengthDirect[1] / lengthDistance, lengthDirect[0] / lengthDistance);
        return SavaneMath.normalizeAngle(angle);
    }

    /**
     *
     *
     */
    setWallLength(newValue, allRelatedWalls) {
        var halfDelta = (this.length - newValue) / 2;
        var deltaLeft;
        var deltaRight;

        switch (this._lengthModifier) {
            case Wall.PossibleLengthModifier.LEFT:
                deltaLeft = halfDelta * 2;
                deltaRight = 0;
                break;
            case Wall.PossibleLengthModifier.MIDDLE:
                deltaLeft = deltaRight = halfDelta;
                break;
            case Wall.PossibleLengthModifier.RIGHT:
                deltaLeft = 0;
                deltaRight = halfDelta * 2;
                break;
        }

        var beginPos: [number, number, number] = [0, 0 ,0];
        var endPos: [number, number, number] = [0, 0 ,0];
        if (this.begin[0] - this.end[0] > 0) [deltaLeft, deltaRight] = [deltaRight, deltaLeft];

        //Remove the wall itself
        var allWallsAtBegin = wallManager.getWallsAtPosition(this.begin, this.floor, 1, null).filter((x) => x.id != this.id);
        var allWallsAtEnd = wallManager.getWallsAtPosition(this.end, this.floor, 1, null).filter((x) => x.id != this.id);

        for (var i = 0; i < this._wallDirection.length; i++) {
            beginPos[i] = (this.begin[i] + this._wallDirection[i] * deltaLeft);
            endPos[i] = (this.end[i] - this._wallDirection[i] * deltaRight);
        }

        if (allRelatedWalls) {
            //Move every wall at the same position
            if (allWallsAtBegin) {
                for (let i = 0; i < allWallsAtBegin.length; i++) {
                    if (allWallsAtBegin[i].isEnd(this.begin)) allWallsAtBegin[i].end = beginPos;
                    if (allWallsAtBegin[i].isBegin(this.begin)) allWallsAtBegin[i].begin = beginPos;
                }
            }

            if (allWallsAtEnd) {
                for (let i = 0; i < allWallsAtEnd.length; i++) {
                    if (allWallsAtEnd[i].isEnd(this.end)) allWallsAtEnd[i].end = endPos;
                    if (allWallsAtEnd[i].isBegin(this.end)) allWallsAtEnd[i].begin = endPos;
                }
            }
        }

        this.segment = new Segment(beginPos, endPos);
    }

    /**
     * Create the temporaryWall that will be used during edition
     */
    startTemporary() {
        this.temporary = new TemporaryWall(this);
        for (let i = 0; i < this.joineries.length; i++) {
            this.joineries[i].startTemporary();
        }
    }

    /**
     * delete the temporaryWall
     */
    endTemporary() {
        this.temporary = null;
        for (let i = 0; i < this.joineries.length; i++) {
            this.joineries[i].endTemporary();
        }
    }

    /**
     * save the temporary data in the wall and delete the temporaryWall
     */
    saveAndEndTemporary() {
        let thicknessTemp = this.thickness;
        let heightTemp = this.height;
        let beginTemp = this.begin;
        let endTemp = this.end;
        let shiftTemp = this.shiftDirection;
        let forceNoPlinthTemp = this.forceNoPlinth;
        let forceNoCorniceTemp = this.forceNoCornice;

        this.temporary = null;
        this.thickness = thicknessTemp;
        this.height = heightTemp;
        this.begin = beginTemp;
        this.end = endTemp;
        this.shiftDirection = shiftTemp;
        this.forceNoPlinth = forceNoPlinthTemp;
        this.forceNoCornice = forceNoCorniceTemp;
    }

    _beginNoze(normal: math.vec3, thickness: number) : Line {
        let n = math.vec3.clone(normal);
        math.vec3.multiply(n, n, math.vec3.fromValues(thickness, thickness, thickness));
        let begin = math.vec3.clone(this.begin);
        math.vec3.subtract(begin, begin, n);
        let end = math.vec3.clone(this.begin);
        math.vec3.add(end, end, n);
        return new Line(begin, end);
    }

    _leftLine(wall: Wall, normal: math.vec3, thickness: number) : Line {
        let n = math.vec3.clone(normal);
        math.vec3.multiply(n, n, math.vec3.fromValues(thickness, thickness, thickness));
        let begin = wall.shiftedBegin;
        math.vec3.subtract(begin, begin, n);
        let end = wall.shiftedEnd;
        math.vec3.subtract(end, end, n);
        return new Line(begin, end);
    }

    _endNoze(normal: math.vec3, thickness: number) : Line {
        let n = math.vec3.clone(normal);
        math.vec3.multiply(n, n, math.vec3.fromValues(thickness, thickness, thickness));
        let begin = math.vec3.clone(this.end);
        math.vec3.subtract(begin, begin, n);
        let end = math.vec3.clone(this.end);
        math.vec3.add(end, end, n);
        return new Line(begin, end);
    }

    _rightLine(wall: Wall, normal: math.vec3, thickness: number): Line {
        let n = math.vec3.clone(normal);
        math.vec3.multiply(n, n, math.vec3.fromValues(thickness, thickness, thickness));
        let begin = wall.shiftedBegin;
        math.vec3.add(begin, begin, n);
        let end = wall.shiftedEnd;
        math.vec3.add(end, end, n);
        return new Line(begin, end);
    }

    _getIntersectionDatas(walls: Array<Wall>, wLeft: Line, wRight: Line, fromEnd: boolean): any {
        let index = 0;
        for (var i = 0; i < walls.length; ++i) {
            index = i;
            if (walls[i].id === this.id) {
                break;
            }
        }
        let w1 = walls[(index + 1) % walls.length];
        let w2 = walls[(index - 1 + walls.length) % walls.length];

        // determine walls directions
        let reverseW1 = false;
        if (fromEnd && SavaneMath.equal(this.end, w1.end)) {
            reverseW1 = true;
        } else if (SavaneMath.equal(this.begin, w1.end)) {
            reverseW1 = true;
        }

        let reverseW2 = false;
        if (fromEnd && SavaneMath.equal(this.end, w2.end)) {
            reverseW2 = true;
        } else if (SavaneMath.equal(this.begin, w2.end)) {
            reverseW2 = true;
        }

        let dw1 = math.vec3.clone(w1.wallDirection);
        let nw1 = w1.normal;
        if (reverseW1) {
            math.vec3.negate(dw1, dw1);
            math.vec3.negate(nw1, nw1);
        }
        let dw2 = math.vec3.clone(w2.wallDirection);
        let nw2 = w2.normal;
        if (reverseW2) {
            math.vec3.negate(dw2, dw2);
            math.vec3.negate(nw2, nw2);
        }

        let n = this.normal;
        let dw = math.vec3.clone(this.wallDirection);
        if (fromEnd) {
            math.vec3.negate(dw, dw);
            math.vec3.negate(n, n);
        }

        let right = this._rightLine(w2, nw2, w2.thickness / 2);
        let left = this._leftLine(w1, nw1, w1.thickness / 2);

        let EPS = 0.00001;
        let useNozeLeft = false;
        let useNozeRight = false;
        let nozeLeft = null;
        let nozeRight = null;

        let normal = this.thickness < w1.thickness ? nw1 : n;
        let thickness = Math.min(this.thickness, w1.thickness) + EPS;
        let line = this.thickness < w1.thickness ? wRight : left;
        if (fromEnd) {
            nozeRight = this._endNoze(normal, thickness / 2);
        } else {
            nozeRight = this._beginNoze(normal, thickness / 2);
        }

        if (this.thickness !== w1.thickness) {
            if (nozeRight.BelongToSegment(nozeRight.Intersect(line)) || this.isParallel(w1, 0.98)) {
                useNozeRight = math.vec3.dot(dw, dw1) < 0;
            }
        }

        normal = this.thickness < w2.thickness ? nw2 : n;
        thickness = Math.min(this.thickness, w2.thickness) + EPS;
        line = this.thickness < w2.thickness ? wLeft : right;
        if (fromEnd) {
            nozeLeft = this._endNoze(normal, thickness / 2);
        } else {
            nozeLeft = this._beginNoze(normal, thickness / 2);
        }

        if (this.thickness !== w2.thickness) {
            if (nozeLeft.BelongToSegment(nozeLeft.Intersect(line)) || this.isParallel(w2, 0.98)) {
                useNozeLeft = math.vec3.dot(dw, dw2) < 0;
            }
        }

        if (useNozeLeft !== useNozeRight && (this.shiftOffset || w1.shiftOffset || w2.shiftOffset)) {
            useNozeLeft = false;
            useNozeRight = false;
        }

        if (math.vec3.dot(dw, dw1) > 0) {
            useNozeRight = false;
        }

        if (math.vec3.dot(dw, dw2) > 0) {
            useNozeLeft = false;
        }

        return {
            right,
            left,
            nozeRight,
            nozeLeft,
            useNozeLeft,
            useNozeRight,
            dw,
            dw1,
            dw2
        };
    }

    isParallel(other: Wall, precision: number): boolean {
        var dV = math.vec3.create();
        math.vec3.subtract(dV, other.end, other.begin);
        math.vec3.normalize(dV, dV);
        var d = math.vec3.create();
        math.vec3.subtract(d, this.end, this.begin);
        math.vec3.normalize(d, d);

        return Math.abs(math.vec3.dot(dV, d)) > precision;
    }

    /**
     * Retrieve the bounding box of the wall with neighboor intersections calcul
     * @returns {*[]}
     */
    get boundingBoxCut(): Array<math.vec3> {
        let bbox = this.boundingBox;

        let wallsBegin = wallManager.getWallsAtCornerAngleOrdered(this.begin, this.floor, SavaneConstants.PositionTolerance, -1);
        let wallsEnd = wallManager.getWallsAtCornerAngleOrdered(this.end, this.floor, SavaneConstants.PositionTolerance, -1);

        let EPS = 0.00001;
        let p1 = null,
            p2 = null,
            p3 = null,
            p4 = null;
        if (wallsBegin.length > 1) {
            let left = this._leftLine(this, this.normal, this.thickness / 2);
            let right = this._rightLine(this, this.normal, this.thickness / 2);
            let datas = this._getIntersectionDatas(wallsBegin, left, right, false);
            if (datas.useNozeLeft || left.IsParallel(datas.right)) {
                p1 = left.Intersect(datas.nozeLeft);
            } else {
                p1 = left.Intersect(datas.right);
                let dot = math.vec3.dot(datas.dw, datas.dw2);
                if (dot < -EPS) {
                    let length = Math.min(datas.right.Length, this.length);
                    if (p1 && math.vec3.distance(p1, this.begin) > length) {
                        // shifted wall :: intersection can go far behind - blocked to noze
                        p1 = left.Intersect(datas.nozeLeft);
                    }
                }
            }
            if (datas.useNozeRight || right.IsParallel(datas.left)) {
                p2 = right.Intersect(datas.nozeRight);
            } else {
                p2 = right.Intersect(datas.left);
                let dot = math.vec3.dot(datas.dw, datas.dw1);
                if (dot < -EPS) {
                    let length = Math.min(datas.left.Length, this.length);
                    if (p2 && math.vec3.distance(p2, this.begin) > length) {
                        // shifted wall :: intersection can go far behind - blocked to noze
                        p2 = right.Intersect(datas.nozeRight);
                    }
                }
            }
        }

        if (wallsEnd.length > 1) {
            let left = this._rightLine(this, this.normal, this.thickness / 2);
            let right = this._leftLine(this, this.normal, this.thickness / 2);
            let datas = this._getIntersectionDatas(wallsEnd, left, right, true);
            if (datas.useNozeRight || right.IsParallel(datas.left)) {
                p4 = right.Intersect(datas.nozeRight);
            } else {
                p4 = right.Intersect(datas.left);
                let dot = math.vec3.dot(datas.dw, datas.dw1);
                if (dot < -EPS) {
                    let length = Math.min(datas.left.Length, this.length);
                    if (p4 && math.vec3.distance(p4, this.end) > length) {
                        // shifted wall :: intersection can go far behind - blocked to noze
                        p4 = right.Intersect(datas.nozeRight);
                    }
                }
            }
            if (datas.useNozeLeft || left.IsParallel(datas.right)) {
                p3 = left.Intersect(datas.nozeLeft);
            } else {
                p3 = left.Intersect(datas.right);
                let dot = math.vec3.dot(datas.dw, datas.dw2);
                if (dot < -EPS) {
                    let length = Math.min(datas.right.Length, this.length);
                    if (p3 && math.vec3.distance(p3, this.end) > length) {
                        // shifted wall :: intersection can go far behind - blocked to noze
                        p3 = left.Intersect(datas.nozeLeft);
                    }
                }
            }
        }

        if (!p1) {
            p1 = bbox[0];
        }
        if (!p2) {
            p2 = bbox[1];
        }
        if (!p3) {
            p3 = bbox[2];
        }
        if (!p4) {
            p4 = bbox[3];
        }

        return [p1, p2, p3, p4];
    }

    /**
     * test if the two walls have a similar corner
     *
     * @param {Wall} wall1
     * @param {Wall} wall2
     * @returns {*}
     */
    static areAdjacent(wall1: Wall, wall2: Wall) {
        return wall1.isCorner(wall2.begin) || wall1.isCorner(wall2.end);
    }

    /**
     * test if walls are colinear
     *
     * @param {Wall} wall1
     * @param {Wall} wall2
     * @param {Number} colinearPrecision
     */
    static areColinear(wall1: Wall, wall2: Wall, colinearPrecision?: number) {
        return Segment.areColinear(wall1.segment, wall2.segment, colinearPrecision);
    }

    /**
     * test if walls can be merged
     *
     * @param {Wall} wall1
     * @param {Wall} wall2
     */
    static mergeWall(wall1: Wall, wall2: Wall) {
        // Check if both walls are special, if not, merge not allowed
        if (wall1.isSpecialWall !== wall2.isSpecialWall) {
            return null;
        }

        // Check if one of the wall has a slope, merge not allowed
        if (wall1.slope || wall2.slope) {
            return null;
        }

        if (wall1.height !== wall2.height) {
            return null;
        }

        // If both walls are special
        if (wall1.isSpecialWall && wall2.isSpecialWall) {
            // Check all wallType components are the same
            let wall1Type = wall1.getComponents(ComponentConstants.ComponentType.WallType);
            let wall2Type = wall2.getComponents(ComponentConstants.ComponentType.WallType);

            // Not same amount of components, merge not allowed
            if (wall1Type.length !== wall2Type.length) {
                return null;
            }

            // Check that all elements in wall1 are also in wall2, otherwise, merge not allowed
            for (let i = 0; i < wall1Type.length; i++) {
                let j;

                for (j = 0; j < wall2Type.length; j++) {
                    // Found ?
                    if ((wall1Type[i] as WallType).wallTypeId === (wall2Type[j] as WallType).wallTypeId) {
                        // Yes, remove the element from wall2Type array and treat next element from wall1Type
                        wall2Type.splice(j, 1, null);
                        break;
                    }
                }

                // If reach here with j === wall2Type.length ==> element of wall1Type not found ==> merge forbidden
                if (j === wall2Type.length) {
                    return null;
                }
            }
        }

        if (this.areColinear(wall1, wall2, SavaneConstants.ToleranceCollinear) && Math.abs(wall1.thickness - wall2.thickness) < SavaneConstants.PositionTolerance) {
            let point1 = math.vec3.create();
            let point2 = math.vec3.create();
            if (SavaneMath.equal(wall1.begin, wall2.begin)) {
                math.vec3.copy(point1, wall1.end);
                math.vec3.copy(point2, wall2.end);
                return new Segment(point1, point2);
            }
            if (SavaneMath.equal(wall1.end, wall2.begin)) {
                math.vec3.copy(point1, wall1.begin);
                math.vec3.copy(point2, wall2.end);
                return new Segment(point1, point2);
            }
            if (SavaneMath.equal(wall1.begin, wall2.end)) {
                math.vec3.copy(point1, wall1.end);
                math.vec3.copy(point2, wall2.begin);
                return new Segment(point1, point2);
            }
            if (SavaneMath.equal(wall1.end, wall2.end)) {
                math.vec3.copy(point1, wall1.begin);
                math.vec3.copy(point2, wall2.begin);
                return new Segment(point1, point2);
            }
        }

        return null;
    }

    /**
     * test if walls are crossing each others
     *
     * @param {Wall} wall1
     * @param {Wall} wall2
     * @param precision
     */
    static areWallCrossing(wall1: Wall, wall2: Wall, precision: number) {
        let bbox1 = wall1.boundingBox;
        let bbox2 = wall2.boundingBox;

        for (let i = 0; i < 4; i++) {
            let seg1 = new Segment(bbox1[i], bbox1[(i + 1) % 4]);
            for (let j = 0; j < 4; j++) {
                let seg2 = new Segment(bbox2[j], bbox2[(j + 1) % 4]);

                if (Segment.areSegmentCrossing(seg1, seg2, precision)) {
                    return true;
                }
            }
        }
    }
}

export namespace Wall {
    
    export enum PossibleLengthModifier {
        LEFT = -1,
        MIDDLE = 0,
        RIGHT = 1
    };

}
