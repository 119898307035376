import { ComponentConstants, math } from '../SavaneJS';
import { Component } from './Component';

export class BoundingBox extends Component {
    private _center: math.vec3;
    private _length: number;
    private _width: number;
    private _height: number;

    constructor() {
        super();
        this._center = math.vec3.create();
        this._length = 0;
        this._width = 0;
        this._height = 0;
        this._isUnique = true;
    }

    clone(): BoundingBox {
        let box = new BoundingBox();
        box.update(math.vec3.clone(this._center), this.length, this.width, this.height);
        return box;
    }

    get componentType(): ComponentConstants.ComponentType {
        return ComponentConstants.ComponentType.BoundingBox;
    }

    get center2d(): math.vec2 {
        let center = math.vec2.create();
        math.vec2.set(center, this._center[0], this._center[1]);
        return center;
    }

    get center3d(): math.vec3 {
        return this._center;
    }

    get center(): math.vec3 {
        return this._center;
    }

    get width(): number {
        return this._width;
    }

    get length(): number {
        return this._length;
    }

    get height(): number {
        return this._height;
    }

    set center(c: math.vec3) {
        this._center = c;
    }

    set center3d(c: math.vec3) {
        this._center = c;
    }

    set width(w: number) {
        this._width = w;
    }

    set length(l: number) {
        this._length = l;
    }

    set height(h: number) {
        this._height = h;
    }

    update(center: math.vec3, l: number, w: number, h: number) {
        this._center = center;
        this._length = l;
        this._width = w;
        this._height = h;
    }
}
