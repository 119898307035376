import { Command, CommandEnum, EditWallPropertiesCommand, EditTechnicalElementCommand } from "./CommandModule";
import { Events } from "../events";
import { eventsManager } from "../managers/EventsManager";
import { EntityFactory, CorniceType, ComponentConstants,  Room, SceneConstants, roomManager } from "../SavaneJS";

export class EditRoomPropertiesCommand extends Command {
    private _modifiedRoom: Room;
    private _currentRoom: Room;
    // To store commands created to perform the change
    private _subCommands: Array<Command> = [];
    
    constructor(currentRoom: Room) {
        super();
        // Clone current room so it copies the temporary fields into the cloned room entity
        this._modifiedRoom = EntityFactory.cloneEntity(currentRoom, false) as Room;

        this._currentRoom = currentRoom;

        let i: number;
        let roomWalls = currentRoom.walls;
        let nonRoomedWalls = currentRoom.nonRoomedWalls;

        // End the room temporary so it drops the temporary data
        currentRoom.endTemporary();

        // Change room height ?
        if (this._modifiedRoom.height !== this._currentRoom.height) {
            // Parse all walls passed to the constructor and create en edit wall properties command for each of them and store them in the wallcommand field
            for (i = 0; i < roomWalls.length; i++) {
                if (roomWalls[i].height <= 1050 || roomWalls[i].isSpecialWall) {
                    continue;
                }

                let finalHeight = this._modifiedRoom.height;

                for (let j = 0; j < roomWalls[i].rooms.length; j++) {
                    if (roomWalls[i].rooms[j].id !== this._modifiedRoom.id) {
                        if (roomWalls[i].rooms[j].height > finalHeight) {
                            finalHeight = roomWalls[i].rooms[j].height;
                        }
                    }
                }

                roomWalls[i].startTemporary();
                roomWalls[i].height = finalHeight;
                this._subCommands.push(new EditWallPropertiesCommand(roomWalls[i]));
            }
            for (i = 0; i < nonRoomedWalls.length; i++) {
                if (nonRoomedWalls[i].height <= 1050 || nonRoomedWalls[i].isSpecialWall) {
                    continue;
                }
                nonRoomedWalls[i].startTemporary();
                nonRoomedWalls[i].height = this._modifiedRoom.height;
                this._subCommands.push(new EditWallPropertiesCommand(nonRoomedWalls[i]));
            }
        }
        if (this._modifiedRoom.floorHeight !== this._currentRoom.floorHeight) {
            let techElements = currentRoom.floor.technicalElementsWithStaircases;
            for (i = 0; i < techElements.length; i++) {
                let room = roomManager.getRoomAtPosition(techElements[i].position, currentRoom.floor);

                if (room) {
                    if (room.id === currentRoom.id) {
                        switch (techElements[i].objectId) {
                            case SceneConstants.TechnicalElementType.beam:
                            case SceneConstants.TechnicalElementType.spotLight:
                            case SceneConstants.TechnicalElementType.frame:
                            case SceneConstants.TechnicalElementType.rosette:
                                techElements[i].startTemporary();
                                techElements[i].floorHeight -= this._modifiedRoom.floorHeight - this._currentRoom.floorHeight;
                                this._subCommands.push(new EditTechnicalElementCommand(techElements[i]));
                                break;
                        }
                    }
                }
            }
        }
    }

    // Command name returned from a global enum
    name(): string {
        return CommandEnum.EditRoomPropertiesCommand;
    }

    execDatas(): any {
        return {
            id: this._currentRoom.id,
            type: this._currentRoom.entityType
        };
    }

    // Echange room parameters between modified and original room entities
    exchangeParameters() {
        let tmpHeight = this._currentRoom.height;
        this._currentRoom.height = this._modifiedRoom.height;
        this._modifiedRoom.height = tmpHeight;

        let tmpFloorHeight = this._currentRoom.floorHeight;
        this._currentRoom.floorHeight = this._modifiedRoom.floorHeight;
        this._modifiedRoom.floorHeight = tmpFloorHeight;

        let tmpPlinths = this._currentRoom.hasPlinths;
        this._currentRoom.hasPlinths = this._modifiedRoom.hasPlinths;
        this._modifiedRoom.hasPlinths = tmpPlinths;

        let tmpPlinthsHeight = this._currentRoom.plinthsHeight;
        this._currentRoom.plinthsHeight = this._modifiedRoom.plinthsHeight;
        this._modifiedRoom.plinthsHeight = tmpPlinthsHeight;

        let tmpPlinthsMaterialType = this._currentRoom.plinthsMaterialType;
        this._currentRoom.plinthsMaterialType = this._modifiedRoom.plinthsMaterialType;
        this._modifiedRoom.plinthsMaterialType = tmpPlinthsMaterialType;

        let tmpCorniceTypeId1;
        if (this._modifiedRoom.hasCornices) {
            let comp = this._modifiedRoom.getComponent(ComponentConstants.ComponentType.CorniceType);
            if (comp) {
                tmpCorniceTypeId1 = (comp as CorniceType).corniceTypeId;
            }
        }
        var tmpCorniceTypeId2;
        if (this._currentRoom.hasCornices) {
            let comp = this._currentRoom.getComponent(ComponentConstants.ComponentType.CorniceType);
            if (comp) {
                tmpCorniceTypeId2 = (comp as CorniceType).corniceTypeId;
            }
        }

        let tmpCornices = this._currentRoom.hasCornices;
        this._currentRoom.hasCornices = this._modifiedRoom.hasCornices;
        this._modifiedRoom.hasCornices = tmpCornices;

        if (tmpCorniceTypeId1) {
            let comp = this._currentRoom.getComponent(ComponentConstants.ComponentType.CorniceType);
            if (comp) {
                (comp as CorniceType).corniceTypeId = tmpCorniceTypeId1;
            }
        }
        if (tmpCorniceTypeId2) {
            let comp = this._modifiedRoom.getComponent(ComponentConstants.ComponentType.CorniceType);
            if (comp) {
                (comp as CorniceType).corniceTypeId = tmpCorniceTypeId2;
            }
        }
    }

    // Undo the current command
    undo() {
        this.exchangeParameters();

        // Undo all wall properties commands created by the constructor
        for (let i = 0; i < this._subCommands.length; i++) {
            this._subCommands[i].undo();
        }

        eventsManager.instance.dispatch(Events.CHANGE_EDITOR_SELECTION, {
            selection: [this._currentRoom],
            keepSelected: false,
            showTulip: false,
        });

        eventsManager.instance.dispatch(Events.PROJECT_DID_LOAD);
    }

    // Execute current command (redo)
    execute() {
        this.exchangeParameters();

        // Execute all wall properties command created by the constrcutor to update all walls
        for (let i = 0; i < this._subCommands.length; i++) {
            this._subCommands[i].execute();
        }

        // Execute parent function
        super.execute();

        eventsManager.instance.dispatch(Events.CHANGE_EDITOR_SELECTION, {
            selection: [this._currentRoom],
            keepSelected: false,
            showTulip: false,
        });

        eventsManager.instance.dispatch(Events.PROJECT_DID_LOAD);
    }
}
