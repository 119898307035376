import { ComponentConstants } from '../SavaneJS';
import { Component } from './Component';

export class SmartDesignerSolution extends Component {
    private allTemplates: any;
    private currentTemplateId: any;
    private maxSolution: any;
    private currentSolution: any;
    private datas: any;

    constructor(){
        super();
    }

    setCurrentTemplate(allTemplates: any, currentTemplateId: any) {
        this.allTemplates       = allTemplates;
        this.currentTemplateId  = currentTemplateId;
    }

    setSolutions(currentSolution: any, maxSolution: any) {
        this.maxSolution      = maxSolution;
        this.currentSolution  = currentSolution;
    }

    setDatas(datas: any) {
        this.datas = datas;
    }

    get componentType(): ComponentConstants.ComponentType {
        return ComponentConstants.ComponentType.SmartDesignerSolution;
    }

    get smartDatas(): any {
        return this.datas;
    }

    clone(): SmartDesignerSolution {
        let smartSolution               = new SmartDesignerSolution();
        smartSolution.allTemplates      = this.allTemplates;
        smartSolution.currentTemplateId = this.currentTemplateId;
        smartSolution.maxSolution       = this.maxSolution;
        smartSolution.currentSolution   = this.currentSolution;
        smartSolution.datas             = this.datas;
        return smartSolution;
    }
}
