import { ComponentConstants, Entity } from "../SavaneJS";

export class Component {
    accessor componentType: ComponentConstants.ComponentType;

    private _id: number | null;
    protected _entity: Entity | null;
    protected _isUnique: boolean;

    constructor() {
        if (this.constructor === Component) {
            throw new TypeError('Abstract class "Component" cannot be instantiated directly.');
        }

        if (this.componentType === undefined) {
            throw new TypeError("Classes extending the component abstract class require the componentType property");
        }

        this._id = null;
        this._entity = null;
        this._isUnique = false;
    }

    get name(): string {
        return "Inconnu";
    }

    get id(): number | null {
        return this._id;
    }

    set id(i: number | null) {
        this._id = i;
    }

    get isUnique(): boolean {
        return this._isUnique;
    }

    get entity(): Entity {
        return this._entity;
    }

    set entity(e: Entity) {
        this._entity = e;
    }

    clone() : Component {
        throw new TypeError("Not implemented - Abstract class");
    }
}
