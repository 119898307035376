import { Wall, Joinery, joineryManager, math, Transform, SceneConstants, Floor } from '../../SavaneJS';
import { TemporaryEntity } from './TemporaryEntity';

/**
 * TemporaryJoinery is used for joinery edition
 *
 * @constructor
 */
/**
 * The goal here is to save a joinery in a temporary element when it is edited, make changes on this temporary model and not on the real entityType
 * and potentially save back the changes at the end
 */

export class TemporaryJoinery extends TemporaryEntity {
    public position: math.vec3;
    public length: number;
    public height: number;
    public floorHeight: number;
    public scalable: boolean;
    public freeColor: boolean;
    public materialType: number;
    public orientation: boolean;
    public wall: Wall;
    public transom: boolean;
    public transomHeight: number;
    public bottomTransom: boolean;
    public bottomTransomHeight: number;

    public nbDoors: number;
    public openingMode: SceneConstants.OpeningMode;
    public isOpened: boolean;
    public leftOpeningAngle: number;
    public rightOpeningAngle: number;

    public nbCasement: number;
    public handleSide: SceneConstants.HandleSide;
    public thickness: number;
    public wallInstallType: SceneConstants.WallInstallType;

    public frosted: boolean;
    public cutSlope: boolean;

    private _transform: Transform;

    // Constructor, save all fields of the joinery into the temporary object
    constructor(joinery: Joinery) {
        super(joinery);
        // Clone joinery position
        this.position = math.vec3.clone(joinery.position);

        // Save joinery parameters (length, height and floorHeight)
        this.length = joinery.length;
        this.height = joinery.height;
        this.floorHeight = joinery.floorHeight;
        this.scalable = joinery.scalable;
        this.freeColor = joinery.freeColor;

        // Save material type of the joinery
        this.materialType = joinery.materialType;

        // Orientation is a boolean. The real orientation depends of this boolean and the sens of the wallthis
        this.orientation = joinery.orientation;
        // Save the wall holding the joinery
        this.wall = joinery.wall;

        // Save transom (imposte) parameter
        this.transom = joinery.transom;
        this.transomHeight = joinery.transomHeight;
        this.bottomTransom = joinery.bottomTransom;
        this.bottomTransomHeight = joinery.bottomTransomHeight;

        // If the joinery has a number of doors (for instance Door can be 1 or 2 doors) save the parameter
        if ((joinery as any).nbDoors !== undefined) {
            this.nbDoors = (joinery as any).nbDoors;
        }

        // If the joinery has an opening mode (slide for instance) save it
        if ((joinery as any).openingMode !== undefined) {
            this.openingMode = (joinery as any).openingMode;
        }

        // If the joinery can be opened (like doors) save the parameter
        if ((joinery as any).isOpened !== undefined) {
            this.isOpened = (joinery as any).isOpened;
        }

        if ((joinery as any).leftOpeningAngle !== undefined) {
            this.leftOpeningAngle = (joinery as any).leftOpeningAngle;
        }

        if ((joinery as any).rightOpeningAngle !== undefined) {
            this.rightOpeningAngle = (joinery as any).rightOpeningAngle;
        }

        // If the joinery has casements (battants) like windows save the value
        if ((joinery as any).nbCasement !== undefined) {
            this.nbCasement = (joinery as any).nbCasement;
        }

        if ((joinery as any).handleSide !== undefined) {
            this.handleSide = (joinery as any).handleSide;
        }

        if ((joinery as any).thickness !== undefined) {
            this.thickness = (joinery as any).thickness;
        }

        if (joinery.wallInstallType !== undefined) {
            this.wallInstallType = joinery.wallInstallType;
        }

        if (joinery.frosted !== undefined) {
            this.frosted = joinery.frosted;
        }

        if (joinery.cutSlope !== undefined) {
            this.cutSlope = joinery.cutSlope;
        }

        // Save the entity transformation too
        this._transform = new Transform(null);
        this._transform.clone(joinery.transform);
    }

    get transform() {
        return this._transform;
    }

    computeValidity(floor: Floor) {
        this._isValid = joineryManager.isJoineryValid(this.entity as Joinery);
    }
}
