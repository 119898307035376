import { Wall } from '../model/Wall';
import { glMatrix } from '../../../math/Math';
import { Photo2World } from '../Photo2World';

export class WallFactory {

    private _pixels: Array<glMatrix.vec2> = new Array<glMatrix.vec2>();
    private _photo2world: Photo2World;

    constructor(photo2world: Photo2World) {
        this._photo2world = photo2world;
    }

    set Pixels(value: Array<glMatrix.vec2>) {
        this._pixels = value;
    }

    Build() : void {
        this._photo2world.Model.Walls = [];

        if (this._pixels.length < 2) {
            return;
        }

        //Build walls from given pixels
        var A = this._photo2world.ProjectOnGround(this._pixels[0]);
        if (A === null) {
            return;
        }

        for (var i = 1; i < this._pixels.length; ++i) {
            var B = this._photo2world.ProjectOnGround(this._pixels[i]);
            if (B === null) {
                return;
            }

            this._photo2world.Model.Walls.push(new Wall(A, B, this._photo2world.WallHeight, this._photo2world.Thickness));
            A = glMatrix.vec3.clone(B);
        }
    }

    CloseRoom(walls: Array<Wall>) : void {
        //Extend first and last wall according to the camera position
        var firstWall = walls[0];
        var pfw = firstWall.Project(this._photo2world.CameraPosition);
        if (pfw === null) {
            return;
        }

        var APfw = glMatrix.vec3.create();
        glMatrix.vec3.subtract(APfw, firstWall.A, pfw);
        var BPfw = glMatrix.vec3.create();
        glMatrix.vec3.subtract(BPfw, firstWall.B, pfw);

        var build2walls = false;
        var A = glMatrix.vec3.clone(firstWall.A);
        if (glMatrix.vec3.length(BPfw) < glMatrix.vec3.length(APfw)) {
            pfw = glMatrix.vec3.clone(A);
            build2walls = true;
        }
        var dfw = firstWall.NegateD;
        glMatrix.vec3.set(A, pfw[0] + dfw[0] * 1000, pfw[1] + dfw[1] * 1000, 0);

        var lastWall = walls[walls.length - 1];
        var plw = lastWall.Project(this._photo2world.CameraPosition);
        if (plw === null) {
            return;
        }
        
        var B = glMatrix.vec3.clone(lastWall.B);
        var APlw = glMatrix.vec3.create();
        glMatrix.vec3.subtract(APlw, lastWall.A, plw);
        var BPlw = glMatrix.vec3.create();
        glMatrix.vec3.subtract(BPlw, lastWall.B, plw);

        if (glMatrix.vec3.length(BPlw) > glMatrix.vec3.length(APlw)) {
            plw = glMatrix.vec3.clone(B);
            build2walls = true;
        }
        var dlw = lastWall.D;
        glMatrix.vec3.set(B, plw[0] + dlw[0] * 1000, plw[1] + dlw[1] * 1000, 0);

        //close the room by connecting the last and first wall
        var wall = new Wall(B, A, this._photo2world.WallHeight, this._photo2world.Thickness);

        //Project the camera on the wall
        if (!build2walls && wall.Project(this._photo2world.CameraPosition) !== null) {
            //The camera is inside the room, just close it
            walls.push(wall);
        } else {
            //The camera is outside the room, we need to build two walls
            var backWall = new Wall(B, glMatrix.vec3.fromValues(B[0] + dfw[0] * 1000, B[1] + dfw[1] * 1000, 0), this._photo2world.WallHeight, this._photo2world.Thickness);
            var pbw = backWall.Project(A);
            if (!pbw) { //just connect the fisrt and last wall
                pbw = lastWall.Project(firstWall.A, true);
                lastWall.B = glMatrix.vec3.clone(pbw);
                firstWall.A = glMatrix.vec3.clone(pbw);
            } else {
                glMatrix.vec3.set(backWall.B, pbw[0], pbw[1], 0);
                walls.push(backWall);
                walls.push(new Wall(glMatrix.vec3.clone(backWall.B), glMatrix.vec3.clone(A), this._photo2world.WallHeight, this._photo2world.Thickness));
            }
        }
        //update the first and last wall position
        firstWall.A = glMatrix.vec3.clone(A);
        lastWall.B = glMatrix.vec3.clone(B);
    }

}
