import { Command, CommandEnum } from './CommandModule';
import { Events } from '../events';
import { eventsManager } from '../managers/EventsManager';
import { EntityFactory, Floor, Wall, Room } from '../SavaneJS';

export class SetFloorHeightCommand extends Command
{
    private _height: number;
    private _floor: Floor;
    // To store cloned walls (roomed and non roomed)
    private _clonedWalls: Array<Wall> = [];
    private _clonedNonRoomedWalls: Array<Wall> = [];
    private _clonedRooms: Array<Room> = [];

    constructor(height: number, floor: Floor) {
        super();
        // Activate of not value
        this._height = height;
        this._floor = floor;

        // Array to store cloned elements
        this._clonedRooms = [];
        let i: number;
        // Parse all floor walls
        for (i = 0; i < floor.walls.length; i++) {
            // Clone current wall for undo
            this._clonedWalls.push(EntityFactory.cloneEntity(floor.walls[i], false) as Wall);
        }
        for (i = 0; i < floor.nonRoomedWalls.length; i++) {
            // Clone current wall for undo
            this._clonedNonRoomedWalls.push(EntityFactory.cloneEntity(floor.nonRoomedWalls[i], false) as Wall);
        }
        // Parse all rooms of the floor
        for (i = 0; i < floor.rooms.length; i++) {
            // Clone the room for undo
            this._clonedRooms.push(EntityFactory.cloneEntity(floor.rooms[i], false) as Room);
        }
    }

    // Command name returned from a global enum
    name(): string {
        return CommandEnum.SetFloorHeightCommand;
    }

    // Datas that allows the command to be executed
    execDatas(): any {
        return {
            height: this._height
        };
    }

    // Undo the current command
    undo() {
        let i: number;
        // Get all floor walls and rooms
        let walls = this._floor.walls;
        let nonRoomedWalls = this._floor.nonRoomedWalls;
        let rooms = this._floor.rooms;

        // Restore old wall height values
        for (i = 0; i < walls.length; i++) {
            walls[i].height = this._clonedWalls[i].height;
        }
        for (i = 0; i < nonRoomedWalls.length; i++) {
            nonRoomedWalls[i].height = this._clonedNonRoomedWalls[i].height;
        }

        // Restore all room height values
        for (i = 0; i < rooms.length; i++) {
            rooms[i].height = this._clonedRooms[i].height;

            // Redraw Node
            eventsManager.instance.dispatch(Events.REDRAW_GRAPHICAL_ENTITY,
                {
                    entity: rooms[i],
                    extraActions: ['updateName'],
                });
        }

        this._floor.readjustFloorsHeight();

        super.undo();
    }

    canExecute(): boolean {
        let i: number, j: number;
        // Get all floor walls and rooms
        let walls = this._floor.walls;
        let nonRoomedWalls = this._floor.nonRoomedWalls;

        // Verify that no joinery conflicts the new room height
        for (i = 0; i < walls.length; i++) {
            let joineries = walls[i].joineries;

            for (j = 0; j < joineries.length; j++) {
                // Conflict, don't execute the room height change
                if (joineries[j].floorHeight + joineries[j].height > this._height)
                    return (false);
            }
        }
        for (i = 0; i < nonRoomedWalls.length; i++) {
            let joineries = nonRoomedWalls[i].joineries;

            for (j = 0; j < joineries.length; j++) {
                // Conflict, don't execute the room height change
                if (joineries[j].floorHeight + joineries[j].height > this._height)
                    return (false);
            }
        }

        return (true);
    }

    // Execute current command (redo), call the applyTranslation method with the translation values initialised at start
    execute() {
        let i: number;
        // Get all floor walls and rooms
        let walls = this._floor.walls;
        let nonRoomedWalls = this._floor.nonRoomedWalls;
        let rooms = this._floor.rooms;

        // Can't execute for some reason (joinery too high) don't do that
        if (!this.canExecute())
            return;

        // Parse all floor walls
        for (i = 0; i < walls.length; i++) {
            // Apply only to walls that are taller than 105cm
            if (walls[i].height >= 1050) {
                walls[i].height = this._height;
            }
        }
        for (i = 0; i < nonRoomedWalls.length; i++) {
            // Apply only to walls that are taller than 105cm
            if (nonRoomedWalls[i].height >= 1050) {
                nonRoomedWalls[i].height = this._height;
            }
        }

        // Parse all rooms of the floor
        for (i = 0; i < rooms.length; i++) {
            // Set room height
            rooms[i].height = this._height;

            // Redraw Node
            eventsManager.instance.dispatch(Events.REDRAW_GRAPHICAL_ENTITY,
                {
                    entity: rooms[i],
                    extraActions: ['updateName'],
                });
        }

        this._floor.readjustFloorsHeight();

        super.execute();
    }
};
