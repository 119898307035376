import { ComponentConstants, SceneConstants, SavaneConstants, math, SavaneMath, Segment } from "../SavaneJS";
import { Component } from "./Component";

/* Area used for various things (CoatingArea, FloorCoatingArea, Add and CutArea, ) */
export class Area extends Component {
    protected _vertices: Array<math.vec3>;

    constructor() {
        super();
        this._vertices = [];
    }

    get componentType(): ComponentConstants.ComponentType {
        return ComponentConstants.ComponentType.Area;
    }

    get vertices(): Array<math.vec3> {
        return this._vertices;
    }

    set vertices(v: Array<math.vec3>) {
        this._vertices = v;
    }

    get needReverse(): boolean {
        let areaM = 0;
        for (let i = 0; i < this._vertices.length; ++i) {
            areaM = areaM + (this._vertices[i][0] + this._vertices[(i + 1) % this._vertices.length][0]) * (this._vertices[i][1] - this._vertices[(i + 1) % this._vertices.length][1]);
        }
        return areaM > 0;
    }

    clone(): Area {
        let vertices: Array<math.vec3> = new Array<math.vec3>();
        for (let i = 0; i < this.vertices.length; i++) {
            vertices.push(math.vec3.clone(this.vertices[i]));
        }
        let cloneArea = new Area();
        cloneArea.vertices = vertices;
        return cloneArea;
    }

    isInArea(p: math.vec3): boolean {
        return SavaneMath.isInPoly(p, this._vertices);
    }
    
    superSmooth() {
        this.mergeVertices();
        this.smooth();
    }

    smooth() {
        let angle = 0;
        for (let i = 0; i < this.vertices.length; i++) {
            angle = SavaneMath.anglePoints(this.vertices[(i + 1) % this.vertices.length], this.vertices[i], this.vertices[(i + 2) % this.vertices.length], true);
            if (Math.abs(angle - Math.PI) < SavaneConstants.Tolerance) {
                this.vertices.splice((i + 1) % this.vertices.length, 1);
                i--;
            }
        }
    }

    mergeVertices() {
        for (let i = 0; i < this.vertices.length; i++) {
            let a = this.vertices[(i - 1 + this.vertices.length) % this.vertices.length];
            let b = this.vertices[i];
            let distance = math.vec3.distance(a, b);
            if (distance < SceneConstants.MinWorktopverticesDistance) {
                this.vertices.splice((i - 1) % this.vertices.length, 1);
                i--;
            }
        }

        for (let i = 0; i < this.vertices.length; i++) {
            let a = this.vertices[(i - 1 + this.vertices.length) % this.vertices.length];
            let b = this.vertices[i];
            let c = this.vertices[(i + 1 + this.vertices.length) % this.vertices.length];
            let distance1 = math.vec3.distance(a, b);
            let distance2 = math.vec3.distance(b, c);
            if (distance1 < SceneConstants.MinWorktopverticesDistance * 6.1 && distance2 < SceneConstants.MinWorktopverticesDistance * 6.1) {
                let point1 = this.vertices[(i - 2 + this.vertices.length) % this.vertices.length];
                let point2 = this.vertices[(i - 1 + this.vertices.length) % this.vertices.length];
                let point3 = this.vertices[(i + 1 + this.vertices.length) % this.vertices.length];
                let point4 = this.vertices[(i + 2 + this.vertices.length) % this.vertices.length];

                let newPoint = SavaneMath.getCrossPoint(point1, point2, point3, point4, 0);

                if (newPoint !== null) {
                    this.vertices[i] = newPoint;
                }
            }
        }
    }

    cleanColinearVertices() {
        for (let i = 0; i < this.vertices.length; i++) {
            let a = this.vertices[(i - 1 + this.vertices.length) % this.vertices.length];
            let b = this.vertices[i];
            let c = this.vertices[(i + 1) % this.vertices.length];
            let s1 = new Segment(a, b);
            let s2 = new Segment(b, c);
            if (Segment.areColinear(s1, s2, SavaneConstants.ToleranceCollinear)) {
                this.vertices.splice(i, 1);
                i--;
            }
        }
    }

    get aabb(): any {
        let result = {
            min: math.vec3.fromValues(Number.POSITIVE_INFINITY, Number.POSITIVE_INFINITY, Number.POSITIVE_INFINITY),
            max: math.vec3.fromValues(Number.NEGATIVE_INFINITY, Number.NEGATIVE_INFINITY, Number.NEGATIVE_INFINITY),
        }
        for (let i = 0; i < this._vertices.length; ++i) {
            result.min[0] = Math.min(result.min[0], this._vertices[i][0]);
            result.min[1] = Math.min(result.min[1], this._vertices[i][1]);
            result.min[2] = Math.min(result.min[2], this._vertices[i][2]);

            result.max[0] = Math.max(result.max[0], this._vertices[i][0]);
            result.max[1] = Math.max(result.max[1], this._vertices[i][1]);
            result.max[2] = Math.max(result.max[2], this._vertices[i][2]);
        }
        return result;
    }
}
