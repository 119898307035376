import {ComponentConstants} from '../SavaneJS';
import {Component} from './Component';

export class RoomQuotation extends Component {
    public floorCovering: number;
    public ceilingRepair: boolean;
    public ceilingCovering: string;
    public kitchenSize: number;

    constructor() {
        super();
        this.floorCovering = 0;
        this.ceilingRepair = true;
        this.ceilingCovering = "5";
        this.kitchenSize = 0;
        this._isUnique = true;

    }

    get componentType(): ComponentConstants.ComponentType {
        return ComponentConstants.ComponentType.RoomQuotation;
    }

    clone(): RoomQuotation {
        let roomQuotation = new RoomQuotation();
        roomQuotation.floorCovering = this.floorCovering;
        roomQuotation.ceilingRepair = this.ceilingRepair;
        roomQuotation.ceilingCovering = this.ceilingCovering;
        roomQuotation.kitchenSize = this.kitchenSize;
        return roomQuotation;
    }
}
