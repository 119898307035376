import { HttpRequest } from "./HttpRequest";

export class HttpService {
    constructor() {
    }

    static process(httpRequest: HttpRequest, onSuccess: CallableFunction, onError: CallableFunction, userData: any) {
        var request = httpRequest.webRequest;
        request.onreadystatechange = function (){
            if (request.readyState === 4 && request.status >= 200 && request.status < 300) {
                onSuccess(request, userData);
            } else if (request.readyState === 4) {
                onError(Error(request.statusText), request, userData);
            }
        };

        httpRequest.send();
    }

    static get(url: string) {
        return new Promise<{status: number, data: any}>(function (resolve, reject){
            var req = new XMLHttpRequest();

            req.onreadystatechange = function (){
                if (this.readyState === 4) {
                    if (this.status < 300) {
                        resolve({
                            status: this.status,
                            data: this.responseText.length > 0 ? JSON.parse(this.responseText) : {}
                        });
                    } else {
                        reject({
                            status: this.status,
                            data: this.responseText.length > 0 ? {error: this.responseText} : {}
                        });
                    }
                }
            };

            req.open('GET', url, true);
            req.send();
        });
    }

    static post(url: string, data: any) {
        return new Promise<{status: number, data: any}>(function (resolve, reject){
            var req = new XMLHttpRequest();

            req.onreadystatechange = function (){
                if (this.readyState === 4) {
                    if (this.status < 300) {
                        try{
                            var response ;
                            if(this.responseText != undefined && this.responseText != null && this.responseText.length > 0)
                            {
                                response = JSON.parse(this.responseText);
                            } 
                            resolve({
                                status: this.status,
                                data: response
                            });
                        }                        
                        catch(e)
                        {
                            resolve({
                                status: 500,
                                data: e.message
                            });
                        }
                    } else {
                        reject({
                            status: this.status,
                            data: JSON.parse(this.responseText)
                        });
                    }
                }
            };
            req.open('POST', url);
            req.setRequestHeader('Content-Type', 'application/json');
            req.send(JSON.stringify(data));
        });
    }

    static put(url: string, data: any) {
        return new Promise<{status: number, data: any}>(function (resolve, reject){
            var req = new XMLHttpRequest();

            req.onreadystatechange = function (){
                if (this.readyState === 4) {
                    if (this.status < 300) {
                        resolve({
                            status: this.status,
                            data: JSON.parse(this.responseText)
                        });
                    } else {
                        reject({
                            status: this.status,
                            data: JSON.parse(this.responseText)
                        });
                    }
                }
            };
            req.open('PUT', url, true);
            req.send(data);
        });
    }

    static patch(url: string, data: any) {
        return new Promise<{status: number, data: any}>(function (resolve, reject){
            var req = new XMLHttpRequest();

            req.onreadystatechange = function (){
                if (this.readyState === 4) {
                    if (this.status < 300) {
                        resolve({
                            status: this.status,
                            data: JSON.parse(this.responseText)
                        });
                    } else {
                        reject({
                            status: this.status,
                            data: JSON.parse(this.responseText)
                        });
                    }
                }
            };
            req.open('PATCH', url);
            req.setRequestHeader('Content-Type', 'application/json');
            req.send(JSON.stringify(data));
        });
    }

    static delete(url: string) {
        return new Promise<{status: number, data: any}>(function (resolve, reject){
            var req = new XMLHttpRequest();

            req.onreadystatechange = function (){
                if (this.readyState === 4) {
                    if (this.status < 300) {
                        resolve({
                            status: this.status,
                            data: this.responseText.length > 0 ? JSON.parse(this.responseText) : {}
                        });
                    } else {
                        reject({
                            status: this.status,
                            data: this.responseText.length > 0 ? {error: this.responseText} : {}
                        });
                    }
                }
            };

            req.open('DELETE', url, true);
            req.send();
        });
    }
}
