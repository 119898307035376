import { glMatrix, Line, Ray, Plane } from "../../../math/Math";

export class Wall {

    protected _type: string = "WALL";
    protected _invert: boolean;
    protected _thickness: number;

    private _A: glMatrix.vec3;
    private _B: glMatrix.vec3;
    private _height: number;
    private _savaneId: number;
    private _plane: Plane;

    constructor(A: glMatrix.vec3, B: glMatrix.vec3, height: number, thickness: number) {
        this._A = A;
        this._B = B;
        this._height = height;
        this._thickness = thickness;

        this._savaneId = null;

        this._computePlane();
    }

    _computePlane() : void {
        var C = glMatrix.vec3.clone(this._A);
        C[2] = this._height;

        var AB = glMatrix.vec3.create();
        glMatrix.vec3.subtract(AB, this._B, this._A);
        glMatrix.vec3.normalize(AB, AB);

        var AC = glMatrix.vec3.create();
        glMatrix.vec3.subtract(AC, C, this._A);
        glMatrix.vec3.normalize(AC, AC);

        var N = glMatrix.vec3.create();
        glMatrix.vec3.cross(N, AB, AC);
        glMatrix.vec3.normalize(N, N);
        if (this._invert) {
            glMatrix.vec3.negate(N, N);
        }

        this._plane = new Plane(this._A, N);
    }

    get Type() : string {
        return this._type;
    }

    get A() : glMatrix.vec3 {
        return this._A;
    }

    get MiddleA() : glMatrix.vec3 {
        var MA = glMatrix.vec3.clone(this.A);
        glMatrix.vec3.subtract(MA, MA, glMatrix.vec3.fromValues(this.N[0] * this.AsoluteThickness / 2, this.N[1] * this.AsoluteThickness / 2, 0));
        return MA;
    }

    set A(value: glMatrix.vec3) {
        this._A = value;
        this._computePlane();
    }

    get B() : glMatrix.vec3 {
        return this._B;
    }

    get MiddleB() : glMatrix.vec3 {
        var MB = glMatrix.vec3.clone(this.B);
        glMatrix.vec3.subtract(MB, MB, glMatrix.vec3.fromValues(this.N[0] * this.AsoluteThickness / 2, this.N[1] * this.AsoluteThickness / 2, 0));
        return MB;
    }

    set B(value: glMatrix.vec3) {
        this._B = value;
        this._computePlane();
    }

    get Height() : number {
        return this._height;
    }

    get Thickness() : number {
        return this._thickness;
    }

    set Thickness(value: number) {
        this._thickness = value;
    }

    get AsoluteThickness() : number {
        return Math.abs(this._thickness);
    }

    get N() : glMatrix.vec3 {
        return this._plane.N;
    }

    get NegateN() : glMatrix.vec3 {
        var N = glMatrix.vec3.create();
        glMatrix.vec3.negate(N, this.N);
        return N;
    }

    get D() : glMatrix.vec3 {
        var D = glMatrix.vec3.create();
        glMatrix.vec3.subtract(D, this._B, this._A);
        glMatrix.vec3.normalize(D, D);
        return D;
    }

    get NegateD(): glMatrix.vec3 {
        var D = glMatrix.vec3.create();
        glMatrix.vec3.subtract(D, this._A, this._B);
        glMatrix.vec3.normalize(D, D);
        return D;
    }

    get Length() : number {
        var D = glMatrix.vec3.create();
        glMatrix.vec3.subtract(D, this._A, this._B);
        return glMatrix.vec3.length(D);   
    }

    get SavaneId() : number {
        return this._savaneId;
    }

    set SavaneId(value: number) {
        this._savaneId = value;
    }

    get Plane() : Plane {
        return this._plane;
    }

    //return the intersection if the intersection projected on the ground lies between A and B
    Intersect(ray: Ray, dualside: boolean, backface: boolean, infinite: boolean) : glMatrix.vec3 | null {
        var result = this._plane.Intersect(ray, dualside, backface);
        if (!result) {
            return null;
        }

        if (!infinite) {
            if (result[2] < 0 || result[2] > this._height) {
                return null;
            }
            //create segment 1mm larger to fix precision problems
            var segment = new Line(glMatrix.vec3.fromValues(this.A[0] + this.NegateD[0], this.A[1] + this.NegateD[1], 0), glMatrix.vec3.fromValues(this.B[0] + this.D[0], this.B[1] + this.D[1], 0));
            if (segment.BelongToSegment(glMatrix.vec3.fromValues(result[0], result[1], 0)) === false) {
                return null;
            }
        }
        
        return result;
    }

    //return the orthogonal projection
    Project(position: glMatrix.vec3, dualside?: boolean) : glMatrix.vec3 | null {
        return this._plane.Project(position, dualside);
    }

    ClampToBorder(position: glMatrix.vec3) : glMatrix.vec3 | null {
        if (!position) {
            return null;
        }
        
        var result = glMatrix.vec3.clone(position);
        var segment = new Line(glMatrix.vec3.fromValues(this.A[0], this.A[1], 0), glMatrix.vec3.fromValues(this.B[0], this.B[1], 0));
        if (segment.BelongToSegment(position) === false) {
            if (glMatrix.vec3.distance(position, this.A) < glMatrix.vec3.distance(position, this.B)) {
                result[0] = this.A[0];
                result[1] = this.A[1];
            } else {
                result[0] = this.B[0];
                result[1] = this.B[1];
            }
        }
        return result;
    }

}
