export class SavaneConstants {
    constructor() {
    }
}

export namespace SavaneConstants {
    export const Tolerance = 0.001;
    export const PositionTolerance = 1;
    export const ToleranceCollinear = 0.05;
    export const ToleranceCollinearLight = 0.01;
    export const MagnetDistance = 100.0;
    export const RoomMagnetDistance = 150.0;
    export const uncomputedWall = -1;
    export const minFloorHeightBottom = 150;

    /**
     * Version number of JSONFormat
     * Should be incremented when the JSON format change and the parsing methods should be modified
     *
     * @type {number}
     */
    export const formatVersion = 2.1;
}
