import { glMatrix, Line, Ray, Functions } from '../../math/Math';
import { Converters } from './Converters';
import { roomManager } from '../../managers/RoomManager';
import async from 'async';
import * as Savane from '../../SavaneJS';
import { Photo2World } from '../photo2World/Photo2World';
import { Wall } from '../photo2World/model/Wall';
import { Joinery } from '../photo2World/model/Joinery';
import { Partition } from '../photo2World/model/Partition';

declare var AssetManagerServices;

export class World2Savane {

    private _scene: Savane.Scene = Savane.EntityFactory.createScene();
    private _photo: number;

    public camera: Savane.RenderCamera;

    constructor(photo: number) {
        this._photo = photo;
    }

    extractCamera(photo2world: Photo2World, world: Savane.World) : Savane.RenderCamera {
        this._buildCamera(photo2world, world);
        return this.camera;
    }

    _buildCamera(photo2world: Photo2World, world: Savane.World) : void {
        this.camera = Savane.EntityFactory.createEmptyRenderCamera(-1);
        var cameraName = "photo2savane_";
        cameraName = cameraName.concat(this.camera.id.toString());
        if (this._photo !== undefined) {
            cameraName = cameraName.concat("_photo_", this._photo.toString());
        }

        var renderWidth = photo2world.Width;
        var renderheight = photo2world.Height;
        var aspect = renderWidth / renderheight;

        this.camera.name = cameraName;
        this.camera.fov = photo2world.FovY;
        this.camera.verticalShift = photo2world.VerticalShift;


        var X = glMatrix.vec3.fromValues(photo2world.Camera2World[0], photo2world.Camera2World[1], photo2world.Camera2World[2]);
        var Y = glMatrix.vec3.fromValues(photo2world.Camera2World[4], photo2world.Camera2World[5], photo2world.Camera2World[6]);
        var Z = glMatrix.vec3.fromValues(photo2world.Camera2World[8], photo2world.Camera2World[9], photo2world.Camera2World[10]);


        glMatrix.vec3.cross(X, Y, Z);
        glMatrix.vec3.cross(Z, X, Y);
        glMatrix.vec3.cross(Y, Z, X);

        var transform = glMatrix.mat4.create();
        glMatrix.mat4.fromRotation(transform, -Math.PI / 2, X);
        glMatrix.vec3.transformMat4(Y, Y, transform);
        glMatrix.vec3.transformMat4(Z, Z, transform);

        glMatrix.mat4.set(this.camera.transform.localMatrix,
            X[0], X[1], X[2], 0,
            Y[0], Y[1], Y[2], 0,
            Z[0], Z[1], Z[2], 0,
            photo2world.Camera2World[12], photo2world.Camera2World[13], photo2world.Camera2World[14], 1);

        this.camera.projection = photo2world.Camera2Image;
        this.camera.cameraType = Savane.SceneConstants.CameraType.PhotoRender;
        renderheight = 2400;
        renderWidth  = renderheight * aspect;
        this.camera.renderWidth  = renderWidth;
        this.camera.renderHeight = renderheight;

        // Setup an HD camera
        this.camera.hd = true;

        this.camera.updateCameraNb(world);
        this._scene.addEntity(this.camera);
    }

    _buildWalls(photo2world: Photo2World) : void {
        //Build Walls
        var walls = [];
        for (var i = 0; i < photo2world.Model.Walls.length; ++i) {
            let current = photo2world.Model.Walls[i];

            //push walls with half thickness along the normal
            var A = glMatrix.vec3.clone(current.MiddleA);
            var B = glMatrix.vec3.clone(current.MiddleB);
            walls.push(new Line(A, B));
        }

        //compute the new begin/end of each walls by intersecting the previous and current line and the current and next line
        for (var i = 0; i < walls.length; ++i) {
            var worldWall = photo2world.Model.Walls[i];

            let current = walls[i];
            let previous = walls[(i - 1 + walls.length) % walls.length];
            var next = walls[(i + 1) % walls.length];

            var A = previous.Intersect(current) as glMatrix.vec3;
            var B = current.Intersect(next) as glMatrix.vec3;

            var savaneWall = Savane.EntityFactory.createWall(A, B);
            savaneWall.height = worldWall.Height;
            savaneWall.thickness = worldWall.Thickness;
            worldWall.SavaneId = savaneWall.id;
            this._scene.addWall(savaneWall, true, worldWall.Height);
        }
    }

    _adjacentWall(photo2world: Photo2World, wall: Wall, P: glMatrix.vec3) : Wall {
        var index = photo2world.Model.Walls.indexOf(wall);
        var previous = photo2world.Model.Walls[(index - 1 + photo2world.Model.Walls.length) % photo2world.Model.Walls.length];
        var next = photo2world.Model.Walls[(index + 1) % photo2world.Model.Walls.length];

        var result = null;
        if (glMatrix.vec3.distance(P, previous.B) < 1) {
            result = previous;
        } else if (glMatrix.vec3.distance(P, next.A) < 1) {
            result = next;
        }

        return result;
    }

    _moveWallPositionWhenPartitionSnappedOnExtreminity(photo2world: Photo2World) : void {
        for (var i = 0; i < photo2world.Model.Partitions.length; ++i) {
            var partition = photo2world.Model.Partitions[i];
            if (!partition.IsOnExtremity) {
                continue;
            }

            if (partition.IsShifted(photo2world)) {
                continue;
            }

            let floor = this._scene.floors[0];
            var savanePartition = floor.getWall(partition.SavaneId);
            if (partition.IsOnWallAExtrimity) {
                var savaneWall = floor.getWall(partition.WallA.SavaneId);
                var adjacentWall = this._adjacentWall(photo2world, partition.WallA, partition.A);
                if (!adjacentWall) {
                    continue;
                }
                var savaneAdjacentWall = floor.getWall(adjacentWall.SavaneId);
                if (glMatrix.vec3.distance(partition.A, partition.WallA.A) < 1) {
                    savaneWall.begin = savanePartition.begin;
                    savaneAdjacentWall.end = savanePartition.begin;
                } else {
                    savaneAdjacentWall.begin = savanePartition.begin;
                    savaneWall.end = savanePartition.begin;
                }
            }

            if (partition.IsOnWallBExtrimity) {
                var savaneWall = floor.getWall(partition.WallB.SavaneId);
                var adjacentWall = this._adjacentWall(photo2world, partition.WallB, partition.B);
                if (!adjacentWall) {
                    continue;
                }
                var savaneAdjacentWall = floor.getWall(adjacentWall.SavaneId);
                if (glMatrix.vec3.distance(partition.B, partition.WallB.A) < 1) {
                    savaneWall.begin = savanePartition.end;
                    savaneAdjacentWall.end = savanePartition.end;
                } else {
                    savaneAdjacentWall.begin = savanePartition.end;
                    savaneWall.end = savanePartition.end;
                }
            }
        }
    }

    _supportRollingShutter(joineryType: Savane.SceneConstants.JoineryType) : boolean {
        switch (joineryType) {
            case Savane.SceneConstants.JoineryType.window:
            case Savane.SceneConstants.JoineryType.frenchWindow:
            case Savane.SceneConstants.JoineryType.pictureWindow:
            case Savane.SceneConstants.JoineryType.canopy:
            case Savane.SceneConstants.JoineryType.fixedWindow:
                return true;
            default:
                return false;
        }
    }

    _setJoineryDatas(worldJoinery: Joinery, savaneJoinery: Savane.Joinery, joineryType: Savane.SceneConstants.JoineryType) : void {
        if (worldJoinery.MaterialType !== undefined) {
            savaneJoinery.materialType = worldJoinery.MaterialType;
        }

        if (worldJoinery.Frosted !== undefined) {
            savaneJoinery.frosted = worldJoinery.Frosted;
        }

        if (worldJoinery.Thickness !== undefined) {
            (savaneJoinery as any).thickness = worldJoinery.Thickness * 10;
        }

        if (worldJoinery.Opened !== undefined) {
            (savaneJoinery as any).isOpened = worldJoinery.Opened;
        }

        if (worldJoinery.Sliding !== undefined) {
            (savaneJoinery as any).openingMode = (worldJoinery.Sliding ? Savane.SceneConstants.OpeningMode.mode_slide : Savane.SceneConstants.OpeningMode.mode_default);
        }

        if (worldJoinery.NbDoors !== undefined) {
            if ((savaneJoinery as any).nbDoors !== undefined) {
                (savaneJoinery as any).nbDoors = worldJoinery.NbDoors;
            } else {
                if ((savaneJoinery as any).nbCasement !== undefined) {
                    (savaneJoinery as any).nbCasement = worldJoinery.NbDoors;
                }
            }
        }

        if (worldJoinery.Transom !== undefined) {
            savaneJoinery.transom = worldJoinery.Transom;
        }

        if (worldJoinery.TransomHeight !== undefined) {
            savaneJoinery.transomHeight = worldJoinery.TransomHeight * 10;
        }

        if (worldJoinery.BottomTransom !== undefined) {
            savaneJoinery.bottomTransom = worldJoinery.BottomTransom;
        }

        if (worldJoinery.BottomTransomHeight !== undefined) {
            savaneJoinery.bottomTransomHeight = worldJoinery.BottomTransomHeight * 10;
        }

        if (worldJoinery.WallInstallType !== undefined) {
            savaneJoinery.wallInstallType = worldJoinery.WallInstallType;
        }

        if (worldJoinery.Model !== undefined) {
            var components = savaneJoinery.getComponents(Savane.ComponentConstants.ComponentType.JoineryType) as Array<Savane.JoineryType>;
            if (components.length > 0) {
                components[0].joineryTypeId = worldJoinery.Model;
            } else {
                savaneJoinery.addComponent(new Savane.JoineryType(worldJoinery.Model, null, null));
            }
        }

        if (worldJoinery.RollingShutter !== undefined && worldJoinery.RollingShutter && this._supportRollingShutter(joineryType) === true) {
            var rollingShutter = Savane.EntityFactory.createTechnicalElement(Savane.SceneConstants.TechnicalElementType.ceilingBox,  worldJoinery.Length, 200, 200, null, '');
            savaneJoinery.addChild(rollingShutter);
            var sign = (savaneJoinery.orientation ? -1 : 1);
            var joineryTopAltitude = worldJoinery.TopHeight;
            if (savaneJoinery.transom) {
                joineryTopAltitude = savaneJoinery.transomHeight;
            }
            rollingShutter.transform.localPosition = glMatrix.vec3.fromValues(0, sign * worldJoinery.Wall.Thickness / 2, joineryTopAltitude);
        }
    }

    _createJoinery(worldJoinery: Joinery, joineryType: Savane.SceneConstants.JoineryType) : Savane.Joinery {
        var center = glMatrix.vec3.clone(worldJoinery.Center);
        let thickness = worldJoinery.WallThicknessOffset;
        let N = worldJoinery.Wall.N;
        glMatrix.vec3.subtract(center, center, glMatrix.vec3.fromValues(N[0] * thickness, N[1] * thickness, 0));

        var savaneJoinery = Savane.EntityFactory.createJoinery(center, joineryType, worldJoinery.Length);
        worldJoinery.SavaneId = savaneJoinery.id;
        savaneJoinery.height = worldJoinery.Height;
        savaneJoinery.floorHeight = worldJoinery.BottomHeight;
        if (worldJoinery.BottomHeight < 10) {
            savaneJoinery.floorHeight = 0;
        }

        this._setJoineryDatas(worldJoinery, savaneJoinery, joineryType);
        var floor = this._scene.floors[0];
        var savaneWall = floor.getWall(worldJoinery.Wall.SavaneId);
        savaneWall.addJoinery(savaneJoinery);
        return savaneJoinery;
    }

    _createDoor(worldJoinery: Joinery, joineryType: Savane.SceneConstants.JoineryType) : void {
        var joinery = this._createJoinery(worldJoinery, joineryType);
        switch (worldJoinery.JoineryType) {
            case 2:
            case 0: //simple door
                (joinery as Savane.Door).nbDoors = 1;
                (joinery as Savane.Door).handleSide = worldJoinery.HandleSide;
            break;
            case 1: //double door
                (joinery as Savane.Door).nbDoors = 2;
                (joinery as Savane.Door).handleSide = Savane.SceneConstants.HandleSide.handle_center;
            break;
        }
    }

    _createPictureWindow(worldJoinery: Joinery, joineryType: Savane.SceneConstants.JoineryType) : void {
        this._createJoinery(worldJoinery, joineryType);
    }

    _createFrenchWindow(worldJoinery: Joinery, joineryType: Savane.SceneConstants.JoineryType) : void {
        var joinery = this._createJoinery(worldJoinery, joineryType);
        switch (worldJoinery.JoineryType) {
            case 6: //simple french window
                (joinery as Savane.FrenchDoor).nbDoors = 1;
                (joinery as Savane.FrenchDoor).handleSide = worldJoinery.HandleSide;
            break;
            case 7: //double french window
                (joinery as Savane.FrenchDoor).nbDoors = 2;
                (joinery as Savane.FrenchDoor).handleSide = Savane.SceneConstants.HandleSide.handle_center;
            break;
        }
    }

    _createWindow(worldJoinery: Joinery, joineryType: Savane.SceneConstants.JoineryType) : void {
        var joinery = this._createJoinery(worldJoinery, joineryType);
        switch (worldJoinery.JoineryType) {
            case 4: //simple window
                (joinery as Savane.Window).nbCasement = 1;
                (joinery as Savane.Window).handleSide = worldJoinery.HandleSide;
            break;
            case 5: //double window
                (joinery as Savane.Window).nbCasement = 2;
                (joinery as Savane.Window).handleSide = Savane.SceneConstants.HandleSide.handle_center;
            break;
        }
    }

    _createVelux(worldJoinery: Joinery, joineryType: Savane.SceneConstants.JoineryType) : void {
        var joinery = this._createJoinery(worldJoinery, joineryType);
        joinery.position = worldJoinery.Center;
        (joinery as Savane.Velux).updateFloorHeight();
    }

    _setTechnicalDatas(worldJoinery: Joinery, savaneTechnicalElement: Savane.TechnicalElement) : void {
        if (worldJoinery.Model !== undefined) {
            var components = savaneTechnicalElement.getComponents(Savane.ComponentConstants.ComponentType.TechnicalElementType) as Array<Savane.TechnicalElementType>;
            if (components.length > 0) {
                components[0].technicalElementTypeId = worldJoinery.Model;
            }
        }

        if (worldJoinery.MaterialType !== undefined) {
            savaneTechnicalElement.materialType = worldJoinery.MaterialType;
        }
    }

    _createTechnicalElement(worldJoinery: Joinery, elementType: Savane.SceneConstants.TechnicalElementType, width: number, offset: number, length? : number, height?: number) : Savane.TechnicalElement {
        var element = Savane.EntityFactory.createTechnicalElement(elementType, length ? length : worldJoinery.Length, width, height ? height : worldJoinery.Height, null, null);
        var center = glMatrix.vec3.clone(worldJoinery.Center);
        let thickness = worldJoinery.Wall ? worldJoinery.WallThicknessOffset : 0;
        let N = worldJoinery.Wall ? worldJoinery.Wall.N : glMatrix.vec3.create();
        offset *= (worldJoinery.Wall && worldJoinery.Wall.Thickness < 0 ? -1 : 1)
        glMatrix.vec3.add(center, center, glMatrix.vec3.fromValues(-N[0] * thickness + N[0] * offset, -N[1] * thickness + N[1] * offset, worldJoinery.BottomHeight));
        element.position = center;
        element.floorHeight = element.position[2];
        this._setTechnicalDatas(worldJoinery, element);
        this._scene.addTechnicalElement(element);
        return element;
    }

    _createPlaceholderElement(worldJoinery: Joinery, callback: CallableFunction) : void {
        AssetManagerServices.getAsset("assets", worldJoinery.Model, null, function(result) {
            AssetManagerServices.createAssetEntity("assets", result, false, function(entity) {
                var center = glMatrix.vec3.clone(worldJoinery.Center);
                center[2] = 0;
                let thickness = worldJoinery.Wall ? worldJoinery.WallThicknessOffset - entity.width / 2 : 0;
                let N = worldJoinery.Wall ? worldJoinery.Wall.N : glMatrix.vec3.fromValues(0, 0, -1);
                let offset = (worldJoinery.Wall ? worldJoinery.Wall.Thickness / 2 : entity.height / 2);
                offset *= (worldJoinery.Wall && worldJoinery.Wall.Thickness < 0 ? -1 : 1);
                glMatrix.vec3.add(center, center, glMatrix.vec3.fromValues(-N[0] * thickness + N[0] * offset, -N[1] * thickness + N[1] * offset, (worldJoinery.BottomHeight + worldJoinery.TopHeight) / 2 + N[2] * offset));
                entity.position = center;
                if (worldJoinery.Wall) {
                    var X = glMatrix.vec3.fromValues(1, 0, 0);
                    entity.setRotationZ(Functions.OrientedAngle(X, worldJoinery.WallDirection) + Math.PI);
                }

                var room = roomManager.getRoomAtPosition(center, this._scene.currentFloor);
                if (room) {
                    room.addChild(entity);
                } else {
                    this._scene.addEntity(entity);
                }
                callback();
            }.bind(this));
        }.bind(this));
    }

    _createRadiator(worldJoinery: Joinery) : void {
        let elementType = Savane.SceneConstants.TechnicalElementType.radiator;
        let width = worldJoinery.Dimensions.width; // do not recompute width, this a repeatable model
        let offset = worldJoinery.Wall.Thickness / 2 + width / 2;
        var element = this._createTechnicalElement(worldJoinery, elementType, width, offset);
        var X = glMatrix.vec3.fromValues(1, 0, 0);
        element.setRotationZ(Functions.OrientedAngle(X, worldJoinery.WallDirection) + Math.PI);
    }

    _createStove(worldJoinery: Joinery) : void {
        let elementType = Savane.SceneConstants.TechnicalElementType.stove;
        let width = worldJoinery.Dimensions.width * worldJoinery.Length / worldJoinery.Dimensions.length;
        let offset = worldJoinery.Wall.Thickness / 2 + width / 2;
        var element = this._createTechnicalElement(worldJoinery, elementType, width, offset);
        var X = glMatrix.vec3.fromValues(1, 0, 0);
        element.setRotationZ(Functions.OrientedAngle(X, worldJoinery.WallDirection) + Math.PI);
    }

    _createBoiler(worldJoinery: Joinery) : void {
        let elementType = Savane.SceneConstants.TechnicalElementType.boiler;
        let width = worldJoinery.Dimensions.width * worldJoinery.Length / worldJoinery.Dimensions.length;
        let offset = worldJoinery.Wall.Thickness / 2 + width / 2;
        var element = this._createTechnicalElement(worldJoinery, elementType, width, offset);
        var X = glMatrix.vec3.fromValues(1, 0, 0);
        element.setRotationZ(Functions.OrientedAngle(X, worldJoinery.WallDirection) + Math.PI);
    }

    _createAirConditionner(worldJoinery: Joinery) : void {
        let elementType = Savane.SceneConstants.TechnicalElementType.airConditioner;
        let width = worldJoinery.Dimensions.width * worldJoinery.Length / worldJoinery.Dimensions.length;
        let offset = worldJoinery.Wall.Thickness / 2 + width / 2;
        var element = this._createTechnicalElement(worldJoinery, elementType, width, offset);
        var X = glMatrix.vec3.fromValues(1, 0, 0);
        element.setRotationZ(Functions.OrientedAngle(X, worldJoinery.WallDirection) + Math.PI);
    }

    _createFireplace(worldJoinery: Joinery) : void {
        let elementType = Savane.SceneConstants.TechnicalElementType.fireplace;
        let width = worldJoinery.Dimensions.width * worldJoinery.Length / worldJoinery.Dimensions.length;
        let offset = worldJoinery.Wall.Thickness / 2 + width / 2;
        var element = this._createTechnicalElement(worldJoinery, elementType, width, offset);
        var X = glMatrix.vec3.fromValues(1, 0, 0);
        element.setRotationZ(Functions.OrientedAngle(X, worldJoinery.WallDirection) + Math.PI);
    }

    _createBeam(photo2world: Photo2World, worldJoinery: Joinery) : void {
        let elementType = Savane.SceneConstants.TechnicalElementType.beam;
        var center = worldJoinery.Center;
        center[2] = worldJoinery.BottomHeight + worldJoinery.Height / 2;
        //offset the center with 1mm along the normal to prevent self intersection
        var N = worldJoinery.Wall.N;
        var offsetCenter = glMatrix.vec3.create();
        glMatrix.vec3.add(offsetCenter, center, N);
        var hit = photo2world.FindClosestWallWithRay(new Ray(offsetCenter, N), false, false, true);
        var AB = glMatrix.vec3.create();
        glMatrix.vec3.subtract(AB, hit.intersection, center);
        let length = glMatrix.vec3.length(AB) + worldJoinery.Wall.Thickness;
        let offset = length / 2;
        var element = this._createTechnicalElement(worldJoinery, elementType, 0, offset);
        element.length = length;
        element.width = worldJoinery.Length;
        var X = glMatrix.vec3.fromValues(1, 0, 0);
        element.setRotationZ(Functions.OrientedAngle(X, worldJoinery.WallDirection) + Math.PI / 2);
    }

    _createRosette(worldJoinery: Joinery) : void {
        let elementType = Savane.SceneConstants.TechnicalElementType.rosette;
        worldJoinery.Dimensions.height = 30;
        var v1 = glMatrix.vec3.clone(worldJoinery.A);
        var v2 = glMatrix.vec3.clone(worldJoinery.A);
        v2[1] = worldJoinery.B[1];
        var v3 = glMatrix.vec3.clone(worldJoinery.B);
        var element = this._createTechnicalElement(worldJoinery, elementType, glMatrix.vec3.distance(v1, v2), 0, glMatrix.vec3.distance(v2, v3), worldJoinery.Dimensions.height);
       var center = glMatrix.vec3.clone(worldJoinery.Center);
        center[2] -= worldJoinery.Dimensions.height / 2;
        element.position = center;
    }

    _createSpotLight(worldJoinery: Joinery) : void {
        let elementType = Savane.SceneConstants.TechnicalElementType.spotLight;
        worldJoinery.Dimensions.height = 20;
        var v1 = glMatrix.vec3.clone(worldJoinery.A);
        var v2 = glMatrix.vec3.clone(worldJoinery.A);
        v2[1] = worldJoinery.B[1];
        var v3 = glMatrix.vec3.clone(worldJoinery.B);
        var element = this._createTechnicalElement(worldJoinery, elementType, glMatrix.vec3.distance(v1, v2), 0, glMatrix.vec3.distance(v2, v3), worldJoinery.Dimensions.height);
        var center = glMatrix.vec3.clone(worldJoinery.Center);
        center[2] -= worldJoinery.Dimensions.height / 2;
        element.position = center;
    }

    _createPole(photo2world: Photo2World, worldJoinery: Joinery, shape: Savane.SceneConstants.ShapeType) : void {
        let elementType = Savane.SceneConstants.TechnicalElementType.pole;
        var element = this._createTechnicalElement(worldJoinery, elementType, worldJoinery.Dimensions.width, 0, worldJoinery.Dimensions.length, photo2world.WallHeight);
        element.shapeType = shape;
        var center = glMatrix.vec3.clone(worldJoinery.Center);
        center[2] = 0;
        element.position = center;
        element.floorHeight = element.position[2];
    }

    _createSwitchBoard(worldJoinery: Joinery) : void {
        let elementType = Savane.SceneConstants.TechnicalElementType.switchBoard;
        let width = worldJoinery.Dimensions.width * worldJoinery.Length / worldJoinery.Dimensions.length;
        let offset = worldJoinery.Wall.Thickness / 2 + width / 2;
        var element = this._createTechnicalElement(worldJoinery, elementType, width, offset);
        var X = glMatrix.vec3.fromValues(1, 0, 0);
        element.setRotationZ(Functions.OrientedAngle(X, worldJoinery.WallDirection) + Math.PI);
    }

    _createWallDecoration(worldJoinery: Joinery) : void {
        let elementType = Savane.SceneConstants.TechnicalElementType.wallDecoration;
        let width = worldJoinery.Dimensions.width * worldJoinery.Length / worldJoinery.Dimensions.length;
        let offset = worldJoinery.Wall.Thickness / 2 + width / 2;
        var element = this._createTechnicalElement(worldJoinery, elementType, width, offset);
        var X = glMatrix.vec3.fromValues(1, 0, 0);
        element.setRotationZ(Functions.OrientedAngle(X, worldJoinery.WallDirection) + Math.PI);
    }

    _buildJoinery(photo2world: Photo2World, worldJoinery: Joinery) : void {
        let defaultType = Converters.DefaultType(worldJoinery.JoineryType);

        switch (defaultType) {
            case Savane.SceneConstants.DefaultJoineryType.door:
            case Savane.SceneConstants.DefaultJoineryType.frontDoor:
            case Savane.SceneConstants.DefaultJoineryType.cupboardDoor:
            case Savane.SceneConstants.DefaultJoineryType.garageDoor:
                this._createDoor(worldJoinery, Converters.JoineryType(defaultType) as Savane.SceneConstants.JoineryType);
            break;
            case Savane.SceneConstants.JoineryType.opening:
                this._createJoinery(worldJoinery, Converters.JoineryType(defaultType) as Savane.SceneConstants.JoineryType);
            break;
            case Savane.SceneConstants.DefaultJoineryType.window:
                this._createWindow(worldJoinery, Converters.JoineryType(defaultType) as Savane.SceneConstants.JoineryType);
            break;
            case Savane.SceneConstants.DefaultJoineryType.frenchWindow:
                this._createFrenchWindow(worldJoinery, Converters.JoineryType(defaultType) as Savane.SceneConstants.JoineryType);
            break;
            case Savane.SceneConstants.DefaultJoineryType.pictureWindow:
                this._createPictureWindow(worldJoinery, Converters.JoineryType(defaultType) as Savane.SceneConstants.JoineryType);
            break;
            case Savane.SceneConstants.DefaultJoineryType.canopy:
                this._createJoinery(worldJoinery, Converters.JoineryType(defaultType) as Savane.SceneConstants.JoineryType);
            break;
            case Savane.SceneConstants.JoineryType.niche:
                this._createJoinery(worldJoinery, Converters.JoineryType(defaultType) as Savane.SceneConstants.JoineryType);
            break;
            case Savane.SceneConstants.DefaultJoineryType.fixedWindow:
                this._createJoinery(worldJoinery, Converters.JoineryType(defaultType) as Savane.SceneConstants.JoineryType);
            break;
            case Savane.SceneConstants.DefaultJoineryType.velux:
                this._createVelux(worldJoinery, Converters.JoineryType(defaultType) as Savane.SceneConstants.JoineryType);
            break;
            case Savane.SceneConstants.DefaultTechnicalElementType.radiator:
                this._createRadiator(worldJoinery);
            break;
            case Savane.SceneConstants.DefaultTechnicalElementType.stove:
                this._createStove(worldJoinery);
            break;
            case Savane.SceneConstants.DefaultTechnicalElementType.boiler:
                this._createBoiler(worldJoinery);
            break;
            case Savane.SceneConstants.DefaultTechnicalElementType.airConditioner:
                this._createAirConditionner(worldJoinery);
            break;
            case Savane.SceneConstants.DefaultTechnicalElementType.fireplace:
                this._createFireplace(worldJoinery);
            break;
            case Savane.SceneConstants.DefaultTechnicalElementType.beam:
                this._createBeam(photo2world, worldJoinery);
            break;
            case Savane.SceneConstants.DefaultTechnicalElementType.rosette:
                this._createRosette(worldJoinery);
            break;
            case Savane.SceneConstants.DefaultTechnicalElementType.spotLight:
                this._createSpotLight(worldJoinery);
            break;
            case Savane.SceneConstants.DefaultTechnicalElementType.circularPole:
                this._createPole(photo2world, worldJoinery, Savane.SceneConstants.ShapeType.ellipse);
            break;
            case Savane.SceneConstants.DefaultTechnicalElementType.rectangularPole:
                this._createPole(photo2world, worldJoinery, Savane.SceneConstants.ShapeType.quad);
            break;
            case Savane.SceneConstants.DefaultTechnicalElementType.switchBoard:
                this._createSwitchBoard(worldJoinery);
            break;
            case Savane.SceneConstants.DefaultTechnicalElementType.wallDecoration:
                this._createWallDecoration(worldJoinery);
            break;
        }
    }

    _adjustNicheWallsThickness(photo2world: Photo2World) : void {
        for (var i = 0; i < photo2world.Model.WallJoineries.length; ++i) {
            let worldJoinery = photo2world.Model.WallJoineries[i];
            let defaultType = Converters.DefaultType(worldJoinery.JoineryType);
            if (defaultType !== Savane.SceneConstants.JoineryType.niche) continue;
            let thickness = worldJoinery.Thickness * 10;
            if (thickness >= worldJoinery.Wall.Thickness) {
                (worldJoinery.Wall as Wall).Thickness = thickness + 10;
            }
        }
    }

    _adjustNichePartitionsThickness(photo2world: Photo2World) : void {
        for (var i = 0; i < photo2world.Model.PartitionJoineries.length; ++i) {
            let worldJoinery = photo2world.Model.PartitionJoineries[i];
            let defaultType = Converters.DefaultType(worldJoinery.JoineryType);
            if (defaultType !== Savane.SceneConstants.JoineryType.niche) continue;
            let thickness = worldJoinery.Thickness * 10;
            if (thickness >= worldJoinery.Wall.Thickness) {
                (worldJoinery.Wall as Wall).Thickness = thickness + 10;
            }
        }
    }

    _buildWallJoineries(photo2world: Photo2World) : void {
        for (var i = 0; i < photo2world.Model.WallJoineries.length; ++i) {
            let worldJoinery = photo2world.Model.WallJoineries[i];
            this._buildJoinery(photo2world, worldJoinery);
        }
    }

    _buildSlopes(photo2world: Photo2World) : void {
        let floor = this._scene.floors[0];
        for (var i = 0; i < photo2world.Model.Slopes.length; ++i) {
            let worldSlope = photo2world.Model.Slopes[i];
            var savaneWall = floor.getWall(worldSlope.Wall.SavaneId);
            savaneWall.slope = true;
            savaneWall.slopeHeight = worldSlope.Height;
            savaneWall.slopeLength1 = worldSlope.Length;
        }
    }

    _buildSlopesPartition(photo2world: Photo2World) : void {
        let floor = this._scene.floors[0];
        for (var i = 0; i < photo2world.Model.SlopesPartition.length; ++i) {
            let worldSlope = photo2world.Model.SlopesPartition[i];
            var savaneWall = floor.getWall(worldSlope.Wall.SavaneId);
            savaneWall.slope = true;
            savaneWall.slopeHeight = worldSlope.Height;
            savaneWall.slopeLength1 = worldSlope.Length;
        }
    }

    _buildPartitionJoineries(photo2world: Photo2World) : void {
        for (var i = 0; i < photo2world.Model.PartitionJoineries.length; ++i) {
            let worldJoinery = photo2world.Model.PartitionJoineries[i];
            this._buildJoinery(photo2world, worldJoinery);
        }
    }

    _addPlaceholders(photo2world: Photo2World, callback: CallableFunction) : void {
        var placeholders = [];
        for (var i = 0; i < photo2world.Model.WallJoineries.length; ++i) {
            let worldJoinery = photo2world.Model.WallJoineries[i];
            if (worldJoinery.JoineryType === 28) {
                placeholders.push(worldJoinery);
            }
        }

        for (var i = 0; i < photo2world.Model.PartitionJoineries.length; ++i) {
            let worldJoinery = photo2world.Model.PartitionJoineries[i];
            if (worldJoinery.JoineryType === 28) {
                placeholders.push(worldJoinery);
            }
        }

        async.eachSeries(placeholders, function(worldJoinery, endCallback) {
            this._createPlaceholderElement(worldJoinery, function () {
                return endCallback();
            });
        }.bind(this), callback);
    }

    _shiftedOffset(photo2world: Photo2World, partition: Partition) : number {
        if (!partition.IsShifted(photo2world)) {
            return partition.Thickness;
        }

        if (partition.WallA) {
            return partition.WallA.Thickness;
        }
        if (partition.WallB) {
            return partition.WallB.Thickness;
        }
    }

    _snapToWall(position: glMatrix.vec3, direction: glMatrix.vec3, wall: Wall) : glMatrix.vec3 {
        var result = wall.Intersect(new Ray(position, direction), true, false, true);
        if (!result) {
            return position;
        }

        var thickness = wall.Thickness;
        var N = wall.NegateN;
        var angle = Functions.OrientedAngle(direction, N);
        var offset = (thickness / 2) / Math.cos(angle);
        return glMatrix.vec3.fromValues(result[0] + direction[0] * offset, result[1] + direction[1] * offset, 0);
    }

    _snapToWallWhenOutsideViewport(photo2world: Photo2World, position: glMatrix.vec3, direction: glMatrix.vec3) : {intersection: glMatrix.vec3, wall: Wall} | null {
        var pixel = photo2world.Project(position);
        if (pixel[0] < 0 || pixel[0] >= photo2world.Width || pixel[1] < 0 || pixel[1] >= photo2world.Height) { //we are outside the viewport
            var hit = photo2world.FindClosestWallWithRay(new Ray(position, direction), false, false, false);
            if (hit) { //we hit a wall
                var result = hit.intersection;
                var angle = Functions.OrientedAngle(direction, hit.wall.NegateN);
                var offset = (hit.wall.Thickness / 2) / Math.cos(angle);
                hit.intersection = glMatrix.vec3.fromValues(result[0] + direction[0] * offset, result[1] + direction[1] * offset, 0);
                return hit;
            }
        }

        return null;
    }

    _findPartitionsReferencingWall(photo2world: Photo2World, wall: Wall) : Array<Partition> {
        var result = [];

        for (var i = 0; i < photo2world.Model.Partitions.length; ++i) {
            var partition = photo2world.Model.Partitions[i];
            if (partition.WallA === wall || partition.WallB === wall) {
                result.push(partition);
            }
        }

        //sort partitions according to the wall start position - required for a correct wall split
        result.sort(function(a, b) {
            var VA = glMatrix.vec3.create();
            var PA = (a.WallA === wall ? a.A : a.B);
            glMatrix.vec3.subtract(VA, wall.A, PA);

            var VB = glMatrix.vec3.create();
            var PB = (b.WallA === wall ? b.A : b.B);
            glMatrix.vec3.subtract(VB, wall.A, PB);

            var DA = glMatrix.vec3.squaredLength(VA);
            var DB = glMatrix.vec3.squaredLength(VB);
            if (DA < DB) {
                return -1;
            }

            if (DA > DB) {
                return 1;
            }

            return 0;
        });

        return result;
    }

    _splitWalls(photo2world: Photo2World) : void {
        for (var i = 0; i < photo2world.Model.Walls.length; ++i) {
            var wall2split = photo2world.Model.Walls[i];
            var partitions = this._findPartitionsReferencingWall(photo2world, wall2split);
            this._splitWallFromPartitions(wall2split, partitions, photo2world);
        }
    }

    _moveJoineriesToNewWalls(previousWall: Savane.Wall, newWalls: Array<Savane.Wall>) : void {
        var joineries = previousWall.joineries;
        for (var i = 0; i < joineries.length; ++i) {
            var joinerie = joineries[i];
            for (var j = 0; j < newWalls.length; ++j) {
                var wall = newWalls[j];
                if (wall.isPointOnWall(joinerie.position, wall.thickness)) {
                    wall.addJoinery(joinerie);
                } else if (joinerie instanceof Savane.Velux && wall.getSlope(joinerie) !== 0) {
                    wall.addJoinery(joinerie);
                }
            }
        }
    }

    _splitWallFromPartitions(worldWall: Wall, partitions: Array<Partition>, photo2world: Photo2World) : void {
        if (partitions.length === 0) {
            return;
        }

        let floor = this._scene.floors[0];
        var savaneWall = floor.getWall(worldWall.SavaneId);
        this._scene.deleteWall(savaneWall.id, false);

        var newWalls = [];
        var A = savaneWall.begin;
        for (var i = 0; i < partitions.length; ++i) {
            var partition = partitions[i];

            var savanePartition = floor.getWall(partition.SavaneId);
            if (savanePartition === null) continue;
            var B = null;
            if (partition.WallA === worldWall) {
                if (partition.IsOnWallAExtrimity) { //The wall snap on a wall extrimity -> do not split
                    continue;
                }
                B = savanePartition.begin;
            }

            if (partition.WallB === worldWall) {
                if (partition.IsOnWallBExtrimity) { //The wall snap on a wall extrimity -> do not split
                    continue;
                }
                B = savanePartition.end;
            }

            var newSavaneWall = Savane.EntityFactory.createWall(glMatrix.vec3.clone(A), glMatrix.vec3.clone(B));
            newSavaneWall.height = worldWall.Height;
            newSavaneWall.thickness = worldWall.Thickness;
            newSavaneWall.shiftDirection = savaneWall.shiftDirection;
            this._scene.addWall(newSavaneWall, true, photo2world.WallHeight);
            newWalls.push(newSavaneWall);

            A = glMatrix.vec3.clone(B);
        }

        B = savaneWall.end;
        var newSavaneWall = Savane.EntityFactory.createWall(glMatrix.vec3.clone(A), glMatrix.vec3.clone(B));
        newSavaneWall.height = worldWall.Height;
        newSavaneWall.thickness = worldWall.Thickness;
        newSavaneWall.shiftDirection = savaneWall.shiftDirection;
        this._scene.addWall(newSavaneWall, true, photo2world.WallHeight);
        newWalls.push(newSavaneWall);

        for (var i = 0; i < newWalls.length; ++i) {
            var newWall = newWalls[i];
            newWall.slope = savaneWall.slope;
            newWall.slopeHeight = savaneWall.slopeHeight;
            newWall.slopeLength1 = savaneWall.slopeLength1;
            newWall.slopeLength2 = savaneWall.slopeLength2;
        }

        this._moveJoineriesToNewWalls(savaneWall, newWalls);
    }

    _splitPartitions(photo2world: Photo2World) : void {
        for (var i = 0; i < photo2world.Model.Partitions.length; ++i) {
            var wall2split = photo2world.Model.Partitions[i];
            var partitions = this._findPartitionsReferencingWall(photo2world, wall2split);
            this._splitWallFromPartitions(wall2split, partitions, photo2world);
        }
    }

    _snapPartition(photo2world: Photo2World, partition: Partition) : { A: glMatrix.vec3, B: glMatrix.vec3 } {
        var A = glMatrix.vec3.clone(partition.MiddleA);
        var B = glMatrix.vec3.clone(partition.MiddleB);

        if (partition.IsAShifted(photo2world)) { //snap to wall corner
            A = partition.ShiftPositionWallA;
            if (!partition.WallB) {
                B = partition.OppositeShiftPositionWallA;
            }
        }

        if (partition.IsBShifted(photo2world)) { //snap to wall corner
            B = partition.ShiftPositionWallB;
            if (!partition.WallA) {
                A = partition.OppositeShiftPositionWallB;
            }
        }

        if (!partition.IsAShifted(photo2world)) {
            if (partition.WallA) { // snap to the wall
                A = this._snapToWall(A, partition.NegateD, partition.WallA);
            } else {
                // check if A is outside the viewport and snap to the not visible wall
                var hit = this._snapToWallWhenOutsideViewport(photo2world, partition.MiddleA, partition.NegateD);
                if (hit) { //we snap to a not visible wall
                    A = hit.intersection;
                    partition.WallA = hit.wall;
                }
            }
        }

        if (!partition.IsBShifted(photo2world)) {
            if (partition.WallB) { // snap to the wall
                B = this._snapToWall(B, partition.D, partition.WallB);
            } else {
                // check if B is outside the viewport and snap to the not visible wall
                var hit = this._snapToWallWhenOutsideViewport(photo2world, partition.MiddleB, partition.D);
                if (hit) { //we snap to a not visible wall
                    B = hit.intersection;
                    partition.WallB = hit.wall;
                }
            }
        }

        return {A: A, B: B};
    }

    _buildPartitions(photo2world: Photo2World) : void {
        //reproject partitions on walls
        for (var i = 0; i < photo2world.Model.Partitions.length; ++i) {
            let partition = photo2world.Model.Partitions[i];

            var snapPositions = this._snapPartition(photo2world, partition);
            var savanePartition = Savane.EntityFactory.createWall(glMatrix.vec3.fromValues(snapPositions.A[0], snapPositions.A[1], 0),
                                                           glMatrix.vec3.fromValues(snapPositions.B[0], snapPositions.B[1], 0));
            savanePartition.height = partition.Height;
            savanePartition.thickness = partition.Thickness;
            savanePartition.shiftDirection = partition.ShiftDirection(photo2world);
            partition.SavaneId = savanePartition.id;
            this._scene.addWall(savanePartition, true, photo2world.WallHeight);
        }
    }

    Build(photo2world: Photo2World, world: Savane.World, exterior: boolean, callback: CallableFunction) : void {
        this._scene.currentFloor.deleteAllChild();

        this._buildCamera(photo2world, world);
        photo2world.CloseWalls();
        this._adjustNicheWallsThickness(photo2world);
        this._buildWalls(photo2world);
        this._buildSlopes(photo2world);
        this._buildWallJoineries(photo2world);
        this._adjustNichePartitionsThickness(photo2world);
        this._buildPartitions(photo2world);
        this._buildSlopesPartition(photo2world);
        this._buildPartitionJoineries(photo2world);

        this._moveWallPositionWhenPartitionSnappedOnExtreminity(photo2world);

        this._splitWalls(photo2world);
        this._splitPartitions(photo2world);

        this._scene.currentFloor.addSun();
        // leave default sun exterior no so I comment next line
        // this._scene.currentFloor.getSun().exterior = "None";

        if (exterior) {
            var sun = this._scene.currentFloor.getSun();
            sun.shadowBlurRadius = 20;
            sun.skyIntensity = 6;
        }

        var rooms = this._scene.currentFloor.rooms;
        for (var i = 0; i < rooms.length; ++i) {
            rooms[i].height = photo2world.WallHeight;
        }

        world.addChild(this._scene);

        this._addPlaceholders(photo2world, callback);
    }
}
