import { TemporaryComment } from './temporary/TemporaryModule';
import { SceneConstants, math, Transform } from '../SavaneJS';
import { Entity } from './Entity';

export class Comment extends Entity {
    // Comment text
    private _text: string;
    // Comment image url array
    private _images: Array<string>;
    // Comment width and height (fake)
    private width: number;
    private length: number;

    constructor() {
        super();

        this._text = "";
        this._images = [];
        // Fake Width and Length for rotation
        this.width = 350;
        this.length = 350;
    }

    get entityType(): SceneConstants.EntityType {
        return SceneConstants.EntityType.Comment;
    }

    get floorHeight(): number {
        return(0);
    }

    set floorHeight(fh: number) {
    }

    get position(): math.vec3 {
        if (this.temporary === null) {
            return this.transform.globalPosition;
        } else {
            return (this.temporary as TemporaryComment).transform.globalPosition;
        }
    }

    set position(p: math.vec3) {
        if (this.temporary === null) {
            this.transform.globalPosition = p;
        } else {
            (this.temporary as TemporaryComment).transform.globalPosition = p;
        }
    }

    get text(): string {
        if (this.temporary === null) {
            return this._text;
        } else {
            return (this.temporary as TemporaryComment).text;
        }
    }

    set text(t: string) {
        if (this.temporary === null) {
            this._text = t;
        } else {
            (this.temporary as TemporaryComment).text = t;
        }
    }

    get images(): Array<string> {
        if (this.temporary === null) {
            return this._images;
        } else {
            return (this.temporary as TemporaryComment).images;
        }
    }

    set images(i: Array<string>) {
        if (this.temporary === null) {
            this._images = i;
        } else {
            (this.temporary as TemporaryComment).images = i;
        }
    }

    get boundingBox(): Array<math.vec3> {
        let points = [];
        let point1 = math.vec3.create();
        let point2 = math.vec3.create();
        let point3 = math.vec3.create();
        let point4 = math.vec3.create();
        // Precalculate sin/cos
        let sAngle = Math.sin(0);
        let cAngle = Math.cos(0);
        let invSAngle = Math.sin(Math.PI / 2);
        let invCAngle = Math.cos(Math.PI / 2);

        math.vec3.set(point1, this.position[0] + (cAngle * this.length) / 2, this.position[1] + (sAngle * this.length) / 2, 0);

        math.vec3.set(point2, point1[0] - (invCAngle * this.width) / 2, point1[1] + (invSAngle * this.width) / 2, 0);

        math.vec3.set(point1, point1[0] + (invCAngle * this.width) / 2, point1[1] - (invSAngle * this.width) / 2, 0);

        math.vec3.set(point3, this.position[0] - (cAngle * this.length) / 2, this.position[1] - (sAngle * this.length) / 2, 0);

        math.vec3.set(point4, point3[0] + (invCAngle * this.width) / 2, point3[1] - (invSAngle * this.width) / 2, 0);
        math.vec3.set(point3, point3[0] - (invCAngle * this.width) / 2, point3[1] + (invSAngle * this.width) / 2, 0);

        points.push(point1);
        points.push(point2);
        points.push(point3);
        points.push(point4);

        return points;
    }

    setRotationZ(rz: number) {
    }

    // Get transformation matrix
    get transform(): Transform {
        if (this.temporary === null) {
            return this._transform;
        } else {
            return (this.temporary as TemporaryComment).transform;
        }
    }

    /**
     * Create the renderCamera that will be used during edition
     */
    startTemporary() {
        let temp = new TemporaryComment(this);
        this.temporary = temp;
    }

    /**li
     * delete the renderCamera
     */
    endTemporary() {
        this.temporary = null;
    }

    /**
     * save the temporary data in the renderCamera and delete the temporaryArrangement
     */
    saveAndEndTemporary() {
        let positionTemp = this.position;
        let textTemp = this.text;
        let imagesTemp = this.images;

        this.temporary = null;

        this.position = positionTemp;
        this.text = textTemp;
        this.images = imagesTemp;
    }
}
