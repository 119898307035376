import { ComponentConstants, math, SavaneMath } from '../SavaneJS';
import { Area } from './Area';

export class CutArea extends Area {
    public isDirectSide: Boolean;

    constructor() {
        super();
        //whether the coating is apply to the direct side of the wall or the undirect
        this.isDirectSide = true;
    }

    isInArea(p: math.vec3): boolean {
        return SavaneMath.isInPoly(p, this._vertices);
    }

    get area(): number {
        return SavaneMath.getPolygonArea(this._vertices);
    }

    get componentType(): ComponentConstants.ComponentType {
        return ComponentConstants.ComponentType.CutArea;
    }

    get vertices(): Array<math.vec3> {
        return this._vertices;
    }

    set vertices(v: Array<math.vec3>) {
        this._vertices = v;
    }

    clone(): CutArea {
        let obj = new CutArea();
        obj.isDirectSide = this.isDirectSide;

        let vertices: Array<math.vec3> = new Array<math.vec3>();
        for (let i = 0; i < this.vertices.length; i++) {
            vertices.push(math.vec3.clone(this.vertices[i]));
        }
        
        obj.vertices = vertices;
        return obj;
    }
}
