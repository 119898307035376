import { TemporaryRenderCamera } from './temporary/TemporaryModule';
import { World, Scene, SceneConstants, math } from '../SavaneJS';
import { Entity } from './Entity';

/**
 * RenderCamera is item placed in room to allow scene to be render in 3D
 * using this point as POV.
 */
export class RenderCamera extends Entity {
    // Fake for 2d icon
    private width: number;
    private length: number;
    // Camera parameters
    private _fov: number;
    private _iso: number;
    private _shutterSpeed: number;
    private _fNumber: number;

    private _quality: number;
    private _isFinalRender: boolean;
    private _cameraType: SceneConstants.CameraType;

    private _renderWidth: number;
    private _renderHeight: number;

    private _cameraNb: number;

    private _verticalShift: number;
    private _denoising: boolean;
    private _autoexposure: boolean;
    private _whitebalance: boolean;
    private _exposure: number;
    private _dof: number;
    private _aperture: number;

    private _renderType: number;
    private _hd: boolean;
    private _format: SceneConstants.CameraPreset;

    private _nbImages: number;

    private _excludedObjectIds: Array<number>;
    private _shoppingList: Array<string>;
    private _entityShoppingList: Array<number>;

    private _sunOffsetRotation: number;
    private _sunOffsetAltitude: number;

    private _projection: math.mat4;

    constructor() {
        super();

        // Original camera parameters
        this._fov = SceneConstants.CameraPerspectiveOriginalFOV;

        // Fake Width and Length for rotation
        this.width = 450;
        this.length = 450;

        // Camera standard parameters having impact on lightening
        this._iso = 100;
        this._shutterSpeed = 1;
        this._fNumber = 14;

        // Default qualities are 0 for uggly, 1 for average, (preview), 2 for good and 3 for great
        this._quality = 2;
        // Is final render boolean set to true as default to go to rhinov back office when render is generated
        this._isFinalRender = true;
        // Default camera type = perspective
        this._cameraType = SceneConstants.CameraType.Perspective;
        // Camera isn't locked as it is a perspective camera (lock only available on photo render)
        this.locked = false;

        // Camera Nb used to name the camera during render
        this._cameraNb = 1;

        // 1.8m height default
        var newPosition = this.transform.localPosition;
        newPosition[2] = 1200;
        this.transform.localPosition = newPosition;

        //projection matrix
        this._projection = undefined;
        this._verticalShift = 0;

        // denoising for the rendering
        this._denoising = true;
        // auto exposure
        this._autoexposure = true;
        // white balance
        this._whitebalance = false;
        // render type : 1 = day
        this._renderType = 1;
        // Exposure for unreal
        this._exposure = 0;
        // Depth of field
        this._dof = 0;
        // Aperture
        this._aperture = 2.8;

        // hd false first
        this._hd = false;

        // Paysage
        this._format = SceneConstants.CameraPreset.NormalPaysage;

        // Number of images for this camera
        this._nbImages = 1;

        // Set rendering resolution
        this.setResolution();

        //excluded objects from this camera
        this._excludedObjectIds = [];

        //visible shopping list items from this camera (store asset manager ids)
        this._shoppingList = [];
        //visible shopping list items from this camera (store savane entity ids)
        this._entityShoppingList = [];

        //sun offsets
        this._sunOffsetRotation = 0;
        this._sunOffsetAltitude = 0;
    }

    /**
     * Getter for the Entity type
     *
     */
    get entityType(): SceneConstants.EntityType {
        return SceneConstants.EntityType.RenderCamera;
    }

    // Setters and getters for positions
    get transform() {
        if (this.temporary === null) {
            return this._transform;
        }
        else {
            return (this.temporary as TemporaryRenderCamera).transform;
        }
    }

    get position(): math.vec3 {
        return this.transform.globalPosition;
    }

    set position(p: math.vec3) {
        // It is forbidden to touch any camera parameter is it is a photoRender camera
        if (this.locked) {
            return;
        }
        this.transform.globalPosition = p;
    }

    set floorHeight(h: number) {
        // It is forbidden to touch any camera parameter is it is a photoRender camera
        if (this.locked) {
            return;
        }

        var newPos = math.vec3.create();
        math.vec3.set(newPos, this.transform.localPosition[0], this.transform.localPosition[1], h);
        this.transform.localPosition = newPos;
    }

    get floorHeight() : number {
        return this.transform.localPosition[2];
    }

    set nbImages(ni: number) {
        if (this.cameraType !== SceneConstants.CameraType.Video) {
            ni = 1;
        }
        else {
            // Force 2 images min for video (1 empty, 1 full)
            if (ni < 2) {
                ni = 2;
            }
        }

        if (this.temporary === null) {
            this._nbImages = ni;
        }
        else {
            (this.temporary as TemporaryRenderCamera).nbImages = ni;
        }
    }

    get nbImages(): number {
        if (this.temporary === null) {
            return this._nbImages;
        }
        else {
            return (this.temporary as TemporaryRenderCamera).nbImages;
        }
    }

    set cameraNb(cn: number) {
        if (this.temporary === null) {
            this._cameraNb = cn;
        }
        else {
            (this.temporary as TemporaryRenderCamera).cameraNb = cn;
        }
    }

    get cameraNb(): number {
        if (this.temporary === null) {
            return this._cameraNb;
        }
        else {
            return (this.temporary as TemporaryRenderCamera).cameraNb;
        }
    }

    set denoising(d: boolean) {
        if (this.temporary === null) {
            this._denoising = d;
        }
        else {
            (this.temporary as TemporaryRenderCamera).denoising = d;
        }
    }

    get denoising(): boolean {
        if (this.temporary === null) {
            return this._denoising;
        }
        else {
            return (this.temporary as TemporaryRenderCamera).denoising;
        }
    }

    set autoexposure(ae: boolean) {
        if (this.temporary === null) {
            this._autoexposure = ae;
        }
        else {
            (this.temporary as TemporaryRenderCamera).autoexposure = ae;
        }
    }

    get autoexposure(): boolean {
        if (this.temporary === null) {
            return this._autoexposure;
        }
        else {
            return (this.temporary as TemporaryRenderCamera).autoexposure;
        }
    }

    set whitebalance(wb: boolean) {
        if (this.temporary === null) {
            this._whitebalance = wb;
        }
        else {
            (this.temporary as TemporaryRenderCamera).whitebalance = wb;
        }
    }

    get whitebalance(): boolean {
        if (this.temporary === null) {
            return this._whitebalance;
        }
        else {
            return (this.temporary as TemporaryRenderCamera).whitebalance;
        }
    }

    set hd(h: boolean) {
        if (this.temporary === null) {
            this._hd = h;
        }
        else {
            (this.temporary as TemporaryRenderCamera).hd = h;
        }

        this.setResolution();
    }

    get hd(): boolean {
        if (this.temporary === null) {
            return this._hd;
        }
        else {
            return (this.temporary as TemporaryRenderCamera).hd;
        }
    }

    set renderType(rt: number) {
        if (this.temporary === null) {
            this._renderType = rt;
        }
        else {
            (this.temporary as TemporaryRenderCamera).renderType = rt;
        }
    }

    get renderType(): number {
        if (this.temporary === null) {
            return this._renderType;
        }
        else {
            return (this.temporary as TemporaryRenderCamera).renderType;
        }
    }

    set exposure(e: number) {
        if (this.temporary === null) {
            this._exposure = e;
        }
        else {
            (this.temporary as TemporaryRenderCamera).exposure = e;
        }
    }

    get exposure(): number {
        if (this.temporary === null) {
            return this._exposure;
        }
        else {
            return (this.temporary as TemporaryRenderCamera).exposure;
        }
    }

    set dof(d: number) {
        if (this.temporary === null) {
            this._dof = d;
        }
        else {
            (this.temporary as TemporaryRenderCamera).dof = d;
        }
    }

    get dof(): number {
        if (this.temporary === null) {
            return this._dof;
        }
        else {
            return (this.temporary as TemporaryRenderCamera).dof;
        }
    }

    set aperture(a: number) {
        if (this.temporary === null) {
            this._aperture = a;
        }
        else {
            (this.temporary as TemporaryRenderCamera).aperture = a;
        }
    }

    get aperture(): number{
        if (this.temporary === null) {
            return this._aperture;
        }
        else {
            return (this.temporary as TemporaryRenderCamera).aperture;
        }
    }

    setResolution() {
        if (this.format === SceneConstants.CameraPreset.Free) {
            return;
        }
        if (this.cameraType === SceneConstants.CameraType.Panoramic) {
            if (this.hd) {
                this.renderWidth = 2048;
                this.renderHeight = 2048;
            }
            else {
                this.renderWidth = 1024;
                this.renderHeight = 1024;
            }
        }

        if (this.cameraType === SceneConstants.CameraType.Perspective || this.cameraType === SceneConstants.CameraType.Storage ||this.cameraType === SceneConstants.CameraType.Axonomic || this.cameraType === SceneConstants.CameraType.Video) {
            if (this.hd) {
                this.renderWidth = SceneConstants.CameraPresetSize.hd[this.format].width;
                this.renderHeight = SceneConstants.CameraPresetSize.hd[this.format].height;
            }
            else {
                this.renderWidth = SceneConstants.CameraPresetSize.sd[this.format].width;
                this.renderHeight = SceneConstants.CameraPresetSize.sd[this.format].height;
            }
        }
    }

    set format(f) {
        if (this.cameraType === SceneConstants.CameraType.PhotoRender) {
            f = SceneConstants.CameraPreset.Free;
        }

        if (this.temporary === null) {
            this._format = f;
        }
        else {
            (this.temporary as TemporaryRenderCamera).format = f;
        }

        this.setResolution();
    }

    get format(): number {
        if (this.temporary === null) {
            return this._format;
        }
        else {
            return (this.temporary as TemporaryRenderCamera).format;
        }
    }

    isSameCameraType(c: RenderCamera) {
        if (c.cameraType === this.cameraType) {
            return true;
        }

        if ((this.cameraType === SceneConstants.CameraType.Perspective || this.cameraType === SceneConstants.CameraType.PhotoRender) &&
            (c.cameraType === SceneConstants.CameraType.Perspective || c.cameraType === SceneConstants.CameraType.PhotoRender)) {
            return true;
        }

        return false;
    }

    updateCameraNb(world: World) {
        var maxCameraNb = 0;
        for (var k = 0; k < world.children.length; ++k) {
            var scene = world.children[k] as Scene;
            var floors = scene.floors;
            for (var i = 0; i < floors.length; i++) {
                for (var j = 0; j < floors[i].renderCameras.length; j++) {
                    if (this.isSameCameraType(floors[i].renderCameras[j]) && floors[i].renderCameras[j].id !== this.id) {
                        if (maxCameraNb < floors[i].renderCameras[j].cameraNb) {
                            maxCameraNb = floors[i].renderCameras[j].cameraNb;
                        }
                    }
                }
            }
        }

        this.cameraNb = maxCameraNb + 1;
    };

    get projection(): math.mat4 {
        return this._projection;
    }

    set projection(p: math.mat4) {
        // It is forbidden to touch any camera parameter is it is a photoRender camera
        if (this.cameraType === SceneConstants.CameraType.PhotoRender) {
            return;
        }
        this._projection = p;
    }

    get verticalShift(): number {
        if (this.temporary) {
            return (this.temporary as TemporaryRenderCamera).verticalShift;
        }

        return this._verticalShift;
    }

    set verticalShift(vs: number) {
        // It is forbidden to touch any camera parameter is it is a photoRender camera
        if (this.cameraType === SceneConstants.CameraType.PhotoRender) {
            return;
        }

        if (this.temporary) {
            (this.temporary as TemporaryRenderCamera).verticalShift = vs;
        } else {
            this._verticalShift = vs;
        }
    }

    get excludedObjectIds(): Array<number> {
        if (this.temporary) {
            return (this.temporary as TemporaryRenderCamera).excludedObjectIds;
        }

        return this._excludedObjectIds;
    }

    set excludedObjectIds(eoi: Array<number>) {
        if (this.temporary) {
            (this.temporary as TemporaryRenderCamera).excludedObjectIds = eoi;
        } else {
            this._excludedObjectIds = eoi;
        }
    }

    get shoppingList(): Array<string> {
        if (this.temporary) {
            return (this.temporary as TemporaryRenderCamera).shoppingList;
        }

        return this._shoppingList;
    }

    set shoppingList(sl: Array<string>) {
        if (this.temporary) {
            (this.temporary as TemporaryRenderCamera).shoppingList = sl;
        } else {
            this._shoppingList = sl;
        }
    }

    get entityShoppingList(): Array<number>{
        if (this.temporary) {
            return (this.temporary as TemporaryRenderCamera).entityShoppingList;
        }

        return this._entityShoppingList;
    }

    set entityShoppingList(esl: Array<number>) {
        if (this.temporary) {
            (this.temporary as TemporaryRenderCamera).entityShoppingList = esl;
        } else {
            this._entityShoppingList = esl;
        }
    }

    get sunOffsetRotation(): number {
        if (this.temporary) {
            return (this.temporary as TemporaryRenderCamera).sunOffsetRotation;
        }

        return this._sunOffsetRotation;
    }

    set sunOffsetRotation(sor: number) {
        if (this.temporary) {
            (this.temporary as TemporaryRenderCamera).sunOffsetRotation = sor;
        } else {
            this._sunOffsetRotation = sor;
        }
    }

    get sunOffsetAltitude(): number {
        if (this.temporary) {
            return (this.temporary as TemporaryRenderCamera).sunOffsetAltitude;
        }

        return this._sunOffsetAltitude;
    }

    set sunOffsetAltitude(soa: number) {
        if (this.temporary) {
            (this.temporary as TemporaryRenderCamera).sunOffsetAltitude = soa;
        } else {
            this._sunOffsetAltitude = soa;
        }
    }

    // Setters and getters for camera types
    get cameraType(): SceneConstants.CameraType {
        if (this.temporary === null) {
            return this._cameraType;
        }
        else {
            return ((this.temporary as TemporaryRenderCamera).cameraType);
        }
    }

    set rawCameraType(ct: SceneConstants.CameraType) {
        this._cameraType = ct;
    }

    set cameraType(ct: SceneConstants.CameraType) {
        let position;

        if (this.temporary === null) {
            this._cameraType = ct;
        }
        else {
            (this.temporary as TemporaryRenderCamera).cameraType = ct;
        }

        switch (this.cameraType) {
            case SceneConstants.CameraType.Perspective:
                if (this.format === SceneConstants.CameraPreset.RangementPaysage || this.format === SceneConstants.CameraPreset.RangementPortrait) {
                    this.format = SceneConstants.CameraPreset.NormalPaysage;
                }
                position = this.transform.localPosition;
                position[2] = 1200;
                this.transform.localPosition = position;
                this.fNumber = 14;
                this.shutterSpeed = 1;
                this.pitch = 0;
                this.nbImages = 1;
                break;

            case SceneConstants.CameraType.Storage:
                if (this.format !== SceneConstants.CameraPreset.RangementPaysage && this.format !== SceneConstants.CameraPreset.RangementPortrait) {
                    this.format = SceneConstants.CameraPreset.RangementPaysage;
                }
                position = this.transform.localPosition;
                position[2] = 1200;
                this.transform.localPosition = position;
                this.fNumber = 14;
                this.shutterSpeed = 1;
                this.pitch = 0;
                this.nbImages = 1;
                break;

            case SceneConstants.CameraType.Panoramic:
                position = this.transform.localPosition;
                position[2] = 1600;
                this.transform.localPosition = position;
                this.fNumber = 10;
                this.shutterSpeed = 1;
                this.pitch = 0;
                this.nbImages = 1;
                break;

            case SceneConstants.CameraType.Axonomic:
                this.fNumber = 16;
                this.shutterSpeed = 20;
                this.pitch = 90;
                this.nbImages = 1;
                break;

            case SceneConstants.CameraType.PhotoRender:
                this.format = SceneConstants.CameraPreset.Free;
                this.locked = true;
                break;

            case SceneConstants.CameraType.Video:
                this.nbImages = 10;
                break;
        }

        this.setResolution();

        switch (this.cameraType) {
            case SceneConstants.CameraType.Axonomic:
                this.fov = Math.round((2.0 * Math.atan(Math.tan(0.5044023 * 0.5) / (this.renderWidth / this.renderHeight))) * (180 / Math.PI));
                this.calculateAxoPosition();
                break;
        }
    }

    calculateAxoPosition() {
        let i;
        // Get camera floor
        let floorRenderCamera = this.floor;

        // Now change camera position to see all scene from top

        // Get the max height of the rooms of the floorRenderCamera
        var maxHeight = -Infinity;
        for (i = 0; i < floorRenderCamera.roomArray.length; i++) {
            let room = floorRenderCamera.roomArray[i];
            maxHeight = Math.max(maxHeight, room.height);
        }

        for (i = 0; i < floorRenderCamera.wallArray.length; i++) {
            let wall = floorRenderCamera.wallArray[i];
            maxHeight = Math.max(maxHeight, wall.height);
        }

        // First get bounding box of current floorRenderCamera
        let originalFloorBBox = floorRenderCamera.boundingBox;
        // floorBounding box relatively to camera position
        let floorCameraBBoxX1;
        let floorCameraBBoxX2;
        let floorCameraBBoxY1;
        let floorCameraBBoxY2;
        // Camera rotated floor bounding box
        let floorBBoxX1;
        let floorBBoxX2;
        let floorBBoxX3;
        let floorBBoxX4;
        let floorBBoxY1;
        let floorBBoxY2;
        let floorBBoxY3;
        let floorBBoxY4;

        // Warning this is an interior bounding box so we will add 500mm to make sure we are bigger than the exterior bounding box
        // Transform floor bounding box relatively to camera position
        floorCameraBBoxX1 = (originalFloorBBox[0][0]) - this.transform.globalPosition[0];
        floorCameraBBoxX2 = (originalFloorBBox[3][0]) - this.transform.globalPosition[0];
        floorCameraBBoxY1 = (originalFloorBBox[0][1]) - this.transform.globalPosition[1];
        floorCameraBBoxY2 = (originalFloorBBox[3][1]) - this.transform.globalPosition[1];
        // Rotate the floor bounding box according to the camera angle
        floorBBoxX1 = floorCameraBBoxX1 * Math.cos(-this.angle) - floorCameraBBoxY2 * Math.sin(-this.angle);
        floorBBoxY1 = floorCameraBBoxY2 * Math.cos(-this.angle) + floorCameraBBoxX1 * Math.sin(-this.angle);

        floorBBoxX2 = floorCameraBBoxX2 * Math.cos(-this.angle) - floorCameraBBoxY2 * Math.sin(-this.angle);
        floorBBoxY2 = floorCameraBBoxY2 * Math.cos(-this.angle) + floorCameraBBoxX2 * Math.sin(-this.angle);

        floorBBoxX3 = floorCameraBBoxX2 * Math.cos(-this.angle) - floorCameraBBoxY1 * Math.sin(-this.angle);
        floorBBoxY3 = floorCameraBBoxY1 * Math.cos(-this.angle) + floorCameraBBoxX2 * Math.sin(-this.angle);

        floorBBoxX4 = floorCameraBBoxX1 * Math.cos(-this.angle) - floorCameraBBoxY1 * Math.sin(-this.angle);
        floorBBoxY4 = floorCameraBBoxY1 * Math.cos(-this.angle) + floorCameraBBoxX1 * Math.sin(-this.angle);

        // Get max width and max height
        let maxHalfWidth = Math.max(Math.max(Math.abs(floorBBoxX1), Math.abs(floorBBoxX2)),
            Math.max(Math.abs(floorBBoxX3), Math.abs(floorBBoxX4))) + 500;
        let maxHalfHeight = Math.max(Math.max(Math.abs(floorBBoxY1), Math.abs(floorBBoxY2)),
            Math.max(Math.abs(floorBBoxY3), Math.abs(floorBBoxY4))) + 500;
        let aspectRatio = this.renderWidth / this.renderHeight;
        let fovRad = this.fov * Math.PI / 180;
        let fovxRad;
        let fovyRad;

        if (this.renderWidth > this.renderHeight) {
            fovxRad = fovRad;
            fovyRad = 2.0 * Math.atan(Math.tan(fovRad * 0.5) / aspectRatio);
        }
        else {
            fovyRad = fovRad;
            fovxRad = 2.0 * Math.atan(Math.tan(fovRad * 0.5) * aspectRatio);
        }

        // Prevent a division by 0.
        if (maxHalfWidth === 0.0) {
            maxHalfWidth = 0.0001;
        }
        if (aspectRatio === 0.0) {
            aspectRatio = 0.0001;
        }

        var distanceVertical = maxHalfHeight / (2 * Math.tan(fovyRad / 2));
        var distanceHorizontal = maxHalfWidth / (2 * Math.tan(fovxRad / 2));

        // Compute camera distance thanks to max of distanceWidth and distanceHeight and move it up to the ceiling of the room and base of the floor
        this.height = maxHeight + Math.max(distanceVertical, distanceHorizontal);
    }

    // Setters and getters for final render boolean
    get isFinalRender(): boolean {
        if (this.temporary === null) {
            return this._isFinalRender;
        }
        else {
            return ((this.temporary as TemporaryRenderCamera).isFinalRender);
        }
    }

    set isFinalRender(f: boolean) {
        if (this.temporary === null) {
            this._isFinalRender = f;
        }
        else {
            (this.temporary as TemporaryRenderCamera).isFinalRender = f;
        }
    }

    get boundingBox(): Array<math.vec3> {
        let points = [];
        let point1 = math.vec3.create();
        let point2 = math.vec3.create();
        let point3 = math.vec3.create();
        let point4 = math.vec3.create();
        //Precalculate sin/cos
        let sAngle = Math.sin(this.angle);
        let cAngle = Math.cos(this.angle);
        let invSAngle = Math.sin(Math.PI / 2 - this.angle);
        let invCAngle = Math.cos(Math.PI / 2 - this.angle);

        math.vec3.set(point1, this.position[0] + cAngle * (this.length) / 2,
            this.position[1] + sAngle * (this.length) / 2, 0);

        math.vec3.set(point2, point1[0] - invCAngle * (this.width) / 2,
            point1[1] + invSAngle * (this.width) / 2, 0);

        math.vec3.set(point1, point1[0] + invCAngle * (this.width) / 2,
            point1[1] - invSAngle * (this.width) / 2, 0);

        math.vec3.set(point3, this.position[0] - cAngle * (this.length) / 2,
            this.position[1] - sAngle * (this.length) / 2, 0);

        math.vec3.set(point4, point3[0] + invCAngle * (this.width) / 2,
            point3[1] - invSAngle * (this.width) / 2, 0);

        math.vec3.set(point3, point3[0] - invCAngle * (this.width) / 2,
            point3[1] + invSAngle * (this.width) / 2, 0);

        points.push(point1);
        points.push(point2);
        points.push(point3);
        points.push(point4);

        return points;
    }

    // Setters and getters for fov and other parameters
    get fov(): number {
        if (this.temporary === null) {
            return (this._fov);
        }
        else {
            return ((this.temporary as TemporaryRenderCamera).fov);
        }
    }

    set fov(f: number) {
        // It is forbidden to touch any camera parameter is it is a photoRender camera
        if (this.cameraType === SceneConstants.CameraType.PhotoRender) {
            return;
        }

        if (this.temporary === null) {
            this._fov = f;
        }
        else {
            (this.temporary as TemporaryRenderCamera).fov = f;
        }
    }

    get fNumber(): number {
        if (this.temporary === null) {
            return (this._fNumber);
        }
        else {
            return ((this.temporary as TemporaryRenderCamera).fNumber);
        }
    }

    set fNumber(fn: number) {
        if (this.temporary === null) {
            this._fNumber = fn;
        }
        else {
            (this.temporary as TemporaryRenderCamera).fNumber = fn;
        }
    }

    get shutterSpeed(): number {
        if (this.temporary === null) {
            return (this._shutterSpeed);
        }
        else {
            return (this.temporary as TemporaryRenderCamera).shutterSpeed;
        }
    }

    set shutterSpeed(sp: number) {
        if (this.temporary === null) {
            this._shutterSpeed = sp;
        }
        else {
            (this.temporary as TemporaryRenderCamera).shutterSpeed = sp;
        }
    }

    get iso(): number {
        if (this.temporary === null) {
            return (this._iso);
        }
        else {
            return ((this.temporary as TemporaryRenderCamera).iso);
        }
    }

    set iso(i: number) {
        if (this.temporary === null) {
            this._iso = i;
        }
        else {
            (this.temporary as TemporaryRenderCamera).iso = i;
        }
    }

    get quality(): number {
        if (this.temporary === null) {
            return this._quality;
        }
        else {
            return ((this.temporary as TemporaryRenderCamera).quality);
        }
    }

    set quality(q: number) {
        if (this.temporary === null) {
            this._quality = q;
        }
        else {
            (this.temporary as TemporaryRenderCamera).quality = q;
        }
    }

    get renderWidth(): number {
        if (this.temporary === null) {
            return this._renderWidth;
        }
        else {
            return ((this.temporary as TemporaryRenderCamera).renderWidth);
        }
    }

    set renderWidth(w: number) {
        if (this.temporary === null) {
            this._renderWidth = w;
        }
        else {
            (this.temporary as TemporaryRenderCamera).renderWidth = w;
        }
    }

    get renderHeight(): number {
        if (this.temporary === null) {
            return this._renderHeight;
        }
        else {
            return ((this.temporary as TemporaryRenderCamera).renderHeight);
        }
    }

    set renderHeight(h: number) {
        if (this.temporary === null) {
            this._renderHeight = h;
        }
        else {
            (this.temporary as TemporaryRenderCamera).renderHeight = h;
        }
    }

    get pitch(): number {
        var vector = math.vec3.create();
        math.vec3.copy(vector, this.transform.globalPosition);
        vector[2] += 1000;
        //Covnert to local of camera
        math.vec3.transformMat4(vector, vector, this.transform.invertedGlobalMatrix);
        math.vec3.normalize(vector, vector);
        var neigh = math.vec3.create();
        math.vec3.set(neigh, 0, 0, 1);
        var angleRag = math.vec3.angle(neigh, vector);
        if (math.vec3.dot(math.vec3.cross([0, 0, 0], vector, neigh), [1, 0, 0]) < 0) {
            angleRag = -angleRag;
        }
        let rounded = angleRag * 180 / Math.PI;

        //Return inverted positive angle
        return -rounded;
    }

    set pitch(p: number) {
        // It is forbidden to touch any camera parameter is it is a photoRender camera
        if (this.locked) {
            return;
        }

        var pitchRad = (this.pitch - p) * Math.PI / 180;
        var angle = this.transform.localZRotation;
        //Make it a global rotation from pivot point
        var axis = math.vec3.create();
        math.vec3.copy(axis, this.transform.globalPosition);
        axis[0] += 1000 * Math.cos(angle);//Set as a z Axis
        axis[1] += 1000 * Math.sin(angle);//Set as a z Axis
        //Convert vector to local space
        var localAxis = math.vec3.create();
        math.vec3.transformMat4(localAxis, axis, this.transform.invertedGlobalMatrix);
        math.vec3.normalize(localAxis, localAxis);
        //Generate rotation around local z axis
        var matrix = math.mat4.create();
        math.mat4.fromRotation(matrix, pitchRad, localAxis);
        math.mat4.multiply(this.transform.localMatrix, this.transform.localMatrix, matrix);
    }

    get angle(): number {
        return this.transform.globalZRotation;
    }

    set angle(a: number) {
        // It is forbidden to touch any camera parameter is it is a photoRender camera
        if (this.locked) {
            return;
        }

        this.transform.globalZRotation = a;
    }

    get height(): number {
        if (this.temporary === null) {
            return (this.transform.globalPosition[2]);
        }
        else {
            return ((this.temporary as TemporaryRenderCamera).transform.globalPosition[2]);
        }
    }

    set height(h: number) {
        // It is forbidden to touch any camera parameter is it is a photoRender camera
        if (this.locked) {
            return;
        }

        if (this.temporary === null) {
            var newPosition = this.transform.globalPosition;
            newPosition[2] = h;
            this.transform.globalPosition = newPosition;
        }
        else {
            var newPosition = (this.temporary as TemporaryRenderCamera).transform.globalPosition;
            newPosition[2] = h;
            (this.temporary as TemporaryRenderCamera).transform.globalPosition = newPosition;
        }
    }

    setRotationZ(rz: number) {
        // It is forbidden to touch any camera parameter is it is a photoRender camera
        if (this.locked) {
            return;
        }

        this.angle = rz;
    }

    // Called when a property is changed to verify max and min boundaries or to perform computations before final assignment
    addToCappedProperty(property: string, value: any) : number {
        // -1 = unknwon property, 0 = no cap, 1 = minus cap, 2 max cap will be returned at the end
        let capped = 0;

        // It is forbidden to touch some parameters of PhotoRender cameras, so lock them
        if (this.locked) {
            if ((property === "fov") || (property === "height") || (property === "pitch") ||
                (property === "renderWidth") || (property === "renderHeight")) {
                return (3);
            }
        }

        if (property === "cameraNb") {
            if (this.cameraNb === 1) {
                return(1);
            }
        }

        if (property === "renderHeight" || property === "renderWidth") {
            if (this.format !== SceneConstants.CameraPreset.Free) {
                capped = 3;
            }
            else {
                if (this.cameraType === SceneConstants.CameraType.Panoramic && property === "renderHeight") {
                    capped = 3;
                }
                else {
                    return super.addToCappedProperty(property, value);
                }
            }
        }
        else {
            return super.addToCappedProperty(property, value);
        }

        return capped;
    }

    /**
     * Create the renderCamera that will be used during edition
     */
    startTemporary() {
        if (!this.temporary) {
            this.temporary = new TemporaryRenderCamera(this);
        }
    }

    /**
     * delete the renderCamera
     */
    endTemporary() {
        this.temporary = null;
    }

    /**
     * save the temporary data in the renderCamera and delete the temporaryArrangement
     */
    saveAndEndTemporary() {
        let matrix = math.mat4.clone(this.transform.localMatrix);
        let fovTemp = this.fov;
        let isoTemp = this.iso;
        let camTypeTemp = this.cameraType;
        let qualityTemp = this.quality;
        let shutterTemp = this.shutterSpeed;
        let fNumberTemp = this.fNumber;
        let cameraNbTemp = this.cameraNb;
        let renderWTemp = this.renderWidth;
        let renderHTemp = this.renderHeight;
        let projectionTemp = this.projection;
        let verticalShiftTemp = this.verticalShift;
        let excludedObjectIdsTemp = this.excludedObjectIds;
        let shoppingListTemp = this.shoppingList;
        let entityShoppingListTemp = this.entityShoppingList;
        let denoising = this.denoising;
        let autoexposure = this.autoexposure;
        let whitebalance = this.whitebalance;
        let exposure = this.exposure;
        let dof = this.dof;
        let aperture = this.aperture;
        let nbImages = this.nbImages;

        this.temporary = null;

        this.fov = fovTemp;
        this.iso = isoTemp;
        this.cameraNb = cameraNbTemp;
        this.projection = projectionTemp;
        this.verticalShift = verticalShiftTemp;
        this.cameraType = camTypeTemp;
        this.quality = qualityTemp;
        this.shutterSpeed = shutterTemp;
        this.fNumber = fNumberTemp;
        this.renderWidth = renderWTemp;
        this.renderHeight = renderHTemp;
        this.excludedObjectIds = excludedObjectIdsTemp;
        this.shoppingList = shoppingListTemp;
        this.entityShoppingList = entityShoppingListTemp;
        this.denoising = denoising;
        this.autoexposure = autoexposure;
        this.whitebalance = whitebalance;
        this.exposure = exposure;
        this.dof = dof;
        this.aperture = aperture;
        this.nbImages = nbImages;
        math.mat4.copy(this.transform.localMatrix, matrix);
    }
}
