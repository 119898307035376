import { Command, CommandEnum } from './CommandModule';
import { Events } from '../events';
import { eventsManager } from '../managers/EventsManager';
import { Coating, ComponentConstants, EntityFactory, Entity, ArrangementObject } from '../SavaneJS';

export class ChangeKitchenCoatingCommand extends Command
{
    private _currentEntities: Array<ArrangementObject>;
    // Array of entities cloned before being modified (to restore in case of undo)
    private _modifiedEntities: Array<ArrangementObject> = [];
    // Coating AM ressource
    private _newCoatingToApply: any;
    private _customPartNameToApply: string;
    private _objectTypeList: Array<string>;
    
    constructor(currentEntities: Array<ArrangementObject>, newCoatingToApply: any, customPartNameToApply: string, objectTypeList: Array<string>) {
        super();
        // Array of entities to modify
        this._currentEntities = currentEntities;
        // New coating to apply to the kitchen entities
        this._newCoatingToApply = newCoatingToApply;
        // Custom part on which to apply the new coating
        this._customPartNameToApply = customPartNameToApply;
        // To avoid dealing with objects that aren't kicthen elements
        this._objectTypeList = objectTypeList;
    }

    static getHangType(customName: string) {
        // Default Caisson
        let hangTypeToFind = Coating.HangType.box;

        if (customName.includes('Porte')) {
            hangTypeToFind = Coating.HangType.door;
        }
        if (customName.includes('Gouttiere')) {
            hangTypeToFind = Coating.HangType.gutter;
        }
        if (customName.includes('HautBas')) {
            hangTypeToFind = Coating.HangType.topBottom;
        }
        if (customName.includes('Evier')) {
            hangTypeToFind = Coating.HangType.sink;
        }
        if (customName.includes('Mitigeur')) {
            hangTypeToFind = Coating.HangType.mixer;
        }
        if (customName.includes('Panneau')) {
            hangTypeToFind = Coating.HangType.panel;
        }
        if (customName.includes('Etagere')) {
            hangTypeToFind = Coating.HangType.shelf;
        }
        if (customName.includes('Fond')) {
            hangTypeToFind = Coating.HangType.back;
        }
        if (customName.includes('Chant')) {
            hangTypeToFind = Coating.HangType.front;
        }
        if (customName.includes('Bouton')) {
            hangTypeToFind = Coating.HangType.button;
        }
        if (customName.includes('Plaque')) {
            hangTypeToFind = Coating.HangType.flatpiece;
        }

        return hangTypeToFind;
    }



    static getDoorCoatingStringFilter(entity: Entity, kitchenType: string) {
        let kitchenFinishingColorsMatching = {
            'IX_blank': ['CG_IXINA_COLORIS_LISSE', 'COLORIS_GENERIQUES'],
            'IX_frame_delia': ['CG_IXINA_COLORIS_CADRE_DELIA', 'COLORIS_GENERIQUES'],
            'IX_frame_suede': ['CG_IXINA_COLORIS_CADRE_SUEDE', 'COLORIS_GENERIQUES'],
            'IX_gutter_line-N': ['CG_IXINA_COLORIS_LINE-N', 'COLORIS_GENERIQUES']
        }

        let result = null;
        if (!entity && kitchenType) {
            if (kitchenFinishingColorsMatching[kitchenType]) {
                result = '&q.customizationGroups.name.in=' + kitchenFinishingColorsMatching[kitchenType].join(',');
                // Add final string to force availability, validation and vrscene
                result += '&q.pipeline.state.eq=available&q.pipeline.modelState.eq=validated';
                return result;
            } else return null;
        }

        if (!entity) return null;

        if (entity.isArrangementObjectEntity() && (entity as ArrangementObject).customization) {
            // Only if expected parts
            if ((entity as ArrangementObject).customization.expectedParts) {
                // Loop variable
                let i;

                // Parse expected parts to find door expected part
                for (i = 0; i < (entity as ArrangementObject).customization.expectedParts.length; i++) {
                    if ((entity as ArrangementObject).customization.expectedParts[i].name.includes('Porte')) {
                        break;
                    }
                }

                // Found ?
                if (i < (entity as ArrangementObject).customization.expectedParts.length) {
                    // Add initial string to filter customizationGroups
                    result = '&q.customizationGroups.name.in=';
                    // Get all groups and create string for filter door coatings
                    for (let j = 0; j < (entity as ArrangementObject).customization.expectedParts[i].groups.length; j++) {
                        // Not first then add a , to the string
                        if (j > 0) {
                            result += ',';
                        }
                        // Add customization group name to the string for door filtering
                        result += encodeURIComponent((entity as ArrangementObject).customization.expectedParts[i].groups[j].name);
                    }
                    // Add final string to force availability, validation and vrscene
                    result += '&q.pipeline.state.eq=available&q.pipeline.modelState.eq=validated'
                }
            }
        }
        return result;
    }

    // Command name returned from a global enum
    name(): string {
        return CommandEnum.ChangeKitchenCoatingCommand;
    }

    // Datas that allows the command to be executed
    execDatas(): any {
        return {
            originalEntities: this._modifiedEntities,
            finalEntities: this._currentEntities,
        };
    }

    needsRefreshHull(): boolean {
        if (this._customPartNameToApply.includes('Porte') || this._customPartNameToApply.includes('Caisson')) {
            return(true);
        }

        return (false);
    }

    cloneAndSaveEntity(item: Entity) {
        this._modifiedEntities.push(EntityFactory.cloneEntity(item, false) as ArrangementObject);
    }

    // Update an arrangement customization and recompute the kitchen style
    // coating is a coating AM ressource and not a savane coating
    updateArrangementCoating(item: ArrangementObject, coating: any, customName: string) {
        // Change coating attached to the arrangement
        let coatings = item.getComponents(ComponentConstants.ComponentType.Coating) as Array<Coating>;
        // HangType to find
        let hangTypeToFind = ChangeKitchenCoatingCommand.getHangType(customName);
        // Expected part index for doors (Porte)
        let expectedPartIndex;

        // Before changing door coating, check that the object style is compatible with it otherwise don't assign it
        if (hangTypeToFind === Coating.HangType.door) {
            // Is the coating customization group id in the object customization group of the expected part ? at start, no
            let found = false;

            // If there are expected parts
            if (item.customization.expectedParts) {
                // Parse all customization expected part and find the door
                for (expectedPartIndex = 0; expectedPartIndex < item.customization.expectedParts.length; expectedPartIndex++) {
                    if (item.customization.expectedParts[expectedPartIndex].name.includes('Porte')) {
                        break;
                    }
                }
                // If expectedPartIndex < length of the expected parts of the object then the door customization expected part was found
                if (expectedPartIndex < item.customization.expectedParts.length) {
                    // Parse both arrays to determine if they both contain the same ID, if so we can assign the coating
                    // Parse object expected parts groups
                    for (let k = 0; k < item.customization.expectedParts[expectedPartIndex].groups.length; k++) {
                        // Parse coating customization groups
                        for (let l = 0; l < coating.customizationGroups.length; l++) {
                            // If they match, we have can assign the coating to the door
                            if (item.customization.expectedParts[expectedPartIndex].groups[k]._id === coating.customizationGroups[l]._id) {
                                // Match found
                                found = true;
                                // Save customization group here for future use in shopping listvier
                                item.customization.customizationGroup = coating.customizationGroups[l];
                                // Quit the loop
                                break;
                            }
                        }

                        // Match found, quit the loop
                        if (found) {
                            break;
                        }
                    }
                }
                else {
                    // Here the door customization group wasn't found, this means there is no constracit so we can assign the coating
                    found = true;
                }

                // No match found between door expected part customization groups and coating customization groups so we can't assign the coating
                if (!found) {
                    // Leave now
                    return;
                }
            }
        }

        // Change customization
        for (let i = 0; i < item.customization.parts.length; i++) {
            if (item.customization.parts[i].name.includes(customName)) {
                item.customization.parts[i].id_coating = coating._id;
                break;
            }
        }

        // Any relevant hangType to find ?
        if (hangTypeToFind !== undefined) {
            // Check each coating
            for (let i = 0; i < coatings.length; i++) {
                // If coating hangType matches the hangType to find
                if (coatings[i].hangType === hangTypeToFind) {
                    // Found and break the loop
                    coatings[i].coatingId = coating._id;
                    if (coating.colors !== undefined) {
                        coatings[i].colors = [coating.colors];
                    }
                    else {
                        coatings[i].colors = coating.configs;
                    }
                    coatings[i].randomization = coating.randomization;
                    if (coating.manufacturer) {
                        coatings[i].manufacturer = {name: coating.manufacturer.name,
                                              _id: coating.manufacturer._id};
                    }
                    else {
                        coatings[i].manufacturer = undefined;
                    }
                    if (coating.retailer) {
                        coatings[i].retailer = {name: coating.retailer.name,
                                          _id: coating.retailer._id};
                    }
                    else {
                        coatings[i].retailer = undefined;
                    }

                    break;
                }
            }
        }

        // If apply to box, check what we have for top/bottom and potentially change it
        if (hangTypeToFind === Coating.HangType.box) {
            // Only if expected parts
            if (item.customization.expectedParts) {
                // Parse all customization expected part and find the topbottom
                for (expectedPartIndex = 0; expectedPartIndex < item.customization.expectedParts.length; expectedPartIndex++) {
                    if (item.customization.expectedParts[expectedPartIndex].name.includes('HautBas')) {
                        this.updateArrangementCoating(item, coating, 'HautBas');
                    }
                }
            }
        }

        // Inform WebGL to update this object by adding it back to the scene
        eventsManager.instance.dispatch(Events.UPDATE_ARRANGEMENT_COATING, item);
    }

    // Restore entity original customization
    restoreEntity(item: ArrangementObject, savedItem: ArrangementObject) {
        item.customization = savedItem.customization;
        item.components = savedItem.components;
        // Inform WebGL to update this object by adding it back to the scene
        eventsManager.instance.dispatch(Events.UPDATE_ARRANGEMENT_COATING, item);
    }

    // Undo the current command
    undo() {
        // Index within _modifiedEntities array
        let modifiedEntitiesIndex = 0;

        // Parse all entities we wanted to modify in the execute operation
        for (let i = 0; i < this._currentEntities.length; ++i) {
            let item = this._currentEntities[i];

            // If not a kitchen object, next object
            if (this._objectTypeList.indexOf(item.objectType) === -1) {
                continue;
            }
            // Restore the original entity saved into the _modifiedEntities array
            this.restoreEntity(item, this._modifiedEntities[modifiedEntitiesIndex++]);
        }

        // Execute parent function
        super.undo();
    }

    // Execute current command (redo)
    execute() {
        // Empty array of entities cloned before being modified. It will be filled when an entity is a real candidate for modification
        this._modifiedEntities = [];

        // Parse all entities to modify
        for (let i = 0; i < this._currentEntities.length; ++i) {
            let item = this._currentEntities[i];

            // If not a kitchen object, next object
            if (this._objectTypeList.indexOf(item.objectType) === -1) {
                continue;
            }
            // Before modifying the entity, clone it so we can restore it with an undo operation
            this.cloneAndSaveEntity(item);
            // Update the coating for this object
            this.updateArrangementCoating(item, this._newCoatingToApply, this._customPartNameToApply);
        }

        //Execute parent function
        super.execute();
    }
};
