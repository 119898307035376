import { Component, CustomCoating, ComponentConstants, EntityFactory, Wall, Joinery, Room, RenderCamera, ArrangementGroup, ArrangementObject, SketchBlock, WorkTop, GeometryPrimitive, Sun, SceneConstants, SavaneConstants, roomManager , math, SavaneMath, Comment} from "../SavaneJS";
import { TechnicalElement } from "./TechElements/TechnicalElement";
import { Staircase } from "./Staircases/Staircase"
import { Entity } from "./Entity";

/**
 * Floor is an entity representing a floor
 *
 * @constructor
 */
export class Floor extends Entity {
    public wallArray: Array<Wall> = null;
    public roomArray: Array<Room> = null;
    public technicalElementArray: Array<TechnicalElement> = null;
    
    constructor(id: number) {
        super();
        this.id = id;
    }

    /**
     * Getter for the Entity type
     *
     */
    get entityType(): SceneConstants.EntityType {
        return SceneConstants.EntityType.Floor;
    }

    /**
     * Getter for the height of the floor
     *
     */
    get height(): number {
        return this.transform.localPosition[2];
    }

    /**
     * Setter for the height of the floor
     *
     * @param {*} newHeight
     */
    set height(nh: number) {
        let newPosition = math.vec3.create();
        newPosition[2] = nh;
        this.transform.localPosition = newPosition;
    }

    get boundingBox(): Array<math.vec3> {
        //Calculate center of all walls
        let walls = this.walls;
        let topLeft = math.vec3.create();
        let botRight = math.vec3.create();
        if (walls.length > 0) {
            topLeft[0] = Math.min(walls[0].begin[0], walls[0].end[0]);
            botRight[0] = Math.max(walls[0].begin[0], walls[0].end[0]);
            topLeft[1] = Math.min(walls[0].begin[1], walls[0].end[1]);
            botRight[1] = Math.max(walls[0].begin[1], walls[0].end[1]);
            for (let i = 0; i < walls.length; i++) {
                topLeft[0] = Math.min(topLeft[0], walls[i].end[0]);
                botRight[0] = Math.max(botRight[0], walls[i].end[0]);
                topLeft[1] = Math.min(topLeft[1], walls[i].end[1]);
                botRight[1] = Math.max(botRight[1], walls[i].end[1]);
                topLeft[0] = Math.min(topLeft[0], walls[i].begin[0]);
                botRight[0] = Math.max(botRight[0], walls[i].begin[0]);
                topLeft[1] = Math.min(topLeft[1], walls[i].begin[1]);
                botRight[1] = Math.max(botRight[1], walls[i].begin[1]);
            }
        }

        let pointB = math.vec3.create();
        let pointC = math.vec3.create();
        math.vec3.set(pointB, topLeft[0], botRight[1], 0);
        math.vec3.set(pointC, botRight[0], topLeft[1], 0);

        return [topLeft, pointB, pointC, botRight];
    }

    /**
     * Add a entity containing a entity wall to the floor
     *
     * @param {Entity} wallEntity
     * @param {Boolean} tryCreateRoom
     * @param {Integer} roomHeight
     * @returns {Room} The room affected by this operation (can be null)
     */
    addWall(wallEntity: Wall, tryCreateRoom: boolean, roomHeight: number, isB2B: boolean): Array<Room> {
        this.wallArray = null;
        if (tryCreateRoom === null || tryCreateRoom === undefined) {
            tryCreateRoom = true;
        }
        let rooms = [];

        let roomContainingWall = null;
        try {
            if (wallEntity.isWallEntity()) {
                this.addChild(wallEntity);

                if (tryCreateRoom) {
                    let isOutsideWall = true;
                    for (let i = 0; i < this.rooms.length; i++) {
                        if (SavaneMath.isWallInRoom(wallEntity, this.rooms[i])) {
                            if (!roomContainingWall) {
                                isOutsideWall = false;
                                roomContainingWall = this.rooms[i];
                                rooms.push(roomContainingWall);
                            } else {
                                if (SavaneMath.isWallInRoom(this.rooms[i].walls[0], roomContainingWall)) {
                                    roomContainingWall = this.rooms[i];
                                    rooms.push(roomContainingWall);
                                }
                            }
                            break;
                        }
                    }
                    if (isOutsideWall) {
                        //try creating a new room from the wall
                        let room = roomManager.tryCreatingRoom(wallEntity, this, roomHeight, undefined, undefined, isB2B);
                        if (room) {
                            rooms.push(room);
                        }
                    } else {
                        let splitRoom = roomManager.getRoomAtPosition(wallEntity.center, this);
                        if (splitRoom) {
                            rooms = roomManager.splitRoom(splitRoom.id, wallEntity, this);
                            if (!rooms) {
                                let room = roomManager.tryCreatingRoom(wallEntity, this, roomHeight, undefined, undefined, isB2B);
                                if (room) {
                                    rooms.push(room);
                                }
                            }
                        } else {
                            //try to create a room contained in an existing room
                            let room = roomManager.tryCreatingRoom(wallEntity, this, roomHeight, undefined, undefined, isB2B);
                            if (room) {
                                rooms.push(room);
                            }
                        }
                    }

                    roomManager.manageNonRoomedWalls(this);
                    return rooms;
                }
            } else {
                throw new TypeError("the parameter is not a wallEntity");
            }
        } catch (err) {
            console.error(err);
        }
        return null;
    }

    /**
     * delete the entity (wall) of id = wallEntityId of the floor
     *
     * @param {Number} wallId
     * @param {Number} tryCreateRoom
     */
    deleteWall(wallId: number, tryCreateRoom: boolean): Array<Room> {
        if (tryCreateRoom === null || tryCreateRoom === undefined) {
            tryCreateRoom = true;
        }
        let wallDeleted = this.getWall(wallId);

        let roomsToUpdate = [];
        let roomsToDelete = wallDeleted.rooms.slice();
        let roomAround = roomManager.deleteNonRoomedWall(wallId, this);
        if (roomAround) {
            roomsToUpdate.push(roomAround);
        }

        let count = this.children.length;
        this.deleteChild(wallId);
        this.wallArray = null;

        for (let i = 0; i < roomsToDelete.length; ++i) {
            this.deleteRoom(roomsToDelete[i].id);
        }

        if (tryCreateRoom) {
            if (roomsToDelete.length === 2) {
                let fusedRoom = roomManager.fuseRoom(wallDeleted, roomsToDelete[0], roomsToDelete[1], this);
                if (fusedRoom) {
                    roomsToUpdate.push(fusedRoom);
                }
            }
        }

        roomManager.manageNonRoomedWalls(this);
        try {
            if (count === this.children.length) {
                throw new TypeError("There is no wallEntity of id = parameter");
            }
        } catch (err) {
            console.log(err);
        }
        return roomsToUpdate;
    }

    /**
     *  Returns the list of walls parts of the compound of the floor
     *
     */
    get compoundWalls(): Array<Wall> {
        let walls = [];
        let floorRooms = this.rooms;
        let floorWalls = this.walls;

        for (let i = 0; i < floorWalls.length; i++) {
            if (floorWalls[i].rooms.length === 1) {
                let toAdd = true;
                for (let j = 0; j < floorRooms.length; j++) {
                    if (SavaneMath.isWallInRoom(floorWalls[i], floorRooms[j])) {
                        toAdd = false;
                    } else if (floorRooms[j].isWallInRoom(floorWalls[i].id) && !floorWalls[i].isRoomedWall) {
                        toAdd = false;
                    }
                }
                if (toAdd) {
                    walls.push(floorWalls[i]);
                }
            }
        }
        return walls;
    }

    /**
     * Returns walls of the floor
     */
    get walls(): Array<Wall> {
        if (!this.wallArray) {
            this.wallArray = [];
            for (let i = 0; i < this.children.length; i++) {
                if (this.children[i].isWallEntity()) {
                    this.wallArray.push((this.children[i] as Wall));
                }
            }
        }
        return this.wallArray;
    }

    /**
     * Getter for the wall of id
     *
     * @param {Number} wallId
     */
    getWall(wallId: number): Wall {
        for (let i = 0; i < this.walls.length; i++) {
            if (this.walls[i].id === wallId) {
                return this.walls[i];
            }
        }
        return null;
    }

    /**
     * Returns all walls which are not parts of the enclosure of a room
     */
    get nonRoomedWalls(): Array<Wall> {
        let walls = [];
        for (let i = 0; i < this.children.length; i++) {
            if (this.children[i].isWallEntity()) {
                if (!(this.children[i] as Wall).isRoomedWall) {
                    walls.push(this.children[i]);
                }
            }
        }
        return walls;
    }

    /**
     * Get all walls that are inside the room and are not nonRoomedWalls
     * @param {Room} room
     */
    getRoomedWallsInsideRoom(room: Room): Array<Wall> {
        let result = [];
        let walls = this.walls;
        for (let i = 0; i < walls.length; ++i) {
            let wall = walls[i];
            if (wall.rooms.length !== 1) {
                // the wall must be in a room at most
                continue;
            }

            if (room.isWallInRoom(wall.id)) {
                // the wall is part of the room
                continue;
            }

            if (!room.isInRoom(wall.center)) {
                // the wall is not in the room
                continue;
            }

            // the wall is in the room
            result.push(wall);
        }
        return result;
    }

    /**
     *  Get all arrangement fix to the walls
     * @param {Number} wallId : id of the walls to test
     * @param {boolean} isDirectSide : whether the test is done on the direct side or the undirect
     * @param {Number} precision :
     */
    getWallDecorations(wallId: number, isDirectSide: boolean, precision = SavaneConstants.PositionTolerance): Array<ArrangementObject> {
        let wall = this.getWall(wallId);
        let objs = this.arrangementObjectsRec;

        let i, res = [];
        if (wall !== null) {
            let bbox = wall.boundingBoxCut;
            let d = math.vec3.create();
            if (isDirectSide) {
                math.vec3.subtract(d, bbox[2], bbox[1]);
            } else {
                math.vec3.subtract(d, bbox[3], bbox[0]);
            }
            let length = math.vec3.length(d);

            for (i = 0; i < objs.length; i++) {
                if ((isDirectSide && SavaneMath.distanceToLine(objs[i].position, bbox[2], bbox[1]) < precision + objs[i].width / 2) || (!isDirectSide && SavaneMath.distanceToLine(objs[i].position, bbox[3], bbox[0]) < precision + objs[i].width / 2)) {
                    let projection;

                    if (isDirectSide) {
                        projection = SavaneMath.getProjection(objs[i].position, bbox[2], bbox[1]);
                    } else {
                        projection = SavaneMath.getProjection(objs[i].position, bbox[3], bbox[0]);
                    }

                    if ((isDirectSide && math.vec3.dist(projection, bbox[2]) < length && math.vec3.dist(projection, bbox[1]) < length) || (!isDirectSide && math.vec3.dist(projection, bbox[3]) < length && math.vec3.dist(projection, bbox[0]) < length)) {
                        res.push(objs[i]);
                    }
                }
            }
        }
        return res;
    }

    /**
     *  Get all technical elements fixed to the walls
     * @param {Number} wallId : id of the walls to test
     * @param {boolean} isDirectSide : whether the test is done on the direct side or the undirect
     * @param {Number} precision :
     */
    getWallTechnicalElements(wallId: number, isDirectSide: boolean, precision = SavaneConstants.PositionTolerance): Array<TechnicalElement> {
        let wall = this.getWall(wallId);
        let objs = this.technicalElementsWithStaircases;

        let i, res = [];
        if (wall !== null) {
            let bbox = wall.boundingBoxCut;
            let d = math.vec3.create();
            if (isDirectSide) {
                math.vec3.subtract(d, bbox[2], bbox[1]);
            } else {
                math.vec3.subtract(d, bbox[3], bbox[0]);
            }
            let length = math.vec3.length(d);

            for (i = 0; i < objs.length; i++) {
                if ((isDirectSide && SavaneMath.distanceToLine(objs[i].position, bbox[2], bbox[1]) < precision + objs[i].width / 2) || (!isDirectSide && SavaneMath.distanceToLine(objs[i].position, bbox[3], bbox[0]) < precision + objs[i].width / 2)) {
                    let projection;

                    if (isDirectSide) {
                        projection = SavaneMath.getProjection(objs[i].position, bbox[2], bbox[1]);
                    } else {
                        projection = SavaneMath.getProjection(objs[i].position, bbox[3], bbox[0]);
                    }

                    if ((isDirectSide && math.vec3.dist(projection, bbox[2]) < length && math.vec3.dist(projection, bbox[1]) < length) || (!isDirectSide && math.vec3.dist(projection, bbox[3]) < length && math.vec3.dist(projection, bbox[0]) < length)) {
                        res.push(objs[i]);
                    }
                }
            }
        }
        return res;
    }

    /**
     *  Get all arrangement fix to the walls
     * @param {Number} wallId : id of the walls to test
     * @param {boolean} isDirectSide : whether the test is done on the direct side or the undirect
     * @param {*} position : point to test
     * @param {Number} precision :
     */
    getWallDecorationAtPosition(wallId: number, isDirectSide: boolean, position, precision = SavaneConstants.PositionTolerance): ArrangementObject {
        let wallDecos = this.getWallDecorations(wallId, isDirectSide, precision),
            wall = this.getWall(wallId),
            testPoint = math.vec3.clone(position),
            points = [math.vec3.create(), math.vec3.create(), math.vec3.create(), math.vec3.create()],
            objPosition,
            obj;

        if (!wall) {
            return null;
        }

        let bbox = wall.boundingBoxCut;
        let d = math.vec3.create();
        if (isDirectSide) {
            math.vec3.subtract(d, bbox[2], bbox[1]);
        } else {
            math.vec3.subtract(d, bbox[3], bbox[0]);
        }
        let length = math.vec3.length(d);
        let begin = bbox[1];
        let end = bbox[0];

        let wallTechnicalElements = this.getWallTechnicalElements(wallId, isDirectSide, precision);
        testPoint[1] = testPoint[2];
        testPoint[2] = 0;
        if (position[0] >= 0 && position[0] <= length && position[2] >= 0 && position[2] <= wall.height) {
            let i;
            for (i = 0; i < wall.joineries.length; i++) {
                obj = wall.joineries[i];
                objPosition = SavaneMath.getProjection(obj.position, wall.begin, wall.end);

                let objLength = 0;
                if (isDirectSide) {
                    math.vec3.set(objPosition, objPosition[0] - begin[0], objPosition[1] - begin[1], 0);
                    objLength = math.vec3.length(objPosition);
                } else {
                    math.vec3.set(objPosition, objPosition[0] - end[0], objPosition[1] - end[1], 0);
                    objLength = length - math.vec3.length(objPosition);
                }

                if (obj.joineryType === SceneConstants.JoineryType.velux) {
                    math.vec3.set(points[0], objLength - obj.length / 2, obj.position[2] - obj.getVerticalHeight() / 2, 0);
                    math.vec3.set(points[1], objLength - obj.length / 2, obj.position[2] + obj.getVerticalHeight() / 2, 0);
                    math.vec3.set(points[2], objLength + obj.length / 2, obj.position[2] + obj.getVerticalHeight() / 2, 0);
                    math.vec3.set(points[3], objLength + obj.length / 2, obj.position[2] - obj.getVerticalHeight() / 2, 0);
                } else {
                    math.vec3.set(points[0], objLength - obj.length / 2, obj.floorHeight, 0);
                    math.vec3.set(points[1], objLength - obj.length / 2, obj.floorHeight + obj.height, 0);
                    math.vec3.set(points[2], objLength + obj.length / 2, obj.floorHeight + obj.height, 0);
                    math.vec3.set(points[3], objLength + obj.length / 2, obj.floorHeight, 0);
                }

                if (SavaneMath.isInPoly(testPoint, points)) {
                    return obj;
                }
            }
            for (i = 0; i < wallTechnicalElements.length; i++) {
                obj = wallTechnicalElements[i];
                // Ignore staircases
                if (obj.isStaircaseEntity()) {
                    continue;
                }
                objPosition = SavaneMath.getProjection(obj.position, wall.begin, wall.end);

                let objLength = 0;
                if (isDirectSide) {
                    math.vec3.set(objPosition, objPosition[0] - begin[0], objPosition[1] - begin[1], 0);
                    objLength = math.vec3.length(objPosition);
                } else {
                    math.vec3.set(objPosition, objPosition[0] - end[0], objPosition[1] - end[1], 0);
                    objLength = length - math.vec3.length(objPosition);
                }

                math.vec3.set(points[0], objLength - obj.length / 2, obj.floorHeight - wall.begin[2], 0);
                math.vec3.set(points[1], objLength - obj.length / 2, obj.floorHeight + obj.height - wall.begin[2], 0);
                math.vec3.set(points[2], objLength + obj.length / 2, obj.floorHeight + obj.height - wall.begin[2], 0);
                math.vec3.set(points[3], objLength + obj.length / 2, obj.floorHeight - wall.begin[2], 0);

                if (SavaneMath.isInPoly(testPoint, points)) {
                    return obj;
                }
            }
            for (i = 0; i < wallDecos.length; i++) {
                obj = wallDecos[i];
                objPosition = SavaneMath.getProjection(obj.position, wall.begin, wall.end);

                let objLength = 0;
                if (isDirectSide) {
                    math.vec3.set(objPosition, objPosition[0] - begin[0], objPosition[1] - begin[1], 0);
                    objLength = math.vec3.length(objPosition);
                } else {
                    math.vec3.set(objPosition, objPosition[0] - end[0], objPosition[1] - end[1], 0);
                    objLength = length - math.vec3.length(objPosition);
                }

                math.vec3.set(points[0], objLength - obj.length / 2, obj.position[2] - obj.height / 2 - wall.begin[2], 0);
                math.vec3.set(points[1], objLength - obj.length / 2, obj.position[2] + obj.height / 2 - wall.begin[2], 0);
                math.vec3.set(points[2], objLength + obj.length / 2, obj.position[2] + obj.height / 2 - wall.begin[2], 0);
                math.vec3.set(points[3], objLength + obj.length / 2, obj.position[2] - obj.height / 2 - wall.begin[2], 0);
                if (SavaneMath.isInPoly(testPoint, points)) {
                    while (obj.parent.isArrangementObjectEntity() || obj.parent.isArrangementGroupEntity()) {
                        obj = obj.parent;
                    }
                    return obj;
                }
            }
        }
        return null;
    }

    /**
     *  Returns joineries of the floor
     */
    get joineries(): Array<Joinery> {
        let walls = this.walls;
        let joineries = [];
        for (let i = 0; i < walls.length; i++) {
            for (let j = 0; j < walls[i].joineries.length; j++) {
                if (walls[i].joineries[j].isJoineryEntity()) {
                    joineries.push(walls[i].joineries[j]);
                }
            }
        }
        return joineries;
    }

    /**
     *  Returns rooms of the floor
     */
    get rooms(): Array<Room> {
        if (this.roomArray === null) {
            this.roomArray = [];
            for (let i = 0; i < this.children.length; i++) {
                if (this.children[i].isRoomEntity()) {
                    this.roomArray.push((this.children[i] as Room));
                }
            }
        }
        return this.roomArray;
    }

    /**
     * return the list of all the object in the floor (in groups as well)
     *
     * @returns {Array}
     */
    get arrangementObjectsRec(): Array<ArrangementObject> {
        let arrangementArray = [];
        let room;
        for (let i = 0; i < this.children.length; i++) {
            if (this.children[i].isArrangementObjectEntity()) {
                arrangementArray.push(this.children[i]);
            } else if (this.children[i].isRoomEntity()) {
                //Look deeper inside room
                room = this.children[i];
                for (let j = 0; j < room.children.length; j++) {
                    if (room.children[j].isArrangementObjectEntity()) {
                        arrangementArray.push(room.children[j]);
                    } else if (room.children[j].isArrangementGroupEntity()) {
                        arrangementArray = arrangementArray.concat(room.children[j].arrangementObjectsRec);
                    }
                }
            } else if (this.children[i].isArrangementGroupEntity()) {
                arrangementArray = arrangementArray.concat((this.children[i] as ArrangementGroup).arrangementObjectsRec);
            }
        }
        return arrangementArray;
    }

    /**
     * return the list of all the object in the floor
     *
     * @returns {Array}
     */
    get arrangementObjects(): Array<ArrangementObject> {
        let arrangementArray = [];
        let room;
        for (let i = 0; i < this.children.length; i++) {
            if (this.children[i].isArrangementObjectEntity()) {
                arrangementArray.push(this.children[i]);
            } else if (this.children[i].isArrangementGroupEntity()) {
                arrangementArray = arrangementArray.concat((this.children[i] as ArrangementGroup).arrangementObjectsRec);
            } else if (this.children[i].isRoomEntity()) {
                //Look deeper inside room
                room = this.children[i];
                for (let j = 0; j < room.children.length; j++) {
                    if (room.children[j].isArrangementObjectEntity()) {
                        arrangementArray.push(room.children[j]);
                    } else if (room.children[j].isArrangementGroupEntity()) {
                        arrangementArray = arrangementArray.concat((room.children[j] as ArrangementGroup).arrangementObjectsRec);
                    }
                }
            }
        }

        return arrangementArray;
    }

    /**
     * return the list of arrangementGroup use in the scene
     *
     * @returns {Array}
     */
    get arrangementGroups(): Array<ArrangementGroup> {
        let arrangementGroupArray = [];
        for (let i = 0; i < this.children.length; i++) {
            if (this.children[i].isArrangementGroupEntity()) {
                arrangementGroupArray.push(this.children[i]);
                arrangementGroupArray = arrangementGroupArray.concat((this.children[i] as ArrangementGroup).arrangementGroups);
            } else if (this.children[i].isRoomEntity()) {
                arrangementGroupArray = arrangementGroupArray.concat((this.children[i] as Room).arrangementGroups);
            }
        }

        return arrangementGroupArray;
    }

    /**
     * return the list of all IArrangement  in the floor
     *
     * @returns {Array}
     */
    get arrangementObjectsAndGroups(): Array<ArrangementObject | ArrangementGroup> {
        let arrangements = [];
        for (let i = 0; i < this.children.length; i++) {
            if (this.children[i].isArrangementObjectEntity() || this.children[i].isArrangementGroupEntity()) {
                arrangements.push(this.children[i]);
            } else if (this.children[i].isRoomEntity()) {
                for (let j = 0; j < this.children[i].children.length; j++) {
                    let subChild = this.children[i].children[j];
                    if (subChild.isArrangementObjectEntity() || subChild.isArrangementGroupEntity()) {
                        arrangements.push(subChild);
                    }
                }
            }
        }
        return arrangements;
    }

    /**
     * return the list of all IArrangement  in the floor
     *
     * @returns {Array}
     */
    get iArrangements(): Array<ArrangementObject | ArrangementGroup | TechnicalElement | Staircase> {
        let arrangements = [];
        for (let i = 0; i < this.children.length; i++) {
            if (this.children[i].isArrangementObjectEntity() || this.children[i].isArrangementGroupEntity() || this.children[i].isTechnicalElementEntity() || this.children[i].isStaircaseEntity()) {
                arrangements.push(this.children[i]);
            } else if (this.children[i].isRoomEntity()) {
                for (let j = 0; j < this.children[i].children.length; j++) {
                    let subChild = this.children[i].children[j];
                    if (subChild.isArrangementObjectEntity() || subChild.isArrangementGroupEntity() || subChild.isTechnicalElementEntity() || subChild.isStaircaseEntity()) {
                        arrangements.push(subChild);
                    }
                }
            }
        }
        return arrangements;
    }

    /**
     * return the list of all sketchBlocks in the floor
     *
     * @returns {Array}
     */
    get sketchBlocks(): Array<SketchBlock> {
        let sketchBlocks = [];
        for (let i = 0; i < this.children.length; i++) {
            if (this.children[i].isSketchBlockEntity()) {
                sketchBlocks.push(this.children[i]);
            } else if (this.children[i].isRoomEntity()) {
                for (let j = 0; j < this.children[i].children.length; j++) {
                    let subChild = this.children[i].children[j];
                    if (subChild.isSketchBlockEntity()) {
                        sketchBlocks.push(subChild);
                    }
                }
            }
        }
        return sketchBlocks;
    }

    /**
     * return the list of all renderCamera  in the floor
     *
     * @returns {Array}
     */
    get renderCameras(): Array<RenderCamera> {
        let cameras = [];

        let allDescendants = this.getChildren([SceneConstants.EntityType.RenderCamera]);
        for (let i = 0; i < allDescendants.length; i++) {
            if (allDescendants[i].isRenderCameraEntity()) {
                cameras.push(allDescendants[i]);
            }
        }
        return cameras;
    }

    /**
     * return the list of all worktops  in the floor
     *
     * @returns {Array}
     */
    get worktops(): Array<WorkTop> {
        let worktopList = [];

        let allDescendants = this.getChildren([SceneConstants.EntityType.WorkTop]);
        for (let i = 0; i < allDescendants.length; i++) {
            if (allDescendants[i].isWorktopEntity()) {
                worktopList.push(allDescendants[i]);
            }
        }
        return worktopList;
    }

    get arrangementLights(): Array<Entity> {
        let result = [];

        let arrangements = this.arrangementObjectsRec;
        for (let i = 0; i < arrangements.length; ++i) {
            if (arrangements[i].lightOn != null || arrangements[i].lightOn != undefined) {
                result.push(arrangements[i]);
            }
        }
        let technicalElements = this.technicalElements;
        for (let i = 0; i < technicalElements.length; ++i) {
            if (technicalElements[i].lightOn != null || technicalElements[i].lightOn != undefined) {
                result.push(technicalElements[i]);
            }
        }

        return result;
    }

    /**
     * return the list of all comments  in the floor
     *
     * @returns {Array}
     */
    get comments(): Array<Comment> {
        let comments = [];
        for (let i = 0; i < this.children.length; i++) {
            if (this.children[i].isCommentEntity()) {
                comments.push(this.children[i]);
            }
        }
        return comments;
    }

    get coatings(): Array<Component> {
        let coatingArray = [];

        let j, k;
        let childCoatings;
        let walls = this.walls;
        let rooms = this.rooms;
        let joineries = this.joineries;
        let techelems = this.technicalElementsWithStaircases;

        for (j = 0; j < walls.length; j++) {
            childCoatings = walls[j].getComponents(ComponentConstants.ComponentType.Coating);

            for (k = 0; k < childCoatings.length; k++) {
                coatingArray.push(childCoatings[k]);
            }

            childCoatings = walls[j].getComponents(ComponentConstants.ComponentType.CoatingArea);

            for (k = 0; k < childCoatings.length; k++) {
                coatingArray.push(childCoatings[k]);
            }
        }
        for (j = 0; j < rooms.length; j++) {
            childCoatings = rooms[j].getComponents(ComponentConstants.ComponentType.Coating);

            for (k = 0; k < childCoatings.length; k++) {
                coatingArray.push(childCoatings[k]);
            }
        }
        for (j = 0; j < joineries.length; j++) {
            childCoatings = joineries[j].getComponents(ComponentConstants.ComponentType.Coating);

            for (k = 0; k < childCoatings.length; k++) {
                coatingArray.push(childCoatings[k]);
            }
        }
        for (j = 0; j < techelems.length; j++) {
            childCoatings = techelems[j].getComponents(ComponentConstants.ComponentType.Coating);

            for (k = 0; k < childCoatings.length; k++) {
                coatingArray.push(childCoatings[k]);
            }
        }
        childCoatings = this.getComponents(ComponentConstants.ComponentType.CustomCoating);
        for (k = 0; k < childCoatings.length; k++) {
            coatingArray.push(childCoatings[k].coating);
        }
        childCoatings = this.getComponents(ComponentConstants.ComponentType.FloorCoatingArea);
        for (k = 0; k < childCoatings.length; k++) {
            coatingArray.push(childCoatings[k]);
        }

        return coatingArray;
    }

    /**
     * Add a entity containing a entity geometry primitive to the floor
     *
     * @param {Entity}geomPrimElement
     */
    addGeometryPrimitive(geomPrimElement: GeometryPrimitive) {
        this.addChild(geomPrimElement);
    }

    /**
     * return the list of all comments  in the floor
     *
     * @returns {Array}
     */
    get geometryPrimitives(): Array<GeometryPrimitive> {
        let geomPrims = [];

        let allDescendants = this.getChildren([SceneConstants.EntityType.GeometryPrimitive]);
        for (let i = 0; i < allDescendants.length; i++) {
            if (allDescendants[i].isGeometryPrimitiveEntity()) {
                geomPrims.push(allDescendants[i]);
            }
        }
        return geomPrims;
    }

    /**
     * Add a entity containing a entity technicalElement to the floor
     *
     * @param {Entity} technicalElement
     */
    addTechnicalElement(technicalElement: TechnicalElement) {
        this.addChild(technicalElement);
        this.technicalElementArray = null;
    }

    /**
     * delete a entity technicalElement of the floor
     *
     * @param {Number} id
     */
    deleteTechnicalElement(id: number) {
        this.deleteChild(id);
        this.technicalElementArray = null;
    }

    addStaircase(staircase: Staircase) {
        this.addChild(staircase);
        this.technicalElementArray = null;
    }

    deleteStaircase(id: number) {
        this.deleteChild(id);
        this.technicalElementArray = null;
    }

    /**
     * return the list of all the technicalElement in the floor
     *  FIXME I disabled lazy get because to merge multiple behaviour with arrangement and others, we may need to reduce call
     *
     * @returns {Array}
     */
    get technicalElementsWithStaircases(): Array<TechnicalElement> {
        this.technicalElementArray = [];
        for (let i = 0; i < this.children.length; i++) {
            if (this.children[i].isTechnicalElementEntity()) {
                this.technicalElementArray.push(this.children[i] as TechnicalElement);
            }
            if (this.children[i].isStaircaseEntity()) {
                this.technicalElementArray.push(this.children[i] as Staircase);
            }
        }
        return this.technicalElementArray;
    }

    get technicalElements(): Array<TechnicalElement> {
        let technicalElementList = [];
        for (let i = 0; i < this.children.length; i++) {
            if (this.children[i].isTechnicalElementEntity()) {
                technicalElementList.push(this.children[i]);
            }
        }
        return technicalElementList;
    }

    get stairCases(): Array<Staircase> {
        let stairCaseList = [];
        for (let i = 0; i < this.children.length; i++) {
            if (this.children[i].isStaircaseEntity()) {
                stairCaseList.push(this.children[i]);
            }
        }
        return stairCaseList;
    }

    /**
     * get the room of id == parameter inside this floor
     *
     * @param {*} id
     */
    getRoom(id: number): Room {
        let currentFloorRooms = this.rooms;
        for (let i = 0; i < currentFloorRooms.length; i++) {
            if (currentFloorRooms[i].id === id) {
                return currentFloorRooms[i];
            }
        }
        return null;
    }

    /**
     * add a room of the floor. The roomEntity is also added for all wall in the room
     *
     * @param  {Room} roomEntity
     */
    addRoom(room: Room) {
        try {
            if (room.isRoomEntity()) {
                for (let i = 0; i < room.walls.length; i++) {
                    room.walls[i].addRoom(room);
                    room.walls[i].isRoomedWall = true;
                }
                this.addChild(room);
            } else {
                throw new TypeError("the parameter is not a roomEntity");
            }
        } catch (err) {
            console.log(err);
        }
        this.roomArray = null;
    }

    /**
     * add a new sun to the floor
     */
    addSun() {
        let sun = EntityFactory.createSun();
        this.addChild(sun);
    }

    hasSun(): boolean {
        for (let i = 0; i < this.children.length; ++i) {
            let child = this.children[i];
            if (child.isSunEntity()) {
                return true;
            }
        }
        return false;
    }

    getSun(): Sun {
        for (let i = 0; i < this.children.length; ++i) {
            let child = this.children[i];
            if (child.isSunEntity()) {
                return (child as Sun);
            }
        }
        return null;
    }

    getCustomCoating(name: string): CustomCoating {
        for (let i = 0; i < this.components.length; ++i) {
            let component = this.components[i];
            if (component.componentType == ComponentConstants.ComponentType.CustomCoating && component.name === name) {
                return (component as CustomCoating);
            }
        }

        return null;
    }

    /**
     * delete a room of the floor. The roomEntity is also deleted for all wall in the room
     *
     * @param {Number} id
     */
    deleteRoom(id: number) {
        let deletedRoom = this.getRoom(id);
        if (deletedRoom === null) {
            return;
        }
        for (let j = 0; j < deletedRoom.walls.length; j++) {
            deletedRoom.walls[j].deleteRoom(id);
            if (deletedRoom.walls[j].rooms.length === 0) {
                deletedRoom.walls[j].isRoomedWall = false;
            }
        }
        this.deleteChild(id);
        this.roomArray = null;
    }

    /**
     *  Delete every children
     */
    deleteAllChild() {
        super.deleteAllChild();
        this.wallArray = null;
        this.roomArray = null;
    }

    /**
     * getFloorMaxRoomHeight parse all rooms of a floor and returns the highest height
     * @param floor to give the height of
     * @constructor
     */
    getFloorMaxRoomHeight(): number{
        let maxHeight = 0;

        if (this.rooms.length === 0) {
            return SceneConstants.DefaultWallHeight;
        } else {
            for (let i = 0; i < this.rooms.length; i++) {
                if (this.rooms[i].height > maxHeight) {
                    maxHeight = this.rooms[i].height;
                }
            }
        }

        return maxHeight;
    }

    /**
     * GetUpperFloors parse all floors and return the list of floors upper than the one passed in parameter
     * @param floor to give the height of
     * @constructor
     */
    getUpperFloors(): Array<Floor> {
        let floors = this.scene.floors;
        let currentFloorHeight = this.height;
        let upperFloors = [];

        for (let i = 0; i < floors.length; i++) {
            if (floors[i].height > currentFloorHeight) {
                upperFloors.push(floors[i]);
            }
        }

        return upperFloors;
    }

    getUpperFloor(): Floor {
        let floors = this.scene.floors;
        let currentFloorHeight = this.height;
        let minFloorHeight = currentFloorHeight;
        let upperFloor = null;

        for (let i = 0; i < floors.length; i++) {
            if (floors[i].height > currentFloorHeight) {
                if (minFloorHeight === currentFloorHeight) {
                    minFloorHeight = floors[i].height;
                    upperFloor = floors[i];
                } else {
                    if (floors[i].height < minFloorHeight) {
                        minFloorHeight = floors[i].height;
                        upperFloor = floors[i];
                    }
                }
            }
        }

        return upperFloor;
    }

    /**
     * GetLowerFloors parse all floors and return the list of floors lower than the one passed in parameter
     * @param floor to give the height of
     * @constructor
     */
    getLowerFloors(): Array<Floor> {
        let floors = this.scene.floors;
        let currentFloorHeight = this.height;
        let lowerFloors = [];

        for (let i = 0; i < floors.length; i++) {
            if (floors[i].height < currentFloorHeight) {
                lowerFloors.push(floors[i]);
            }
        }

        return lowerFloors;
    }

    /**
     * readjustFloorsHeight after a change in a room height
     * @param floor to readjust height and apply to upper or lower floors depending on its position in the scene
     * @constructor
     */
    readjustFloorsHeight() {
        let upperFloor = this.getUpperFloor();
        let deltaHeight;

        if (upperFloor === null) {
            return;
        }

        if (this.height >= 0) {
            deltaHeight = this.getFloorMaxRoomHeight() + SceneConstants.DefaultInterfloorHeight - (upperFloor.height - this.height);
        } else {
            deltaHeight = this.getFloorMaxRoomHeight() + SceneConstants.DefaultInterfloorHeight + (this.height - upperFloor.height);
        }

        if (deltaHeight === 0) {
            return;
        }

        if (this.height >= 0) {
            let upperFloors = this.getUpperFloors();

            for (let i = 0; i < upperFloors.length; i++) {
                upperFloors[i].height += deltaHeight;
            }
        } else {
            this.height -= deltaHeight;

            let lowerFloors = this.getLowerFloors();

            for (let i = 0; i < lowerFloors.length; i++) {
                lowerFloors[i].height -= deltaHeight;
            }
        }
    }

    // get all children in the floor in a axis-aligned rectangle
    getEntitiesInRectangle(topLeft: math.vec2, bottomRight: math.vec2, entityTypes: Array<SceneConstants.EntityType>, entityTypesToBrowse: Array<SceneConstants.EntityType>): Array<Entity> {
        let entities = this.getChildren(entityTypes, entityTypesToBrowse).filter((entity) => {
            return entity.position[0] >= topLeft[0] && entity.position[0] <= bottomRight[0] && entity.position[1] <= topLeft[1] && entity.position[1] >= bottomRight[1];
        });

        return entities;
    }
}
