import { ComponentConstants } from '../SavaneJS';
import { Component } from './Component';

export class WallType extends Component {
    protected _wallTypeId: string;

    constructor(amid: string) {
        super();
        this._wallTypeId  = amid;
        this._isUnique    = true;
    }

    clone(): WallType {
        return new WallType(this._wallTypeId);
    }

    get componentType(): ComponentConstants.ComponentType {
        return ComponentConstants.ComponentType.WallType;
    }

    get wallTypeId(): string {
        return this._wallTypeId;
    }

    set wallTypeId(amid: string) {
        this._wallTypeId = amid;
    }
}
