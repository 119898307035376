import { HermiteCurve } from "../../math/curves/hermiteCurve";
import { HermitePoint } from "../../math/curves/hermitePoint";
import { CameraKeyPoint } from "./CameraKeyPoint";
import { EntityFactory } from "../../scene/EntityFactory";
import { RenderCamera } from "../../scene/RenderCamera";
import * as glMatrix from 'gl-matrix'
import { Animation } from "../Animation";

export class CameraAnimation extends Animation
{
    private _cameraKeyPoints: Array<CameraKeyPoint> = new Array<CameraKeyPoint>();
    private _duration: number;
    private _framesPerSecond: number;

    constructor(id: number) {
        super(id);
        this._duration = 4;
        this._framesPerSecond = 12;
    }

    get KeyPoints(): Array<CameraKeyPoint> {
        return this._cameraKeyPoints;
    }

    get Duration(): number {
        return this._duration;
    }

    set Duration(value: number) {
        this._duration = value;
    }

    get FramesPerSecond(): number {
        return this._framesPerSecond;
    }

    set FramesPerSecond(value: number) {
        this._framesPerSecond = value;
    }

    AddKeyPoint(keyPoint: CameraKeyPoint) {
        this._cameraKeyPoints.push(keyPoint);
    }

    RemoveKeyPoint(keyPointOrIndex: CameraKeyPoint | number) {
        if (typeof keyPointOrIndex === 'number') {
            if (keyPointOrIndex < 0 || keyPointOrIndex >= this._cameraKeyPoints.length) {
                throw("RemoveKeyPoint out of range");
            }
            this._cameraKeyPoints.splice(keyPointOrIndex, 1);
        } else {
            const index = this._cameraKeyPoints.indexOf(keyPointOrIndex);
            if (index !== -1) {
                this._cameraKeyPoints.splice(index, 1);
            } 
        }
    }

    Evaluate(t: number) {
        let current = Math.floor(t);
        let next = Math.ceil(t);

        if (current < 0 || current >= this._cameraKeyPoints.length) {
            throw("Evaluate out of range");
        }

        if (next < 0 || next >= this._cameraKeyPoints.length) {
            throw("Evaluate out of range");
        }

        const startKeyPoint: CameraKeyPoint = this._cameraKeyPoints[current];
        const endKeyPoint: CameraKeyPoint = this._cameraKeyPoints[next];

        let startCurve: HermitePoint = new HermitePoint(startKeyPoint.Camera.position, startKeyPoint.StartTangent);
        let endCurve: HermitePoint = new HermitePoint(endKeyPoint.Camera.position, endKeyPoint.EndTangent);

        let curve: HermiteCurve = new HermiteCurve(startCurve, endCurve);
        let position = curve.Evaluate(t - current);

        let camera: RenderCamera = EntityFactory.cloneEntity(startKeyPoint.Camera, true) as RenderCamera;

        let rStart: glMatrix.mat3 = glMatrix.mat3.create();
        glMatrix.mat3.fromMat4(rStart, startKeyPoint.Camera.transform.localMatrix);

        let rEnd: glMatrix.mat3 = glMatrix.mat3.create();
        glMatrix.mat3.fromMat4(rEnd, endKeyPoint.Camera.transform.localMatrix);

        let qStart: glMatrix.quat = glMatrix.quat.create();
        glMatrix.quat.fromMat3(qStart, rStart);

        let qEnd: glMatrix.quat = glMatrix.quat.create();
        glMatrix.quat.fromMat3(qEnd, rEnd);

        let quaternion: glMatrix.quat = glMatrix.quat.create();
        glMatrix.quat.lerp(quaternion, qStart, qEnd, t - current);

        glMatrix.mat4.fromRotationTranslation(camera.transform.localMatrix, quaternion, position);
        return camera;
    }
}
