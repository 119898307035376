import { Command, CommandEnum } from './CommandModule';
import { Events } from '../events';
import { eventsManager } from '../managers/EventsManager';
import { Component, CorniceType, JoineryType, TechnicalElementType, WallType, ComponentConstants, SavaneConstants, math } from '../SavaneJS';

export class EditComponentCommand  extends Command
{
    private _editedComponent: Component;
    private _params: any;

    constructor(editedComponent: Component, params: any) {
        super();
        this._editedComponent = editedComponent;
        this._params = JSON.parse(JSON.stringify(params));
    }

    name(): string {
        return CommandEnum.EditComponentCommand;
    }

    // Datas that allows the command to be executed
    execDatas(): any {
        return {
            component: this._editedComponent,
        };
    }

    exchangeParameters(componentType: ComponentConstants.ComponentType) {
        let tmp;

        switch (componentType) {
            case ComponentConstants.ComponentType.JoineryType:
                tmp = { _id: (this._editedComponent as JoineryType).joineryTypeId, manufacturer: (this._editedComponent as JoineryType).manufacturer, retailer: (this._editedComponent as JoineryType).retailer };
                (this._editedComponent as JoineryType).joineryTypeId = this._params._id;
                (this._editedComponent as JoineryType).manufacturer = this._params.manufacturer;
                (this._editedComponent as JoineryType).retailer = this._params.retailer;
                this._params._id = tmp._id;
                this._params.manufacturer = tmp.manufacturer;
                this._params.retailer = tmp.retailer;
                break;

            case ComponentConstants.ComponentType.TechnicalElementType:
                tmp = (this._editedComponent as TechnicalElementType).technicalElementTypeId;
                (this._editedComponent as TechnicalElementType).technicalElementTypeId = this._params;
                this._params = tmp;
                break;

            case ComponentConstants.ComponentType.WallType:
                tmp = (this._editedComponent as WallType).wallTypeId;
                (this._editedComponent as WallType).wallTypeId = this._params;
                this._params = tmp;
                break;

            case ComponentConstants.ComponentType.CorniceType:
                tmp = (this._editedComponent as CorniceType).corniceTypeId;
                (this._editedComponent as CorniceType).corniceTypeId = this._params;
                this._params = tmp;
                break;
        }
    }

    undo() {
        switch (this._editedComponent.componentType) {
            case ComponentConstants.ComponentType.Area:
            case ComponentConstants.ComponentType.AddArea:
            case ComponentConstants.ComponentType.CutArea:
            case ComponentConstants.ComponentType.CoatingArea:
            case ComponentConstants.ComponentType.FloorCoatingArea:
                // Parse all parameters
                for (let i = this._params.length - 1; i >= 0; i--) {
                    // Undo point added
                    if (this._params[i].addedPoint !== undefined && this._params[i].addedPoint !== null) {
                        (this._editedComponent as any)._vertices.splice(this._params[i].index, 1);
                    }

                    // Undo edited point
                    if (this._params[i].begin !== undefined) {
                        //legs!!!
                        if (this._editedComponent.entity !== null && this._editedComponent.entity.isWorktopEntity()) {
                            let legs = (this._editedComponent as any)._entity.legs;

                            for (let j = 0; j < legs.length; j++) {
                                if (legs[j].areaBeginIndex === this._params[i].index) {
                                    legs[j].begin = math.vec3.clone(this._params[i].begin);
                                }
                                else {
                                    if (legs[j].areaEndIndex === this._params[i].index) {
                                        legs[j].end = math.vec3.clone(this._params[i].begin);
                                    }
                                }
                            }

                            let credences = (this._editedComponent as any)._entity.credences;

                            for (let j = 0; j < credences.length; j++) {
                                if (credences[j].areaBeginIndex === this._params[i].index) {
                                    credences[j].begin = math.vec3.clone(this._params[i].begin);
                                }
                                else {
                                    if (credences[j].areaEndIndex === this._params[i].index) {
                                        credences[j].end = math.vec3.clone(this._params[i].begin);
                                    }
                                }
                            }
                        }
                        (this._editedComponent as any)._vertices[this._params[i].index] = this._params[i].begin;
                    }

                    // Undo add Credence
                    if (this._params[i].credenceBegin !== undefined) {
                        let credences = (this._editedComponent as any)._entity.credences;

                        for (let j = 0; j < credences.length; j++) {
                            if (math.vec3.dist(credences[j].begin, this._params[i].credenceBegin) < SavaneConstants.PositionTolerance &&
                                math.vec3.dist(credences[j].end, this._params[i].credenceEnd) < SavaneConstants.PositionTolerance) {
                                (this._editedComponent as any)._entity.deleteCredence(j);
                            }
                        }
                    }

                    // Undo delete Credence
                    if (this._params[i].deletedCredence !== undefined) {
                        (this._editedComponent as any)._entity.addCredence(this._params[i].deletedCredenceBegin, this._params[i].deletedCredenceEnd);
                    }

                    // Undo add Leg
                    if (this._params[i].legBegin !== undefined) {
                        let legs = (this._editedComponent as any)._entity.legs;

                        for (let j = 0; j < legs.length; j++) {
                            if (math.vec3.dist(legs[j].begin, this._params[i].legBegin) < SavaneConstants.PositionTolerance &&
                                math.vec3.dist(legs[j].end, this._params[i].legEnd) < SavaneConstants.PositionTolerance) {
                                (this._editedComponent as any)._entity.deleteLeg(j);
                            }
                        }
                    }

                    // Undo delete Leg
                    if (this._params[i].deletedLeg !== undefined) {
                        (this._editedComponent as any)._entity.addLeg(this._params[i].deletedLegBegin, this._params[i].deletedLegEnd);
                    }
                }
                if (this._editedComponent.componentType === ComponentConstants.ComponentType.CoatingArea) {
                    eventsManager.instance.dispatch(Events.REDRAW_FRONTVIEW_AREA,
                        {
                            component: this._editedComponent
                        });
                }
                break;

            case ComponentConstants.ComponentType.JoineryType:
            case ComponentConstants.ComponentType.TechnicalElementType:
            case ComponentConstants.ComponentType.WallType:
            case ComponentConstants.ComponentType.CorniceType:
                this.exchangeParameters(this._editedComponent.componentType);
                break;
        }

        //Execute parent function
        super.undo();
    }

    execute() {
        switch (this._editedComponent.componentType) {
            case ComponentConstants.ComponentType.Area:
            case ComponentConstants.ComponentType.AddArea:
            case ComponentConstants.ComponentType.CutArea:
            case ComponentConstants.ComponentType.CoatingArea:
            case ComponentConstants.ComponentType.FloorCoatingArea:
                for (let i = 0; i < this._params.length; i++) {
                    let j;

                    if (this._params[i].addedPoint !== undefined && this._params[i].addedPoint !== null) {
                        if (this._editedComponent.entity !== null && this._editedComponent.entity.isWorktopEntity()) {
                            let legs = (this._editedComponent as any)._entity.legs;
                            let testBegin = (this._editedComponent as any)._vertices[this._params[i].index - 1];
                            let testEnd = (this._editedComponent as any)._vertices[this._params[i].index % (this._editedComponent as any)._vertices.length];
                            for (j = 0; j < legs.length; j++) {
                                if (legs[j].areaBeginIndex >= this._params[i].index) {
                                    legs[j].areaBeginIndex++;
                                }
                                if (legs[j].areaEndIndex > this._params[i].index) {
                                    legs[j].areaEndIndex++;
                                }

                                if (legs[j].areaEndIndex === this._params[i].index) {
                                    legs[j].end = math.vec3.clone(testEnd);
                                }
                            }

                            let credences = (this._editedComponent as any)._entity.credences;
                            testBegin = (this._editedComponent as any)._vertices[this._params[i].index - 1];
                            testEnd = (this._editedComponent as any)._vertices[this._params[i].index % (this._editedComponent as any)._vertices.length];
                            for (j = 0; j < credences.length; j++) {
                                if (credences[j].areaBeginIndex >= this._params[i].index) {
                                    credences[j].areaBeginIndex++;
                                }
                                if (credences[j].areaEndIndex > this._params[i].index) {
                                    credences[j].areaEndIndex++;
                                }

                                if (credences[j].areaEndIndex === this._params[i].index) {
                                    credences[j].end = math.vec3.clone(testEnd);
                                }
                            }
                        }
                        (this._editedComponent as any)._vertices.splice(this._params[i].index, 0, this._params[i].addedPoint);

                        //todo : do not update entity in EditComponentCommand ...
                    } else if (this._params[i].deletedLeg !== null && this._params[i].deletedLeg !== undefined) {
                        this._params[i].deletedLegBegin = math.vec3.clone((this._editedComponent as any)._entity.legs[this._params[i].deletedLeg].begin);
                        this._params[i].deletedLegEnd = math.vec3.clone((this._editedComponent as any)._entity.legs[this._params[i].deletedLeg].end);
                        (this._editedComponent as any)._entity.deleteLeg(this._params[i].deletedLeg);
                    } else if (this._params[i].legBegin !== null && this._params[i].legBegin !== undefined) {
                        (this._editedComponent as any)._entity.addLeg(this._params[i].legBegin, this._params[i].legEnd, this._params[i].isUnder);
                    } else if (this._params[i].deletedCredence !== null && this._params[i].deletedCredence !== undefined) {
                        this._params[i].deletedCredenceBegin = math.vec3.clone((this._editedComponent as any)._entity.credences[this._params[i].deletedCredence].begin);
                        this._params[i].deletedCredenceEnd = math.vec3.clone((this._editedComponent as any)._entity.credences[this._params[i].deletedCredence].end);
                        (this._editedComponent as any)._entity.deleteCredence(this._params[i].deletedCredence);
                    } else if (this._params[i].credenceBegin !== null && this._params[i].credenceBegin !== undefined) {
                        (this._editedComponent as any)._entity.addCredence(this._params[i].credenceBegin, this._params[i].credenceEnd);
                    } else {
                        //legs!!!
                        if (this._editedComponent.entity !== null && this._editedComponent.entity.isWorktopEntity()) {
                            let legs = (this._editedComponent as any)._entity.legs;

                            for (j = 0; j < legs.length; j++) {
                                if (legs[j].areaBeginIndex === this._params[i].index) {
                                    legs[j].begin = math.vec3.clone(this._params[i].end);
                                }
                                else {
                                    if (legs[j].areaEndIndex === this._params[i].index) {
                                        legs[j].end = math.vec3.clone(this._params[i].end);
                                    }
                                }
                            }

                            let credences = (this._editedComponent as any)._entity.credences;

                            for (j = 0; j < credences.length; j++) {
                                if (credences[j].areaBeginIndex === this._params[i].index) {
                                    credences[j].begin = math.vec3.clone(this._params[i].end);
                                }
                                else {
                                    if (credences[j].areaEndIndex === this._params[i].index) {
                                        credences[j].end = math.vec3.clone(this._params[i].end);
                                    }
                                }
                            }
                        }

                        (this._editedComponent as any)._vertices[this._params[i].index] = this._params[i].end;
                    }
                }
                if (this._editedComponent.componentType === ComponentConstants.ComponentType.CoatingArea) {
                    eventsManager.instance.dispatch(Events.REDRAW_FRONTVIEW_AREA,
                        {
                            component: this._editedComponent
                        });
                }
                break;

            case ComponentConstants.ComponentType.JoineryType:
            case ComponentConstants.ComponentType.TechnicalElementType:
            case ComponentConstants.ComponentType.WallType:
            case ComponentConstants.ComponentType.CorniceType:
                this.exchangeParameters(this._editedComponent.componentType);
                break;
        }
        eventsManager.instance.dispatch(Events.COMPONENT_EDITED, this._editedComponent);

        // Execute parent function
        super.execute();
    }
};
