import { GeometryPrimitive } from './GeometryPrimitive';
import { math } from '../Transform';
import { TemporaryGeometryPrimitive } from './../temporary/TemporaryGeometryPrimitive';
import { SceneConstants } from '../SceneConstants';

/**
 * GeometryPrimitive  of type : box
 */
export class Hood extends GeometryPrimitive {
    protected _width: number;
    protected _length: number;
    protected _topWidth: number;
    protected _topLength: number;
    protected _shapeType: number;

    constructor(primitiveType: number, height: number, length = 0, width = 0, topLength = 0, topWidth = 0, shapeType = 3) {
        super(primitiveType, height);

        this._width = width;
        this._length = length;
        this._topWidth = topWidth;
        this._topLength = topLength;
        this._shapeType = shapeType;
    }

    /**
     * Getter for the width of the box
     */
    get width(): number {
        if (this.temporary === null) {
            return this._width;
        }
        else {
            return (this.temporary as TemporaryGeometryPrimitive).width;
        }
    }

    /**
     * Setter for the width of the box
     *
     * @param {*} w
     */
    set width(w) {
        if (this.temporary === null) {
            this._width = w;
        }
        else {
            (this.temporary as TemporaryGeometryPrimitive).width = w;
        }
    }

    /**
     * Getter for the width of the box
     */
    get topWidth(): number {
        if (this.temporary === null) {
            return this._topWidth;
        }
        else {
            return (this.temporary as TemporaryGeometryPrimitive).topWidth;
        }
    }

    /**
     * Setter for the width of the box
     *
     * @param {*} tw
     */
    set topWidth(tw) {
        if (this.temporary === null) {
            this._topWidth = tw;
        }
        else {
            (this.temporary as TemporaryGeometryPrimitive).topWidth = tw;
        }
    }

    /**
     * Getter for the length of the box
     */
    get length(): number {
        if (this.temporary === null) {
            return this._length;
        }
        else {
            return (this.temporary as TemporaryGeometryPrimitive).length;
        }
    }

    /**
     * Setter for the length of the box
     *
     * @param {*} l
     */
    set length(l: number) {
        if (this.temporary === null) {
            this._length = l;
        }
        else {
            (this.temporary as TemporaryGeometryPrimitive).length = l;
        }
    }

    /**
     * Getter for the length of the box
     */
    get topLength(): number {
        if (this.temporary === null) {
            return this._topLength;
        }
        else {
            return (this.temporary as TemporaryGeometryPrimitive).topLength;
        }
    }

    /**
     * Setter for the top length of the box
     *
     * @param {*} tl
     */
    set topLength(tl) {
        if (this.temporary === null) {
            this._topLength = tl;
        }
        else {
            (this.temporary as TemporaryGeometryPrimitive).topLength = tl;
        }
    }

    /**
     * Getter for the shapeType of the box
     */
    get shapeType(): number {
        if (this.temporary === null) {
            return this._shapeType;
        }
        else {
            return (this.temporary as TemporaryGeometryPrimitive).shapeType;
        }
    }

    /**
     * Setter for the length of the box
     *
     * @param {*} newLength
     */
    set shapeType(st) {
        if (this.temporary === null) {
            this._shapeType = st;
        }
        else {
            (this.temporary as TemporaryGeometryPrimitive).shapeType = st;
        }
    }

    /**
     * Returns the array of 4 point deliminting the box (2d)
     */
    get boundingBox(): Array<math.vec3> {
        let points = [];
        let point1 = math.vec3.create();
        let point2 = math.vec3.create();
        let point3 = math.vec3.create();
        let point4 = math.vec3.create();
        //Precalculate sin/cos
        let sAngle = Math.sin(this.angle);
        let cAngle = Math.cos(this.angle);
        let invSAngle = Math.sin(Math.PI / 2 - this.angle);
        let invCAngle = Math.cos(Math.PI / 2 - this.angle);

        let length = this.length;
        if (this.topLength > length) {
            length = this.topLength;
        }
        let width = this.width;
        if (this.topWidth > width) {
            width = this.topWidth;
        }

        math.vec3.set(point1, this.position[0] + cAngle *
            length / 2, this.position[1] +
            sAngle * length / 2, 0);

        math.vec3.set(point2, point1[0] -
            invCAngle *
            width / 2, point1[1] +
            invSAngle *
            width / 2, 0);

        math.vec3.set(point1, point1[0] +
            invCAngle *
            width / 2, point1[1] -
            invSAngle *
            width / 2, 0);

        math.vec3.set(point3, this.position[0] -
            cAngle *
            length / 2, this.position[1] -
            sAngle * length / 2, 0);

        math.vec3.set(point4, point3[0] +
            invCAngle *
            width / 2, point3[1] -
            invSAngle *
            width / 2, 0);
        math.vec3.set(point3, point3[0] -
            invCAngle *
            width / 2, point3[1] +
            invSAngle *
            width / 2, 0);

        points.push(point1);
        points.push(point2);
        points.push(point3);
        points.push(point4);

        return points;
    }

    /**
     * save the temporary data in the joinery and delete the temporaryJoinery
     */
    saveAndEndTemporary() {
        // Common parameters to all joineries, we get the from getter getting them from the temporary object
        let savedLocalMatrix = math.mat4.create();
        math.mat4.copy(savedLocalMatrix, this.transform.localMatrix);
        let heightTemp = this.height;
        let hideInAxoTemp = this.hideInAxo;
        let cutByJoineriesTemp = this.cutByJoineries;
        // Specific parameters
        let lengthTemp = this._length;
        let widthTemp = this._width;
        let topWidthTemp = this._topWidth;
        let topLengthTemp = this._topLength;
        let shapeTypeTemp = this._shapeType;

        // Destroy the temporary object so that getters and setter now modify the real object
        this.temporary = null;

        // Reassign parameters with setters setting them to the real object now (temporary is null now)
        math.mat4.copy(this.transform.localMatrix, savedLocalMatrix);
        this.height = heightTemp;
        this.hideInAxo = hideInAxoTemp;
        this.cutByJoineries = cutByJoineriesTemp;

        // Reassign specific parameters
        this._length = lengthTemp;
        this._width = widthTemp;
        this._topLength = topLengthTemp;
        this._topWidth = topWidthTemp;
        this._shapeType = shapeTypeTemp;
    }
}
