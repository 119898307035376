import { Command, CommandEnum, SetFloorCornicesCommand } from './CommandModule';

export class SetProjectCornicesCommand extends Command
{
    // Command list to execute and undo
    private _subCommands: Array<Command> = [];
    // Scale factor
    private _corniceActivated: boolean;

    constructor(corniceActivated: boolean, scene) {
        super();
        // Cornices activated ?
        this._corniceActivated = corniceActivated;
        // Build commands
        for (let i = 0; i < scene.floors.length; i++) {
            // Create a SetFloorCornicesCommand for each floor of the array
            this._subCommands.push(new SetFloorCornicesCommand(this._corniceActivated, scene.floors.floors[i]));
        }
    }

    // Command name returned from a global enum
    name(): string {
        return CommandEnum.SetProjectCornicesCommand;
    }

    // Datas that allows the command to be executed
    execDatas(): any {
        return {
            corniceActivated: this._corniceActivated
        };
    }

    // Undo the current command
    undo() {
        // Call set floor cornice undo for each command created by the constuctor
        for (let i = 0; i < this._subCommands.length; i++) {
            this._subCommands[i].undo();
        }

        // Call parent undo to broadcast a global message
        super.undo();
    }

    // Execute current command (redo), call the set floor cornices command created by the constructor
    execute() {
        // Parse all commands
        for (let i = 0; i < this._subCommands.length; i++) {
            // Execute the command
            this._subCommands[i].execute();
        }

        // Call parent execute to broadcast a global message
        super.execute();
    }
};
