import { TemporaryArrangementObject } from "./temporary/TemporaryArrangementObject";
import { Plane } from "../math/primitives/plane";
import { ComponentConstants, Coating, EntityFactory, Wall, ArrangementGroup, ArrangementZone, SketchBlock, WorkTop, SceneConstants, SavaneConstants, arrangementManager, math, SavaneMath, Segment, Transform } from "../SavaneJS";
import { AMFurnitureFinishesReference } from "../kitchenTool/kitchenTool";
import { KitchenToolConstants } from "../kitchenTool/KitchenToolConstants";
import { SnappableEntity } from "./Interfaces/SnappableEntity";
import { Entity } from "./Entity";
import { ConfiguratorParameters } from "./Interfaces/ConfiguratorParameters";
import { SmartDesignerParameters } from "./Interfaces/SmartDesignerParameters";

/**
 * Furniture and other scene objects (Decor,lightning,furnitures...)
 */
export class ArrangementObject extends Entity implements SnappableEntity, ConfiguratorParameters, SmartDesignerParameters {
    private _objectId: string;
    private _manufacturer: any;
    private _retailer: any;
    private _colorId: any;
    private _objectStyles: any;
    public stretchability: any;
    private _customization: any;
    private _symmetry: boolean = false;
    private _hidden: boolean = false;
    private _objectType: string;
    private _coatingId: string;
    private _objectTypeConfig: any;
    private _originalLength: number;
    private _originalWidth: number;
    private _originalHeight: number;
    private _length: number;
    private _width: number;
    private _height: number;
    // If set to true, this object can be placed over another object
    private _stackable: boolean = false;
    private _anchor: math.vec3;
    // If set to true anchor will be used, if false z position can only be set manually
    private _anchorActive: boolean = true;
    // Light on for day rendering
    private _lightOn: boolean;
    // Light off for night rendering
    private _lightOff: boolean;
    // Light temperature (K)
    private _temperature: number;
    // Light color
    private _lightColor: any;
    // Can we apply a coating on the object
    private _coatingAllowed: boolean = false;
    private _configurable: boolean = false;
    
    private _excludeFromShoppingList: boolean;

    public handleSide: number;
    public handleAsset: string;

    public masterObjectId: string;
    public masterObjectLayouts: Array<any>;
    public masterObjectCurrentLayout: number;

    constructor(id: number, objectId: string, objectManufacturer: any, objectRetailer: any, objectType: string, objectStyles: any, length: number, width: number, height: number, colorId: any, coatingId: string, stretchability: any, customization: any) {
        super();

        this._id = id;
        this._objectId = objectId;
        if (objectManufacturer) {
            this._manufacturer = {
                name: objectManufacturer.name,
                _id: objectManufacturer._id,
            };
        }
        if (objectRetailer) {
            this._retailer = {
                name: objectRetailer.name,
                _id: objectRetailer._id,
            };
        }
        if (colorId) {
            this._colorId = colorId;
        } else {
            this._colorId = 1;
        }
        if (objectStyles) {
            this._objectStyles = [];
            for (let i = 0; i < objectStyles.length; i++) {
                this._objectStyles.push({
                    _id: objectStyles[i]._id,
                });
            }
        }

        this.stretchability = stretchability;
        this._customization = customization;

        this._objectType = objectType;
        this._coatingId = coatingId !== undefined ? coatingId : null;
        this._objectTypeConfig = null;

        if (this.stretchability !== undefined) {
            if (this.stretchability.x !== undefined && this.stretchability.x.isStretchable) {
                this._originalLength = length;
                if (!this.stretchability.x.min) {
                    this.stretchability.x.min = this._originalLength / 4;
                }
                if (!this.stretchability.x.max) {
                    this.stretchability.x.max = this._originalLength * 2;
                }
            }
        }
        this._length = length;
        if (this.stretchability !== undefined) {
            if (this.stretchability.y !== undefined && this.stretchability.y.isStretchable) {
                this._originalWidth = width;
                if (!this.stretchability.y.min) {
                    this.stretchability.y.min = this._originalWidth / 4;
                }
                if (!this.stretchability.y.max) {
                    this.stretchability.y.max = this._originalWidth * 2;
                }
            }
        }
        this._width = width;
        if (this.stretchability !== undefined) {
            if (this.stretchability.z !== undefined && this.stretchability.z.isStretchable) {
                this._originalHeight = height;
                if (!this.stretchability.z.min) {
                    this.stretchability.z.min = this._originalHeight / 4;
                }
                if (!this.stretchability.z.max) {
                    this.stretchability.z.max = this._originalHeight * 2;
                }
            }
        }
        this._height = height;

        this._anchor = math.vec3.create();
        math.vec3.set(this._anchor, 0, 0, -1);

        this._transform = super.transform;
        let res = math.vec3.create();
        math.vec3.set(res, 0, 0, this._height * 0.5);
        this.position = res;
    }

    accessor initialObjectId: string;
    accessor smartDesignerParentId: string;

    getCoatingQuantity(coating: any) {
        return (this.width * this.height * 2 + this.width * this.length * 2 + this.height * this.length * 2) / 2 / (100 * 100 * 100);
    }

    get transform(): Transform {
        if (this.temporary === null) {
            return this._transform;
        } else {
            return (this.temporary as TemporaryArrangementObject).transform;
        }
    }

    get objectTypeConfig(): any {
        return this._objectTypeConfig;
    }

    set objectTypeConfig(otc: any) {
        this._objectTypeConfig = otc;
    }

    get objectStyles() : any {
        return this._objectStyles;
    }
 
    get coatingId(): string {
        return this._coatingId;
    }

    get customization(): any {
        return(this._customization);
    }

    set customization(c: any) {
        this._customization = c;
    }

    /**
     * Getter for the Entity type
     *
     */
    get entityType(): SceneConstants.EntityType {
        return SceneConstants.EntityType.ArrangementObject;
    }

    /**
     * Getter for the object type
     *
     */
    get objectType(): string {
        return this._objectType;
    }

    get manufacturer(): any {
        if (this.temporary === null) {
            return this._manufacturer;
        } else {
            return (this.temporary as TemporaryArrangementObject).manufacturer;
        }
    }

    set manufacturer(manuf: any) {
        if (this.temporary === null) {
            this._manufacturer = manuf;
        } else {
            (this.temporary as TemporaryArrangementObject).manufacturer = manuf;
        }
    }

    get retailer(): any {
        if (this.temporary === null) {
            return this._retailer;
        } else {
            return (this.temporary as TemporaryArrangementObject).retailer;
        }
    }

    set retailer(retail: any) {
        if (this.temporary === null) {
            this._retailer = retail;
        } else {
            (this.temporary as TemporaryArrangementObject).retailer = retail;
        }
    }

    get symmetry(): boolean {
        if (this.temporary === null) {
            return this._symmetry;
        } else {
            return (this.temporary as TemporaryArrangementObject).symmetry;
        }
    }

    set symmetry(s: boolean) {
        if (this.stretchability === undefined) {
            return;
        }

        if (this.temporary === null) {
            this._symmetry = s;
        } else {
            (this.temporary as TemporaryArrangementObject).symmetry = s;
        }
    }

    get hidden(): boolean {
        if (this.temporary === null) {
            return this._hidden;
        } else {
            return (this.temporary as TemporaryArrangementObject).hidden;
        }
    }

    set hidden(h: boolean) {
        if (this.temporary === null) {
            this._hidden = h;
        } else {
            (this.temporary as TemporaryArrangementObject).hidden = h;
        }
    }

    /**
     * Getter for the object id
     *
     */
    get objectId(): string {
        if (this.temporary === null) {
            return this._objectId;
        } else {
            return (this.temporary as TemporaryArrangementObject).objectId;
        }
    }

    set objectId(amid: string) {
        if (this.temporary === null) {
            this._objectId = amid;
        } else {
            (this.temporary as TemporaryArrangementObject).objectId = amid;
        }
    }

    /**
     * Getter for the object type
     *
     */
    get colorId(): any {
        return this._colorId;
    }

    /**
     * Getter for the object type
     *
     */
    set colorId(c: any) {
        if (c === 0 || c === null || c === undefined) {
            console.log("trying to set colorId to null");
        } else {
            this._colorId = c;

            let AMFFR = this.getComponent(ComponentConstants.ComponentType.AMFurnitureFinishesReference) as AMFurnitureFinishesReference;

            if (AMFFR !== null) {
                AMFFR.fillerData.configId = c;
                AMFFR.endPanelData.configId = c;
            }
        }
    }

    /**
     * Getter for the object width
     *
     */
    get width(): number {
        if (this._width === undefined) {
            return 0;
        }

        if (this.temporary === null) {
            return this._width;
        } else {
            return (this.temporary as TemporaryArrangementObject).width;
        }
    }

    set width(w: number) {
        if (this.stretchability !== undefined) {
            if (this.stretchability.y !== undefined && this.stretchability.y.isStretchable) {
                if (w > this.stretchability.y.max) {
                    w = this.stretchability.y.max;
                }
                if (w < this.stretchability.y.min) {
                    w = this.stretchability.y.min;
                }
            }
        }

        if (this.temporary === null) {
            this._width = w;
        } else {
            (this.temporary as TemporaryArrangementObject).width = w;
        }

        let lengthRatio = 1;
        let widthRatio = 1;
        let heightRatio = 1;
        if (this.originalLength !== undefined) {
            lengthRatio = this.length / this.originalLength;
        }
        if (this.originalWidth !== undefined) {
            widthRatio = w / this.originalWidth;
        }
        if (this.originalHeight !== undefined) {
            heightRatio = this.height / this.originalHeight;
        }

        let vec = math.vec3.create();
        math.vec3.set(vec, lengthRatio, widthRatio, heightRatio);
        this.transform.localScale = vec;
    }

    get originalWidth(): number {
        return this._originalWidth;
    }

    set originalWidth(w: number) {
        this._originalWidth = w;
    }

    /**
     * Getter for the object height
     *
     */
    get height(): number {
        if (this._height === undefined) {
            return 0;
        }

        if (this.temporary === null) {
            return this._height;
        } else {
            return (this.temporary as TemporaryArrangementObject).height;
        }
    }

    set height(h: number) {
        if (this.stretchability !== undefined) {
            if (this.stretchability.z !== undefined && this.stretchability.z.isStretchable) {
                if (h > this.stretchability.z.max) {
                    h = this.stretchability.z.max;
                }
                if (h < this.stretchability.z.min) {
                    h = this.stretchability.z.min;
                }
            }
        }

        if (this.height !== h) {
            let pos = this.position;

            pos[2] += (h - this.height) / 2;

            this.position = pos;
        }

        if (this.temporary === null) {
            this._height = h;
        } else {
            (this.temporary as TemporaryArrangementObject).height = h;
        }

        let lengthRatio = 1;
        let widthRatio = 1;
        let heightRatio = 1;
        if (this.originalLength !== undefined) {
            lengthRatio = this.length / this.originalLength;
        }
        if (this.originalWidth !== undefined) {
            widthRatio = this.width / this.originalWidth;
        }
        if (this.originalHeight !== undefined) {
            heightRatio = h / this.originalHeight;
        }

        let vec = math.vec3.create();
        math.vec3.set(vec, lengthRatio, widthRatio, heightRatio);
        this.transform.localScale = vec;
    }

    get originalHeight(): number {
        return this._originalHeight;
    }

    set originalHeight(h: number) {
        this._originalHeight = h;
    }

    /**
     * Getter for the object length
     *
     */
    get length() {
        if (this._length === undefined) {
            return 0;
        }

        if (this.temporary === null) {
            return this._length;
        } else {
            return (this.temporary as TemporaryArrangementObject).length;
        }
    }

    set length(l: number) {
        if (this.stretchability !== undefined) {
            if (this.stretchability.x !== undefined && this.stretchability.x.isStretchable) {
                if (l > this.stretchability.x.max) {
                    l = this.stretchability.x.max;
                }
                if (l < this.stretchability.x.min) {
                    l = this.stretchability.x.min;
                }
            }
        }

        if (this.temporary === null) {
            this._length = l;
        } else {
            (this.temporary as TemporaryArrangementObject).length = l;
        }

        let lengthRatio = 1;
        let widthRatio = 1;
        let heightRatio = 1;
        if (this.originalLength !== undefined) {
            lengthRatio = l / this.originalLength;
        }
        if (this.originalWidth !== undefined) {
            widthRatio = this.width / this.originalWidth;
        }
        if (this.originalHeight !== undefined) {
            heightRatio = this.height / this.originalHeight;
        }

        let vec = math.vec3.create();
        math.vec3.set(vec, lengthRatio, widthRatio, heightRatio);
        this.transform.localScale = vec;
    }

    get originalLength(): number {
        return this._originalLength;
    }

    set originalLength(l: number) {
        this._originalLength = l;
    }

    get lightOn(): boolean {
        if (this.temporary === null) {
            return this._lightOn;
        } else {
            return (this.temporary as TemporaryArrangementObject).lightOn;
        }
    }

    set lightOn(l: boolean) {
        if (this.temporary === null) {
            this._lightOn = l;
        } else {
            (this.temporary as TemporaryArrangementObject).lightOn = l;
        }
    }

    get lightOff(): boolean{
        if (this.temporary === null) {
            return this._lightOff;
        } else {
            return (this.temporary as TemporaryArrangementObject).lightOff;
        }
    }

    set lightOff(l: boolean) {
        if (this.temporary === null) {
            this._lightOff = l;
        } else {
            (this.temporary as TemporaryArrangementObject).lightOff = l;
        }
    }

    get temperature(): number {
        if (this.temporary === null) {
            return this._temperature;
        } else {
            return (this.temporary as TemporaryArrangementObject).temperature;
        }
    }

    set temperature(t: number) {
        if (this.temporary === null) {
            this._temperature = t;
        } else {
            (this.temporary as TemporaryArrangementObject).temperature = t;
        }
    }

    get lightColor(): any {
        if (this.temporary === null) {
            return this._lightColor;
        } else {
            return (this.temporary as TemporaryArrangementObject).lightColor;
        }
    }

    set lightColor(lc: any) {
        if (this.temporary === null) {
            this._lightColor = lc;
        } else {
            (this.temporary as TemporaryArrangementObject).lightColor = lc;
        }
    }

    get coatingAllowed(): boolean {
        if (this.temporary === null) {
            return this._coatingAllowed;
        } else {
            return (this.temporary as TemporaryArrangementObject).coatingAllowed;
        }
    }

    set coatingAllowed(ca: boolean) {
        if (this.temporary === null) {
            this._coatingAllowed = ca;
        } else {
            (this.temporary as TemporaryArrangementObject).coatingAllowed = ca;
        }
    }

    get excludeFromShoppingList(): boolean {
        return this._excludeFromShoppingList;
    }

    set excludeFromShoppingList(value: boolean) {
        this._excludeFromShoppingList = value;
    }

    canLeaveGroup(): boolean {
        if (this.parent && this.parent.isArrangementGroupEntity() && (this.parent as ArrangementGroup).isUnbreakableGroup()) {
            return false;
        }

        return true;
    }

    isSnappableColumnArrangement(): boolean {
        return(SceneConstants.ArrangementType.snappableColumnArrangements.indexOf(this.objectType) !== -1);
    }

    isSnappableBottomArrangement(): boolean {
        return(SceneConstants.ArrangementType.snappableBottomArrangements.indexOf(this.objectType) !== -1);
    }

    isWorktopBottomArrangement() {
        return(SceneConstants.ArrangementType.generateWorktopBottomArrangements.indexOf(this.objectType) !== -1);
    }

    isSnappableTopArrangement() {
        return(SceneConstants.ArrangementType.snappableTopArrangements.indexOf(this.objectType) !== -1);
    }

/* OLD    isBathroomArrangement() {
        return(SceneConstants.ArrangementType.bathroomArrangements.indexOf(this.objectType) !== -1);
    }*/

    /* Check if a child object is within an arrangement zone */
    isChildInZones(child, zones, handleSide) {
        let childLocalPosition = child.localPosition;

        // Parse all zones passed to the function
        for (let i = 0; i < zones.length; i++) {
            let childAdjustedLocalPositionZ = childLocalPosition[2];

            if (handleSide !== undefined) {
                if (handleSide !== 0) {
                    // Anchor offset > half height of the zone
                    if (zones[i].offset.y > zones[i].height / 2) {
                        // Anchor to top
                        childAdjustedLocalPositionZ += child.length / 2;
                    } else {
                        // Anchor to bottom
                        childAdjustedLocalPositionZ -= child.length / 2;
                    }
                }
            }

            if (childLocalPosition[0] === zones[i].offset.x && childLocalPosition[1] === zones[i].offset.y && childAdjustedLocalPositionZ === zones[i].offset.z) {
                return true;
            }
        }

        // Not any zone
        return false;
    }

    /* Clear all handle from an arrangement object i.e. check all zones and if it contains an object, clear it */
    clearAllHandles(handleSide: number) {
        // Get all arrangement children now
        let children = this.getChildren([SceneConstants.EntityType.ArrangementObject]);
        // Get all handle zones (zones that must be populated whatever the side of the handles is)
        let handleZones = this.getZones(SceneConstants.ArrangementZoneType.handle);

        // Remove all children of the handle zones
        for (let i = 0; i < handleZones.length; i++) {
            handleZones[i].deleteAllChild();
        }

        // Get the handle zones from which we need to remove the handle from (left, right or center)
        switch (handleSide) {
            case -1:
                handleZones = this.getZones(SceneConstants.ArrangementZoneType.handleleft);
                break;

            case 0:
                handleZones = this.getZones(SceneConstants.ArrangementZoneType.handlecenter);
                break;

            case 1:
                handleZones = this.getZones(SceneConstants.ArrangementZoneType.handleright);
                break;
        }

        for (let i = 0; i < handleZones.length; i++) {
            handleZones[i].deleteAllChild();
        }
    }

    /* Populate an arrangement object with handles in the handle zone it might have */
    populateWithHandles(handleAsset: ArrangementObject, handleSide: number) {
        this.clearAllHandles(this.handleSide);

        let handleZones = this.getZones(SceneConstants.ArrangementZoneType.handle);

        for (let i = 0; i < handleZones.length; i++) {
            let clonedHandle = EntityFactory.cloneArrangementObject(handleAsset, true);
            let localPosition = math.vec3.create();
            math.vec3.set(localPosition, handleZones[i].offset.x, handleZones[i].offset.y + handleAsset.width / 2, handleZones[i].offset.z);

            let rot = Math.abs(handleZones[i].transform.localMatrix[0]);
            if (rot < 0.01) {
                // Anchor offset z > object Z local position
                if (handleZones[i].offset.z > handleZones[i].height / 4) {
                    // Anchor to top
                    localPosition[2] -= handleAsset.length / 2;
                }

                if (handleZones[i].offset.z < -(handleZones[i].height / 4)) {
                    // Anchor to bottom
                    localPosition[2] += handleAsset.length / 2;
                }
            }

            let matrix = math.mat4.clone(handleZones[i].transform.localMatrix);
            matrix[12] = 0;
            matrix[13] = 0;
            matrix[14] = 0;
            math.mat4.invert(matrix, matrix);

            math.vec3.transformMat4(localPosition, localPosition, matrix);

            clonedHandle.localPosition = localPosition;

            handleZones[i].addChild(clonedHandle);
        }

        this.handleSide = handleSide;
        this.handleAsset = handleAsset.objectId;

        switch (handleSide) {
            case -1:
                handleZones = this.getZones(SceneConstants.ArrangementZoneType.handleleft);
                break;

            case 0:
                handleZones = this.getZones(SceneConstants.ArrangementZoneType.handlecenter);
                break;

            case 1:
                handleZones = this.getZones(SceneConstants.ArrangementZoneType.handleright);
                break;
        }

        for (let i = 0; i < handleZones.length; i++) {
            let clonedHandle = EntityFactory.cloneArrangementObject(handleAsset, true);
            let localPosition = math.vec3.create();
            math.vec3.set(localPosition, handleZones[i].offset.x, handleZones[i].offset.y + handleAsset.width / 2, handleZones[i].offset.z);

            if (handleSide !== 0) {
                // Anchor offset z > object Z local position
                if (handleZones[i].offset.z > handleZones[i].height / 4) {
                    // Anchor to top
                    localPosition[2] -= handleAsset.length / 2;
                }

                if (handleZones[i].offset.z < -(handleZones[i].height / 4)) {
                    // Anchor to bottom
                    localPosition[2] += handleAsset.length / 2;
                }
            }

            let matrix = math.mat4.clone(handleZones[i].transform.localMatrix);
            matrix[12] = 0;
            matrix[13] = 0;
            matrix[14] = 0;
            math.mat4.invert(matrix, matrix);

            math.vec3.transformMat4(localPosition, localPosition, matrix);

            clonedHandle.localPosition = localPosition;

            handleZones[i].addChild(clonedHandle);
        }
    }

    // Get the side where the handles are installed
    getHandleSide(): number {
        return this.handleSide;
    }

    // Get the handle modele asset id
    getHandleAsset(): string {
        return this.handleAsset;
    }

    /**
     * Getter for the object angle
     *
     */
    get localAngle(): number {
        return this.transform.localZRotation;
    }

    /**
     * Setter for absolute position of ArrangementObject
     *
     * @param {*} newPosition
     */
    set localPosition(p: math.vec3) {
        this.transform.localPosition = p;
    }

    get localPosition(): math.vec3 {
        return this.transform.localPosition;
    }

    /**
     * Setter for absolute position of ArrangementObject
     *
     * @param {*} newPosition
     */
    set position(p: math.vec3) {
        if (this.parent !== null && this.parent.isArrangementGroupEntity()) {
            (this.parent as ArrangementGroup).recomputeParent();
        }

        this.transform.globalPosition = p;
        if (this.temporary !== null) {
            this.temporary.recomputeValidity = true;
        }
    }

    get position(): math.vec3 {
        return this.transform.globalPosition;
    }

    get anchor(): any {
        return this._anchor;
    }

    set anchor(a: any) {
        this._anchor = a;
    }

    //GET/SET anchor active bool
    get isAnchorActive(): boolean {
        if (this.temporary === null) {
            return this._anchorActive;
        } else {
            return (this.temporary as TemporaryArrangementObject).isAnchorActive;
        }
    }

    set isAnchorActive(aa: boolean) {
        if (this.temporary === null) {
            this._anchorActive = aa;
        } else {
            (this.temporary as TemporaryArrangementObject).isAnchorActive = aa;
        }
    }

    get configurable() {
        if (this.temporary === null) {
            return this._configurable;
        } else {
            return (this.temporary as TemporaryArrangementObject).configurable;
        }
    }

    set configurable(value) {
        if (this.temporary === null) {
            this._configurable = value;
        } else {
            (this.temporary as TemporaryArrangementObject).configurable = value;
        }
    }

    get stackable(): boolean {
        return this._stackable;
    }

    set stackable(s: boolean) {
        this._stackable = s;
    }

    set floorHeight(fh: number) {
        let newPos = math.vec3.create();
        math.vec3.set(newPos, this.localPosition[0], this.localPosition[1], fh + this.height * 0.5);
        this.localPosition = newPos;
    }

    get floorHeight(): number {
        let parent = this.parent;
        while (parent && !parent.isFloorEntity()) {
            parent = parent.parent;
        }

        if (!parent) {
            return this.localPosition[2] - this.height * 0.5;
        } else {
            return this.transform.globalPosition[2] - this.height * 0.5 - parent.transform.globalPosition[2];
        }
    }

    /**
     * Set rotation on a top down view (plan2d)
     *
     * @param {*} newAngle
     */
    setRotationZ(rz: number) {
        if (this.parent !== null && this.parent.isArrangementGroupEntity()) {
            (this.parent as ArrangementGroup).recomputeParent();
        }
        this.transform.globalZRotation = rz;
        if (this.temporary !== null) {
            this.temporary.recomputeValidity = true;
        }
    }

    setRotationX(rx: number) {
        if (this.parent !== null && this.parent.isArrangementGroupEntity()) {
            (this.parent as ArrangementGroup).recomputeParent();
        }
        this.transform.globalXRotation = rx;
        if (this.temporary !== null) {
            this.temporary.recomputeValidity = true;
        }
    }

    setRotationY(ry: number) {
        if (this.parent !== null && this.parent.isArrangementGroupEntity()) {
            (this.parent as ArrangementGroup).recomputeParent();
        }
        this.transform.globalYRotation = ry;
        if (this.temporary !== null) {
            this.temporary.recomputeValidity = true;
        }
    }

    set inclinaison(i: number) {
        if (this.parent !== null && this.parent.isArrangementGroupEntity()) {
            (this.parent as ArrangementGroup).recomputeParent();
        }
        this.transform.localXRotation = (i * Math.PI) / 180;
        if (this.temporary !== null) {
            this.temporary.recomputeValidity = true;
        }
    }

    get inclinaison(): number {
        return (this.transform.localXRotation * 180) / Math.PI;
    }

    /**
     * Getter for the object angle
     *
     */
    get angle(): number {
        return this.transform.globalZRotation;
    }

    /**
     * set for the arrangementGroup angle
     *
     */
    set angle(a: number) {
        this.setRotationZ(a);
    }

    /**
     * get the potential coating of hangtype attached to the arragement object
     *
     */
    getCoating(hangType: Coating.HangType) {
        let coatings = this.getComponents(ComponentConstants.ComponentType.Coating);

        for (let i = 0; i < coatings.length; i++) {
            if ((coatings[i] as Coating).hangType === hangType) {
                return coatings[i];
            }
        }

        return null;
    }

    /**
     * set position = center
     *
     */
    recenter() {
    }

    /**
     * Returns the array of 4 point deliminting the arrangement (2d)
     */
    get boundingBox(): Array<math.vec3> {
        //Look for a collider zone, that redefine collider
        let collider = this.getZones(SceneConstants.ArrangementZoneType.collider);
        if (collider.length === 1) {
            return collider[0].boundingBox;
        } else {
            return this.getHooverBox(0);
        }
    }

    get rawBoundingBox(): Array<math.vec3> {
        let points = [];

        let point1 = math.vec3.create();
        let point2 = math.vec3.create();
        let point3 = math.vec3.create();
        let point4 = math.vec3.create();
        let point5 = math.vec3.create();
        let point6 = math.vec3.create();
        let point7 = math.vec3.create();
        let point8 = math.vec3.create();

        math.vec3.set(point1, this.length / 2, -this.width / 2, -this.height / 2);
        math.vec3.set(point2, this.length / 2, this.width / 2, -this.height / 2);
        math.vec3.set(point3, -this.length / 2, this.width / 2, -this.height / 2);
        math.vec3.set(point4, -this.length / 2, -this.width / 2, -this.height / 2);

        points.push(point1);
        points.push(point2);
        points.push(point3);
        points.push(point4);

        math.vec3.set(point5, point1[0], point1[1], point1[2] + this.height);
        math.vec3.set(point6, point2[0], point2[1], point2[2] + this.height);
        math.vec3.set(point7, point3[0], point3[1], point3[2] + this.height);
        math.vec3.set(point8, point4[0], point4[1], point4[2] + this.height);

        points.push(point5);
        points.push(point6);
        points.push(point7);
        points.push(point8);

        return points;
    }

    get realHeight(): number {
        let localBBox = this.rawBoundingBox;
        let min = Number.POSITIVE_INFINITY;
        let max = Number.NEGATIVE_INFINITY;

        let transform = new Transform(null);
        transform.clone(this.transform);

        let scale = math.vec3.create();
        math.vec3.set(scale, 1, 1, 1);
        transform.localScale = scale;

        for (let i = 0; i < localBBox.length; ++i) {
            let p = math.vec3.create();
            math.vec3.transformMat4(p, localBBox[i], transform.localMatrix);
            min = Math.min(min, p[2]);
            max = Math.max(max, p[2]);
        }

        return max - min;
    }

    get realWidth(): number {
        let axes = [math.vec3.fromValues(-this.length / 2, -this.width / 2, -this.height / 2), math.vec3.fromValues(this.length / 2, this.width / 2, this.height / 2)];
        let rotatedAxes = [];
        let O = math.vec3.fromValues(0, 0, 0);
        let N = math.vec3.fromValues(1, 0, 0);

        let transform = new Transform(null);
        transform.clone(this.transform);
        transform.localScale = math.vec3.fromValues(1, 1, 1);
        transform.localPosition = math.vec3.fromValues(0, 0, 0);

        let rotation = transform.globalMatrix;
        let angles = Transform.extractEulerAngles(rotation);

        math.vec3.rotateZ(N, N, O, angles[2]);
        let plane = new Plane(O, N);

        for (let i = 0; i < axes.length; ++i) {
            let p = math.vec3.create();
            math.vec3.transformMat4(p, axes[i], rotation);
            p[2] = 0;
            rotatedAxes.push(p);
        }

        return math.vec3.distance(plane.Project(rotatedAxes[0], true), plane.Project(rotatedAxes[1], true));
    }

    get realLength(): number {
        let axes = [math.vec3.fromValues(-this.length / 2, -this.width / 2, -this.height / 2), math.vec3.fromValues(this.length / 2, this.width / 2, this.height / 2)];
        let rotatedAxes = [];
        let O = math.vec3.fromValues(0, 0, 0);
        let N = math.vec3.fromValues(0, 1, 0);

        let transform = new Transform(null);
        transform.clone(this.transform);
        transform.localScale = math.vec3.fromValues(1, 1, 1);
        transform.localPosition = math.vec3.fromValues(0, 0, 0);

        let rotation = transform.globalMatrix;
        let angles = Transform.extractEulerAngles(rotation);

        math.vec3.rotateZ(N, N, O, angles[2]);
        let plane = new Plane(O, N);

        for (let i = 0; i < axes.length; ++i) {
            let p = math.vec3.create();
            math.vec3.transformMat4(p, axes[i], rotation);
            p[2] = 0;
            rotatedAxes.push(p);
        }

        return math.vec3.distance(plane.Project(rotatedAxes[0], true), plane.Project(rotatedAxes[1], true));
    }

    get worktopBox(): Array<math.vec3> {
        let sketchs = this.getChildren([SceneConstants.EntityType.SketchBlock]);
        let sketch = null;
        if (sketchs.length === 1) {
            sketch = sketchs[0];
        }
        //Special bbox adding 5cm in front of element
        let bbox = [];
        if (sketch === null) {
            //Pick sketch dim if avaible
            let l = -this.length * 0.5,
                w = -this.width * 0.5;
            bbox[0] = math.vec3.create();
            math.vec3.set(bbox[0], -l * 0.5, -w * 0.5, 0);
            bbox[1] = math.vec3.create();
            math.vec3.set(bbox[1], l * 0.5, -w * 0.5, 0);
            bbox[2] = math.vec3.create();
            math.vec3.set(bbox[2], l * 0.5, w * 0.5 + KitchenToolConstants.workTopDefaultBorder, 0);
            bbox[3] = math.vec3.create();
            math.vec3.set(bbox[3], -l * 0.5, w * 0.5 + KitchenToolConstants.workTopDefaultBorder, 0);
        } else {
            //Pick sketch dim if avaible
            let l = sketch.length,
                w = sketch.width;
            bbox[0] = math.vec3.create();
            math.vec3.set(bbox[0], -l * 0.5 + sketch.transform.localPosition[0], -w * 0.5 + sketch.transform.localPosition[1], 0);
            bbox[1] = math.vec3.create();
            math.vec3.set(bbox[1], l * 0.5 + sketch.transform.localPosition[0], -w * 0.5 + sketch.transform.localPosition[1], 0);
            bbox[2] = math.vec3.create();
            math.vec3.set(bbox[2], l * 0.5 + sketch.transform.localPosition[0], w * 0.5 + KitchenToolConstants.workTopDefaultBorder + sketch.transform.localPosition[1], 0);
            bbox[3] = math.vec3.create();
            math.vec3.set(bbox[3], -l * 0.5 + sketch.transform.localPosition[0], w * 0.5 + KitchenToolConstants.workTopDefaultBorder + sketch.transform.localPosition[1], 0);
        }
        //Convert to global
        for (let i = 0; i < bbox.length; i++) {
            math.vec3.transformMat4(bbox[i], bbox[i], this.transform.globalMatrix);
            bbox[i][2] = 0; ///Floor the box
        }
        return bbox;
    }
    /**
     * get bounding box bigger of the precision
     *
     * @param precision {Number}
     */
    getHooverBox(precision: number): Array<math.vec3> {
        //Look for a collider zone, that redefine collider
        let collider = this.getZones(SceneConstants.ArrangementZoneType.collider);
        if (collider.length === 1) {
            return collider[0].getHooverBox(precision);
        } else {
            let points = [];
            points.push(math.vec3.fromValues(this.realLength / 2 + precision, -this.realWidth / 2 - precision, 0));
            points.push(math.vec3.fromValues(this.realLength / 2 + precision, this.realWidth / 2 + precision, 0));
            points.push(math.vec3.fromValues(-this.realLength / 2 - precision, this.realWidth / 2 + precision, 0));
            points.push(math.vec3.fromValues(-this.realLength / 2 - precision, -this.realWidth / 2 - precision, 0));

            let q = math.quat.create();
            math.quat.setAxisAngle(q, math.vec3.fromValues(0, 0, 1), this.angle);
            let m = math.mat4.create();
            math.mat4.fromRotationTranslation(m, q, this.position);
            for (let i = 0; i < points.length; ++i) {
                math.vec3.transformMat4(points[i], points[i], m);
                points[i][2] = 0;
            }

            return points;
        }
    }

    //TODO Too specific think about a more generic behaviour
    /**
     * Update position from worktop, only update item that are on a worktop
     *
     * @param {Array} worktops Worktop list
     */
    updateForWorktop(worktops: Array<WorkTop>) {
        if (this.stackable && this._anchor[2] === -1 && this.isAnchorActive) {
            let hoveredEntity = null;
            for (let i = 0; i < worktops.length; i++) {
                if (worktops[i].contains(this.position)) {
                    hoveredEntity = worktops[i];
                    let baseValue = hoveredEntity.maxHeight;
                    let zones = this.children;
                    let zone = null;
                    for (let j = 0; j < zones.length; j++) {
                        if (zones[j].entityType === SceneConstants.EntityType.ArrangementZone && ((zones[j] as ArrangementZone).zoneType === SceneConstants.ArrangementZoneType.housing || (zones[j] as ArrangementZone).zoneType === SceneConstants.ArrangementZoneType.housable)) {
                            zone = zones[j];
                            break;
                        }
                    }
                    if (zone !== null) {
                        baseValue += zone.localPosition[1] - zone.height;
                    }
                    //Anchored to worktop
                    let pos = math.vec3.clone(this.position);
                    pos[2] = baseValue + this.height * 0.5;
                    this.position = pos;
                    return;
                }
            }
        }
    }

    /**
     *
     * Update floorHeight using anchor values depending on other objects above or underneath
     *
     * @param {Room} room
     */
    updateFloorHeightFromAnchor(room, floor) {
        // Upper (anchor[2] === -1) or lower (anchor[2] === 1) entity underneath (anchor[2] === -1) or above (anchor[2] === 1) the arrangementObject being moved
        let hoveredEntity = null;

        // Does the object have an active anchor (does it anchor to something)
        if (this.isAnchorActive) {
            // Height where to anchor
            let baseValue = 0;

            // Does the arrangement object stacks on or under other arrangement objects
            if (this.stackable) {
                // Retrieve list of arrangement objecst collidable
                let list = floor.getChildren(
                    [
                        SceneConstants.EntityType.ArrangementObject,
                        SceneConstants.EntityType.WorkTop
                    ],
                    [SceneConstants.EntityType.Room, SceneConstants.EntityType.ArrangementGroup]
                );

                // Find object that are under entity and keep only the one with the max height
                for (let i = 0; i < list.length; i++) {
                    // Anchoring to floor look for higher object
                    if (this.anchor[2] === -1) {
                        if (list[i].id !== this.id && list[i].contains(this.position) && (hoveredEntity === null || hoveredEntity.maxHeight < list[i].maxHeight) && list[i].anchor[2] === this.anchor[2]) {
                            hoveredEntity = list[i];
                        }
                    }

                    // Anchoring to ceiling look for lower object
                    if (this.anchor[2] >= 1) {
                        if (list[i].id !== this.id && list[i].contains(this.position) && (hoveredEntity === null || hoveredEntity.maxHeight > list[i].maxHeight) && list[i].anchor[2] === this.anchor[2]) {
                            hoveredEntity = list[i];
                        }
                    }
                }

                // Any hovered object at the position where the object is right now ?
                if (hoveredEntity !== null) {
                    // Yes, get the maxHeight of this object
                    baseValue = hoveredEntity.getHeightAtPosition(this.position) - hoveredEntity.floor.transform.globalPosition[2];

                    // If we anchor from the top, we have to subtract the the object height to reach the point below the hovered object
                    if (this.anchor[2] >= 1) {
                        baseValue -= hoveredEntity.height;
                    }

                    // Check if the hoveredObject has a collidable zone and retrieve the height from this collidable zone
                    let zones = this.children;
                    let zone = null;
                    let collider = null;
                    for (let i = 0; i < zones.length; i++) {
                        if (zones[i].entityType === SceneConstants.EntityType.ArrangementZone && ((zones[i] as ArrangementZone).zoneType === SceneConstants.ArrangementZoneType.housing || (zones[i] as ArrangementZone).zoneType === SceneConstants.ArrangementZoneType.housable)) {
                            zone = zones[i];
                            break;
                        }

                        if (zones[i].entityType === SceneConstants.EntityType.ArrangementZone && (zones[i] as ArrangementZone).zoneType === SceneConstants.ArrangementZoneType.collider) {
                            collider = zones[i];
                        }
                    }

                    // Any zone found ?
                    if (zone !== null) {
                        // We anchor to bottom then we get the bottom of the collision zone
                        if (this._anchor[2] === -1) {
                            baseValue += zone.localPosition[1] - zone.height;
                        } else {
                            // We anchor to top then we get the top of the collision zone
                            if (this.anchor[2] >= 1) {
                                baseValue += zone.localPosition[1];
                            }
                        }
                    }

                    if (collider !== null) {
                        // We anchor to bottom then we get the bottom of the collision zone
                        if (this._anchor[2] === -1) {
                            baseValue -= this.height / 2 + (collider.localPosition[2] - collider.height / 2);
                        } else {
                            // We anchor to top then we get the top of the collision zone
                            if (this.anchor[2] >= 1) {
                                baseValue -= this.height / 2 + (collider.localPosition[2] + collider.height / 2);
                            }
                        }
                    }
                } else {
                    if (room !== null) {
                        baseValue = room.floorHeight;
                    }
                }
            } else {
                if (room !== null) {
                    baseValue = room.floorHeight;
                }
            }

            // Anchored to floor ?
            if (this._anchor[2] === -1) {
                // Place the object
                this.floorHeight = baseValue;
            } else {
                // Anchored to ceiling
                if (this._anchor[2] >= 1) {
                    let height;

                    // Anchored underneath lower object if any
                    if (hoveredEntity !== null) {
                        height = baseValue;
                    } else {
                        // Anchored to room height o to 0 if no room
                        if (room === null) {
                            height = 0;
                        } else {
                            let technicalElement = arrangementManager.getTechnicalElementAtPosition(this.position, floor);

                            if (technicalElement && technicalElement.objectId === SceneConstants.TechnicalElementType.beam) {
                                height = technicalElement.floorHeight;
                            } else {
                                height = room.roomHeight;
                                if (this.parent && this.parent.isArrangementGroupEntity()) {
                                    height -= room.floorHeight;
                                }
                            }
                        }
                    }

                    // Finally set floor height
                    this.floorHeight = height * (this._anchor[2] > 0 ? 1 : -1) - this.height;
                }
            }
        }
    }

    /**
     * Check if bounding box contains
     *
     * @param point
     * @param precision
     */
    contains(point: math.vec3, precision = SavaneConstants.PositionTolerance) {
        if (this.isGhost()) {
            return null;
        }
        if (SavaneMath.isInPoly(point, this.getHooverBox(precision))) {
            return this;
        }
        return null;
    }

    get maxHeight(): number {
        //Use the collider zone if avaible
        let collider = this.getZones(SceneConstants.ArrangementZoneType.collider);
        if (collider.length >= 1) {
            let max = this.position[2] + collider[0].height * 0.5 + collider[0].localPosition[2];

            for (let i = 1; i < collider.length; i++) {
                if (this.position[2] + collider[i].height * 0.5 + collider[i].localPosition[2] > max) {
                    max = this.position[2] + collider[i].height * 0.5 + collider[i].localPosition[2];
                }
            }
            return max;
        } else {
            return this.position[2] + this.height * 0.5;
        }
    }

    get localToFloorMaxHeight(): number {
        //Use the collider zone if avaible
        let sketchBlock = this.getChildren([SceneConstants.EntityType.SketchBlock]);
        if (sketchBlock.length === 1) {
            return (sketchBlock[0] as SketchBlock).height + this.floorHeight;
        }

        let collider = this.getZones(SceneConstants.ArrangementZoneType.collider);
        if (collider.length >= 1) {
            let max = this.floorHeight + this.height / 2 + collider[0].height * 0.5 + collider[0].localPosition[2];

            for (let i = 1; i < collider.length; i++) {
                if (this.floorHeight + this.height / 2 + collider[i].height * 0.5 + collider[i].localPosition[2] > max) {
                    max = this.floorHeight + this.height / 2 + collider[i].height * 0.5 + collider[i].localPosition[2];
                }
            }
            return max;
        } else {
            return this.floorHeight + this.height;
        }
    }

    doesHoverCollider(collider: ArrangementZone, position: math.vec3): boolean {
        let matrix = collider.transform.invertedGlobalMatrix;
        let localPosition = math.vec3.create();

        math.vec3.transformMat4(localPosition, position, matrix);

        if (localPosition[0] > -collider.width / 2 && localPosition[0] < collider.width / 2 && localPosition[1] > -collider.length / 2 && localPosition[1] < collider.length / 2) {
            return true;
        } else {
            return false;
        }
    }

    collides(otherArrangement: ArrangementObject): boolean {
        let bbox = otherArrangement.boundingBox;
        let i;

        for (i = 0; i < bbox.length; i++) {
            if (this.contains(bbox[i])) {
                return true;
            }
        }

        bbox = this.boundingBox;
        for (i = 0; i < bbox.length; i++) {
            if (otherArrangement.contains(bbox[i])) {
                return true;
            }
        }

        return false;
    }

    getHeightAtPosition(p: math.vec3) {
        //Use the collider zone if avaible
        let collider = this.getZones(SceneConstants.ArrangementZoneType.collider);
        if (collider.length >= 1) {
            let heightToReturn = 0;

            for (let i = 0; i < collider.length; i++) {
                let colliderTest = collider[i];

                if (this.doesHoverCollider(colliderTest, p)) {
                    if (this.position[2] + colliderTest.height * 0.5 + colliderTest.localPosition[2] > heightToReturn) {
                        heightToReturn = this.position[2] + colliderTest.height * 0.5 + colliderTest.localPosition[2];
                    }
                }
            }
            return heightToReturn;
        } else {
            return this.position[2] + this.height * 0.5;
        }
    }

    get minHeight(): number {
        //Use the collider zone if avaible
        let collider = this.getZones(SceneConstants.ArrangementZoneType.collider);
        if (collider.length >= 1) {
            let min = this.position[2] + collider[0].height * 0.5 + collider[0].localPosition[2];

            for (let i = 1; i < collider.length; i++) {
                if (this.position[2] + collider[i].height * 0.5 + collider[i].localPosition[2] < min) {
                    min = this.position[2] + collider[i].height * 0.5 + collider[i].localPosition[2];
                }
            }
            return min;
        } else {
            return this.position[2] + this.height * 0.5;
        }
    }

    get localMinHeight(): number {
        //Use the collider zone if avaible
        let collider = this.getZones(SceneConstants.ArrangementZoneType.collider);
        if (collider.length >= 1) {
            let min = this.localPosition[2] + collider[0].height * 0.5 + collider[0].localPosition[2];

            for (let i = 1; i < collider.length; i++) {
                if (this.localPosition[2] + collider[i].height * 0.5 + collider[i].localPosition[2] < min) {
                    min = this.localPosition[2] + collider[i].height * 0.5 + collider[i].localPosition[2];
                }
            }
            return min;
        } else {
            return this.localPosition[2] + this.height * 0.5;
        }
    }

    /**
     * Test whether the wall cross the object
     *
     * @param {*} wall
     */
    crossWall(wall: Wall): boolean {
        // Get both bounding boxes
        let bbox1 = wall.boundingBox;
        let bbox2 = this.boundingBox;

        // And check if the borders are crossing
        for (let i = 0; i < bbox1.length; i++) {
            let seg1 = new Segment(bbox1[i], bbox1[(i + 1) % bbox1.length]);
            for (let j = 0; j < bbox2.length; j++) {
                let seg2 = new Segment(bbox2[j], bbox2[(j + 1) % bbox2.length]);

                // Found two borders crossing ?
                if (Segment.areSegmentCrossing(seg1, seg2, SavaneConstants.Tolerance)) {
                    // The arrangement crosses the wall
                    return true;
                }
            }
        }

        // Nothing, no collision
        return false;
    }

    /**
     * Return the list of zone with the selected zoneType of the this arrangement
     *
     * @param zoneType Type of zone wanted, if set to undefined will return all zones
     * @returns {Array} Array of ArrangementZones
     */
    getZones(zoneType: SceneConstants.ArrangementZoneType) {
        let zonesList = [];
        for (let i = 0; i < this.children.length; i++) {
            if (this.children[i].isArrangementZoneEntity() && ((this.children[i] as ArrangementZone).zoneType === zoneType || zoneType === undefined)) {
                zonesList.push(this.children[i]);
            }
        }
        return zonesList;
    }

    /**
     * Return the zone with corresponding id
     *
     * @param {Number} zoneId
     * @returns {Array} Array of ArrangementZones
     */
    getZone(id: number): ArrangementZone {
        for (let i = 0; i < this.children.length; i++) {
            if (this.children[i].isArrangementZoneEntity() && (this.children[i] as ArrangementZone).id === id) {
                return (this.children[i] as ArrangementZone);
            }
        }
        return null;
    }

    /**
     * Create the temporaryArrangementObject that will be used during edition
     */
    startTemporary() {
        let temp = new TemporaryArrangementObject(this);
        this.temporary = temp;
    }

    /**
     * delete the temporaryArrangementObject
     */
    endTemporary() {
        this.temporary = null;
    }

    /**
     * save the temporary data in the arrangementObject and delete the temporaryArrangementObject
     */
    saveAndEndTemporary() {
        let matrix = math.mat4.clone(this.transform.localMatrix);
        let width = this.width;
        let height = this.height;
        let length = this.length;
        let objectId = this.objectId;
        let objectManufacturer = this.manufacturer;
        let objectRetailer = this.retailer;
        let isAnchorActive = this.isAnchorActive;
        let lightOn = this.lightOn;
        let temperature = this.temperature;
        let lightColor = this.lightColor;
        let lightOff = this.lightOff;
        let symmetry = this.symmetry;
        let coatingAllowed = this.coatingAllowed;

        this.temporary = null;

        this.width = width;
        this.height = height;
        this.length = length;
        this.objectId = objectId;
        this.manufacturer = objectManufacturer;
        this.retailer = objectRetailer;
        this.isAnchorActive = isAnchorActive;
        math.mat4.copy(this.transform.localMatrix, matrix);
        if (lightOn !== undefined) {
            this.lightOn = lightOn;
        }
        if (lightOff !== undefined) {
            this.lightOff = lightOff;
        }
        if (temperature !== undefined) {
            this.temperature = temperature;
        }
        if (lightColor !== undefined) {
            this.lightColor = lightColor;
        }
        this.symmetry = symmetry;
        this.coatingAllowed = coatingAllowed;
    }

    // Called when a property is changed to verify max and min boundaries or to perform computations before final assignment
    addToCappedProperty(property: string, value: any) : number {
        let capped = 0; // 0 = no cap, 1 = minus cap, 2 max cap

        if (this[property] === undefined) {
            return -1;
        }

        this[property] += value;

        if (property === "height") {
            if (this.stretchability !== undefined) {
                if (this.stretchability.z !== undefined && this.stretchability.z.isStretchable) {
                    if (this[property] > this.stretchability.z.max) {
                        this[property] = this.stretchability.z.max;
                        capped = 2;
                    }
                    if (this[property] < this.stretchability.z.min) {
                        this[property] = this.stretchability.z.min;
                        capped = 1;
                    }
                } else {
                    capped = 3;
                }
            } else {
                capped = 3;
            }

            return capped;
        }

        if (property === "width") {
            if (this.stretchability !== undefined) {
                if (this.stretchability.y !== undefined && this.stretchability.y.isStretchable) {
                    if (this[property] > this.stretchability.y.max) {
                        this[property] = this.stretchability.y.max;
                        capped = 2;
                    }
                    if (this[property] < this.stretchability.y.min) {
                        this[property] = this.stretchability.y.min;
                        capped = 1;
                    }
                } else {
                    capped = 3;
                }
            } else {
                capped = 3;
            }

            return capped;
        }

        if (property === "length") {
            if (this.stretchability !== undefined) {
                if (this.stretchability.x !== undefined && this.stretchability.x.isStretchable) {
                    if (this[property] > this.stretchability.x.max) {
                        this[property] = this.stretchability.x.max;
                        capped = 2;
                    }
                    if (this[property] < this.stretchability.x.min) {
                        this[property] = this.stretchability.x.min;
                        capped = 1;
                    }
                } else {
                    capped = 3;
                }
            } else {
                capped = 3;
            }

            return capped;
        }

        return capped;
    }

    enableStretchability() {
        this.stretchability = {
            x: {
                isStretchable: true,
                min: this.length / 4,
                max: this.length * 2,
            },
            y: {
                isStretchable: true,
                min: this.width / 4,
                max: this.width * 2,
            },
            z: {
                isStretchable: true,
                min: this.height / 4,
                max: this.height * 2,
            },
        };
        this._originalLength = this.length;
        this._originalWidth = this.width;
        this._originalHeight = this.height;
        this._excludeFromShoppingList = true;
    }

    disableStretchability() {
        this.stretchability = undefined;
        this.symmetry = false;
        this.length = this._originalLength;
        this.width = this._originalWidth;
        this.height = this._originalHeight;
        this._originalLength = undefined;
        this._originalWidth = undefined;
        this._originalHeight = undefined;
        this._excludeFromShoppingList = undefined;
    }

    /**
     * If return true will be consider as a ghost
     * Will be ingored by designated methods
     *
     **/
    isGhost(): boolean {
        return SceneConstants.GhostArrangementType.indexOf(this.objectType) >= 0;
    }

    isGhostForWebGL(): boolean {
        return SceneConstants.WebGLGhostArrangementType.indexOf(this.objectType) >= 0;
    }
}
