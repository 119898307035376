import { Events } from '../events';
import { eventsManager } from '../managers/EventsManager';
import { ComponentConstants, EntityFactory, Entity, Floor, Room, Wall, Joinery, SceneConstants, SavaneConstants, wallManager, roomManager, joineryManager, math } from '../SavaneJS';
import { Command, CommandEnum } from './CommandModule';

declare var Sentry;

export class EditWallCommand  extends Command
{
    private _currentFloor: Floor;
    private _selectedEntities: Array<Entity>;
    private _beginPointPosition: math.vec3;
    private _endPointPosition: math.vec3;
    private _endPoint1: math.vec3;
    private _endPoint2: math.vec3;
    private _roomEditedDirect: Room;
    private _roomSavedDirect: Room;
    private _toRecompute: any;
    private _roomEditedUndirect: Room;
    private _roomSavedUndirect: Room;
    private _wallEdited: Wall;
    private _joineriesDeleted: Array<Joinery> =  [];
    private _joineriesDeletedPos: Array<math.vec3> = [];
    private _joineriesToCheck: Array<Joinery> = [];
    private _joineriesToCheckPos: Array<math.vec3> = [];
    private _modifiedWalls1: Array<Wall> = [];
    private _modifiedWalls2: Array<Wall> = [];
    private _wallsDeleted: Array<Wall> = [null, null];
    private _wallsAdded: Array<Wall> = [null, null];
    private _wallsMerged: Array<Wall> = [null, null];
    private _wallsBeforeMerge: any = [null, null];
    private _wallsBeforeCut: Array<Wall> = [null, null];
    private _wallsCutted: any = [null, null];

    private isWallEditedMerge: boolean = false;
    private firstTime: boolean = true;

    private _wallsMergedAfterEdition: any = [];
    private _wallsBeforeMergeAfterEdition: any= [];

    constructor(wallEdited: Wall, modifiedWalls1: Array<Wall>, modifiedWalls2: Array<Wall>, wallsDeleted: Array<Wall>, wallsAdded: Array<Wall>,
        wallsBeforeMerge: Array<Wall>, wallsMerged: Array<Wall>, wallsCutted: Array<Wall>, wallsBeforeCut: Array<Wall>, beginPointPosition: math.vec3,
        endPointPosition: math.vec3, endPoint1: math.vec3, endPoint2: math.vec3, joineriesDeleted: Array<Joinery>, joineriesToCheck: Array<Joinery>, roomEditedDirect: Room, roomEditedUndirect: Room, toRecompute: any,
        currentFloor: Floor, selectedEntities: Array<Entity>) {
        super();
        
        let i;

        /** Mouchard */
        try {
            if (!selectedEntities) {
                (selectedEntities as any).crashLog = "Sentry crash";
            }
        } catch (error) {
            Sentry.captureException(error);
        }

        this._currentFloor = currentFloor;
        this._selectedEntities = selectedEntities;

        this._beginPointPosition = math.vec3.clone(beginPointPosition);
        this._endPointPosition = math.vec3.clone(endPointPosition);

        this._endPoint1 = math.vec3.clone(endPoint1);
        this._endPoint2 = math.vec3.clone(endPoint2);

        this._roomEditedDirect = null;
        this._roomSavedDirect = null;
        this._toRecompute = toRecompute;
        if (roomEditedDirect !== null) {
            this._roomEditedDirect = EntityFactory.cloneRoom(roomEditedDirect, false, false);
            for (i = 0; i < roomEditedDirect.walls.length; i++) {
                this._roomEditedDirect.addWall(roomEditedDirect.walls[i]);
            }
        }

        this._roomEditedUndirect = null;
        this._roomSavedUndirect = null;
        if (roomEditedUndirect !== null) {
            this._roomEditedUndirect = EntityFactory.cloneRoom(roomEditedUndirect, false, false);
            for (i = 0; i < roomEditedUndirect.walls.length; i++) {
                this._roomEditedUndirect.addWall(roomEditedUndirect.walls[i]);
            }
        }

        this._wallEdited = wallEdited;

        for (i = 0; i < joineriesDeleted.length; i++) {
            this._joineriesDeleted.push(joineriesDeleted[i]);
            this._joineriesDeletedPos.push(math.vec3.clone(this._joineriesDeleted[i].position));
        }

        for (i = 0; i < joineriesToCheck.length; i++) {
            this._joineriesToCheck.push(joineriesToCheck[i]);
            this._joineriesToCheckPos.push(math.vec3.clone(this._joineriesToCheck[i].position));
        }

        for (i = 0; i < modifiedWalls1.length; i++) {
            this._modifiedWalls1.push(modifiedWalls1[i]);
        }

        for (i = 0; i < modifiedWalls2.length; i++) {
            this._modifiedWalls2.push(modifiedWalls2[i]);
        }

        if (wallsDeleted[0] !== null) {
            this._wallsDeleted[0] = wallsDeleted[0];
        }
        if (wallsDeleted[1] !== null) {
            this._wallsDeleted[1] = wallsDeleted[1];
        }

        if (wallsAdded[0] !== null) {
            this._wallsAdded[0] = wallsAdded[0];
        }
        if (wallsAdded[1] !== null) {
            this._wallsAdded[1] = wallsAdded[1];
        }

        if (wallsMerged[0] !== null) {
            this._wallsMerged[0] = wallsMerged[0];
        }
        if (wallsMerged[1] !== null) {
            this._wallsMerged[1] = wallsMerged[1];
        }

        if (wallsBeforeMerge[0] !== null) {
            this._wallsBeforeMerge[0] = [null, null];
            this._wallsBeforeMerge[0][0] = wallsBeforeMerge[0][0];
            this._wallsBeforeMerge[0][1] = wallsBeforeMerge[0][1];
        }
        if (wallsBeforeMerge[1] !== null) {
            this._wallsBeforeMerge[1] = [null, null];
            this._wallsBeforeMerge[1][0] = wallsBeforeMerge[1][0];
            this._wallsBeforeMerge[1][1] = wallsBeforeMerge[1][1];
        }

        if (wallsBeforeCut[0] !== null) {
            this._wallsBeforeCut[0] = wallsBeforeCut[0];
        }
        if (wallsBeforeCut[1] !== null) {
            this._wallsBeforeCut[1] = wallsBeforeCut[1];
        }

        if (wallsCutted[0] !== null) {
            this._wallsCutted[0] = [null, null];
            this._wallsCutted[0][0] = wallsCutted[0][0];
            this._wallsCutted[0][1] = wallsCutted[0][1];
        }
        if (wallsCutted[1] !== null) {
            this._wallsCutted[1] = [null, null];
            this._wallsCutted[1][0] = wallsCutted[1][0];
            this._wallsCutted[1][1] = wallsCutted[1][1];
        }
    }

    name(): string {
        return CommandEnum.EditWallCommand;
    }

    addJoinery(joinery: Joinery) {
        eventsManager.instance.dispatch(Events.ADD_GRAPHICAL_ENTITY,
            {
                entity: joinery,
            });
        joinery.wall.addJoinery(joinery);
    }

    deleteJoinery(id: number) {
        let joinery = joineryManager.getJoinery(id, this._currentFloor.scene);
        if (!joinery) {
            return;
        }
        eventsManager.instance.dispatch(Events.REMOVE_GRAPHICAL_ENTITY,
            {
                entity: joinery,
            });
        if (joinery.wall) {
            joinery.wall.deleteJoinery(id);
        }

        for (let i = 0; i < this._selectedEntities.length; i++) {
            if (this._selectedEntities[i].id === id && this._selectedEntities[i].isJoineryEntity()) {
                this._selectedEntities.splice(i, 1);
                break;
            }
        }
    }

    undo() {
        let i, j;

        for (i = this._wallsMergedAfterEdition.length - 1; i >= 0; i--) {
            wallManager.cutWallForCommand(this._wallsMergedAfterEdition[i], this._wallsBeforeMergeAfterEdition[i], this._currentFloor, this._selectedEntities);
        }

        while (this._wallEdited.rooms.length != 0) {
            eventsManager.instance.dispatch(Events.REMOVE_GRAPHICAL_ENTITY,
                {
                    entity: this._wallEdited.rooms[0],
                });
            this._currentFloor.deleteRoom(this._wallEdited.rooms[0].id);
        }

        let joineryPos;
        for (i = 0; i < this._modifiedWalls1.length; i++) {
            joineryPos = [];
            for (j = 0; j < this._modifiedWalls1[i].joineries.length; j++) {
                joineryPos.push(math.vec3.clone(this._modifiedWalls1[i].joineries[j].position));
            }

            if (this._modifiedWalls1[i].isBegin(this._wallEdited.begin)) {
                this._modifiedWalls1[i].begin = this._beginPointPosition;
            } else {
                this._modifiedWalls1[i].end = this._beginPointPosition;
            }

            for (j = 0; j < this._modifiedWalls1[i].joineries.length; j++) {
                this._modifiedWalls1[i].joineries[j].position = joineryPos[j];
            }
        }

        for (i = 0; i < this._modifiedWalls2.length; i++) {
            joineryPos = [];
            for (j = 0; j < this._modifiedWalls2[i].joineries.length; j++) {
                joineryPos.push(math.vec3.clone(this._modifiedWalls2[i].joineries[j].position));
            }
            if (this._modifiedWalls2[i].isBegin(this._wallEdited.end)) {
                this._modifiedWalls2[i].begin = this._endPointPosition;
            } else {
                this._modifiedWalls2[i].end = this._endPointPosition;
            }

            for (j = 0; j < this._modifiedWalls2[i].joineries.length; j++) {
                this._modifiedWalls2[i].joineries[j].position = joineryPos[j];
            }
        }

        this._wallEdited.begin = this._beginPointPosition;
        this._wallEdited.end = this._endPointPosition;

        if (this._wallsDeleted[0] !== null) {
            wallManager.addWallForCommand(this._wallsDeleted[0], false, this._currentFloor, this._wallsDeleted[0].height);
        }
        if (this._wallsDeleted[1] !== null) {
            wallManager.addWallForCommand(this._wallsDeleted[1], false, this._currentFloor, this._wallsDeleted[1].height);
        }

        if (this._wallsAdded[0] !== null) {
            wallManager.deleteWallForCommand(this._wallsAdded[0].id, false, this._currentFloor, this._selectedEntities);
        }
        if (this._wallsAdded[1] !== null) {
            wallManager.deleteWallForCommand(this._wallsAdded[1].id, false, this._currentFloor, this._selectedEntities);
        }

        if (this._wallsMerged[0] !== null) {
            wallManager.cutWallForCommand(this._wallsMerged[0], this._wallsBeforeMerge[0], this._currentFloor, this._selectedEntities);
        }
        if (this._wallsMerged[1] !== null) {
            wallManager.cutWallForCommand(this._wallsMerged[1], this._wallsBeforeMerge[1], this._currentFloor, this._selectedEntities);
        }

        if (this._wallsCutted[0] !== null) {
            wallManager.mergeWallsForCommand(this._wallsBeforeCut[0], this._wallsCutted[0], this._currentFloor, this._selectedEntities);
        }
        if (this._wallsCutted[1] !== null) {
            wallManager.mergeWallsForCommand(this._wallsBeforeCut[1], this._wallsCutted[1], this._currentFloor, this._selectedEntities);
        }

        for (i = 0; i < this._joineriesDeleted.length; i++) {
            this.addJoinery(this._joineriesDeleted[i]);
            this._joineriesDeleted[i].position = this._joineriesDeletedPos[i];
        }

        for (i = 0; i < this._joineriesToCheck.length; i++) {
            let wallAtJoineryPos = wallManager.getWallsAtPosition(this._joineriesToCheckPos[i], this._currentFloor, SavaneConstants.PositionTolerance, undefined);
            if (wallAtJoineryPos !== null && wallAtJoineryPos.length === 1 && wallAtJoineryPos[0].id !== this._joineriesToCheck[i].wall.id) {
                //              planManager.deleteJoinery(this._joineriesToCheck[i].id);
                this._joineriesToCheck[i].wall = wallAtJoineryPos[0];
                this.addJoinery(this._joineriesToCheck[i]);
            }
        }

        if (this._roomEditedDirect !== null && this._roomSavedDirect !== null) {
            this._currentFloor.addRoom(this._roomSavedDirect);
            eventsManager.instance.dispatch(Events.REDRAW_GRAPHICAL_ROOM,
                {
                    entity: this._roomSavedDirect
                });
            //GameObjectManager.drawRoom(this._roomSavedDirect, false, null, true);
        }
        if (this._roomEditedUndirect !== null && this._roomSavedUndirect !== null) {
            this._currentFloor.addRoom(this._roomSavedUndirect);
            eventsManager.instance.dispatch(Events.REDRAW_GRAPHICAL_ROOM,
                {
                    entity: this._roomSavedUndirect
                });
            //GameObjectManager.drawRoom(this._roomSavedUndirect, false, null, true);
        }

        roomManager.deleteNonRoomedWall(this._wallEdited.id, this._currentFloor);
        roomManager.manageNonRoomedWalls(this._currentFloor);

        eventsManager.instance.dispatch(Events.REDRAW_GRAPHICAL_WALL,
            {
                entity: this._wallEdited
            });

        for (i = 0; i < this._modifiedWalls1.length; i++) {
            if ((this._wallsAdded[0] === null || this._modifiedWalls1[i].id !== this._wallsAdded[0].id) &&
                (this._wallsCutted[0] === null || (this._modifiedWalls1[i].id !== this._wallsCutted[0][0].id && this._modifiedWalls1[i].id !== this._wallsCutted[0][1].id))) {
                eventsManager.instance.dispatch(Events.REDRAW_GRAPHICAL_WALL,
                    {
                        entity: this._modifiedWalls1[i]
                    });
            }
        }
        for (i = 0; i < this._modifiedWalls2.length; i++) {
            if ((this._wallsAdded[1] === null || this._modifiedWalls2[i].id !== this._wallsAdded[1].id) &&
                (this._wallsCutted[1] === null || (this._modifiedWalls2[i].id !== this._wallsCutted[1][0].id && this._modifiedWalls2[i].id !== this._wallsCutted[1][1].id))) {
                eventsManager.instance.dispatch(Events.REDRAW_GRAPHICAL_WALL,
                    {
                        entity: this._modifiedWalls2[i]
                    });
            }
        }

        //Recompute all items
        for (i = 0; i < this._toRecompute.length; i++) {
            let item = this._toRecompute[i];
            //Recompute parent
            if (item.isArrangementObjectEntity() || item.isArrangementGroupEntity() || item.isRenderCameraEntity() || item.isGeometryPrimitiveEntity() || item.isWorktopEntity()) {
                // Find containing room
                let roomContaining = null;

                if (item.isWorktopEntity()) {
                    let area = item.getComponent(ComponentConstants.ComponentType.Area);
                    if (area) {
                        roomContaining = roomManager.getRoomAtPosition(area.vertices[0], this._currentFloor);
                    }
                }
                else {
                    roomContaining = roomManager.getRoomAtPosition(item.position, this._currentFloor);
                }

                if (roomContaining !== null) {
                    //set item child of room
                    if (item.parent !== null) {
                        item.parent.deleteChild(item.id);
                    }
                    roomContaining.addChild(item);
                } else {
                    //set item child of floor
                    if (item.parent !== null) {
                        item.parent.deleteChild(item.id);
                    }
                    this._currentFloor.addChild(item);
                }
                // Graphical node
                eventsManager.instance.dispatch(Events.ADD_GRAPHICAL_ENTITY,
                    {
                        entity: item,
                    });
            }
        }

        // Select object at the end
        eventsManager.instance.dispatch(Events.CHANGE_EDITOR_SELECTION, {
            selection: [this._wallEdited],
            keepSelected: false,
            showTulip: false,
        });
    }

    addWall(wall: Wall, tryCreateRoom: boolean) {
        let i, j;

        if (tryCreateRoom === null || tryCreateRoom === undefined) {
            tryCreateRoom = true;
        }

        eventsManager.instance.dispatch(Events.REMOVE_GRAPHICAL_ENTITY,
            {
                entity: wall,
            });

        let affectedRooms = this._currentFloor.scene.addWall(wall, tryCreateRoom, wall.height);
        let itemToRecompute = [];
        if (affectedRooms !== null) {
            for (i = 0; i < affectedRooms.length; ++i) {
                for (j = 0; j < affectedRooms[i].children.length; ++j) {
                    itemToRecompute.push(affectedRooms[i].children[j]);
                }
            }
        }

        if (affectedRooms) {
            for (i = 0; i < affectedRooms.length; ++i) {
                eventsManager.instance.dispatch(Events.REMOVE_GRAPHICAL_ENTITY,
                    {
                        entity: affectedRooms[i],
                    });
            }

            for (i = 0; i < affectedRooms.length; ++i) {
                if (affectedRooms[i].parent) {
                    eventsManager.instance.dispatch(Events.REDRAW_GRAPHICAL_ROOM,
                        {
                            entity: affectedRooms[i]
                        });
                }
            }
        }

        eventsManager.instance.dispatch(Events.ADD_GRAPHICAL_ENTITY,
            {
                entity: wall,
            });

        //Recompute all items
        for (i = 0; i < itemToRecompute.length; ++i) {
            let item = itemToRecompute[i];
            //Recompute parent
            if (item.isArrangementObjectEntity() || item.isArrangementGroupEntity() || item.isRenderCameraEntity() || item.isGeometryPrimitiveEntity() || item.isWorktopEntity()) {
                // Find containing room
                let roomContaining = null;

                if (item.isWorktopEntity()) {
                    let area = item.getComponent(ComponentConstants.ComponentType.Area);
                    if (area) {
                        roomContaining = roomManager.getRoomAtPosition(area.vertices[0], this._currentFloor);
                    }
                }
                else {
                    roomContaining = roomManager.getRoomAtPosition(item.position, this._currentFloor);
                }

                if (roomContaining !== null) {
                    //set item child of room
                    if (item.parent !== null) {
                        item.parent.deleteChild(item.id);
                    }
                    roomContaining.addChild(item, 100);
                }
                else {
                    //set item child of floor
                    if (item.parent !== null) {
                        item.parent.deleteChild(item.id);
                    }
                    this._currentFloor.addChild(item);
                }
                //Graphical node
                eventsManager.instance.dispatch(Events.ADD_GRAPHICAL_ENTITY,
                    {
                        entity: item,
                    });
            }
        }
    }

    deleteWall(id: number, tryCreateRoom: boolean) {
        let i, j;

        if (tryCreateRoom === null || tryCreateRoom === undefined) {
            tryCreateRoom = true;
        }
        //Retrieve wall framework entity
        let wall = this._currentFloor.getWall(id);

        if (wall === null) {
            return null;
        }

        //Remove graphical node even for unprocessed wall
        eventsManager.instance.dispatch(Events.REMOVE_GRAPHICAL_ENTITY,
            {
                entity: wall,
            });

        let rooms = [];
        let itemToRecompute = [];
        for (i = 0; i < wall.rooms.length; i++) {
            rooms.push(wall.rooms[i]);
            for (j = 0; j < wall.rooms[i].children.length; j++) {
                itemToRecompute.push(wall.rooms[i].children[j]);
            }
        }
        //Remove from framework and update display
        let roomsToUpdate = this._currentFloor.scene.deleteWall(id, tryCreateRoom);
        for (i = 0 ; i < roomsToUpdate.length ; i++) {
            eventsManager.instance.dispatch(Events.REDRAW_GRAPHICAL_ROOM,
                {
                    entity: roomsToUpdate[i]
                });
        }
        //GameObjectManager.updateRooms(roomsToUpdate);
        for (i = 0; i < this._selectedEntities.length; i++) {
            if (this._selectedEntities[i].id === id && this._selectedEntities[i].isWallEntity()) {
                this._selectedEntities.splice(i, 1);
                break;
            }
        }

        //Recompute all items
        for (i = 0; i < itemToRecompute.length; i++) {
            let item = itemToRecompute[i];
            //Recompute parent
            if (item.isArrangementObjectEntity() || item.isArrangementGroupEntity() || item.isRenderCameraEntity() || item.isGeometryPrimitiveEntity() || item.isWorktopEntity()) {
                // Find containing room
                let roomContaining = null;

                if (item.isWorktopEntity()) {
                    let area = item.getComponent(ComponentConstants.ComponentType.Area);
                    if (area) {
                        roomContaining = roomManager.getRoomAtPosition(area.vertices[0], this._currentFloor);
                    }
                }
                else {
                    roomContaining = roomManager.getRoomAtPosition(item.position, this._currentFloor);
                }

                if (roomContaining !== null) {
                    //set item child of room
                    if (item.parent !== null) {
                        item.parent.deleteChild(item.id);
                    }
                    roomContaining.addChild(item, 100);
                } else {
                    //set item child of floor
                    if (item.parent !== null) {
                        item.parent.deleteChild(item.id);
                    }
                    this._currentFloor.addChild(item);
                }
                //Graphical node
                eventsManager.instance.dispatch(Events.ADD_GRAPHICAL_ENTITY,
                    {
                        entity: item,
                    });
            }
        }
    }

    execute() {
        let i, j;

        for (i = 0; i < this._wallEdited.rooms.length; i++) {
            let roomEdited = this._wallEdited.rooms[i];

            if (roomEdited.wallOrientationInRoom(this._wallEdited, undefined)) {
                this._roomSavedDirect = EntityFactory.cloneRoom(roomEdited, false, false);
                for (j = 0; j < roomEdited.walls.length; j++) {
                    this._roomSavedDirect.addWall(roomEdited.walls[j]);
                }
            } else {
                this._roomSavedUndirect = EntityFactory.cloneRoom(roomEdited, false, false);
                for (j = 0; j < roomEdited.walls.length; j++) {
                    this._roomSavedUndirect.addWall(roomEdited.walls[j]);
                }
            }
        }

        if (this._roomEditedDirect !== null && this._roomSavedDirect !== null) {
            this._currentFloor.deleteRoom(this._roomSavedDirect.id);
            eventsManager.instance.dispatch(Events.REMOVE_GRAPHICAL_ENTITY,
                {
                    entity: this._roomSavedDirect,
                });
        }
        if (this._roomEditedUndirect !== null && this._roomSavedUndirect !== null) {
            this._currentFloor.deleteRoom(this._roomSavedUndirect.id);
            eventsManager.instance.dispatch(Events.REMOVE_GRAPHICAL_ENTITY,
                {
                    entity: this._roomSavedUndirect,
                });
        }

        if (this._wallsMerged[1] !== null) {
            wallManager.mergeWallsForCommand(this._wallsMerged[1], this._wallsBeforeMerge[1], this._currentFloor, this._selectedEntities);
        }

        if (this._wallsMerged[0] !== null) {
            wallManager.mergeWallsForCommand(this._wallsMerged[0], this._wallsBeforeMerge[0], this._currentFloor, this._selectedEntities);
        }

        if (this._wallsCutted[1] !== null) {
            wallManager.cutWallForCommand(this._wallsBeforeCut[1], this._wallsCutted[1], this._currentFloor, this._selectedEntities);

            if (this._wallsCutted[1][0].isBegin(this._endPointPosition, SavaneConstants.PositionTolerance * 2)) {
                this._wallsCutted[1][0].begin = this._endPointPosition;
            }
            if (this._wallsCutted[1][0].isEnd(this._endPointPosition, SavaneConstants.PositionTolerance * 2)) {
                this._wallsCutted[1][0].end = this._endPointPosition;
            }
            if (this._wallsCutted[1][1].isBegin(this._endPointPosition, SavaneConstants.PositionTolerance * 2)) {
                this._wallsCutted[1][1].begin = this._endPointPosition;
            }
            if (this._wallsCutted[1][1].isEnd(this._endPointPosition, SavaneConstants.PositionTolerance * 2)) {
                this._wallsCutted[1][1].end = this._endPointPosition;
            }
        }

        if (this._wallsCutted[0] !== null) {
            wallManager.cutWallForCommand(this._wallsBeforeCut[0], this._wallsCutted[0], this._currentFloor, this._selectedEntities);

            if (this._wallsCutted[0][0].isBegin(this._beginPointPosition, SavaneConstants.PositionTolerance * 2)) {
                this._wallsCutted[0][0].begin = this._beginPointPosition;
            }
            if (this._wallsCutted[0][0].isEnd(this._beginPointPosition, SavaneConstants.PositionTolerance * 2)) {
                this._wallsCutted[0][0].end = this._beginPointPosition;
            }
            if (this._wallsCutted[0][1].isBegin(this._beginPointPosition, SavaneConstants.PositionTolerance * 2)) {
                this._wallsCutted[0][1].begin = this._beginPointPosition;
            }
            if (this._wallsCutted[0][1].isEnd(this._beginPointPosition, SavaneConstants.PositionTolerance * 2)) {
                this._wallsCutted[0][1].end = this._beginPointPosition;
            }
        }

        if (this._wallsAdded[1] !== null) {
            if (this._wallsAdded[1].isCorner(this._endPointPosition)) {
                this._wallsAdded[1].end = this._endPointPosition;
                this._wallsAdded[1].begin = this._endPointPosition;
            }

            this.addWall(this._wallsAdded[1], false);
        }

        if (this._wallsAdded[0] !== null) {
            if (this._wallsAdded[0].isCorner(this._beginPointPosition)) {
                this._wallsAdded[0].end = this._beginPointPosition;
                this._wallsAdded[0].begin = this._beginPointPosition;
            }
            this.addWall(this._wallsAdded[0], false);
        }

        if (this._wallsDeleted[1] !== null) {
            this.deleteWall(this._wallsDeleted[1].id, false);
        }

        if (this._wallsDeleted[0] !== null) {
            this.deleteWall(this._wallsDeleted[0].id, false);
        }
        
        let wall, position;
        for (i = 0; i < this._modifiedWalls1.length; i++) {
            wall = this._modifiedWalls1[i];
            let distOnWall = [];

            if (wall.isBegin(this._wallEdited.begin)) {
                for (j = 0; j < wall.joineries.length; j++) {
                    distOnWall.push(math.vec2.dist(wall.shiftedEnd, wall.joineries[j].position));
                }
                let originalLength = wall.length;
                wall.begin = this._endPoint1;
                let finalLength = wall.length;
                let direction = math.vec3.clone(wall.wallDirection);
                for (j = 0; j < wall.joineries.length; j++) {
                    if (wall.joineries[j].joineryType !== SceneConstants.JoineryType.velux) {
                        position = math.vec3.clone(wall.joineries[j].position);
                        position[0] = wall.shiftedEnd[0] - direction[0] * distOnWall[j];
                        position[1] = wall.shiftedEnd[1] - direction[1] * distOnWall[j];

                        wall.joineries[j].position = position;
                    }
                    else {
                        let deltaLength = finalLength - originalLength;
                        wall.joineries[j].transform.localMatrix[12] = wall.joineries[j]._transform.localMatrix[12] + deltaLength;
                    }
                }

            } else {
                for (j = 0; j < wall.joineries.length; j++) {
                    distOnWall.push(math.vec2.dist(wall.shiftedBegin, wall.joineries[j].position));
                }
                wall.end = this._endPoint1;
                let direction = math.vec3.clone(wall.wallDirection);
                for (j = 0; j < wall.joineries.length; j++) {
                    if (wall.joineries[j].joineryType !== SceneConstants.JoineryType.velux) {
                        position = math.vec3.clone(wall.joineries[j].position);
                        position[0] = wall.shiftedBegin[0] + direction[0] * distOnWall[j];
                        position[1] = wall.shiftedBegin[1] + direction[1] * distOnWall[j];

                        wall.joineries[j].position = position;
                    }
                }
            }
        }

        for (i = 0; i < this._modifiedWalls2.length; i++) {
            wall = this._modifiedWalls2[i];
            let distOnWall = [];

            if (wall.isBegin(this._wallEdited.end)) {
                for (j = 0; j < wall.joineries.length; j++) {
                    distOnWall.push(math.vec2.dist(wall.shiftedEnd, wall.joineries[j].position));
                }
                let originalLength = wall.length;
                wall.begin = this._endPoint2;
                let finalLength = wall.length;
                let direction = math.vec3.clone(wall.wallDirection);
                for (j = 0; j < wall.joineries.length; j++) {
                    if (wall.joineries[j].joineryType !== SceneConstants.JoineryType.velux) {
                        position = math.vec3.clone(wall.joineries[j].position);
                        position[0] = wall.shiftedEnd[0] - direction[0] * distOnWall[j];
                        position[1] = wall.shiftedEnd[1] - direction[1] * distOnWall[j];

                        wall.joineries[j].position = position;
                    }
                    else {
                        let deltaLength = finalLength - originalLength;
                        wall.joineries[j].transform.localMatrix[12] = wall.joineries[j]._transform.localMatrix[12] + deltaLength;
                    }
                }
            } else {
                for (j = 0; j < wall.joineries.length; j++) {
                    distOnWall.push(math.vec2.dist(wall.shiftedBegin, wall.joineries[j].position));
                }
                wall.end = this._endPoint2;
                let direction = math.vec3.clone(wall.wallDirection);
                for (j = 0; j < wall.joineries.length; j++) {
                    if (wall.joineries[j].joineryType !== SceneConstants.JoineryType.velux) {
                        position = math.vec3.clone(wall.joineries[j].position);
                        position[0] = wall.shiftedBegin[0] + direction[0] * distOnWall[j];
                        position[1] = wall.shiftedBegin[1] + direction[1] * distOnWall[j];

                        wall.joineries[j].position = position;
                    }
                }
            }
        }

        this._wallEdited.begin = this._endPoint1;
        this._wallEdited.end = this._endPoint2;

        if (this._roomEditedDirect !== null && this._roomSavedDirect !== null) {
            this._currentFloor.addRoom(this._roomEditedDirect);
            eventsManager.instance.dispatch(Events.REDRAW_GRAPHICAL_ROOM,
                {
                    entity: this._roomEditedDirect
                });
            this._roomEditedDirect.wallsOrderer = false;
        }
        if (this._roomEditedUndirect !== null && this._roomSavedUndirect !== null) {
            this._currentFloor.addRoom(this._roomEditedUndirect);
            eventsManager.instance.dispatch(Events.REDRAW_GRAPHICAL_ROOM,
                {
                    entity: this._roomEditedUndirect
                });
            this._roomEditedUndirect.wallsOrderer = false;
        }

        roomManager.deleteNonRoomedWall(this._wallEdited.id, this._currentFloor);

        if (this.firstTime) {
            let beforeWalls, afterWall;
            this.firstTime = false;
            this._wallsMergedAfterEdition = [];
            this._wallsBeforeMergeAfterEdition = [];
            let wallsAtBegin = wallManager.getWallsAtPosition(this._endPoint1, this._currentFloor, SavaneConstants.PositionTolerance, undefined);
            if (wallsAtBegin !== null && wallsAtBegin.length === 2 && Wall.mergeWall(wallsAtBegin[0], wallsAtBegin[1]) !== null) {
                beforeWalls = [wallsAtBegin[0], wallsAtBegin[1]];
                afterWall = wallManager.mergeWall(wallsAtBegin[0], wallsAtBegin[1]);
                this._wallsBeforeMergeAfterEdition.push(beforeWalls);
                this._wallsMergedAfterEdition.push(afterWall);
                this.isWallEditedMerge = true;
                wallManager.mergeWallsForCommand(afterWall, beforeWalls, this._currentFloor, this._selectedEntities);
            }
            let wallsAtEnd = wallManager.getWallsAtPosition(this._endPoint2, this._currentFloor, SavaneConstants.PositionTolerance, undefined);
            if (wallsAtEnd !== null && wallsAtEnd.length === 2 && Wall.mergeWall(wallsAtEnd[0], wallsAtEnd[1]) !== null) {
                beforeWalls = [wallsAtEnd[0], wallsAtEnd[1]];
                afterWall = wallManager.mergeWall(wallsAtEnd[0], wallsAtEnd[1]);
                this._wallsBeforeMergeAfterEdition.push(beforeWalls);
                this._wallsMergedAfterEdition.push(afterWall);
                this.isWallEditedMerge = true;
                wallManager.mergeWallsForCommand(afterWall, beforeWalls, this._currentFloor, this._selectedEntities);

            }
            let otherPoint, wallsAtPoint;
            if (this._wallsAdded[0] !== null) {
                otherPoint = this._wallsAdded[0].isBegin(this._endPoint1) ? this._wallsAdded[0].end : this._wallsAdded[0].begin;
                wallsAtPoint = wallManager.getWallsAtPosition(otherPoint, this._currentFloor, SavaneConstants.PositionTolerance, undefined);
                if (wallsAtPoint !== null && wallsAtPoint.length === 2 && Wall.mergeWall(wallsAtPoint[0], wallsAtPoint[1]) !== null) {
                    beforeWalls = [wallsAtPoint[0], wallsAtPoint[1]];
                    afterWall = wallManager.mergeWall(wallsAtPoint[0], wallsAtPoint[1]);
                    this._wallsBeforeMergeAfterEdition.push(beforeWalls);
                    this._wallsMergedAfterEdition.push(afterWall);
                    wallManager.mergeWallsForCommand(afterWall, beforeWalls, this._currentFloor, this._selectedEntities);
                }
            }

            if (this._wallsAdded[1] !== null) {
                otherPoint = this._wallsAdded[1].isBegin(this._endPoint2) ? this._wallsAdded[1].end : this._wallsAdded[1].begin;
                wallsAtPoint = wallManager.getWallsAtPosition(otherPoint, this._currentFloor, SavaneConstants.PositionTolerance, undefined);
                if (wallsAtPoint !== null && wallsAtPoint.length === 2 && Wall.mergeWall(wallsAtPoint[0], wallsAtPoint[1]) !== null) {
                    beforeWalls = [wallsAtPoint[0], wallsAtPoint[1]];
                    afterWall = wallManager.mergeWall(wallsAtPoint[0], wallsAtPoint[1]);
                    this._wallsBeforeMergeAfterEdition.push(beforeWalls);
                    this._wallsMergedAfterEdition.push(afterWall);
                    wallManager.mergeWallsForCommand(afterWall, beforeWalls, this._currentFloor, this._selectedEntities);
                }
            }
        }

        for (i = 0; i < this._joineriesToCheck.length; i++) {
            let wallAtJoineryPos = wallManager.getWallsAtPosition(this._joineriesToCheckPos[i], this._currentFloor, SavaneConstants.PositionTolerance, undefined);
            if (wallAtJoineryPos !== null && wallAtJoineryPos.length === 1 && wallAtJoineryPos[0].id !== this._joineriesToCheck[i].wall.id) {
                this.deleteJoinery(this._joineriesToCheck[i].id);
                this._joineriesToCheck[i].wall = wallAtJoineryPos[0];
                if (this._joineriesToCheck[i].wall.joineries.indexOf(this._joineriesToCheck[i]) !== -1) {
                    this.deleteJoinery(this._joineriesToCheck[i].id);
                }
                this.addJoinery(this._joineriesToCheck[i]);
            }
        }

        for (i = 0; i < this._joineriesDeleted.length; i++) {
            this.deleteJoinery(this._joineriesDeleted[i].id);
        }

        roomManager.manageNonRoomedWalls(this._currentFloor);

        let point = math.vec3.create();
        math.vec3.set(point, (this._endPoint1[0] + this._endPoint2[0]) / 2, (this._endPoint1[1] + this._endPoint2[1]) / 2, 0);
        let room = roomManager.getRoomAtPosition(point, this._currentFloor);
        if (room !== null) {
            eventsManager.instance.dispatch(Events.REDRAW_GRAPHICAL_ROOM,
                {
                    entity: room
                });
        }

        if (!this.isWallEditedMerge) {
            eventsManager.instance.dispatch(Events.REDRAW_GRAPHICAL_WALL,
                {
                    entity: this._wallEdited
                });
        }
        let toDraw;
        for (i = 0; i < this._modifiedWalls1.length; i++) {
            toDraw = true;
            for (j = 0; j < this._wallsBeforeMergeAfterEdition.length; j++) {
                if (this._wallsBeforeMergeAfterEdition[j].indexOf(this._modifiedWalls1[i]) !== -1) {
                    toDraw = false;
                }
            }
            if (toDraw) {
                eventsManager.instance.dispatch(Events.REDRAW_GRAPHICAL_WALL,
                    {
                        entity: this._modifiedWalls1[i]
                    });
            }
        }
        for (i = 0; i < this._modifiedWalls2.length; i++) {
            toDraw = true;
            for (j = 0; j < this._wallsBeforeMergeAfterEdition.length; j++) {
                if (this._wallsBeforeMergeAfterEdition[j].indexOf(this._modifiedWalls2[i]) !== -1) {
                    toDraw = false;
                }
            }
            if (toDraw) {
                eventsManager.instance.dispatch(Events.REDRAW_GRAPHICAL_WALL,
                    {
                        entity: this._modifiedWalls2[i]
                    });
            }
        }

        //Recompute all items
        for (i = 0; i < this._toRecompute.length; i++) {
            let item = this._toRecompute[i];
            //Recompute parent
            if (item.isArrangementObjectEntity() || item.isArrangementGroupEntity() || item.isRenderCameraEntity() || item.isGeometryPrimitiveEntity() || item.isWorktopEntity()) {
                // Find containing room
                let roomContaining = null;

                if (item.isWorktopEntity()) {
                    let area = item.getComponent(ComponentConstants.ComponentType.Area);
                    if (area) {
                        roomContaining = roomManager.getRoomAtPosition(area.vertices[0], this._currentFloor);
                    }
                }
                else {
                    roomContaining = roomManager.getRoomAtPosition(item.position, this._currentFloor);
                }

                if (roomContaining !== null) {
                    //set item child of room
                    if (item.parent !== null) {
                        item.parent.deleteChild(item.id);
                    }
                    roomContaining.addChild(item);
                } else {
                    //set item child of floor
                    if (item.parent !== null) {
                        item.parent.deleteChild(item.id);
                    }
                    this._currentFloor.addChild(item);
                }
                //Graphical node
                eventsManager.instance.dispatch(Events.ADD_GRAPHICAL_ENTITY,
                    {
                        entity: item,
                    });
            }
        }

        let selection = [];

        selection.push(this._wallEdited);
        selection = selection.concat(this._modifiedWalls1);
        selection = selection.concat(this._modifiedWalls2);
        for (i = 0 ; i < this._wallsAdded ; i++) {
            selection.push(this._wallsAdded[i]);
        }

        // Select object at the end
        eventsManager.instance.dispatch(Events.CHANGE_EDITOR_SELECTION, {
            selection: selection,
            keepSelected: false,
            showTulip: false,
        });
    }
}
