import { ComponentConstants } from '../SavaneJS';
import { Component } from './Component';

export class CorniceType extends Component {
    protected _corniceTypeId: string;

    constructor(amid: string) {
        super();
        this._corniceTypeId = amid;
        this._isUnique = true;
    }

    clone(): CorniceType {
        return new CorniceType(this._corniceTypeId);
    }

    get componentType(): ComponentConstants.ComponentType {
        return ComponentConstants.ComponentType.CorniceType;
    }

    get corniceTypeId(): string {
        return this._corniceTypeId;
    }

    set corniceTypeId(amid: string) {
        this._corniceTypeId = amid;
    }
}
