import { glMatrix } from "../../../math/Math";
import { SceneConstants } from "../../../scene/SceneConstants";
import { Joinery } from "./Joinery";
import { Slope } from "./Slope";
import { Wall } from "./Wall";

export class SlopeJoinery extends Joinery {

    private _C: glMatrix.vec3;
    private _D: glMatrix.vec3;

    constructor(A: glMatrix.vec3, B: glMatrix.vec3, C: glMatrix.vec3, D: glMatrix.vec3, wall: Slope | Wall, joineryData: any, orientation: SceneConstants.HandleSide){
        var AC = glMatrix.vec3.create();
        glMatrix.vec3.subtract(AC, C, A);
        super(A, B, glMatrix.vec3.length(AC), A[2], wall, joineryData, orientation);

        this._type = 'SLOPE';
        this._C = C;
        this._D = D;
    }
    
    get C() : glMatrix.vec3 {
        return this._C;
    }

    get D() : glMatrix.vec3 {
        return this._D;
    }

    get Center() : glMatrix.vec3 {
        return glMatrix.vec3.fromValues((this.A[0] + this.D[0]) / 2, (this.A[1] + this.D[1]) / 2, (this.A[2] + this.D[2]) / 2);
    }

}