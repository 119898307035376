import { Wall } from "./Wall";
import { glMatrix } from "../../../math/Math";
import { Photo2World } from "../Photo2World"

export class Partition extends Wall {

    private _wallA: Wall;
    private _wallB: Wall;

    constructor(A: glMatrix.vec3, B: glMatrix.vec3, height: number, thickness: number, invertNormal: boolean) {
        super(A, B, height, thickness);

        this._type = "PARTITION";
        this._wallA = null;
        this._wallB = null;

        this._invert = invertNormal;
        if (thickness < 0) {
            this._invert = !this._invert;
        }
        this._computePlane();
    }

    _isShifted(photo2world: Photo2World, wall: Wall, P: glMatrix.vec3) : boolean {
        if (!wall) {
            return false;
        }

        if (Math.abs(glMatrix.vec3.dot(wall.N, this.N)) > 0.99) { //parallel walls
            return true;
        }

        var index = photo2world.Model.Walls.indexOf(wall);
        var previous = photo2world.Model.Walls[(index - 1 + photo2world.Model.Walls.length) % photo2world.Model.Walls.length];
        var next = photo2world.Model.Walls[(index + 1) % photo2world.Model.Walls.length];

        var adjacentWall = null;
        if (glMatrix.vec3.distance(P, previous.B) < 1) {
            adjacentWall = previous;
        } else if (glMatrix.vec3.distance(P, next.A) < 1) {
            adjacentWall = next;
        }

        if (adjacentWall === null) {
            return false;
        }

        if (Math.abs(glMatrix.vec3.dot(adjacentWall.N, this.N)) > 0.99) { //parallel walls
            return true;
        }

        return false;
    }

    _isOnExtremity(wall: Wall, P: glMatrix.vec3) : boolean {
        if (!wall) {
            return false;
        }

        if (glMatrix.vec3.distance(P, wall.A) < 1) {
            return true;
        }

        if (glMatrix.vec3.distance(P, wall.B) < 1) {
            return true;
        }

        return false;
    }

    get WallA() : Wall {
        return this._wallA;
    }

    set WallA(value: Wall) {
        this._wallA = value;
    }

    get IsOnExtremity() : boolean {
        return this.IsOnWallAExtrimity || this.IsOnWallBExtrimity;
    }

    get IsOnWallAExtrimity() : boolean {
        return this._isOnExtremity(this.WallA, this.A);
    }

    get IsOnWallBExtrimity() : boolean {
        return this._isOnExtremity(this.WallB, this.B);
    }

    IsShifted(photo2world: Photo2World) : boolean {
        if (!this.WallA && !this.WallB) {
            return false;
        }

        return this._isShifted(photo2world, this.WallA, this.A) || this._isShifted(photo2world, this.WallB, this.B);
    }

    IsAShifted(photo2world: Photo2World) : boolean {
        return this._isShifted(photo2world, this.WallA, this.A);
    }

    get ShiftPositionWallA() : glMatrix.vec3 | null {
        if (!this.WallA) {
            return null;
        }

        if (glMatrix.vec3.distance(this.A, this.WallA.A) < 10) {
            var middleA = glMatrix.vec3.clone(this.WallA.MiddleA);
            middleA[0] -= this.N[0] * this.WallA.Thickness / 2;
            middleA[1] -= this.N[1] * this.WallA.Thickness / 2;
            return middleA;
        }

        if (glMatrix.vec3.distance(this.A, this.WallA.B) < 10) {
            var middleB = glMatrix.vec3.clone(this.WallA.MiddleB);
            middleB[0] -= this.N[0] * this.WallA.Thickness / 2;
            middleB[1] -= this.N[1] * this.WallA.Thickness / 2;
            return middleB;
        }

        return null;
    }

    get OppositeShiftPositionWallA() : glMatrix.vec3 | null {
        if (!this.WallA) {
            return null;
        }

        if (glMatrix.vec3.distance(this.A, this.WallA.A) < 10 || glMatrix.vec3.distance(this.A, this.WallA.B) < 10) {
            var result = glMatrix.vec3.clone(this.B);
            result[0] -= this.N[0] * this.WallA.Thickness / 2;
            result[1] -= this.N[1] * this.WallA.Thickness / 2;
            return result;
        }

        return null;
    }

    get WallB() : Wall {
        return this._wallB;
    }

    set WallB(value: Wall) {
        this._wallB = value;
    }

    IsBShifted(photo2world: Photo2World): boolean {
        return this._isShifted(photo2world, this.WallB, this.B);
    }

    get ShiftPositionWallB() : glMatrix.vec3 | null {
        if (!this.WallB) {
            return null;
        }

        if (glMatrix.vec3.distance(this.B, this.WallB.A) < 10) {
            var middleA = glMatrix.vec3.clone(this.WallB.MiddleA);
            middleA[0] -= this.N[0] * this.WallB.Thickness / 2;
            middleA[1] -= this.N[1] * this.WallB.Thickness / 2;
            return middleA;
        }

        if (glMatrix.vec3.distance(this.B, this.WallB.B) < 10) {
            var middleB = glMatrix.vec3.clone(this.WallB.MiddleB);
            middleB[0] -= this.N[0] * this.WallB.Thickness / 2;
            middleB[1] -= this.N[1] * this.WallB.Thickness / 2;
            return middleB;
        }

        return null;
    }

    get OppositeShiftPositionWallB() : glMatrix.vec3 | null {
        if (!this.WallB) {
            return null;
        }

        if (glMatrix.vec3.distance(this.B, this.WallB.A) < 10 || glMatrix.vec3.distance(this.B, this.WallB.B) < 10) {
            var result = glMatrix.vec3.clone(this.A);
            result[0] -= this.N[0] * this.WallB.Thickness / 2;
            result[1] -= this.N[1] * this.WallB.Thickness / 2;
            return result;
        }

        return null;
    }

    ShiftDirection(photo2world: Photo2World) : number {
        if (!this.IsShifted(photo2world)) { //not a corner - no shift
            return 0;
        }

        if (this.IsAShifted(photo2world)) {
            if (glMatrix.vec3.distance(this.A, this.WallA.A) < 10) {
                return (this._thickness > 0 ? -1 : 1);
            }
    
            if (glMatrix.vec3.distance(this.A, this.WallA.B) < 10) {
                return (this._thickness > 0 ? 1 : -1);
            }
        }

        if (this.IsBShifted(photo2world)) {
            if (glMatrix.vec3.distance(this.B, this.WallB.A) < 10) {
                return (this._thickness > 0 ? 1 : -1);
            }

            if (glMatrix.vec3.distance(this.B, this.WallB.B) < 10) {
                return (this._thickness > 0 ? -1 : 1);
            }
        }

        return 0;
    }

}
