import { ComponentConstants, math } from '../SavaneJS';
import { Component } from './Component';

declare var Sentry;

export class Coating extends Component {
    protected _coatingId: string;
    protected _manufacturer: any;
    protected _retailer: any;
    protected _hangType: Coating.HangType;
    protected _colors: any;
    protected _randomization: any;
    protected _floorGeneratorSettings: any;
    protected _offset: math.vec2 = math.vec2.create();
    private _repeat: math.vec2 = math.vec2.create();
    protected _colorIndex: number;
    private _rotation: number;
    private _usemtlName: string;
    private _locked: boolean;
    private _configurable: boolean = false;

    public urlPreview: string;

    constructor(coatingId: string, manufacturer: any, retailer: any, hangType: Coating.HangType, colors: any, randomization: any, floorGeneratorSettings: any, coatingUrl: string) {

        super();
        //Values from asset manager
        this._coatingId = coatingId;
        if (manufacturer) {
            this._manufacturer = {name: manufacturer.name,
                                  _id: manufacturer._id};
        };
        if (retailer) {
            this._retailer = {name: retailer.name,
                              _id: retailer._id};
        }
        this._hangType = hangType;
        this._colors = colors;
        this._randomization = randomization;
        this._floorGeneratorSettings = floorGeneratorSettings ? JSON.parse(JSON.stringify(floorGeneratorSettings)) : undefined;
        if (this._floorGeneratorSettings) {
            if (!this._floorGeneratorSettings.tile) {
                try {
                    throw new TypeError('FloorGenerator coating bad configuration: ' + this._coatingId);
                } catch (error) {
                    Sentry.captureException(error);
                }
            }

            if (this._floorGeneratorSettings.chevronAngle === undefined) {
                this._floorGeneratorSettings.chevronAngle = 45;
            }
            if (this._floorGeneratorSettings.offsetX === undefined) {
                this._floorGeneratorSettings.offsetX = 0;
            }
            if (this._floorGeneratorSettings.offsetY === undefined) {
                this._floorGeneratorSettings.offsetY = 0;
            }
            if (this._floorGeneratorSettings.cut === undefined) {
                this._floorGeneratorSettings.cut = true;
            }
            if (this._floorGeneratorSettings.cleat === undefined) {
                this._floorGeneratorSettings.cleat = false;
            }
            if (this._floorGeneratorSettings.weaveNumber === undefined) {
                this._floorGeneratorSettings.weaveNumber = 3;
            }
            if (this._floorGeneratorSettings.tile.dimensions.maxWidth === undefined) {
                this._floorGeneratorSettings.tile.dimensions.maxWidth = 0;
            }
            if (this._floorGeneratorSettings.tile.dimensions.maxLength === undefined) {
                this._floorGeneratorSettings.tile.dimensions.maxLength = 0;
            }
        }
        this.urlPreview = coatingUrl !== null && coatingUrl !== undefined ? coatingUrl : '';
        if (this._colors && this._colors.length > 0 && this._colors[0].index !== undefined) {
            this._colorIndex = this._colors[0].index - 0;
        } else {
            this._colorIndex = 1;
        }
        math.vec2.set(this.offset, 0, 0);
        math.vec2.set(this.repeat, 1, 1);
        this._rotation = 0;
        this._usemtlName = '';
        this._locked = false;
    }

    clone(): Coating {
        let coat = new Coating(this._coatingId, this._manufacturer, this._retailer, this._hangType, this._colors, this._randomization, this._floorGeneratorSettings, this.urlPreview);
        coat.colorIndex = this._colorIndex;
        coat.offset = this._offset;
        coat.repeat = this._repeat;
        coat.rotation = this._rotation;
        coat.usemtlName = this._usemtlName;
        coat.locked = this._locked;
        return coat;
    }

    get name(): string {
        let n = 'Matériau de ';
        switch (this.hangType) {
            case Coating.HangType.floor:
                return(n + 'sol');
            case Coating.HangType.ceiling:
                return(n + 'plafond');
            case Coating.HangType.wallDirect:
                return(n + 'mur direct');
            case Coating.HangType.wallUndirect:
                return(n + 'mur indirect');
            case Coating.HangType.joinery:
                return(n + 'menuiserie');
            case Coating.HangType.technicalElement:
                return(n + 'élément technique');
            case Coating.HangType.usemtl:
                return(n + 'sous élément');
            case Coating.HangType.slopeDirect:
                return(n + 'sous-pente directe');
            case Coating.HangType.slopeUndirect:
                return(n + 'sous-pente indirecte');
            case Coating.HangType.plinthDirect:
                return(n + 'plinthe directe');
            case Coating.HangType.plinthUndirect:
                return(n + 'plinthe indirecte');
            case Coating.HangType.corniceDirect:
                return(n + 'corniche directe');
            case Coating.HangType.corniceUndirect:
                return(n + 'corniche indirecte');
            case Coating.HangType.wallTop:
                return(n + 'mur dessus');
            case Coating.HangType.wallBottom:
                return(n + 'mur dessous');
            case Coating.HangType.wallLeftSide:
                return(n + 'mur côté gauche');
            case Coating.HangType.wallRightSide:
                return(n + 'mur côté droit');
            case Coating.HangType.arrangementObject:
                return(n + "d'objet");
            default:
              return(n + 'inconnu');
        }
    }

    set locked(l: boolean) {
      this._locked = l;
    }

    get locked(): boolean {
      return(this._locked);
    }

    set usemtlName(n: string) {
        this._usemtlName = n;
    }

    get usemtlName(): string {
        return(this._usemtlName);
    }

    set manufacturer(manuf: any) {
        if (!manuf) return;
        this._manufacturer = {name: manuf.name,
            _id: manuf._id};
    }

    get manufacturer(): any {
        return (this._manufacturer);
    }

    set retailer(retail: any) {
        if (!retail) return;
        this._retailer = {name: retail.name,
            _id: retail._id};
    }

    get retailer(): any {
        return (this._retailer);
    }

    get componentType(): ComponentConstants.ComponentType {
        return ComponentConstants.ComponentType.Coating;
    }

    get colors(): any {
        return this._colors;
    }

    set colors(c: any) {
        this._colors = c;
    }

    get color(): any {
        if (this._colors) {
            for (let i = 0; i < this._colors.length; i++) {
                if (this._colors[i].index === this._colorIndex) {
                    return this._colors[i].color;
                }
            }
        }
        return null;
    }

    get hangType(): Coating.HangType {
        return this._hangType;
    }

    get coatingId(): string {
        return this._coatingId;
    }

    set coatingId(amid: string) {
        this._coatingId = amid;
    }

    get offset(): math.vec2 {
        return this._offset;
    }

    get repeat(): math.vec2 {
        return this._repeat;
    }

    get colorIndex(): number {
        return this._colorIndex;
    }

    get randomization(): any {
        return this._randomization;
    }

    get floorGeneratorSettings(): any {
        return this._floorGeneratorSettings;
    }

    set offset(o: math.vec2) {
        this._offset = o;
    }

    set repeat(r: math.vec2) {
        this._repeat = r;
    }

    set colorIndex(v: any) {
        this._colorIndex = v - 0;
    }

    set hangType(h: Coating.HangType) {
        this._hangType = h;
    }

    set randomization(r: any) {
        this._randomization = r;
    }

    set floorGeneratorSettings(fgs: any) {
        this._floorGeneratorSettings = fgs;
    }

    get rotation(): number {
        return (this._rotation);
    }

    set rotation(r: number) {
        this._rotation = r;
    }

    get configurable() {
        return this._configurable;
    }
    
    set configurable(value) {
        this._configurable = value;
    }
}
/**
 * Enum for hanging type of coating
 *
 * @type {{floor: Number, ceiling: Number, wallDirect: Number, wallUndirect: Number}}
 */
export namespace Coating
{
    export enum HangType {
        floor = 0,
        ceiling = 1,
        wallDirect = 2,
        wallUndirect = 3,
        kitchenplinth = 4,
        door = 5,
        box = 6,
        gutter = 7,
        joinery = 8,
        technicalElement = 9,
        topBottom = 10,
        joint = 11, // not used now but must be kept
        sink = 12,
        custom = 13,
        panel = 14,
        shelf = 15,
        back = 16,
        front = 17,
        button = 18,
        flatpiece = 19,
        usemtl = 20,
        slopeDirect = 21,
        slopeUndirect = 22,
        arrangementObject = 23,
        plinthDirect = 24,
        plinthUndirect = 25,
        corniceDirect = 26,
        corniceUndirect = 27,
        wallTop = 28,
        wallBottom = 29,
        wallLeftSide = 30,
        wallRightSide = 31,
        mixer = 32
    };
}
