import { TemporarySun } from "./temporary/TemporaryModule";
import { SceneConstants, math } from "../SavaneJS";
import { Entity } from "./Entity";

/**
 * Scene component to handle Sun ligth for render
 */
export class Sun extends Entity {
    private _hour: number;
    private _weather: number;
    private _southDirection: math.vec2;
    private _exterior: string;
    private _additionalExterior: string;
    private _increaseDecorsSize: boolean;
    private _decorsRotation: number;
    private _altitude: number;
    private _shadowBlurRadius: number;
    private _temperature: number;
    private _skyIntensity: number;

    constructor(id?: number) {
        super();
        this.id = id;
        this._hour = 15;
        this._weather = 0;
        this._southDirection = math.vec2.create();
        math.vec2.set(this._southDirection, 0, 1);
        this._exterior = "defaut";
        this._additionalExterior = "tiergarten_16k_autumn copie"; // Garden decors
        this._increaseDecorsSize = false;
        this._decorsRotation = 0;
        this._altitude = 35;
        this._shadowBlurRadius = 0;
        this._temperature = 6250;
        this._skyIntensity = 1;
    }

    get entityType(): SceneConstants.EntityType {
        return SceneConstants.EntityType.Sun;
    }

    get altitude(): number {
        if (this.temporary === null) {
            return this._altitude;
        } else {
            return (this.temporary as TemporarySun).altitude;
        }
    }

    set altitude(a: number) {
        if (this.temporary === null) {
            this._altitude = a;
        } else {
            (this.temporary as TemporarySun).altitude = a;
        }
    }

    get weather(): number {
        if (this.temporary === null) {
            return this._weather;
        } else {
            return (this.temporary as TemporarySun).weather;
        }
    }

    set weather(w: number) {
        if (this.temporary === null) {
            this._weather = w;
        } else {
            (this.temporary as TemporarySun).weather = w;
        }
    }

    get southDirection(): math.vec2 {
        if (this.temporary === null) {
            return this._southDirection;
        } else {
            return (this.temporary as TemporarySun).southDirection;
        }
    }

    set southDirection(sd: math.vec2) {
        if (this.temporary === null) {
            this._southDirection = sd;
        } else {
            (this.temporary as TemporarySun).southDirection = sd;
        }
    }

    get topAngle(): number {
        if (this.temporary === null) {
            var toReturn = Math.round(Math.atan2(this._southDirection[1], this._southDirection[0]) * 180 / Math.PI);
            return(toReturn);
        }
        else {
            var toReturn = Math.round(Math.atan2((this.temporary as TemporarySun).southDirection[1], (this.temporary as TemporarySun).southDirection[0]) * 180 / Math.PI);
            return(toReturn);
        }
    }

    set topAngle(ta: number) {
        if (this.temporary === null) {
            this._southDirection[0] = Math.cos(ta * Math.PI / 180);
            this._southDirection[1] = Math.sin(ta * Math.PI / 180);
        } else {
            (this.temporary as TemporarySun).southDirection[0] = Math.cos(ta * Math.PI / 180);
            (this.temporary as TemporarySun).southDirection[1] = Math.sin(ta * Math.PI / 180);
        }
    }

    get exterior(): string {
        if (this.temporary === null) {
            return this._exterior;
        } else {
            return (this.temporary as TemporarySun).exterior;
        }
    }

    set exterior(e: string) {
        if (e === null) {
            e = "";
        }

        if (this.temporary === null) {
            this._exterior = e;
        } else {
            (this.temporary as TemporarySun).exterior = e;
        }
    }

    get additionalExterior(): string {
        if (this.temporary === null) {
            return this._additionalExterior;
        } else {
            return (this.temporary as TemporarySun).additionalExterior;
        }
    }

    set additionalExterior(ae: string) {
        if (ae === null) {
            ae = "";
        }

        if (this.temporary === null) {
            this._additionalExterior = ae;
        } else {
            (this.temporary as TemporarySun).additionalExterior = ae;
        }
    }

    get increaseDecorsSize(): boolean {
        if (this.temporary === null) {
            return this._increaseDecorsSize;
        } else {
            return (this.temporary as TemporarySun).increaseDecorsSize;
        }
    }

    set increaseDecorsSize(ids: boolean) {
        if (ids === null) {
            ids = false;
        }

        if (this.temporary === null) {
            this._increaseDecorsSize = ids;
        } else {
            (this.temporary as TemporarySun).increaseDecorsSize = ids;
        }
    }

    get decorsRotation(): number {
        if (this.temporary === null) {
            return this._decorsRotation;
        } else {
            return (this.temporary as TemporarySun).decorsRotation;
        }
    }

    set decorsRotation(dr: number) {
        if (dr === null) {
            dr = 0;
        }

        if (this.temporary === null) {
            this._decorsRotation = dr;
        } else {
            (this.temporary as TemporarySun).decorsRotation = dr;
        }
    }

    get shadowBlurRadius(): number {
        if (this.temporary === null) {
            return this._shadowBlurRadius;
        } else {
            return (this.temporary as TemporarySun).shadowBlurRadius;
        }
    }

    set shadowBlurRadius(sbr: number) {
        if (sbr === null) {
            sbr = 0;
        }

        if (this.temporary === null) {
            this._shadowBlurRadius = sbr;
        } else {
            (this.temporary as TemporarySun).shadowBlurRadius = sbr;
        }
    }

    get temperature(): number {
        if (this.temporary === null) {
            return this._temperature;
        } else {
            return (this.temporary as TemporarySun).temperature;
        }
    }

    set temperature(t: number) {
        if (t === null) {
            t = 6250;
        }

        if (this.temporary === null) {
            this._temperature = t;
        } else {
            (this.temporary as TemporarySun).temperature = t;
        }
    }

    get skyIntensity(): number {
        if (this.temporary === null) {
            return this._skyIntensity;
        } else {
            return (this.temporary as TemporarySun).skyIntensity;
        }
    }

    set skyIntensity(si: number) {
        if (si === null) {
            si = 1;
        }

        if (this.temporary === null) {
            this._skyIntensity = si;
        } else {
            (this.temporary as TemporarySun).skyIntensity = si;
        }
    }

    /**
     * Create the renderCamera that will be used during edition
     */
    startTemporary() {
        this.temporary = new TemporarySun(this);
    }

    /**li
     * delete the renderCamera
     */
    endTemporary() {
        this.temporary = null;
    }

    /**
     * save the temporary data in the sun and delete the temporarySun
     */
    saveAndEndTemporary() {
        var altitudeTemp = this.altitude;
        var weatherTemp = this.weather;
        var southDirectionTemp = this.southDirection;
        var exterior = this.exterior;
        var additionalExterior = this.additionalExterior;
        var increaseDecorsSize = this.increaseDecorsSize;
        var shadowBlurRadius = this.shadowBlurRadius;
        var temperature = this.temperature;
        var skyIntensity = this.skyIntensity;

        this.temporary = null;

        this.altitude = altitudeTemp;
        this.weather = weatherTemp;
        this.southDirection = southDirectionTemp;
        this.exterior = exterior;
        this.additionalExterior = additionalExterior;
        this.increaseDecorsSize = increaseDecorsSize;
        this.shadowBlurRadius = shadowBlurRadius;
        this.temperature = temperature;
        this.skyIntensity = skyIntensity;
    }
}
