import { Command, CommandEnum, AddComponentCommand, DeleteComponentsCommand, EditWallPropertiesCommand } from './CommandModule';
import { Component, Wall, ComponentConstants } from '../SavaneJS';

export class CopyPasteWallAndComponentsCommand  extends Command
{
    // Command list to execute and undo
    private _subCommands: Array<Command> = [];

    constructor(wall: Wall, components: Array<Component>) {
        super();
        // Command list to execute and undo
        this._subCommands.push(new EditWallPropertiesCommand(wall));
        // We will remove all coatings from the wall
        let coatings = wall.getComponents(ComponentConstants.ComponentType.Coating);
        this._subCommands.push(new DeleteComponentsCommand(coatings, true));
        // Then add the components to the modified wall
        for (let i = 0; i < components.length; ++i) {
            this._subCommands.push(new AddComponentCommand(components[i], wall, components[i].isUnique, false));
        }
    }

    name(): string {
        return CommandEnum.CopyPasteWallAndComponentsCommand;
    }

    // Undo the current command
    undo() {
        // Parse all commands created by the constructor and undo them all
        for (let i = 0; i < this._subCommands.length; i++) {
            this._subCommands[i].undo();
        }

        //Execute parent function
        super.undo();
    }

    // Execute current command (redo)
    execute() {
        // Parse all commands created by the constructor and execute them all
        for (let i = 0; i < this._subCommands.length; i++) {
            this._subCommands[i].execute();
        }

        //Execute parent function
        super.execute();
    }
};
