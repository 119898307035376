import earcut from "earcut";
import { math } from "../scene/Transform";
import { SavaneConstants } from "./SavaneConstants";
import { SceneConstants } from "../scene/SceneConstants";
import { Segment } from "./Segment";
import { Wall } from "../scene/Wall";
import { Room } from "../scene/Room";
/**
 * Class which contains static computing function
 */
export class SavaneMath {
    constructor() {
    }

    static equal(p1: math.vec3, p2: math.vec3): boolean {
        return math.vec3.dist(p1, p2) < SavaneConstants.PositionTolerance;
    }

    static getMiddle(p1: math.vec3, p2: math.vec3): math.vec3 {
        let res = math.vec3.create();
        math.vec3.set(res, (p1[0] + p2[0]) / 2, (p1[1] + p2[1]) / 2, (p1[2] + p2[2]) / 2);
        return res;
    }

    /**
     * compute the joinery extremity (value saved in point1 and point2)
     *
     * @param {Wall} wall
     * @param {Joinery} joinery
     * @param {*} point1
     * @param {*} point2
     * @param {*} point3 // optionals for velux only
     * @param {*} point4
     */
    static computeJoineryExtremities(wall, joinery, point1, point2, point3?, point4?) {
        let direction = wall.wallDirection;
        let normal = wall.normal;

        if (joinery.joineryType === SceneConstants.JoineryType.velux) {
            point1[0] = joinery.position[0] + direction[1] * -wall.shiftOffset - (joinery.length / 2) * direction[0];
            point1[1] = joinery.position[1] + direction[0] * wall.shiftOffset - (joinery.length / 2) * direction[1];
            point1[2] = joinery.floorHeight;
            point2[0] = joinery.position[0] + direction[1] * -wall.shiftOffset + (joinery.length / 2) * direction[0];
            point2[1] = joinery.position[1] + direction[0] * wall.shiftOffset + (joinery.length / 2) * direction[1];
            point2[2] = joinery.floorHeight;

            var height = joinery.height;
            if (joinery.wall != null) {
                var slope = joinery.wall.getSlope(joinery);
                var wheight = joinery.wall.height - joinery.wall.slopeHeight;
                var length;

                if (slope === 1) {
                    length = joinery.wall.slopeLength1;
                } else {
                    length = joinery.wall.slopeLength2;
                }

                if (length !== 0) {
                    var slopeAngle = Math.atan(wheight / length);

                    height = height * Math.cos(slopeAngle);
                }
            }

            if (point3 && point4) {
                point3[0] = joinery.position[0] + direction[1] * -wall.shiftOffset - (height / 2) * normal[0];
                point3[1] = joinery.position[1] + direction[0] * wall.shiftOffset - (height / 2) * normal[1];
                point3[2] = joinery.floorHeight;
                point4[0] = joinery.position[0] + direction[1] * -wall.shiftOffset + (height / 2) * normal[0];
                point4[1] = joinery.position[1] + direction[0] * wall.shiftOffset + (height / 2) * normal[1];
                point4[2] = joinery.floorHeight;
            }
        } else {
            let p = joinery.position;
            p[2] = 0;
            let d = math.vec3.dist(p, wall.begin);

            let d1 = d - joinery.length / 2;
            let d2 = d + joinery.length / 2;

            point1[0] = wall.begin[0] + direction[1] * -wall.shiftOffset + d1 * direction[0];
            point1[1] = wall.begin[1] + direction[0] * wall.shiftOffset + d1 * direction[1];
            point1[2] = joinery.floorHeight;
            point2[0] = wall.begin[0] + direction[1] * -wall.shiftOffset + d2 * direction[0];
            point2[1] = wall.begin[1] + direction[0] * wall.shiftOffset + d2 * direction[1];
            point2[2] = joinery.floorHeight;
        }
    }

    /**
     * compute the distance betwenn a line (defined by linePoint1 and linePoint2) width the point.
     *
     * @param {*} point
     * @param {*} linePoint1
     * @param {*} linePoint2
     */
    static distanceToLine(p: math.vec3, lineP1: math.vec3, lineP2: math.vec3): number {
        let a = lineP2[1] - lineP1[1];
        let b = lineP2[0] - lineP1[0];
        let c = lineP1[0] * a - lineP1[1] * b;

        return Math.abs(-a * p[0] + b * p[1] + c) / Math.sqrt(a * a + b * b);
    }

    /**
     * Get projection of the point on the line
     * @param point
     * @param linePoint1
     * @param linePoint2
     */
    static getProjection(p: math.vec3, lineP1: math.vec3, lineP2: math.vec3): math.vec3 {
        let direction = math.vec3.create();
        math.vec3.set(direction, lineP2[0] - lineP1[0], lineP2[1] - lineP1[1], 0);
        math.vec3.normalize(direction, direction);

        //line equation : -a *x  + b * y + c1 = 0
        let a = direction[0];
        let b = direction[1];
        let c1 = lineP1[0] * b - lineP1[1] * a;

        //equation pass through point have equation b *x  + a * y + c2 = 0
        let c2 = -p[0] * a - p[1] * b;

        //solving system:
        let res = math.vec3.create();
        math.vec3.set(res, c1 * b - c2 * a, -a * c1 - c2 * b, 0);
        return res;
    }

    /**
     * Test if the wall is contained in the room
     *
     * @param {Wall} wall
     * @param {Room} aRoom
     * @returns {*}
     */
    static isWallInRoom(wall: Wall, room: Room): boolean {
        let aPoint = math.vec3.create();
        let aWallEnd = wall.end;
        aPoint[0] = (wall.begin[0] + aWallEnd[0]) * 0.5;
        aPoint[1] = (wall.begin[1] + aWallEnd[1]) * 0.5;

        let points: Array<math.vec3> = new Array<math.vec3>();
        for (let i = 0; i < room.walls.length; i++) {
            if (wall.id === room.walls[i].id) {
                return false;
            }
            points[points.length] = SavaneMath.findCommonPoint(room.walls[i], room.walls[(i + 1) % room.walls.length]);
        }
        return SavaneMath.isInPoly(aPoint, points);
    }

    /**
     * Return the commons corner of the two walls
     *
     * @param {Wall} wall1
     * @param {Wall} wall2
     * @returns {vec3}
     */
    static findCommonPoint(wall1: Wall, wall2: Wall): math.vec3 {
        let res = math.vec3.create();
        if (SavaneMath.equal(wall1.begin, wall2.begin) || SavaneMath.equal(wall1.begin, wall2.end)) {
            math.vec3.copy(res, wall1.begin);
        } else if (SavaneMath.equal(wall1.end, wall2.begin) || SavaneMath.equal(wall1.end, wall2.end)) {
            math.vec3.copy(res, wall1.end);
        }
        return res;
    }

    /**
     * Test whereas the point is contained in the polygon
     *
     * @param {*} aPoint
     * @param {*} points
     * @returns {*}
     */
    static isInPoly(p: math.vec3, points: Array<math.vec3>): boolean {
        if (points.length < 3 || !p) {
            return false;
        }

        let point1;
        let point2;
        let cn = 0; // the  crossing number counter
        // loop through all edges of the polygon
        for (let i = 0; i < points.length - 1; i++) {
            // edge from V[i]  to V[i+1]
            point1 = points[i];
            point2 = points[i + 1];
            if (!point1 || !point2) return false;
            if ((point1[1] <= p[1] && point2[1] > p[1]) || (point1[1] > p[1] && point2[1] <= p[1])) {
                // a downward crossing
                // compute  the actual edge-ray intersect x-coordinate
                let vt = (p[1] - point1[1]) / (point2[1] - point1[1]);
                if (p[0] < point1[0] + vt * (point2[0] - point1[0])) {
                    ++cn; // a valid crossing of y=P[1] right of P[0]
                }
            }
        }
        point1 = points[points.length - 1];
        point2 = points[0];
        if ((point1[1] <= p[1] && point2[1] > p[1]) || (point1[1] > p[1] && point2[1] <= p[1])) {
            // a downward crossing
            // compute  the actual edge-ray intersect x-coordinate
            let vt = (p[1] - point1[1]) / (point2[1] - point1[1]);
            if (p[0] < point1[0] + vt * (point2[0] - point1[0])) {
                ++cn; // a valid crossing of y=P[1] right of P[0]
            }
        }
        return (cn & 1) === 1; // 0 if even (out), and 1 if  odd (in)
    }

    static fusePolys(poly1, poly2, precision = SavaneConstants.PositionTolerance) {
        let graph = {
            edges: [],
            vertices: [],
            addVertex: function (edge1, edge2) {
                let i, j, k;
                for (i = 0; i < this.edges.length; i++) {
                    if (math.vec3.dist(this.edges[i], edge1) < 10 * precision * precision) {
                        break;
                    }
                }
                if (i === this.edges.length) {
                    this.edges.push(edge1);
                }
                for (j = 0; j < this.edges.length; j++) {
                    if (math.vec3.dist(this.edges[j], edge2) < 10 * precision * precision) {
                        break;
                    }
                }
                if (j === this.edges.length) {
                    this.edges.push(edge2);
                }

                if (i !== j) {
                    for (k = 0; k < this.vertices.length; k++) {
                        if ((this.vertices[k][0] === i && this.vertices[k][1] === j) || (this.vertices[k][1] === i && this.vertices[k][0] === j)) {
                            break;
                        }
                    }
                    if (k === this.vertices.length) {
                        this.vertices.push([i, j]);
                    }
                }
            },
        };

        //create graph describing polygons
        let haveIntersection = false,
            add1 = false,
            add2 = false,
            toAdd,
            i = 0,
            j = 0,
            k = 0,
            seg1,
            seg2,
            found = false,
            crossPoint;
        while (i < poly1.length) {
            add1 = false;
            j = 0;
            seg1 = new Segment(poly1[i], poly1[(i + 1) % poly1.length]);
            found = false;
            while (j < poly2.length) {
                add2 = false;
                seg2 = new Segment(poly2[j], poly2[(j + 1) % poly2.length]);
                if (Segment.areSegmentCrossing(seg1, seg2, 10 * precision) && !Segment.areColinear(seg1, seg2, 10 * SavaneConstants.Tolerance)) {
                    crossPoint = this.getCrossPoint(seg1.begin, seg1.end, seg2.begin, seg2.end, 10 * SavaneConstants.Tolerance);
                    if (crossPoint) {
                        haveIntersection = true;
                        if (math.vec3.dist(seg2.begin, crossPoint) >= 10 * precision * precision && math.vec3.dist(seg2.end, crossPoint) >= 10 * precision * precision) {
                            //make sure the edge is not in the poly
                            toAdd = true;
                            for (k = 0; k < poly2.length; k++) {
                                if (math.vec3.dist(poly2[k], crossPoint) < 10 * precision * precision) {
                                    toAdd = false;
                                    crossPoint = math.vec3.clone(poly2[k]);
                                }
                            }
                            if (toAdd) {
                                poly2.splice(j + 1, 0, crossPoint);
                                add2 = true;
                            }
                        }
                        if (math.vec3.dist(seg1.begin, crossPoint) >= 10 * precision * precision && math.vec3.dist(seg1.end, crossPoint) >= 10 * precision * precision) {
                            //make sure the edge is not in the poly
                            toAdd = true;
                            for (k = 0; k < poly1.length; k++) {
                                if (math.vec3.dist(poly1[k], crossPoint) < 10 * precision * precision) {
                                    toAdd = false;
                                }
                            }
                            if (toAdd) {
                                poly1.splice(i + 1, 0, crossPoint);
                                add1 = true;
                            }
                        }
                    }
                }
                if (!add2) {
                    j++;
                }
                if (add1) {
                    break;
                }
            }

            if (!add1) {
                i++;
            }
        }
        for (i = 0; i < poly1.length; i++) {
            seg1 = new Segment(poly1[i], poly1[(i + 1) % poly1.length]);
            graph.addVertex(seg1.begin, seg1.end);
        }
        for (i = 0; i < poly2.length; i++) {
            seg2 = new Segment(poly2[i], poly2[(i + 1) % poly2.length]);
            graph.addVertex(seg2.begin, seg2.end);
        }

        if (!haveIntersection) {
            if (this.isInPoly(poly1[0], poly2)) {
                return poly2;
            } else if (this.isInPoly(poly2[0], poly1)) {
                return poly1;
            } else {
                return null;
            }
        } else {
            //find min point (minX , minY)
            let vertices = [],
                minX = graph.edges[0][0],
                minY = graph.edges[0][1],
                firstIndex = 0,
                currentIndex,
                index,
                nextIndex = -1;

            for (i = 0; i < graph.edges.length; i++) {
                if (graph.edges[i][0] < minX) {
                    minX = graph.edges[i][0];
                    minY = graph.edges[i][1];
                    firstIndex = i;
                } else if (graph.edges[i][0] === minX && graph.edges[i][1] < minY) {
                    minY = graph.edges[i][1];
                    firstIndex = i;
                }
            }
            vertices.push(graph.edges[firstIndex]);

            currentIndex = firstIndex;
            let vector1,
                vector2,
                errorHandler = 0,
                angle,
                maxAngle;
            while (errorHandler < graph.edges.length) {
                errorHandler++;
                maxAngle = 0;
                //find next ==>max angle betwen vector
                for (i = 0; i < graph.vertices.length; i++) {
                    index = null;
                    if (graph.vertices[i][0] === currentIndex) {
                        index = graph.vertices[i][1];
                    } else if (graph.vertices[i][1] === currentIndex) {
                        index = graph.vertices[i][0];
                    }

                    if (index !== null) {
                        if (vertices.length > 1) {
                            vector1 = math.vec3.create();
                            math.vec3.set(vector1, vertices[vertices.length - 2][0] - vertices[vertices.length - 1][0], vertices[vertices.length - 2][1] - vertices[vertices.length - 1][1], 0);
                            math.vec3.normalize(vector1, vector1);
                        } else {
                            vector1 = math.vec3.create();
                            vector1[1] = -1;
                        }

                        vector2 = math.vec3.create();
                        math.vec3.set(vector2, graph.edges[index][0] - vertices[vertices.length - 1][0], graph.edges[index][1] - vertices[vertices.length - 1][1], 0);
                        math.vec3.normalize(vector2, vector2);

                        angle = this.normalizeAngle(this.angleVectors(vector1, vector2));
                        if (angle > maxAngle) {
                            nextIndex = index;
                            maxAngle = angle;
                        }
                    }
                }

                if (nextIndex === firstIndex) {
                    break;
                } else {
                    vertices.push(graph.edges[nextIndex]);
                    currentIndex = nextIndex;
                }
            }

            if (nextIndex === firstIndex) {
                return vertices;
            }
            return null;
        }
    }

    /**
     * compute the angle betwenn two vector
     *
     * @param {*} v1
     * @param {*} v2
     * @returns {number}
     */
    static angleVectors(v1: math.vec3, v2: math.vec3): number {
        let ilengtha = 1.0 / Math.sqrt(v1[0] * v1[0] + v1[1] * v1[1]);
        let ax = v1[0] * ilengtha;
        let ay = v1[1] * ilengtha;
        let ilengthb = 1.0 / Math.sqrt(v2[0] * v2[0] + v2[1] * v2[1]);
        let bx = v2[0] * ilengthb;
        let by = v2[1] * ilengthb;
        let dot = ax * bx + ay * by;
        let cross = ax * by - ay * bx;

        if (dot === 0.0) {
            return cross < 0 ? -Math.PI * 0.5 : Math.PI * 0.5;
        } else if (cross === 0.0) {
            return dot < 0 ? Math.PI : 0;
        }

        let angle = Math.atan(cross / dot);
        if (angle < 0) {
            angle += Math.PI;
        }
        if (cross < 0) {
            angle += Math.PI;
        }
        //angle = 2*Math.PI - angle;

        return angle;
    }

    /**
     * compute the angle betwenn three point
     *
     * @param {*} center
     * @param {*} p1
     * @param {*} p2
     * @param {boolean} normalize
     * @returns {number}
     */
    static anglePoints(center: math.vec3, p1: math.vec3, p2: math.vec3, normalize?: boolean): number {
        var vecA = math.vec3.create();
        math.vec3.set(vecA, p1[0] - center[0], p1[1] - center[1], p1[2] - center[2]);
        var vecB = math.vec3.create();
        math.vec3.set(vecB, p2[0] - center[0], p2[1] - center[1], p2[2] - center[2]);
        if (normalize === undefined || normalize) {
            return SavaneMath.normalizeAngle(SavaneMath.angleVectors(vecA, vecB));
        }
        return SavaneMath.angleVectors(vecA, vecB);
    }

    /**
     * return the cross point of two line
     *
     * @param {*} line1P1
     * @param {*} line1Point2
     * @param {*} line2P1
     * @param {*} line2P2
     */
    static getCrossPoint(line1P1: math.vec3, line1P2: math.vec3, line2P1: math.vec3, line2P2: math.vec3, precision?: number): math.vec3 {
        let seg1 = new Segment(line1P1, line1P2);
        let seg2 = new Segment(line2P1, line2P2);
        if (Segment.areColinear(seg1, seg2, precision)) {
            return null;
        }

        let crossPoint = math.vec3.create();
        if (math.vec3.distance(seg1.begin, seg1.end) === 0) {
            if (seg2.isPointOnSegment(seg1.begin, precision ? precision : 0)) {
                math.vec3.copy(crossPoint, seg1.begin);
                return crossPoint;
            } else {
                return null;
            }
        }

        if (math.vec3.distance(seg2.begin, seg2.end) === 0) {
            if (seg1.isPointOnSegment(seg2.begin, precision ? precision : 0)) {
                math.vec3.copy(crossPoint, seg2.begin);
                return crossPoint;
            } else {
                return null;
            }
        }

        let director1 = seg1.direction;
        let director2 = seg2.direction;

        let a1 = director1[1];
        let a2 = director2[1];
        let b1 = -director1[0];
        let b2 = -director2[0];

        let c1 = -line1P1[0] * a1 - line1P1[1] * b1;
        let c2 = -line2P1[0] * a2 - line2P1[1] * b2;

        if (Math.abs(a1) > SavaneConstants.Tolerance) {
            crossPoint[1] = (-c2 * a1 + c1 * a2) / (b2 * a1 - b1 * a2);
            crossPoint[0] = (-b1 * crossPoint[1] - c1) / a1;
        } else {
            crossPoint[1] = (-c1 * a2 + c2 * a1) / (b1 * a2 - b2 * a1);
            crossPoint[0] = (-b2 * crossPoint[1] - c2) / a2;
        }
        return crossPoint;
    }

    /**
     * Convert the radian angle to the equivalent radian angle in range [0,PI*2]
     *
     * @param {Number} a (rad)
     * @returns {*}
     */
    static normalizeAngle(a: number): number {
        let normalizedAngle = a;
        while (normalizedAngle > Math.PI * 2.0) {
            normalizedAngle -= Math.PI * 2.0;
        }
        while (normalizedAngle < -(Math.PI * 2.0)) {
            normalizedAngle += Math.PI * 2.0;
        }
        if (normalizedAngle < 0) {
            normalizedAngle = Math.PI * 2.0 + normalizedAngle;
        }
        return normalizedAngle;
    }

    /**
     *
     */
    static intersctionsCircle(centerA: math.vec3, radiusA: number, centerB: math.vec3, radiusB: number): Array<math.vec3> {
        //Equation circleA (x-centerA[0])² + (y-centerA[1])² = radiusA²
        //Equation circleB (x-centerB[0])² + (y-centerB[1])² = radiusB²

        let distanceCenter = math.vec3.distance(centerA, centerB);
        if (distanceCenter > radiusA + radiusB) {
            return null;
        }
        if (distanceCenter < Math.abs(radiusA - radiusB)) {
            return null;
        }
        //There is at least one solution
        let a = (radiusA * radiusA - radiusB * radiusB + distanceCenter * distanceCenter) / (2.0 * distanceCenter);

        let pointA = math.vec3.create();
        math.vec3.set(pointA, centerA[0] + (centerB[0] - centerA[0]) * (a / distanceCenter), centerA[1] + (centerB[1] - centerA[1]) * (a / distanceCenter), 0);
        //Distance to the intersections points
        let h = Math.sqrt(radiusA * radiusA - a * a);

        let rx = -(centerB[1] - centerA[1]) * (h / distanceCenter);
        let ry = (centerB[0] - centerA[0]) * (h / distanceCenter);

        //Calculate the two points
        var interA = math.vec3.create();
        math.vec3.set(interA, pointA[0] + rx, pointA[1] + ry, 0);
        var interB = math.vec3.create();
        math.vec3.set(interB, pointA[0] - rx, pointA[1] - ry, 0);

        return [interA, interB];
    }

    static getCentroid(pointsList: Array<math.vec3>): math.vec3 {
        var factor = 0;
        var centroid = math.vec3.create();
        math.vec3.set(centroid, 0, 0, 0);
        for (let i = 0; i < pointsList.length; i++) {
            var diff = pointsList[i][0] * pointsList[(i + 1) % pointsList.length][1] - pointsList[i][1] * pointsList[(i + 1) % pointsList.length][0];
            centroid[0] += (pointsList[i][0] + pointsList[(i + 1) % pointsList.length][0]) * diff;
            centroid[1] += (pointsList[i][1] + pointsList[(i + 1) % pointsList.length][1]) * diff;
            factor += diff;
        }
        factor *= 0.5;
        centroid[0] *= 1 / (6 * factor);
        centroid[1] *= 1 / (6 * factor);
        return centroid;
    }

    static getPolygonArea(pointsList: Array<math.vec3>): number {
        let areaI = 0;
        //Calculate area of polygon defined by innerpoints list
        for (let i = 0; i < pointsList.length; ++i) {
            areaI = areaI + (pointsList[i][0] + pointsList[(i + 1) % pointsList.length][0]) * (pointsList[i][1] - pointsList[(i + 1) % pointsList.length][1]);
        }
        areaI = Math.abs(areaI * 0.5);
        return areaI;
    }

    /**
     *
     * Triangulate a poly
     * Points has to be ordererd counter clockwise
     *
     * @param points List of points of polygon
     * @param holes Array of points list , can be undefined if no holes
     * @returns {*} List of polygon (points list)
     */
    static triangulate(points: Array<math.vec3>, holes?: Array<math.vec3>): Array<Array<math.vec3>> {
        let earcutDatas: Array<number> = new Array<number>();

        for (let i = 0; i < points.length; i++) {
            earcutDatas.push(points[i][0]);
            earcutDatas.push(points[i][1]);
        }

        var roomHoles: Array<number> = new Array<number>();
        //Rooms
        if (holes !== undefined) {
            for (let i = 0; i < holes.length; i++) {
                let pointsRoom = holes[i];
                if (pointsRoom.length > 0) {
                    roomHoles.push(earcutDatas.length / 2);
                    for (let j = 0; j < pointsRoom.length; j++) {
                        earcutDatas.push(pointsRoom[j][0]);
                        earcutDatas.push(pointsRoom[j][1]);
                    }
                }
            }
        }

        let trianglesNoHoles: Array<Array<math.vec3>> = Array<Array<math.vec3>>();
        let triangles = earcut(earcutDatas, roomHoles);
        for (let i = 0; i < triangles.length; i += 3) {
            let tri: Array<math.vec3> = new Array<math.vec3>();
            tri.push([earcutDatas[triangles[i] * 2], earcutDatas[triangles[i] * 2 + 1], 0]);
            tri.push([earcutDatas[triangles[i + 1] * 2], earcutDatas[triangles[i + 1] * 2 + 1], 0]);
            tri.push([earcutDatas[triangles[i + 2] * 2], earcutDatas[triangles[i + 2] * 2 + 1], 0]);

            trianglesNoHoles.push(tri);
        }
        return trianglesNoHoles;
    }
}
