import { Command, CommandEnum } from './CommandModule'
import { Events } from '../events';
import { eventsManager } from '../managers/EventsManager';
import { EntityFactory, Entity, ArrangementObject, ArrangementGroup, arrangementManager, math } from '../SavaneJS';

export class MergeGroupCommand  extends Command
{
    // New group containing the result of the merge
    private _newGroup: ArrangementGroup;
    // Entities to merge into a group
    private _entitiesToMerge: Array<(ArrangementObject | ArrangementGroup)>;
    // parent entity to parent the merged group to
    private _parent: Entity;

    constructor(entitiesToMerge: Array<(ArrangementObject | ArrangementGroup)>) {
        super();
        // New group that will contain both groups content
        this._newGroup = EntityFactory.createEmptyArrangementGroup();
        // Arrangements to merge into a single group (can be groups or other arrangement objets)
        this._entitiesToMerge = entitiesToMerge.filter((item) => item.isIArrangementEntity());
        // Store the parent
        this._parent = this._entitiesToMerge[0].parent;
    }

    getGroupId(): number {
        return this._newGroup.id;
    }

    // Command name returned from a global enum
    name(): string {
        return CommandEnum.MergeGroupCommand;
    }

    // Datas that allows the command to be executed
    execDatas(): any {
        return {
            group: this._newGroup,
            arrangements: this._entitiesToMerge
        };
    }

    // Undo the current command
    undo() {
        //Remove node group
        eventsManager.instance.dispatch(Events.REMOVE_GRAPHICAL_ENTITY,
            {
                entity: this._newGroup,
            });

        // Get parent invert global
        let toWorld = this._parent.transform.invertedGlobalMatrix;
        // Parse all arrangements to unmerge
        for (let i = 0; i < this._entitiesToMerge.length; i++) {
            // Get deleted arrangement group or object from the arrangement manager
            let arr = arrangementManager.getArrangement(this._entitiesToMerge[i].id, this._newGroup.scene) as (ArrangementObject | ArrangementGroup);

            // If doesn't exist (it must be an arrangemet group which ws destroyed by the exectue function)
            if (!arr) {
                // Get the arrangement itself
                arr = this._entitiesToMerge[i];

                this._parent.addChild(arr);

                // Parse all children and attach them back to the parent
                for (let j = 0; j < arr.children.length; j++) {
                    let subArr = arr.children[j];

                    let toLocal = arr.transform.invertedGlobalMatrix;
                    let global = subArr.transform.globalMatrix;

                    this._newGroup.deleteArrangement(subArr.id);
                    subArr.parent = arr;
                    //This are absolute position, should have group position
                    let matrix = math.mat4.create();
                    math.mat4.multiply(matrix, toLocal, global);
                    math.mat4.copy(subArr.transform.localMatrix, matrix);
                }

                // Recenter the group
                arr.recenter();
            }
            else {
                let global = arr.transform.globalMatrix;

                this._newGroup.deleteArrangement(arr.id);
                this._parent.addChild(arr);

                let matrix = math.mat4.create();
                math.mat4.multiply(matrix, toWorld, global);
                math.mat4.copy(arr.transform.localMatrix, matrix);
            }

            // Recreate each sub entity graphical node
            eventsManager.instance.dispatch(Events.ADD_GRAPHICAL_ENTITY,
                {
                    entity: arr
                });
        }

        // Finally remove the group that received the result of the merge
        this._newGroup.deleteFromParent();

        // Execute parent function
        super.undo();

        // Select the object used to create the group originally
        eventsManager.instance.dispatch(Events.CHANGE_EDITOR_SELECTION,
            {
                selection: this._entitiesToMerge,
                keepSelected: false,
                showTulip: false
            });
    }

    // Execute current command (redo)
    execute() {
        // We start by adding the new arrangement group to the scene
        this._parent.addChild(this._newGroup);

        // Parse all arrangements to merge
        for (let i = 0; i < this._entitiesToMerge.length; i++) {
            // Get the arrangement entity from its id
            let arr = this._entitiesToMerge[i];

            // Remove the arrangement from the scene
            eventsManager.instance.dispatch(Events.REMOVE_GRAPHICAL_ENTITY,
                {
                    entity: arr,
                });

            // If the arrangement is an arrangement group
            if (arr.isArrangementGroupEntity()) {
                // Parse all group child and add them to the new created group
                for (let j = 0; j < arr.children.length; j++) {
                    this.addSubEntities((arr.children[j] as (ArrangementObject | ArrangementGroup)), true);
                }
                // Remove the group from its parent
                arr.parent.deleteChild(arr.id);
            }
            else {
                // Arrangement object, just add it to the new group
                this.addSubEntities(arr, false);
            }
        }

        // Recenter the group
        this._newGroup.recenter();

        // Add the entity to the cocos node manager
        eventsManager.instance.dispatch(Events.ADD_GRAPHICAL_ENTITY,
            {
                entity: this._newGroup,
            });

        //Execute parent function
        super.execute();

        // Select created group at the end
        eventsManager.instance.dispatch(Events.CHANGE_EDITOR_SELECTION,
            {
                selection: [this._newGroup],
                keepSelected: false,
                showTulip: false
            });
    }

    // Add a sub tree to the new created group
    addSubEntities(arr: (ArrangementObject | ArrangementGroup), deep: boolean) {
        let toLocal = this._newGroup.transform.invertedGlobalMatrix;
        let world = arr.transform.globalMatrix;

        if (!deep) {
            arr.parent.deleteChild(arr.id);
        }

        this._newGroup.addArrangement(arr);

        let matrix = math.mat4.create();
        math.mat4.multiply(matrix, toLocal, world);
        math.mat4.copy(arr.transform.localMatrix, matrix);
    }
};
