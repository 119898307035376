import { Command, CommandEnum } from './CommandModule';
import { Events } from '../events';
import { eventsManager } from '../managers/EventsManager';
import { EntityFactory, Entity, ArrangementGroup, math, ArrangementObject } from '../SavaneJS';

export class CreateGroupCommand  extends Command
{
    // To store new created group
    private _newGroup: ArrangementGroup;
    // Arrangement list to add to the created group
    private _arrangementList: Array<ArrangementObject | ArrangementGroup>;
    // Parent entity of the new group
    private _parent: Entity;

    constructor(arrangementList: Array<ArrangementObject | ArrangementGroup>) {
        super();
        // Create an empty group into which the arrangements will be added
        this._newGroup = EntityFactory.createEmptyArrangementGroup();
        // Store arrangement list to add to the group
        this._arrangementList = arrangementList.filter((item) => item.isIArrangementEntity());
        // Store the parent
        this._parent = this._arrangementList[0].parent;
    }

    // Command name returned from a global enum
    name(): string {
        return CommandEnum.CreateGroupCommand;
    }

    // Datas that allows the command to be executed
    execDatas(): any {
        return {
            group: this._newGroup,
            arrangements: this._arrangementList
        };
    }

    // Undo the current command
    undo() {
        // Remove the group from its parent so it disapears
        eventsManager.instance.dispatch(Events.REMOVE_GRAPHICAL_ENTITY,
            {
                entity: this._newGroup,
            });

        // Get parent invert global
        let toWorld = this._parent.transform.invertedGlobalMatrix;
        // Parse all arrangement added to the entity group
        for (let i = 0; i < this._arrangementList.length; i++) {
            // Get arrangement
            let arr = this._arrangementList[i];

            // get arrangement global
            let global = arr.transform.globalMatrix;

            // Delete arrangemet from created group
            arr.deleteFromParent();
            // Add back the arrangement to the original parent (i.e. parent of the group now)
            this._parent.addChild(arr);

            // express global to new parent -> new local
            let matrix = math.mat4.create();
            math.mat4.multiply(matrix, toWorld, global);
            math.mat4.copy(arr.transform.localMatrix, matrix);

            // Recreate each sub entity graphical node
            eventsManager.instance.dispatch(Events.ADD_GRAPHICAL_ENTITY,
                {
                    entity: arr
                });
        }

        // Finally remove the group that received the result of the group creation
        this._newGroup.deleteFromParent();

        // Execute parent function
        super.undo();

        // Select the object used to create the group originally
        eventsManager.instance.dispatch(Events.CHANGE_EDITOR_SELECTION,
            {
                selection: this._arrangementList,
                keepSelected: false,
                showTulip: false
            });
    }

    // Execute current command (redo)
    execute() {
        // Add the group to the first object parent (empty for the moment)
        // This is not 100% rigorous since the objects to group might have different parents BUT, we have to make a choice here and the group parent will be updated
        // when the group will be moved in the scene anyway
        this._parent.addChild(this._newGroup);

        // Parse all arrangements to add them to the group
        for (let i = 0; i < this._arrangementList.length; i++) {
            // Get arrangement from current floor
            let arr = this._arrangementList[i];

            // Delete arrangement entities (will be recreated inside group)
            eventsManager.instance.dispatch(Events.REMOVE_GRAPHICAL_ENTITY,
                {
                    entity: arr,
                });

            //Process to group
            let toLocal = this._newGroup.transform.invertedGlobalMatrix;
            let world = arr.transform.globalMatrix;

            // Delete the link from parents to the object
            arr.deleteFromParent();
            // Add it to the created group
            this._newGroup.addChild(arr);

            let matrix = math.mat4.create();
            math.mat4.multiply(matrix, toLocal, world);
            math.mat4.copy(arr.transform.localMatrix, matrix);
        }

        this._newGroup.recenter();
        // Recreate entity and node
        eventsManager.instance.dispatch(Events.ADD_GRAPHICAL_ENTITY,
            {
                entity: this._newGroup,
            });

        //Execute parent function
        super.execute();

        // Select created group at the end
        eventsManager.instance.dispatch(Events.CHANGE_EDITOR_SELECTION,
            {
                selection: [this._newGroup],
                keepSelected: false,
                showTulip: false
            });
    }
};
