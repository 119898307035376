import { RenderCamera } from "../../scene/RenderCamera";
import * as glMatrix from "gl-matrix"

export class CameraKeyPoint
{
    private _camera: RenderCamera;
    private _startTangent: glMatrix.vec3 | null;
    private _endTangent: glMatrix.vec3 | null;

    constructor(camera: RenderCamera, startTangent: glMatrix.vec3 | null, endTangent: glMatrix.vec3 | null) {
        this._camera = camera;
        this._startTangent = startTangent;
        this._endTangent = endTangent;
    }

    get Camera() : RenderCamera {
        return this._camera;
    }

    get StartTangent() : glMatrix.vec3 | null {
        return this._startTangent;
    }

    get EndTangent() : glMatrix.vec3 | null {
        return this._endTangent;
    }

    set Camera(value: RenderCamera) {
        this._camera = value;
    }

    set StartTangent(value: glMatrix.vec3) {
        this._startTangent = value;
    }

    set EndTangent(value: glMatrix.vec3) {
        this._endTangent = value;
    }
}
